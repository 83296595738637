import { HeatmapBlockTypeEnum } from "../../enum/heatmap-block-type.enum";

export class HeatmapLayerState {
    layer: HeatmapBlockTypeEnum;
    isCondo: string;
    dateRangeInMonths: number;

    constructor(layer: HeatmapBlockTypeEnum, isCondo: string, dateRangeInMonths: number) {
        this.layer = layer;
        this.isCondo = isCondo;
        this.dateRangeInMonths = dateRangeInMonths;
    }

}