import {PropertyDetail} from "./property-detail";
import {AddressUtility} from "../../../shared/utility/address.utility";
import {Assessment} from "../assessment/assessment";

export class EStoreProperty {
  arnonly: boolean;
  pin: string;
  address: string;
  conduitCertAvailable: boolean;
  arn: string;
  assessments: Assessment[];
  lro: string;
  requestedarn?: string;
  requestedpin?: string;
  landRegistryStatus: string;
  pincode: string;
  legalDescription: string;

  loadFromPropertyDetails(propertySearchResult: PropertyDetail, requestedpin?: string, requestedarn?: string) {

    this.arnonly = false;
    this.pin = 'N/A';
    this.requestedpin = requestedpin;
    this.requestedarn = requestedarn;

    if (propertySearchResult.pii != null && propertySearchResult.pii.landRegistryStatus != null) {
      this.landRegistryStatus = propertySearchResult.pii.landRegistryStatus;
    }
    this.legalDescription = propertySearchResult.pii?.shortLegalDescription;
    if (propertySearchResult.pii != null) {
      
      if (!!propertySearchResult.pii.pin) {
        this.pin = propertySearchResult.pii.pin;
      }

      if (propertySearchResult.pii.arns[0] != null) {
        this.arnonly = true;
        this.arn = propertySearchResult.pii.arns[0];
      }

      this.lro = propertySearchResult.pii.lro;
      this.assessments = propertySearchResult.assessments;      
    }

    if (propertySearchResult.pii && propertySearchResult.pii.address != null && propertySearchResult.pii.address.fullAddress != null && propertySearchResult.pii.address.fullAddress != "") {
      var add = propertySearchResult.pii.address.fullAddress;
      let pinCodeNumber = AddressUtility.getPinCodeFromStreetAddress(add);

      if (pinCodeNumber) {
        this.pincode = pinCodeNumber;
      }
      this.address = propertySearchResult.pii.address.fullAddress;
    } else {
      this.address = "Address Not Available";
    }

    this.conduitCertAvailable = false;
    if (propertySearchResult.condoDetail != null && propertySearchResult.condoDetail.conduitDatas != null && propertySearchResult.condoDetail.conduitDatas.length > 0) {
      this.conduitCertAvailable = true;
    }
  }
}
