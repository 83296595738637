import { Centroid } from "../../../../core/model/spatial/centroid";

export class StreetViewDialogData {
  constructor(title: string, pin: string, centroid: Centroid, closeButtonText: string) {
    this.title = title;
    this.pin = pin;
    this.centroid = centroid;
    this.closeButtonText = closeButtonText;
  }

  title: string;
  pin: string;
  centroid: Centroid;
  closeButtonText: string;
}
