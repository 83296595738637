import {HttpClient} from '@angular/common/http';
import {baseUrl} from './system';
import {inject, Injectable} from '@angular/core';
import {catchError, map, Observable, of, throwError} from 'rxjs';
import * as _ from 'lodash';
import {LoggerService} from './log/logger.service';
import {ComparableSalesReportItem} from "../../core/model/comparables/report/comparable-sales-report-item";
import {ComparableSalesReportDetail} from "../../core/model/comparables/report/comparable-sales-report-detail";
import {FileUtility} from "../utility/file.utility";
import {ComparablesSalesReportPrintParam} from "../../core/model/comparables/report/print/comparables-sales-report-print-param";
import {ComparableSalesReportResponse} from "../../core/model/comparables/report/comparable-sales-report-response";
import {ComparableSalesReportCreationRequest} from "../../core/model/comparables/report/comparable-sales-report-creation-request";
import {ComparableSalesReportRecentReport} from "../../core/model/comparables/report/comparable-sales-report-recent-report";
import {PinOrArn} from "../../core/model/property/pin-or-arn";


@Injectable({
  providedIn: 'root'
})

export class ComparablesReportService {

  private https = inject(HttpClient);
  private loggerService = inject(LoggerService);
  private fileUtilityService = inject(FileUtility);

  getExistingReports = (): Observable<ComparableSalesReportItem[]> => {
    let url = baseUrl + '/comparablereport/reportList';

    return this.https.get<ComparableSalesReportItem[]>(url)
      .pipe(
        map(response => {
          if (!_.isEmpty(response)) {
            return response.map(item => {
              return new ComparableSalesReportItem(item)
            });
          } else {
            return [];
          }
        }),
        catchError((err) => {
          this.loggerService.logError('error retrieving reports list', err);
          return throwError(err);
        })
      );
  }

  getReport = (reportId: number): Observable<ComparableSalesReportDetail> => {
    let url = `${baseUrl}/comparablereport?reportId=${reportId}`;

    return this.https.get<ComparableSalesReportDetail>(url)
      .pipe(
        map(response => {
          return new ComparableSalesReportDetail(response);
        }),
        catchError((err) => {
          this.loggerService.logError('error retrieving reports list', err);
          return throwError(err);
        })
      );
  }

  getCsvReport(reportId: number, fileName: string): Observable<boolean> {
    const url = `${baseUrl}/comparablereport/${reportId}/getReportInCSV`;

    return this.https.get(url, {
      headers: {
        'Content-Type': 'undefined'
      },
      params: {fileName: fileName},
      responseType: 'arraybuffer'
    }).pipe(
      map((response: any) => {
        this.loggerService.logInfo(response);
        this.fileUtilityService.saveArrayBufferToFile(response, fileName);
        return true;
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return of(false);
      })
    );
  }


  getPrintParamSections = (): Observable<ComparablesSalesReportPrintParam> => {
    let url = baseUrl + '/comparablereport/sections';

    return this.https.get<ComparablesSalesReportPrintParam>(url)
      .pipe(
        map(response => {
          return new ComparablesSalesReportPrintParam(response);
        }),
        catchError((err) => {
          this.loggerService.logError('error retrieving reports list', err);
          return throwError(err);
        })
      );
  }

  getPdfReport(reportId: number, fileName: string, sections: ComparablesSalesReportPrintParam): Observable<boolean> {
    const url = `${baseUrl}/comparablereport/pdf?reportId=${reportId}&sections=${sections.getSelectedSections()}`;

    return this.https.get(url, {
      headers: {
        'Content-Type': 'undefined'
      },
      params: {fileName: fileName},
      responseType: 'arraybuffer'
    }).pipe(
      map((response: any) => {
        this.loggerService.logInfo(response);
        this.fileUtilityService.saveArrayBufferToFile(response, fileName);
        return true;
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return of(false);
      })
    );
  }


  removePropertyFromReport(reportId: number, compId: number): Observable<ComparableSalesReportResponse> {
    const url = `${baseUrl}/comparablereport/${reportId}/removepin?entryId=${compId}`;
    return this.https.post(url, {}).pipe(
      map(resp => {
        return new ComparableSalesReportResponse(resp);
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }

  removeReport(reportId: number): Observable<ComparableSalesReportResponse> {
    const url = `${baseUrl}/comparablereport?reportId=${reportId}`;
    return this.https.delete(url).pipe(
      map(resp => {
        return new ComparableSalesReportResponse(resp);
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }

  createReport(param: ComparableSalesReportCreationRequest): Observable<ComparableSalesReportResponse> {
    const url = `${baseUrl}/comparablereport`;
    return this.https.post(url, param).pipe(
      map(resp => {
        return new ComparableSalesReportResponse(resp);
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }

  getRecentReports(pin: string, arn: string): Observable<ComparableSalesReportRecentReport> {
    const url = `${baseUrl}/comparablereport/recentreports?pin=${pin}&arn=${arn}`;
    return this.https.get<ComparableSalesReportRecentReport>(url).pipe(
      map(resp => {
        return new ComparableSalesReportRecentReport(resp);
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }

  addPinsToReport(reportId: number, pins: PinOrArn[]): Observable<ComparableSalesReportResponse> {
    const url = `${baseUrl}/comparablereport/${reportId}/addpin`;
    return this.https.post(url, pins).pipe(
      map(resp => {
        return new ComparableSalesReportResponse(resp);
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }


}
