<div class="top-banner">
  <div class="flex flex-[1]">
    <span class="text-white ml-3">
      @if (isSelectedReportAvailable){
        Comparables Report ({{ selectedReport?.reportName }} - {{ selectedReport?.compsArnVO?.length }} properties)
      } @else {
        Comparables Reports
      }
    </span>

  </div>
  <div class="flex-[1]"></div>
  <div class="flex w-3/12 justify-end items-center gap-3">
    @if (isSelectedReportAvailable){
      <div id="csr-btn-export-img" (click)="getCsvReport()">
        <img id="csr-img-export" src="assets/img/mygeo-icons/icon_download.png" class="h-4" (click)="getCsvReport()"/>
      </div>
      <div id="csr-btn-export-txt" (click)="getCsvReport()">
        <span id="pr-export" class="text-white">Export</span>
      </div>

      <div id="csr-btn-print-img" (click)="printPDFReport()">
        <img id="csr-img-print" src="assets/img/svg/GWHPrint.svg" class="h-4 border-l-2 border-l-white border-solid pl-2" (click)="printPDFReport()"/>
      </div>
      <div id="csr-btn-print-txt" (click)="printPDFReport()">
        <span id="pr-print" class="text-white">Print</span>
      </div>
    }
    <div id="csr-btn-close" (click)="closeForm()" class="w-[30px] mr-4">
      <fa-icon class="fa-lg text-white" [icon]="faCircleXmark"></fa-icon>
    </div>
  </div>
</div>


@if (isLoading) {
  <div class="spinner">

    Loading...
    <img id="rpt-img-spinner" alt="Loading..." src="assets/img/icon-refresh.gif"/>
  </div>
} @else {

  @if (!isSelectedReportAvailable) {
    <div class="report-container">
      <table id="rpt-tbl" mat-table [dataSource]="reports" class="mat-elevation-z8 standard-table">

        <ng-container matColumnDef="reportName">
          <th mat-header-cell *matHeaderCellDef> Report Name</th>
          <td id="rpt-tbl-nm" mat-cell *matCellDef="let report">
            <a (click)="openReport(report.reportId)" class="anchor" target="_blank">{{ report.reportName }}</a>
          </td>
        </ng-container>

        <ng-container matColumnDef="subjectPin">
          <th mat-header-cell *matHeaderCellDef> Subject Pin</th>
          <td id="rpt-tbl-pin" mat-cell *matCellDef="let report">

            @if (report.subjectPin) {
              @if (userAccessControls.propertyReportAccess) {
                <a (click)="goToPin(report.subjectPin)" class="anchor" target="_blank">{{ report.subjectPin }}</a>
              } @else {
                {{ report.subjectPin }}
              }
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef> Address</th>
          <td id="rpt-tbl-adr" mat-cell *matCellDef="let report"> {{ report.address }}</td>
        </ng-container>

        <ng-container matColumnDef="clientName">
          <th mat-header-cell *matHeaderCellDef> Client Name</th>
          <td id="rpt-tbl-cn" mat-cell *matCellDef="let report"> {{ report.clientName }}</td>
        </ng-container>

        <ng-container matColumnDef="jobNumber">
          <th mat-header-cell *matHeaderCellDef> Job Number</th>
          <td id="rpt-tbl-jn" mat-cell *matCellDef="let report"> {{ report.jobNumber }}</td>
        </ng-container>

        <ng-container matColumnDef="modifiedDate">
          <th mat-header-cell *matHeaderCellDef> Modified Date</th>
          <td id="rpt-tbl-md" mat-cell *matCellDef="let report"> {{ report.modifiedDate | date }}</td>
        </ng-container>

        <ng-container matColumnDef="comments">
          <th mat-header-cell *matHeaderCellDef> Comments</th>
          <td id="rpt-tbl-comm" mat-cell *matCellDef="let report"> {{ report.comments }}</td>
        </ng-container>

        <ng-container matColumnDef="addReport">
          <th mat-header-cell *matHeaderCellDef> <a id="comp-sal-report-remove" (click)="addReport()" class="anchor" target="_blank">Add New</a> </th>
          <td mat-cell *matCellDef="let report">
            <a id="comp-sal-report-remove-{{report.reportId}}" (click)="removeReport(report.reportId)" class="anchor" target="_blank">Remove</a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  } @else if(selectedReport) {
    <div class="report-container">

      <div class="flex flex-row flex-nowrap gap-0.5">
        <div class="max-w-[300px]">
        <gema3g-comparables-property-detail-header
          [propertyDetail]="selectedReport.subjectArnVO"
        >
        </gema3g-comparables-property-detail-header>
        </div>

        <div class="max-w-[300px]">
        <gema3g-comparables-property-detail
          [propertyDetail]="selectedReport.subjectArnVO"
          [imageUrl]="propertyImages.getImageUrlForPin(selectedReport?.subjectArnVO?.pii?.pin)"
          [isSubject] = true
          [reportDate]="selectedReport.reportDate"
        ></gema3g-comparables-property-detail>
        </div>

        <div class="w-full flex flex-row gap-0.5">
        @for (comp of selectedReport?.compsArnVO; track $index) {
          <div class="max-w-[300px]">
          <gema3g-comparables-property-detail
            [propertyDetail]="comp"
            [imageUrl]="propertyImages.getImageUrlForPin(comp?.pii?.pin)"
            [isSubject] = false
            [reportDate]="selectedReport.reportDate"
            [compId]="comp.compsReportEntry.compId"
            [reportId]="selectedReport.reportId"
            (propertyRemoved)="propertyRemoved($event)"
          ></gema3g-comparables-property-detail>
          </div>
        }
        </div>
      </div>
    </div>
  }

}
