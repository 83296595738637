import { Component } from '@angular/core';

@Component({
  selector: 'gema3g-omnibar-owner-search-results',
  templateUrl: './omnibar-owner-search-results.component.html',
  styleUrls: ['./omnibar-owner-search-results.component.scss']
})
export class OmnibarOwnerSearchResultsComponent {

}
