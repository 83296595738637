import {BaseModel} from "../../../base-model";
import {PdfReportPropertyDetailConfiguration} from "./pdf-report-property-detail-configuration";
import {PropertyDetail} from "../../../property/property-detail";
import {UserAccessControl} from "../../../user/user-access-control";
import {ReportPreference} from "../../../user/preference/report-preference";

export class PdfReportPropertyInfoConfiguration extends BaseModel {

  constructor(propertyDetail: PropertyDetail, userAccessControls: UserAccessControl, reportPreferences? : ReportPreference ) {
    super();
    if(propertyDetail){
      if(!propertyDetail.pii?.hasLegalDescription) {
        this.propertyHasLegalDescription = false;
      }
      if(propertyDetail.pii?.pin == null && propertyDetail.pii?.arns[0] != null){
        this.propertyHasLegalDescription = false;
      }

      this.propertyIsCondo = propertyDetail.isCondoOrRelatedToACondo;

      this.hasLotSize = propertyDetail.hasLotSize;

      this.canDisplayAssessmentInfo = propertyDetail.assessments?.length > 0 && userAccessControls?.propertyReportAccess;

      this.hasSalesHistory = propertyDetail.salesHistory?.length > 0;

      this.canDisplaySalesHistory = this.hasSalesHistory  && userAccessControls?.propertyReportAccess;

      //this is set outside of this scope has it requires checking with backend if demographics data exists
      // see generate-pdf-report.component -> checkAccess()

      this.canDisplayDemographic = propertyDetail.hasDemographicsData && !propertyDetail.isCondoOrRelatedToACondo;

      this.propertyDetail = new PdfReportPropertyDetailConfiguration(propertyDetail, reportPreferences);

      if (reportPreferences) {

        this.ownership = reportPreferences.pdfOwnership;
        if(this.propertyHasLegalDescription){
          this.legalDescription = reportPreferences.pdfLegalDescription;
        }

        if (this.canDisplayAssessmentInfo) {
          this.assessmentInfo = reportPreferences.pdfAssessmentInformation;
        }
        if(this.hasLotSize) {
          this.lotSize = reportPreferences.pdfLotSize;
        }
        if(this.hasSalesHistory) {
          this.salesHistory = reportPreferences.pdfSalesHistory;
        }
        if(this.canDisplayAssessmentInfo) {
          this.demographic = reportPreferences.pdfDemographics;
          this.dominantMarketGroup = reportPreferences.pdfDominantMarketGroup;
        }
      }
      this.hasProperty = !propertyDetail.isEmpty;
      this.propertyPinOrArn = (propertyDetail.pii?.pin)
        ? `PIN: ${propertyDetail.pii?.pin}`
        : (propertyDetail.pii?.arn) ? `ARN: propertyDetail.pii.arn` : '';
      this.propertyDescription = ((propertyDetail.pii?.fullAddress) ? propertyDetail.pii?.fullAddress : 'ADDRESS NOT AVAILABLE');
    }
  }

  propertyDetail: PdfReportPropertyDetailConfiguration;
  ownership: boolean;

  canDisplayAssessmentInfo: boolean;
  assessmentInfo: boolean;

  propertyHasLegalDescription: boolean = true;
  legalDescription: boolean;

  propertyIsCondo: boolean;
  condo: boolean;

  canDisplaySalesHistory: boolean;
  hasSalesHistory: boolean;
  salesHistory: boolean;

  hasLotSize: boolean;
  lotSize: boolean;

  canDisplayDemographic: boolean;
  demographic: boolean;
  dominantMarketGroup: boolean;

  hasProperty: boolean;
  propertyDescription: string;
  propertyPinOrArn: string;
}
