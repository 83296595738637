<div class="generate-pdf-report-client">
  <h1 id="pdf-h1-client-title" class="generate-pdf-report-client-header">
    Customize your report
    <a id="pdf-link-client-close" (click)="closeAdditionalParams()" class="pull-right">
      <gema3g-svg-icon [icon]="'close'" [width]="16" [height]="16" fill="#cccccc"></gema3g-svg-icon>
    </a>
  </h1>
  <div>
    <div class="report-format">
      <div class="report-format-column col-span-2">
        <div class="report-format-column-title">
          <div>
            <mat-label id="pdf-label-client-rt">Report Title</mat-label>
          </div>
          <div>
            <input id="pdf-tf-client-rt" [(ngModel)]="inputParam.reportTitle" mat-input placeholder="Report title" type="text"/>
          </div>
        </div>
      </div>

      <div class="report-format-column">

        <div class="report-format-column-row">
          <div id="pdf-client-cover-label" class="report-format-column-row-item">
            Cover
          </div>
          <div class="report-format-column-row-item">
            <mat-slide-toggle id="pdf-toggle-client-cover" [(ngModel)]="inputParam.cover" color="primary"></mat-slide-toggle>
          </div>
        </div>

      </div>
    </div>

    <mat-divider class="!my-2"></mat-divider>

    @if (openedFromComparablesReport) {
      <gema3g-generate-pdf-report-area-sales-info-section
        [inputParam]="inputParam"
      >
      </gema3g-generate-pdf-report-area-sales-info-section>

    } @else {
      <div id="pdf-client-pi-label" class="section-heading">Property Information</div>
      <gema3g-generate-pdf-report-property-info-section
        [inputParam]="inputParam"
        (reloadProperty)="goReloadProperty($event)"
      >
      </gema3g-generate-pdf-report-property-info-section>
    }
    <mat-divider class="!my-2"></mat-divider>
  </div>
</div>
