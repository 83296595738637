export class ProgressBarWithTimeoutDialogData {

  constructor(title: string, timeout?: number, content?: string[], innerHtmlContent?: string, buttonText?: string) {
    if (title != null) {
      this.title = title;
    }
    if (content && content.length > 0) {
      this.content = content;
    }
    if (innerHtmlContent != null) {
      this.innerHtmlContent = innerHtmlContent;
    }
    if (buttonText != null) {
      this.buttonText = buttonText;
    }
    if(timeout != null) {
      this.timeout = timeout;
    }
  }

  title: string = '';
  content: string[] | undefined = [];
  innerHtmlContent: string = '';
  buttonText: string = 'Close';
  timeout: number = 1000;
}
