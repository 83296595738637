<h1 id="ad-h1-title" mat-dialog-title>{{data.title}}</h1>

<mat-divider></mat-divider>

<div class="dialog-content" mat-dialog-content>
  <div class="about-body">
    <div class="logo">
      <img id="ad-logo" src="assets/img/geowarehouse-mini-logo.png"/>
    </div>
    <div class="text-lines">
      <h3 id="ad-h3-up-to-date">GeoWarehouse is up to date.</h3>
      <h3 id="ad-h3-version">Version {{data.buildText}}</h3>
      <small id="ad-copyright" class="sm:mr-4">Copyright &copy; 2002-{{data.thisYear}} Teranet Inc. and its suppliers. All rights reserved.</small>
    </div>
  </div>
</div>

<mat-divider></mat-divider>

<div align="end" mat-dialog-actions>
  <button id="ad-btn-ok" [mat-dialog-close]='true' cdkFocusInitial color="primary" mat-button>{{data.closebuttonText}}</button>
</div>
