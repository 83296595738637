import {SpatialPoint} from "../../core/model/spatial/spatial-point";
import {BoundingBox} from "../../core/model/map/bounding-box";
import {WebMercator} from "../../core/model/map/web-mercator";

export class MapUtility {

  public static DEGREES_TO_RADIANS = Math.PI / 180;

  public static EARTH_EQUATORIAL_RADIUS = 6378137;
  public static EARTH_POLAR_RADIUS =  6356752.3142;

  public static screenPointToLatLng = (x: number, y: number, map: google.maps.Map): google.maps.LatLng => {
    let topRight = map.getProjection()!.fromLatLngToPoint(map.getBounds()!.getNorthEast());
    let bottomLeft = map.getProjection()!.fromLatLngToPoint(map.getBounds()!.getSouthWest());
    let scale = Math.pow(2, map.getZoom()!);
    let worldPoint = new google.maps.Point(
      x / scale + bottomLeft!.x,
      y / scale + topRight!.y
    );

    return map.getProjection()!.fromPointToLatLng(worldPoint)!;
  }

  public static latLngToScreenPoint = (latLng: google.maps.LatLng, map: google.maps.Map): [number, number] => {
    var topRight = map.getProjection()!.fromLatLngToPoint(map.getBounds()!.getNorthEast());
    var bottomLeft = map.getProjection()!.fromLatLngToPoint(map.getBounds()!.getSouthWest());
    var scale = Math.pow(2, map.getZoom()!);
    var worldPoint = map.getProjection()!.fromLatLngToPoint(latLng);
    return [Math.floor((worldPoint!.x - bottomLeft!.x) * scale), Math.floor((worldPoint!.y - topRight!.y) * scale)];
  }

  public static tileToQuadKey(x: number, y: number, zoom: number): string {
    var quad = "";

    for (var i = zoom; i > 0; i--) {
      var mask = 1 << (i - 1);
      var cell = 0;
      if ((x & mask) != 0) cell++;
      if ((y & mask) != 0) cell += 2;
      quad += cell;
    }

    //this.loggerService.logInfo('quad: ', quad);
    return quad;
  }

  public static tileToBoundingBox(tileX: number, tileY: number, zoom: number): BoundingBox {
    const topLeft = MapUtility.tileToPoint(tileX, tileY, zoom);
    const bottomRight = MapUtility.tileToPoint(tileX + 1, tileY + 1, zoom);
    const topLeftMercator = MapUtility.pointToWeb3395Mercator(topLeft);
    const bottomRightMercator = MapUtility.pointToWeb3395Mercator(bottomRight);

    return new BoundingBox({xMin: topLeftMercator.x, yMin: topLeftMercator.y, xMax: bottomRightMercator.x, yMax: bottomRightMercator.y});
  }

  // maps a tile to lat/long
  public static tileToPoint(tileX: number, tileY: number, zoom: number): SpatialPoint {
    const z2 = Math.pow(2, zoom);
    const lng = (tileX / z2) * 360.0 - 180.0;
    const latRadian = Math.atan(Math.sinh(Math.PI * (1 - (2 * tileY) / z2)));
    const lat = latRadian / (Math.PI / 180);

    return new SpatialPoint({longitude: lng, latitude: lat});
  }

  // page 44 from here https://pubs.usgs.gov/pp/1395/report.pdf
  // todo - may want to use a lib instead https://www.npmjs.com/package/transform-coordinates
  public static pointToWeb3395Mercator(spatialPoint: SpatialPoint): WebMercator {
    spatialPoint.truncatePoint();

    const ellipsoidEccentricity = Math.sqrt(1 - (Math.pow(MapUtility.EARTH_POLAR_RADIUS, 2)  / (Math.pow(MapUtility.EARTH_EQUATORIAL_RADIUS, 2))));

    const c = Math.pow((1 - ellipsoidEccentricity * Math.sin(spatialPoint.latitudeRadians))
                        /
                          (1 + ellipsoidEccentricity * Math.sin(spatialPoint.latitudeRadians))
                      , ellipsoidEccentricity / 2);

    return {
      x: MapUtility.EARTH_EQUATORIAL_RADIUS * spatialPoint.longitudeRadians,
      y: MapUtility.EARTH_EQUATORIAL_RADIUS * Math.log(Math.tan(Math.PI / 4 + spatialPoint.latitudeRadians / 2) * c)
    };
  }

}
