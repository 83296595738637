import {FusionChartDataSource} from "../../../core/model/fusion-chart/fusion-chart-data-source";
import {FusionChartLayout} from "../../../core/model/fusion-chart/fusion-chart-layout";
import {DemographicsPopulationItem} from "../../../core/model/demographics/demographics-population-item";
import {FusionChartData} from "../../../core/model/fusion-chart/fusion-chart-data";
import {FusionChartAnnotation} from "../../../core/model/fusion-chart/fusion-chart-annotation";
import {FusionChartAnnotationGroup} from "../../../core/model/fusion-chart/fusion-chart-annotation-group";
import {FusionChartAnnotationGroupItem} from "src/app/core/model/fusion-chart/fusion-chart-annotation-group-item";
import {FusionChartCategoryData} from "src/app/core/model/fusion-chart/fusion-chart-category-data";
import {FusionChartDataset} from "../../../core/model/fusion-chart/fusion-chart-dataset";
import {FusionChartDatasetData} from "../../../core/model/fusion-chart/fusion-chart-dataset-data";
import {FusionChartCategory} from "../../../core/model/fusion-chart/fusion-chart-category";
import {DemographicsCulturalItem} from "../../../core/model/demographics/demographics-cultural-item";
import {DemographicsMetadata} from "../../../core/model/demographics/demographics-metadata";
import {DemographicsSocioEconomicItem} from "../../../core/model/demographics/demographics-socio-economic-item";
import {DemographicsHouseHoldItem} from "../../../core/model/demographics/demographics-house-hold-item";

export class DemographicsChartsUtility {

  static maritalStatusDataSource(fontSize: string, key: string, population: DemographicsPopulationItem, windowWidth: number): FusionChartDataSource {

    const chartDataSource = new FusionChartDataSource();
    if (!population) {
      return chartDataSource;
    }
    const chart = new FusionChartLayout();
    //chart.legendPosition = "right";
    chart.dataEmptyMessage = "No Data Available.";
    chart.numberSuffix = "%";
    chart.paletteColors = "#322d88,#605ca8,#a09dcb,#d7d6e9";
    chart.bgColor = "#ffffff";
    chart.showBorder = "0";
    chart.use3DLighting = "0";
    chart.showShadow = "0";
    chart.enableSmartLabels = "0";
    chart.startingAngle = "310";
    chart.showLabels = "0";
    chart.showPercentValues = "1";
    chart.showLegend = "1";
    chart.legendShadow = "0";
    chart.legendBorderAlpha = "0";
    chart.centerLabelBold = "0";
    chart.showTooltip = "";
    chart.decimals = "2";
    chart.captionFontSize = "14";
    chart.subcaptionFontSize = "14";
    chart.subcaptionFontBold = "0";
    chart.chartLeftMargin = "0";
    chart.chartRightMargin = "0";
    chart.chartTopMargin = "0";
    chart.chartBottomMargin = "0";
    chart.showLegend = "0";
    chart.showLabels = "0";
    chart.showValues = "0";
    chart.labelFont = '"Open Sans Reg", sans-serif';
    chart.baseFont = '"Open Sans Reg", sans-serif';
    chart.baseFontSize = fontSize;
    chart.baseFontColor = "#1c1c1c";
    chart.enableSlicing = "0";
    chart.defaultCenterLabel = key;
    if (windowWidth > 2500) {
      chart.pieRadius = "70";
    } else if (windowWidth >= 1025 && windowWidth <= 2500) {
      chart.pieRadius = "48";
    } else if (windowWidth < 1025) {
      chart.pieRadius = "46";
    }
    chartDataSource.chart = chart;

    let data: FusionChartData[] = [];
    data.push(new FusionChartData(Math.round(population.percentageOfSingle), "Single"));
    data.push(new FusionChartData(Math.round(population.percentageOfMarried), "Married"));
    data.push(new FusionChartData(Math.round(population.percentageOfSeparatedOrDivorced), "Seperated / Divorced"));
    data.push(new FusionChartData(100 - Math.round(population.percentageOfSingle) - Math.round(population.percentageOfMarried) - Math.round(population.percentageOfSeparatedOrDivorced), "Other"));

    chartDataSource.data = data;

    return chartDataSource;
  }

  static genderDataSource(fontSize: string, key: string, population: DemographicsPopulationItem, windowWidth: number): FusionChartDataSource {

    const chartDataSource = new FusionChartDataSource();
    if (!population) {
      return chartDataSource;
    }
    const chart = new FusionChartLayout();
    chart.dataEmptyMessage = "No Data Available.";
    chart.xaxismaxvalue = "100";
    chart.yaxismaxvalue = "100";
    chart.formatnumberscale = "0";
    chart.numberSuffix = "%";
    chart.paletteColors = "#322d88,#afadd3";
    chart.bgColor = "#ffffff";
    chart.borderAlpha = "20";
    chart.showCanvasBorder = "0";
    chart.usePlotGradientColor = "0";
    chart.plotBorderAlpha = "0";
    chart.legendBorderAlpha = "0";
    chart.legendShadow = "0";
    chart.formatnumberscale = "0";
    chart.valueFontColor = "#ffffff";
    chart.showXAxisLine = "0";
    chart.xAxisLineColor = "#999999";
    chart.divlineColor = "#999999";
    chart.divLineDashed = "0";
    chart.divLineAlpha = "0";
    chart.showAlternateVGridColor = "0";
    chart.subcaptionFontBold = "0";
    chart.subcaptionFontSize = "14";
    chart.showHoverEffect = "1";
    chart.labelDisplay = "none";
    chart.showBorder = "0";
    chart.showLabels = "0";
    chart.showValues = "0";
    chart.showYAxisValues = "0";
    chart.adjustDiv = "0";
    chart.showVLineLabelBorder = "0";
    chart.plotSpacePercent = "50";
    chart.placeValuesInside = "0";
    chart.chartLeftMargin = "0";
    chart.chartRightMargin = "0";
    chart.chartTopMargin = "0";
    chart.chartBottomMargin = "0";
    chart.labelFont = '"Open Sans Reg", sans-serif';
    chart.baseFont = '"Open Sans Reg", sans-serif';
    chart.baseFontSize = fontSize;
    chart.baseFontColor = "#1c1c1c";
    chart.canvasTopMargin = "0";
    chart.canvasBottomMargin = "0";
    if (windowWidth > 2500) {
      chart.canvasLeftMargin = "191";
      chart.canvasRightMargin = "80";
    } else if (windowWidth >= 1025 && windowWidth <= 2500) {
      chart.canvasLeftMargin = "127";
      chart.canvasRightMargin = "53";
    } else if (windowWidth < 1025) {
      chart.canvasLeftMargin = "96";
      chart.canvasRightMargin = "45";
    }
    chartDataSource.chart = chart;

    // add annotations
    let x1 = '', x2 = '', x3 = '';
    if (windowWidth > 2500) {
      x1 = "$canvasStartX - 178";
      x2 = "$canvasStartX - 63";
      x3 = "$canvasEndX + 64";
    } else if (windowWidth >= 1025 && windowWidth <= 2500) {
      x1 = "$canvasStartX - 117";
      x2 = "$canvasStartX - 41";
      x3 = "$canvasEndX + 42";
    } else if (windowWidth < 1025) {
      x1 = "$canvasStartX - 95";
      x2 = "$canvasStartX - 33";
      x3 = "$canvasEndX + 34";
    }
    let annotations: FusionChartAnnotation = new FusionChartAnnotation();
    let annotationGroup = new FusionChartAnnotationGroup();
    annotationGroup.items.push(new FusionChartAnnotationGroupItem("text", key, x1, "$dataset.0.set." + 0 + ".y", "#1b1b1b", "left"));
    annotationGroup.items.push(new FusionChartAnnotationGroupItem("text", Math.round(population.percentageOfFemales) + "%", x2, "$dataset.0.set." + 0 + ".y", "#1b1b1b", "left"));
    annotationGroup.items.push(new FusionChartAnnotationGroupItem("text", Math.round(population.percentageOfMales) + "%", x3, "$dataset.0.set." + 0 + ".y", "#1b1b1b", "right"));
    annotations.groups.push(annotationGroup);
    chartDataSource.annotations = annotations;

    chartDataSource.categories = [new FusionChartCategory([new FusionChartCategoryData(key)])];

    chartDataSource.dataset = [
      new FusionChartDataset([new FusionChartDatasetData(Math.round(population.percentageOfFemales))]),
      new FusionChartDataset([new FusionChartDatasetData(Math.round(population.percentageOfMales))])
    ];

    return chartDataSource;
  }

  static ageDistributionDataSource(fontSize: string, key: string, population: DemographicsPopulationItem, windowWidth: number): FusionChartDataSource {
    const chartDataSource = new FusionChartDataSource();
    if (!population) {
      return chartDataSource;
    }
    const chart = new FusionChartLayout();
    chart.numberSuffix = "%";
    chart.paletteColors = "#afadd3";
    chart.bgColor = "#ffffff";
    chart.canvasBorderAlpha = "0";
    chart.usePlotGradientColor = "0";
    chart.plotBorderAlpha = "0";
    chart.placeValuesInside = "0";
    chart.rotatevalues = "0";
    chart.valueFontColor = "#000000";
    chart.showXAxisLine = "0";
    chart.xAxisLineColor = "#999999";
    chart.divlineColor = "#999999";
    chart.divLineDashed = "0";
    chart.showAlternateVGridColor = "0";
    chart.labelDisplay = "wrap";
    chart.showBorder = "0";
    chart.showYAxisValues = "0";
    chart.adjustDiv = "0";
    chart.showVLineLabelBorder = "0";
    chart.showCanvasBorder = "0";
    chart.usePlotGradientColor = "0";
    chart.plotBorderAlpha = "0";
    chart.showXAxisLine = "0";
    chart.divLineDashed = "0";
    chart.divLineAlpha = "0";
    chart.adjustDiv = "0";
    chart.showVLineLabelBorder = "0";
    chart.labelFont = '"Open Sans Reg", sans-serif';
    chart.baseFont = '"Open Sans Reg", sans-serif';
    chart.baseFontColor = "#1c1c1c";
    chart.baseFontSize = fontSize;
    chart.chartRightMargin = "0";
    if (windowWidth > 2500) {
      chart.chartLeftMargin = "40";
    } else if (windowWidth >= 1025 && windowWidth <= 2500) {
      chart.chartLeftMargin = "14";
    } else if (windowWidth < 1025) {
      chart.chartLeftMargin = "15";
    }
    chartDataSource.chart = chart;

    let x1, x2, x3 = '', y1, y2;
    let data: FusionChartData[] = [];
    data.push(new FusionChartData(Math.round(population.percentageOfAge0To9), "0-9"));
    data.push(new FusionChartData(Math.round(population.percentageOfAge10To14), "10-14"));
    data.push(new FusionChartData(Math.round(population.percentageOfAge15To19), "15-19"));
    data.push(new FusionChartData(Math.round(population.percentageOfAge20To29), "20-29"));
    data.push(new FusionChartData(Math.round(population.percentageOfAge30To44), "30-44"));
    data.push(new FusionChartData(Math.round(population.percentageOfAge45To54), "45-54"));
    data.push(new FusionChartData(Math.round(population.percentageOfAge55To64), "55-64"));
    data.push(new FusionChartData(Math.round(population.percentageOfAge65To74), "65-74"));
    data.push(new FusionChartData(Math.round(population.percentageOfAge75OrMore), "75+"));
    chartDataSource.data = data;
    if (windowWidth > 2500) {
      x1 = "$canvasEndX-15";
      x2 = "$canvasEndX+25";
      y1 = "$canvasStartY-15";
      y2 = "$canvasStartY+15";
      x3 = "$canvasStartX-17";
    } else if (windowWidth >= 1025 && windowWidth <= 2500) {
      x1 = "$canvasEndX-15";
      x2 = "$canvasEndX+25";
      y1 = "$canvasStartY-15";
      y2 = "$canvasStartY+15";
      x3 = "$canvasStartX+5";
    } else if (windowWidth < 1025) {
      x1 = "$canvasEndX-15";
      x2 = "$canvasEndX+25";
      y1 = "$canvasStartY-15";
      y2 = "$canvasStartY+15";
      x3 = "$canvasStartX-15";
    }

    let annotations: FusionChartAnnotation = new FusionChartAnnotation();
    let annotationGroup = new FusionChartAnnotationGroup();
    annotationGroup.items.push(new FusionChartAnnotationGroupItem("text", key, x3, "$canvasStartY", "#1c1c1c", "left"));

    annotations.groups.push(annotationGroup);
    chartDataSource.annotations = annotations;

    return chartDataSource;
  }

  static immigrationStatusDataSource(fontSize: string, key: string, cultural: DemographicsCulturalItem, windowWidth: number): FusionChartDataSource {
    const chartDataSource = new FusionChartDataSource();
    if (!cultural) {
      return chartDataSource;
    }
    const chart = new FusionChartLayout();
    chart.numberSuffix = "%";
    chart.paletteColors = "#6f2974,#b17eb4";
    chart.bgColor = "#ffffff";
    chart.showBorder = "0";
    chart.use3DLighting = "0";
    chart.showShadow = "0";
    chart.enableSmartLabels = "0";
    chart.startingAngle = "0";
    chart.showLabels = "0";
    chart.showPercentValues = "1";
    chart.showLegend = "1";
    chart.legendShadow = "0";
    chart.legendBorderAlpha = "0";
    chart.centerLabelBold = "0";
    chart.showTooltip = "";
    chart.decimals = "2";
    chart.captionFontSize = "14";
    chart.subcaptionFontSize = "14";
    chart.subcaptionFontBold = "0";
    chart.chartLeftMargin = "0";
    chart.chartRightMargin = "0";
    chart.chartTopMargin = "0";
    chart.chartBottomMargin = "0";
    chart.showLegend = "0";
    chart.showLabels = "0";
    chart.showValues = "0";
    chart.labelFont = '"Open Sans Reg", sans-serif';
    chart.baseFont = '"Open Sans Reg", sans-serif';
    chart.baseFontColor = "#1c1c1c";
    chart.baseFontSize = fontSize;
    chart.enableSlicing = "0";
    chart.showAlternateHGridColor = "0";
    if (windowWidth > 2500) {
      chart.pieRadius = "70";
    } else if (windowWidth >= 1025 && windowWidth <= 2500) {
      chart.pieRadius = "48";
    } else if (windowWidth < 1025) {
      chart.pieRadius = "46";
    }

    chart.defaultCenterLabel = key;
    chartDataSource.chart = chart;

    let data: FusionChartData[] = [];
    data.push(new FusionChartData(Math.round(cultural.percentageImmigrant), "Immigrant"));
    data.push(new FusionChartData(Math.round(Math.round(cultural.percentageNonImmigrant)), "Non-Immigrant"));
    chartDataSource.data = data;

    return chartDataSource;
  }

  static dominantLanguageDataSource(fontSize: string, key: string, cultural: DemographicsCulturalItem, metadata: DemographicsMetadata[], windowWidth: number): FusionChartDataSource {
    const chartDataSource = new FusionChartDataSource();
    if (!cultural) {
      return chartDataSource;
    }

    const chart = new FusionChartLayout();
    chart.numberSuffix = "%";
    chart.paletteColors = "#6f2974,#87398c,#b17eb4,#cfb0d1";
    chart.bgColor = "#ffffff";
    chart.canvasBorderAlpha = "0";
    chart.usePlotGradientColor = "0";
    chart.plotBorderAlpha = "0";
    chart.showValues = "1";
    chart.placeValuesInside = "0";
    chart.rotatevalues = "0";
    chart.valueFontColor = "#000000";
    chart.showXAxisLine = "0";
    chart.xAxisLineColor = "#ffffff";
    chart.divlineColor = "#ffffff";
    chart.divLineDashed = "0";
    chart.showAlternateHGridColor = "0";
    chart.labelDisplay = "auto";
    chart.showBorder = "0";
    chart.showYAxisValues = "0";
    chart.adjustDiv = "0";
    chart.showVLineLabelBorder = "0";
    chart.showCanvasBorder = "0";
    chart.usePlotGradientColor = "0";
    chart.plotBorderAlpha = "0";
    chart.showXAxisLine = "0";
    chart.divLineDashed = "0";
    chart.divLineAlpha = "0";
    chart.adjustDiv = "0";
    chart.showVLineLabelBorder = "0";
    chart.showBorder = "0";
    chart.showCanvasBorder = "0";
    chart.showAxisLines = "0";
    chart.usePlotGradientColor = "0";
    chart.alignCaptionWithCanvas = "0";
    chart.showAlternateVGridColor = "0";
    chart.labelFont = '"Open Sans Reg", sans-serif';
    chart.baseFont = '"Open Sans Reg", sans-serif';
    chart.baseFontColor = "#1c1c1c";
    chart.baseFontSize = fontSize;
    // chart.canvasRightMargin = "60";
    chart.canvasBottomMargin = "0";
    if (windowWidth > 2500) {
      chart.canvasLeftMargin = "130";
      chart.canvasTopMargin = "25";
    } else if (windowWidth >= 1025 && windowWidth <= 2500) {
      chart.canvasLeftMargin = "87";
      chart.canvasTopMargin = "17";
    } else if (windowWidth < 1025) {
      chart.canvasLeftMargin = "75";
      chart.canvasTopMargin = "13";
    }
    chartDataSource.chart = chart;

    let data: FusionChartData[] = [];
    data.push(new FusionChartData(Math.round(cultural.percentagePrimaryDominantLanguage), DemographicsChartsUtility.getDescriptionOfMetadata(metadata, cultural.primaryDominantLanguageCode)));
    data.push(new FusionChartData(Math.round(cultural.percentageSecondaryDominantLanguage), DemographicsChartsUtility.getDescriptionOfMetadata(metadata, cultural.secondaryDominantLanguageCode)));
    data.push(new FusionChartData(Math.round(cultural.percentageThirdDominantLanguage), DemographicsChartsUtility.getDescriptionOfMetadata(metadata, cultural.thirdDominantLanguageCode)));
    data.push(new FusionChartData(Math.round(cultural.percentageFourthDominantLanguage), DemographicsChartsUtility.getDescriptionOfMetadata(metadata, cultural.fourthDominantLanguageCode)));
    data.push(new FusionChartData(Math.round(cultural.percentageFifthDominantLanguage), DemographicsChartsUtility.getDescriptionOfMetadata(metadata, cultural.fifthDominantLanguageCode)));

    chartDataSource.data = data;

    let x1 = '', y1 = '';
    if (windowWidth > 2500) {
      x1 = "$canvasStartX-75";
      y1 = "$canvasStartY-17";
    } else if (windowWidth >= 1025 && windowWidth <= 2500) {
      x1 = "$canvasStartX-59";
      y1 = "$canvasStartY-11";
    } else if (windowWidth < 1025) {
      x1 = "$canvasStartX-66";
      y1 = "$canvasStartY-9";
    }
    let annotations: FusionChartAnnotation = new FusionChartAnnotation();
    let annotationGroup = new FusionChartAnnotationGroup();
    annotationGroup.items.push(new FusionChartAnnotationGroupItem("text", key, x1, y1, "#1b1b1b", "left"));

    annotations.groups.push(annotationGroup);
    chartDataSource.annotations = annotations;
    return chartDataSource;
  }

  static getDescriptionOfMetadata(metadata: DemographicsMetadata[], field: string): string {
    if (!metadata || !Array.isArray(metadata) || metadata.length < 1 || !field) {
      return '';
    }
    const foundItem = metadata.find(value => value.variable == field);
    return foundItem ? foundItem.description : '';
  }


  static employmentStatusDataSource(fontSize: string, key: string, socioEconomic: DemographicsSocioEconomicItem, metadata: DemographicsMetadata[], windowWidth: number): FusionChartDataSource {
    const chartDataSource = new FusionChartDataSource();
    if (!socioEconomic) {
      return chartDataSource;
    }
    const chart = new FusionChartLayout();
    chart.numberSuffix = "%";
    chart.paletteColors = "#0ca59e,#c2e8e7,#85d2ce,#c2e8e7";
    chart.bgColor = "#ffffff";
    chart.showBorder = "0";
    chart.use3DLighting = "0";
    chart.showShadow = "0";
    chart.enableSmartLabels = "0";
    chart.startingAngle = "0";
    chart.showLabels = "0";
    chart.showPercentValues = "1";
    chart.showLegend = "1";
    chart.legendShadow = "0";
    chart.legendBorderAlpha = "0";
    chart.centerLabelBold = "0";
    chart.showTooltip = "1";
    chart.decimals = "0";
    chart.captionFontSize = "13";
    chart.subcaptionFontSize = "13";
    chart.subcaptionFontBold = "0";
    chart.showLegend = "0";
    chart.showLabels = "0";
    chart.showValues = "0";
    chart.labelFont = '"Open Sans Reg", sans-serif';
    chart.baseFont = '"Open Sans Reg", sans-serif';
    chart.baseFontColor = "#1c1c1c";
    chart.baseFontSize = fontSize;
    chart.enableSlicing = "0";
    chart.defaultCenterLabel = key;

    if (windowWidth > 2500) {
      chart.pieRadius = "70";
    } else if (windowWidth >= 1025 && windowWidth <= 2500) {
      chart.pieRadius = "48";
    } else if (windowWidth < 1025) {
      chart.pieRadius = "46";
    }
    chartDataSource.chart = chart;

    let data: FusionChartData[] = [];
    data.push(new FusionChartData(Math.round(socioEconomic.percentageOfEmploymentRate), "Employed"));
    data.push(new FusionChartData((100 - Math.round(socioEconomic.percentageOfEmploymentRate)), "Unemployed"));
    chartDataSource.data = data;

    return chartDataSource;
  }

  static educationStatusDataSource(fontSize: string, key: string, socioEconomic: DemographicsSocioEconomicItem, windowWidth: number): FusionChartDataSource {
    const chartDataSource = new FusionChartDataSource();
    if (!socioEconomic) {
      return chartDataSource;
    }
    const chart = new FusionChartLayout();
    chart.numberSuffix = "%";
    chart.paletteColors = "#10514e,#0ca59e,#85d2ce,#c2e8e7";
    chart.bgColor = "#ffffff";
    chart.showBorder = "0";
    chart.use3DLighting = "0";
    chart.showShadow = "0";
    chart.enableSmartLabels = "0";
    chart.startingAngle = "310";
    chart.showLabels = "0";
    chart.showPercentValues = "1";
    chart.showLegend = "1";
    chart.legendShadow = "0";
    chart.legendBorderAlpha = "0";
    chart.centerLabelBold = "0";
    chart.showTooltip = "1";
    chart.decimals = "0";
    chart.captionFontSize = "14";
    chart.subcaptionFontSize = "14";
    chart.subcaptionFontBold = "0";
    chart.chartLeftMargin = "0";
    chart.chartRightMargin = "0";
    chart.chartTopMargin = "0";
    chart.chartBottomMargin = "0";
    chart.showLegend = "0";
    chart.showLabels = "0";
    chart.showValues = "0";
    chart.labelFont = '"Open Sans Reg", sans-serif';
    chart.baseFont = '"Open Sans Reg", sans-serif';
    chart.baseFontColor = "#1c1c1c";
    chart.baseFontSize = fontSize;
    chart.enableSlicing = "0";
    chart.defaultCenterLabel = key;
    if (windowWidth > 2500) {
      chart.pieRadius = "70";
    } else if (windowWidth >= 1025 && windowWidth <= 2500) {
      chart.pieRadius = "48";
    } else if (windowWidth < 1025) {
      chart.pieRadius = "46";
    }
    chartDataSource.chart = chart;

    let data: FusionChartData[] = [];
    data.push(new FusionChartData(Math.round(Math.round(socioEconomic.percentageWithUniversityDegree)), "University"));
    data.push(new FusionChartData(Math.round(Math.round(socioEconomic.percentageWithCollegeDiploma)), "College"));
    data.push(new FusionChartData(Math.round(Math.round(socioEconomic.percentageWithHighSchoolDiploma)), "High School"));
    data.push(new FusionChartData(Math.round(100 - Math.round(socioEconomic.percentageWithUniversityDegree) - Math.round(socioEconomic.percentageWithCollegeDiploma) - Math.round(socioEconomic.percentageWithHighSchoolDiploma)),
      "Other"));
    chartDataSource.data = data;

    return chartDataSource;
  }


  static ownershipStatusDataSource(fontSize: string, key: string, houseHold: DemographicsHouseHoldItem, windowWidth: number): FusionChartDataSource {
    const chartDataSource = new FusionChartDataSource();
    if (!houseHold) {
      return chartDataSource;
    }

    const chart = new FusionChartLayout();
    chart.xaxismaxvalue = "100";
    chart.yaxismaxvalue = "100";
    chart.formatnumberscale = "0";
    chart.numberSuffix = "%";
    chart.paletteColors = "#003b7d,#8cafd5";
    chart.bgColor = "#ffffff";
    chart.borderAlpha = "20";
    chart.showCanvasBorder = "0";
    chart.usePlotGradientColor = "0";
    chart.plotBorderAlpha = "0";
    chart.legendBorderAlpha = "0";
    chart.legendShadow = "0";
    chart.formatnumberscale = "0";
    chart.valueFontColor = "#ffffff";
    chart.showXAxisLine = "0";
    chart.xAxisLineColor = "#999999";
    chart.divlineColor = "#999999";
    chart.divLineDashed = "0";
    chart.divLineAlpha = "0";
    chart.showAlternateVGridColor = "0";
    chart.subcaptionFontBold = "0";
    chart.subcaptionFontSize = "14";
    chart.showHoverEffect = "1";
    chart.labelDisplay = "none";
    chart.showBorder = "0";
    chart.showLabels = "0";
    chart.showValues = "0";
    chart.showYAxisValues = "0";
    chart.adjustDiv = "0";
    chart.showVLineLabelBorder = "0";
    chart.plotSpacePercent = "50";
    chart.placeValuesInside = "0";
    chart.chartLeftMargin = "0";
    chart.chartRightMargin = "0";
    chart.chartTopMargin = "0";
    chart.chartBottomMargin = "0";
    chart.labelFont = '"Open Sans Reg", sans-serif';
    chart.baseFont = '"Open Sans Reg", sans-serif';
    chart.baseFontSize = fontSize;
    chart.baseFontColor = "#1c1c1c";
    chart.canvasTopMargin = "0";
    chart.canvasBottomMargin = "0";
    if (windowWidth > 2500) {
      chart.canvasLeftMargin = "191";
      chart.canvasRightMargin = "80";
    } else if (windowWidth >= 1025 && windowWidth <= 2500) {
      chart.canvasLeftMargin = "127";
      chart.canvasRightMargin = "53";
    } else if (windowWidth < 1025) {
      chart.canvasLeftMargin = "96";
      chart.canvasRightMargin = "45";
    }
    chartDataSource.chart = chart;

    let x1 = '', x2 = '', x3 = '';
    if (windowWidth > 2500) {
      x1 = "$canvasStartX - 178";
      x2 = "$canvasStartX - 63";
      x3 = "$canvasEndX + 64";
    } else if (windowWidth >= 1025 && windowWidth <= 2500) {
      x1 = "$canvasStartX - 117";
      x2 = "$canvasStartX - 41";
      x3 = "$canvasEndX + 42";
    } else if (windowWidth < 1025) {
      x1 = "$canvasStartX - 95";
      x2 = "$canvasStartX - 33";
      x3 = "$canvasEndX + 34";
    }
    let annotations: FusionChartAnnotation = new FusionChartAnnotation();
    let annotationGroup = new FusionChartAnnotationGroup();

    if (Math.round(houseHold.percentageOwned) + Math.round(houseHold.percentageRented) == 100) {

      chartDataSource.dataset = [
        new FusionChartDataset([new FusionChartDatasetData(Math.round(houseHold.percentageOwned))]),
        new FusionChartDataset([new FusionChartDatasetData(Math.round(houseHold.percentageRented))])
      ];
      annotationGroup.items.push(new FusionChartAnnotationGroupItem("text", key, x1, "$dataset.0.set." + 0 + ".y", "#1b1b1b", "left"));
      annotationGroup.items.push(new FusionChartAnnotationGroupItem("text", Math.round(houseHold.percentageOwned) + "%", x2, "$dataset.0.set." + 0 + ".y", "#1b1b1b", "left"));
      annotationGroup.items.push(new FusionChartAnnotationGroupItem("text", Math.round(houseHold.percentageRented) + "%", x3, "$dataset.0.set." + 0 + ".y", "#1b1b1b", "right"));
    } else {
      chartDataSource.dataset = [
        new FusionChartDataset([new FusionChartDatasetData(Math.round(houseHold.percentageOwned - 0.01))]),
        new FusionChartDataset([new FusionChartDatasetData(Math.round(houseHold.percentageRented - 0.01))])
      ];
      annotationGroup.items.push(new FusionChartAnnotationGroupItem("text", key, x1, "$dataset.0.set." + 0 + ".y", "#1b1b1b", "left"));
      annotationGroup.items.push(new FusionChartAnnotationGroupItem("text", Math.round(houseHold.percentageOwned - 0.01) + "%", x2, "$dataset.0.set." + 0 + ".y", "#1b1b1b", "left"));
      annotationGroup.items.push(new FusionChartAnnotationGroupItem("text", Math.round(houseHold.percentageRented + 0.01) + "%", x3, "$dataset.0.set." + 0 + ".y", "#1b1b1b", "right"));
    }

    annotations.groups.push(annotationGroup);
    chartDataSource.annotations = annotations;
    chartDataSource.categories = [new FusionChartCategory([new FusionChartCategoryData(key)])];

    return chartDataSource;
  }


  static homeAgeStatusDataSource(fontSize: string, key: string, houseHold: DemographicsHouseHoldItem, windowWidth: number): FusionChartDataSource {
    const chartDataSource = new FusionChartDataSource();
    if (!houseHold) {
      return chartDataSource;
    }
    const chart = new FusionChartLayout();
    chart.numberSuffix = "%";
    chart.paletteColors = "#003b7d,#3f79b9,#8cafd5,#b2c9e3";
    chart.bgColor = "#ffffff";
    chart.canvasBorderAlpha = "0";
    chart.usePlotGradientColor = "0";
    chart.plotBorderAlpha = "0";
    chart.placeValuesInside = "0";
    chart.rotatevalues = "0";
    chart.valueFontColor = "#000000";
    chart.showXAxisLine = "0";
    chart.xAxisLineColor = "#999999";
    chart.divlineColor = "#999999";
    chart.divLineDashed = "0";
    chart.showAlternateHGridColor = "0";
    chart.labelDisplay = "AUTO";
    chart.showBorder = "0";
    chart.showYAxisValues = "0";
    chart.adjustDiv = "0";
    chart.showVLineLabelBorder = "0";
    chart.showCanvasBorder = "0";
    chart.usePlotGradientColor = "0";
    chart.plotBorderAlpha = "0";
    chart.showXAxisLine = "0";
    chart.divLineDashed = "0";
    chart.divLineAlpha = "0";
    chart.adjustDiv = "0";
    chart.showVLineLabelBorder = "0";
    chart.labelFont = '"Open Sans Reg", sans-serif';
    chart.baseFont = '"Open Sans Reg", sans-serif';
    chart.baseFontSize = fontSize;
    chart.baseFontColor = "#1c1c1c";
    chart.canvasRightMargin = "0";
    chart.canvasTopMargin = "0";
    chart.canvasBottomMargin = "0";
    if (windowWidth > 2500) {
      chart.canvasLeftMargin = "100";
    } else if (windowWidth >= 1025 && windowWidth <= 2500) {
      chart.canvasLeftMargin = "68";
    } else if (windowWidth < 1025) {
      chart.canvasLeftMargin = "68";
    }
    chartDataSource.chart = chart;

    let x1 = '', y1 = '';
    if (windowWidth > 2500) {
      x1 = "$canvasStartX-56";
      y1 = "$canvasStartY-8";
    } else if (windowWidth >= 1025 && windowWidth <= 2500) {
      x1 = "$canvasStartX-31";
      y1 = "$canvasStartY-6";
    } else if (windowWidth < 1025) {
      x1 = "$canvasStartX-57";
      y1 = "$canvasStartY-4";
    }

    let data: FusionChartData[] = [];
    data.push(new FusionChartData(Math.round(houseHold.ageOfHomeGroup1Total), houseHold.ageofhomegroup1LABEL));
    data.push(new FusionChartData(Math.round(houseHold.ageOfHomeGroup2Total), houseHold.ageofhomegroup2LABEL));
    data.push(new FusionChartData(Math.round(houseHold.ageOfHomeGroup3Total), houseHold.ageofhomegroup3LABEL));
    data.push(new FusionChartData(Math.round(houseHold.ageOfHomeGroup4Total), houseHold.ageofhomegroup4LABEL));
    chartDataSource.data = data;

    let annotations: FusionChartAnnotation = new FusionChartAnnotation();
    let annotationGroup = new FusionChartAnnotationGroup();
    annotationGroup.items.push(new FusionChartAnnotationGroupItem("text", key, x1, y1, "#1b1b1b", "left"));
    annotations.groups.push(annotationGroup);
    chartDataSource.annotations = annotations;

    return chartDataSource;
  }

  static structuralTypeDataSource(fontSize: string, key: string, houseHold: DemographicsHouseHoldItem, windowWidth: number): FusionChartDataSource {
    const chartDataSource = new FusionChartDataSource();
    if (!houseHold) {
      return chartDataSource;
    }
    const chart = new FusionChartLayout();
    chart.numberSuffix = "%";
    chart.paletteColors = "#003b7d,#3f79b9,#9fbcdc,#cfdded,#e2ebf5";
    chart.bgColor = "#ffffff";
    chart.canvasBorderAlpha = "0";
    chart.usePlotGradientColor = "0";
    chart.plotBorderAlpha = "0";
    chart.placeValuesInside = "0";
    chart.rotatevalues = "0";
    chart.valueFontColor = "#000000";
    chart.showXAxisLine = "0";
    chart.xAxisLineColor = "#ffffff";
    chart.divlineColor = "#ffffff";
    chart.divLineDashed = "0";
    chart.showAlternateHGridColor = "0";
    chart.labelDisplay = "AUTO";
    chart.showBorder = "0";
    chart.showYAxisValues = "0";
    chart.adjustDiv = "0";
    chart.showVLineLabelBorder = "0";
    chart.showCanvasBorder = "0";
    chart.usePlotGradientColor = "0";
    chart.plotBorderAlpha = "0";
    chart.showXAxisLine = "0";
    chart.divLineDashed = "0";
    chart.divLineAlpha = "0";
    chart.adjustDiv = "0";
    chart.showVLineLabelBorder = "0";
    chart.baseFontColor = "#1c1c1c";
    chart.showBorder = "0";
    chart.showCanvasBorder = "0";
    chart.showAxisLines = "0";
    chart.usePlotGradientColor = "0";
    chart.alignCaptionWithCanvas = "0";
    chart.showAlternateVGridColor = "0";
    chart.labelFont = '"Open Sans Reg", sans-serif';
    chart.baseFont = '"Open Sans Reg", sans-serif';
    chart.baseFontSize = fontSize;
    chart.baseFontColor = "#1c1c1c";
    // chart.canvasRightMargin = "60";
    chart.canvasBottomMargin = "0";
    if (windowWidth > 2500) {
      chart.canvasLeftMargin = "130";
      chart.canvasTopMargin = "25";
    } else if (windowWidth >= 1025 && windowWidth <= 2500) {
      chart.canvasLeftMargin = "87";
      chart.canvasTopMargin = "17";
    } else if (windowWidth < 1025) {
      chart.canvasLeftMargin = "69";
      chart.canvasTopMargin = "13";
    }
    chartDataSource.chart = chart;
    let x1 = '', y1 = '';
    if (windowWidth > 2500) {
      x1 = "$canvasStartX-120";
      y1 = "$canvasStartY-17";
    } else if (windowWidth >= 1025 && windowWidth <= 2500) {
      x1 = "$canvasStartX-79";
      y1 = "$canvasStartY-11";
    } else if (windowWidth < 1025) {
      x1 = "$canvasStartX-91";
      y1 = "$canvasStartY-9";
    }

    let data: FusionChartData[] = [];
    data.push(new FusionChartData(Math.round(houseHold.percentageOfApartment), "Apartment"));
    data.push(new FusionChartData(Math.round(houseHold.percentageOfSingleDetached), "Single Detached"));
    data.push(new FusionChartData(Math.round(houseHold.percentageOfSemiDetached), "Semi-Detached"));
    data.push(new FusionChartData(Math.round(houseHold.percentageOfMoveableDwelling), "Movable",));
    data.push(new FusionChartData(Math.round(houseHold.percentageOfRowHouses), "Row House"));
    chartDataSource.data = data;

    let annotations: FusionChartAnnotation = new FusionChartAnnotation();
    let annotationGroup = new FusionChartAnnotationGroup();
    annotationGroup.items.push(new FusionChartAnnotationGroupItem("text", key, x1, y1, "#1b1b1b", "left"));
    annotations.groups.push(annotationGroup);
    chartDataSource.annotations = annotations;

    return chartDataSource;
  }

  static getTestDataForStackedbar2d(): FusionChartDataSource {
    const chartDataSource = new FusionChartDataSource();

    const chart = new FusionChartLayout()

    chart.dataEmptyMessage = "No Data Available.";
    chart.xaxismaxvalue = "100";
    chart.yaxismaxvalue = "100";
    chart.formatnumberscale = "0";
    chart.numberSuffix = "%";
    chart.paletteColors = "#322d88,#afadd3";
    chart.bgColor = "#ffffff";
    chart.borderAlpha = "20";
    chart.showCanvasBorder = "0";
    chart.usePlotGradientColor = "0";
    chart.plotBorderAlpha = "0";
    chart.legendBorderAlpha = "0";
    chart.legendShadow = "0";
    chart.formatnumberscale = "0";
    chart.valueFontColor = "#ffffff";
    chart.showXAxisLine = "0";
    chart.xAxisLineColor = "#999999";
    chart.divlineColor = "#999999";
    chart.divLineDashed = "0";
    chart.divLineAlpha = "0";
    chart.showAlternateVGridColor = "0";
    chart.subcaptionFontBold = "0";
    chart.subcaptionFontSize = "14";
    chart.showHoverEffect = "1";
    chart.labelDisplay = "none";
    chart.showBorder = "0";
    chart.showLabels = "0";
    chart.showValues = "0";
    chart.showYAxisValues = "0";
    chart.adjustDiv = "0";
    chart.showVLineLabelBorder = "0";
    chart.plotSpacePercent = "50";
    chart.placeValuesInside = "0";
    chart.chartLeftMargin = "0";
    chart.chartRightMargin = "0";
    chart.chartTopMargin = "0";
    chart.chartBottomMargin = "0";
    chart.labelFont = '"Open Sans Reg", sans-serif';
    chart.baseFont = '"Open Sans Reg", sans-serif';
    chart.baseFontSize = '12';
    chart.baseFontColor = "#1c1c1c";
    chart.canvasTopMargin = "0";
    chart.canvasBottomMargin = "0";

    chartDataSource.categories = [new FusionChartCategory([new FusionChartCategoryData('Qa')])];


    chartDataSource.dataset = [{
      //"seriesname": "Water",
      "data": [{
        "value": 121000
      }
      ]
    },
      {
        //"seriesname": "Solids",
        "data": [{
          "value": 131400
        }
        ]
      }
    ];
    return chartDataSource;
  }
}
