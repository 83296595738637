import {PdfReportRequestReportConfig} from "./pdf-report-request-report-config";
import {PdfReportCircleRequest} from "./pdf-report-circle-request";
import {BaseModel} from "../base-model";
import {PdfReportClientReportConfiguration} from "./input-param/pdf-report-client-report-configuration";
import {ComparableSalesRequest} from "../comparables/comparable-sales-request";
import {PdfReportRequestUtil} from "./pdf-report-request-util";


export class PdfReportRequestCompsRequest extends BaseModel {

  constructor(pin?: string, arn?: string) {
    super();
    if (pin) {
      this.pin = pin;
    }
    if (arn) {
      this.arn = arn;
    }
    this.reportConfig = new PdfReportRequestReportConfig();
    this.circleRequest = new ComparableSalesRequest();
  }

  pin: string;
  arn: string;
  ids: number[];

  reportConfig: PdfReportRequestReportConfig;
  circleRequest: ComparableSalesRequest | PdfReportCircleRequest | null;
  latLongRequest: ComparableSalesRequest | PdfReportCircleRequest | null;
  polygonRequest: ComparableSalesRequest | null;
  address: string;

  loadFromClientReportConfig(reportParam: PdfReportClientReportConfiguration) {
    this.reportConfig.reportTitle = reportParam.reportTitle;
    this.reportConfig.uom = reportParam.uom;
    this.address = reportParam.propertyInfo.propertyDescription;
    PdfReportRequestUtil.setComparableSalesReportConfig(this.reportConfig, reportParam);
    if (reportParam.areaSalesInfo.pdfReportCircleRequest) {
      this.circleRequest = reportParam.areaSalesInfo.pdfReportCircleRequest;
    }
  }
}
