import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { HeaderComponent } from "./component/header/header/header.component";
import { FooterComponent } from "./component/footer/footer/footer.component";
import { OmnibarSearchComponent } from "./component/omnibar/omnibar/omnibar-search.component";
import { MaterialModule } from "./material.module";
import { FontAwesomeModule2 } from './fontawesome.module';
import { PageNotFoundComponent } from "./component/page-not-found/page-not-found.component";
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ConfirmDialogComponent } from './component/modal/confirm-dialog/confirm-dialog.component';
import { MatDialogModule } from "@angular/material/dialog";
import { RouterModule } from "@angular/router";
import { SafeUrlPipe } from '../shared/pipe/safe-pipe';
import { PybReportStatusComponent } from './component/modal/pyb-report-status/pyb-report-status/pyb-report-status.component';
import { NeedAssistanceComponent } from './component/need-assistance/need-assistance/need-assistance.component';
import { StreetViewDialogComponent } from './component/modal/street-view-dialog/street-view-dialog.component';
import { SvgIconComponent } from './component/svg-icon/svg-icon.component';
import { AddressCorrectionDialogComponent } from './component/modal/address-correction-dialog/address-correction-dialog.component';
import { SelectedPropertyDialogComponent } from './component/modal/selected-property-dialog/selected-property-dialog.component';
import { InsightsReportDialogComponent } from './component/modal/insights-report-dialog/insights-report-dialog.component';
import { WarningDialogComponent } from './component/modal/warning-dialog/warning-dialog.component';
import { ResidentialRelocationAdditionalInfoDialogComponent } from './component/modal/residential-relocation-additional-info-dialog/residential-relocation-additional-info-dialog.component';
import { FusionChartsNumberPipe } from "../shared/pipe/fusion-charts-number.pipe";
import { BaseChartComponent } from './component/base-chart/base-chart.component';
import { NaPipe } from "../shared/pipe/na.pipe";
import { SubscriptionExpiredDialogComponent } from './component/modal/subscription-expired-dialog/subscription-expired-dialog.component';
import { SafeHtmlPipe } from "../shared/pipe/safe-html.pipe";
import { ImageDialogComponent } from './component/modal/image-dialog/image-dialog.component';
import { LoadingSpinnerDialogComponent } from './component/modal/loading-spinner-dialog/loading-spinner-dialog.component';
import { AboutDialogComponent } from './component/about/about-dialog.component';
import { LongDateOrNaPipe } from "../shared/pipe/long-date-or-na.pipe";
import { TooltipModule as NG2TooltipModule } from 'ng2-tooltip-directive';
import { TickerTapeComponent } from './component/message-center/ticker-tape/ticker-tape.component';
import { NgxNewstickerAlbeModule } from 'ngx-newsticker-albe';
import { UserMessagesComponent } from './component/message-center/user-messages/user-messages.component';
import { VoiceSearchComponent } from './component/voice-search/voice-search.component';
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { SatPopoverModule } from '@ncstate/sat-popover';
import { TooltipComponent } from './component/tooltip/tooltip.component';
import { FullscreenOverlayContainer, OverlayContainer } from "@angular/cdk/overlay";
import { RecentResultComponent } from './component/omnibar/recent-result/recent-result.component';
import {SingleCatalogueProductComponent} from "./component/catalogue-product/single-catalogue-product/single-catalogue-product.component";
import {MultiCatalogueProductComponent} from "./component/catalogue-product/multi-catalogue-product/multi-catalogue-product.component";
import {BaseCatalogueProductComponent} from "./component/catalogue-product/base-catalogue-product.component";
import {ProgressBarDialogComponent} from "./component/modal/progress-bar-dialog/progress-bar-dialog.component";
import {ProgressBarModule} from "primeng/progressbar";
import {AssessmentSelectionDialogComponent} from "./component/modal/assessment-selection-dialog/assessment-selection-dialog.component";
import {ComparablesReportSectionsPrint} from "./component/modal/comparables-report-sections-print/comparables-report-sections-print";
import {PanelModule} from "primeng/panel";
import {FieldsetModule} from "primeng/fieldset";
import {ComparablesReportAdd} from "./component/modal/comparables-report-add/comparables-report-add";
import {ComparablesReportSelectReport} from "./component/modal/comparables-report-select-report/comparables-report-select-report";
import {ProgressBarWithTimeoutDialogComponent} from "./component/modal/progress-bar-with-timeout-dialog/progress-bar-with-timeout-dialog.component";

@NgModule({
  declarations: [
    HeaderComponent,
    OmnibarSearchComponent,
    RecentResultComponent,
    FooterComponent,
    PageNotFoundComponent,
    ConfirmDialogComponent,
    SafeUrlPipe,
    PybReportStatusComponent,
    NeedAssistanceComponent,
    StreetViewDialogComponent,
    AddressCorrectionDialogComponent,
    SelectedPropertyDialogComponent,
    InsightsReportDialogComponent,
    WarningDialogComponent,
    ProgressBarDialogComponent,
    ProgressBarWithTimeoutDialogComponent,
    ResidentialRelocationAdditionalInfoDialogComponent,
    FusionChartsNumberPipe,
    BaseChartComponent,
    NaPipe,
    SubscriptionExpiredDialogComponent,
    SafeHtmlPipe,
    ImageDialogComponent,
    LoadingSpinnerDialogComponent,
    AboutDialogComponent,
    LongDateOrNaPipe,
    TickerTapeComponent,
    UserMessagesComponent,
    VoiceSearchComponent,
    TooltipComponent,
    BaseCatalogueProductComponent,
    SingleCatalogueProductComponent,
    MultiCatalogueProductComponent,
    AssessmentSelectionDialogComponent,
    ComparablesReportSectionsPrint,
    ComparablesReportAdd,
    ComparablesReportSelectReport
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    FontAwesomeModule2,
    HttpClientModule,
    SharedModule,
    ReactiveFormsModule,
    BrowserModule,
    MatDialogModule,
    RouterModule,
    NG2TooltipModule,
    NgxNewstickerAlbeModule,
    MatSlideToggleModule,
    SvgIconComponent,
    SatPopoverModule,
    ProgressBarModule,
    PanelModule,
    FieldsetModule
  ],
    exports: [
        HeaderComponent,
        OmnibarSearchComponent,
        RecentResultComponent,
        FooterComponent,
        PageNotFoundComponent,
        SafeUrlPipe,
        NeedAssistanceComponent,
        StreetViewDialogComponent,
        SharedModule,
        FusionChartsNumberPipe,
        NaPipe,
        SafeHtmlPipe,
        LongDateOrNaPipe,
        TickerTapeComponent,
        UserMessagesComponent,
        VoiceSearchComponent,
        FontAwesomeModule2,
        SatPopoverModule,
        TooltipComponent,
        SingleCatalogueProductComponent,
        MultiCatalogueProductComponent
    ],
  providers:[{provide: OverlayContainer, useClass: FullscreenOverlayContainer}]
})
export class CoreModule {
}
