export enum PropertyDetailSectionEnum {
  PROPERTY_DETAILS = 'Property Details',
  PROPERTY_DETAILS_MAPS = 'Property Details Maps',
  CONDO_DETAILS = 'Condo Details',
  SITE_STRUCTURE = 'Site & Structure',
  ENHANCED_SITE_STRUCTURE = 'Enhanced Site & Structure',
  VALUATION_SALES = 'Valuation & Sales',
  PLANS_SURVEYS_EASEMENTS = 'Plans, Surveys & Easements',
  RESIDENTIAL_RELOCATION = 'Residential Relocation',
  HOODQ = 'HoodQ™',
  DEMOGRAPHICS = 'Demographics'
}
