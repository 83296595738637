@if (demographicsData.prizmSegment) {
  <div class="no-print">
    <div class="demographics-header">
      <span id="dg-dmg-label">
        Dominant Market Group
      </span>
    </div>
    <div class="flex flex-row pt-4">
      <div class="flex md:w-1/2 md:pl-8">
        <div class="">
          <img id="dg-dmg-pdf-icon" (click)="downloadPrizmPdf()" alt=""
            class="display-priszm5-right-pdf-img" src="/assets/img/pdf-blue.png">
            <a id="dg-link-dmg-prizm" class="anchor" (click)="downloadPrizmPdf()" >Prizm Group #{{prizmName}}</a>
          </div>
        </div>
        <div class="flex md:w-1/2">
          <div class="display-priszm5-left-content">
            <h3 id="dg-h3-dmg-source">SOURCE</h3>
            <div id="dg-dmg-desc">Prizm is the latest release of the pioneering
              segmentation system from Environics Analytics that classifies
              Canada's neighbourhoods into 68 unique lifestyle&nbsp;types.
            </div>
          </div>
        </div>
      </div>
    </div>
  }
