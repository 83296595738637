import {EStoreProduct} from "../product/e-store/e-store-product";

export class ProductCarousel {

  constructor(productCarousel?: ProductCarousel) {
    if (productCarousel) {

      productCarousel.enhanced_site_structure?.forEach(ess => {
        this.enhanced_site_structure.push(new EStoreProduct(ess));
      });

      productCarousel.hoodq?.forEach(ess => {
        this.hoodq.push(new EStoreProduct(ess));
      });

      productCarousel.buyer_mobility?.forEach(bm => {
        this.buyer_mobility.push(new EStoreProduct(bm));
      });

      productCarousel.valuation_sales?.forEach(vs => {
        this.valuation_sales.push(new EStoreProduct(vs));
      });

      productCarousel.plans_surveys_easements?.forEach(pse => {
        this.plans_surveys_easements.push(new EStoreProduct(pse));
      });

      productCarousel.site_structure?.forEach(ss => {
        this.site_structure.push(new EStoreProduct(ss));
      });

      productCarousel.plans_surveys?.forEach(ps => {
        this.plans_surveys.push(new EStoreProduct(ps));
      });
      productCarousel.prop_details?.forEach(pd => {
        this.prop_details.push(new EStoreProduct(pd));
      });
    }
  }

  enhanced_site_structure: EStoreProduct[] = [];
  hoodq: EStoreProduct[] = [];
  buyer_mobility: EStoreProduct[] = [];
  valuation_sales: EStoreProduct[] = [];
  plans_surveys_easements: EStoreProduct[] = [];
  site_structure: EStoreProduct[] = [];
  plans_surveys: EStoreProduct[] = [];
  prop_details: EStoreProduct[] = [];
}
