import {Component, Input} from '@angular/core';
import {icons} from "./svg-icon-path";

@Component({
  selector: 'gema3g-svg-icon',
  templateUrl: './svg-icon.component.html',
  standalone: true,
  styleUrls: ['./svg-icon.component.scss']
})
export class SvgIconComponent {
  @Input() icon: string;
  @Input() width?: number;
  @Input() height?: number;
  @Input() size?: number = 24;
  @Input() fill?: string;
  @Input() class?: string;

  ngOnInit(): void{
    if (!this.width || !this.height) {
      this.width = this.size;
      this.height = this.size;
    }
  }

  get path() {
    if(icons.hasOwnProperty(this.icon)){
      // @ts-ignore
      return icons[this.icon];
    }
    return 'M1 14 L5 10 L13 18 L27 4 L31 8 L13 26 z'; // returns a check by default
  }

}
