import {Component, inject, Input} from '@angular/core';
import {UserAccessControl} from "../../../../core/model/user/user-access-control";
import {UserService} from "../../../../shared/service/user.service";
import {ComparableSalesReportComparableProperty} from "../../../../core/model/comparables/report/comparable-sales-report-comparable-property";
import {ComparableSalesReportSubjectArn} from "../../../../core/model/comparables/report/comparable-sales-report-subject-arn";

@Component({
  selector: 'gema3g-comparables-property-detail-header',
  standalone: true,
  imports: [],
  templateUrl: './comparables-property-detail-header.component.html',
  styleUrl: './comparables-property-detail-header.component.scss'
})
export class ComparablesPropertyDetailHeaderComponent {
  private userService = inject(UserService);
  protected userAccessControls: UserAccessControl = this.userService.getUserAccessControl();

  @Input() propertyDetail: ComparableSalesReportSubjectArn;
}
