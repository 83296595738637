import {HttpClient} from '@angular/common/http';
import {baseUrl} from './system';
import {inject, Injectable} from '@angular/core';
import {catchError, map, Observable, of, throwError} from 'rxjs';
import * as _ from 'lodash';
import {PinListStaticStreetView, SignedImageData} from '../../core/model/map/pinlist-static-streetview';
import {LoggerService} from './log/logger.service';

@Injectable({
  providedIn: 'root'
})

export class ComparablesService {
  private https = inject(HttpClient);
  private loggerService = inject(LoggerService);

  getStaticStreetViewForMultiplePins = (pinList: string[], width: number, height: number, type: string, fov: number, defaultValueIfNotFound?: string): Observable<PinListStaticStreetView> => {
    if (!Array.isArray(pinList) || pinList.length == 0) {
      return of(new PinListStaticStreetView());
    }

    const url = `${baseUrl}/property/map/imagelist?pinList=${pinList.join(",")}&width=${width}&height=${height}&type=${type}&fov=${fov}&intnl=1`;

    return this.https.get<{ [key: string]: SignedImageData }>(url)
      .pipe(
        map(response => {
          const result = new PinListStaticStreetView(pinList, defaultValueIfNotFound);
          if (!_.isEmpty(response)) {
            Object.entries(response).forEach(([key, value]) => {
              result.data.set(key, value);
            });
          }
          return result;
        }),
        catchError(err => {
          this.loggerService.logError(`error getting static streetview image for pin list ${pinList}`);
          return of(new PinListStaticStreetView(pinList, defaultValueIfNotFound));
        })
      );
  }

  //the backend will not return the signed image url when product id 610 is off
  getStaticStreetView = (pin: string, width: number, height: number, type: string, fov: number, defaultValueIfNotFound?: string): Observable<string> => {
    return this.getStaticStreetViewForMultiplePins([pin], width, height, type, fov, defaultValueIfNotFound)
      .pipe(
        map(pinListStaticStreetView => {
          const firstItem = pinListStaticStreetView.data.values().next().value;
          return firstItem ? firstItem.imageUrl : defaultValueIfNotFound;
        })
      );
  }
}
