export enum PropertyReportSectionEnum {
    SUMMARY = 'SUMMARY',
    REGISTRY = 'REGISTRY',
    CONDO_DETAILS = 'CONDO_DETAILS',
    ENHANCED_SITE_AND_STRUCTURE = 'ENHANCED_SITE_AND_STRUCTURE',
    SITE_AND_STRUCTURE = 'SITE_AND_STRUCTURE',
    VALUATION_AND_SALES = 'VALUATION_AND_SALES',
    PLANS_AND_SURVEYS = 'PLANS_AND_SURVEYS',
    RESIDENTIAL_RELOCATION = 'RESIDENTIAL_RELOCATION',
    HOODQ = 'HOODQ',
    DEMOGRAPHICS = 'DEMOGRAPHICS'
}