import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from "../../shared/service/authentication.service";
import { Router } from "@angular/router";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { lastValueFrom, takeUntil } from "rxjs";
import { UrlService } from "../../shared/service/url.service";
import { UserService } from "../../shared/service/user.service";
import { defaultErrorMatSnackBarConfig } from "../../shared/constant/constants";
import { LoggerService } from '../../shared/service/log/logger.service';
import { BaseUnsubscribe } from '../../core/component/base-unsubscribe/base-unsubscribe';
import { User } from '../../core/model/user/user';

@Component({
  selector: 'gema3g-legal-agreement',
  templateUrl: './legal-agreement.component.html',
  styleUrls: ['./legal-agreement.component.scss']
})
export class LegalAgreementComponent extends BaseUnsubscribe implements OnInit {

  constructor(private authService: AuthenticationService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private userService: UserService,
    private urlService: UrlService,
    private loggerService: LoggerService) {

    super();
  }

  inProgress: boolean = false;
  attemptsNo: number = 3;
  loggedInBeId: number;

  logout() {
    this.decline();
  }

  decline() {
    this.loggerService.logInfo(`user ${this.loggedInBeId} has declined the legal agreement.`);
    this.authService.setTermsAndConditionsAccepted(false);
    this.authService.logoutWithoutRedirect();
    this.urlService.goToMarketingSitePage();
  }

  async accept() {
    this.inProgress = true;
    try {

      let accept = await lastValueFrom(this.authService.acceptLegalAgreement());
      if (accept) {
        //reload the user
        let user = await lastValueFrom(this.userService.getUser(true));
        if (user.isLegalAgreementAccepted) {
          this.loggerService.logInfo(`user ${user.businessEntityId} has accepted the legal agreement.`);
          this.authService.setTermsAndConditionsAccepted(true);
        } else {
          throw new Error(`unable to confirm whether user ${user.businessEntityId} has accepted the legal agreement.`);
        }
      }
    } catch (e) {
      this.authService.setTermsAndConditionsAccepted(false);
      this.errorHandler();
    }
    finally {
      this.inProgress = false;
    }
  }

  errorHandler() {
    this.attemptsNo--;
    if (this.attemptsNo > 0) {
      this.openSnackBarError(`An error occurred, please try again. ${this.attemptsNo} attempt${(this.attemptsNo > 1) ? "s" : ""} left.`)
    } else {
      this.openSnackBarError('The system is unreachable, please try again after some time. Will be logged out shortly.');
      setTimeout(() => {
        this.decline();
      }, 7000);
    }
  }

  openSnackBarError(msg: string) {
    this._snackBar.open(msg, 'Close', defaultErrorMatSnackBarConfig);
  }

  async ngOnInit() {
    this.loggedInBeId = this.userService.user.businessEntityId;

    this.authService.termsAndConditionsAccepted$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((accepted) => {
        if (accepted) {
          this.urlService.goToInitializeUserProfilePage();
        }
      });

    const user: User = await lastValueFrom(this.userService.getUser(), { defaultValue: new User() });
    if (user.isLegalAgreementAccepted) {
      if (user.isProfileAccepted) {
        this.loggerService.logInfo(`user ${this.loggedInBeId} has already accepted the legal agreement.`);
        this.urlService.goToLandingPage();
      } else {
        this.loggerService.logInfo(`redirecting user ${this.loggedInBeId} to the user profile initialization page.`);
        this.urlService.goToInitializeUserProfilePage();
      }
    }
  }
}
