import {PropertyDetailSectionEnum} from "../../../../core/enum/property-detail-section-enum";

export class PropertyDetailSectionMenuDefinition {
  propertyDetailSection : PropertyDetailSectionEnum
  selected: boolean;

  constructor(propertyDetailSection : PropertyDetailSectionEnum, isSelected: boolean) {
    this.propertyDetailSection = propertyDetailSection;
    this.selected = isSelected;
  }

  get title(): string{
    return this.propertyDetailSection?.toString();
  }
}
