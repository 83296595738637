export class AddressCorrectionRequestCustomFields {

  constructor(id: number, value: string) {
    this.id = id;
    this.value = value;
  }

  id: number;
	value: string;
}
export class AddressCorrectionRequestComment {
	body: string;
	uploads: any[];
}
export class AddressCorrectionRequestRequester {
	name: string;
	email: string;
	locale_id: number;
}
export class AddressCorrection {
	request: AddressCorrectionRequest;
}

export class AddressCorrectionRequest {
  subject: string;
  tags: string[];
  via_id: number;
  ticket_form_id: number;
  custom_fields: AddressCorrectionRequestCustomFields[] = [];
  comment: AddressCorrectionRequestComment;
  requester: AddressCorrectionRequestRequester;
}
