import {MatSelectItem} from "../../../core/model/mat-select-item/mat-select-item";

export enum GenericPreferenceLayerType {
  OWNERSHIP_PARCEL = 'OWNERSHIP_PARCEL',
  ASSESSMENT_PARCELS = 'ASSESSMENT_PARCELS',
  STREET_NUMBERS = 'STREET_NUMBERS',
  LOT_CONCESSIONS = 'LOT_CONCESSIONS',
  LRO_MUNICIPALITY = 'LRO_MUNICIPALITY',
  THEMATIC_LAYERS = 'THEMATIC_LAYERS'
}


export const GenericPreferenceLayerDisplay = (Object.keys(GenericPreferenceLayerType) as Array<keyof typeof GenericPreferenceLayerType>)
  .map((enumKey) => {
    switch (enumKey) {
      case "OWNERSHIP_PARCEL":
        return {
          key: enumKey,
          label: 'Ownership Parcels',
          description: 'Display ownership parcel boundaries.'
        }
      case "ASSESSMENT_PARCELS":
        return {
          key: enumKey,
          label: 'Assessment Parcels',
          description: 'Display assessment parcel boundaries.'
        }
      case "STREET_NUMBERS":
        return {
          key: enumKey,
          label: 'Street Numbers',
          description: 'Display address street numbers.'
        }
      case "LOT_CONCESSIONS":
        return {
          key: enumKey,
          label: 'Lot / Concession',
          description: 'Display Lot/Concession boundaries'
        }
      case "LRO_MUNICIPALITY":
        return {
          key: enumKey,
          label: 'LRO / Municipality',
          description: 'Display Land Regional Office and Municipality boundary lines.'
        }
      case "THEMATIC_LAYERS":
        return {
          key: enumKey,
          label: 'Ownership Parcels',
          description: 'Display ownership parcel boundaries.'
        }
      default:
        return {}

    }
  });

export const SearchMethods: MatSelectItem[] = [
  {
    value: 'SEARCH_ALL',
    viewValue: 'Search All'
  },
  {
    value: 'SEARCH_NAME',
    viewValue: 'Name'
  },
  {
    value: 'SEARCH_INSTRUMENT_PLAN',
    viewValue: 'Instrument / Plan'
  },
  {
    value: 'SEARCH_LOT_CON',
    viewValue: 'Lot & Concession'
  }
];
