import { AfterViewInit, Component, inject, OnInit } from '@angular/core';
import { lastValueFrom, takeUntil } from "rxjs";
import { EstoreProductCategoryEnum } from "../../../enum/estore-product-category-enum";
import { UserActivity } from "../../../model/user/user-activity";
import { SelectedPropertyDialogComponent } from "../../modal/selected-property-dialog/selected-property-dialog.component";
import { PropertyDetail } from "../../../model/property/property-detail";
import { UserService } from "../../../../shared/service/user.service";
import { User } from "../../../model/user/user";
import { baseUrl } from "../../../../shared/service/system";
import { MainMapService } from "../../../../home/home/main-map/main-map.service";
import { PropertyDetailSectionEnum } from "../../../enum/property-detail-section-enum";
import { BaseCatalogueProductComponent } from "../base-catalogue-product.component";
import { TILE_INSTRUMENT_IMAGE_PROD_ID, TILE_PLAN_IMAGE_PROD_ID } from "../../../../shared/constant/constants";
import { FileUtility } from "../../../../shared/utility/file.utility";
import { SelectedProperty } from '../../../../core/model/property/selected-property';

@Component({
  selector: 'gema3g-single-catalogue-product',
  templateUrl: './single-catalogue-product.component.html',
  styleUrls: ['./single-catalogue-product.component.scss']
})
export class SingleCatalogueProductComponent extends BaseCatalogueProductComponent implements OnInit, AfterViewInit {
  renewalPrice: number;
  licenseAvailableForRenew: boolean;
  earlyRenewalPrice: number;
  GWHsubspnEndDate: number;

  private userService = inject(UserService);
  private mainMapService = inject(MainMapService);
  private fileUtility = inject(FileUtility);

  ngAfterViewInit(): void {
    this.loadSubscriptionRenewalData();
  }

  ngOnInit(): void {
    this.eStoreService.getPropertyInsightsReportRunnerObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((productId) => {
        if (productId > -1 && productId + "" == this.product.prodId) {
          setTimeout(async () => {
            await this.runReportWithSelectedProperty(EstoreProductCategoryEnum.TERANET_INSIGHTS);
          }, 100);
        }
      });

    this.eStoreService.getResidentialRelocationReportTimeFramePrompterObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((productId) => {
        if (productId > -1 && productId + "" == this.product.prodId) {
          setTimeout(async () => {
            await this.runReportWithSelectedProperty(EstoreProductCategoryEnum.RESIDENTIAL_RELOCATION);
          }, 100);
        }
      });

    this.isButtonDisabled = this.eStoreService.isProductNotAccessible(this.product);
  }

  async loadSubscriptionRenewalData() {
    if (this.product?.category == EstoreProductCategoryEnum.GWH_RENEW_SUBSCRIPTION) {
      const user: User = await lastValueFrom(this.userService.getUser());
      this.renewalPrice = user.licenses["GWH_RENEWAL"].renewalPrice;
      this.licenseAvailableForRenew = user.licenses["GWH_RENEWAL"].licenseAvailableForRenew;
      this.GWHsubspnEndDate = user.licenses["GWH_RENEWAL"].licenseEndDate;
      this.renewalPrice = user.licenses["GWH_RENEWAL"].renewalPrice;
      this.earlyRenewalPrice = user.licenses["GWH_RENEWAL"].earlyRenewalPrice;
      // use this to force testing on 'Most popular' horizontal ribbon
      //this.product.pricedesc.desc = "Most popular";
    }
  }

  buttonClicked() {
    if (this.product.prodId === TILE_INSTRUMENT_IMAGE_PROD_ID) {
      this.navigateToHome();
    } else if (this.product.prodId === TILE_PLAN_IMAGE_PROD_ID || (this.product.title && this.product.title.toUpperCase() === 'PROPERTY SURVEY IMAGE')) {
      // Property Survey Image can be one of product id 601, 602, 603 or 604
      this.navigateToPlansAndSurvey();
    } else {
      this.runReport();
    }
  }

  navigateToHome = async () => {
    if (this.selectedProperty) {
      const pin = this.selectedProperty.pin;
      await this.router.navigate(['/home'], {
        queryParams: {
          pin: pin,
          showMapOnly: true
        }
      }).then(async () => {
        const propertyDetail: PropertyDetail = await lastValueFrom(this.propertyReportSearchService.getPropertyDetailByPin(pin));
        const markerBounds = new google.maps.LatLngBounds();

        setTimeout(() => {
          const piiMarker = this.mainMapService.renderPIIPropertyMarker(propertyDetail.pii, false, false);
          if (piiMarker?.getPosition()) {
            markerBounds.extend(<google.maps.LatLng>piiMarker.getPosition());
            this.mainMapService.setMapCenter(<google.maps.LatLng>piiMarker.getPosition());
          }
        }, 800);
      });
    }
  }

  propertySelectedPostAction = async (selectedProperty: SelectedProperty) => {
    if (this.selectedProperty?.isSelected) {
      const pin = this.selectedProperty.pin;
      this.propertyReportService.scrolltoSectionPanel(PropertyDetailSectionEnum.PLANS_SURVEYS_EASEMENTS);

      await this.router.navigate(['/home'], {
        queryParams: {
          pin: pin
        }
      });
    }
  }

  navigateToPlansAndSurvey = () => {
    if (this.selectedProperty?.isSelected) {
      this.propertySelectedPostAction(this.selectedProperty);
    } else {
      this.openPropertyDialog(this.propertySelectedPostAction);
    }
  }

  openPropertyDialog(postPropertySelectedCallback: any) {
    let categoryType = this.product.category;
    this.gaService.openModal('SelectedPropertyDialogComponent');
    const dialogRef = this.dialog.open(SelectedPropertyDialogComponent, {data: this.recentProperties})
      .afterClosed()
      .subscribe(async (resp: UserActivity) => {
        this.loggerService.logDebug(resp);
        if (resp instanceof UserActivity) {
          let propertyDetail: PropertyDetail;
          if (resp.pin && resp.pin != "N/A") {
            propertyDetail = await lastValueFrom(this.propertyReportSearchService.getPropertyDetailByPin(resp.pin));
          } else {
            propertyDetail = await lastValueFrom(this.propertyReportSearchService.getPropertyDetailByArn(resp.text));
          }
          this.propertyReportService.updateSubjectProperty(propertyDetail);

          if (postPropertySelectedCallback) {
            postPropertySelectedCallback(this.selectedProperty);
          }

        } else if (resp == "CLOSE_STORE") {
          await this.router.navigate(['../home']);
        }
      });
  }

  getProFormaInvoice() {
    const quantity = isNaN(this.product?.licenseCount) ? 1 : this.product.licenseCount;
    if (this.product.cta.deeplink == "GWHCompLicenseSubscriptionRenewal")
      window.location.assign(`${baseUrl}/user/accountstatement/pdf?type=Multiple&quantity=${quantity}&lpId=${this.product.licensePackageId}`);
    else
      window.location.assign(`${baseUrl}/user/accountstatement/pdf?type=Single&quantity=1&lpId=${this.product.licensePackageId}`);
  }

  downloadSample(url: string) {
    this.fileUtility.downloadFileFromUrl(url);
  }
}
