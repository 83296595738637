import {PinOrArn} from "../../property/pin-or-arn";
import {BaseModel} from "../../base-model";
import dayjs from "dayjs";
import {ComparablesReportSelectReport} from "../../../component/modal/comparables-report-select-report/comparables-report-select-report";

export class ComparableSalesReportRecentReport extends BaseModel{

  constructor(csrr?: ComparableSalesReportRecentReport) {
    super(csrr);
    if (csrr?.subjectPinArns) {
      this.subjectPinArns = csrr.subjectPinArns.map(item => new PinOrArn(item.pin, item.arn));
    }
    if (csrr?.recentReports) {
      this.recentReports = csrr.recentReports.map(item => new RecentReport(item));
    }
  }
  subjectPinArns: PinOrArn[];
  recentReports: RecentReport[];
}

export class RecentReport extends BaseModel{
  reportId: number;
  reportName: string;
  modifiedDate: number;
  clientName: string | null;
  jobNumber: string | null;
  comments: string | null;
  numberOfComparables: number;
  subjectPin: string | null;
  address: string;

  //UI only
  get reportRadioButtonLabel() : string {
    return `${this.reportName} - ${this.address} - ${dayjs(this.modifiedDate).format('DD-MMM-YYYY')} - (${this.numberOfComparables -1} of 20 pins)`;
  }
}
