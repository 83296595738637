import {BaseModel} from "../base-model";

export class CondoDetail extends BaseModel{

    condoType: string;
    condoTypeVal: string;
    level: string;
    unit: number;
    pin: string;
}
