import { Component, inject, ViewChild } from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import { TransactionHistory } from "../../../core/model/transaction/transaction-history";
import { UserService } from "../../../shared/service/user.service";
import { EstoreService } from "../../../shared/service/estore.service";
import { lastValueFrom } from "rxjs";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { LoggerService } from '../../../shared/service/log/logger.service';

@Component({
  selector: 'gema3g-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss']
})
export class PaymentHistoryComponent {
  isLoading: boolean = true;
  displayedColumns: string[] = ['date', 'price', 'numberOfProducts', 'productDetails', 'transactionId'];
  dataSource = new MatTableDataSource<TransactionHistory>();
  items: TransactionHistory[];
  pageEvent: Promise<void>;
  pageIndex: number;
  pageSize: number;
  length: number = 0;
  pageSizes = [5, 10, 20];
  sortDirection: string = 'desc';
  sortField: string = '';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor() {
    setTimeout(() => {
      this.getReport('', null);
    }, 100);
  }

  private userService = inject(UserService);
  private eStoreService = inject(EstoreService);
  private loggerService = inject(LoggerService);

  async getReport(source: string, $event: any) {
    this.loggerService.logInfo($event);

    if (source == 'sort') {
      this.sortDirection = !$event.direction ? '' : $event.direction;
      this.sortField = !$event.direction ? '' : $event.active;
    }

    this.isLoading = true;
    try {
      const transactionHistoryResult = await lastValueFrom(this.userService.getTransactionReportHistory(this.paginator.pageIndex, this.paginator.pageSize, this.sortField, this.sortDirection));
      this.items = [];
      this.items.push(...transactionHistoryResult.transactionHistory.map(value => {
        return new TransactionHistory(value);
      }));
      this.dataSource = new MatTableDataSource(this.items);
      this.length = transactionHistoryResult.totalItems;
    } finally {
      this.isLoading = false;
    }
  }

  async redirectToGWHInvoicePage(transactionId: string | undefined) {
    await this.eStoreService.redirectToGWHInvoicePage(transactionId);
  }
}
