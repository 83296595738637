import {BaseModel} from "../../base-model";
import {ComparableSalesReportStructureAssessments} from "./comparable-sales-report-structure-assessments";
import {ComparableSalesReportLandAssessment} from "./comparable-sales-report-land-assessment";
import {ComparableSalesReportShoppingCenter} from "./comparable-sales-report-shopping-center";
import {ComparableSalesReportIncomeData} from "./comparable-sales-report-income-data";

export class ComparableSalesReportPropertyAssessment extends BaseModel{

  constructor(comparableSalesReportPropertyAssessment? : ComparableSalesReportPropertyAssessment) {
    super(comparableSalesReportPropertyAssessment);

    if(comparableSalesReportPropertyAssessment) {
      this.structureAssessments =
        comparableSalesReportPropertyAssessment.structureAssessments?.map((comp: any) => new ComparableSalesReportStructureAssessments(comp));
      this.landAssessments =
        comparableSalesReportPropertyAssessment.landAssessments?.map((comp: any) => new ComparableSalesReportLandAssessment(comp));
      this.calculateExtremumStructureAssessments();
      this.incomeData = new ComparableSalesReportIncomeData(comparableSalesReportPropertyAssessment.incomeData);
      this.shoppingCentreData = new ComparableSalesReportShoppingCenter(comparableSalesReportPropertyAssessment.shoppingCentreData);
    }
  }
  propertyTypeCode: string;
  propertyTypeDescription: string;
  assessmentRollNumber: string;
  currentValue: number;
  destinationValue: number;
  depth: number;
  frontage: number;
  siteArea: number;
  assessmentCurrentYear: number;
  assessmentEndYear: number;
  assessmentStartYear: number;
  actualFrontageUom: string;
  actualDepthUom: string;
  actualSiteAreaUom: string;
  actualUnitOfMeasure: string;
  streetNumber: string;
  streetName: string;
  streetType: string;
  streetDirection: string | null;
  unitNo: string;
  legalDescription: string;
  address: string;
  sales: any;
  yearBuilt: any;
  propertyTypeDescClass: any;
  municipality: string;
  spatialInformation: any;
  valuePerAreaUnit: any;
  industrial: boolean;
  lastSale: any;
  commercial: boolean;
  corporationName: string;
  mailingAddress: string;
  zoningCode: string;
  basementArea: number;
  mezzanineArea: number;
  interiorOfficeArea: number;
  basementOfficeArea: number;
  mezzanineOfficeArea: number;
  totalArea: number;
  additionalArea: number;
  oldestYearBuilt: number;
  largestYearBuilt : number;

  structureAssessments: ComparableSalesReportStructureAssessments[];
  landAssessments: ComparableSalesReportLandAssessment[];

  shoppingCentreData: ComparableSalesReportShoppingCenter;
  incomeData: ComparableSalesReportIncomeData;

  get firstLandAssessment(): ComparableSalesReportLandAssessment | null {
    return this.landAssessments ? this.landAssessments[0] : null;
  }

  // displayOnly

  private calculateExtremumStructureAssessments(): void {
    if (this.structureAssessments?.length) {
      this.structureMinHeight = this.structureAssessments.reduce((min, struct) => Math.min(min, struct.height), this.structureAssessments[0].height);
      this.structureMaxHeight = this.structureAssessments.reduce((max, struct) => Math.max(max, struct.height), this.structureAssessments[0].height);
      this.structureMaxInteriorOfficeArea = this.structureAssessments.reduce((max, struct) => Math.max(max, struct.interiorOfficeArea), this.structureAssessments[0].interiorOfficeArea);
    }
  }
  structureMinHeight: number;
  structureMaxHeight: number;
  structureMaxInteriorOfficeArea: number;
}
