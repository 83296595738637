import { Centroid } from "../property/centroid";
import {Polygon} from "../property/polygon";

export class Spatial {
  constructor(spatial?: Spatial) {
    if(spatial){
      if (spatial.centroid){
        this.centroid = new Centroid(spatial.centroid);
      }
      if(Array.isArray(spatial.polygon)){
        this.polygon = spatial.polygon.map(value => {return new Polygon(value)});
      }
    }
  }

  centroid: Centroid;
  polygon: Polygon[];
}


