import { PortalModule } from "@angular/cdk/portal";

export class LroPolygonMap {
    lroId: string;
    polygon: google.maps.Polygon

    constructor(lroId: string, polygon: google.maps.Polygon) {
        this.lroId = lroId;
        this.polygon = polygon;
    }
}