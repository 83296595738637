<gema3g-property-report-layout [propertyReportSection]="propertyReportSection" [legend]="legend">
  <section class="pr-shutter-middle-body-full sm:pr-shutter-middle-body">
    @if (!demographicsData.geoCodes) {
      <div class="flex flex-col">
        <div id="dg-na-label" class="pr-no-data-gray-background">
          {{noDemographicsData}}
        </div>
      </div>
    } @else {
      <div class="toggle">
        <mat-label id="dg-label-display" class="pt-1 pr-1.5">Display</mat-label>
        <mat-button-toggle-group
          id="dg-button-toggle-group-ds"
          class="button-toggle-group no-print"
          [(ngModel)]="selectedArea"
          multiple
          name="demographics-selection"
        >
          <mat-button-toggle id="dg-button-toggle-nbh" class="button-toggle" [checked] = "selectedArea.includes(demographicsAreaEnum.NBH)" [disabled] = "!selectedArea.includes(demographicsAreaEnum.COM) && !selectedArea.includes(demographicsAreaEnum.CITY)" value="{{demographicsAreaEnum.NBH}}">
            {{demographicsAreaEnum.NBH}}
          </mat-button-toggle>
          <mat-button-toggle id="dg-button-toggle-com" class="button-toggle" [checked] = "selectedArea.includes(demographicsAreaEnum.COM)" [disabled] = "!selectedArea.includes(demographicsAreaEnum.NBH) && !selectedArea.includes(demographicsAreaEnum.CITY)" value="{{demographicsAreaEnum.COM}}">
            {{demographicsAreaEnum.COM}}
          </mat-button-toggle>
          <mat-button-toggle id="dg-button-toggle-city" class="button-toggle" [checked] = "selectedArea.includes(demographicsAreaEnum.CITY)" [disabled] = "!selectedArea.includes(demographicsAreaEnum.NBH) && !selectedArea.includes(demographicsAreaEnum.COM)" value="{{demographicsAreaEnum.CITY}}">
            {{demographicsAreaEnum.CITY}}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div class="flex flex-col">
        <div>
          <gema3g-demographics-population [demographicsData]="demographicsData" [selectedArea]="selectedArea" ></gema3g-demographics-population>
          <gema3g-demographics-household [demographicsData]="demographicsData" [selectedArea]="selectedArea"></gema3g-demographics-household>
          <gema3g-demographics-socio-economic [demographicsData]="demographicsData" [selectedArea]="selectedArea"></gema3g-demographics-socio-economic>
          <gema3g-demographics-cultural [demographicsData]="demographicsData" [selectedArea]="selectedArea"></gema3g-demographics-cultural>
          <gema3g-demographics-dominant-market-group [demographicsData]="demographicsData" [selectedArea]="selectedArea"></gema3g-demographics-dominant-market-group>
        </div>
      </div>
    }
  </section>
</gema3g-property-report-layout>
