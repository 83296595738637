export class StringUtility {

  public static TOKENS_TO_BE_REMOVED_FROM_SEARCH = ['://', ']', '['];

  public static safeAppend(base: string, append: string, prefix: string = '', suffix: string = ''): string {
    if (append != null && append != undefined) {
      return `${base}${prefix}${append}${suffix}`;
    }
    return base;
  }

  public static safeNumber(value: any): number {
    if(value) {
      return Number(value.toString().replace(/\D/g, ""));
    }
    return 0;
  }

  public static sanitizeSearchInput(input: string): string {
    if(input) {
      const tokens = this.TOKENS_TO_BE_REMOVED_FROM_SEARCH;
      let shortestIndex = input.length;
      let foundToken = false;
      tokens.forEach(token => {
        const tokenIndex = input.indexOf(token);
        if (tokenIndex !== -1 && tokenIndex < shortestIndex) {
          shortestIndex = tokenIndex;
          foundToken = true;
          // clean token from input
          input = input.replace(token, '');
        }
      });
      if (foundToken) {
        return this.sanitizeSearchInput(input.substring(shortestIndex));
      }
    }
    return input;
  }
}
