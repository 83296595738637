import {PinOrArn} from "../../../model/property/pin-or-arn";
import {PropertyDetail} from "../../../model/property/property-detail";
import {ComparableSalesResultPayload} from "../../../model/comparables/comparable-sales-result-payload";


export class GeneratePdfReportDialogData{
  constructor(propertyDetail: PropertyDetail, openFromComparablesReport: boolean = false, comparableSales?: ComparableSalesResultPayload) {
    if(propertyDetail) {
      if(propertyDetail.pii?.pin){
        this.pinOrArn = new PinOrArn(propertyDetail.pii?.pin,'');
      } else {
        this.pinOrArn = new PinOrArn('', propertyDetail.pii?.arn);
      }

    } else {
      this.pinOrArn = new PinOrArn('','');
    }
    this.propertyDetail = propertyDetail;
    this.openFromComparablesReport = openFromComparablesReport;
    if(comparableSales){
      this.comparableSales = comparableSales;
    }
  }
  pinOrArn : PinOrArn;
  propertyDetail: PropertyDetail;
  openFromComparablesReport: boolean;
  comparableSales?: ComparableSalesResultPayload;
}
