import { Component } from '@angular/core';

@Component({
  selector: 'gema3g-property-report-highlight',
  templateUrl: './highlight.component.html',
  styleUrls: ['./highlight.component.scss']
})
export class HighlightComponent {

}
