import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PdfReportMyPropertyReportConfiguration} from "../../../../../model/pdf-report/input-param/pdf-report-my-property-report-configuration";
import {MatInputModule} from "@angular/material/input";
import {MaterialModule} from "../../../../../material.module";
import {SvgIconComponent} from "../../../../svg-icon/svg-icon.component";


@Component({
  selector: 'gema3g-generate-pdf-report-area-sales-info-section',
  standalone: true,
  imports: [
    MatInputModule,
    MaterialModule,
    SvgIconComponent
  ],
  templateUrl: './generate-pdf-report-area-sales-info-section.component.html',
  styleUrl: './generate-pdf-report-area-sales-info-section.component.scss'
})
export class GeneratePdfReportAreaSalesInfoSectionComponent {

  @Input() inputParam: PdfReportMyPropertyReportConfiguration;
  @Output() openComparableSales = new EventEmitter<boolean>();

  goToComparableSales() {
    this.openComparableSales.emit(true);
  }
}
