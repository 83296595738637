import {ActivityTypeEnum} from "../../enum/activity-type-enum";
import {OmnibarSearchTypeEnum} from "../../enum/omnibar-search-type-enum";
import {BaseModel} from "../base-model";
import {AutoSuggestRecentResult} from "../interface/autosuggest-recent-result";

export class SearchActivity extends BaseModel {
  constructor(result?: AutoSuggestRecentResult) {
    super(result);
  }

  lro: string;
  pin: string;
  text: string;
  type: string;
  url: string;
  categoryType: OmnibarSearchTypeEnum;
  activityType: ActivityTypeEnum;

  cleanBeforeSave() {
    if (this.lro) {
      this.lro = this.lro.trim();
    }
  }
}
