<h1 id="dbg-h1-title" mat-dialog-title>
    Debug Info
</h1>

<mat-dialog-content class="mat-typography">
    <div class="debugger-container">
        <div class="row">
            <div class="label text-os-bold">
                Angular version
            </div>
            <div class="data">
                {{ version }}
            </div>
        </div>

        <div class="row">
            <div class="label text-os-bold">
                Env
            </div>
            <div class="data">
                {{ env }}
            </div>
        </div>

        <div class="row">
            <div class="label text-os-bold">
                Logged in
            </div>
            <div class="data">
                {{ lastLoggedIn }}
            </div>
        </div>
        
        <div class="row">
            <div class="label text-os-bold">
                User
            </div>
            <div class="data">
                {{ userInfo }}
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button id="dbg-btn-close" mat-button mat-dialog-close class="dialog-close-btn" type="button">Close</button>
</mat-dialog-actions>