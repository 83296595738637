import {Component, EventEmitter, Input, Output, OnInit} from '@angular/core';
import {BaseSearchResults} from "../base-search-results";
import {OmnibarSearchService} from "../../../../shared/service/search/omnibar-search.service";
import {SearchResultOrder} from "../search-results-container/search-result-order";
import * as _ from "lodash";
import {OwnerToPropertyMap} from "../../../../core/model/search-result/owner-to-property-map";
import {MainMapService} from "../../main-map/main-map.service";
import {OwnerToPropertyMapItem} from "../../../../core/model/search-result/owner-to-property-map-item";

@Component({
  selector: 'gema3g-owner-search-results',
  templateUrl: './owner-search-results.component.html',
  styleUrls: ['./owner-search-results.component.scss']
})
export class OwnerSearchResultsComponent extends BaseSearchResults implements OnInit{

  sortingOptions = SearchResultOrder.AddressPinDropdownValues;

  constructor(private searchService: OmnibarSearchService,
              private mainMapService: MainMapService
              ) {
    super();
  }

  @Input() ownerToPropertyMap:OwnerToPropertyMap[] = [];
  @Input() isMobileFullScreen: boolean = false;
  @Output() onDivScroll = new EventEmitter<Event>();

  ngOnInit(): void {
  }

  applySorting($event: SearchResultOrder){
    this.ownerToPropertyMap[0].ownerToPropertyMapItems = _.orderBy(this.ownerToPropertyMap[0].ownerToPropertyMapItems, $event.sorting, $event.isReverse ? 'desc' : 'asc');
  }

  onMouseOut(result: OwnerToPropertyMapItem) {
    if(result.pin) {
      this.mainMapService.setMarkerDefault(result.pin);
    }
    result.showExpansionImages = false;
  }

  onMouseOver(result: OwnerToPropertyMapItem) {
    if(result.pin) {
      this.mainMapService.setMarkerSelected(result.pin);
    }
    result.showExpansionImages = true;
  }

  onScroll($event: Event) {
    this.onDivScroll.emit($event);
  }
}
