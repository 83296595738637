<h1 id="spd-h1-title" mat-dialog-title>
  COMPARABLES REPORT - SECTIONS TO PRINT
</h1>
<div class="dialog-content" mat-dialog-content>
  @if (sections) {
    <p-fieldset legend="Subject Property Details" class="mt-12">
      @for (item of sections.subjectPropertySections; track $index) {
        <div>
          <label>
            <input type="checkbox" name="section" [(ngModel)]="item.checked"/> {{ item.label }}
          </label>
        </div>
      }
    </p-fieldset>

    <p-fieldset legend="Comparables">
      @for (item of sections.comparableSections; track $index) {
        <div>
          <label>
            <input type="checkbox" name="section" [(ngModel)]="item.checked"/> {{ item.label }}
          </label>
        </div>
      }
    </p-fieldset>
  } @else {
    Loading ....
  }
</div>

<mat-divider></mat-divider>

<div align="end" mat-dialog-actions>
  <button id="btn-download" mat-button mat-dialog-close class="dialog-accept-btn" cdkFocusInitial color="primary" [mat-dialog-close]='sections'>Download</button>
  <button id="btn-close" mat-button mat-dialog-close class="dialog-close-btn">Close</button>
</div>
