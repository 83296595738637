import {BaseModel} from "../../base-model";

export class ComparableSalesReportStructureAssessments extends BaseModel {
  id: string;
  floorArea: number;
  floorLevel: string;
  height: number;
  yearBuilt: number;
  interiorOfficeArea: number;
}
