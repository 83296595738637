import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, QueryList, ViewChildren } from '@angular/core';
import { PropertyDetail, PropertyZoning, ResidentialStructure } from '../../../../core/model/property/property-detail';
import { PropertyReportSectionEnum } from '../../../../core/enum/property-report-section-enum';
import { PropertyReportComponentBase } from '../property-report-component-base';
import { UserAccessControl } from '../../../../core/model/user/user-access-control';
import { EStoreProduct } from "../../../../core/model/product/e-store/e-store-product";
import { takeUntil } from "rxjs";

@Component({
  selector: 'gema3g-property-report-site-structure',
  templateUrl: './site-structure.component.html',
  styleUrls: ['./site-structure.component.scss']
})
export class SiteStructureComponent extends PropertyReportComponentBase implements OnInit, AfterViewInit {

  constructor() {
    super();
  }

  @ViewChildren('assessmentsRef') assessmentsRef: QueryList<ElementRef>;
  subjectProperty: PropertyDetail;
  @Input() userAccessControls: UserAccessControl;
  @Input() siteStructureCarouselProducts: EStoreProduct[] | undefined;
  @Input() enhancedSiteStructureCarouselProducts: EStoreProduct[] | undefined;
  propertyReportSection = PropertyReportSectionEnum.SITE_AND_STRUCTURE;

  refreshUI = () => {
  }


  initializeSubjectPropertyListener = () => {
    this.propertyReportService.subjectProperty$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((newPropertyDetail) => {
      this.subjectProperty = newPropertyDetail;
      this.refreshUI();
    });
  }

  ngOnInit(): void {
    this.initializeSubjectPropertyListener();
  }

  ngAfterViewInit(): void {
    //this.initializeSubjectPropertyListener();
  }

  scrollToAssessment(index: number) {
    const elemRef = this.assessmentsRef.get(index);
    if (elemRef) {
      // @ts-ignore
      elemRef['element'].nativeElement?.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
  }

}
