import { AfterViewInit, Component, ElementRef, inject, Input, OnDestroy, OnInit, signal, WritableSignal } from "@angular/core";
import { BaseUnsubscribe } from "../base-unsubscribe/base-unsubscribe";
import { skip, takeUntil } from "rxjs";
import { PropertyReportService } from "../../../shared/service/property-report.service";
import { MapService } from "../../../shared/service/map-service";
import { UserService } from "../../../shared/service/user.service";
import { LoggerService } from "../../../shared/service/log/logger.service";
import { UserAccessControl } from "../../model/user/user-access-control";
import { DivVisibility } from "./div-visibility";
import { Polygon } from "../../model/property/polygon";
import { PropertyDetail } from "../../model/property/property-detail";
import { PropertyReportSearchService } from "../../../shared/service/search/property-report-search.service";
import { MainMapService } from "../../../home/home/main-map/main-map.service";
import { PropertyReportMapService } from "../../../home/home/property-report/property-report-map.service";
import { BrowserDetectorService } from "src/app/shared/service/browser-detector.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { defaultMatSnackBarConfig, defaultErrorMatSnackBarConfig } from '../../../shared/constant/constants';
import html2canvas from 'html2canvas';
import { DataService } from "../../../shared/service/data.service";
import { LroPolygonsService } from "../../../shared/service/lro-polygons.service";
import { ScreenManager } from "../../../shared/service/screen-manager.service";
import { ScreenNameEnum } from "../../enum/screen-name.enum";
import { SearchComparablesResultService } from "../../../shared/service/search/search-comparables-result.service";

@Component({
  template: ''
})
export abstract class BaseMap extends BaseUnsubscribe implements OnDestroy, OnInit {

  protected constructor() {
    super();
  }

  protected propertyReportService = inject(PropertyReportService);
  protected mapService = inject(MapService);
  protected userService = inject(UserService);
  protected loggerService = inject(LoggerService);
  protected propertyReportSearchService: PropertyReportSearchService = inject(PropertyReportSearchService);
  protected mainMapService: MainMapService = inject(MainMapService);
  protected propertyReportMapService: PropertyReportMapService = inject(PropertyReportMapService);
  protected _snackBar: MatSnackBar = inject(MatSnackBar);
  protected lroPolygonsService: LroPolygonsService  = inject(LroPolygonsService);
  protected screenManager: ScreenManager = inject(ScreenManager);
  protected searchComparablesResultService: SearchComparablesResultService = inject(SearchComparablesResultService);

  protected userAccessControls: UserAccessControl = this.userService.getUserAccessControl();
  protected googleMap: google.maps.Map;
  protected mapInFullScreen: boolean = false;
  protected listenerAdded: boolean = false;

  protected componentPrefix: string =  'default';
  protected parentMapContainerElementId: string;
  protected mapCopyUINotify: boolean = false;
  protected copyMapTooltip: string = 'Copy map';
  protected copyingMapTooltip: string = 'Copying map...';
  protected copiedMapTooltip: string = 'Copied';
  protected copyMapTooltipCurrent: string = this.copiedMapTooltip;

  protected mapElementRef: ElementRef;

  @Input('subjectProperty') subjectProperty: PropertyDetail;

  get mapButtonsVisibility(): DivVisibility[] {
    return [
      {divId: this.componentPrefix + '-top-right-control-full-map', visibile: 'none', hidden: 'block'},
      {divId: this.componentPrefix + '-top-right-control-exit-map', visibile: 'block', hidden: 'none'},
    ];
  };

  initializeSubjectPropertyListener = () => {
    this.propertyReportService.subjectProperty$
      .pipe(skip(1), takeUntil(this.ngUnsubscribe))
      .subscribe((newPropertyDetail) => {
        this.subjectProperty = newPropertyDetail;
        this.initializeMap();
      });
  }

  initializeMap() {
    this.mainMapService.setMapTwoFingerTouchGesture(this.googleMap);
  }

  fullScreenModeChanged() {
  }

  protected startListenerOnFullScreen(mapElementRef: ElementRef) {
    this.mapElementRef = mapElementRef;

    if (!this.listenerAdded) {
      document.addEventListener('fullscreenchange', this.fullScreenEventHandler);
      document.addEventListener('webkitfullscreenchange', this.fullScreenEventHandler);
      document.addEventListener('mozfullscreenchange', this.fullScreenEventHandler);
      document.addEventListener('MSFullscreenChange', this.fullScreenEventHandler);
      this.listenerAdded = true;
    }
  }

  protected fullScreenEventHandler = () => {
    try {
      this.mapInFullScreen = !!(document.fullscreenElement?.parentElement?.id && document.fullscreenElement?.parentElement?.id === this.mapElementRef?.nativeElement?.id);
      this.mapButtonsVisibility.forEach(div => this.setDisplay(div.divId, this.mapInFullScreen, div.visibile, div.hidden));
      this.fullScreenModeChanged();

      if (!document.fullscreenElement) {  //user exited fullscreen
        this.mainMapService.setMapTwoFingerTouchGesture(this.googleMap);

        if (this.screenManager.isScreenVisible(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS)) {
          this.searchComparablesResultService.adjustSearchResultsMapBounds(null);
        }
      } else {
        this.mainMapService.setMapOneFingerTouchGesture(this.googleMap);
      }

    } catch (ex) {
      this.loggerService.logError(ex);
    }
  }

  protected setDisplay(elementId: string, condition: boolean, trueValue: string, falseValue: string) {
    try {
      if(document.getElementById(elementId)) {
        // @ts-ignore
        document.getElementById(elementId).style.display = condition ? trueValue : falseValue;
      }
    } catch (ex) {
      this.loggerService.logError(ex);
    }
  }

  ngOnInit(): void {
    this.initializeSubjectPropertyListener();
  }

  protected getPolygon(subjectProperty: PropertyDetail): Polygon[] {
    let polygon: Polygon[] = subjectProperty?.pii?.spatial[0]?.polygon;
    if (Array.isArray(polygon) && polygon.length) {
      return polygon;
    }
    const assessmentPolygons = subjectProperty?.assessments[0]?.spatial[0]?.polygon;
    if (assessmentPolygons?.length) {
      return assessmentPolygons;
    }
    const lroPolygons = this.lroPolygonsService.getLroById(subjectProperty?.pii?.lro);
    if (lroPolygons?.length) {
      return lroPolygons.flatMap(lroPolygon => {
        return lroPolygon?.polygon?.map(lroCoordinate => {
          return new Polygon({latitude: lroCoordinate.lat, longitude: lroCoordinate.lng});
        })
      });
    }
    return [];
  }

  protected getMapOptions = async (subjectProperty: PropertyDetail, mapType: string = google.maps.MapTypeId.HYBRID): Promise<google.maps.MapOptions> => {
    let mapOptions: google.maps.MapOptions = {
      zoom: 17,
      mapTypeId: mapType,
      disableDefaultUI: true,
    };

    let centroid = await this.propertyReportSearchService.getCentroidForMap(subjectProperty);
    let lat = centroid.latitude;
    let lng = centroid.longitude;
    let coordinates = new google.maps.LatLng(lat, lng);

    if (lat !== undefined && lng !== undefined) {
      mapOptions.center = coordinates;
    } else {
      const lroPolygons = this.lroPolygonsService.getLroById(subjectProperty?.pii?.lro);
      if(lroPolygons[0]?.centroid){
        mapOptions.center = new google.maps.LatLng(lroPolygons[0]?.centroid.lat, lroPolygons[0]?.centroid.lng);
      } else {
        throw new Error('missing lat/lng coordinates');
      }
    }
    return mapOptions;
  }

  zoomIn() {
    // @ts-ignore
    this.googleMap?.setZoom(this.googleMap.getZoom() + 1);
  }

  zoomOut() {
    // @ts-ignore
    this.googleMap?.setZoom(this.googleMap.getZoom() - 1);
  }

  enterFullMap() {
    this.mainMapService.enterFullScreenMap(this.googleMap);
  }

  exitFullMap() {
    this.mainMapService.exitFullScreenMap();
  }

  /*
    <div class="map-top-right-controls" id="plans-survey-map-top-right-controls">
      <div id="top-right-control-zoom-in" class="zoom-in-black" (click)="zoomIn()">
        <img src="/assets/img/svg/icons/icon_map_zoom_plus_white.svg"/>
      </div>
      <div id="top-right-control-zoom-out" class="zoom-out-black" (click)="zoomOut()">
        <img src="/assets/img/svg/icons/icon_map_zoom_minus_white.svg"/>
      </div>
      <div id="plans-survey-top-right-control-full-map" class="enter-full-map-black" (click)="enterFullMap()"></div>
      <div id="plans-survey-top-right-control-exit-map" class="exit-full-map-black" (click)="exitFullMap()"></div>
    </div>
    */

  addMapControls(isBlack: boolean = false): HTMLDivElement {
    const mainDiv = document.createElement('div');
    mainDiv.id = `${this.componentPrefix}-map-top-right-controls`;
    mainDiv.className = 'map-top-right-controls';

    const copyMapDiv = document.createElement('div');
    copyMapDiv.id = `${this.componentPrefix}-top-right-control-copy-map`;
    copyMapDiv.className = 'copy-map' + (isBlack ? '-black' : '');
    //copyMapDiv.setAttribute('pTooltip', 'copyMapTooltipCurrent');
    //copyMapDiv.setAttribute('tooltipPosition', 'bottom');
    copyMapDiv.addEventListener('click', () => { this.copyMapImageToClipBoard(this.mapCopyUINotify); });
    mainDiv.appendChild(copyMapDiv);

    const zoomInDiv = document.createElement('div');
    zoomInDiv.id = `${this.componentPrefix}-top-right-control-zoom-in`;
    zoomInDiv.className = 'zoom-in' + (isBlack ? '-black' : '');
    zoomInDiv.addEventListener('click', () => { this.zoomIn(); });
    const zoomInImg = document.createElement('img');
    zoomInImg.src = `/assets/img/svg/icons/icon_map_zoom_plus${isBlack ? "_white": ""}.svg`;
    zoomInDiv.appendChild(zoomInImg);
    mainDiv.appendChild(zoomInDiv);

    const zoomOutDiv = document.createElement('div');
    zoomOutDiv.id = `${this.componentPrefix}-top-right-control-zoom-out`;
    zoomOutDiv.className = 'zoom-out' + (isBlack ? '-black' : '');
    zoomOutDiv.addEventListener('click', () => { this.zoomOut(); });
    const zoomOutImg = document.createElement('img');
    zoomOutImg.src = `/assets/img/svg/icons/icon_map_zoom_minus${isBlack ? "_white": ""}.svg`;
    zoomOutDiv.appendChild(zoomOutImg);
    mainDiv.appendChild(zoomOutDiv);

    const enterFullMapDiv = document.createElement('div');
    enterFullMapDiv.id = `${this.componentPrefix}-top-right-control-full-map`;
    enterFullMapDiv.className = 'enter-full-map' + (isBlack ? '-black' : '');
    enterFullMapDiv.addEventListener('click', () => { this.enterFullMap(); });
    mainDiv.appendChild(enterFullMapDiv);

    const exitFullMapDiv = document.createElement('div');
    exitFullMapDiv.id = `${this.componentPrefix}-top-right-control-exit-map`;
    exitFullMapDiv.className = 'exit-full-map' + (isBlack ? '-black' : '');
    exitFullMapDiv.addEventListener('click', () => { this.exitFullMap(); });
    mainDiv.appendChild(exitFullMapDiv);

    return mainDiv;
  }

  private copyMapImageToClipBoard = (mapCopyUINotify: boolean) => {
    try {
      if (this.parentMapContainerElementId) {
        let mapContainer: HTMLElement = document.getElementById(this.parentMapContainerElementId) as HTMLElement;

        if (BrowserDetectorService.isNotFirefox()) {

          if (mapCopyUINotify) {
            this._snackBar.open(DataService.MAP_COPY_STARTED, 'Close', defaultMatSnackBarConfig);
          }

          setTimeout(() => {
            navigator?.permissions
              //@ts-ignore
              ?.query({ name: "clipboard-write" })
              .then(async (result) => {
                if (result.state === "granted") {
                  const type = "image/png";
                  let mapContainer: HTMLElement = document.getElementById(this.parentMapContainerElementId) as HTMLElement;
                  let me = this;

                  html2canvas(mapContainer, {
                    backgroundColor: null,
                    useCORS: true
                  }).then(function (canvas) {
                    canvas.toBlob(function (blob) {
                      navigator.clipboard
                        .write([
                          new ClipboardItem(
                            //@ts-ignore
                            Object.defineProperty({}, blob.type, {
                              value: blob,
                              enumerable: true
                            })
                          )
                        ])
                        .then(function () {
                          me.loggerService.logDebug(`map id ${me.parentMapContainerElementId} copied`);
                          if (mapCopyUINotify) {
                            me._snackBar.open(DataService.MAP_COPY_SUCCESSFUL, 'Close', defaultMatSnackBarConfig);
                          }
                        });
                    });
                  });
                }
              });
          }, 200);
        } else {
          this.loggerService.logDebug(`copying the map is not supported in firefox`);
          if (mapCopyUINotify) {
            this._snackBar.open(DataService.MAP_COPY_BROWSER_NO_SUPPORTED, 'Close', defaultErrorMatSnackBarConfig);
          }
        }
      } else {
        //no map to copy
      }
    } catch (e) {
      if (mapCopyUINotify) {
        this._snackBar.open(DataService.MAP_COPY_FAILED, 'Close', defaultErrorMatSnackBarConfig);
      }
      this.loggerService.logError('map copy failed', e);
    }

  }


}
