export class BaseModel {
  constructor(baseModel?: BaseModel) {
    if (baseModel) {
      for (let property in baseModel) {
        if (baseModel.hasOwnProperty(property)) {
          // @ts-ignore
          this[property] = baseModel[property];
        }
      }
    }
  }
}
