<div class="area-sales">
  <div class="area-sales-row">

    <div class="area-sales-row-col">
      <div id="pdf-mr-as-ht-label" class="area-sales-row-col-item">
        Map/Results
      </div>
      <div class="area-sales-row-col-item">
        <mat-slide-toggle id="pdf-mr-ma-ht-toggle" [(ngModel)]="inputParam.areaSalesInfo.mapResults" color="primary"></mat-slide-toggle>
      </div>
    </div>

    <div class="area-sales-row-col">
      <div id="pdf-mr-as-stat-label" class="area-sales-row-col-item">
        Sales Statistics
      </div>
      <div class="area-sales-row-col-item">
        <mat-slide-toggle id="pdf-mr-as-stat-toggle" [(ngModel)]="inputParam.areaSalesInfo.salesStatistics" color="primary"></mat-slide-toggle>
      </div>
    </div>

    <div class="area-sales-row-col">
    </div>

  </div>

  <mat-divider></mat-divider>
  @if (inputParam.areaSalesInfo.usingDefaultCriteria) {
    <div>Comparable Sales are based on your default criteria.</div>
  } @else {
    <div>Comparable Sales are based on your criteria.</div>
  }
  <div>{{ inputParam.areaSalesInfo.comparableSalesText }}</div>
  @if (inputParam.areaSalesInfo.hasAccessToComparableSales) {
    <div>Want to refine Area Sales included? <a (click)="goToComparableSales();" class="anchor">Search for a Comparable Sales</a></div>
  }
</div>
