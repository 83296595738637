<div class="flex flex-col w-full">
  @if (selectedArea.includes(demographicsAreaEnum.NBH)) {
    <gema3g-marital-status-item [key]="'NBH'"
                                [maritalStatusItem]="demographicsPopulation.PRCDDA">
    </gema3g-marital-status-item>
  }

  @if (selectedArea.includes(demographicsAreaEnum.COM)) {

    <gema3g-marital-status-item [key]="'COM'"
                                [maritalStatusItem]="demographicsPopulation.FSA"
    >
    </gema3g-marital-status-item>
  }

  @if (selectedArea.includes(demographicsAreaEnum.CITY)) {
    <gema3g-marital-status-item [key]="'City'"
                                [maritalStatusItem]="demographicsPopulation.PRCDCSD"
    >
    </gema3g-marital-status-item>

  }
</div>
