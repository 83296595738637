<h1 id="acd-h1-suggest-correction" mat-dialog-title>
  Suggest an Address Correction
</h1>

<div class="dialog-content w-[100%]" mat-dialog-content>
  @if (data) {
    @if (status == 'CollectingData') {
      <form id="acd-form">
        <div>
          <div class='geo-flex-column get-dense-3 remove-margin display:flex flex-direction:column'>
            <div>
              <h4 id="acd-h4-original-address" class="mb-9">Original Address</h4>
              <div id="acd-property-address" class="mb-6">{{data.propertyAddress}}</div>
              <h4 id="acd-h4-pin-or-arn" class="mb-9">{{data.pinOrARNLabel}}</h4>
              <div id="acd-pin-or-arn" class="mb-8">{{data.pinOrARN}}</div>
            </div>
            <mat-form-field id="acd-ff-address" appearance="outline" class="input-field" required>
              <mat-label id="acd-label-suggest-address">Suggested Address*</mat-label>
              <input [(ngModel)]="correctAddress" id="address-correction-correct-address" matInput name="address-correction-correct-address" placeholder="">
            </mat-form-field>
            <mat-form-field id="acd-ff-google-maps" appearance="outline" class="input-field" required>
              <mat-label id="acd-label-google-maps">Link to Google Maps*</mat-label>
              <input pattern={{GMapRegexStr}} [(ngModel)]="linkToGoogleMaps" id="address-correction-link-to-google-maps" matInput name="address-correction-link-to-google-maps" placeholder="">
              <mat-hint id="acd-hint-maps" class="input-hint text-xxs 2xl:text-xs font-medium">
                *Providing a Google Maps link will help confirm your suggestion and decrease the processing time of your request.
              </mat-hint>
            </mat-form-field>
            <mat-form-field id="acd-ff-additional-comments" appearance="outline" class="input-field additional-comments">
              <mat-label id="acd-label-additional-comments">Additional comments</mat-label>
              <textarea [(ngModel)]="additionalComments" id="address-correction-additional-comments" matInput name="address-correction-additional-comments" placeholder=""></textarea>
            </mat-form-field>
            <div>
              <button id="acd-btn-submit-request" (click)="submitRequest()" [disabled]="dataNotValid"
                class="dialog-accept-btn"
                color="primary" mat-raised-button type="button">Submit Correction
              </button>
            </div>
            <div id="acd-report-issue" class="text-xxs 2xl:text-xs font-medium mt-4">
              Need to report different issue? Please send us your <a id="acd-link-feedback" (click)="activateZendesk()" class="anchor">feedback</a>.
            </div>
          </div>
        </div>
      </form>
    }
    @if (status == 'Sending') {
      <div id="acd-spinner" class="spinner">
        Please wait...
        <img id="acd-img-spinner" alt="Loading..." src="assets/img/icon-refresh.gif"/>
      </div>
    }
    @if (status == 'Error') {
      <div id="acd-error-sending-request" class="py-2">There was an error sending your request. Please try again later.</div>
    }
    @if (status == 'Success') {
      <div id="acd-success-message" class="success-message">
        <img id="acd-img-checkmark" class="img-checkmark" src="assets/img/mygeo-icons/checkmark.png"/>
        Your suggestion has been successfully submitted.
      </div>
      <div id="acd-gwh-experience" class="py-8 w-[720px]">Thank you for helping us improve the GeoWarehouse experience.</div>
      <div id="acd-suggestion-sent" class="w-[720px]">
        Your suggestion was sent to the GeoWarehouse Support Team to verify and correct the information. The timeframe for correction can vary so if you are still interested
        in the property, please check GeoWarehouse again in a few weeks.
      </div>
    }
  }
  @if (!data) {
    <span id="acd-error-dialog">There was an error on data passes to this dialog. Please close and try again</span>
  }
</div>

<mat-divider></mat-divider>

<div align="end" mat-dialog-actions>
  <button id="acd-btn-close" mat-button mat-dialog-close class="dialog-close-btn" type="button">Close</button>
</div>