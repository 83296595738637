import * as _ from 'lodash';
import { AfterViewInit, ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { MainMapState } from '../../core/model/spatial/main-map-state';
import { UserService } from '../../shared/service/user.service';
import { User } from '../../core/model/user/user';
import { OmnibarStateService } from '../../shared/service/search/omnibar-state.service';
import { MatDialog } from "@angular/material/dialog";
import { UrlService } from "../../shared/service/url.service";
import { AuthenticationService } from "../../shared/service/authentication.service";
import { SubscriptionExpiredDialogComponent } from "../../core/component/modal/subscription-expired-dialog/subscription-expired-dialog.component";
import { catchError, lastValueFrom, of, skip, Subscription, switchMap, takeUntil, timer } from "rxjs";
import { BaseUnsubscribe } from "../../core/component/base-unsubscribe/base-unsubscribe";
import { RouteMappingUtility } from "../../shared/utility/route-mapping-utility";
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationSkipped, NavigationStart, Router } from "@angular/router";
import { EstoreProductCategoryEnum } from "../../core/enum/estore-product-category-enum";
import { SearchBusyIndicatorService } from '../../shared/service/search/ui/search-busy-indicator.service';
import { LoggerService } from '../../shared/service/log/logger.service';
import { DataService } from "../../shared/service/data.service";
import { ScreenManager } from '../../shared/service/screen-manager.service';
import { ScreenNameEnum } from '../../core/enum/screen-name.enum';
import { MainMapService } from './main-map/main-map.service';
import { MessageCenterService } from "../../shared/service/message-center.service";
import { BannerMessage } from '../../core/model/message-center/banner-message';
import { GoogleAnalyticsService } from "../../shared/service/google-analytics.service";
import { DialogUtil } from "../../shared/service/dialog.util";
import { WarningDialogData } from "../../core/component/modal/warning-dialog/warning-dialog-data";
import { UserAccessControl } from "../../core/model/user/user-access-control";
import { WarningService } from "../../shared/service/warning.service";
import { WarningTypeEnum } from "../../core/enum/warning-type.enum";
import { HomeService } from "../../shared/service/home.service";
import { HomeParam } from "../../core/model/home/home-param";
import { LocalStorageKey } from "../../shared/constant/constants";
import { PropertyReportService } from '../../shared/service/property-report.service';

@Component({
  selector: 'gema3g-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent extends BaseUnsubscribe implements OnInit, AfterViewInit {

  constructor() {
    super();

    this.userAccessControls = this.userService.getUserAccessControl();

    this.route.queryParams
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((params) => {
              const pin = params['pin'];
              const arn = params['arn'];
              const showMapOnly = params['showMapOnly'];

              if (!this.userAccessControls.restrictedAccess) {
                this.refreshPropertyReport(new HomeParam(pin, arn, showMapOnly));
              } else {
                this.router.navigate(['/home'])
              }

          }
      );

    this.homeService.childrenRequest$
      .pipe(skip(1), takeUntil(this.ngUnsubscribe))
      .subscribe((params: HomeParam) => {
        this.refreshPropertyReport(params);
      });

    //Subscribe to router events.
    //'runGuardsAndResolvers' in the routing module must be in place in order to handle routing requests to this component when the user is already in this component.
    //Eg: user is requesting "/home?pin=123" when the current route is already at "/home".
    this.navigationSubscription = this.router.events
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((e: any) => {
        //handle the router event accordingly
        if (e instanceof NavigationStart) {
        } else if (e instanceof NavigationEnd) {
        } else if (e instanceof NavigationSkipped) {
        } else if (e instanceof NavigationCancel) {
        } else if (e instanceof NavigationError) {
        }
    });

  }

  private warningService = inject(WarningService);
  private homeService = inject(HomeService);
  private userService = inject(UserService);
  private omnibarStateService = inject(OmnibarStateService);
  private dialog = inject(MatDialog);
  private authService = inject(AuthenticationService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private searchBusyIndicatorService = inject(SearchBusyIndicatorService);
  private loggerService = inject(LoggerService);
  private mainMapService = inject(MainMapService);
  private dataService = inject(DataService);
  private screenManager = inject(ScreenManager);
  private messageCenterService = inject(MessageCenterService);
  private gaService = inject(GoogleAnalyticsService);
  private propertyReportService = inject(PropertyReportService);

  navigationSubscription;
  loggedInUser: User;
  public hoodQMapVisible: boolean = false;
  mainMapState: MainMapState;
  mainMapSearchInProgress: boolean = false;
  isMapControlsMoved = false;
  bannerMessages: BannerMessage | null;
  userAccessControls: UserAccessControl;
  isMobileFullScreen: boolean = false;

  refreshPropertyReport(params: HomeParam){
    if(params) {
      if (params.pin && this.dataService.isPin(params.pin) && !params.showMapOnly) {
        this.mainMapService.openPropertyReportByPin(params.pin);
      } else if (params.arn && this.dataService.isArn(params.arn) && !params.showMapOnly) {
        this.mainMapService.openPropertyReportByArn(params.arn);
      }
    }
  }

  onHoodQMapOpened = (event: MainMapState) => {
    this.mainMapState = event;
    this.hoodQMapVisible = true;
    this.screenManager.showScreen(ScreenNameEnum.HOODQ);  //keep track of screen visibility
    this.screenManager.closeScreensWhenThisOpened(ScreenNameEnum.HOODQ);
  }

  onHoodQMapClosed = () => {
    this.hoodQMapVisible = false;
    this.screenManager.hideScreen(ScreenNameEnum.HOODQ);  //keep track of screen visibility
  }

  ngOnInit() {
    this.userService.getUser()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user: User) => {
        this.loggedInUser = user;
      });

    this.omnibarStateService.omnibarState
      .pipe(skip(1), takeUntil(this.ngUnsubscribe))
      .subscribe((state) => {
        if (state.searchInitiated) {
          this.onHoodQMapClosed();
        }
      });

    this.searchBusyIndicatorService.mainMapBusyFlag$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((busy) => {
        if (busy != null && busy) {
          this.mainMapSearchInProgress = true;
        } else {
          this.mainMapSearchInProgress = false;
        }
      });

    this.screenManager.getObservableScreen(ScreenNameEnum.SEARCH_COMPARABLES_FORM)!
      .pipe(skip(1), takeUntil(this.ngUnsubscribe))
      .subscribe(visible => {
        if (visible) {
          this.onHoodQMapClosed();
        }
      });

    //get user messages
    let intervalPeriod: number = 1;
    let minutes: number = intervalPeriod * 60 * 1000;

    let bannerMessagesSubscription: Subscription = timer(0, minutes)
      .pipe(skip(1), takeUntil(this.ngUnsubscribe))
      .pipe(
        switchMap(() => {
          return this.messageCenterService.getBannerMessages()
            .pipe(catchError(err => {
              this.loggerService.logWarning(`error getting banner messages for user beid ${this.userService.getBEID()}`);
              return of(null);
            }));
        })
      )
      .subscribe(bannerMessages => {
        this.messageCenterService.setBannerMessages(bannerMessages);
      });

    this.messageCenterService.bannerMessages$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((bannerMessages) => {
        this.bannerMessages = bannerMessages;
      });

    let expiredBannerMessagesSubscription: Subscription = timer(0, minutes)
      .pipe(skip(1), takeUntil(this.ngUnsubscribe))
      .pipe(
        switchMap(() => {
          return this.messageCenterService.getExpiredBannerMessages()
            .pipe(catchError(err => {
              this.loggerService.logWarning(`error getting expired banner messages for user beid ${this.userService.getBEID()}`);
              return of(null);
            }));
        })
      )
      .subscribe(bannerMessages => {
        this.messageCenterService.setExpiredBannerMessages(bannerMessages);
      });

    //listen to new incoming subject property identifier (pin or arn) and open the property report with the pin or arn request parameters visible (handled by this component's route queryParams observable)
    this.propertyReportService.subjectPropertyIdentifier$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((identifier) => {
        if (!_.isEmpty(identifier) && (this.dataService.isPin(identifier) || this.dataService.isArn(identifier))) {
          setTimeout(async () => {
            try {
              this.mainMapService.exitFullScreenMap();
            } catch (e) {
            }
  
            if (this.dataService.isPin(identifier)) {
              await this.router.navigate(['/home'], {
                queryParams: {pin: identifier}
              });
            } else if (this.dataService.isArn(identifier)) {
              await this.router.navigate(['/home'], {
                queryParams: {arn: identifier}
              });
            }

            this.screenManager.closeScreensWhenThisOpened(ScreenNameEnum.PROPERTY_REPORT);
            this.screenManager.showScreen(ScreenNameEnum.PROPERTY_REPORT);
            this.propertyReportService.setNextPropertyDetailIdentifier(identifier);
          }, 500);
        }
      });

    this.checkUserSessionValidity();
  }

  ngAfterViewInit(): void {
    this.showLicenseExpiringDialogIfNeeded();
  }

  showLicenseExpiringDialogIfNeeded() {
    if (this.loggedInUser.doesUserNeedLicenseReminder()) {
      const content = this.loggedInUser.isMultiLicense ? [DataService.LICENSE_SUBSCRIPTION_RENEWAL_CONTENT_ML] : [DataService.LICENSE_SUBSCRIPTION_RENEWAL_CONTENT_SL];
      const dialogData = new WarningDialogData(DataService.LICENSE_SUBSCRIPTION_RENEWAL_HEADER, content, '', 'Remind Me Later', 'Renew Now', !this.loggedInUser.isMultiLicense);
      this.warningService.showWarningOnceADay(WarningTypeEnum.LICENSE_RENEWAL, dialogData, false, 560, () => {
        this.router.navigate(['../catalogue', RouteMappingUtility.eStoreCategoryToRoute(EstoreProductCategoryEnum.GWH_RENEW_SUBSCRIPTION)]);
      });
    }
  }

  async checkUserSessionValidity(){
    // reload here user access controls if this is from a redirect from a subscription renewal request to eStore
    if(localStorage.getItem(LocalStorageKey.reloadUserAccessControls) === 'true'){
      const user = await lastValueFrom(this.userService.getUser(true));
      localStorage.setItem(LocalStorageKey.reloadUserAccessControls, '');
    }
    if ( this.userAccessControls.GWHRSubscriptionExpired){
      this.displayUserLicenseIsExpiredDialog();
    } else if (! this.userAccessControls.userActive){
      this.displayUserIsSuspendedDialog();
    }
  }

  displayUserLicenseIsExpiredDialog() {
    this.gaService.openModal('SubscriptionExpiredDialog');
    const dialogRef = this.dialog.open(SubscriptionExpiredDialogComponent, {disableClose: true, maxWidth: 500})
      .afterClosed()
      .subscribe(async (resp) => {
          if (resp) {
            if (resp.openCatalogue) {
              this.router.navigate(['../catalogue', RouteMappingUtility.eStoreCategoryToRoute(EstoreProductCategoryEnum.GWH_RENEW_SUBSCRIPTION)]);
            } else {
              this.loggerService.logDebug('logging out');
              this.logout();
            }
          } else {
            this.loggerService.logDebug("no user response from modal"); // it should not happen
          }
        }
      );
  }

  displayUserIsSuspendedDialog(): void {
    this.gaService.openModal('AccountSuspendedDialog');
    const dialogData = new WarningDialogData(DialogUtil.ACCOUNT_IS_SUSPENDED_TITLE, [DialogUtil.ACCOUNT_IS_SUSPENDED_MESSAGE], '','Log Out');
    this.warningService.showWarning(dialogData, true, undefined, undefined, () =>{
      setTimeout(() => {
        this.logout();
      }, 100);
    });
  }

  async logout() {
    const loggedOut = await  lastValueFrom(this.authService.logoutWithoutRedirect());
    if(!loggedOut) {
      document.location.href = UrlService.REDIRECT_AFTER_SESSION_TIMEOUT;
    }
  }

  moveMapControls(val:boolean) {
    this.isMapControlsMoved = val;
    this.loggerService.logDebug('isMapControlsMove', this.isMapControlsMoved)
  }

  mobileFullScreenMode(result: boolean) {
    this.isMobileFullScreen = result;
  }
}
