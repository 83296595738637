<!-- @defer (on viewport) { -->
  <span id="dg-hh-os-label" class="demographics-sub-header">
  Ownership
</span>

  <div class="ownership-table-container">
    <table id="dg-tbl-hh-os" class="ownership-table">
      <tr id="dg-tbl-row-hh-os-hdr">
        <th>
          <div class="flex">
            <div class="flex ml-[85px]">
              <div>
                <img id="dg-hh-os-owned-icon" alt="Owned" class="w-[15px] h-[30px] mb-1" src="/assets/img/icon_owned.png">
                <p id="dg-tbl-hdr-hh-os-owned" class="text-os-bold">Owned</p>
              </div>
            </div>
            <div class="flex ml-auto">
              <div>
                <img id="dg-hh-os-rented-icon" alt="Rented" class="w-[15px] h-[30px] mb-1" src="/assets/img/icon_rented.png">
                <p id="dg-tbl-hdr-hh-os-rented" class="text-os-bold">Rented</p>
              </div>
            </div>
          </div>
        </th>
      </tr>
      @if (selectedArea.includes(demographicsAreaEnum.NBH)) {
        <tr id="dg-tbl-row-hh-os-nbh">
          <td id="dg-hh-os-fusionchart-nbh">
            <div id="chart-ownership-nbh">
              <fusioncharts
                type="stackedbar2d"
                (initialized)="initialized($event)"
                [dataEmptyMessage]="ds.nbhDataEmptyMessage"
                [dataSource]="nbhDataSource"
                dataFormat="json"
                height="{{defaultHeight}}"
                width="{{defaultWidth}}"
              ></fusioncharts>
            </div>
          </td>
        </tr>
      }
      @if (selectedArea.includes(demographicsAreaEnum.COM)) {
        <tr id="dg-tbl-row-hh-os-com">
          <td id="dg-hh-os-fusionchart-com">
            <div id="chart-ownership-com">
              <fusioncharts
                type="stackedbar2d"
                (initialized)="initialized($event)"
                [dataEmptyMessage]="ds.comDataEmptyMessage"
                [dataSource]="comDataSource"
                dataFormat="json"
                height="{{defaultHeight}}"
                width="{{defaultWidth}}"
              ></fusioncharts>
            </div>
          </td>
        </tr>
      }
      @if (selectedArea.includes(demographicsAreaEnum.CITY)) {
        <tr id="dg-tbl-row-hh-os-city">
          <td id="dg-hh-os-fusionchart-city">
            @if (cityTotalPopulation) {
              <div id="chart-ownership-city">
                <fusioncharts
                  type="stackedbar2d"
                  (initialized)="initialized($event)"
                  [dataEmptyMessage]="ds.cityDataEmptyMessage"
                  [dataSource]="cityDataSource"
                  dataFormat="json"
                  height="{{defaultHeight}}"
                  width="{{defaultWidth}}"
                ></fusioncharts>
              </div>
            }
          </td>
        </tr>
      }
    </table>
  </div>
<!-- } @placeholder {
  <div>Loading data ...</div>
} -->
