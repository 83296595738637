import { AfterViewInit, Component, HostListener, inject, OnDestroy, OnInit } from '@angular/core';
import { TimerService } from "./shared/service/timer/timer.service";
import { PropertyReportService } from "./shared/service/property-report.service";
import { LoggerService } from './shared/service/log/logger.service';
import { SSODeeplinkService } from './shared/service/sso-deeplink.service';
import { UserService } from './shared/service/user.service';
import { VersioningService } from "./shared/service/versioning-service";
import { MatDialog } from '@angular/material/dialog';
import { DebuggerDialogData } from './core/component/modal/debugger-dialog/debugger-dialog-data';
import { DebuggerDialogComponent } from './core/component/modal/debugger-dialog/debugger-dialog.component';
import { CustomPrintService } from "./shared/service/utility/custom-print.service";
import * as _ from "lodash";

@Component({
  selector: 'gema3g-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  title = 'GEMA 3G';
  private pressedKeys: Map<string, string> = new Map<string, string>();
  debuggerDialogData: DebuggerDialogData = new DebuggerDialogData();
  private customPrintService = inject(CustomPrintService);

  constructor() {
  }

  private timerService = inject(TimerService);
  private userService = inject(UserService);
  private propertyReportService = inject(PropertyReportService);
  private ssoDeeplinkService = inject(SSODeeplinkService);
  private loggerService = inject(LoggerService);
  private versioningService = inject(VersioningService);
  private dialog = inject(MatDialog);

  async ngOnInit(): Promise<void> {
    await this.versioningService.loadBuildVersion();
    this.loggerService.logInfo(`${this.title} version ${this.versioningService.currentVersion()} is starting...`);
  }


  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    // listen to the key combination to trigger the application-wide debbuger window
    this.pressedKeys.set(event.key.toUpperCase(), event.key?.toUpperCase());
    if (this.checkDebbugerKeyCombination()) {
      this.pressedKeys.clear();
      this.loggerService.logDebug('Debugger activated');
      this.dialog.open(DebuggerDialogComponent, { data: this.debuggerDialogData });

    } else if (this.checkPrintKeyCombination()){
      if(this.customPrintService.propertyReportHasPrintableArea()) {
        event.preventDefault();
        this.pressedKeys.clear();
        this.customPrintService.customPrintPropertyReport();
      }
    }
  }

  @HostListener('window:keyup', ['$event'])
  handleKeyUp(event: KeyboardEvent) {
    this.pressedKeys.delete(event.key.toUpperCase());
  }

  private checkDebbugerKeyCombination() : boolean {
    const debuggerKeyCombination = ['CONTROL', 'ALT', 'G'];
    const pressedKeys = Array.from(this.pressedKeys.values());
    this.loggerService.logDebug(pressedKeys);
    return debuggerKeyCombination.length == pressedKeys.length && _.isEqual(debuggerKeyCombination, pressedKeys);
  }

  private checkPrintKeyCombination() : boolean {
    const printerKeyCombination = ['CONTROL', 'P'];
    const pressedKeys = Array.from(this.pressedKeys.values());
    this.loggerService.logDebug(pressedKeys);
    return printerKeyCombination.length == pressedKeys.length && _.isEqual(printerKeyCombination, Array.from(this.pressedKeys.values()));
  }

  ngAfterViewInit(): void {
    this.timerService.startAllTimers();
  }

  ngOnDestroy(): void {
    this.loggerService.logInfo(`${this.title} is stopping...`);
    this.timerService.stopAllTimers();
    this.userService.clearUser();
    this.propertyReportService.resetSubscribers();
    this.ssoDeeplinkService.resetSubscribers();
  }

}
