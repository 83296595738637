import { AfterViewInit, NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { CartMainComponent } from './cart-main/cart-main.component';


@NgModule({
  declarations: [
    CartMainComponent
  ],
  imports: [
    CommonModule,
    CoreModule
  ],
  exports: [
  ]
})
export class CartModule {
}