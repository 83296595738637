import { inject, NgModule } from '@angular/core';
import { PreloadAllModules, Router, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from "./home/home/home.component";
import { LoginComponent } from "./login/login/login.component";
import { StoreFrontComponent } from './store/store-front/store-front.component';
import { RegistrationTrackerComponent } from './report/registration-tracker/registration-tracker.component';
import { ComparablesComponent } from './report/comparables/comparables.component';
import { PageNotFoundComponent } from "./core/component/page-not-found/page-not-found.component";
import { StoreRetailComponent } from "./store/store-retail/store-retail.component";
import { LegalAgreementComponent } from "./login/legal-agreement/legal-agreement.component";
import { UserNotLoggedInAuthGuard } from './core/authenticate/auth-guard/user-not-logged-in.auth.guard';
import { NewUserProfileComponent } from './login/new-user-profile/new-user-profile.component';
import { ValidUserAuthGuard } from './core/authenticate/auth-guard/user-valid.auth.guard';
import { UserTermsAndConditionsAcceptedAuthGuard } from './core/authenticate/auth-guard/user-terms-and-conditions-accepted.auth.guard';
import { UserProfileInitializedAuthGuard } from './core/authenticate/auth-guard/user-profile-initialized.auth.guard';
import { UserMessagesComponent } from './core/component/message-center/user-messages/user-messages.component';
import { AuthCallbackComponent } from "./login/auth0-callback/auth-callback.component";
import { LoggerService } from "./shared/service/log/logger.service";
import { NotForEStoreOnlyAccessGuard } from "./core/authenticate/auth-guard/not-for-e-store-only-access.guard";
import { Auth0AuthenticationComponent } from "./login/auth0-authentication/auth0-authentication.component";
import { SiteDownComponent } from './site-down/site-down.component';
import { AuthPasswordResetCallbackComponent } from "./login/auth0-password-reset-callback/auth-password-reset-callback.component";
import { UserSubscriptionNotExpiredAuthGuard } from './core/authenticate/auth-guard/user-subscription-not-expired.auth.guard';

const routes: Routes = [
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent, canActivate: [UserNotLoggedInAuthGuard]},
  {path: 'login-by-token', component: LoginComponent, canActivate: []},
  {path: 'legal-agreement', component: LegalAgreementComponent, canActivate: [UserTermsAndConditionsAcceptedAuthGuard]},
  {path: 'initialize-profile', component: NewUserProfileComponent, canActivate: [UserProfileInitializedAuthGuard]},
  {path: 'estore', component: StoreFrontComponent, canActivate: [ValidUserAuthGuard, UserSubscriptionNotExpiredAuthGuard]},
  {path: 'rata', component: RegistrationTrackerComponent, canActivate: [ValidUserAuthGuard, UserSubscriptionNotExpiredAuthGuard]},
  {path: 'comparables-report', component: ComparablesComponent, canActivate: [ValidUserAuthGuard, UserSubscriptionNotExpiredAuthGuard]},
  {path: 'catalogue/:type', component: StoreRetailComponent, canActivate: [ValidUserAuthGuard, UserSubscriptionNotExpiredAuthGuard]},
  {path: 'catalogue', component: StoreRetailComponent, canActivate: [ValidUserAuthGuard, UserSubscriptionNotExpiredAuthGuard]},
  {
    path: 'account',
    canActivate: [ValidUserAuthGuard, NotForEStoreOnlyAccessGuard, UserSubscriptionNotExpiredAuthGuard],
    loadChildren: () => import('./account/account-routing.module').then(m => m.AccountRoutingModule)
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [ValidUserAuthGuard, NotForEStoreOnlyAccessGuard],
    children: [],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {path: 'messages', component: UserMessagesComponent, canActivate: [ValidUserAuthGuard, UserSubscriptionNotExpiredAuthGuard]},

  // https://localhost.geowarehouse.ca:4200/ui/authcallback?code=xKdR7_2Q1Qxu17N-PAPEQpsw5jPmORGx3l0Khw56e0zhB&state=R2dTNUthMV9obk5XS0M0eEI4M01IY3Y0RFc5SWh5UlhscUpzYWlhMUdEbQ%3D%3D
  // https://localhost.geowarehouse.ca:4200/ui/authcallback?code=f&state=t
  {path: 'auth0-callback', component: AuthCallbackComponent},
  {path: 'login-with-okta', component: Auth0AuthenticationComponent},
  {path: 'auth0-password-reset-callback', component: AuthPasswordResetCallbackComponent},

  //html redirects
  {path: 'sitedown', component: SiteDownComponent, canActivate: [UserNotLoggedInAuthGuard]},

  //catch-all for all others
  {
    path: '**', pathMatch: 'full',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule {

  router = inject(Router);
  loggerService = inject(LoggerService);

  constructor() {
    this.router.events.subscribe(event => {
      // use this to debug router events
      //this.loggerService.logDebug("Router event:", event);
    })
  }
}
