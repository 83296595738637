<div id="comparables-result" class="comparables-result" class="{{containerClassName}}">
  <header class="sticky-header pl-8">
    <div class="w-9/12 items-center">
      <div class="flex flex-row items-center h-full gap-2">
        <div id="scr-cs-label" class="flex flex-col items-center justify-center">
          Comparables Sales
        </div>

        @if (searchResultsCount >= searchResultsCountMaxAllowed) {
          <div class="flex flex-col justify-center tooltip-icon">
            <div [satPopoverAnchor]="maxComparableSalesPopover" (mouseover)="maxComparableSalesPopover.open()" (mouseout)="maxComparableSalesPopover.close()">
              <fa-icon id="ps-icon-ph-parents-tooltip" class="fa-lg text-white" [icon]="faCircleInfo"/>
              <sat-popover #maxComparableSalesPopover [autoFocus]="false" [restoreFocus]="false" verticalAlign="above" openTransition="500ms ease-out" closeTransition="100ms ease-in">
                <gema3g-tooltip [content]="resultsCountTooltipText"></gema3g-tooltip>
              </sat-popover>
            </div>
          </div>
        }
      </div>
    </div>
    <div class="flex w-3/12 justify-end items-center gap-3">
      <div class="flip">
        @if (screenOrientation == orientation.HORIZONTAL) {
          <img id="scr-flip-v-icon" src="assets/img/svg/icons/icon_flipscreen_v.svg" (click)="toggleScreenOrientation()"
          [satPopoverAnchor]="verticalPopover" (mouseover)="verticalPopover.open()" (mouseout)="verticalPopover.close()" />
          <sat-popover #verticalPopover [autoFocus]="false" [restoreFocus]="false" verticalAlign="above" openTransition="500ms ease-out" closeTransition="100ms ease-in">
            <gema3g-tooltip [content]="flipResultsVertically"></gema3g-tooltip>
          </sat-popover>
        }

        @if (screenOrientation == orientation.VERTICAL) {
          <img id="scr-flip-h-icon" src="assets/img/svg/icons/icon_flipscreen_h.svg" (click)="toggleScreenOrientation()"
          [satPopoverAnchor]="horizontalPopover" (mouseover)="horizontalPopover.open()" (mouseout)="horizontalPopover.close()" />
          <sat-popover #horizontalPopover [autoFocus]="false" [restoreFocus]="false" verticalAlign="above" openTransition="500ms ease-out" closeTransition="100ms ease-in">
            <gema3g-tooltip [content]="flipResultsHorizontally"></gema3g-tooltip>
          </sat-popover>
        }
      </div>

      <div class="display">
        @if (screenDisplay == display.NORMAL) {
          <fa-icon id="scr-size-maximize-icon" class="fa-lg text-white" [icon]="faMaximize" (click)="toggleScreenSize()" [satPopoverAnchor]="maximizePopover" (mouseover)="maximizePopover.open()" (mouseout)="maximizePopover.close()"></fa-icon>
          <sat-popover #maximizePopover [autoFocus]="false" [restoreFocus]="false" verticalAlign="above" openTransition="500ms ease-out" closeTransition="100ms ease-in">
            <gema3g-tooltip [content]="maximizeDisplay"></gema3g-tooltip>
          </sat-popover>
        }

        @if (screenDisplay == display.MAXIMIZED) {
          <fa-icon id="scr-size-minimize-icon" class="fa-lg text-white" [icon]="faMinimize" (click)="toggleScreenSize()" [satPopoverAnchor]="minimizePopover" (mouseover)="minimizePopover.open()" (mouseout)="minimizePopover.close()"></fa-icon>
          <sat-popover #minimizePopover [autoFocus]="false" [restoreFocus]="false" verticalAlign="above" openTransition="500ms ease-out" closeTransition="100ms ease-in">
            <gema3g-tooltip [content]="minimizeDisplay"></gema3g-tooltip>
          </sat-popover>
        }
      </div>

      @if(!wasLastSearchByMunicipality){
        <img id="pr-img-print" src="assets/img/svg/GWHPrint.svg" class="h-4 border-l-2 border-l-white border-solid pl-2"
          (click)="printPDFReports()" />
        <div id="pr-btn-print" class="hidden lg:block" (click)="printPDFReports()">
          <span id="pr-print" class="text-white">Print PDF</span>
        </div>
      }

      <div id="scr-btn-close" (click)="closeResultsScreen()" class="w-[30px] mr-4">
          <fa-icon class="fa-lg text-white" [icon]="faCircleXmark"></fa-icon>
      </div>
    </div>

  </header>

  <div class="comparables-result-body">
    @if (searchResultsCount > 0) {
      <div class="display-option-container hidden lg:flex">
        <mat-radio-group class="" [(ngModel)]="selectedDisplay" (change)="onDisplayOptionChanged($event)">
          @for (display of displayOptions; track display) {
            <mat-radio-button id="{{'csrb-' + display}}" class="" [value]="display">
              {{display}}
              @if (display == "List") {
                @if (searchResultsCount < 200) {
                  ({{searchResultsCount}})
                } @else {
                  (200+)
                }
              }
            </mat-radio-button>
          }
        </mat-radio-group>

        <div class="flex flex-row justify-center">
          <mat-chip-set aria-label="Search criteria crumbs">
            @for (crumb of searchCriteriaCrumbs; track crumb) {
              <mat-chip>
                <span (click)="modifySearchCriteria()">{{crumb.value}}</span>
              </mat-chip>
            }
          </mat-chip-set>
        </div>

        <div>
          <a id="scr-btn-modify" (click)="modifySearchCriteria()" class="anchor">Modify search criteria</a>
        </div>
        <div>
          @if (userAccessControls.mpsComparablesReportsAccess){
            <a id="scr-add-to-rpt" (click)="addToReport()" class="anchor">Add to report</a>
          }
        </div>
      </div>
      <div class="table-container mat-elevation-z4">
        <div class="display-option-container flex lg:hidden">
          <mat-radio-group class="" [(ngModel)]="selectedDisplay" (change)="onDisplayOptionChanged($event)">
            @for (display of displayOptions; track display) {
              <mat-radio-button id="{{'csrb-' + display}}" class="" [value]="display">
                {{display}}
                @if (display == "List") {
                  @if (searchResultsCount < 200) {
                    ({{searchResultsCount}})
                  } @else {
                    (200+)
                  }
                }
              </mat-radio-button>
            }
          </mat-radio-group>
  
          <div class="flex flex-row justify-center">
            <mat-chip-set aria-label="Search criteria crumbs">
              @for (crumb of searchCriteriaCrumbs; track crumb) {
                <mat-chip>
                  <span (click)="modifySearchCriteria()">{{crumb.value}}</span>
                </mat-chip>
              }
            </mat-chip-set>
          </div>
  
          <div>
            <a id="scr-btn-modify" (click)="modifySearchCriteria()" class="anchor">Modify search criteria</a>
          </div>
          <div>
            @if (userAccessControls.mpsComparablesReportsAccess){
              <a id="scr-add-to-rpt" (click)="addToReport()" class="anchor">Add to report</a>
            }
          </div>
        </div>
        
        @if (selectedDisplay == 'Chart') {
          <div class="chart-container">
            <gema3g-search-comparables-result-price-chart>
            </gema3g-search-comparables-result-price-chart>
          </div>
        }

        @if (selectedDisplay == 'List') {
          <table id="scr-tbl" class="mat-elevation-z2 standard-table" mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="selected">
              <th id="scr-tbl-hdr-sel" mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? toggleAll() : null"
                              [checked]="areAllSelected()"
                              [indeterminate]="areSomeSelected() && !areAllSelected()">
                </mat-checkbox>
              </th>
              <td id="scr-tbl-data-sel-{{row.pin}}-{{row.registrationDate}}" mat-cell *matCellDef="let row">
                <mat-checkbox (click)="toggle(row)"
                              [checked]="row.selected">
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="saleAddressStr">
              <th id="scr-tbl-hdr-address" mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
              <td id="scr-tbl-data-address-{{row.pin}}-{{row.registrationDate}}" mat-cell *matCellDef="let row">
                @if (row.saleAddressStr) {
                  {{row.saleAddressStr}}
                } @else {
                  {{ANA}}
                }
              </td>
            </ng-container>
            <ng-container matColumnDef="registrationDate">
              <th id="scr-tbl-hdr-rd" mat-header-cell *matHeaderCellDef mat-sort-header class="center-header">Sale Date</th>
              <td id="scr-tbl-data-rd-{{row.pin}}-{{row.registrationDate}}" mat-cell *matCellDef="let row" class="centered">
                {{row.registrationDate | date : 'mediumDate'}}</td>
            </ng-container>
            <ng-container matColumnDef="considerationAmount">
              <th id="scr-tbl-hdr-ca" mat-header-cell *matHeaderCellDef mat-sort-header class="center-header">Sale Amount</th>
              <td id="scr-tbl-data-ca-{{row.pin}}-{{row.registrationDate}}" mat-cell *matCellDef="let row" class="centered">
                ${{row.considerationAmount | number: '1.'}}</td>
            </ng-container>
            <ng-container matColumnDef="area">
              <th id="scr-tbl-hdr-area" mat-header-cell *matHeaderCellDef mat-sort-header class="center-header">{{lotSizeHeader()}}</th>
              <td id="scr-tbl-data-area-{{row.pin}}-{{row.id}}" mat-cell *matCellDef="let row"
                  class="centered">
              {{row.displayArea}}
              </td>
            </ng-container>
            <ng-container matColumnDef="pricePerArea">
              <th id="scr-tbl-hdr-ppa" mat-header-cell *matHeaderCellDef mat-sort-header class="center-header">{{lotSizePriceHeader()}}</th>
              <td id="scr-tbl-data-ppa-{{row.pin}}-{{row.id}}" mat-cell *matCellDef="let row" class="centered">
                ${{ row.displayPricePerArea}}
              </td>
            </ng-container>
            <ng-container matColumnDef="distance">
              <th id="scr-tbl-hdr-dist" mat-header-cell *matHeaderCellDef mat-sort-header class="center-header">Distance (m)</th>
              <td id="scr-tbl-data-dist-{{row.pin}}-{{row.registrationDate}}" mat-cell *matCellDef="let row" class="centered">
                {{row.distance | number: '1.0-0'}}</td>
            </ng-container>
            <ng-container matColumnDef="pin">
              <th id="scr-tbl-hdr-pin" mat-header-cell *matHeaderCellDef mat-sort-header class="center-header">PIN</th>
              <td mat-cell *matCellDef="let row" class="centered">
                @if (propertyReportAccess) {
                  <a id="scr-tbl-link-pin-{{row.pin}}-{{row.registrationDate}}" (click)="openPropertyReport(row.pin)"
                    class="anchor" [satPopoverAnchor]="pinPopover" (mouseover)="pinPopover.open()" (mouseout)="pinPopover.close()">
                    {{row.pin}}
                  </a>
                } @else {
                  <span id="scr-tbl-link-pin-{{row.pin}}-{{row.registrationDate}}">{{row.pin}}</span>
                }
                <sat-popover #pinPopover [autoFocus]="false" [restoreFocus]="false"
                  verticalAlign="above" openTransition="500ms ease-out" closeTransition="100ms ease-in">
                  <gema3g-tooltip [content]="openPropertyReportTooltipText"></gema3g-tooltip>
                </sat-popover>
              </td>
            </ng-container>
            @if (isMpsRequest) {
              <ng-container matColumnDef="yearBuilt">
                <th id="scr-tbl-hdr-yb" mat-header-cell *matHeaderCellDef mat-sort-header class="center-header">Year Built</th>
                <td id="scr-tbl-data-yb-{{row.pin}}-{{row.registrationDate}}" mat-cell *matCellDef="let row" class="centered">
                  {{row.yearBuilt | na}}</td>
              </ng-container>
            }
            @if (isMpsRequest) {
              <ng-container matColumnDef="propertyCode">
                <th id="scr-tbl-hdr-pc" mat-header-cell *matHeaderCellDef mat-sort-header class="center-header">Property Code</th>
                <td mat-cell *matCellDef="let row" class="centered">
                  <div class="property-code-container">
                    <div id="scr-tbl-data-pc-{{row.pin}}-{{row.registrationDate}}" class="value">
                      {{row.propertyCode}}
                    </div>
                    <div class="tooltip-icon">
                      <fa-icon
                        id="scr-tbl-pc-tooltip-icon-{{row.propertyCode}}"
                        class="fa-sm text-[#428BCA]"
                        [icon]="faCircleInfo"
                        [satPopoverAnchor]="propertyCodeDescriptionPopover"
                        (mouseover)="propertyCodeDescriptionPopover.open()"
                        (mouseout)="propertyCodeDescriptionPopover.close()">
                      </fa-icon>
                      <sat-popover #propertyCodeDescriptionPopover [autoFocus]="false" [restoreFocus]="false"
                        verticalAlign="above" openTransition="500ms ease-out" closeTransition="100ms ease-in">
                        <gema3g-tooltip [content]="propertyCodeTooltipDescription"></gema3g-tooltip>
                      </sat-popover>
                    </div>
                  </div>
                </td>
              </ng-container>
            }
            @if (isMpsRequest) {
              <ng-container matColumnDef="rollNumber">
                <th id="scr-tbl-hdr-arn" mat-header-cell *matHeaderCellDef mat-sort-header class="center-header">Assessment Roll Number</th>
                <td id="scr-tbl-data-arn-{{row.pin}}-{{row.registrationDate}}" mat-cell *matCellDef="let row" class="centered">
                  {{row.rollNumber}}</td>
              </ng-container>
            }
            <tr id="scr-tbl-row-hdr" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr id="scr-tbl-row-{{row.pin}}-{{row.registrationDate}}" mat-row *matRowDef="let row; columns: displayedColumns;"
              (mouseover)="onRowMouseOver(row, 'scr-tbl-row-{{row.pin}}-{{row.registrationDate}}')" (mouseout)="onRowMouseOut(row)">
            </tr>
          </table>
        }
      </div>
    }

  </div>
</div>
