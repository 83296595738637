<h1 id="pybrs-h1-title" mat-dialog-title>
  PYB Report Status
</h1>

<mat-divider></mat-divider>

<div class="dialog-content" mat-dialog-content>
  @if (data) {
    @if (data.estorePybReport?.statusName != 'Failed' && data.estorePybReport?.statusName != 'Initiated') {
      <div>
        @if (data.estorePybReport?.statusName != 'Complete' && data.estorePybReport?.statusName != 'Cancelled' && !isUpdatedStatusFrmPYB) {
          <p id="pybrs-prev-status-label">
            Your PYB Report's previous status is
            @if (!isUpdatingStatus) {
              <span id="pybrs-prev-status" class="pybStatus-highlight">{{data.estorePybReport?.statusName}}</span>
            }.
          </p>
        }
        @if (data.estorePybReport?.statusName == 'Complete' || data.estorePybReport?.statusName == 'Cancelled' || isUpdatedStatusFrmPYB) {
          <p id="pyrbs-current-status-label">
            Your PYB Report's status is
            @if (!isUpdatingStatus) {
              <span id="pybrs-current-status" class="pybStatus-highlight">{{data.estorePybReport?.statusName}}</span>
            }.
          </p>
        }
        @if (data.estorePybReport?.statusName != 'Complete' && data.estorePybReport?.statusName != 'Cancelled' && data.pybRptETA != null) {
          <p id="pybrs-delivery-date-label">
            It is estimated that your report will be delivered to you via email by
            @if (!isUpdatingStatus) {
              <span id="pybrs-delivery-date" class="pybStatus-highlight">{{data.pybRptETA  | date: 'shortDate' }}</span>
            }.
          </p>
        }
        <p id="pybrs-info">If you have any questions, please contact: 
          <a id="pybrs-link-info" class="anchor-standard-blue" href="mailto:reports@protectyourboundaries.ca" target="_blank">reports&#64;protectyourboundaries.ca</a>.
        </p>
        <p>
          @if (isErrorInPybStatus) {
            <span id="pybrs-no-status" class="padding error-msg" target="_blank">Unable to get the status. Please try later.</span>
          }
        </p>
            </div>
    }
    <div>
      @if (data.estorePybReport?.statusName == 'Failed') {
        <div>
          <p>
            <span id="pybrs-pyb-error" class="padding error-msg" target="_blank">We have encountered an error with your PYB Report. Please contact 
              <a id="pybrs-link-pyb-error" class="anchor-standard-blue" href="mailto:reports@protectyourboundaries.ca" target="_blank">reports&#64;protectyourboundaries.ca</a>.
            </span>
          </p>
        </div>
      }
    </div>
    <div>
      @if (!data.estorePybReport) {
        <div>
          <p>
            <span id="pybrs-system-error" class="padding error-msg" target="_blank">System error in getting status. Please contact Teranet Customer Service Centre at 
              <a id="pybrs-link-system-error" class="anchor-standard-blue" href="mailto:geowarehouse.support@teranet.ca" target="_blank">geowarehouse.support&#64;teranet.ca</a> for more details.
            </span>
          </p>
        </div>
      }
    </div>
  }
</div>

<mat-divider></mat-divider>

<div align="end" mat-dialog-actions>
  @if (data) {
    @if (data.estorePybReport?.statusName != 'Complete' && data.estorePybReport?.statusName != 'Cancelled' && !isUpdatingStatus) {
      <button id="pybrs-btn-get-latest" (click)="getPYBUpdatedStatus(data)" mat-button>Get Latest Status</button>
    }
  }
  <button id="pybrs-btn-close" mat-button mat-dialog-close class="dialog-close-btn" type="button">Close</button>
</div>
