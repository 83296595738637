import {BaseModel} from "../base-model";

export class UserProfile extends  BaseModel{
  constructor(userProfile? :UserProfile) {
    super(userProfile);
  }
  address?: any;
  businessEntityId: number;
  cellphone?: any;
  city?: any;
  collaboration: string;
  company: string;
  created?: any;
  email: string;
  facebook?: any;
  fax?: any;
  firstName: string;
  homePageURL?: any;
  lastName: string;
  lastUpdated?: any;
  legalAccepted: boolean;
  linkedInUrl?: any;
  phone?: any;
  position?: any;
  postalCode?: any;
  profileAccepted: boolean;
  profileImageExists: boolean;
  province: string;
  receiveEmailModifiedDate: number;
  receiveMail: boolean;
  twitterUrl?: any;
  licensePackageId? : number;
  accountTypeId?: number;
  accountTypeName?: string;
  parentREB?: boolean;

  getFullName = () => {
    return this.firstName + ' ' + this.lastName;
  }
}
