<!-- @defer (on viewport) { -->
  @if (houseHold) {
    <span id="dg-hh-sd-label" class="demographics-sub-header">
    Structure Details
  </span>
    <table id="dg-tbl-hh-sd" class="structure-detail-table">
      <tr id="dg-tbl-row-hh-sd-hdr">
        <th id="dg-tbl-hdr-hh-sd-type"></th>
        <th>
          <img id="dg-hh-sd-dwl-icon" alt="Dwellings" src="/assets/img/icon_dwellings.png"/>
          <p id="dg-tbl-hdr-hh-sd-dwl" class="text-os-bold">Total # of Occupied Private Dwellings</p></th>
        <th>
          <img id="dg-hh-sd-year-icon" alt="Dominant" src="/assets/img/icon_dominant-year-built.png"/>
          <p id="dg-tbl-hdr-hh-sd-year" class="text-os-bold">Dominant Year Built</p></th>
      </tr>
      @if (selectedArea.includes(demographicsAreaEnum.NBH)) {
        <tr id="dg-tbl-row-hh-sd-nbh">
          <td id="dg-tbl-data-hh-sd-type-nbh" class="first-td">NBH</td>
          <td id="dg-tbl-data-hh-sd-dwl-nbh-ttl" class="other-td">
            {{ houseHold.PRCDDA?.totalOccupiedDwl | number  | na }}
          </td>
          <td id="dg-tbl-data-hh-sd-year-nbh" class="other-td">
            {{ houseHold.PRCDDA?.yearBuilt | na }}
          </td>
        </tr>
      }

      @if (selectedArea.includes(demographicsAreaEnum.COM)) {
        <tr id="dg-tbl-row-hh-sd-com">
          <td id="dg-tbl-data-hh-sd-type-com" class="first-td">COM</td>
          <td id="dg-tbl-data-hh-sd-dwl-com" class="other-td">
            {{ houseHold.FSA?.totalOccupiedDwl  | number | na }}
          </td>
          <td id="dg-tbl-data-hh-sd-year-com" class="other-td">
            {{ houseHold.FSA?.yearBuilt | na }}
          </td>
        </tr>
      }

      @if (selectedArea.includes(demographicsAreaEnum.CITY)) {
        <tr id="dg-tbl-row-hh-sd-city">
          <td id="dg-tbl-data-hh-sd-type-city" class="first-td">CITY</td>
          <td id="dg-tbl-data-hh-sd-dwl-city" class="other-td">
            {{ houseHold.PRCDCSD?.totalOccupiedDwl  | number | na }}
          </td>
          <td id="dg-tbl-data-hh-sd-year-city" class="other-td">
            {{ houseHold.PRCDCSD?.yearBuilt | na }}
          </td>
        </tr>
      }
    </table>
  }
<!-- } @placeholder {
  <div>Loading Data ...</div>
} -->
