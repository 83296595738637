import {RouteMappingUtility} from "../../../../shared/utility/route-mapping-utility";
import {EstoreProductCategoryEnum} from "../../../enum/estore-product-category-enum";
import {BaseModel} from "../../base-model";

export type CATALOGUE_TYPE = 'ALL' | 'PARCEL_REGISTER_SURVEY' | 'PROPERTY_INSIGHT_REPORT' | 'MPAC_REPORT' | 'SUBSCRIPTION' | 'RESIDENTIAL_RELOCATION_REPORT' | 'HOODQ' | 'PROTECT_YOUR_BOUNDARIES_REPORT';

export class ProductCategoryItem extends BaseModel{
  title: string;
  img: string;
  imgAlt: string;
  content: string;
  link: string;
  linkParamType: CATALOGUE_TYPE;
  linkCaption: string;

  static getLinkParam(linkParamType: CATALOGUE_TYPE): string {
    switch(linkParamType){
      case "PARCEL_REGISTER_SURVEY": return RouteMappingUtility.eStoreCategoryToRoute(EstoreProductCategoryEnum.STAT);
      case "PROPERTY_INSIGHT_REPORT": return RouteMappingUtility.eStoreCategoryToRoute(EstoreProductCategoryEnum.TERANET_INSIGHTS);
      case "MPAC_REPORT": return RouteMappingUtility.eStoreCategoryToRoute(EstoreProductCategoryEnum.MPAC);
      case "SUBSCRIPTION": return RouteMappingUtility.eStoreCategoryToRoute(EstoreProductCategoryEnum.GWH_RENEW_SUBSCRIPTION);
      case "RESIDENTIAL_RELOCATION_REPORT": return RouteMappingUtility.eStoreCategoryToRoute(EstoreProductCategoryEnum.RESIDENTIAL_RELOCATION);
      case "HOODQ": return RouteMappingUtility.eStoreCategoryToRoute(EstoreProductCategoryEnum.HOODQ);
      case "PROTECT_YOUR_BOUNDARIES_REPORT": return RouteMappingUtility.eStoreCategoryToRoute(EstoreProductCategoryEnum.PYB);
      default: return '';
    }
  }
}
