import {inject, Injectable} from "@angular/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {defaultErrorMatSnackBarConfig, defaultMatSnackBarConfig} from "../constant/constants";

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  private _snackBar = inject(MatSnackBar);


  displaySnackBarError(msg: string) {
    this._snackBar.open(msg, 'Close', defaultErrorMatSnackBarConfig);
  }

  displaySnackBarMessage(msg: string) {
    this._snackBar.open(msg, 'Close', defaultMatSnackBarConfig);
  }
}
