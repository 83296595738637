import { Component } from '@angular/core';
import {EstoreService} from "../../../shared/service/estore.service";
import {LandingPageSettings} from "../../../core/model/store-front/landing-page/landing-page-settings";
import {lastValueFrom} from "rxjs";
import {Router} from "@angular/router";
import {EstoreProductCategoryEnum} from "../../../core/enum/estore-product-category-enum";
import {RouteMappingUtility} from "../../../shared/utility/route-mapping-utility";

@Component({
  selector: 'gema3g-store-front-main',
  templateUrl: './store-front-main.component.html',
  styleUrls: ['./store-front-main.component.scss']
})
export class StoreFrontMainComponent {

  landingPageSettings : LandingPageSettings;
  estoreProductCategoryEnum: typeof EstoreProductCategoryEnum = EstoreProductCategoryEnum;
  constructor(private eStoreService: EstoreService,
              private router: Router) {
    this.init();
  }

  async init(){
    this.landingPageSettings = await lastValueFrom(this.eStoreService.getEstoreLandingPageSettings());
    //this.loggerService.logInfo(this.landingPageSettings);
  }

  navigateHome() {
    this.router.navigate(['../home']);
  }

  goToCatalogue(category: EstoreProductCategoryEnum) {
    this.eStoreService.goToCatalogueCategory(category);
  }
}
