import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {ComparableSalesReportSubjectArn} from "../../../core/model/comparables/report/comparable-sales-report-subject-arn";
import {ComparableSalesReportComparableProperty} from "../../../core/model/comparables/report/comparable-sales-report-comparable-property";
import {CoreModule} from "../../../core/core.module";
import {UserService} from "../../../shared/service/user.service";
import {UserAccessControl} from "../../../core/model/user/user-access-control";
import {PrefixWithDollarSignPipe} from "../../../shared/pipe/prefix-with-dollar-sign.pipe";
import {Router} from "@angular/router";
import {LoggerService} from "../../../shared/service/log/logger.service";
import {DataService} from "../../../shared/service/data.service";
import {PinListStaticStreetView} from "../../../core/model/map/pinlist-static-streetview";
import {AddressOrNaPipe} from "../../../shared/pipe/address-or-na.pipe";
import {ComparableSalesReportPii} from "../../../core/model/comparables/report/comparable-sales-report-pii";
import {ComparablesReportService} from "../../../shared/service/comparables-report.service";
import {lastValueFrom} from "rxjs";
import {remove} from "lodash";
import {SnackBarService} from "../../../shared/service/snack-bar.service";
import {ComparablesSalesReportPrintParam} from "../../../core/model/comparables/report/print/comparables-sales-report-print-param";
import {ProgressBarDialogData} from "../../../core/component/modal/progress-bar-dialog/progress-bar-data";
import {ProgressBarDialogComponent} from "../../../core/component/modal/progress-bar-dialog/progress-bar-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'gema3g-comparables-property-detail',
  standalone: true,
  imports: [
    CoreModule,
    PrefixWithDollarSignPipe,
    AddressOrNaPipe
  ],
  templateUrl: './comparables-property-detail.component.html',
  styleUrl: './comparables-property-detail.component.scss'
})
export class ComparablesPropertyDetailComponent {
  private userService = inject(UserService);
  protected userAccessControls: UserAccessControl = this.userService.getUserAccessControl();

  private router = inject(Router);
  private loggerService = inject(LoggerService);
  private dataService = inject(DataService);
  comparableSaleReportService = inject(ComparablesReportService);
  snackBarService = inject(SnackBarService);
  dialog = inject(MatDialog);

  @Input() propertyDetail: ComparableSalesReportComparableProperty | ComparableSalesReportSubjectArn;
  @Input() imageUrl: string;
  @Input() isSubject: boolean = false;
  @Input() reportDate: string;
  @Input() reportId: number;
  @Input() compId: number;

  @Output() propertyRemoved = new EventEmitter<number>();

  async goToPin(pin: string) {
    this.loggerService.logDebug('Navigating to home with pin: ' + pin);
    let qp = {};
    if (this.dataService.isPin(pin)) {
      qp = {
        pin: pin
      }
    }
    await this.router.navigate(['/home'], {
      queryParams: qp
    });
  }

  async removePropertyFromReport() {
    let modalConfig = {
      data: new ProgressBarDialogData(`Removing property with pin ${this.propertyDetail?.pii?.pin} from report in progress...`, this.removePropertyFromReportWithWarning.bind(this), null),
      minWidth: 400,
      maxWidth: 900,
    };
    const dialogRef = await this.dialog.open(ProgressBarDialogComponent, modalConfig)
      .afterClosed()
      .subscribe(async (resp: boolean) => {
        this.loggerService.logDebug("Done.")
      });
  }

  async removePropertyFromReportWithWarning() {
    const removed = await lastValueFrom(this.comparableSaleReportService.removePropertyFromReport(this.reportId, this.compId));
    if(removed.businessError == 0){
      this.propertyRemoved.emit(this.compId);
    } else {
      this.snackBarService.displaySnackBarError('Error removing property from report');
    }
  }
}
