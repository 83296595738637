import { BaseModel } from "../base-model";

export class CartCounter extends BaseModel {
    constructor(cartCounter?: CartCounter) {
        super(cartCounter);
        if (!cartCounter) {
            this.counter = 0;   //set default if cart is empty
        }
    }

    counter: number;
}