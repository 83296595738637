export enum ScreenNameEnum {
  OMNIBAR_SEARCH,
  OMNIBAR_SEARCH_RESULTS,
  //OMNIBAR_MAIN_SEARCH_RESULTS,
  //OMNIBAR_OWNER_SEARCH_RESULTS,
  OMNIBAR_CONDO_SEARCH_RESULTS,
  PROPERTY_REPORT,
  SEARCH_COMPARABLES_FORM,
  SEARCH_COMPARABLES_RESULTS,
  MAIN_MAP_STREETVIEW,
  HOODQ,
  MAP_LAYER_POPUP,
  MAP_TYPE_POPUP,
  MY_ACCOUNT,
  COMPARABLES_REPORT
}
