import {inject, Injectable} from "@angular/core";
import {catchError, map, Observable, throwError} from "rxjs";
import {Download} from "../../core/model/download/download";
import {baseUrl} from "./system";
import {HttpClient} from "@angular/common/http";
import {PybPurchaseStatus} from "../../core/model/purchase/pyb-purchase/pyb-purchase-status";
import {MatDialog} from "@angular/material/dialog";
import {FulfillmentItem} from "../../core/model/download/fulfillment-item";
import {
  PybReportStatusComponent
} from "../../core/component/modal/pyb-report-status/pyb-report-status/pyb-report-status.component";
import { LoggerService } from "./log/logger.service";
import {GoogleAnalyticsService} from "./google-analytics.service";

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  private https = inject(HttpClient);
  private loggerService = inject(LoggerService);
  public dialog = inject(MatDialog);
  private gaService  = inject(GoogleAnalyticsService);

  public getDownloads(pageNum: number, pageSize: number, orderBy: string, orderDir: string, startDate: string, endDate: string): Observable<Download> {
    const url = `${baseUrl}/user/downloads?pageSize=${pageSize}&pageNumber=${pageNum}&orderBy=${orderBy}&orderDirection=${orderDir}&startDate=${startDate}&endDate=${endDate}`;
    return this.retrieveDownloads(url);
  }

  public getDefaultDownloads(): Observable<Download> {
    const url = baseUrl + '/user/downloads?pageSize=4&pageNumber=0';
    return this.retrieveDownloads(url);
  }

  private retrieveDownloads(url: string): Observable<Download> {
    return this.https.get(url).pipe(
      map(resp => {
        const download: Download = <Download>(resp);
        this.loggerService.logTable(download);
        return download;
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }

  downloadReport(fulfillmentId: number | undefined, itemId: number | undefined) {
    window.location.assign(`${baseUrl}/user/downloads/file?fulfillmentId=${fulfillmentId}&itemId=${itemId}`);
  }

  downloadAccountStatement(type: string, quantity: number) {
    window.location.assign(`${baseUrl}/user/accountstatement/pdf?type=${type}&quantity=${quantity}`);
  }

  getPYBUpdatedStatus(sourceOrderNumber: number): Observable<PybPurchaseStatus> {
    const url = baseUrl + '/pyb/report/status?sourceOrderNumber=' + sourceOrderNumber;
    return this.https.get(url).pipe(
      map(resp => {
        const pybPurchaseStatus: PybPurchaseStatus = <PybPurchaseStatus>(resp);
        this.loggerService.logTable(pybPurchaseStatus);
        return pybPurchaseStatus;
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }

  openPYBReportStatus(item: FulfillmentItem) {
    this.gaService.openModal('PybReportStatus');
    this.dialog.open(PybReportStatusComponent, {data: item});
  }

}
