import {BaseModel} from "../base-model";

export class Municipality extends BaseModel{
  lro: string;
  munId: string;
  municipality: string;

  get municipalityNoSpaces(): string{
    return this.municipality?.replace(/ /g, '_');
  }
}
