<section class="relative text-center fixed">
  <img id="estore-img-front-banner" class=" image" src="assets/img/store-front/GWH_graphic_estore_banner_1920_160816.png">
  <a id="estore-link-front-close" (click)="navigateHome()" class="x-close-btn">Close X</a>
  <div class="text-overlay">
    <div id="estore-front-desc-1" class="hidden md:block text-lg md:text-2xl">ALL THE REPORTS YOU NEED AT YOUR FINGERTIPS</div>
    <div id="estore-front-desc-2" class="hidden md:block text-base md:text-lg">Access authoritative data. Become the expert.</div>
    <div>
      <button id="estore-btn-front-ss" (click)="goToCatalogue(estoreProductCategoryEnum.ALL)" class="shop-btn" type="button">Start Shopping</button>
    </div>
  </div>
</section>


<div class="store-ads-container">
  <div class="store-ads-container__inner">
    <div class="protection-box">
      <div class="protection-box__inner">
        <div>
          <div id="estore-front-firstbox-header" class="mb-5">{{landingPageSettings?.landingPageBox?.firstBox?.headertext}}</div>
          <div id="estore-front-firstbox-content" class="text-xs">{{landingPageSettings?.landingPageBox?.firstBox?.content}}</div>
        </div>
        <div>
          <button id="estore-btn-front-firstbox" (click)="goToCatalogue(estoreProductCategoryEnum.STAT)">Purchase</button>
        </div>
      </div>
    </div>

    <div class="hoodq-box-container">
      <div class="hoodq-box">
        <div class="hoodq-box__inner">
          <div id="estore-front-hqbox-header" class="text-2xl">HoodQ™</div>
          <div id="estore-front-hqbox-content">
            Neighbourhood and<br>
            School Catchment Reports
          </div>
          <button id="estore-btn-front-hqbox" (click)="goToCatalogue(estoreProductCategoryEnum.HOODQ)">Shop Now</button>
        </div>
      </div>
      <div class="background-img-container">
        <img id="estore-img-front-hqbox" src="assets/img/hoodq/hoodq-background1.png"/>
      </div>
    </div>
  </div>
</div>


<section class="store-front-main-container">
  <section>
    <gema3g-store-front-product-categories></gema3g-store-front-product-categories>
  </section>
  <section class="store-front-footer">
    <gema3g-store-front-footer
      [landingPageSettings]="landingPageSettings"
    ></gema3g-store-front-footer>
  </section>
</section>
