import { Component } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { UserService } from "../../../../shared/service/user.service";
import { UserAccessControl } from "../../../model/user/user-access-control";
import { Router } from '@angular/router';
import { LoggerService } from '../../../../shared/service/log/logger.service';
import { AuthenticationService } from '../../../../shared/service/authentication.service';
import { DataService } from '../../../../shared/service/data.service';

@Component({
  selector: 'gema3g-subscription-expired-dialog',
  templateUrl: './subscription-expired-dialog.component.html',
  styleUrls: ['./subscription-expired-dialog.component.scss']
})
export class SubscriptionExpiredDialogComponent {

  constructor(public dialogRef: MatDialogRef<SubscriptionExpiredDialogComponent>,
    private loggerService: LoggerService,
    private authService: AuthenticationService,
    private userService: UserService,
    private router: Router) {
    this.userAccessControls = this.userService.getUserAccessControl();
  }

  userAccessControls: UserAccessControl;

  logoutUser() {
    this.dialogRef.close({ openCatalogue: false });
    this.authService.logoutFromApp();
  }

  goToSubscriptionRenewal() {
    this.dialogRef.close({ openCatalogue: true });
  }

  public get DataService() {
    return DataService;
  }
}
