import {inject, Injectable} from "@angular/core";
import {baseUrl} from "./system";
import {HttpClient} from "@angular/common/http";
import {catchError, lastValueFrom, map, Observable, throwError} from 'rxjs';
import * as _ from 'lodash';
import {SSOOpenIDConnectJson} from "../../core/model/sso/sso-oidc-json";
import {LoggerService} from "./log/logger.service";

@Injectable({
  providedIn: 'root'
})
export class SSOService {

  private https = inject(HttpClient);
  private loggerService = inject(LoggerService);

  getOpenIDConnectJson = (): Observable<SSOOpenIDConnectJson> => {
    const url = baseUrl + '/gwhsso/token';
    this.loggerService.logInfo(`calling ${url} to get access token for estore`);

    return this.https.get<SSOOpenIDConnectJson>(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).pipe(
      map(response => {
        if (!_.isEmpty(response)) {
          return <SSOOpenIDConnectJson>response;
        } else {
          return new SSOOpenIDConnectJson();
        }
      }),
      catchError((err) => {
        this.loggerService.logError('unable to acquire access token for estore', err);
        return throwError(err);
      })
    );
  }

  getSSOTokenForEstore = async (): Promise<string | undefined> => {
    try {
      this.loggerService.logDebug("calling sso service");
      let ssoOIDCJson: any = await lastValueFrom(this.getOpenIDConnectJson(), {defaultValue: {}});
      let oidcAccessToken: string = ssoOIDCJson.accessToken;
      this.loggerService.logInfo('successfully acquired estore access token', oidcAccessToken);

      return oidcAccessToken;

    } catch (e) {
      this.loggerService.logError(e);
      return undefined;
    } finally {
    }
  }
}
