import { BaseModel } from "../base-model";
import { MessageCategory } from "./message-category";

export class Message extends BaseModel {
  constructor(message?: Message) {
    super(message);
  }

  private allUser: string;
  private body: string;
  private createDate: Date;
  private createdById: number;
  private expiryDate: Date;
  private groupId: string;
  private lockedById: number;
  private lockedDate: Date;
  private messageCategory: MessageCategory;
  private messageId: number;
  private remedyTicket: string;
  private status: string;
  private subject: string;
  private threadId: number;

  getAllUser(): string {
    return this.allUser;
  }

  getBody(): string {
    return this.body;
  }

  getCreateDate(): Date {
    return this.createDate;
  }

  getCreatedById(): number {
    return this.createdById;
  }

  getExpiryDate(): Date {
    return this.expiryDate;
  }

  getGroupId(): string {
    return this.groupId;
  }

  getLockedById(): number {
    return this.lockedById;
  }

  getLockedDate(): Date {
    return this.lockedDate;
  }

  getMessageCategory(): MessageCategory {
    return this.messageCategory;
  }

  getMessageId(): number {
    return this.messageId;
  }

  getRemedyTicket(): string {
    return this.remedyTicket;
  }

  getStatus(): string {
    return this.status;
  }

  getSubject(): string {
    return this.subject;
  }

  getThreadId(): number {
    return this.threadId;
  }

  setAllUser(allUser: string): void {
    this.allUser = allUser;
  }

  setBody(body: string): void {
    this.body = body;
  }

  setCreateDate(createDate: Date): void {
    this.createDate = createDate;
  }

  setCreatedById(createdById: number): void {
    this.createdById = createdById;
  }

  setExpiryDate(expiryDate: Date): void {
    this.expiryDate = expiryDate;
  }

  setGroupId(groupId: string): void {
    this.groupId = groupId;
  }

  setLockedById(lockedById: number): void {
    this.lockedById = lockedById;
  }

  setLockedDate(lockedDate: Date): void {
    this.lockedDate = lockedDate;
  }

  setMessageCategory(messageCategory: MessageCategory): void {
    this.messageCategory = messageCategory;
  }

  setMessageId(messageId: number): void {
    this.messageId = messageId;
  }

  setRemedyTicket(remedyTicket: string): void {
    this.remedyTicket = remedyTicket;
  }

  setStatus(status: string): void {
    this.status = status;
  }

  setSubject(subject: string): void {
    this.subject = subject;
  }

  setThreadId(threadId: number): void {
    this.threadId = threadId;
  }
}

