<div class="sr-sorting-container">
  <div class="flex justify-end !border-b-2 !border-solid">
    <gema3g-svg-icon id="ssr-icon-sorting"
                     [fill]="'#a0a0a0'"
                     [height]="27"
                     [icon]="'sorting'"
                     [width]="27"
                     class="sorting-icon"
    >
    </gema3g-svg-icon>

    <p-dropdown id="ssr-dd-sorting-options"
                (onChange)="onSortingChange()"
                (onClick)="onClick()"
                [(ngModel)]="selectedSortingOption"
                [options]="sortingOptions"
                optionLabel="label"
                optionValue="value"
                placeholder="SORT"
    >
    </p-dropdown>
  </div>
</div>
