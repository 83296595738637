import { ChangeDetectionStrategy, Component, Inject, inject, input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { UserService } from '../../../../shared/service/user.service';
import { PropertyViewedInsight } from '../../../model/property/property-viewed-insight';
import { BaseUnsubscribe } from '../../base-unsubscribe/base-unsubscribe';
import { LoggerService } from '../../../../shared/service/log/logger.service';

@Component({
  selector: 'gema3g-property-viewed-insights',
  templateUrl: './property-viewed-insights-dialog.component.html',
  styleUrl: './property-viewed-insights-dialog.component.scss',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertyViewedInsightsDialogComponent extends BaseUnsubscribe implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<PropertyViewedInsightsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PropertyViewedInsight,
  ) {
    super();
  }

  public uniqueUsersViewedCountChart: any;
  public allUsersViewedCountChart: any;
  propertyViewedInsights: PropertyViewedInsight | undefined;
  private loggerService = inject(LoggerService);
  private userService = inject(UserService);

  uniqueUsersCount30Days: number;
  uniqueUsersCount60Days: number;
  uniqueUsersCount90Days: number;
  allUsersCount30Days: number;
  allUsersCount60Days: number;
  allUsersCount90Days: number;
  loggedInUserCount30Days: number;
  loggedInUserCount60Days: number;
  loggedInUserCount90Days: number;

  documentStyle = getComputedStyle(document.documentElement);
  textColor = this.documentStyle.getPropertyValue('--text-color');
  textColorSecondary = this.documentStyle.getPropertyValue('--text-color-secondary');
  surfaceBorder = this.documentStyle.getPropertyValue('--surface-border');

  createUniqueUsersViewedChart = () => {
    try {
      this.uniqueUsersCount30Days = this.data.trackingRecords.reduce((count, record) => record.days === 30 ? ++count : count, 0);
    } catch (e) {
      this.uniqueUsersCount30Days = 0;
    }

    try {
      this.uniqueUsersCount60Days = this.data.trackingRecords.reduce((count, record) => record.days === 60 ? ++count : count, 0);
    } catch (e) {
      this.uniqueUsersCount60Days = 0;
    }

    try {
      this.uniqueUsersCount90Days = this.data.trackingRecords.reduce((count, record) => record.days === 90 ? ++count : count, 0);
    } catch (e) {
      this.uniqueUsersCount90Days = 0;
    }

    let me = this;
    this.uniqueUsersViewedCountChart = new Chart("pvi-chart-unique-users-viewed-count", {
      type: 'line',
      data: {
        labels: ['30 Days', '60 Days', '90 Days'],
        datasets: [
          {
            label: "Total count of unique users",
            data: [this.uniqueUsersCount30Days, this.uniqueUsersCount60Days, this.uniqueUsersCount90Days],
            backgroundColor: this.documentStyle.getPropertyValue('--blue-500'),
            borderColor: this.documentStyle.getPropertyValue('--blue-500'),
            pointBackgroundColor: this.documentStyle.getPropertyValue('--blue-500'),
            borderWidth: 2,
          }
        ]
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        aspectRatio: 2.5,
        animations: {
          tension: {
            duration: 2000,
            easing: 'linear',
            from: 1,
            to: 0,
            loop: false
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              color: 'rgba(0, 0, 0, 0.1)',
            },
            ticks: {
              callback: function (value: any, index: any, values: any) {
                // when the floored value is the same as the value we have a whole number
                if (Math.floor(value) === value) {
                  return value;
                }
              }
            }
          },
          x: {
            grid: {
              color: 'rgba(0, 0, 0, 0.1)',
            }
          }
        },
        plugins: {
          tooltip: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            bodyFont: {
              size: 12,
            },
            titleFont: {
              size: 12,
              weight: 'bold',
            }
          },
          legend: {
            labels: {
              font: {
                size: 12,
              }
            }
          },
          datalabels: {
            anchor: 'center',
            align: 'center',
            font: {
              weight: 'bold'
            }
          }
        }
      }
    });
  }

  createUsersViewedCountChart = () => {
    try {
      this.allUsersCount30Days = this.data.trackingRecords.filter((record) => {return record.days === 30}).reduce((accumulator, record) => accumulator + record.count, 0);
    } catch (e) {
      this.allUsersCount30Days = 0;
    }

    try {
      this.allUsersCount60Days = this.data.trackingRecords.filter((record) => {return record.days === 60}).reduce((accumulator, record) => accumulator + record.count, 0);
    } catch (e) {
      this.allUsersCount60Days = 0;
    }

    try {
      this.allUsersCount90Days = this.data.trackingRecords.filter((record) => {return record.days === 90}).reduce((accumulator, record) => accumulator + record.count, 0);
    } catch (e) {
      this.allUsersCount90Days = 0;
    }

    try {
      this.loggedInUserCount30Days = this.data.trackingRecords.filter((record) => {return record.days === 30 && record.businessEntityId === this.userService.user.businessEntityId})[0].count;
    } catch (e) {
      this.loggedInUserCount30Days = 0;
    }

    try {
      this.loggedInUserCount60Days = this.data.trackingRecords.filter((record) => {return record.days === 60 && record.businessEntityId === this.userService.user.businessEntityId})[0].count;
    } catch (e) {
      this.loggedInUserCount60Days = 0;
    }

    try {
      this.loggedInUserCount90Days = this.data.trackingRecords.filter((record) => {return record.days === 90 && record.businessEntityId === this.userService.user.businessEntityId})[0].count;
    } catch (e) {
      this.loggedInUserCount90Days = 0;
    }

    let me = this;
    this.allUsersViewedCountChart = new Chart("pvi-chart-users-viewed-count", {
      type: 'line',
      data: {
        labels: ['30 Days', '60 Days', '90 Days'],
        datasets: [
          {
            label: "Total viewed count by all users",
            data: [this.allUsersCount30Days, this.allUsersCount60Days, this.allUsersCount90Days],
            backgroundColor: this.documentStyle.getPropertyValue('--green-500'),
            borderColor: this.documentStyle.getPropertyValue('--green-500'),
            pointBackgroundColor: this.documentStyle.getPropertyValue('--green-500'),
            borderWidth: 2
          },
          {
            label: "Your total viewed count",
            data: [this.loggedInUserCount30Days, this.loggedInUserCount60Days, this.loggedInUserCount90Days],
            backgroundColor: this.documentStyle.getPropertyValue('--yellow-500'),
            borderColor: this.documentStyle.getPropertyValue('--yellow-500'),
            pointBackgroundColor: this.documentStyle.getPropertyValue('--yellow-500'),
            borderWidth: 2
          }
        ]
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        aspectRatio: 2.5,
        animations: {
          tension: {
            duration: 2000,
            easing: 'linear',
            from: 1,
            to: 0,
            loop: false
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              color: 'rgba(0, 0, 0, 0.1)',
            },
            ticks: {
              callback: function (value: any, index: any, values: any) {
                // when the floored value is the same as the value we have a whole number
                if (Math.floor(value) === value) {
                  return value;
                }
              }
            }
          },
          x: {
            grid: {
              color: 'rgba(0, 0, 0, 0.1)',
            }
          }
        },
        plugins: {
          tooltip: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            bodyFont: {
              size: 12,
            },
            titleFont: {
              size: 12,
              weight: 'bold',
            }
          },
          legend: {
            labels: {
              font: {
                size: 12,
              }
            }
          },
          datalabels: {
            anchor: 'center',
            align: 'center',
            font: {
              weight: 'bold'
            }
          }
        }
      }
    });
  }

  ngOnInit(): void {
    this.loggerService.logDebug(`creating property viewed insights for pin ${this.data.pin}`);
    this.createUniqueUsersViewedChart();
    this.createUsersViewedCountChart();
  }

}
