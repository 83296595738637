import {FusionChartLayout} from "./fusion-chart-layout";
import {FusionChartData} from "./fusion-chart-data";
import {FusionChartAnnotation} from "./fusion-chart-annotation";
import {FusionChartDataset} from "./fusion-chart-dataset";
import {FusionChartCategory} from "./fusion-chart-category";

export class FusionChartDataSource {

  chart: FusionChartLayout;
  data: FusionChartData[];
  annotations: FusionChartAnnotation;
  categories: FusionChartCategory[];
  dataset: any;

}
