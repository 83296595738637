import {BaseModel} from "../base-model";

export class ZendeskConnectionParam extends BaseModel {

  constructor(defaultUrl?: string) {
    super();
    if(defaultUrl){
      this.url = defaultUrl;
    }
  }

  returnTo: string;
  jwtToken: string;
  url: string;

}
