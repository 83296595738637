import {Injectable} from "@angular/core";
import {LoggerService} from "./log/logger.service";
import {environment} from "../../../environments/environment";

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor(private loggerService: LoggerService) {
    if (environment.isGoogleAnalyticsEnabled) {
      this.loggerService.logInfo('Google Analytics is enabled. Events will be sent to Google Analytics');
    } else {
      this.loggerService.logInfo('Google Analytics is disabled. No events will be sent to Google Analytics');
    }
  }

  // ToDo investigate if this event can be tracked easier from app.component with something like this.router.events....
  public navigate(category: string, label?: string, value: number = 0) {
    this.sendEvent('Navigate', category, label, value);
  }

  public openModal(category: string, label?: string, value: number = 0) {
    this.sendEvent('OpenModal', category, label, value);
  }

  public openPage(category: string, label?: string, value: number = 0) {
    this.sendEvent('OpenPage', category, label, value);
  }

  public openSection(category: string, label?: string, value: number = 0) {
    this.sendEvent('OpenSection', category, label, value);
  }

  public buttonClicked(category: string, label?: string, value: number = 0) {
    this.loggerService.logDebug('Button clicked', category, label, value);
    this.sendEvent('ButtonClicked', category, label, value);
  }

  public sendEvent(action: string, category: string, label?: string, value: number = 0) {
    if (environment.isGoogleAnalyticsEnabled) {
      try {
        gtag('event', action, {
          'event_category': category,
          'event_label': label,
          'value': value
        });
      } catch (ex) {
        this.loggerService.logError("Could not send event to GoogleAnalytics", ex);
      }
    }
  }
}
