import {ChangeDetectionStrategy, Component, Input, SimpleChanges} from '@angular/core';
import {FusionChartDataSource} from "../../../../../../../core/model/fusion-chart/fusion-chart-data-source";
import {DemographicsPopulationItem} from "../../../../../../../core/model/demographics/demographics-population-item";
import {DemographicsChartsUtility} from "../../../../../../../shared/utility/fusion-charts/demographics-charts.utility";
import {DataService} from "../../../../../../../shared/service/data.service";
import {BaseChartComponent} from "../../../../../../../core/component/base-chart/base-chart.component";
import {FusionChartsModule} from "angular-fusioncharts";
import {DecimalPipe} from "@angular/common";

@Component({
  selector: 'gema3g-marital-status-item',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './marital-status-item.component.html',
  imports: [
    FusionChartsModule,
    DecimalPipe
  ],
  styleUrls: ['./marital-status-item.component.scss']
})
export class MaritalStatusItemComponent extends BaseChartComponent{
  @Input() maritalStatusItem : DemographicsPopulationItem;
  @Input() key: string;

  maritalStatus: FusionChartDataSource = new FusionChartDataSource();

  override loadChartData() {
    if(!this.pinOrArn || this.pinOrArn != this.maritalStatusItem?.pinOrArn) {
      this.maritalStatus = DemographicsChartsUtility.maritalStatusDataSource(DataService.DEFAULT_CHART_FONT, this.key, this.maritalStatusItem, window.innerWidth);
      this.pinOrArn = this.maritalStatusItem?.pinOrArn;
    }
  }

  calculateReminderValue(percentageOfSingle: number, percentageOfMarried: number, percentageOfSeparatedOrDivorced: number) {
    return (100 - Math.round(percentageOfSingle) - Math.round(percentageOfMarried) - Math.round(percentageOfSeparatedOrDivorced));
  }
}
