import dayjs from "dayjs";
import {FusionChartDataSource} from "../../../core/model/fusion-chart/fusion-chart-data-source";
import {FusionChartLayout} from "../../../core/model/fusion-chart/fusion-chart-layout";
import {Assessment} from "../../../core/model/assessment/assessment";
import {UserAccessControl} from "../../../core/model/user/user-access-control";
import {FusionChartCategory} from "../../../core/model/fusion-chart/fusion-chart-category";
import {FusionChartCategoryData} from "../../../core/model/fusion-chart/fusion-chart-category-data";
import {SalesHistory} from "../../../core/model/sale/sales-history";
import {FusionChartDataset} from "../../../core/model/fusion-chart/fusion-chart-dataset";
import {FusionChartDatasetData} from "../../../core/model/fusion-chart/fusion-chart-dataset-data";

export class AssessmentChartsUtility {
  static assessmentDataSource(fontSize: string, assessments: Assessment[], salesHistory: SalesHistory[], userAccessControls: UserAccessControl): FusionChartDataSource {

    const minValueBuffer = 100000;
    const maxValueBuffer = 100000;

    const chartDataSource = new FusionChartDataSource();
    if (!assessments || assessments.length == 0) {
      return chartDataSource;
    }
    let categories: FusionChartCategory[] = [];
    const chart = new FusionChartLayout();
    chart.connectNullData = "1"; // always connect data points in the line chart even when assessment years are not provided
    chart.caption = "";
    chart.numberPrefix = "$";
    chart.plotgradientColor = "";
    chart.bgColor = "FFFFFF";
    chart.showAlternateHGridColor = "0";
    chart.divlineColor = "CCCCCC";
    chart.showValues = assessments.length > 1 ? "0" : "1";
    chart.showCanvasBorder = "0";
    chart.canvasBorderAlpha = "0";
    chart.canvasBorderColor = "CCCCCC";
    chart.canvasBorderThickness = "1";
    chart.captionPadding = "30";
    chart.lineThickness = "2";
    chart.yaxisValuesPadding = "15";
    chart.legendShadow = "0";
    chart.legendBorderAlpha = "0";
    chart.paletteColors = "#f8bd19,#008ee4,#33bdda,#e44a00,#6baa01,#583e78";
    chart.showBorder = "0";
    chart.showLabels = "1";
    chart.drawAnchors = "1";
    chart.anchorRadius = "5";
    chart.showShadow = "0";
    chart.chartBottomMargin = "20";
    chart.canvasBottomMargin = "30";


    chart.labelFont = '"Open Sans Reg", sans-serif';
    chart.baseFont = '"Open Sans Reg", sans-serif';
    chart.baseFontSize = "11";

    chart.valueBorderThickness = "1";
    chart.valueBorderPadding = "4";
    chart.valueFontColor = "#f78f1e";
    chart.valueFontSize = "12";
    chart.valueFontBold = "0";
    chart.valueBorderColor = "#f78f1e";
    chart.valueBorderRadius = "2";

    let assessStartYear: number = 0;
    let assessEndYear: number = 0;
    let assessmentCurrentYear: number = 0;
    var labelsBase = "";
    var labelsPhasedIn = "";
    let series: FusionChartDataset[] = [];

    if (assessments && assessments.length > 0) {
      assessStartYear = assessments[0].baseInfo.assessmentStartYear;
      assessEndYear = assessments[0].baseInfo.assessmentEndYear;
      assessmentCurrentYear = assessments[0].baseInfo.assessmentCurrentYear;
      labelsBase = assessments[0].baseInfo.labelsBaseShort;
      labelsPhasedIn = assessments[0].baseInfo.labelsPhasedInShort;
    }

    let amounts: number[] = [];
    // For ARN only properties with no assessment history
    if (assessments.length > 0 && assessments[0].baseInfo.propertyValues == null && assessments[0].baseInfo.destinationValue != null) {
      var singleAssesmentValue = assessments[0].baseInfo.destinationValue;
      amounts.push(singleAssesmentValue);
    }


    if (assessments && assessments.length > 0) {
      for (var i = 0; i < assessments.length; i++) {
        let assessment = assessments[i];

        amounts = []; // ported from 2g

        let xAxisYearValues: number[] = [];

        if (userAccessControls.mpacEnhancedDetailsAccess && assessment.enhancedAssessment && assessment.enhancedAssessment.assessment) {
          //based on data feed containing 4 data points (each year in the four-year assessment cycle)
          if (assessment.enhancedAssessment.assessment.phaseIn && assessment.enhancedAssessment.assessment.phaseIn.length > 0) {
            for (var j = 0; j < assessment.enhancedAssessment.assessment.phaseIn.length; j++) {
              if (assessment.enhancedAssessment.assessment.phaseIn[j].assessmentValue != null) {
                amounts.push(Number(assessment.enhancedAssessment.assessment.phaseIn[j].assessmentValue));
              }

              xAxisYearValues.push(Number(assessment.enhancedAssessment.assessment.phaseIn[j].taxYear));
            }
          }

          // set lowest and highest amounts in the y-axis
          if (amounts && amounts.length > 0) {
            //chart.yaxisminvalue = Math.min(...amounts);
            chart.yaxisminvalue = Math.min.apply(Math, amounts);

            if (Number(chart.yaxisminvalue) - minValueBuffer > 0) {
              chart.yaxisminvalue = (Number(chart.yaxisminvalue) - minValueBuffer).toString();
            }

            //chart.yaxismaxvalue = (Number(Math.max(...amounts)) + maxValueBuffer).toString();
            chart.yaxismaxvalue = (Number(Math.max.apply(Math, amounts)) + maxValueBuffer).toString();
          } else {
            chart.yaxisminvalue = "0";
            chart.yaxismaxvalue = maxValueBuffer.toString();
          }

          // create the x-axis labels; each year gathered above becomes a label
          let category: FusionChartCategoryData[] = [];

          for (var j = 0; j < xAxisYearValues.length; j++) {

            let categoryLabel =
              (xAxisYearValues[j] == assessEndYear) ?
                assessEndYear.toString() + "<br/>Assessed Value<br/>" + labelsBase :
                xAxisYearValues[j].toString() + "<br/>Phased-In Value<br/>";

            var categoryData = new FusionChartCategoryData(categoryLabel);
            category.push(categoryData);
          }

          categories.push(new FusionChartCategory(category));

          // create the y-axis data points for each sale history and assessment on the property
          // note: each assessment record (consisting of assessment years) is in its own line chart y-axis series
          // note: each sale history data (each sale transaction) is in its own area chart y-axis series


          //series.push(object);

          // create the data points (values) in the series
          // important note: in a line chart, the number of y-axis data points must match the number of x-axis labels
          var data = [];

          for (var j = 0; j < xAxisYearValues.length; j++) {
            let dataValue: FusionChartDatasetData;
            var year = xAxisYearValues[j];

            // based on data feed containing 4 data points (each year in the four-year assessment cycle)
            var propertyValue = amounts[j];

            if (propertyValue) {
              dataValue = new FusionChartDatasetData(propertyValue);

              if (year == assessmentCurrentYear) {
                dataValue.valuePosition = "BELOW";
                dataValue.valueBgColor = '#eeeee';
                dataValue.valueFontSize = 50;
              }

              data.push(dataValue);
            } else {
              dataValue = new FusionChartDatasetData(0); // empty data point required to match y-axis data to x-axis year label
              data.push(dataValue);
            }
          }

          var seriesData = new FusionChartDataset(data);
          seriesData.seriesname = "Assessment " + (i + 1);
          seriesData.renderAs = "line"; // assessments will be rendered with line charts

          // keep track of visible assessments in the chart

          series[i] = seriesData;

        } else {
          // based on data feed containing 2 data points (current year and last year)
          amounts.push(assessment.baseInfo.currentValue);
          amounts.push(assessment.baseInfo.destinationValue);

          /*
           * based on data feed containing 4 data points (each year in the four-year assessment cycle) if (assessment.baseInfo.propertyValues &&
           * assessment.baseInfo.propertyValues.length > 0) { for (var j = 0; j < assessment.baseInfo.propertyValues.length; j++) { if (assessment.baseInfo.propertyValues[j].value !=
           * null) { amounts.push(assessment.baseInfo.propertyValues[j].value); } } }
           */

          // get sales history amounts
          // we are checking assessStartYear because we will only show the sales history chart when there are assessments
          if (salesHistory && salesHistory.length > 0 && assessStartYear && assessStartYear > 0) {
            for (var j = 0; j < salesHistory.length; j++) {
              var sale = salesHistory[j];
              var saleYear = dayjs(sale.polInstrument.registrationDate, "YYYY-MM-DD").year();

              if (saleYear >= (assessStartYear - 1) && sale.polInstrument.considerationAmt != null) { // we are only showing the sales for the year before the start of the assessment year
                // and onwards
                amounts.push(sale.polInstrument.considerationAmt);
              }
            }
          }

          // set lowest and highest amounts in the y-axis
          var sortedAmounts = amounts.sort();
          if (sortedAmounts && sortedAmounts.length > 0) {
            chart.yaxisminvalue = sortedAmounts[0];
            /*if (chart.yaxisminvalue - minValueBuffer > 0) {
              chart.yaxisminvalue = chart.yaxisminvalue - minValueBuffer;
            }
            chart.yaxismaxvalue = sortedAmounts[sortedAmounts.length - 1] + maxValueBuffer;*/

            if (Number(chart.yaxisminvalue) - minValueBuffer > 0) {
              chart.yaxisminvalue = (Number(chart.yaxisminvalue) - minValueBuffer).toString();
            }

            chart.yaxismaxvalue = (Number(sortedAmounts[sortedAmounts.length - 1]) + maxValueBuffer).toString();
          } else {
            chart.yaxisminvalue = "0";
            chart.yaxismaxvalue = maxValueBuffer.toString();
          }

          // get the sales history year values
          if (salesHistory && salesHistory.length > 0 && assessStartYear && assessStartYear > 0) {
            for (var j = 0; j < salesHistory.length; j++) {
              var sale = salesHistory[j];
              var saleYear = dayjs(sale.polInstrument.registrationDate, "YYYY-MM-DD").year();

              if (saleYear >= (assessStartYear - 1)) {
                xAxisYearValues.push(saleYear);
              }
            }
          }

          // get the start and end assessment year values
          // note: we currently only have these two data points (representing two assessment years) in the assessment_data table.
          // note: the logic below to get the year values must be changed should more assessment year data points be added in the future.
          if (assessments && assessments.length > 0) {
            for (var year = assessStartYear; year <= assessEndYear; year++) {
              xAxisYearValues.push(year);
            }
          }

          var xAxisUniqueYearValues = []; // year array to contain all unique sales history (year before start of assessment year) and assessment year values
          // sort all years in ascending order
          xAxisUniqueYearValues = xAxisYearValues.sort();

          // create the x-axis labels; each year gathered above becomes a label
          var category: FusionChartCategoryData[] = [];
          for (var j = 0; j < xAxisUniqueYearValues.length; j++) {
            let categoryLabel: string;
            if (xAxisUniqueYearValues[j] == assessmentCurrentYear) {
              categoryLabel = xAxisUniqueYearValues[j].toString() + "<br/>Phased-In Value<br/>" + labelsPhasedIn;
            } else if (xAxisUniqueYearValues[j] == assessEndYear) {
              categoryLabel = assessEndYear.toString() + "<br/>Assessed Value<br/>" + labelsBase;
            } else {
              categoryLabel = xAxisUniqueYearValues[j].toString();
            }

            let categoryData = new FusionChartCategoryData(categoryLabel);
            category.push(categoryData);

          }

          categories.push(new FusionChartCategory(category));

          // create the y-axis data points for each sale history and assessment on the property
          // note: each assessment record (consisting of assessment years) is in its own line chart y-axis series
          // note: each sale history data (each sale transaction) is in its own area chart y-axis series

          // create the data points (values) in the series
          // important note: in a line chart, the number of y-axis data points must match the number of x-axis labels
          var data = [];
          for (var j = 0; j < xAxisUniqueYearValues.length; j++) {
            var dataValue;
            var year = xAxisUniqueYearValues[j];

            if (year == assessmentCurrentYear || year == assessEndYear) {
              /*
               * //based on data feed containing 2 data points (current year and last year) if (year == assessEndYear) { dataValue = new Object(); dataValue.value =
               * assessments[i].baseInfo.destinationValue.toString(); data.push(dataValue); } else if (year == now.year()) { dataValue = new Object(); dataValue.value =
               * assessments[i].baseInfo.currentValue.toString(); dataValue.valuePosition = "BELOW"; dataValue.valueBgColor = '#eeeee'; dataValue.valueFontSize = 50;
               * data.push(dataValue); } else { dataValue = new Object(); dataValue.value = ""; //empty data point required to match y-axis data to x-axis year label
               * data.push(dataValue); }
               */

              // based on data feed containing 4 data points (each year in the four-year assessment cycle)
              var propertyValue = this.getPropertyValueForAssessmentYear(assessments[i], year);
              if (propertyValue) {
                dataValue = new FusionChartDatasetData(propertyValue);

                if (year == assessmentCurrentYear) {
                  dataValue.valuePosition = "BELOW";
                  dataValue.valueBgColor = '#eeeee';
                  dataValue.valueFontSize = 50;
                }

                data.push(dataValue);

              } else {
                dataValue = new FusionChartDatasetData(''); // empty data point required to match y-axis data to x-axis year label
                data.push(dataValue);
              }

            } else {
              dataValue = new FusionChartDatasetData(''); // empty data point required to match y-axis data to x-axis year label
              data.push(dataValue);
            }
          }

          let seriesData = {
            'seriesname': "Assessment " + (i + 1),
            'renderAs': "line", // assessments will be rendered with line charts
            'data': data
          }

          series[i] = seriesData;
        }
      }

    }

    chartDataSource.chart = chart;
    chartDataSource.categories = categories;
    chartDataSource.dataset = series;

    return chartDataSource;

  }

  static getPropertyValueForAssessmentYear(assessment: Assessment, year: number): number {
    let propertyValue: number = 0;

    if (assessment?.baseInfo?.propertyValues?.length) {
      for (var j = 0; j < assessment.baseInfo.propertyValues.length; j++) {
        if (assessment.baseInfo.propertyValues[j].id.year == year) {
          propertyValue = assessment.baseInfo.propertyValues[j].value;
          break;
        }
      }
      // for ARN only properties that need a single value in the assesment chart
    } else if (assessment.baseInfo.propertyValues == null && assessment.baseInfo.destinationValue != null) {

      propertyValue = assessment.baseInfo.destinationValue;
    }

    return propertyValue;
  }
}
