<div class="gema3g-ss-container">
  <div class="gema3g-ss-container__inner">

    <div class="gema3g-ss-container__inner-grid">

      <div class="l-column">
        <h4 id="ss-h4-av-{{assessment.rollNumber}}">Assessed Value</h4>
      </div>

      <div class="m-column">
        <div class="flex">
          <span id="ss-av-{{assessment.rollNumber}}" class="ml-auto">{{(assessment.baseInfo?.destinationValue) ? (assessment.baseInfo?.destinationValue | currency) : "N/A"}}, {{(assessment.baseInfo?.labelsBaseShort) ? assessment.baseInfo?.labelsBaseShort : "N/A"}}</span>
        </div>
      </div>

    </div>

  </div>
</div>
