import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'metersToFeet'
})
export class MetersToFeetPipe implements PipeTransform {

  conversionRate: number = 3.28084;

  transform(input: any, symbol: string, decimals: number): any {

    if (!isNaN(input)) {
      let n: number = parseFloat(input) * this.conversionRate;
      let nStr: string = n.toFixed(decimals);

      nStr += '';
      var x = nStr.split('.');
      var x1 = x[0];
      var x2 = x.length > 1 ? '.' + x[1] : '';
      var rgx = /(\d+)(\d{3})/;

      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
      }
      var out = x1 + x2;

      return out + " " + symbol;
    } else {
      return input;
    }
  }

}



