import { BaseModel } from "../base-model";

export class RataReportPeriod extends BaseModel {
    constructor(rrPeriod?: RataReportPeriod) {
        super(rrPeriod);
    }
    
    processId: string;
    reportingPeriodStart: number;
    reportingPeriodEnd: number;
}