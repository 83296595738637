import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {SearchResultOrder} from "../search-results-container/search-result-order";

@Component({
  selector: 'gema3g-sort-search-results',
  templateUrl: './sort-search-results.component.html',
  styleUrls: ['./sort-search-results.component.scss']
})
export class SortSearchResultsComponent implements AfterViewInit {

  @Output() onSortingSelected = new EventEmitter<SearchResultOrder>();
  @Output() onClickEvent = new EventEmitter<any>();
  @Input() sortingOptions = SearchResultOrder.DropdownValues;
  @Input() defaultSortingOption: SearchResultOrder;
  selectedSortingOption: SearchResultOrder;

  onSortingChange() {
    this.onSortingSelected.emit(this.selectedSortingOption);
  }

  onClick = () => {
    this.onClickEvent.emit();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.defaultSortingOption) {
        this.selectedSortingOption = this.defaultSortingOption;
        this.onSortingChange();
      }
    }, 100);
  }

}
