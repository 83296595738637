import {BaseModel} from "../base-model";
import {LicenseCounter} from "./license-counter";
import {LicenseCounterTypeEnum} from "../../enum/license-counter-type-enum";

export class LicenseCounters extends BaseModel {

  constructor(licenseCounters: LicenseCounter[]) {
    super();
    if (licenseCounters && licenseCounters.length > 0) {
      this.counters = licenseCounters.map(value => new LicenseCounter(value));
    }
  }

  counters: LicenseCounter[] = [];

  get propertyReportCounter(): LicenseCounter | undefined {
    return this.counters.find(counter => counter.counterType === LicenseCounterTypeEnum.PROPERTY_DETAILS_REPORT);
  }

  get comparablesSearchCounter(): LicenseCounter | undefined {
    return this.counters.find(counter => counter.counterType === LicenseCounterTypeEnum.COMPARABLES_SEARCH);
  }
}
