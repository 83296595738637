<div class="container">
    <div class="thumbnail">
        @if (staticStreetViewThumbnailImageUrl() && staticStreetViewThumbnailImageUrl()!.length > 0) {
            <img src='{{staticStreetViewThumbnailImageUrl()}}' />
        } @else {
            <img src='{{noThumbnailImage}}' />
        }
    </div>
    <div class="data">
        @if (viewUsage == 'recentlySearched') {
            <div class="identifier text-os-bold">
                {{resultRow.text}}
            </div>
            <h5>
            @if (resultRow.lro) {
                <div class="">
                    in {{resultRow.lroName}}, LRO {{resultRow.lro | trim}}
                    <span class="result-item-separator-fa-icon">
                        <fa-icon id="ob-icon-circle" class="fa-2xs" [icon]="faCircle"></fa-icon>
                    </span>
                    <span>
                        in {{resultRow.categoryType}} Search
                    </span>
                </div>
            } @else {
                in {{resultRow.categoryType}} Search
            }
            </h5>
        }

        @if (viewUsage == 'recentlyViewed') {
            <div class="identifier text-os-bold">
                @if (resultRow.text) {
                    {{resultRow.text}}
                } @else {
                    {{resultRow.pin}}
                }
            </div>
            <div class="days-elapsed">
                <h5>
                @if (resultRow.text) {
                    {{resultRow.pin}}
                    <span class="result-item-separator-fa-icon">
                        <fa-icon id="ob-icon-circle" class="fa-2xs" [icon]="faCircle"></fa-icon>
                    </span>
                }
                @if (resultRow.daysElapsed && resultRow.daysElapsed > 0) {
                    <span>
                    {{(resultRow.daysElapsed && resultRow.daysElapsed > 0)?
                    (resultRow.daysElapsed == 1) ? '1 day ago' : resultRow.daysElapsed
                    + ' days ago' : ''}}
                    </span>
                } @else {
                    <span> < 24 hrs ago</span>
                }
                </h5>
            </div>
        }
    </div>
</div>