<div id="mainmap-controls-container" #mainMapControlsContainer class="main-map-controls" [ngClass]="{'main-map-contols-left': isMapControlsMoved}">

  <!-- hoodq icon -->
  @if (hasAccesstoHoodQSchoolCatchmentMap) {
    <img id="mainmap-img-schools" src="assets/img/svg/map/icon_schools.svg" (click)="onHoodQMapOpened($event)" [ngClass]="['hoodq']"
      [satPopoverAnchor]="schoolMapPopover"
      (mouseover)="schoolMapPopover.open()"
      (mouseout)="schoolMapPopover.close()">
      <sat-popover #schoolMapPopover [autoFocus]="false" [restoreFocus]="false" verticalAlign="below" openTransition="500ms ease-out" closeTransition="100ms ease-in">
        <gema3g-tooltip [content]="schoolMapToolTip"></gema3g-tooltip>
      </sat-popover>
    }

    <!-- map layer control icon -->
    <img id="mainmap-img-layercontrol" src="assets/img/svg/map/icon_map_layercontrol.svg"
      [ngClass]="['control','control-off']"
      [matMenuTriggerFor]="mapLayerMenu"
      (menuOpened)="openedMenu('mapLayerMenu')"
      #mapLayerMenuTrigger="matMenuTrigger"
      [satPopoverAnchor]="mapLayersPopover"
      (mouseover)="mapLayersPopover.open()"
      (mouseout)="mapLayersPopover.close()">
      <sat-popover #mapLayersPopover [autoFocus]="false" [restoreFocus]="false" verticalAlign="below" openTransition="500ms ease-out" closeTransition="100ms ease-in">
        <gema3g-tooltip [content]="mapLayersToolTip"></gema3g-tooltip>
      </sat-popover>

      <!-- map layers -->
      <mat-menu #mapLayerMenu="matMenu" class="map-layer-menu p-5" [hasBackdrop]="false">

        <div class="map-menu-list-header" (click)="$event.stopPropagation();">
          <div class="label">Map Layers</div>
          <div id="mainmap-btn-close-maplayer" (click)="closedMenu('mapLayerMenu')">
            <fa-icon class="fa-lg text-white ml-8" [icon]="faCircleXmark"></fa-icon>
          </div>
        </div>

        <div class="control-row" (click)="$event.stopPropagation();">
          <div class="icon-label">
            <mat-icon id="mainmap-icon-ownership-layers" [ngStyle]="mapLayerVisibility.ownership? {'color':'#60D737'} : {'color':'#E1E1E1'}">layers</mat-icon>
            <label id="mainmap-label-ownership" pTooltip="Levels {{OT.MIN_ZOOM}} to {{OT.MAX_ZOOM}}">Ownership</label>
          </div>
          <mat-slide-toggle id="mainmap-toggle-ownership-visibility" name="OWNER" [(ngModel)]="mapLayerVisibility.ownership" (change)="toggleMapLayerVisibility($event)" color="primary"></mat-slide-toggle>
        </div>
        <div class="control-row" (click)="$event.stopPropagation();">
          <div class="icon-label">
            <mat-icon id="mainmap-icon-assessment-layers" [ngStyle]="mapLayerVisibility.assessment? {'color':'#FFB96F'} : {'color':'#E1E1E1'}">layers</mat-icon>
            <label id="mainmap-label-assessment" pTooltip="Levels {{AT.MIN_ZOOM}} to {{AT.MAX_ZOOM}}">Assessment</label>
          </div>
          <mat-slide-toggle id="mainmap-toggle-assessment-visibility" name="ASSESS" [(ngModel)]="mapLayerVisibility.assessment" (change)="toggleMapLayerVisibility($event)" color="primary"></mat-slide-toggle>
        </div>
        <div class="control-row" (click)="$event.stopPropagation();">
          <div class="icon-label">
            <mat-icon id="mainmap-icon-address-layers" [ngStyle]="mapLayerVisibility.address? {'color':'#8A8888'} : {'color':'#E1E1E1'}">layers</mat-icon>
            <label id="mainmap-label-street-#" pTooltip="Levels {{ADDT.MIN_ZOOM}} to {{ADDT.MAX_ZOOM}}">Street #</label>
          </div>
          <mat-slide-toggle id="mainmap-toggle-address-visibility" name="ADDR" [(ngModel)]="mapLayerVisibility.address" (change)="toggleMapLayerVisibility($event)" color="primary"></mat-slide-toggle>
        </div>
        <div class="control-row" (click)="$event.stopPropagation();">
          <div class="icon-label">
            <mat-icon id="mainmap-icon-lotConcession-layers" [ngStyle]="mapLayerVisibility.lotConcession? {'color':'#0000FF'} : {'color':'#E1E1E1'}">layers</mat-icon>
            <label id="mainmap-label-lotConcession" pTooltip="Levels {{LCT.MIN_ZOOM}} to {{LCT.MAX_ZOOM}}">Lot/Concession</label>
          </div>
          <mat-slide-toggle id="mainmap-toggle-lotConsession-visibility" name="CONN" [(ngModel)]="mapLayerVisibility.lotConcession" (change)="toggleMapLayerVisibility($event)" color="primary"></mat-slide-toggle>
        </div>
        @if (userAccessControls.mpsThematicMapping) {
          <div class="control-row" (click)="$event.stopPropagation();">
            <div class="icon-label">
              <mat-icon id="mainmap-icon-thematic-layers" [ngStyle]="mapLayerVisibility.thematic? {'color':'#FFEAEE'} : {'color':'#E1E1E1'}">layers</mat-icon>
              <label id="mainmap-label-mps-thematic" pTooltip="Levels {{TT.MIN_ZOOM}} to {{TT.MAX_ZOOM}}">MPS Thematic</label>
            </div>
            <mat-slide-toggle id="mainmap-toggle-thematic-visibility" name="THEMATIC_PT" [(ngModel)]="mapLayerVisibility.thematic" (change)="toggleMapLayerVisibility($event)" color="primary"></mat-slide-toggle>
          </div>
        }

        @if (mapLayerVisibility.thematic) {
          <div class="control-row" (click)="$event.stopPropagation();">
            <div class="section-label">Opacity</div>
            <mat-slider min="0" max="1" step="0.1"  showTickMarks discrete [displayWith]="thematicOpacityLabel">
              <input id="mainmap-slider-thematic" matSliderThumb [(ngModel)]="opacityValue">
            </mat-slider>
          </div>
        }

        @if (mapLayerVisibility.thematic) {
          <div class="property-type-legend">
            <div class="control-row" (click)="$event.stopPropagation();">
              <div class="icon-label">
                <mat-icon id="mainmap-icon-residential-layers" [ngStyle]="{'color':'#FFD7DD'}">layers</mat-icon>
                <label id="mainmap-label-residential">Residential</label>
              </div>
              <div>&nbsp;</div>
            </div>
            <div class="control-row" (click)="$event.stopPropagation();">
              <div class="icon-label">
                <mat-icon id="mainmap-icon-industrial-layers" [ngStyle]="{'color':'#CDCDCD'}">layers</mat-icon>
                <label id="mainmap-label-industrial">Industrial</label>
              </div>
              <div>&nbsp;</div>
            </div>
            <div class="control-row" (click)="$event.stopPropagation();">
              <div class="icon-label">
                <mat-icon id="mainmap-icon-commerial-layers" [ngStyle]="{'color':'#FFD57D'}">layers</mat-icon>
                <label id="mainmap-label-commercial">Commercial</label>
              </div>
              <div>&nbsp;</div>
            </div>
            <div class="control-row" (click)="$event.stopPropagation();">
              <div class="icon-label">
                <mat-icon id="mainmap-icon-farm-layers" [ngStyle]="{'color':'#C5D68F'}">layers</mat-icon>
                <label id="mainmap-label-farm">Farm</label>
              </div>
              <div>&nbsp;</div>
            </div>
            <div class="control-row" (click)="$event.stopPropagation();">
              <div class="icon-label">
                <mat-icon id="mainmap-icon-multi-residential-layers" [ngStyle]="{'color':'#815C42'}">layers</mat-icon>
                <label id="mainmap-label-multi-residential">Multi-Residential</label>
              </div>
              <div>&nbsp;</div>
            </div>
            <div class="control-row" (click)="$event.stopPropagation();">
              <div class="icon-label">
                <mat-icon id="mainmap-icon-other-layers" [ngStyle]="{'color':'#FFFEBB'}">layers</mat-icon>
                <label id="mainmap-label-other">Other</label>
              </div>
              <div>&nbsp;</div>
            </div>
          </div>
        }

        <mat-divider></mat-divider>
        @if (userAccessControls.showHeatmapAverageSales || userAccessControls.showHeatmapGrowthRate || userAccessControls.showHeatmapGrowthRate) {
          <div class="control-row">
            <div id="mainmap-div-block-heatmap" class="section-label bold">Block Heatmap</div>
          </div>
        }
        @if (userAccessControls.showHeatmapAverageSales) {
          <div class="control-row" (click)="$event.stopPropagation();">
            <div class="icon-label">
              <mat-icon id="mainmap-icon-average-sales-layers" [ngStyle]="mapLayerVisibility.heatMapAverageSales? {'color':'#FF0000'} : {'color':'#E1E1E1'}">layers</mat-icon>
              <label id="mainmap-label-average-sales" pTooltip="Levels {{DT.HEATMAP_MIN_ZOOM_LEVEL}} to {{DT.HEATMAP_MAX_ZOOM_LEVEL}}">Average Sales</label>
            </div>
            <mat-slide-toggle id="mainmap-toggle-average-sales" name="HM_AVERAGE_SALES" [(ngModel)]="mapLayerVisibility.heatMapAverageSales" (checked)="mapLayerVisibility.heatMapAverageSales" (change)="toggleMapLayerVisibility($event)" color="primary"></mat-slide-toggle>
          </div>
        }
        @if (mapLayerVisibility.heatMapAverageSales && userAccessControls.showHeatmapAverageSales) {
          <div class="heatmap-search-criteria get-dense-3">
            <div class="search-row search-row-bottom-padding" (click)="$event.stopPropagation();">
              <div class="label">
                <label id="mainmap-label-property-type" class="text">Property Type</label>
              </div>
              <div class="value">
                <mat-form-field id="mainmap-ff-property-type" appearance="outline" subscriptSizing="dynamic"> <!--starting Angular 15, subscriptSizing removes the spacing that's normally reserved for mat-error and mat-hint  -->
                  <mat-select id="mainmap-select-property-type" [(ngModel)]="mapLayerVisibility.heatMapAverageSalesPropertyType" (selectionChange)="onPropertyTypeSelected($event, 'HM_AVERAGE_SALES_PT')">
                    @for (option of hmPropertyType; track option) {
                      <mat-option id="mainmap-option-heatmap-{{option.value}}" [value]="option.value">{{option.viewValue}}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        }
        @if (mapLayerVisibility.heatMapAverageSales && userAccessControls.showHeatmapAverageSales) {
          <div class="heatmap-search-criteria get-dense-3">
            <div class="search-row" (click)="$event.stopPropagation();">
              <div class="label">
                <label id="mainmap-label-date-range" class="text">Date Range</label>
              </div>
              <div class="value">
                <mat-form-field id="mainmap-ff-date-range" appearance="outline" subscriptSizing="dynamic">
                  <mat-select id="mainmap-select-date-range" [(ngModel)]="mapLayerVisibility.heatMapAverageSalesDateRange" (selectionChange)="onDateRangeSelected($event, 'HM_AVERAGE_SALES_DR')">
                    @for (option of heatMapDateRanges; track option) {
                      <mat-option id="mainmap-option-heatmap-{{option.value}}" [value]="option.value">{{option.viewValue}}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        }

        @if (userAccessControls.showHeatmapGrowthRate) {
          <div class="control-row" (click)="$event.stopPropagation();">
            <div class="icon-label">
              <mat-icon id="mainmap-icon-growth-rate-layers" [ngStyle]="mapLayerVisibility.heatMapGrowthRate? {'color':'#FF0000'} : {'color':'#E1E1E1'}">layers</mat-icon>
              <label id="mainmap-label-growth-rate" pTooltip="Levels {{DT.HEATMAP_MIN_ZOOM_LEVEL}} to {{DT.HEATMAP_MAX_ZOOM_LEVEL}}">Growth Rate</label>
            </div>
            <mat-slide-toggle id="mainmap-toggle-growth-rate" name="HM_GROWTH_RATE" [(ngModel)]="mapLayerVisibility.heatMapGrowthRate" (checked)="mapLayerVisibility.heatMapGrowthRate" (change)="toggleMapLayerVisibility($event)" color="primary"></mat-slide-toggle>
          </div>
        }
        @if (mapLayerVisibility.heatMapGrowthRate && userAccessControls.showHeatmapGrowthRate) {
          <div class="heatmap-search-criteria get-dense-3">
            <div class="search-row search-row-bottom-padding" (click)="$event.stopPropagation();">
              <div class="label">
                <label id="mainmap-label-property-type" class="text">Property Type</label>
              </div>
              <div class="value">
                <mat-form-field id="mainmap-ff-growth-rate" appearance="outline" subscriptSizing="dynamic">
                  <mat-select id="mainmap-select-growth-rate" [(ngModel)]="mapLayerVisibility.heatMapGrowthRatePropertyType" (selectionChange)="onPropertyTypeSelected($event, 'HM_GROWTH_RATE_PT')">
                    @for (option of hmPropertyType; track option) {
                      <mat-option id="mainmap-option-heatmap-{{option.value}}" [value]="option.value">{{option.viewValue}}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        }
        @if (mapLayerVisibility.heatMapGrowthRate && userAccessControls.showHeatmapGrowthRate) {
          <div class="heatmap-search-criteria get-dense-3">
            <div class="search-row" (click)="$event.stopPropagation();">
              <div class="label">
                <label id="mainmap-label-date-range" class="text">Date Range</label>
              </div>
              <div class="value">
                <mat-form-field id="mainmap-ff-growth-date-range" appearance="outline" subscriptSizing="dynamic">
                  <mat-select id="mainmap-select-growth-date-range" [(ngModel)]="mapLayerVisibility.heatMapGrowthRateDateRange" (selectionChange)="onDateRangeSelected($event, 'HM_GROWTH_RATE_DR')">
                    @for (option of heatMapDateRanges; track option) {
                      <mat-option id="mainmap-option-heatmap-{{option.value}}" [value]="option.value">{{option.viewValue}}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        }

        @if (userAccessControls.showHeatmapGrowthRate) {
          <div class="control-row" (click)="$event.stopPropagation();">
            <div class="icon-label">
              <mat-icon id="mainmap-icon-market-turnover-layers" [ngStyle]="mapLayerVisibility.heatMapMarketTurnover? {'color':'#FF0000'} : {'color':'#E1E1E1'}">layers</mat-icon>
              <label id="mainmap-label-market-turnover" pTooltip="Levels {{DT.HEATMAP_MIN_ZOOM_LEVEL}} to {{DT.HEATMAP_MAX_ZOOM_LEVEL}}">Market Turnover</label>
            </div>
            <mat-slide-toggle id="mainmap-toggle-market-turnover" name="HM_MARKET_TURNOVER" [(ngModel)]="mapLayerVisibility.heatMapMarketTurnover" (checked)="mapLayerVisibility.heatMapMarketTurnover" (change)="toggleMapLayerVisibility($event)" color="primary"></mat-slide-toggle>
          </div>
        }
        @if (mapLayerVisibility.heatMapMarketTurnover && userAccessControls.showHeatmapMarketTurnover) {
          <div class="heatmap-search-criteria get-dense-3">
            <div class="search-row search-row-bottom-padding" (click)="$event.stopPropagation();">
              <div class="label">
                <label id="mainmap-label-property-type" class="text">Property Type</label>
              </div>
              <div class="value">
                <mat-form-field id="mainmap-ff-market-turnover" appearance="outline" subscriptSizing="dynamic">
                  <mat-select id="mainmap-select-market-turnover" [(ngModel)]="mapLayerVisibility.heatMapMarketTurnoverPropertyType" (selectionChange)="onPropertyTypeSelected($event, 'HM_MARKET_TURNOVER_PT')">
                    @for (option of hmPropertyType; track option) {
                      <mat-option id="mainmap-option-heatmap-{{option.value}}" [value]="option.value">{{option.viewValue}}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        }
        @if (mapLayerVisibility.heatMapMarketTurnover && userAccessControls.showHeatmapMarketTurnover) {
          <div class="heatmap-search-criteria get-dense-3">
            <div class="search-row" (click)="$event.stopPropagation();">
              <div class="label">
                <label id="mainmap-label-date-range" class="text">Date Range</label>
              </div>
              <div class="value">
                <mat-form-field id="mainmap-ff-turnover-date-range" appearance="outline" subscriptSizing="dynamic">
                  <mat-select id="mainmap-select-turnover-date-range" [(ngModel)]="mapLayerVisibility.heatMapMarketTurnoverDateRange" (selectionChange)="onDateRangeSelected($event, 'HM_MARKET_TURNOVER_DR')">
                    @for (option of heatMapDateRanges; track option) {
                      <mat-option id="mainmap-option-heatmap-{{option.value}}" [value]="option.value">{{option.viewValue}}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        }
        <mat-divider></mat-divider>
        @if (userAccessControls.heatmapRentalPercentGeo) {
          <div class="control-row">
            <div id="mainmap-div-nhood-heatmap" class="section-label bold">Neighbourhood Heatmap</div>
          </div>
        }
        @if (userAccessControls.heatmapRentalPercentGeo) {
          <div class="control-row" (click)="$event.stopPropagation();">
            <div class="icon-label">
              <mat-icon id="mainmap-icon-average-rental-percent-layers" [ngStyle]="mapLayerVisibility.heatMapAverageRentalPercentage? {'color':'#FF0000'} : {'color':'#E1E1E1'}">layers</mat-icon>
              <label id="mainmap-label-average-rental-percent" pTooltip="Levels {{DT.HEATMAP_NHOOD_MIN_ZOOM_LEVEL}} to {{DT.HEATMAP_MAX_ZOOM_LEVEL}}">Rental Percentage</label>
            </div>
            <mat-slide-toggle id="mainmap-toggle-average-rental-percent" name="HM_RENTAL_PERCENTAGE" [(ngModel)]="mapLayerVisibility.heatMapAverageRentalPercentage" (checked)="mapLayerVisibility.heatMapAverageRentalPercentage" (change)="toggleMapLayerVisibility($event)" color="primary"></mat-slide-toggle>
          </div>
        }

        <mat-divider></mat-divider>
        <div class="control-row" (click)="$event.stopPropagation();">
          <div class="icon-label">
            <mat-icon id="mainmap-icon-municipality-lro-layers" [ngStyle]="mapLayerVisibility.municipalityLro? {'color':'#F26C41'} : {'color':'#E1E1E1'}">layers</mat-icon>
            <label id="mainmap-label-lro" pTooltip="Levels {{MLT.MIN_ZOOM}} to {{MLT.MAX_ZOOM}}">LRO</label>
          </div>
          <mat-slide-toggle id="mainmap-toggle-lro-visibility" name="MUN_LRO" [(ngModel)]="mapLayerVisibility.municipalityLro" (change)="toggleMapLayerVisibility($event)" color="primary"></mat-slide-toggle>
        </div>
        <div class="control-row" (click)="$event.stopPropagation();">
          <div class="icon-label">
            <mat-icon id="mainmap-icon-municipality-lro-layers" [ngStyle]="mapLayerVisibility.municipalityLro? {'color':'#00A651'} : {'color':'#E1E1E1'}">layers</mat-icon>
            <label id="mainmap-label-municipality" pTooltip="Levels {{MLT.MIN_ZOOM}} to {{MLT.MAX_ZOOM}}">Municipality</label>
          </div>
        </div>

      </mat-menu>

      <!-- map type control icon -->
      <img id="mainmap-img-viewcontrol" src="assets/img/svg/map/icon_map_viewcontrol.svg"
        [ngClass]="['control','control-off']"
        [matMenuTriggerFor]="mapTypeMenu"
        (menuOpened)="openedMenu('mapTypeMenu')"
        #mapTypeMenuTrigger="matMenuTrigger"
        [satPopoverAnchor]="mapViewsPopover"
        (mouseover)="mapViewsPopover.open()"
        (mouseout)="mapViewsPopover.close()">
        <sat-popover #mapViewsPopover [autoFocus]="false" [restoreFocus]="false" verticalAlign="below" openTransition="500ms ease-out" closeTransition="100ms ease-in">
          <gema3g-tooltip [content]="mapViewsToolTip"></gema3g-tooltip>
        </sat-popover>

        <!-- map views -->
        <mat-menu id="mainmap-menu-views" #mapTypeMenu="matMenu" class="map-view-menu" [hasBackdrop]="false">
          <div class="flex flex-col">
            <div class="map-menu-list-header" (click)="$event.stopPropagation();">
              <div id="mainmap-div-map-views" class="label">Map Views</div>
              <div id="mainmap-btn-close-maptype" (click)="closedMenu('mapTypeMenu')">
                <fa-icon class="fa-lg text-white ml-8" [icon]="faCircleXmark"></fa-icon>
              </div>
            </div>
            <div (click)="onMapTypeSelected($event, MTE.STANDARD)" [ngClass]="mapType == MTE.STANDARD? 'opacity-100': 'opacity-30'">
              <div class="map-view-option">
                <div class="icon">
                  <img id="mainmap-img-mapview" src="assets/img/svg/map/icon_map_en-mapview.svg" width="30" height="30">
                </div>
                <div class="option-container">
                  <div id="mainmap-div-map" class="label bold">
                    Map
                  </div>
                  <div id="mainmap-div-standard-road-map" class="label">
                    Standard Road Map
                  </div>
                </div>
              </div>
            </div>
            <mat-divider></mat-divider>
            <div (click)="onMapTypeSelected($event, (mapTypeAerialViewLabeled)? MTE.AERIAL: MTE.SATELLITE)" [ngClass]="mapType == MTE.AERIAL || mapType == MTE.SATELLITE? 'opacity-100': 'opacity-30'">
              <div class="map-view-option">
                <div class="icon">
                  <img id="mainmap-img-aerialview" src="assets/img/svg/map/icon_map_en-aerialview.svg" width="30" height="30">
                </div>
                <div class="option-container">
                  <div id="mainmap-div-aerialview" class="label bold">
                    Aerial View
                  </div>
                  <div id="mainmap-div-BEV" class="label">
                    Bird's Eye View
                  </div>
                  <div class="tilt">
                    <div>
                      <mat-checkbox id="mainmap-checkbox-tilt-change" color="primary" [(ngModel)]="mapTypeAerialViewTilted" (change)="onAerialViewTiltChange($event)">
                        <span id="mainmap-label-45" class="label">45&#176;</span>
                      </mat-checkbox>
                    </div>
                    <div>
                      <mat-checkbox id="mainmap-checkbox-label-change" color="primary" [(ngModel)]="mapTypeAerialViewLabeled" (change)="onAerialViewLabelChange($event)">
                        <span id="mainmap-label-label" class="label">Label</span>
                      </mat-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            @if (userAccessControls.FBSAccess) {
              <mat-divider></mat-divider>
              <div (click)="onMapTypeSelected($event, MTE.ENHANCED_AERIAL)" [ngClass]="mapType == MTE.ENHANCED_AERIAL? 'opacity-100': 'opacity-30'">
                <div class="map-view-option">
                  <div class="icon">
                    <img id="mainmap-img-satelliteview" src="assets/img/svg/map/icon_map_en-satelliteview.svg" width="30" height="30">
                  </div>
                  <div class="option-container">
                    <div id="mainmap-div-enhanced-aerial" class="label bold">
                      Enhanced Aerial
                    </div>
                    <div id="mainmap-div-high-resolution" class="label">
                      High Resolution Aerial View
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </mat-menu>

        <!-- streetview icon -->
        @if (userAccessControls.googleStreetViewAccess) {
          @if (!readyToDropStreetViewPegman) {
            <img id="mainmap-img-pegman" src="assets/img/svg/map/icon_map_pegman.svg"
              [ngClass]="['control','control-off']"
              (click)="onInitiateStreetView($event)"
              [satPopoverAnchor]="streetViewOnPopover"
              (mouseover)="streetViewOnPopover.open()"
              (mouseout)="streetViewOnPopover.close()">
              <sat-popover #streetViewOnPopover [autoFocus]="false" [restoreFocus]="false" verticalAlign="below" openTransition="500ms ease-out" closeTransition="100ms ease-in">
                <gema3g-tooltip [content]="streetViewOnToolTip"></gema3g-tooltip>
              </sat-popover>
            }

            @if (readyToDropStreetViewPegman) {
              <img id="mainmap-img-pegman" src="assets/img/svg/map/icon_map_pegman.svg"
                [ngClass]="['control','control-on']" (click)="onInitiateStreetView($event)"
                [satPopoverAnchor]="streetViewOffPopover"
                (mouseover)="streetViewOffPopover.open()"
                (mouseout)="streetViewOffPopover.close()">
                <sat-popover #streetViewOffPopover [autoFocus]="false" [restoreFocus]="false" verticalAlign="below" openTransition="500ms ease-out" closeTransition="100ms ease-in">
                  <gema3g-tooltip [content]="streetViewOffToolTip"></gema3g-tooltip>
                </sat-popover>
              }
            }

            <!-- user location icon -->
            <img id="mainmap-img-locator" src="assets/img/svg/map/icon_map_locator.svg"
              (click)="getUserLocation()"
              [ngClass]="['control','control-off']"
              [satPopoverAnchor]="userLocationPopover"
              (mouseover)="userLocationPopover.open()"
              (mouseout)="userLocationPopover.close()">
              <sat-popover #userLocationPopover [autoFocus]="false" [restoreFocus]="false" verticalAlign="below" openTransition="500ms ease-out" closeTransition="100ms ease-in">
                <gema3g-tooltip [content]="userLocationToolTip"></gema3g-tooltip>
              </sat-popover>

              <div class="zoom-in-out">
                <!-- map zoom in icon -->
                @if (!isZoomMax) {
                  <img id="mainmap-img-zoom-plus" src="assets/img/svg/map/icon_map_zoom+.svg" (click)="onMapZoomIn($event)"
                    [ngClass]="['control','control-off', 'zoom-in-btn']"
                    [satPopoverAnchor]="zoomInPopover"
                    (mouseover)="zoomInPopover.open()"
                    (mouseout)="zoomInPopover.close()">
                  <sat-popover #zoomInPopover [autoFocus]="false" [restoreFocus]="false" verticalAlign="below" openTransition="500ms ease-out" closeTransition="100ms ease-in">
                    <gema3g-tooltip [content]="zoomInToolTip"></gema3g-tooltip>
                  </sat-popover>
                } @else {
                  <img id="mainmap-img-zoom-plus-disabled" class="bg-gray-400" src="assets/img/svg/map/icon_map_zoom+.svg" [ngClass]="['control', 'zoom-in-btn']">
                }

                <!-- map zoom out icon -->
                @if (!izZoomMin) {
                  <img id="mainmap-img-zoom-minus" src="assets/img/svg/map/icon_map_zoom-.svg" (click)="onMapZoomOut($event)"
                    [ngClass]="['control','control-off', 'zoom-out-btn']"
                    [satPopoverAnchor]="zoomOutPopover"
                    (mouseover)="zoomOutPopover.open()"
                    (mouseout)="zoomOutPopover.close()">
                  <sat-popover #zoomOutPopover [autoFocus]="false" [restoreFocus]="false" verticalAlign="below" openTransition="500ms ease-out" closeTransition="100ms ease-in">
                    <gema3g-tooltip [content]="zoomOutToolTip"></gema3g-tooltip>
                  </sat-popover>
                } @else {
                  <img id="mainmap-img-zoom-minus-disabled" class="bg-gray-400" src="assets/img/svg/map/icon_map_zoom-.svg" [ngClass]="['control', 'zoom-out-btn']">
                }

                <!-- current map zoom level -->
                <div class="layer-container">
                  <mat-icon matBadge="{{currentZoomLevel}}" matBadgeSize="small" matBadgeColor="primary" [ngStyle]="{'color':'#5E5E5E'}"
                    [satPopoverAnchor]="currentZoomPopover"
                    (mouseover)="currentZoomPopover.open()"
                  (mouseout)="currentZoomPopover.close()">layers</mat-icon>
                  <sat-popover #currentZoomPopover [autoFocus]="false" [restoreFocus]="false" verticalAlign="below" openTransition="500ms ease-out" closeTransition="100ms ease-in">
                    <gema3g-tooltip [content]="currentZoomLevelToolTip"></gema3g-tooltip>
                  </sat-popover>
                </div>
              </div>

                  <!-- map full screen -->
                  @if (fullScreenMapAvailable) {
                    <img id="mainmap-img-full-screen" src="assets/img/svg/icons/icon_map_enter_full_map_white.svg" (click)="toggleFullScreen($event)"
                      [ngClass]="['control','control-off','ml-3', 'text-white']"
                      [satPopoverAnchor]="fullScreenPopover"
                      (mouseover)="fullScreenPopover.open()"
                      (mouseout)="fullScreenPopover.close()">
                      <sat-popover #fullScreenPopover [autoFocus]="false" [restoreFocus]="false" verticalAlign="below" openTransition="500ms ease-out" closeTransition="100ms ease-in">
                        <gema3g-tooltip [content]="fullScreenMapToolTip"></gema3g-tooltip>
                      </sat-popover>
                    }

</div>
