import { Injectable, Inject, inject } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { LoggerService } from "../../../shared/service/log/logger.service";
import { DOCUMENT } from '@angular/common';
import { UserService } from "../../../shared/service/user.service";
import { AuthenticationService } from "../../../shared/service/authentication.service";
import { lastValueFrom } from "rxjs";

/**
 * This Auth guard restricts the /legal-agreement route to users who are:
 * 1. logged in and,
 * 2. have not yet accepted the terms and conditions.
 */
@Injectable({ providedIn: 'root' })
export class UserTermsAndConditionsAcceptedAuthGuard {
  constructor(@Inject(DOCUMENT) private document: Document,) {
  }

  private loggerService = inject(LoggerService);
  private authService = inject(AuthenticationService);
  private userService = inject(UserService);
  private router = inject(Router);
  
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree> {

    let user = this.userService.user;

    let isUserLoggedIn: boolean = this.authService.isLoggedIn;
    let isLegalAgreementAccepted: boolean = user.isLegalAgreementAccepted;

    this.loggerService.logDebug(`user ${this.userService.user?.businessEntityId} logged in? ${isUserLoggedIn}, legal agreement accepted? ${isLegalAgreementAccepted}`);

    if (!isUserLoggedIn) {
      //send the user back to the landing page
      //TODO sso and non-sso have different landing pages in this scenario
      this.loggerService.logInfo('UserTermsAndConditionsAcceptedAuthGuard -> redirect to /login');
      this.router.navigate(['/login']);
      return false;
    } else if (isLegalAgreementAccepted) {
      this.router.navigate(['/initialize-profile']);
      return false;
    }

    return true;
  }
}
