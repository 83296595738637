import {CondoUnit} from "./condo-unit";

export class CondoLevel {
  constructor(block: string, levelNumber: string | number, count: number) {
    this.block = block;
    this.levelNumber = levelNumber;
    this.count = count;
  }

  levelNumber: string | number;
  count: number;
  block: string;
  units: CondoUnit[] = [];

  //UI only
  expanded: boolean = false;
  loading: boolean = false;
  lbl: string = 'SELECT BY LEVEL';
}
