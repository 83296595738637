import {inject, Injectable} from "@angular/core";
import {BehaviorSubject, Observable, Subject, lastValueFrom, takeUntil, timer, Subscription} from 'rxjs';
import dayjs from "dayjs";
import {ConfirmDialogData} from "../../../core/component/modal/confirm-dialog/confirm-dialog-data";
import {ConfirmDialogComponent} from "../../../core/component/modal/confirm-dialog/confirm-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {AuthenticationService} from "../authentication.service";
import {Router} from "@angular/router";
import {DataService} from "../data.service";
import {UrlService} from "../url.service";
import {LoggerService} from "../log/logger.service";
import {ZendeskService} from "../zendesk.service";
import {GoogleAnalyticsService} from "../google-analytics.service";
import {BuildVersion} from "../../../core/model/miscellaneous/build-version";
import {environment} from "../../../../environments/environment";
import {VersioningService} from "../versioning-service";
import {LocalStorageKey} from "../../constant/constants";
import {DateUtilityService} from "../../utility/date.utility";


@Injectable({providedIn: 'root'})
export class TimerService {

  constructor(private dialog: MatDialog,
              private authService: AuthenticationService,
              private loggerService: LoggerService,
              private zendeskService: ZendeskService,
              private gaService: GoogleAnalyticsService,
              private versioningService: VersioningService) {
  }

  private dateService = inject(DateUtilityService);

  private _delayStartBy: number = 5 * 60 * 1000;
  private _sessionTimeoutTimer$: Observable<number> | null = null;
  private _sessionTimeoutSubject = new Subject();
  private _sessionTimeout: number = 30 * 60 * 1000;
  private _sessionTimeoutCheckingInterval: number = 5000;
  private _displaySessionTimeout: boolean = false;

  private _auth0SessionTimeoutSubject = new Subject();
  private _auth0Timer: Subscription;


  private _sessionTimedOutNotifier = new BehaviorSubject<boolean>(false);
  public sessionTimedOutNotifier$ = this._sessionTimedOutNotifier.asObservable();

  private _buildVersion: BuildVersion;
  private _checkForNewVersionSubject = new Subject();
  private _checkForNewVersionInterval: number = this._sessionTimeout + 5 * 60 * 1000;
  private _displayNewVersion: boolean = false;
  env: string = environment.text;

  private lastCountableHttpRequest = new BehaviorSubject<Date | null>(null);
  // add to this list periodical checks for data that should not be treated as user requests therefore not influencing session timeouts
  // make entries here as specific as it can be
  ignoreFromCountingHttpRequest: string[] = [
    'estore/cart/counter',
    'messagecenter/bannermessages',
    'messagecenter/expiredbannermessages'
  ];

  addHttpRequest(url: string): void {
    if (this.ignoreFromCountingHttpRequest.some(ignoredUrl => {
      return url.includes(ignoredUrl)
    })) {
      // this.loggerService.logInfo(`Ignored ${url} call for session timeout restart => on the list of urls to ignore.`);
    } else {
      // use this to determine any call that resets timeout
      // this.loggerService.logInfo(`New call ${url} that resets timeout timer.`+ dayjs(new Date()).add(this._sessionTimeout, 'milliseconds').format('YYYY-MM-DD HH:mm:ss'));
      this.lastCountableHttpRequest.next(new Date());
    }
  }

  startCheckingForSessionTimeout() {
    if (!this._sessionTimeoutTimer$) {
      timer(this._delayStartBy, this._sessionTimeoutCheckingInterval)
        .pipe(
          takeUntil(this._sessionTimeoutSubject),
        ).subscribe(t => {
          if (this.lastCountableHttpRequest.getValue()) {
            const millisecondSinceLastHttpCall = dayjs(new Date()).diff(this.lastCountableHttpRequest.getValue()).valueOf();
            if (millisecondSinceLastHttpCall > this._sessionTimeout && !this._displaySessionTimeout && this.authService.isLoggedIn) {
              this._sessionTimedOutNotifier.next(true);
              this.loggerService.logInfo(`User session has timeout out.`);
              this.zendeskService.hideMessagingWidget();
              this.processSessionTimeoutMessage(true);
            }
          }
        }
      );
    }
  }

  async processSessionTimeoutMessage(requestLogOut: boolean = false) {
    this._displaySessionTimeout = true;

    if (requestLogOut) {
      try {
        const logoutSuccess = await lastValueFrom(this.authService.logoutWithoutRedirect(), {defaultValue: false});
        if (logoutSuccess) {
          this.loggerService.logDebug("logged out successfully");
        } else {
          this.loggerService.logWarning(`there was an error on logging out user ${this.authService.user.businessEntityId}`);
        }
      } catch (e) {
        this.loggerService.logError(e);
      }
    }
    this.gaService.openModal('SessionTimeoutDialog');
    const dialogData = new ConfirmDialogData('CONNECTION LOST', DataService.SESSION_TIMEOUT_MESSAGE, '', 'Close', false);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {data: dialogData})
      .afterClosed()
      .subscribe(() => {
        try {
          //this.router.navigate(["/login"]);
          document.location.href = UrlService.REDIRECT_AFTER_SESSION_TIMEOUT;
        } finally {
          this._displaySessionTimeout = false;
        }
      });
  }

  startCheckingForNewVersion() {
    if (!this._sessionTimeoutTimer$) {
      timer(this._delayStartBy, this._checkForNewVersionInterval)
        .pipe(
          takeUntil(this._checkForNewVersionSubject),
        ).subscribe(async t => {
          this.versioningService.checkIfNewVersionIsAvailable()
            .then(async restartNeeded => {
              if (restartNeeded) {
                this.loggerService.logInfo(`Restarting app to load the new version ${this.versioningService.currentVersion()}`);
                await this.versioningService.processLocalVersionOutOfDate();
              }
            });
        }
      );
    }
  }

  startCheckingForAuth0TokenExpiration() {
    if (!this._auth0Timer) {
      // start a minute later and check every 55 seconds
      this._auth0Timer = timer(environment.auth0.ACCESS_TOKEN_EARLY_RENEW_BY*1000 + 1, environment.auth0.ACCESS_TOKEN_EARLY_RENEW_BY*1000 - this._sessionTimeoutCheckingInterval)
        .pipe(
          takeUntil(this._auth0SessionTimeoutSubject),
        ).subscribe(async t => {
          const tokenExpiresAt = localStorage.getItem(LocalStorageKey.auth0ExpiresAt);
          if(tokenExpiresAt) {
            //this.loggerService.logDebug(`tokenExpiresAt = ${tokenExpiresAt}`);
            const secondsToExpiration = this.dateService.diffInSeconds(new Date(), new Date(Number(tokenExpiresAt)));
            //this.loggerService.logDebug(`diff = ${secondsToExpiration}`)
            //this.loggerService.logDebug(`environment.auth0.ACCESS_TOKEN_EARLY_RENEW_BY = ${environment.auth0.ACCESS_TOKEN_EARLY_RENEW_BY}`);
            if ( secondsToExpiration < environment.auth0.ACCESS_TOKEN_EARLY_RENEW_BY) {
              const newAuth0Token = await this.authService.refreshAuth0Token();
            }
          }
        }
      );
    }
  }


  stopCheckingForSessionTimeout() {
    this._sessionTimeoutSubject.next(null);
  }

  stopCheckingForNewVersion() {
    this._checkForNewVersionSubject.next(null);
  }

  stopCheckingForAuth0TokenExpiration() {
    this._auth0SessionTimeoutSubject.next(null);
  }

  startAllTimers() {
    this.startCheckingForSessionTimeout();
    // for other environments checking for new version should be done only at login
    if (this.env == 'Development' || this.env == 'UAT') {
      this.startCheckingForNewVersion();
    }
  }

  stopAllTimers() {
    this.stopCheckingForSessionTimeout();
    this.stopCheckingForNewVersion();
    this.stopCheckingForAuth0TokenExpiration();
  }
}
