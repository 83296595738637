<gema3g-property-report-layout [propertyReportSection]="propertyReportSection">
  <section class="pr-shutter-middle-body-full sm:pr-shutter-middle-body">

    <!-- <h3>
      HoodQ<sup>TM</sup>
    </h3> -->
    <div id="hoodq-widget" (click)="gaHoodQ()">
      <div id="neighbourhoodHighlights"></div>
    </div>

    <mat-divider></mat-divider>

    <h2 id="hoodq-h2-reports" class="mt-7">{{productsForPurchaseLabel}}</h2>

    @if (carouselProducts && carouselProducts.length == 0) {
    <div class="carousel-products-loading">
      <div id="hoodq-no-reports-label">{{noProductsForPurchaseLabel}}</div>
    </div>
    }

    @if (carouselProductsLoading) {
      <div class="carousel-products-loading">
        <img id="hoodq-img-refresh" src="assets/img/icon-refresh.gif">
      </div>
    }

    @if (!carouselProductsLoading && carouselProducts && carouselProducts.length > 0) {
      <div class="carousel-container">
        <p-carousel id="hoodq-reports-carousel" [circular]="false" [numScroll]="1" [numVisible]="3" [responsiveOptions]="responsiveOptions" [showIndicators]="false" [showNavigators]="showNavigators" [value]="carouselProducts">
          <ng-template let-product pTemplate="item">
            <div class="carousel-card">
              <gema3g-single-catalogue-product
                class="carousel-card__block"
                [product]="product"
                [selectedProperty]="selectedProperty">
              </gema3g-single-catalogue-product>
            </div>
          </ng-template>
        </p-carousel>
      </div>
    }
  </section>

</gema3g-property-report-layout>
