<h1 id="pdf-h1-title" mat-dialog-title>
  @if(isLoading){
    Please wait while loading report configurations
  } @else {
    Choose your report
  }
</h1>
<div mat-dialog-content class="!overflow-y-visible">
  <div id="pdf-summary" class="summary">
    {{ mainPageSummary }}
  </div>

  <div class="radio-box-container">
    @for (item of uiData; track $index) {
      @if (item.visible) {

        <label
          id="pdf-label-{{item.type}}"
          (click)="selectPdfReport(item)"
          [class.selected]="item.selected"
          class="box">
          <div class="flex flex-col">
            <div id="pdf-checkbox-label-{{item.type}}">
              <input id="pdf-checkbox-{{item.type}}" [checked]="item.selected" name="options" type="radio">
              <b class="pl-1.5">{{ item.radioButtonLabel }}</b>
            </div>
            <div id="pdf-desc-{{item.type}}" class="box-description" [innerHTML]="item.description">
            </div>

            @if (item.customizable && item.selected) {
              <a id="pdf-link-customize-{{item.type}}" (click)="openCustomize(item)" class="anchor">Customize</a>
            }
          </div>
        </label>
      }
    }
  </div>

  @if (uiData[1]?.selected && uiData[1]?.expanded) {
    <gema3g-generate-pdf-client-report
      [inputParam]="uiData[1].reportParam"
      [openedFromComparablesReport]="data.openFromComparablesReport"
      (collapseAdditionalParams)=collapseAdditionalParams(uiData[1])
    ></gema3g-generate-pdf-client-report>
  }
  @if (uiData[2]?.selected && uiData[2]?.expanded) {
    <gema3g-generate-pdf-property-report
      [inputParam]="uiData[2].reportParam"
      (collapseAdditionalParams)=collapseAdditionalParams(uiData[2])
      (openComparableSales)="goToComparableSales()"
      (reloadProperty)="goReloadProperty($event)"

    ></gema3g-generate-pdf-property-report>

  }

</div>

<div mat-dialog-actions align="end" class="mat-dialog-actions-custom">

  @if (isLoading) {
    <mat-spinner id="lsd-spinner" class="custom-spinner" diameter="60"></mat-spinner>
  } @else {
    @if (!buttonIsDisabled) {
      <button id="pdf-btn-generate" (click)="submitPdfReportGenerateRequest()"
              cdkFocusInitial
              class="dialog-accept-btn"
              mat-button [disabled]="buttonIsDisabled">
        Generate PDF
      </button>
    } @else {
      <span class="pr-3">Please select a Subject Property</span>
    }
  }
  <button id="pdf-btn-cancel" mat-button mat-dialog-close class="dialog-close-btn mr-2" type="button">Cancel</button>
</div>
