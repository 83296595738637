import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PdfReportMyPropertyReportConfiguration} from "../../../../model/pdf-report/input-param/pdf-report-my-property-report-configuration";
import {MatInputModule} from "@angular/material/input";
import {MaterialModule} from "../../../../material.module";
import {SvgIconComponent} from "../../../svg-icon/svg-icon.component";
import {GeneratePdfReportPropertyInfoSectionComponent} from "../sections/generate-pdf-report-property-info-section/generate-pdf-report-property-info-section.component";
import {GeneratePdfReportAreaSalesInfoSectionComponent} from "../sections/generate-pdf-report-area-sales-info-section/generate-pdf-report-area-sales-info-section.component";
import {GeneratePdfReportMarketAnalyticsSectionComponent} from "../sections/generate-pdf-report-market-analytics-section/generate-pdf-report-market-analytics-section.component";

@Component({
  selector: 'gema3g-generate-pdf-property-report',
  standalone: true,
  imports: [
    MatInputModule,
    MaterialModule,
    SvgIconComponent,
    GeneratePdfReportPropertyInfoSectionComponent,
    GeneratePdfReportAreaSalesInfoSectionComponent,
    GeneratePdfReportMarketAnalyticsSectionComponent
  ],
  templateUrl: './generate-pdf-property-report.component.html',
  styleUrl: './generate-pdf-property-report.component.scss'
})
export class GeneratePdfPropertyReportComponent {
  @Input() inputParam: PdfReportMyPropertyReportConfiguration;
  @Output() collapseAdditionalParams = new EventEmitter<boolean>();
  @Output() openComparableSales = new EventEmitter<boolean>();
  @Output() reloadProperty = new EventEmitter<string>();

  closeAdditionalParams() {
    this.collapseAdditionalParams.emit(true);
  }

  goToComparableSales() {
    this.openComparableSales.emit(true);
  }

  goReloadProperty(pin?: string){
    this.reloadProperty.emit(pin);
  }
}
