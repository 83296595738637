<mat-sidenav-container #mainSideNavContainer class="main-sidenav-container" [hasBackdrop]="false">

  <!-- content on left side of screen -->
  <mat-sidenav-content #matSideNavContainer (scroll)="onScroll($event)">
    <div #mainMapComponentContainer class="main-map-component-container">

      <gema3g-search-results-container (mobileFullScreen)="mobileFullScreenMode($event)">
      </gema3g-search-results-container>

      <gema3g-main-map-controls #mainMapControls (hoodQMapOpened)="onHoodQMapOpened()"
        (layerToggled)="onMapLayerToggled($event)" (mainMapZoomIn)="onMainMapZoomIn()"
        (mainMapZoomOut)="onMainMapZoomOut()" (thematicLayerOpacityChanged)="onThematicLayerOpacityChanged($event)"
        [mapLayerVisibility]="mapLayerVisibility"
        [isMapControlsMoved]="isMapControlsMoved">
      </gema3g-main-map-controls>

      @if (!omnibarSearchInitiated() && propertyReportVisible()) {
        <gema3g-property-report (scrollToTop)="scrollToTop()"
                                [propertyReportSectionScrolled]="propertyReportSectionScrolled">
        </gema3g-property-report>
      }

      @if (!omnibarSearchInitiated() && searchComparablesResultVisible) {
        <gema3g-search-comparables-result>
        </gema3g-search-comparables-result>
      }

      <div #mainMapContainer class="main-map" id="mainMapContainer" [hidden]="propertyStreetViewEnabled"
        [ngClass]="mapScreenOrientation == orientation.HORIZONTAL ? 'flip-horizontal' : 'flip-vertical'"
        (click)="onMapContainerClicked($event)"
        (mousemove)="onMapContainerMouseMoved($event)">
        <div class="initial-loading"></div>
        <!-- inject the google map here -->
        <!-- map flips vertically or horizontally based on the search comparables results orientation -->
      </div>

      <!-- <gema3g-main-map-streetview [hidden]="!propertyStreetViewEnabled"></gema3g-main-map-streetview> -->

      <div #propertyStreetViewContainer class="main-map z-10" id="propertyStreetViewContainer"
        [hidden]="!propertyStreetViewEnabled">
      </div>

      @if (propertyStreetViewEnabled) {
        <gema3g-geo-address-map-overlay #geoAddressMapOverlayComponent
          (addressOverlayMapLocationChanged)="positionStreetView($event)"
          [streetViewPosition]="streetViewPosition"
          [addressUpdating]="addressUpdating"
          [addressQueryResult]="addressQueryResult">
        </gema3g-geo-address-map-overlay>
      }

    </div>
  </mat-sidenav-content>

  <!-- content of right side of screen; content wrapper in the sidebar -->
  <mat-sidenav #matSideNav position="end" disableClose="true" class="search-comparables-sidenav-background">
    <gema3g-search-comparables-form (formVisible)="toggleSearchComparablesForm($event)"></gema3g-search-comparables-form>
  </mat-sidenav>

</mat-sidenav-container>
