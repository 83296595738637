<div class="gema3g-ao-container">
  <div class="gema3g-ao-container__inner">

    <div class="col-0 p-9">
      <div id="account-myaccountpasswordmsg">
        Please enter a password between 8 and 16 characters, including at least one
        uppercase and one lowercase letter, one number and one special character.

        <span [satPopoverAnchor]="specialCharactersPopover" (mouseover)="specialCharactersPopover.open()" (mouseout)="specialCharactersPopover.close()">
          <mat-icon id="profile-icon-pw-help-tooltip" aria-hidden="false" aria-label="Help icon" fontIcon="help">
          </mat-icon>
        </span>
        <sat-popover #specialCharactersPopover [autoFocus]="false" [restoreFocus]="false" verticalAlign="above" openTransition="500ms ease-out" closeTransition="100ms ease-in">
          <gema3g-tooltip [content]="specialCharacters"></gema3g-tooltip>
        </sat-popover>

        <span id="profile-pw-chars"
          animation="true" class="glyphicon glyphicon-question-sign questionMark" data-content=""
          data-toggle="popover"
          data-trigger="hover focus"
        placement="right" title="Acceptable Special Characters"> </span>
      </div>

      @if (changePasswordResponse?.code == 0) {
        <div class="password-msg">
          <img id="profile-pw-chkmrk-icon" alt="" src="assets/img/mygeo-icons/checkmark.png"/>
          <p id="profile-pw-saved-msg">Your password has been saved</p>
        </div>
      }
      <form id="profile-form-pw" autocomplete="off">
        <div class='geo-flex-row justify-content-center get-dense-3'>
          <div class='geo-flex-column get-dense-3'>

            <mat-form-field id="profile-ff-pw-oldPassword" appearance="outline" class="input-field">
              <mat-label id="profile-label-pw-oldPassword">Old password</mat-label>
              <input [(ngModel)]="changePasswordRequest.oldpw" id="account-oldPassword" matInput name="account-oldPassword" placeholder="" type="password">
            </mat-form-field>
            <mat-form-field id="profile-ff-pw-createNewPassword" appearance="outline" class="input-field">
              <mat-label id="profile-label-pw-createNewPassword">Create new password</mat-label>
              <input [(ngModel)]="changePasswordRequest.newpw" id="account-createNewPassword" matInput name="account-createNewPassword" placeholder="" type="password">
            </mat-form-field>
            <mat-form-field id="profile-ff-pw-confirmNewPassword" appearance="outline" class="input-field">
              <mat-label id="profile-label-pw-confirmNewPassword">Confirm new password</mat-label>
              <input [(ngModel)]="changePasswordRequest.confpw" id="account-lastName" matInput name="account-lastName" placeholder="" type="password">
            </mat-form-field>
            @if (error) {
              <div class="flex-row error-msg">
                <mat-error id="profile-error-pw">{{ error }}</mat-error>
              </div>
            }
            <div class="text-center">
              <button id="profile-btn-pw-change" (click)="changePassword()"
                [ngClass]="!changePasswordRequest.canContinue ? 'button-disabled' : 'button'"
                class="button chgpwd-btn" color="primary" mat-raised-button>Change Password
              </button>
            </div>
          </div>

          <div class='geo-flex-column get-dense-3'>
            @if (changePasswordRequest.edited) {
              <div id="profile-pw-tips">Password Tips</div>
            }
            <div id="account-tips-list">
              @if (changePasswordRequest.edited) {
                <table id="profile-tbl-pw" style="border-collapse:separate;border-spacing:8px">
                  <tr id="profile-tbl-row-pw-length">
                    <td>
                      @if (changePasswordRequest.inRange) {
                        <mat-icon id="profile-tbl-icon-pw-length-y" class="greenCheck">done</mat-icon>
                      }
                      @if (changePasswordRequest.inRange == false) {
                        <mat-icon id="profile-tbl-icon-pw-length-n" class="redCross">highlight_off</mat-icon>
                      }
                    </td>
                    <td>
                      <span id="profile-tbl-data-pw-length" [ngClass]="{'text-os-bold' :changePasswordRequest.inRange} " class="tipsText">From 8 to 16 characters</span><br/><br/>
                    </td>
                  </tr>
                  <tr id="profile-tbl-row-pw-number">
                    <td>
                      @if (changePasswordRequest.hasNumber) {
                        <mat-icon id="profile-tbl-icon-pw-number-y" class="greenCheck">done</mat-icon>
                      }
                      @if (changePasswordRequest.hasNumber == false) {
                        <mat-icon id="profile-tbl-icon-pw-number-n" class="redCross">highlight_off</mat-icon>
                      }
                    </td>
                    <td>
                      <span id="profile-tbl-data-pw-number" [ngClass]="{'text-os-bold': changePasswordRequest.hasNumber}" class="tipsText">At least one number</span><br/><br/>
                    </td>
                  </tr>
                  <tr id="profile-tbl-row-pw-uppercase">
                    <td>
                      @if (changePasswordRequest.hasUpperCase) {
                        <mat-icon id="profile-tbl-icon-pw-uppercase-y" class="greenCheck">done</mat-icon>
                      }
                      @if (changePasswordRequest.hasUpperCase == false) {
                        <mat-icon id="profile-tbl-icon-pw-uppercase-n" class="redCross">highlight_off</mat-icon>
                      }
                    </td>
                    <td>
                      <span id="profile-tbl-data-pw-uppercase" [ngClass]="{'text-os-bold' :changePasswordRequest.hasUpperCase}" class="tipsText">At least one uppercase letter</span><br/><br/>
                    </td>
                  </tr>
                  <tr id="profile-tbl-row-pw-lowercase">
                    <td>
                      @if (changePasswordRequest.hasLowerCase) {
                        <mat-icon id="profile-tbl-icon-pw-lowercase-y" class="greenCheck">done</mat-icon>
                      }
                      @if (changePasswordRequest.hasLowerCase == false) {
                        <mat-icon id="profile-tbl-icon-pw-lowercase-n" class="redCross">highlight_off</mat-icon>
                      }
                    </td>
                    <td>
                      <span id="profile-tbl-data-pw-lowercase" [ngClass]="{'text-os-bold' :changePasswordRequest.hasLowerCase} " class="tipsText">At least one lowercase letter</span><br/><br/>
                    </td>
                  </tr>
                  <tr id="profile-tbl-row-pw-special">
                    <td>
                      @if (changePasswordRequest.hasSpecialChar) {
                        <mat-icon id="profile-tbl-icon-pw-special-y" class="greenCheck">done</mat-icon>
                      }
                      @if (changePasswordRequest.hasSpecialChar == false) {
                        <mat-icon id="profile-tbl-icon-pw-special-n" class="redCross">highlight_off</mat-icon>
                      }
                    </td>
                    <td>
                      <span id="profile-tbl-data-pw-special" [ngClass]="{'text-os-bold' :changePasswordRequest.hasSpecialChar} " class="tipsText">At least one special character</span><br/><br/>
                    </td>
                  </tr>
                  <tr id="profile-tbl-row-pw-match">
                    <td>
                      @if (changePasswordRequest.passwordsMatch) {
                        <mat-icon id="profile-tbl-icon-pw-match-y" class="greenCheck">done</mat-icon>
                      }
                      @if (changePasswordRequest.passwordsMatch == false) {
                        <mat-icon id="profile-tbl-icon-pw-match-n" class="redCross">highlight_off</mat-icon>
                      }
                    </td>
                    <td>
                      <span id="profile-tbl-data-pw-match" [ngClass]="{'text-os-bold' :changePasswordRequest.passwordsMatch} " class="tipsText">The new passwords match</span><br/><br/>
                    </td>
                  </tr>
                  <tr id="profile-tbl-row-pw-valid">
                    <td>
                      @if (changePasswordRequest.hasInvalidChars) {
                        <mat-icon id="profile-tbl-icon-pw-valid-n" class="redCross">highlight_off</mat-icon>
                      } @else {
                        <mat-icon id="profile-tbl-icon-pw-valid-y" class="greenCheck">done</mat-icon>
                      }
                    </td>
                    <td>
                      @if (!changePasswordRequest.hasInvalidChars) {
                        <span id="profile-tbl-data-pw-valid" class="text-os-bold tipsText">All characters valid in new password</span>
                      } @else {
                        <span id="profile-tbl-data-pw-invalid" class="text-os-bold tipsText">Invalid characters in new password</span>
                      }
                      <br/><br/>
                    </td>
                  </tr>
                </table>
              }
            </div>

          </div>
        </div>
      </form>
    </div>

    <div class="col-1">
      <gema3g-need-assistance></gema3g-need-assistance>
    </div>

  </div>
</div>
