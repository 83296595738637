import {Component, Input} from '@angular/core';
import {LandingPageSettings} from "../../../../core/model/store-front/landing-page/landing-page-settings";

@Component({
  selector: 'gema3g-store-front-footer',
  templateUrl: './store-front-footer.component.html',
  styleUrls: ['./store-front-footer.component.scss']
})
export class StoreFrontFooterComponent {
  @Input() landingPageSettings : LandingPageSettings;

}
