import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';

@Component({
  selector: 'gema3g-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrl: './tooltip.component.scss'
})
export class TooltipComponent implements OnChanges {
  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges): void {
    let contentString: string = changes['content']?.currentValue;

    if (contentString) {
      this.content = this.sanitizer.bypassSecurityTrustHtml(contentString);
    }
  }

  @Input() content: SafeHtml;
}
