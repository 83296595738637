import { BaseModel } from "../base-model";

export class RataReportRecord extends BaseModel {

    constructor(rrRecord: RataReportRecord) {
        super(rrRecord);
    }

    public recordId: number;
    public processId: number;
    public lroNumber: string;
    public lroOfficeName: string;
    public pin: string;
    public instrumentNumber: string;
    public instrumentDescription: string;
    public partyFrom: string;
    public partyTo: string;
    public arn: string;
    public createDate: Date;
    public municipality: string;
    public address: string;
    public token: string;
    public chargeInstrumentNumber: string;
    public transferInstrumentNumber: string;
    public chargeConsiderationAmount: number;
    public transferConsiderationAmount: number;
    public transferRegistrationDate: Date;
    public chargeRegistrationDate: Date;
    public transferInstrumentDescription: string;
    public chargeInstrumentDescription: string;
    public chargeInstrumentSubTypeCode: string;
    public transferInstrumentSubTypeCode: string;
    public registrationDate: Date;
    public sumConsideration: number;
    public considerationAmount: number;
    public instrumentSubTypeCode: string;
}
