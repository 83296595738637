import {UserAccessControl} from "../../../../core/model/user/user-access-control";
import {EstoreProductCategoryEnum} from "../../../../core/enum/estore-product-category-enum";

export type StoreRetailMenuItemType = 'ALL_REPORTS_RECORDS_PROPERTY' | 'INSIGHTS_REPORTS' | 'PARCEL_REGISTERS_SURVEYS' | 'MPAC_REPORTS' | 'HOODQ_REPORTS' | 'RENEW_SUBSCRIPTIONS' |
  'PROTECT_YOUR_BOUNDARIES_REPORTS' | 'RESIDENTIAL_RELOCATION_REPORTS' | 'CONDO_SQUARE_FOOTAGE_REPORTS';

export class StoreRetailMenuDefinition {

  constructor(title: string, selected: boolean, type: StoreRetailMenuItemType, isNew: boolean = false) {
    this.title = title;
    this.selected = selected;
    this.type = type;
    this.isNew = isNew;
  }

  title: string;
  selected: boolean;
  type: StoreRetailMenuItemType;
  isNew: boolean;

  static definitions(userAccessControls: UserAccessControl): StoreRetailMenuDefinition[] {
    const result: StoreRetailMenuDefinition[] = [];
    result.push(new StoreRetailMenuDefinition('All Reports <br> & Records', true, "ALL_REPORTS_RECORDS_PROPERTY"));
    result.push(new StoreRetailMenuDefinition('Property <br> Insights Reports', false, "INSIGHTS_REPORTS"));
    result.push(new StoreRetailMenuDefinition('Parcel Registers <br> & Surveys', false, "PARCEL_REGISTERS_SURVEYS"));
    result.push(new StoreRetailMenuDefinition('MPAC <br> Reports', false, "MPAC_REPORTS"));
    result.push(new StoreRetailMenuDefinition('HoodQ™ <br> Reports', false, "HOODQ_REPORTS"));
    if (userAccessControls?.GWHRSubscriptionAccess) {
      result.push(new StoreRetailMenuDefinition('Renew <br> Subscriptions', false, "RENEW_SUBSCRIPTIONS"));
    }
    if (userAccessControls?.pybBuyersReport || userAccessControls?.pybEasmentReport || userAccessControls?.pybSellersReport) {
      result.push(new StoreRetailMenuDefinition('Protect Your <br> Boundaries™ Reports', false, "PROTECT_YOUR_BOUNDARIES_REPORTS"));
    }
    if (userAccessControls?.residentialRelocationReportLro || userAccessControls?.residentialRelocationReportMunicipality || userAccessControls?.residentialRelocationReportFsa) {
      result.push(new StoreRetailMenuDefinition('Residential <br> Relocation Reports', false, "RESIDENTIAL_RELOCATION_REPORTS"));
    }
    if (userAccessControls?.CondoSuiteSqrFootage || userAccessControls?.CondoBuildingSqrFootage) {
      result.push(new StoreRetailMenuDefinition('Condo Square <br> Footage Reports', false, "CONDO_SQUARE_FOOTAGE_REPORTS", true));
    }
    return result;
  }

  static associatedProducts(type: StoreRetailMenuItemType): EstoreProductCategoryEnum[] {
    switch (type) {
      case "INSIGHTS_REPORTS":
        return [EstoreProductCategoryEnum.TERANET_INSIGHTS];
      case "PARCEL_REGISTERS_SURVEYS":
        return [EstoreProductCategoryEnum.STAT, EstoreProductCategoryEnum.PROPERTY_SURVEY_IMAGE];
      case "MPAC_REPORTS":
        return [EstoreProductCategoryEnum.MPAC_BUNDLE, EstoreProductCategoryEnum.MPAC];
      case "HOODQ_REPORTS":
        return [EstoreProductCategoryEnum.HOODQ];
      case "RENEW_SUBSCRIPTIONS":
        return [EstoreProductCategoryEnum.GWH_RENEW_SUBSCRIPTION];
      case "PROTECT_YOUR_BOUNDARIES_REPORTS":
        return [EstoreProductCategoryEnum.PYB];
      case "RESIDENTIAL_RELOCATION_REPORTS":
        return [EstoreProductCategoryEnum.RESIDENTIAL_RELOCATION];
      case "CONDO_SQUARE_FOOTAGE_REPORTS":
        return [EstoreProductCategoryEnum.CONDO];
    }
    return [];

  }
}

