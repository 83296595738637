import { AfterViewInit, Component, ElementRef, HostListener, inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { PropertyDetail } from '../../../../core/model/property/property-detail';
import { PropertyReportSectionEnum } from '../../../../core/enum/property-report-section-enum';
import { AdBannerRotationService } from '../../../../shared/service/ad-banner-rotation.service';
import { AdBanner } from '../../../../core/model/banner/ad-banner';
import { AdBannerWeightEnum } from '../../../../core/enum/ad-banner-weight-enum';
import { PropertyReportComponentBase } from '../property-report-component-base';
import { UserAccessControl } from '../../../../core/model/user/user-access-control';
import { MatTableDataSource } from "@angular/material/table";
import { SaleHistoryDetailed } from '../../../../core/model/sale/sale-history-detailed';
import { EStoreProduct } from "../../../../core/model/product/e-store/e-store-product";
import { DataService } from "../../../../shared/service/data.service";
import { RouteMappingUtility } from "../../../../shared/utility/route-mapping-utility";
import { EstoreProductCategoryEnum } from "../../../../core/enum/estore-product-category-enum";
import { AdBannerAction } from "../../../../core/model/banner/ad-banner-action";
import { AdBannerActionEnum } from "../../../../core/enum/ad-banner-action-enum";
import { takeUntil } from "rxjs";
import { defaultResponsiveOptions } from "../../../../shared/constant/constants";
import { SelectedProperty } from "../../../../core/model/property/selected-property";
import { MatSort } from "@angular/material/sort";
import { FusionChartDataSource } from "../../../../core/model/fusion-chart/fusion-chart-data-source";
import FusionChartInstance from "angular-fusioncharts/interfaces/FusionChartInstance";
import { AssessmentChartsUtility } from "../../../../shared/utility/fusion-charts/assessment-charts.utility";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { CarouselService } from "../../../../shared/service/carousel.service";
import _ from 'lodash';

@Component({
  selector: 'gema3g-property-report-valuation-sales',
  templateUrl: './valuation-sales.component.html',
  styleUrls: ['./valuation-sales.component.scss']
})
export class ValuationSalesComponent extends PropertyReportComponentBase implements OnInit, AfterViewInit {
  constructor() {
    super();
  }
  
  private adBannerRotationService = inject(AdBannerRotationService);
  private carouselService = inject(CarouselService);
  
  subjectProperty: PropertyDetail;
  @Input() userAccessControls: UserAccessControl;
  @Input() carouselProducts: EStoreProduct[] | undefined;
  @Input() selectedProperty: SelectedProperty = new SelectedProperty();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  propertyReportSection: PropertyReportSectionEnum = PropertyReportSectionEnum.VALUATION_AND_SALES;
  dataSource = new MatTableDataSource<SaleHistoryDetailed>();
  displayedColumns: string[];
  saleListItems: SaleHistoryDetailed[];
  hasSalesHistory: boolean = false;
  deededTogetherPropertiesPresent: boolean = false;
  responsiveOptions = defaultResponsiveOptions;
  ds = DataService;
  maxDeededTogetherPins: number = DataService.MAX_DEEDED_TOGETHER_VISIBLE_PINS;
  limitDeededTogetherPins: number = DataService.MAX_DEEDED_TOGETHER_VISIBLE_PINS;
  valuationSalesDataSource: FusionChartDataSource = new FusionChartDataSource();
  salesHistoryPageIndex: number;
  salesHistoryLength: number = 0;
  salesHistoryPageSize: number;
  salesHistoryPageSizes = [5, 10, 20];
  chart: any;
  defaultChartHeight: string = '320';

  refreshUI = () => {
    if (this.subjectProperty?.salesHistory?.length > 0) {
      this.hasSalesHistory = true;
      this.populateSalesTable();
      this.bindingMatSort();
    }
    // we need this workaround as matSort is only available on AfterViewInit and only if not behind and @if block
    this.setDisplay('vs-tbl-sh-container', this.hasSalesHistory, 'block', 'none');
    this.valuationSalesDataSource = new FusionChartDataSource();
    this.loadChartData();
  }

  // ToDo move to a service
  setDisplay(elementId: string, condition: boolean, trueValue: string, falseValue: string) {
    try {
      // @ts-ignore
      document.getElementById(elementId).style.display = condition ? trueValue : falseValue;
    } catch (ex) {
      this.loggerService.logError(ex);
    }
  }

  initializeSalesTableColumns = () => {
    this.displayedColumns = [];
    this.displayedColumns.push('saleDate');
    this.displayedColumns.push('considerationAmt');
    if (this.userAccessControls.displayInstrumentNum) {
      this.displayedColumns.push('instrumentNum');
    }
    this.displayedColumns.push('instrumentSubTypeDescription');
    if (this.userAccessControls.displayPartyTo) {
      this.displayedColumns.push('partyTo');
    }
    this.displayedColumns.push('notes');
  }

  bindingMatSort = () => {
    if (this.sort) { // since table only shows when hasSalesHistory == true, sort might not have been initialized
      this.dataSource.sort = this.sort;
      if (this.sort) {
        this.sort.sort({
          id: 'saleDate',
          start: 'desc',
          disableClear: true
        });
        this.sort.disableClear = true;
      }
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'saleDate':
            return new Date(item.registrationDate).getTime();
          default: // @ts-ignore
            return item[property];
        }
      }
    } else if (this.hasSalesHistory) {
      this.loggerService.logDebug('sort is not initialized; sorting in sales history might not be available');
    }
  }

  initializeAdBanners = () => {
    try {
      this.adBannerRotationService.resetPropertyReportSectionAds(PropertyReportSectionEnum.VALUATION_AND_SALES, 'LEFT');
      this.adBannerRotationService.resetPropertyReportSectionAds(PropertyReportSectionEnum.VALUATION_AND_SALES, 'RIGHT');

      let rightAdBanners = new Set<AdBanner>();
      let adBannerAction = new AdBannerAction(AdBannerActionEnum.NAVIGATE_TO_ROUTE, ['../catalogue', RouteMappingUtility.eStoreCategoryToRoute(EstoreProductCategoryEnum.TERANET_INSIGHTS)]);
      rightAdBanners.add(new AdBanner(AdBanner.IMAGE_AD, PropertyReportSectionEnum.VALUATION_AND_SALES, AdBannerWeightEnum.MEDIUM, 'assets/img/store-front/avm-insights-rpt/AVM2_1.jpg', adBannerAction));
      rightAdBanners.add(new AdBanner(AdBanner.IMAGE_AD, PropertyReportSectionEnum.VALUATION_AND_SALES, AdBannerWeightEnum.MEDIUM, 'assets/img/store-front/avm-insights-rpt/AVM2_2.jpg', adBannerAction));

      let newRightAdBanners: AdBanner[] = [];
      newRightAdBanners.push(this.adBannerRotationService.getNextAdBanner(rightAdBanners));
      this.adBannerRotationService.setNextRightSidebarAds(newRightAdBanners);

    } catch (e) {
      this.loggerService.logError(`error initializing ${PropertyReportSectionEnum.VALUATION_AND_SALES} ads`);
    }
  }

  openPropertyReport = async (pin: any) => {
    this.propertyReportService.showPropertyReportByPinDeferData(<string>pin);
  }

  populateSalesTable = () => {

    this.deededTogetherPropertiesPresent = this.subjectProperty?.deededTogether?.length > 0;

    let counter: number = 0;
    this.saleListItems = this.subjectProperty?.salesHistory?.map(sale => {

      let saleHistory: SaleHistoryDetailed = new SaleHistoryDetailed();
      saleHistory.registrationDate = sale.polInstrument.registrationDate;
      saleHistory.instrumentNum = sale.polInstrument.polInstrumentPK.instrumentNum;
      saleHistory.instrumentSubTypeCode = sale.polInstrument.instrumentSubTypeCode;
      saleHistory.considerationAmt = sale.polInstrument.considerationAmt;
      saleHistory.partyTo = sale.polInstrument.concatenatedPartyTos;

      if (this.deededTogetherPropertiesPresent && counter == 0) {
        saleHistory.notes = 'The following PINs were transferred together with the Subject Property:';
        saleHistory.deededTogetherPins = [];
        this.subjectProperty.deededTogether.forEach(property => {
          saleHistory.deededTogetherPins.push(property.pin);
        })
      }

      return saleHistory;
    });

    this.dataSource = new MatTableDataSource(this.saleListItems);
    this.salesHistoryLength = this.saleListItems.length;
    this.salesHistoryPageSize = this.paginator?.pageSize;
    this.dataSource.paginator = this.paginator;

  }

  initializeSubjectPropertyListener = () => {
    this.propertyReportService.subjectProperty$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((newPropertyDetail) => {
        this.subjectProperty = newPropertyDetail;
        this.refreshUI();
      });
  }

  ngOnInit(): void {
    this.initializeSalesTableColumns();
    this.initializeSubjectPropertyListener();
  }

  ngAfterViewInit(): void {
    this.initializeAdBanners();
    this.initializeSorting();
  }

  changeMaxDeededTogetherPins(listLength: number) {
    if (this.limitDeededTogetherPins == this.maxDeededTogetherPins) {
      this.limitDeededTogetherPins = listLength;
    } else {
      this.limitDeededTogetherPins = this.maxDeededTogetherPins;
    }
  }

  get showNavigators(): boolean {
    return this.carouselService.showNavigators(this.carouselProducts?.length);
  }

  initialized($event: FusionChartInstance) {
    //this.loggerService.logInfo('Valuation Sales :: initialized');
    this.chart = $event;
    this.loadChartData();
  }

  private loadChartData() {
    //this.loggerService.logInfo('Valuation Sales :: initiated a loading of Valuation Sales chart');
    if (_.isEmpty(this.valuationSalesDataSource)) {
      //this.loggerService.logInfo('Valuation Sales :: loading Valuation Sales chart');
      this.valuationSalesDataSource = AssessmentChartsUtility.assessmentDataSource('12', this.subjectProperty?.assessments, this.subjectProperty?.salesHistory, this.userAccessControls);
    }
  }

  onPageChange(event: PageEvent) {
    this.salesHistoryPageSize = event.pageSize;
    this.dataSource.paginator = this.paginator;
  }

  initializeSorting() {
    if (this.subjectProperty && this.saleListItems) {
      // Initialize sorting
      this.bindingMatSort();
    } else {
      this.propertyReportService.subjectProperty$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((newPropertyDetail) => {
          this.subjectProperty = newPropertyDetail;
          this.refreshUI();
        });
    }
  }
}
