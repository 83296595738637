import {EStoreParamAdditional} from "./e-store-param-additional";

export class EStoreParam{

  //Do not alter the positions of the contructor input parameters. Otherwise, a code refactoring is required.
  constructor(storeProductType: string, pin?: string, arn?: string, lro?: string | null, prodId?: string, docNumber?: string, licensePackageId?: number) {
    this.storeProductType = storeProductType;
    this.pin = pin;
    this.arn = arn;
    this.lro = lro;
    this.prodId = prodId;
    this.docNo = docNumber;
    if(licensePackageId){
      this.licensePackageId = licensePackageId;
    }
  }

  storeProductType?: any;
  token?: any;
  pin?: any;
  arn?: any;
  docNo?: any;
  lro?: any;
  tokenStr?: any;
  landRegistryStatus?: any;
  prodId?: any;
  uuid?: any;
  reportReference?: any;
  pybToken?: any;
  accessTokenLoginUrl?: any;

  licensePackageId? : number;

  // these are extra property params needed only for Residential Relocation Reports
  additionalPropertyParam : EStoreParamAdditional;
}



