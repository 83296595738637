import {BaseModel} from "../base-model";

export class InstrumentDocument extends BaseModel{
	attachments: any[];
	certified: string;
	contentType: string;
	documentNumber: string;
	documentSubTypeDesc: string;
	format: string;
	lroNumber: string;
	name: string;
	numberOfPages: number;
	registrationDate: string;
	typeIndicator: string;
	enabled: boolean;
	plan: boolean;
	instrument: boolean;
	waterMarkingRequired: boolean;
	attachment: boolean;
	pdf: boolean;
	tiff: boolean;
}
