import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrationTrackerComponent } from './registration-tracker/registration-tracker.component';
import { ComparablesComponent } from './comparables/comparables.component';
import { RegistrationTrackerMainComponent } from './registration-tracker/registration-tracker-main/registration-tracker-main.component';
import { ComparablesMainComponent } from './comparables/comparables-main/comparables-main.component';
import { CoreModule } from '../core/core.module';
import { MaterialModule } from '../core/material.module';
import { RegistrationTrackerPiiComponent } from './registration-tracker/registration-tracker-pii/registration-tracker-pii.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PrimeNGModule } from '../core/primeng.module';
import { RegistrationTrackerViewInstrumentDialogComponent } from '../core/component/modal/registration-tracker/registration-tracker-view-instrument-dialog.component';
import {ComparablesPropertyDetailComponent} from "./comparables/comparables-property-detail/comparables-property-detail.component";
import {ComparablesPropertyDetailHeaderComponent} from "./comparables/comparables-property-detail/comparables-property-detail-header/comparables-property-detail-header.component";

@NgModule({
  declarations: [
    RegistrationTrackerComponent,
    ComparablesComponent,
    RegistrationTrackerMainComponent,
    ComparablesMainComponent,
    RegistrationTrackerPiiComponent,
    RegistrationTrackerViewInstrumentDialogComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    MaterialModule,
    ReactiveFormsModule,
    PrimeNGModule,
    ComparablesPropertyDetailComponent,
    ComparablesPropertyDetailHeaderComponent
  ]
})
export class ReportModule { }
