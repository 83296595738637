// @ts-nocheck 

export var circleBuffer;
var circleCloseMarker;
var resizeCircleBubble = null;
export var newCircularBufferCentroid;
export var renderedCircularBufferObjects: any[] = [];

/**
 * A distance widget that will display a circle that can be resized and will provide the radius in km. 
 * 
 * @param map 
 * @param latLng 
 * @param rad 
 */
export function DistanceWidget(map, latLng, rad, closeCircleCallback) {

    renderedCircularBufferObjects = [];

    var circleCloseIcon = new google.maps.MarkerImage('assets/img/svg/map/drawing_map_x_hover.svg', null, /* size is determined at runtime */
        null, /* origin is 0,0 */
        new google.maps.Point(-20, 20), /* anchor is bottom center of the scaled image */
        new google.maps.Size(20, 20));

    circleCloseMarker = new google.maps.Marker({
        position: latLng,
        icon: circleCloseIcon,
        title: 'Close circular search area',
        map: map
    });

    renderedCircularBufferObjects.push(circleCloseMarker);

    google.maps.event.addListener(circleCloseMarker, "position_changed", function () {
        var position = centerMarker.getPosition();
        newCircularBufferCentroid = position;
        console.debug(`distance widget centroid changed to ${newCircularBufferCentroid}`);
    });

    /*
    google.maps.event.addListener(circleCloseMarker, 'click', function (closeCircleCallback) {
        closeCircularSearchBuffer.bind(this);
    });
    */

    this.set('map', map);
    this.set('position', latLng);

    var centerPointIcon = new google.maps.MarkerImage('assets/img/svg/map/drawing_map_centrepoint.svg', null, /* size is determined at runtime */
        null, /* origin is 0,0 */
        null, /* anchor is bottom center of the scaled image */
        new google.maps.Size(20, 20));

    var centerMarker = new google.maps.Marker({
        draggable: true,
        raiseOnDrag: false,
        title: 'Drag to re-position circular search area',
        icon: centerPointIcon
    });


    this.set('circularBufferCenterMarker', centerMarker);

    renderedCircularBufferObjects.push(centerMarker);

    google.maps.event.addListener(centerMarker, 'click', function () {
        if (circle != null) {
            circle.setDraggable(false);
        }
    });
    // Bind the marker map property to the DistanceWidget map property
    centerMarker.bindTo('map', this);

    // Bind the marker position property to the DistanceWidget position
    // property
    centerMarker.bindTo('position', this);

    // Create a new radius widget
    var radiusWidget = new RadiusWidget(rad);

    // Bind the radiusWidget map to the DistanceWidget map
    radiusWidget.bindTo('map', this);

    // Bind the radiusWidget center to the DistanceWidget position
    radiusWidget.bindTo('center', this, 'position');

    // Bind to the radiusWidgets' distance property
    this.bindTo('distance', radiusWidget);

    // Bind to the radiusWidgets' bounds property
    this.bindTo('bounds', radiusWidget);
}

DistanceWidget.prototype = new google.maps.MVCObject();

DistanceWidget.prototype.getRenderedCircularBufferObjects = function() {
    return renderedCircularBufferObjects;
}

DistanceWidget.prototype.addRenderedCircularBufferObject = function(obj) {
    renderedCircularBufferObjects.push(obj);
}

DistanceWidget.prototype.getCircle = function() {
    return circleBuffer;
}

DistanceWidget.prototype.getCircleCenter = function() {
    return newCircularBufferCentroid;
}

DistanceWidget.prototype.getCircleCloseMarker = function() {
    return circleCloseMarker;
}

DistanceWidget.prototype.center_changed = function () {
    this.set('radius', this.get('distance') * 1000);
};

/**
 * A radius widget that add a circle to a map and centers on a marker. 
 * 
 * @param radius 
 */
export function RadiusWidget(radius) {
    circleBuffer = new google.maps.Circle({
        fillColor: '#3A8FEF',
        fillOpacity: 0.25,
        strokeColor: '#3A8FEF',
        strokeOpacity: 1.0,
        strokeWeight: 2,
        clickable: false,
        draggable: false

    });
    this.set('distance', radius);
    this.bindTo('bounds', circleBuffer);
    circleBuffer.bindTo('center', this);
    circleBuffer.bindTo('map', this);
    circleBuffer.bindTo('radius', this);
    this.addSizer_();

    //add a circle custom property for search comparables
    circleBuffer['circularSearchArea'] = true;

    renderedCircularBufferObjects.push(circleBuffer);
}

RadiusWidget.prototype = new google.maps.MVCObject();

/** 
 * Update the center of the circle and position the sizer back on the line. 
 * 
 * Position is bound to the DistanceWidget so this is expected to change when 
 * the position of the distance widget is changed. 
 */
RadiusWidget.prototype.center_changed = function () {
    var bounds = this.get('bounds');

    // Bounds might not always be set so check that it exists first.
    if (bounds) {
        var lng = bounds.getNorthEast().lng();

        // Put the sizer at center, right on the circle.
        var position = new google.maps.LatLng(this.get('center').lat(), lng);
        this.set('sizer_position', position);
        circleCloseMarker.setPosition(this.get('sizer_position'));
        console.debug(`radius widget close marker changed to ${position}`);
    }
};

/**
 * Calculates the distance between two latlng locations in km.
 * 
 * @see http://www.movable-type.co.uk/scripts/latlong.html
 * @param {google.maps.LatLng}
 *            p1 The first lat lng point.
 * @param {google.maps.LatLng}
 *            p2 The second lat lng point.
 * @return {number} The distance between the two points in km.
 * @private
 */
RadiusWidget.prototype.distanceBetweenPoints_ = function (p1, p2) {
    if (!p1 || !p2) { return 0; }

    var R = 6371; // Radius of the Earth in km
    var dLat = (p2.lat() - p1.lat()) * Math.PI / 180;
    var dLon = (p2.lng() - p1.lng()) * Math.PI / 180;
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(p1.lat() * Math.PI / 180) * Math.cos(p2.lat() * Math.PI / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
};

/**
 * Set the distance of the circle based on the position of the sizer.
 */
RadiusWidget.prototype.setDistance = function () {
    // As the sizer is being dragged, its position changes. Because the
    // RadiusWidget's sizer_position is bound to the sizer's position, it will
    // change as well.
    var pos = this.get('sizer_position');
    var center = this.get('center');
    var distance = this.distanceBetweenPoints_(center, pos);
    circleCloseMarker.setPosition(this.get('sizer_position'));

    // Set the distance property for any objects that are bound to it
    this.set('distance', distance);
};

/**
 * Update the radius when the distance has changed.
 */
RadiusWidget.prototype.distance_changed = function () {
    this.set('radius', this.get('distance') * 1000);
    console.debug(`radius widget distance changed to ${this.get('radius')}`);
};

/**
 * Add the sizer marker to the map.
 * 
 * @private
 */
RadiusWidget.prototype.addSizer_ = function () {

    var drawing_map_drawing_curser = new google.maps.MarkerImage('assets/img/svg/map/drawing_map_resizing.svg', null, /* size is determined at runtime */
        null, /* origin is 0,0 */
        null, /* anchor is bottom center of the scaled image */
        new google.maps.Size(20, 20));

    var sizer = new google.maps.Marker({
        draggable: true,
        title: 'Drag to resize search radius',
        raiseOnDrag: false,
        icon: drawing_map_drawing_curser,
    });

    sizer.bindTo('map', this);
    sizer.bindTo('position', this, 'sizer_position');

    var me = this;
    google.maps.event.addListener(sizer, 'drag', function () {
        console.debug('resizing circular search area...');
        me.setDistance();

        // let m: number;
        // var updatedRadius: string;
        // var dist = me.get('distance');
        // if (dist != null && dist < 1) { // less than 1km
        //     let tempRadius = me.get('distance') * 1000;
        //     updatedRadius = tempRadius.toFixed(0) + "m";
        // } else {
        //     let tempRadius = me.get('distance');
        //     updatedRadius = tempRadius.toFixed(1) + "km";
        // }
        // const bubbleContent = '<div id="content">' + updatedRadius + "</div>";

        // if (resizeCircleBubble == null) {
        //     resizeCircleBubble = new google.maps.InfoWindow();
        //     google.maps.InfoWindow.prototype.isOpen = function() {
        //         var map = this.get('map');
        //         return (map !== null && typeof map !== "undefined");
        //     }
        // }

        // if (resizeCircleBubble.isOpen()) {
        //     resizeCircleBubble.close();
        // } else {
        //     resizeCircleBubble.open({
        //         content: bubbleContent,
        //         anchor: sizer,
        //         map: this.get('map')
        //     });
        // }
    });

};
