import {BaseModel} from "../base-model";
import {LicenseCounterTypeEnum} from "../../enum/license-counter-type-enum";

export class LicenseCounter extends BaseModel {
  counterId: number;
  businessEntityId: number;
  counterType: LicenseCounterTypeEnum;
  licenseLimit: number;
  licensePurchased: number;
  licenseUsed: number;
  expiryDate: Date;
  licenseRemaining: number;

  get licenseTotal(): number {
    return this.licenseLimit + this.licensePurchased;
  }

  get percentageOfReportsUsedSoFar(): number {
    return (this.licenseUsed/this.licenseTotal) * 100;
  }
}
