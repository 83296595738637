import { Assessment } from "../assessment/assessment";
import { BasicAssessment } from "../assessment/basic-assessment";
import { ConduitData } from "./conduit-data";
import { RelatedCondoDetail } from "./related-condo-detail";
import { BaseModel } from "../base-model";
import { SalesHistory } from "../sale/sales-history";
import { Pii } from "./pii";
import {Centroid} from "../spatial/centroid";



export class PropertyDetail {
  constructor(propDetail?: PropertyDetail) {
    if (propDetail) {
      for (let property in propDetail) {
        if (propDetail.hasOwnProperty(property)) {
          // @ts-ignore
          this[property] = propDetail[property];
        }
      }
      if (Array.isArray(propDetail.assessments)) {
        this.assessments = propDetail.assessments.map(value => { return new Assessment(value) });
      }
      if (Array.isArray(propDetail.relatedCondoDetails)) {
        this.relatedCondoDetails = propDetail.relatedCondoDetails.map(value => { return new RelatedCondoDetail(value) });
      }
      if (propDetail.condoDetail) {
        this.condoDetail = new CondoDetail(propDetail.condoDetail);
      }
      if (Array.isArray(propDetail.salesHistory)) {
        this.salesHistory = propDetail.salesHistory.map(value => { return new SalesHistory(value) });
      }
      if (propDetail.pii) {
        this.pii = new Pii(propDetail.pii);

        // copy spatial information from assessment if not present
        // if(this.pii?.spatial?.length == 0 && this.assessments[0]?.spatial?.length > 0){
        //   this.pii.spatial.push(...this.assessments[0].spatial);
        // }
      }
      // Observer that uses an instance of this class gets initialized if an empty one
      // and usually this gets loaded from backend so a propDetail from backend will be used to instantiate this class
      this.isEmpty = false;
    }
  }

  amountLeft: number;
  amountUsed: number;
  message: string;
  productId: number;
  assessments: Assessment[];
  condoDetail: CondoDetail;
  deededTogether: any[];
  latestPartyTos: string[];
  ownerNames: string[];
  pii: Pii;
  registrationType: string;
  relatedCondoDetails: RelatedCondoDetail[];
  salesHistory: SalesHistory[];
  condoSqft: string;
  ownerSameAsPartyTo: boolean;

  hasDemographicsData: boolean = false;
  isStreetViewAvailable: boolean;

  isEmpty:boolean = true;
  get hasHeatmapAccess(): boolean {
    return this.pii?.landRegistryStatus == 'A';
  }

  hasCondoDetail = () => {
    return !!(this.condoDetail);
  }

  isCondoUnitLockerOrParking = (): boolean => {
    let lockerOrParking: boolean = false;

    this.relatedCondoDetails?.filter(unit => {
      if (unit.pin == this.pii.pin) {
        if (unit.type != null && (unit.type == 'LOCKER_OR_PARKING' || unit.type == 'PARKING' || unit.type == 'LOCKER')) {
          lockerOrParking = true;
        }
      }
    });

    return lockerOrParking;
  }

  getCondoSuitePin = (): string | null => {
    let pin: string | null = null;

    this.relatedCondoDetails?.filter(unit => {
      if (unit.type != null && unit.type == 'SUITE') {
        pin = unit.pin;
      }
    });

    return pin;
  }

  get isCondoOrRelatedToACondo(): boolean {
    return (this.condoDetail?.type == 'SUITE' || this.relatedCondoDetails?.some(rcd => rcd?.type == 'SUITE'));
  }

  get hasLotSize(): boolean {
    return !! this.pii?.pinXy?.area && !!this.pii?.pinXy?.perimeter;
  }

  get hasArielMap(): boolean {
    if (this.pii?.spatial != null && this.pii?.spatial?.length > 0 && this.pii?.spatial[0]?.polygon != null) {
      return true;
    } else if (this.assessments?.length > 0 && this.assessments[0]?.spatial?.length > 0 && this.assessments[0].spatial[0].polygon != null) {
      return true;
    } else if (this.pii?.pinXy != null && this.pii.pinXy.centroid?.latitude != null && this.pii.pinXy.centroid?.longitude != null) {
      return true;
    } else {
      return false;
    }
  }

  get centroid(): Centroid {
    const centroidResult = new Centroid();
    if (this.pii?.pinXy?.centroid?.latitude && this.pii?.pinXy?.centroid?.longitude) {
      centroidResult.latitude = this.pii.pinXy?.centroid?.latitude,
      centroidResult.longitude= this.pii.pinXy?.centroid?.longitude;
    } else if(this.pii?.spatial?.length > 0 && this.pii?.spatial[0]?.centroid?.latitude  && this.pii?.spatial[0]?.centroid?.longitude){
      centroidResult.latitude = this.pii?.spatial[0]?.centroid?.latitude;
      centroidResult.longitude= this.pii?.spatial[0]?.centroid?.longitude;
    }
    return centroidResult;
  }

}

export class PropertyType extends BaseModel {
  code: string;
  description: string;
}

export interface Id {
  rollNumber: string;
  year: number;
}

export class PropertyValue extends BaseModel {
  id: Id;
  value: number;
}

export interface CountyMunicipality {
  code: string;
  description: string;
}

export interface XrefId {
  pin: string;
  rollNumber: string;
}

export interface Pin {
  xrefId: XrefId;
}


export interface Assessment2 {
  value: string;
  previousAssessment: BasicAssessment;
}

export interface OverallPhaseIn {
  phaseIn: BasicAssessment[];
  phaseInTaxYear: string;
}

export class PropertyZoning extends BaseModel {
  constructor(propertyZoning?: PropertyZoning) {
    super(propertyZoning);
  }
  zoning: string;
}

export class ResidentialStructure extends BaseModel{
  actualYearBuilt: string;
  condoParkingSpaces: string;
  condoParkingIndoorSpaces: string;
  condoFloorLevel: string;
  condoGarageTypeDescription: string;
  extraStandardStgLockersCount: number;
  indoorPool: string;
  outdoorPool: string;
  partStoreysCode: string;
  partStoreysDescription: string;
  splitLevelTypeCode: string;
  splitLevelTypeDescription: string;
  structureNumber: string;
  structureCode: string;
  structureDescription: string;
  totalBedroomsCount: string;
  totalHalfBathsCount: string;
  totalFullBathsCount: string;
  totalFullStoreysCount: string;
  totalFirePlacesCount: string;
}

export class CondoDetail extends BaseModel {
  constructor(condoDetail: CondoDetail) {
    super(condoDetail);
    if (Array.isArray(condoDetail.conduitDatas)) {
      this.conduitDatas = condoDetail.conduitDatas.map(value => { return new ConduitData(value) });
    }
  }

  commonName: string;
  conduitDatas: ConduitData[];
  corporationId: string;
  corporationName: string;
  createDate: Date;
  instrumentNum: string;
  levelNumber: string;
  pii?: any;
  pin: string;
  registrationDate: Date;
  suitePin: string;
  type: string;
  unitNumber: string;
  updateDate: Date;
}
