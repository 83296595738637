import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import * as _ from 'lodash';

import {OmnibarState} from '../../../core/component/omnibar/omnibar-state';
import {OmnibarStateEnum} from '../../../core/enum/omnibar-state-enum';

@Injectable({
    providedIn: 'root'
})
export class OmnibarStateService {

    initialDisplay: boolean = true;
    private _omnibarState = new BehaviorSubject<OmnibarState>(new OmnibarState());
    omnibarState = this._omnibarState.asObservable();

    private _omnibarSearchComparablesRequest = new BehaviorSubject<boolean>(false);
    omnibarSearchComparablesRequest$ = this._omnibarSearchComparablesRequest.asObservable();

    addSearchComparablesRequest(){
        this._omnibarSearchComparablesRequest.next(true);
    }

    update = (stateToUpdate: OmnibarStateEnum) => {
        if (this.initialDisplay) this.initialDisplay = false;

        let currentState = this._omnibarState.getValue();
        switch (stateToUpdate) {

            case OmnibarStateEnum.SEARCH_INITIATED:
                currentState.searchInitiated = true;
                currentState.searchResultsFound = false;
                this._omnibarState.next(currentState);
                break;

            case OmnibarStateEnum.SEARCH_ENDED:
                currentState.searchInitiated = false;
                this._omnibarState.next(currentState);
                break;

            case OmnibarStateEnum.SEARCH_RESULTS_FOUND:
                currentState.searchInitiated = false;
                currentState.searchResultsFound = true;
                this._omnibarState.next(currentState);
                break;

            case OmnibarStateEnum.SEARCH_RESULTS_NONE:
                currentState.searchInitiated = false;
                currentState.searchResultsFound = false;
                this._omnibarState.next(currentState);
                break;

        }
    }
}
