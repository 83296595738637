<h1 id="svd-h1-title" mat-dialog-title>{{svDialogData.title}}</h1>

<mat-divider></mat-divider>

<div class="dialog-content" mat-dialog-content #streetViewMapContainer id="street-view-map-container">
</div>

<mat-divider></mat-divider>

<div align="end" mat-dialog-actions>
  <button id="svd-btn-close" mat-button mat-dialog-close cdkFocusInitial class="dialog-close-btn" type="button">{{svDialogData.closeButtonText}}</button>
</div>
