import { ComparableSalesRequest } from "./comparable-sales-request";
import { ComparableSalesResponse } from "./comparable-sales-response";
import { SearchComparablesFormWrapper } from "./search-comparables-form-wrapper";

export class ComparableSalesResultPayload {
    request: ComparableSalesRequest;
    response: ComparableSalesResponse;
    formWrapper: SearchComparablesFormWrapper;

    isEmpty = () => {
        return !(this.response?.sales && this.response?.sales.length > 0);
    }
}