import { Roles } from "./user-roles";
import { Product } from "../product/product";
import { UserProfile } from "./user-profile";
import { UserLicense } from "./user-license";
import { LicenseCategoryEnum } from "../../enum/license-category-enum";
import * as _ from "lodash";

export class User {

  constructor(user?: User) {
    if (user) {
      for (let property in user) {
        if (user.hasOwnProperty(property)) {
          // @ts-ignore
          this[property] = user[property];
        }
      }
      if (user.userProfile) {
        this.userProfile = new UserProfile(user.userProfile);
      }
      if (user.licenses) {
        for (let key in user.licenses) {
          // @ts-ignore
          this.licenses[key] = user.licenses[key];
        }
      }
      if (user.roles) {
        this.roles = new Roles(user.roles);
      }
      this.products = [];
      if (Array.isArray(user.products)) {
        for (let i: number = 0; i < user.products.length; i++) {
          this.products[i] = new Product(user.products[i]);
        }
      }
      if (user.profilePhotoUrl){
        this.profilePhotoUrlRandomized = `${user.profilePhotoUrl}?random=${Math.random()}`;
      }

      this.isLegalAgreementAccepted = this.islegalAgrementAccepted;
    }
  }

  businessEntityId: number;
  companyBeid: number;
  legacySystemLink: string;
  licenses: Record<LicenseCategoryEnum, UserLicense>;
  name: string;
  permissions?: any;
  products: Product[];
  profilePhotoUrl?: string;
  profilePhotoUrlRandomized?: string; // used on display to avoid img caching
  roles: Roles;
  sessionExpiry: number;
  sessionId: string;
  tileAuthKey: string;
  tileServerURL: string;
  tileServerHttpProtocol: string;
  islegalAgrementAccepted: boolean;
  isLegalAgreementAccepted: boolean;
  isProfileAccepted: boolean;
  defaultLRO?: any;
  defaultSearch?: any;
  comparableSalesMultiplePolygonSearchMaxCount: number;
  userProfile: UserProfile;
  omnibarSearchTextLimit: number;
  fullName: string;
  defaultLro: string;
  userLicenseRenewalAvailable: boolean;
  topupLicenseBundleAvailable: boolean;
  email: string;
  userActive: boolean = true;
  requireLegalAgreement: boolean;
  isMultiLicense: boolean;
  promoStartDays: number;
  restrictRenewThroughGema: boolean = false;
  auth0ResponseCode: number;
  auth0ReasonPhrase: string;

  public get reportsUsedSoFar(): number {
    if (this.licenses[LicenseCategoryEnum.GWH_RENEWAL]) {
      let userLicense: UserLicense = this.licenses['GWH_RENEWAL'];
      if (userLicense && userLicense.licenseCountMax > 0) {
        return (userLicense.licenseCountUsed / userLicense.licenseCountMax) * 100;
      }
    }
    return 0;
  }

  public get totalReportsUsed() {
    if (this.licenses[LicenseCategoryEnum.GWH_RENEWAL]) {
      let userLicense: UserLicense = this.licenses['GWH_RENEWAL'];
      return userLicense.licenseCountUsed;
    }
    return 0;
  }

  public get totalReports() {
    if (this.licenses[LicenseCategoryEnum.GWH_RENEWAL]) {
      let userLicense: UserLicense = this.licenses['GWH_RENEWAL'];
      return userLicense.licenseCountMax;
    }
    return 0;
  }

  public get gwhReports() {
    if (this.licenses[LicenseCategoryEnum.GWH_RENEWAL]) {
      let userLicense: UserLicense = this.licenses['GWH_RENEWAL'];
      if (userLicense.licenseCountUnlimited) {
        return 'Unlimited';
      } else {
        return userLicense.licenseCountMax;
      }
    }
    return "N/A";
  }

  public get gwhEndDate() {
    if (this.licenses[LicenseCategoryEnum.GWH_RENEWAL]) {
      let userLicense: UserLicense = this.licenses['GWH_RENEWAL'];
      if (userLicense.licenseEndDate != null) {
        return userLicense.licenseEndDate;
      }
    }
    return 'N/A';
  }

  public get licenseAvailableForRenew() {
    if (this.licenses[LicenseCategoryEnum.GWH_RENEWAL]) {
      let userLicense: UserLicense = this.licenses['GWH_RENEWAL'];
      if (userLicense.licenseAvailableForRenew != null) {
        return userLicense.licenseAvailableForRenew;
      }
    }
    return false;
  }

  public get licenseLength(){
    return Object.keys(this.licenses).length;
  }

  public getProduct(id: number): Product | undefined {
    let product: Product | undefined;

    product = this.products?.find(product => {
      return product.productId == id;
    })

    return product;
  }

  // let's try to use userAccessControls for now
  private hasProductAccess(id: number): boolean {
    let product: Product | undefined = this.getProduct(id);

    return (product == undefined);
  }

  hasProductsConfigured(): boolean {
    return !_.isEmpty(this.products);
  }

  hasLicensePackageId(): boolean {
    return (!!this.userProfile?.licensePackageId && this.userProfile?.licensePackageId > 0);
  }

  isLicenseExpiringInLessThan90Days(): boolean {
    return this.licenseExpiringInDays() <= 90 && this.licenseExpiringInDays() >=0;
  }

  licenseExpiringInDays(): number {
    if (this.licenses[LicenseCategoryEnum.GWH_RENEWAL]) {
      let userLicense: UserLicense = this.licenses['GWH_RENEWAL'];
      if (userLicense.licenseEndDate != null) {
        let currentDate = new Date();
        currentDate.setHours(0,0,0);
        let licenseEndDate = new Date(userLicense.licenseEndDate);
        licenseEndDate.setHours(0, 0 ,0);
        let diff = licenseEndDate.getTime() - currentDate.getTime();
        return Math.abs(Math.ceil(diff / (1000 * 60 * 60 * 24)));
      }
    }
    return 0;
  }


  get hasUpdatableLicense() : boolean {
    return !(this.isMultiLicense === null || this.restrictRenewThroughGema || this.userProfile?.parentREB);
  }

  doesUserNeedLicenseReminder(): boolean {

    if( ! this.hasUpdatableLicense){
      return false;
    }

    const promoStartDays = this.promoStartDays ? this.promoStartDays : 90;

    const dailyReminders = [0, 1, 2, 3, 4, 5];

    for(let promoDay = promoStartDays -1; promoDay > 5; promoDay -= 15){
      dailyReminders.push(promoDay);
    }
    const days = this.licenseExpiringInDays();
    return (dailyReminders.indexOf(days) > -1);
  }

  get summaryInfo(): string{
    let summary = `${this.fullName} (${this.businessEntityId}) `;
    if(this.userProfile.company){
      summary += `from company '${this.userProfile.company}' (${this.companyBeid}) `;
    }
    if(this.userProfile.accountTypeName){
      summary += `with account type ${this.userProfile.accountTypeName} `;
    }
    return summary;
  }

}
