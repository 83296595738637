import { EventEmitter, inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Event, NavigationEnd, Router } from '@angular/router';
import { LoadingSpinnerDialogComponent } from '../..//core/component/modal/loading-spinner-dialog/loading-spinner-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class LoadingSpinnerService {

    constructor() {
    }

    private dialog = inject(MatDialog);
    
    public start(message?: any): MatDialogRef<LoadingSpinnerDialogComponent> {

        const dialogRef = this.dialog.open(LoadingSpinnerDialogComponent, {
            disableClose: true,
            data: message == '' || message == undefined ? "Loading..." : message,
            panelClass: 'loading-spinner-custom-modalbox'
        });
        return dialogRef;
    };

    public stop(ref: MatDialogRef<LoadingSpinnerDialogComponent>) {
        ref?.close();
    }
}