import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DemographicsAreaEnum} from "../../../../../core/enum/demographics-area.enum";
import {Demographics} from "../../../../../core/model/demographics/demographics";

@Component({
  selector: 'gema3g-demographics-household',
  templateUrl: './demographics-household.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./demographics-household.component.scss']
})
export class DemographicsHouseholdComponent {
  demographicsAreaEnum = DemographicsAreaEnum;
  @Input() selectedArea: DemographicsAreaEnum[] = [];
  @Input() demographicsData: Demographics = new Demographics();
}
