<div class="gema3g-ss-container">
  <div class="gema3g-ss-container__inner">

    <div class="gema3g-ss-container__inner-grid">

      <div class="l-column">
        <h4 id="ss-h4-pa-{{assessment.rollNumber}}">Previous Assessment</h4>
      </div>

      <div class="m-column">
        <div class="flex"><span id="ss-pa-{{assessment.rollNumber}}" class="ml-auto">{{(assessment.enhancedAssessment?.assessment?.previousAssessment?.assessmentValue) ? (assessment.enhancedAssessment?.assessment?.previousAssessment?.assessmentValue | currency) : "N/A"}}</span></div>
      </div>

      <div class="r-column">
      </div>

    </div>

  </div>
</div>
