import { BaseModel } from "../base-model";
import { RataReportModel } from "./report-model";

export class RataReportModels extends BaseModel {
    constructor(rrModels?: RataReportModels) {
        super(rrModels);

        if (rrModels) {
            if (Array.isArray(rrModels.models)) {
                this.models = rrModels.models.map(value => {
                    return new RataReportModel(value);
                });
            }
        }
    }

    public models: RataReportModel[];

    public isReportModelsExist() {
        return (this.models && this.models.length > 0) ? true : false;
    }
}