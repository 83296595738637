import {Component, Input} from '@angular/core';
import {Assessment} from "../../../../../core/model/assessment/assessment";
import {UserAccessControl} from "../../../../../core/model/user/user-access-control";

@Component({
  selector: 'gema3g-site-structure-assessed-value',
  templateUrl: './site-structure-assessed-value.component.html',
  styleUrls: ['./site-structure-assessed-value.component.scss']
})
export class SiteStructureAssessedValueComponent {
  @Input() assessment: Assessment = new Assessment();
  @Input() userAccessControls: UserAccessControl;
}
