import { Component } from '@angular/core';

@Component({
  selector: 'gema3g-comparables',
  templateUrl: './comparables.component.html',
  styleUrls: ['./comparables.component.scss']
})
export class ComparablesComponent {

}
