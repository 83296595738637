export class Product {

  constructor(product? : Product) {
    if(product){
      for(let property in product){
        if(product.hasOwnProperty(property)){
          // @ts-ignore
          this[property] = product[property];
        }
      }
    }
  }

  countable: boolean;
  productId: number;
  productName: string;
  transactional: boolean;
}
