import {Component, Input} from '@angular/core';
import {EstoreService} from "../../../../../shared/service/estore.service";
import {EStoreProduct} from "../../../../../core/model/product/e-store/e-store-product";
import {Assessment} from "../../../../../core/model/assessment/assessment";
import {UserAccessControl} from "../../../../../core/model/user/user-access-control";
import {EStoreParam} from "../../../../../core/model/product/e-store/e-store-param";

@Component({
  selector: 'gema3g-site-structure-residential',
  templateUrl: './site-structure-residential.component.html',
  styleUrls: ['./site-structure-residential.component.scss']
})
export class SiteStructureResidentialComponent {
  @Input() assessment: Assessment = new Assessment();
  @Input() userAccessControls: UserAccessControl;

  constructor(private eStoreService: EstoreService) {
  }

  async openStore() {
    const eStoreParam = new EStoreParam('ResidentialFloorAreaReport',  '',this.assessment.siteDetail.arn);
    await this.eStoreService.openReport(eStoreParam);
  }
}
