<div class="main-panel">
  <div class="top-row">
    <div class="addr-overlay-back-btn">
      <a id="gamo-link-close" (click)="closeStreetView()">
        <div class="close-street-view">
          <img id="gamo-close-icon" src="/assets/img/svg/icon_map_streetview_back.svg">
        </div>
      </a>
    </div>
    <div class="addr-overlay-label">
      @if (addressUpdating) {
        <div class="address center">
          <img id="gamo-loading-icon" class="loader" src="/assets/img/svg/map/icon-address-overlay-loading.svg">
        </div>
      }
      @if (!addressUpdating) {
        <span id="gamo-address" class="address text-os-bold">{{calculatedAddress()}}</span>
        @if (pin) {
          <div id="gamo-pin" class="pin" (click)="openPropertyReport(pin)">
            <span>Property Report</span>
            <img class="icon" src="assets/img/svg/map/icon_map_bubble_propertyreport.svg">
          </div>
        }
      }
    </div>
  </div>

  <div id="gamo-mapContainer" #mapContainer class="map-row">
  </div>
</div>
