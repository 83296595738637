@if(isOktaLogin){
  <div class="h-screen flex items-center justify-content-center">
    <h1 class="text-3xl font-bold">Please wait while we are checking your account</h1>
    <div>
      <img id="login-img-spinner" alt="Loading..." src="assets/img/icon-refresh.gif"/>
    </div>
  </div>
} @else {
<section class="login-container">
    <div class="form">
      <img id="login-main-logo" src="{{ themeService.activeTheme === 'dark' ? lightLogo : logo }}" />

      @if (isSSOLogin) {
        <div class="sso-container">

          <div id="login-sso" class="sso-text">
            Single Sign On
          </div>

          @if (ssoLoginError) {
            <div class="login-warning-div">
              <span id="login-warning" class="text-sm">{{ssoLoginText}}</span>
            </div>
          }
          @else {
            <div id="login-sign-in" class="sign-in-text">
              {{ssoLoginText}}
            </div>
          }
        </div>
      }
      @else {
        <mat-form-field id="login-ff-username" appearance="outline">
          <mat-label id="login-label-username">User name</mat-label>
          <mat-icon id="login-icon-username" matPrefix>person</mat-icon>
          <input matInput [(ngModel)]="userName" id="login-tf-username" name="login-username" (keyup.enter)="onLoginClicked()">
        </mat-form-field>

        <mat-form-field id="login-ff-password" appearance="outline">
          <mat-icon matPrefix id="login-icon-password">lock</mat-icon>
          <mat-label id="login-label-password">Password</mat-label>
          <input matInput [(ngModel)]="password" [type]="showPassword ? 'text' : 'password'" id="login-tf-password"
            (keyup.enter)="onLoginClicked()">
          <mat-icon id="login-icon-password-visibility" (click)="togglePasswordVisibility()" matSuffix>
            {{showPassword ? 'visibility_off' : 'visibility'}}
          </mat-icon>
        </mat-form-field>

        @if (loginError.anyBlueErrors) {
          <div class="login-warning-div">

            @if (loginError.badCredentials) {
              <span id="login-err-bad-credentials" class="text-sm">Bad Credentials, please try again.</span>
            }

            @if (loginError.backendNotResponding) {
              <span id="login-err-backend-not-responding" class="text-sm">Sorry, there is currently a system error. Please try logging in again at a later time.</span>
            }
          </div>
        }

        @if (loginError.genericLoginError) {
          <mat-error id="login-err-generic">
            An error has occurred, please try again.
          </mat-error>
        }

        <div class="sign-in">
          <button id="login-btn-sign-in" (click)="onLoginClicked()" [class.spinner]="isLoading" class="sign-in-btn"
            [disabled]="isLoading" mat-raised-button>
            {{loginButtonText}}
          </button>

          <a href="" id="login-link-okta-sign-in" class="okta-sign-in" (click)="oktaLogin()">OKTA Sign in</a>
        </div>
      }
    </div>


    @if (!isSSOLogin) {
      <footer>
        <div class="footer-container">
          <img id="login-ftr-logo" src="{{ lightLogo }}" />
          <div class="legal">
            <h2 id="login-h2-ftr">Legal Navigation</h2>
            <a id="login-link-ftr-privacy" href="{{ privacyStatementUrl }}" target="_blank">Privacy Statement</a>
            <a id="login-link-ftr-terms" href="{{ termsOfUseUrl }}">Terms of Use</a>
            <a id="login-link-ftr-security" href="{{ securityStatementUrl }}" target="_blank">Security Statement</a>
            <hr />
            <!-- Temporarily disabled dark theme
              <a *ngFor="let themeName of themeService.themeNames; let i = index" (click)="themeService.set(themeName)">
                @if (i === 0 && themeService.activeTheme === 'dark') {
                  <span id="login-ftr-light-theme">Light Theme</span>
                }

                @if (i === 1 && themeService.activeTheme === 'light') {
                  <span id="login-ftr-dark-theme">Dark Theme</span>
                }
              </a>
            -->
          </div>
        </div>
      </footer>
    }
</section>
}
