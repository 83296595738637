import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Assessment} from "../../../../../core/model/assessment/assessment";
import {UserAccessControl} from "../../../../../core/model/user/user-access-control";
import {MatTableDataSource} from "@angular/material/table";
import {Structure} from "../../../../../core/model/assessment/structure";
import {PropertyReportComponentBase} from "../../property-report-component-base";

@Component({
  selector: 'gema3g-site-structure-enhanced-data',
  templateUrl: './site-structure-enhanced-data.component.html',
  styleUrls: ['./site-structure-enhanced-data.component.scss']
})
export class SiteStructureEnhancedDataComponent extends PropertyReportComponentBase implements OnChanges {
  @Input() assessment: Assessment = new Assessment();
  @Input() userAccessControls: UserAccessControl;

  structuresDataSource: MatTableDataSource<Structure>;
  columns: string[] = [
    'structureNumber',
    'structureDescription',
    'actualYearBuilt',
    'totalBedroomsCount',
    'totalFullBathsCount',
    'totalHalfBathsCount',
    'totalFullStoreysCount',
    'partStoreysDescription',
    'splitLevelTypeDescription',
    'totalFirePlacesCount'
  ];

  ngOnChanges(changes: SimpleChanges): void {
    if (this.assessment?.enhancedAssessment?.structures) {
      this.structuresDataSource = new MatTableDataSource<Structure>(this.assessment.enhancedAssessment.structures);
    }
  }
}


