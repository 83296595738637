import {BaseAssessmentProperty} from "./base-assessment-property";
import {DataService} from "../../../shared/service/data.service";

export class EnhancedAssessmentProperty extends BaseAssessmentProperty {

  constructor(enhancedAssessmentProperty: EnhancedAssessmentProperty) {
    super(enhancedAssessmentProperty);
    if (enhancedAssessmentProperty) {
      for (let property in enhancedAssessmentProperty) {
        if (enhancedAssessmentProperty.hasOwnProperty(property)) {
          // @ts-ignore
          this[property] = enhancedAssessmentProperty[property];
        }
      }
    }
  }

  municipalityDescription: string;
  actualFrontageSys: string;
  actualDepthSys: string;
  actualLotSizeSys: string;
  totalGarageSpaces: string;
  totalStandardLockers: string;
  drivewayTypeCode: string;
  pool: string;
  isCondo: string;
  garageTypeDescription: string;
  garageTypeCondo: string;

  get garageType(): string {
    if (this.isCondo == "N") {
      return this.garageTypeDescription;
    } else if (this.garageTypeDescription == null && this.garageTypeCondo != null) {
      return this.garageTypeCondo;
    } else if (this.garageTypeCondo == null && this.garageTypeDescription != null) {
      return this.garageTypeDescription;
    }
    return DataService.NOT_APPLICABLE;
  }
}
