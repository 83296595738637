import {BaseModel} from "../base-model";
import {OwnerToPropertyMapItem} from "./owner-to-property-map-item";


export class OwnerToPropertyMap extends BaseModel {
  constructor(name: string, ownerToPropertyMapItems: OwnerToPropertyMapItem[]) {
    super();
    this.name = name;
    if (Array.isArray(ownerToPropertyMapItems)) {
      ownerToPropertyMapItems.forEach(value => {
        this.ownerToPropertyMapItems.push(new OwnerToPropertyMapItem(value));
      });
    }
  }

  name: string;
  ownerToPropertyMapItems: OwnerToPropertyMapItem[] = [];

  // UI only
  detailsExpanded: boolean = false;
  arnExpanded: boolean = false;
  showExpansionImages: boolean = false;

  get fullAddress(): string {
    if (this.hasOneItem) {
      if (this.ownerToPropertyMapItems[0]?.pii?.address) {
        return this.ownerToPropertyMapItems[0]?.pii?.address.fullAddress;
      }
      return "Address Not Available";
    }
    return "";
  }

  get legalDescription(): string {
    if (this.hasOneItem && this.ownerToPropertyMapItems[0]?.pii) {
      return this.ownerToPropertyMapItems[0].pii.shortLegalDescription;
    }
    return '';
  }

  get streetName(): string {
    if (this.hasOneItem && this.ownerToPropertyMapItems[0]?.pii?.address?.streetName) {
      return this.ownerToPropertyMapItems[0].pii.address.streetName;
    }
    return '';
  }

  get streetNumber(): string {
    if (this.hasOneItem && this.ownerToPropertyMapItems[0]?.pii?.address?.streetNumber) {
      return this.ownerToPropertyMapItems[0].pii.address.streetNumber;
    }
    return '';
  }

  get streetNumberInt(): number {
    if (this.hasOneItem && this.ownerToPropertyMapItems[0]?.pii?.address?.streetNumber) {
      return parseInt(this.ownerToPropertyMapItems[0].pii.address.streetNumber);
    }
    return 0;
  }

  get unitNumber(): string {
    if (this.hasOneItem && this.ownerToPropertyMapItems[0]?.pii?.address?.unitNumber) {
      return this.ownerToPropertyMapItems[0].pii.address.unitNumber;
    }
    return '';
  }

  get pin(): string {
    if (this.hasOneItem && this.ownerToPropertyMapItems[0]?.pii?.pin) {
      return this.ownerToPropertyMapItems[0].pii.pin;
    }
    return '';
  }

  get arn(): string {
    if (this.hasOneItem && this.ownerToPropertyMapItems[0]?.pii?.arns?.length > 0) {
      return this.ownerToPropertyMapItems[0].pii.arns[0];
    }
    return '';
  }

  get arns(): string[] {
    if (this.hasOneItem && this.ownerToPropertyMapItems[0]?.pii?.arns?.length > 0) {
      return this.ownerToPropertyMapItems[0].pii.arns;
    }
    return [];
  }

  get unitType(): string {
    if (this.hasOneItem && this.ownerToPropertyMapItems[0]?.pii?.unitType) {
      return this.ownerToPropertyMapItems[0].pii.unitType;
    }
    return '';
  }

  get hasOneItem(): boolean {
    return this.ownerToPropertyMapItems && this.ownerToPropertyMapItems[0]?.count == 1;
  }
  get hasMultipleItems(): boolean {
    return this.ownerToPropertyMapItems && this.ownerToPropertyMapItems[0]?.count > 1;
  }
  get numberOfProperties(): number {
    return this.ownerToPropertyMapItems && this.ownerToPropertyMapItems[0]?.count;
  }
}
