<!-- @defer (on viewport) { -->
  <div class="flex flex-col">
    @if (selectedArea.includes(demographicsAreaEnum.NBH)) {
      <div id="dg-pop-ad-fusionchart-nbh">
        <fusioncharts
          type="column2d"
          [dataSource]="nbhDataSource"
          dataFormat="json"
          height="{{defaultHeight}}"
          width="{{defaultWidth}}"
          [dataEmptyMessage]="ds.nbhDataEmptyMessage"
          (initialized)="initialized($event)"
        ></fusioncharts>
      </div>
    }

    @if (selectedArea.includes(demographicsAreaEnum.COM)) {
      <div id="dg-pop-ad-fusionchart-com">
        <fusioncharts
          type="column2d"
          [dataSource]="comDataSource"
          dataFormat="json"
          height="{{defaultHeight}}"
          width="{{defaultWidth}}"
          [dataEmptyMessage]="ds.comDataEmptyMessage"
          (initialized)="initialized($event)"
        ></fusioncharts>
      </div>
    }
    @if (selectedArea.includes(demographicsAreaEnum.CITY)) {
      <div id="dg-pop-ad-fusionchart-city">
        <fusioncharts
          type="column2d"
          [dataSource]="cityDataSource"
          dataFormat="json"
          height="{{defaultHeight}}"
          width="{{defaultWidth}}"
          [dataEmptyMessage]="ds.cityDataEmptyMessage"
          (initialized)="initialized($event)"
        ></fusioncharts>
      </div>
    }
  </div>
<!-- } @placeholder {
  <div>
    Loading data...
  </div>
} -->
