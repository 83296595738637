<div class="item-wrapper">
  <h4 id="estore-h4-front-pc-{{productCategoryItem?.linkParamType}}">{{productCategoryItem?.title}}</h4>
  <div class="lg:flex mt-3">
    <img id="estore-img-front-pc-{{productCategoryItem?.linkParamType}}" class="max-w-[82px] max-h-[82px] mb-2 lg:mr-5" alt="{{productCategoryItem?.imgAlt}}" src="{{productCategoryItem?.img}}">
    <div id="estore-front-pc-content-{{productCategoryItem?.linkParamType}}">
      {{productCategoryItem?.content}}
      <a id="estore-link-front-pc-{{productCategoryItem?.linkParamType}}" (click)="openEstoreCatalogue(productCategoryItem?.link, productCategoryItem?.linkParamType)" class="anchor">
        {{productCategoryItem?.linkCaption}}
      </a>
    </div>
  </div>
</div>