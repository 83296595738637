<div class="gema3g-ss-container">
  <div class="gema3g-ss-container__inner">

    <div class="gema3g-ss-container__inner-grid">
      <div class="l-column">
        <h4 id="ss-h4-site-{{assessment.rollNumber}}">Site</h4>
      </div>
      <div class="m-column">
        <div id="ss-site-frontage-label-{{assessment.rollNumber}}" class="flex">
          <h4 id="ss-h4-frontage-{{assessment.rollNumber}}">Frontage </h4>
          <span id="ss-site-frontage-{{assessment.rollNumber}}" class="ml-auto">{{ frontage }}</span>
        </div>

        @if (!userAccessControls.MPACSubscriptionEnabled && userAccessControls.assessmentDetailsReport){
          <div id="ss-site-sa-label-{{assessment.rollNumber}}" class="flex">
            <h4 id="ss-h4-sa-{{assessment.rollNumber}}">Site Area </h4>
            <span id="ss-site-sa-{{assessment.rollNumber}}" class="ml-auto">
              @if (assessment.baseInfo?.siteArea == 0) {
                {{NA}}
              }
              @else {
                {{assessment.baseInfo?.siteArea}}{{assessment.baseInfo?.originalUOM}}
              }
            </span>
          </div>
        }

        @if (userAccessControls.MPACSubscriptionEnabled && userAccessControls.MPACSubscriptionPremiumOrEnhanced){
          <div id="ss-site-sa-label-{{assessment.rollNumber}}" class="flex">
            <h4 id="ss-h4-sa-{{assessment.rollNumber}}">Zoning </h4>
            <span id="ss-site-sa-{{assessment.rollNumber}}" class="ml-auto">
              {{(assessment.mpacAssessment?.property?.propertyZoning && assessment.mpacAssessment.property.propertyZoning.length > 0)?
              getZoningList(assessment.mpacAssessment.property.propertyZoning) : 'N/A'}}
            </span>
          </div>
        }
      </div>
      <div class="r-column">
        <div id="ss-site-depth-label-{{assessment.rollNumber}}" class="flex">
          <h4 id="ss-h4-depth-{{assessment.rollNumber}}">Depth </h4>
          <span id="ss-site-depth-{{assessment.rollNumber}}" class="ml-auto"> {{ depth }}</span></div>
      </div>
    </div>

  </div>
</div>
