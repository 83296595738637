<!-- @defer (on viewport) { -->
  <span id="dg-hh-ha-label" class="demographics-sub-header">
  Age of Home (Years)
</span>
  <div class="flex flex-col">
    @if (selectedArea.includes(demographicsAreaEnum.NBH)) {
      <div class="margin-auto" id="chart-home-age-nbh">
        <fusioncharts
          [dataSource]="nbhDataSource"
          dataFormat="json"
          type="column2d"
          height="177"
          width="{{defaultWidth}}"
          [dataEmptyMessage]="ds.nbhDataEmptyMessage"
          (initialized)="initialized($event)"
        ></fusioncharts>
      </div>
    }

    @if (selectedArea.includes(demographicsAreaEnum.COM)) {
      <div class="margin-auto" id="chart-home-age-com">
        <fusioncharts
          [dataSource]="comDataSource"
          dataFormat="json"
          type="column2d"
          height="177"
          width="{{defaultWidth}}"
          [dataEmptyMessage]="ds.comDataEmptyMessage"
          (initialized)="initialized($event)"
        ></fusioncharts>
      </div>
    }

    @if (selectedArea.includes(demographicsAreaEnum.CITY)) {
      <div class="margin-auto" id="chart-home-age-city">
        <fusioncharts
          [dataSource]="cityDataSource"
          dataFormat="json"
          type="column2d"
          height="177"
          width="{{defaultWidth}}"
          [dataEmptyMessage]="ds.cityDataEmptyMessage"
          (initialized)="initialized($event)"
        ></fusioncharts>
      </div>
    }
  </div>
<!-- } @placeholder {
  <div>Loading data...</div>
} -->
