import { BaseModel } from '../base-model';
import { Message } from './message';
import * as _ from 'lodash';

export class BannerMessage extends BaseModel {
    constructor(bannerMessage?: BannerMessage) {
        super(bannerMessage);

        if (bannerMessage) {
            if (Array.isArray(bannerMessage.messages)) {
                this.messages = bannerMessage.messages.map(value => {
                    return new Message(value);
                });
            }
        }
    }

    private messages: Message[];

    public getMessages(): Message[] {
        return this.messages;
    }

    public setMessages(messages: Message[]): void {
        this.messages = messages;
    }

    public hasMessages = () => {
        return !_.isEmpty(this.getMessages());
    }

    /**
     * Explicitly expires all messages on the client side as the backend is not explicitly setting it.
     */
    public setMessagestoExpired = () => {
        if (this.hasMessages()) {
            this.messages = this.messages.map((message) => {
                let today = new Date();
                if (today > message.getCreateDate()) {
                    message.setStatus('E');
                }
                return message;
            });
        }
    }
}
