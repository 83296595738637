import { Component } from '@angular/core';

@Component({
  selector: 'gema3g-site-down',
  standalone: true,
  imports: [],
  templateUrl: './site-down.component.html'
})
export class SiteDownComponent {

}
