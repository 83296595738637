import {ReportPreference} from "./report-preference";

export class PropertyReportPreference {
  constructor(propertyReportPreference?: PropertyReportPreference) {
    if(propertyReportPreference){
      this.reportPreferences = new ReportPreference(propertyReportPreference.reportPreferences);
    }
  }

  reportPreferences: ReportPreference;

  patchFromUserInput(propertyReportPreference: PropertyReportPreference) {
    if(propertyReportPreference){
      this.reportPreferences.patchFromUserInput(propertyReportPreference.reportPreferences);
    }

  }

  makeAdjustmentsOnSystemDefaultValues() {

  }
}
