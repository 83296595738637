export class FusionChartLayout {
  numberSuffix : string;
  paletteColors : string;
  bgColor: string;
  showBorder: string;
  use3DLighting : string;
  showShadow : string;
  enableSmartLabels : string;
  startingAngle : string;
  showPercentValues : string;
  legendShadow : string;
  legendBorderAlpha : string;
  centerLabelBold : string;
  showTooltip : string;
  decimals : string;
  captionFontSize : string;
  subcaptionFontSize : string;
  subcaptionFontBold : string;
  chartLeftMargin : string;
  chartRightMargin : string;
  chartTopMargin : string;
  chartBottomMargin : string;
  showLegend : string;
  showLabels : string;
  showValues : string;
  baseFont : string;
  baseFontSize : string;
  baseFontColor : string;
  enableSlicing : string;
  pieRadius : string;
  defaultCenterLabel: string;
  legendPosition: string;
  dataEmptyMessage: string;
  xaxismaxvalue: string;
  yaxismaxvalue: string;
  formatnumberscale: string;
  borderAlpha: string;
  showCanvasBorder: string;
  usePlotGradientColor: string;
  plotBorderAlpha: string;
  valueFontColor: string;
  showXAxisLine: string;
  canvasBottomMargin: string;
  canvasTopMargin: string;
  placeValuesInside: string;
  plotSpacePercent: string;
  showVLineLabelBorder: string;
  adjustDiv: string;
  showYAxisValues: string;
  labelDisplay: string;
  showHoverEffect: string;
  showAlternateVGridColor: string;
  divLineAlpha: string;
  divLineDashed: string;
  divlineColor: string;
  xAxisLineColor: string;
  canvasLeftMargin: string;
  canvasRightMargin: string;
  canvasBorderAlpha: string;
  rotatevalues: string;
  labelFont: string;
  showAlternateHGridColor: string;
  showAxisLines: string;
  alignCaptionWithCanvas: string;
  divLineIsDashed: string;
  theme: string;
  caption: string;
  subCaption: string;
  xAxisname: string;
  yAxisName: string;
  numberPrefix: string;
  connectNullData: string;
  canvasBorderColor: string;
  canvasBorderThickness: string;
  plotgradientColor: string;
  captionPadding: string;
  lineThickness: string;
  yaxisValuesPadding: string;
  drawAnchors: string;
  anchorRadius: string;
  valueBorderThickness: string;
  valueBorderPadding: string;
  valueFontSize: string;
  valueFontBold: string;
  valueBorderColor: string;
  valueBorderRadius: string;
  yaxisminvalue: any;

}
