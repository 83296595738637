<div class="flex flex-row">
  <div class="flex-1 flex flex-row gap-3">
    <div id="ss-an-assessment-{{assessment.rollNumber}}" class="pr-site-structure-section-label pr-site-structure-arn my-8">
      <h4 id="ss-h4-an-{{assessment.rollNumber}}">Assessment </h4>
      <span id="ss-an-badge-{{assessment.rollNumber}}" matBadge="{{assessmentIndex + 1}}" matBadgeOverlap="false"></span>
    </div>
    <div id="ss-an-arn-{{assessment.rollNumber}}" class="pr-site-structure-section-label my-8 ml-4">
      <div class="arn">ARN : {{ assessment.rollNumber }}</div>
      <div class="icon" [satPopoverAnchor]="copyArnPopOver" (click)="copyArn(assessment.rollNumber)">
        <mat-icon id="reg-icon-copy-pin" class="" aria-hidden="false" aria-label="Copy pin" fontIcon="content_copy">
        </mat-icon>
      </div>
      <sat-popover #copyArnPopOver [autoFocus]="false" [restoreFocus]="false" horizontalAlign="after" openTransition="500ms ease-out" closeTransition="100ms ease-in">
        <gema3g-tooltip [content]="copiedArn"></gema3g-tooltip>
      </sat-popover>
    </div>
    @if (assessmentIndex < assessmentTotalNumber) {
      <div class="content-around">
        @if (assessmentIndex < assessmentTotalNumber - 1) {
          <mat-icon id="ss-icon-an-go-to-next-{{assessment.rollNumber}}" (click)="goToNextAssessment()">arrow_downward
          </mat-icon>
        }
        @if (assessmentIndex > 0) {
          <mat-icon id="ss-icon-an-go-to-prev-{{assessment.rollNumber}}" (click)="goToPreviousAssessment()">arrow_upward
          </mat-icon>
        }
      </div>
    }
  </div>
  <div class="flex-1 flex flex-row gap-3 justify-end">
    <div>
      @if (userAccessControls.propertyLineAccess) {
        <a id="ss-link-an-pl-{{assessment.rollNumber}}" class="anchor" (click)="openPropertyLine(assessment.rollNumber)">
          <img id="ss-an-mpac-logo" class="mpaclogo" src="/assets/img/mpac/mpaclogo.png"/>
          propertyline
        </a>
      }
    </div>
    <div>
      @if (assessment.spatial[0]?.polygon && userAccessControls.TeranetLotDetails) {
        <div class="no-print">
          <a id="ss-link-an-mi-{{assessment.rollNumber}}" class="anchor" (click)="drawAssessmentPolygon(assessment)"
             onclick="setTimeout(() => {scrollToElementById0('parcelMapContainer')}, 500);">
            <img id="ss-an-mi-icon" class="imgmap" src="/assets/img/icon_mapit.png"/>
            Map It
          </a>
        </div>
      }
    </div>
  </div>
</div>
