import {BaseModel} from "../base-model";

export class Roles extends BaseModel{

  constructor(role? : Roles) {
    super(role);
  }

	vimo: boolean;
	managementreports: boolean;
	rmsb2b: boolean;
	lppadmins: boolean;
	hostedpaypage: boolean;
	prodadmin: boolean;
	purview: boolean;
	ssogroup: boolean;
  ssouser: boolean;
	thirdpartyuser: boolean;
	lppuser: boolean;
	listingb2b: boolean;
	customercare: boolean;
  realm: boolean;
  realmiq: boolean;
}
