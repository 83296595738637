import {inject, Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {HomeParam} from "../../core/model/home/home-param";
import {LoggerService} from "./log/logger.service";

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  private loggerService = inject(LoggerService);

  private _childrenRequest = new BehaviorSubject<HomeParam>(new HomeParam("", "", false));
  childrenRequest$  = this._childrenRequest.asObservable();

  //To be used by children of home component to request an update on property report
  appendChildrenRequest(param : HomeParam){
    this.loggerService.logDebug(`appending children request ${param}`);
    this._childrenRequest.next(param);
  }
}
