import { BaseModel } from "../base-model";

export class PropertyViewedInsightRecord extends BaseModel {
    constructor(pviRecord: PropertyViewedInsightRecord) {
        super(pviRecord);
    }
    
    businessEntityId: number;
    count: number;
    days: number;
}