import { AfterViewInit, Component, inject, OnInit, ViewChild } from '@angular/core';
import { UserService } from "../../../shared/service/user.service";
import { lastValueFrom, map, merge, startWith, switchMap, tap } from "rxjs";
import { FulfillmentItem } from "../../../core/model/download/fulfillment-item";
import { DownloadService } from "../../../shared/service/download.service";
import { EstoreService } from "../../../shared/service/estore.service";
import dayjs from "dayjs";
import { DaterangepickerDirective } from "ngx-daterangepicker-material";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { Download } from "../../../core/model/download/download";
import { MatSort } from "@angular/material/sort";
import { LoggerService } from '../../../shared/service/log/logger.service';


@Component({
  selector: 'gema3g-account-download',
  templateUrl: './account-download.component.html',
  styleUrls: ['./account-download.component.scss']
})
export class AccountDownloadComponent implements OnInit {
  @ViewChild(DaterangepickerDirective, {static: false}) pickerDirective: DaterangepickerDirective;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  isLoading: boolean = false;
  items: FulfillmentItem[];
  dataSource = new MatTableDataSource<FulfillmentItem>();
  displayedColumns: string[] = ['date', 'product', 'details', 'transaction'];
  pageEvent: Promise<void>;
  pageIndex: number;
  pageSize: number;
  length: number = 0;
  pageSizes = [5, 10, 20];
  sortDirection: string = '';
  sortField: string = '';

  startDate: any;
  endDate: any;
  selectedRange: any = {};
  customRanges: any = {
    'Today': [dayjs(), dayjs()],
    'Yesterday': [dayjs().subtract(1, 'days'), dayjs().subtract(1, 'days')],
    'Last 7 Days': [dayjs().subtract(6, 'days'), dayjs()],
    'Last 30 Days': [dayjs().subtract(29, 'days'), dayjs()],
    'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
    'Last Month': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    'Last 3 Months': [dayjs().subtract(3, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    'Last 6 Months': [dayjs().subtract(6, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')]
  }
  dataPickerSettings: {
    displayFormat: 'MMM,DD YYYY',
    direction: 'rtl',
    separator: ' To ',
  }

  constructor() {
  }

  private userService = inject(UserService);
  private downloadService = inject(DownloadService);
  private eStoreService = inject(EstoreService);
  private loggerService = inject(LoggerService);

  ngOnInit(): void {
    this.startDate = dayjs().subtract(3, 'months');
    this.endDate = dayjs();
    this.selectedRange.startDate = this.startDate;
    this.selectedRange.endDate = this.endDate;
    setTimeout(() => {
      this.getDownloads('', null);
    }, 100);
  }

  getItems$(pageNum: number, pageSize: number, orderBy: string, orderDir: string, startDate: string, endDate: string) {
    return this.downloadService.getDownloads(pageNum, pageSize, orderBy, orderDir, startDate, endDate);
  }

  async getDownloads(source: string, $event: any) {
    this.loggerService.logInfo($event);
    if (source == 'sort') {
      this.sortDirection = !$event.direction ? '' : $event.direction;
      this.sortField = !$event.direction ? '' : $event.active;
    }

    this.isLoading = true;
    try {
      const download = await lastValueFrom(this.downloadService.getDownloads(this.paginator.pageIndex, this.paginator.pageSize, this.sortField, this.sortDirection, this.startDateFormatted, this.endDateFormatted));
      this.items = [];
      this.items.push(...download.items.map(value => {
        return new FulfillmentItem(value);
      }));
      this.dataSource = new MatTableDataSource(this.items);
      this.length = download.totalItems;
      this.pageSize = this.paginator?.pageSize;
    } finally {
      this.isLoading = false;
    }
  }


  get startDateFormatted(): string {
    if (this.startDate) {
      return this.startDate.format('YYYY-MM-DD');
    }
    return '';
  }

  get endDateFormatted(): string {
    if (this.endDate) {
      return this.endDate.format('YYYY-MM-DD');
    }
    return '';
  }

  downloadReport(item: FulfillmentItem) {
    this.downloadService.downloadReport(item?.pk?.fulfillmentId, item?.pk?.itemId);
  }

  openPYBReportStatus(item: FulfillmentItem) {
    this.downloadService.openPYBReportStatus(item);
  }

  async redirectToGWHInvoicePage(transactionId: string | undefined) {
    await this.eStoreService.redirectToGWHInvoicePage(transactionId);
  }

  openDatepicker() {
    this.pickerDirective.open();
  }

  choosedDate($event: any) {
    if ($event.startDate && $event.endDate) {
      this.startDate = $event.startDate;
      this.endDate = $event.endDate;
      this.getDownloads('', $event);
    }

  }
}
