import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { HomeComponent } from "./home/home.component";
import { CoreModule } from '../core/core.module';
import { MainMapComponent } from './home/main-map/main-map.component';
import { MaterialModule } from '../core/material.module';
import { PrimeNGModule } from '../core/primeng.module';
import { HoodqMapComponent } from './home/hoodq-map/hoodq-map.component';
import { PropertyReportComponent } from './home/property-report/property-report.component';
import { DemographicsComponent } from './home/property-report/demographics/demographics.component';
import { ResidentialRelocationComponent } from './home/property-report/residential-relocation/residential-relocation.component';
import { PlansSurveysComponent } from './home/property-report/plans-surveys/plans-surveys.component';
import { ValuationSalesComponent } from './home/property-report/valuation-sales/valuation-sales.component';
import { SiteStructureComponent } from './home/property-report/site-structure/site-structure.component';
import { HoodqComponent } from './home/property-report/hoodq/hoodq.component';
import { HighlightComponent } from './home/property-report/highlight/highlight.component';
import { MainMapControlsComponent } from './home/main-map-controls/main-map-controls.component';
import { LeftSidebarComponent } from './home/property-report/left-sidebar/left-sidebar.component';
import { RightSidebarComponent } from './home/property-report/right-sidebar/right-sidebar.component';
import { SummaryComponent } from './home/property-report/summary/summary.component';
import { RegistryComponent } from './home/property-report/registry/registry.component';
import { MainMapStreetviewComponent } from './home/main-map-streetview/main-map-streetview.component';
import { SearchComparablesFormComponent } from './home/search-comparables-form/search-comparables-form.component';
import { DemographicsPopulationComponent } from './home/property-report/demographics/demographics-population/demographics-population.component';
import { SearchComparablesResultComponent } from './home/search-comparables-result/search-comparables-result.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LayoutComponent } from './home/property-report/layout/layout.component';

// Import angular-fusioncharts
import { FusionChartsModule } from "angular-fusioncharts";

// Import FusionCharts library and chart modules
import * as FusionCharts from "fusioncharts";
import * as Charts from "fusioncharts/fusioncharts.charts";
import * as Widgets from "fusioncharts/fusioncharts.widgets";
import * as Zoom from "fusioncharts/fusioncharts.zoomline";
import * as FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { MaritalStatusComponent } from './home/property-report/demographics/demographics-population/marital-status/marital-status.component';
import { GenderStatusComponent } from './home/property-report/demographics/demographics-population/gender-status/gender-status.component';
import { AgeDistributionComponent } from './home/property-report/demographics/demographics-population/age-distribution/age-distribution.component';
import { FamilyStatusComponent } from './home/property-report/demographics/demographics-population/family-status/family-status.component';
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { DominantLanguageComponent } from './home/property-report/demographics/demographics-cultural/dominant-language/dominant-language.component';
import { ImmigrationStatusComponent } from './home/property-report/demographics/demographics-cultural/immigration-status/immigration-status.component';
import { DemographicsSocioEconomicComponent } from './home/property-report/demographics/demographics-socio-economic/demographics-socio-economic.component';
import { DemographicsCulturalComponent } from "./home/property-report/demographics/demographics-cultural/demographics-cultural.component";
import { EmploymentComponent } from './home/property-report/demographics/demographics-socio-economic/employment/employment.component';
import { EducationLevelComponent } from './home/property-report/demographics/demographics-socio-economic/education-level/education-level.component';
import { DemographicsHouseholdComponent } from './home/property-report/demographics/demographics-household/demographics-household.component';
import { DemographicsDominantMarketGroupComponent } from './home/property-report/demographics/demographics-dominant-market-group/demographics-dominant-market-group.component';
import { StructureDetailComponent } from './home/property-report/demographics/demographics-household/structure-detail/structure-detail.component';
import { OwnershipComponent } from './home/property-report/demographics/demographics-household/ownership/ownership.component';
import { HomeAgeComponent } from './home/property-report/demographics/demographics-household/home-age/home-age.component';
import { StructuralTypeComponent } from './home/property-report/demographics/demographics-household/structural-type/structural-type.component';
import { MaritalStatusItemComponent } from './home/property-report/demographics/demographics-population/marital-status/marital-status-item/marital-status-item.component';
import { CarouselModule } from "primeng/carousel";
import { TagModule } from "primeng/tag";
import { ButtonModule } from "primeng/button";
import { SiteStructureAssessmentNumberComponent } from './home/property-report/site-structure/site-structure-assesment-number/site-structure-assessment-number.component';
import { SiteStructureSiteComponent } from './home/property-report/site-structure/site-structure-site/site-structure-site.component';
import { SiteStructureCommercialComponent } from './home/property-report/site-structure/site-structure-commercial/site-structure-commercial.component';
import { SiteStructureStructureInfoComponent } from './home/property-report/site-structure/site-structure-structure-info/site-structure-structure-info.component';
import { SiteStructureIndustrialComponent } from './home/property-report/site-structure/site-structure-industrial/site-structure-industrial.component';
import { SiteStructureResidentialComponent } from './home/property-report/site-structure/site-structure-residential/site-structure-residential.component';
import { SiteStructureValuationSalesComponent } from './home/property-report/site-structure/site-structure-valuation-sales/site-structure-valuation-sales.component';
import { SiteStructureAssessmentPropertyComponent } from './home/property-report/site-structure/site-structure-assessment-property/site-structure-assessment-property.component';
import { SiteStructureEnhancedDataComponent } from './home/property-report/site-structure/site-structure-enhanced-data/site-structure-enhanced-data.component';
import { SiteStructureFourYearPhasedInComponent } from './home/property-report/site-structure/site-structure-four-year-phased-in/site-structure-four-year-phased-in.component';
import { SiteStructurePreviousAssessmentComponent } from './home/property-report/site-structure/site-structure-previous-assessment/site-structure-previous-assessment.component';
import { SiteStructureStructureComponent } from './home/property-report/site-structure/site-structure-structure/site-structure-structure.component';
import { SiteStructureAssessedValueComponent } from './home/property-report/site-structure/site-structure-assessed-value/site-structure-assessed-value.component';
import { SiteStructureAssessmentDetailsComponent } from './home/property-report/site-structure/site-structure-assessment-details/site-structure-assessment-details.component';
import { SiteStructureResidentialPropertyTaxComponent } from './home/property-report/site-structure/site-structure-residential-property-tax/site-structure-residential-property-tax.component';
import { SiteStructureMpacSubscriptionEnhancedDataComponent } from './home/property-report/site-structure/site-structure-mpac-subscription-enhanced-data/site-structure-mpac-subscription-enhanced-data.component';
import { SiteStructureMpacSubscriptionAssessmentPropertyComponent } from './home/property-report/site-structure/site-structure-mpac-subscription-assessment-property/site-structure-mpac-subscription-assessment-property.component';
import { SiteStructureCondoInfoComponent } from './home/property-report/site-structure/site-structure-condo-info/site-structure-condo-info.component';
import { TooltipModule } from "primeng/tooltip";
import { TooltipModule as NG2TooltipModule } from 'ng2-tooltip-directive';
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { GeoAddressMapOverlayComponent } from './home/geo-address-map-overlay/geo-address-map-overlay.component';
import { SearchResultsContainerComponent } from './home/search-results/search-results-container/search-results-container.component';
import { OmnibarMainSearchResultsComponent } from './home/omnibar-main-search-results/omnibar-main-search-results.component';
import { OmnibarOwnerSearchResultsComponent } from './home/omnibar-owner-search-results/omnibar-owner-search-results.component';
import { OmnibarCondoSearchResultsComponent } from './home/omnibar-condo-search-results/omnibar-condo-search-results.component';
import { SearchComparablesResultPriceChartComponent } from './home/search-comparables-result-price-chart/search-comparables-result-price-chart.component';
import { MainSearchResultsComponent } from './home/search-results/main-search-results/main-search-results.component';
import { CondoSearchResultsComponent } from './home/search-results/condo-search-results/condo-search-results.component';
import { OwnerSearchResultsComponent } from './home/search-results/owner-search-results/owner-search-results.component';
import { DropdownModule } from "primeng/dropdown";
import { BaseSearchResults } from "./home/search-results/base-search-results";
import { SortSearchResultsComponent } from './home/search-results/sort-search-results/sort-search-results.component';
import { InstrumentSearchResultsComponent } from './home/search-results/instrument-search-results/instrument-search-results.component';
import { PiiSearchResultsComponent } from "./home/search-results/pii-search-results/pii-search-results.component";
import { BasePropertyEventEmitter } from "./home/search-results/base-property-event-emitter";
import { OwnerToPropertyMapSearchResultsComponent } from "./home/search-results/owner-to-property-map-search-results/owner-to-property-map-search-results.component";
import { SvgIconComponent } from "../core/component/svg-icon/svg-icon.component";
import { ScrollToTopComponent } from '../core/component/top-scroller/top-scroller.component';
import {MapPlansSurveyComponent} from "./home/property-report/plans-surveys/map-plans-survey/map-plans-survey.component";

// @ts-ignore
FusionCharts.options['license']({
  key: 'BzA11vmaG5E1E3A9B4C3C1C2D1D1B2F1D2fqyC3E4B-9xvhyC6D2lduH5B1D3C4B3A3D2B5A1B2E4I1A3A5A1B3f1C-9yI2J3A4yE2B2E2lozG1C3C8df1wC4B3I4dC-22A-16B5B2D3G1A4D4I1C11A4B2C2sH-9uD1H4C1B1A-13D-11lB4H3A33epB9c2D5B4lnC-7B2A5A7A1C7A5D5F1H4J3A2C10A5E2g==',
  creditLabel: false,
});


// Pass the fusioncharts library and chart modules
FusionChartsModule.fcRoot(FusionCharts, Charts, Widgets, Zoom, FusionTheme);


@NgModule({
  declarations: [
    HomeComponent,
    MainMapComponent,
    PropertyReportComponent,
    DemographicsComponent,
    PlansSurveysComponent,
    ValuationSalesComponent,
    SiteStructureComponent,
    HighlightComponent,
    MainMapControlsComponent,
    SummaryComponent,
    MainMapStreetviewComponent,
    SearchComparablesFormComponent,
    DominantLanguageComponent,
    ImmigrationStatusComponent,
    DemographicsCulturalComponent,
    DemographicsSocioEconomicComponent,
    EmploymentComponent,
    EducationLevelComponent,
    DemographicsHouseholdComponent,
    DemographicsDominantMarketGroupComponent,
    SiteStructureAssessmentNumberComponent,
    SearchComparablesResultComponent,
    SiteStructureSiteComponent,
    SiteStructureCommercialComponent,
    SiteStructureStructureInfoComponent,
    SiteStructureIndustrialComponent,
    SiteStructureResidentialComponent,
    SiteStructureValuationSalesComponent,
    SiteStructureAssessmentPropertyComponent,
    SiteStructureEnhancedDataComponent,
    SiteStructureFourYearPhasedInComponent,
    SiteStructurePreviousAssessmentComponent,
    SiteStructureStructureComponent,
    SiteStructureAssessedValueComponent,
    SiteStructureAssessmentDetailsComponent,
    SiteStructureResidentialPropertyTaxComponent,
    SiteStructureMpacSubscriptionEnhancedDataComponent,
    SiteStructureMpacSubscriptionAssessmentPropertyComponent,
    SiteStructureCondoInfoComponent,
    GeoAddressMapOverlayComponent,
    SearchResultsContainerComponent,
    OmnibarMainSearchResultsComponent,
    OmnibarOwnerSearchResultsComponent,
    OmnibarCondoSearchResultsComponent,
    SearchComparablesResultPriceChartComponent,
    MainSearchResultsComponent,
    CondoSearchResultsComponent,
    OwnerSearchResultsComponent,
    BaseSearchResults,
    SortSearchResultsComponent,
    InstrumentSearchResultsComponent,
    BasePropertyEventEmitter
  ],
  imports: [
    CommonModule,
    CoreModule,
    MaterialModule,
    PrimeNGModule,
    ReactiveFormsModule,
    FusionChartsModule,
    MatButtonToggleModule,
    CarouselModule,
    TagModule,
    ButtonModule,
    TooltipModule,
    NG2TooltipModule,
    MatSortModule,
    MatPaginatorModule,
    DropdownModule,
    PiiSearchResultsComponent,
    OwnerToPropertyMapSearchResultsComponent,
    SvgIconComponent,
    GenderStatusComponent,
    MaritalStatusItemComponent,
    DemographicsPopulationComponent,
    MaritalStatusComponent,
    AgeDistributionComponent,
    FamilyStatusComponent,
    RegistryComponent,
    LeftSidebarComponent,
    RightSidebarComponent,
    LayoutComponent,
    HoodqComponent,
    HoodqMapComponent,
    ResidentialRelocationComponent,
    StructureDetailComponent,
    HomeAgeComponent,
    OwnershipComponent,
    StructuralTypeComponent,
    ScrollToTopComponent,
    MapPlansSurveyComponent
  ],
  exports: [
    HomeComponent
  ],
  providers: [DecimalPipe]
})
export class HomeModule {
}
