import { AfterViewInit, Component, ElementRef, EventEmitter, inject, OnInit, output, Output, signal, ViewChild, WritableSignal } from '@angular/core';
import { MunicipalityService } from '../../../shared/service/municipality.service';
import { LroPolygonsService } from '../../../shared/service/lro-polygons.service';
import { SearchComparablesFormService } from '../../../shared/service/search/search-comparables-form.service';
import { Municipality } from '../../../core/model/property/municipality';
import { lastValueFrom, skip, takeUntil } from 'rxjs';
import { MainMapService } from '../main-map/main-map.service';
import { UserService } from '../../../shared/service/user.service';
import { UserAccessControl } from '../../../core/model/user/user-access-control';
import { SearchComparablesFormBase } from './search-comparables-form-base';
import { ComparablesSearchService } from '../../../shared/service/search/comparables-search.service';
import { ComparableSalesRequest } from '../../../core/model/comparables/comparable-sales-request';
import { Centroid } from '../../../core/model/spatial/centroid';
import { DataService } from '../../../shared/service/data.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DistanceWidget } from "../search-comparables-form/google-maps-radius-widget";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { CurrencyPipe, DatePipe } from "@angular/common";
import * as _ from 'lodash';
import dayjs from "dayjs";
import { SearchComparablesResultService } from '../../../shared/service/search/search-comparables-result.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {comparablesLotSizeValues, defaultErrorMatSnackBarConfig} from "../../../shared/constant/constants";
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogData } from "../../../core/component/modal/confirm-dialog/confirm-dialog-data";
import { ConfirmDialogComponent } from "../../../core/component/modal/confirm-dialog/confirm-dialog.component";
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { PropertyCode } from '../../../core/model/mpac/property-code';
import { ComparableSalesResultPayload } from '../../../core/model/comparables/comparable-sales-result-payload';
import { SearchComparablesEnum } from '../../../core/enum/search-comparables.enum';
import { SearchBusyIndicatorService } from '../../../shared/service/search/ui/search-busy-indicator.service';
import { LoggerService } from '../../../shared/service/log/logger.service';
import { User } from '../../../core/model/user/user';
import { ScreenManager } from '../../../shared/service/screen-manager.service';
import { ScreenNameEnum } from '../../../core/enum/screen-name.enum';
import { ComparableSearchCriteria } from '../../../core/model/user/preference/comparable-search-criteria';
import { EnumUtility } from '../../../shared/utility/enum.utility';
import { MeasurementUnitService } from "../../../shared/service/measurement-unit.service";
import { StringUtility } from '../../../shared/utility/string-utility';
import { GoogleAnalyticsService } from "../../../shared/service/google-analytics.service";
import { SpatialSearchService } from '../../../shared/service/search/spatial-search.service';
import { Pii } from '../../../core/model/property/pii';
import { SearchComparablesCriteriaCrumb } from '../../../core/model/comparables/search-comparables-criteria-crumb';
import { Accordion, AccordionTab } from 'primeng/accordion';
import { NumberUtility } from '../../../shared/utility/number.utility';
import { faArrowDown, faArrowDownWideShort, faArrowUp, faArrowUpWideShort, faCircleInfo, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FreeholdOrCondoSearchFocusState } from './freehold-or-condo-search-focus-state';
import { ComparableSalesShapeBounds } from '../../../core/model/comparables/comparable-sales-shape-bounds';
import { ErrorUtil } from "../../../shared/service/error.util";
import { HttpErrorResponse } from "@angular/common/http";
import { SearchComparablesFormWrapper } from '../../../core/model/comparables/search-comparables-form-wrapper';
import { SearchComparablesRadiusOption } from '../../../core/model/comparables/search-comparables-radius-option';
import { MatSliderDragEvent } from "@angular/material/slider";
import { SearchComparablesResultSnapshot } from '../../../core/model/search-result/comparables-result-snapshot';
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { ComparableSalesShape } from '../../../core/model/comparables/comparable-sales-shape';
import { PIIService } from '../../../shared/service/pii.service';
import { OmnibarSearchService } from '../../../shared/service/search/omnibar-search.service';
import { SearchResult } from '../../../core/model/search-result/search-result';
import { SliderParam } from "./slider-param";
import { DialogReturnTypeEnum } from "../../../core/enum/dialog-return-type-enum";

export const SALE_DATE_FORMATS = {
  parse: {
    dateInput: "YYYY/MM/DD"
  },
  display: {
    dateInput: "YYYY/MM/DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "YYYY/MM/DD",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

type PREFERENCE_KEY = 'search-area' | 'sale-period-method' | 'sale-period' | 'property-type' | 'minimum-price-amount' | 'maximum-price-amount' | 'minimum-lot-area' | 'maximum-lot-area';
declare var moment: any;

@Component({
  selector: 'gema3g-search-comparables-form',
  templateUrl: './search-comparables-form.component.html',
  styleUrls: ['./search-comparables-form.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {provide: MAT_DATE_FORMATS, useValue: SALE_DATE_FORMATS},
    DatePipe
  ]
})
export class SearchComparablesFormComponent extends SearchComparablesFormBase implements OnInit, AfterViewInit {
  constructor() {
    super();
  }

  private searchComparablesFormService = inject(SearchComparablesFormService);
  private lroPolygonsService = inject(LroPolygonsService);
  private municipalityService = inject(MunicipalityService);
  private mainMapService = inject(MainMapService);
  private userService = inject(UserService);
  private comparablesSearchService = inject(ComparablesSearchService);
  private searchComparablesResultService = inject(SearchComparablesResultService);
  private omnibarSearchService = inject(OmnibarSearchService);
  private formBuilder = inject(FormBuilder);
  private _snackBar = inject(MatSnackBar);
  private dialog = inject(MatDialog);
  private currencyPipe = inject(CurrencyPipe);
  private searchBusyIndicatorService = inject(SearchBusyIndicatorService);
  private loggerService = inject(LoggerService);
  private screenManager = inject(ScreenManager);
  private dataService = inject(DataService);
  private measurementUnitService = inject(MeasurementUnitService);
  private gaService = inject(GoogleAnalyticsService);
  private spatialServiceService = inject(SpatialSearchService);
  private piiService = inject(PIIService);

  @ViewChild('drawPolygonBtn', {static: false}) drawPolygonButtonElement: ElementRef;
  @ViewChild('radiusSelect') radiusSelectElementRef: ElementRef;
  @ViewChild('municipalitiesList') municipalitiesElement: MatSelectionList;
  @ViewChild('selectedMunicipalitiesList') selectedMunicipalitiesElement: MatSelectionList;
  @ViewChild('propertyCodesList') propertyCodesElement: MatSelectionList;
  @ViewChild('selectedPropertyCodesList') selectedPropertyCodesElement: MatSelectionList;
  @ViewChild('accordion') accordion: Accordion;
  @ViewChild('searchByTab') searchByTab: AccordionTab;
  @ViewChild('filterByTab') filterByTab: AccordionTab;
  @ViewChild('minMaxAmountSlider') minMaxAmountSlider: ElementRef;
  visible = output<boolean>({alias: 'formVisible'});

  DS = DataService;
  user: User = this.userService.user;
  form: FormGroup;
  submitted = false;
  formVisible: boolean = false;
  userAccessControls: UserAccessControl;
  fromLotSize: any;
  toLotSize: any;
  municipalities: Municipality[];
  selectedMunicipalities: Municipality[] = [];
  totalMunicipalitiesCount: number;
  propertyCodes: PropertyCode[];
  selectedPropertyCodes: PropertyCode[] = [];
  totalPropertyCodesCount: number;
  maximumSearchPolygonsAllowed: number;
  customRadius: SearchComparablesRadiusOption;
  selectedSearchBy: SearchComparablesEnum = this.searchByTypes[0];
  selectedSalePeriodMethod: string = 'preset';
  selectedFromSaleDate: string;
  selectedToSaleDate: string;
  selectedPropertyType?: string = 'ALL';  //non-mps property type
  selectedPropertyTypeMPS: number = this.propertyTypesMPS[0].code;  //mps property type
  selectedSalePeriod?: string = '6M';
  saleDateFormFormat = 'YYYY/MM/DD';
  saleDateTimeFormFormat = 'YYYY/MM/DD HH:mm';
  maxSaleDate = new Date();
  numberFormatRegex = '^([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)?$';
  numberFormatRegexExp = new RegExp(this.numberFormatRegex);
  customValidators: any[];
  sameCondoSearch: boolean = false;
  sameCondoSearchOption: boolean = false;
  sameCondoSearchAddress: string = 'Search comparable properties only in';
  sameCondoSearchAddressTooltipOptions = {
    showDelay: 150,
    autoHide: false,
    tooltipEvent: 'hover',
    tooltipPosition: 'left'
  };
  priceInput: FormControl;
  circularSearchBufferReady: boolean = false;
  findComparablesTooltipText: string = DataService.SEARCH_COMPARABLES_FIND_TOOLTIP_TEXT;
  propertyCodeTooltipText: string = DataService.SEARCH_COMPARABLES_PROPERTY_CODE_TOOLTIP_TEXT;
  sameCondoTooltipText: string = '';
  freeholdTooltipText: string = '';

  priceAmountSlider: SliderParam = new SliderParam(10000);
  assessmentAmountSlider: SliderParam = new SliderParam(100000);

  distanceWidget: any;
  activeTabIndex: number;

  faCircleXmark = faCircleXmark;
  faCircleInfo = faCircleInfo;
  faArrowUp = faArrowUp;
  faArrowDown = faArrowDown;
  faArrowUpWideShort = faArrowUpWideShort;
  faArrowDownWideShort = faArrowDownWideShort;

  onSearchByChange = (event: any) => {
    this.selectedSearchBy = event.value;

    setTimeout(() => {
      this.mainMapService.closeAllMarkerBubbles();
    }, 100);

    switch (this.selectedSearchBy) {
      case this.SEARCH_BY_RADIUS:
        this.mainMapService.cancelSearchComparablesPolygonDrawing();

        if (this.isMpsUser()) {
          this.removeAllSelectedMunicipalities();
          this.unselectAllMunicipalities();
        }

        setTimeout(() => {
          this.mainMapService.clearSearchComparablesMunicipalityObjects();
          this.resetCircularSearchAreaDisplay(false);
        }, 100);

        break;

      case this.SEARCH_BY_POLYGON:
        setTimeout(() => {
          this.drawUserPolygon();
        }, 100);

        break;

      case this.SEARCH_BY_MUNICIPALITY:
        this.sameCondoSearchOption = false;
        this.sameCondoSearch = false;
        this.mainMapService.cancelSearchComparablesPolygonDrawing();

        setTimeout(() => {
          this.destroyCircularSearchBuffer();
          this.mainMapService.clearSearchComparablesCircleObjects();
          this.mainMapService.clearSearchComparablesPolygonObjects();
        }, 100);
    }
  }

  onRadiusSelected = (event: any, clearMap: boolean) => {

    if (clearMap) this.mainMapService.clearAllRenderedMapObjects();

    if (this.sameCondoSearchOption) {
      this.sameCondoSearch = false; //since "same condo" and radius are mutually exclusive
    }

    setTimeout(async () => {

      await this.createCircularSearchBuffer();

      if (this.isCustomRadiusSelected()) {
        this.centerSearchCircleOnMap();
      } else {
        //the map needs to be on the same zoom level that is configured for the current radius setting when the radius is changed
        this.mainMapService.setMapZoomLevel(event.value.zoomLevel);
        setTimeout(() => {
          this.mainMapService.panMapToCenter(this.distanceWidget.getCircleCenter());
        }, 100);
      }

      //render the polygon of the property in the center of circular search area
      let center: Centroid = this.getCircularSearchBufferCenter();
      let pii: Pii = await lastValueFrom(this.spatialServiceService.getPIIByLatLng(center.latitude, center.longitude), {defaultValue: new Pii()});
      this.loggerService.logDebug(`draw polygon of the property at lat[${center.latitude}] lng[${center.longitude}]`);

      if (pii) {
        this.renderPIIPolygon(pii);
      }
    }, 100);
  }

  preDrawUserPolygon = () => {
    this.sameCondoSearchOption = false;
    this.sameCondoSearch = false;
    this.destroyCircularSearchBuffer();
    this.mainMapService.clearSearchComparablesCircleObjects();
    this.mainMapService.clearSearchComparablesMunicipalityObjects();
    this.mainMapService.closeSearchComparablesStartPolygonInstructions();
    this.mainMapService.closeSearchComparablesInProgressPolygonInstructions();

    if (this.isMpsUser()) {
      this.removeAllSelectedMunicipalities();
      this.unselectAllMunicipalities();
    }
  }

  getConvertedRadius = function (radiusItem: any) {
    // as per notes in 2g, temporarily removed metric/imperial conversion from comps search as per LOB request
    radiusItem.value = radiusItem.metricValue;
    return radiusItem.value;
  }

  centerSearchCircleOnMap = () => {
    let circle: google.maps.Circle = this.distanceWidget.getCircle();
    if (circle) {
      let bounds: google.maps.LatLngBounds | null = circle.getBounds();
      if (bounds) {
        this.mainMapService.fitBounds(bounds, false);
        setTimeout(() => {
          this.mainMapService.panMapToCenter(this.distanceWidget.getCircleCenter());
        }, 100);
      }
    }
  }

  focusMunicipalities = () => {
    this.selectedSearchBy = this.SEARCH_BY_MUNICIPALITY;
    this.destroyCircularSearchBuffer();
    this.mainMapService.cancelSearchComparablesPolygonDrawing();
    this.mainMapService.clearSearchComparablesCircleObjects();
    this.mainMapService.clearSearchComparablesPolygonObjects();
  }

  populateLroMunicipalities = async (lro: string | null) => {
    if (lro) {
      this.municipalities = await lastValueFrom(this.municipalityService.getMunicipalitiesByLro(lro));
      this.totalMunicipalitiesCount = (this.municipalities)? this.municipalities.length : 0;
      this.loggerService.logDebug(`${this.totalMunicipalitiesCount} municipalities in lro ${lro} retrieved`, this.municipalities);
    }
  }

  get municipalitiesCount() {
    return this.totalMunicipalitiesCount;
  }

  get selectedMunicipalitiesCount() {
    return this.selectedMunicipalities?.length;
  }

  populateLroPropertyCodes = async (lro: string | null) => {
    if (lro) {

      let response: any = await lastValueFrom(this.comparablesSearchService.getPropertyCodesByLro(lro));

      //property codes are actually the same for all lros; unfortunately it's bundled in the same api as getting the municipalities by lro
      this.propertyCodes = response.propertyTypes;
      this.dataService.propertyCodes = this.propertyCodes;
      this.totalPropertyCodesCount = this.propertyCodes.length;

      this.loggerService.logDebug(`${this.totalPropertyCodesCount} property types retrieved`, this.propertyCodes);
    }
  }

  get propertyCodesCount() {
    return this.totalPropertyCodesCount;
  }

  get selectedPropertyCodesCount() {
    return this.selectedPropertyCodes?.length;
  }

  onMunicipalitiesListSelectionChange(event: MatSelectionListChange) {
  }

  sortAndRenderMunicipalities = (municipalities: Municipality[]) => {
    municipalities?.sort(this.compareMunicipality);
    this.displaySelectedMunicipalityPolygons();
  }

  addMunicipality = () => {
    this.municipalitiesElement.selectedOptions.selected.forEach(municipality => {
      this.selectedMunicipalities.push(municipality.value);

      this.municipalities.forEach((item, index) => {
        if (item.munId === municipality.value.munId) this.municipalities.splice(index, 1);
      });
    })

    setTimeout(() => {
      this.sortAndRenderMunicipalities(this.selectedMunicipalities);
    }, 100);
  }

  addAllMunicipalities = () => {
    this.mainMapService.cancelSearchComparablesPolygonDrawing();

    this.municipalities.forEach((municipality, index) => {
      this.selectedMunicipalities.push(municipality);
    })
    this.municipalities = [];

    setTimeout(() => {
      this.sortAndRenderMunicipalities(this.selectedMunicipalities);
    }, 100);
  }

  removeSelectedMunicipality = () => {
    this.selectedMunicipalitiesElement.selectedOptions.selected.forEach(municipality => {
      this.municipalities.push(municipality.value);

      this.selectedMunicipalities.forEach((item, index) => {
        if (item.munId === municipality.value.munId) this.selectedMunicipalities.splice(index, 1);
      });
    })

    setTimeout(() => {
      this.sortAndRenderMunicipalities(this.municipalities);
    }, 100);
  }

  removeAllSelectedMunicipalities = () => {
    this.selectedMunicipalities.forEach((municipality, index) => {
      this.municipalities.push(municipality);
    })
    this.selectedMunicipalities = [];

    setTimeout(() => {
      this.sortAndRenderMunicipalities(this.municipalities);
    }, 100);
  }

  unselectAllMunicipalities = () => {
    this.municipalitiesElement.selectedOptions.selected.forEach(municipality => {
      municipality.selected = false;
    })
  }

  compareMunicipality(a: Municipality, b: Municipality) {
    if (a.municipality < b.municipality) {
      return -1;
    }
    if (a.municipality > b.municipality) {
      return 1;
    }
    return 0;
  }

  displaySelectedMunicipalityPolygons = () => {
    if (this.selectedSearchBy == this.SEARCH_BY_MUNICIPALITY) {
      let municipalityIds: string[] = [];
      this.selectedMunicipalities.forEach(municipality => {
        municipalityIds.push(municipality.munId);
      })
      this.mainMapService.renderMunicipalityPolygon(municipalityIds);
    }
  }

  addPropertyCode = () => {
    this.propertyCodesElement.selectedOptions.selected.forEach(propertyCode => {
      this.selectedPropertyCodes.push(propertyCode.value);

      this.propertyCodes.forEach((item, index) => {
        if (item.code === propertyCode.value.code) this.propertyCodes.splice(index, 1);
      });
    })

    this.selectedPropertyCodes?.sort(this.comparePropertyCode);
  }

  addAllPropertyCodes = () => {
    this.propertyCodes.forEach((propertyCode, index) => {
      this.selectedPropertyCodes.push(propertyCode);
    })

    this.selectedPropertyCodes?.sort(this.comparePropertyCode);
    this.propertyCodes = [];
  }

  removeSelectedPropertyCode = () => {
    this.selectedPropertyCodesElement.selectedOptions.selected.forEach(propertyCode => {
      this.propertyCodes.push(propertyCode.value);

      this.selectedPropertyCodes.forEach((item, index) => {
        if (item.code === propertyCode.value.code) this.selectedPropertyCodes.splice(index, 1);
      });
    })

    this.propertyCodes?.sort(this.comparePropertyCode);
  }

  removeAllSelectedPropertyCodes = () => {
    this.selectedPropertyCodes.forEach((propertyCode, index) => {
      this.propertyCodes.push(propertyCode);
    })

    this.propertyCodes?.sort(this.comparePropertyCode);
    this.selectedPropertyCodes = [];
  }

  unselectAllPropertyCodes = () => {
    this.propertyCodesElement.selectedOptions.selected.forEach(propertyCode => {
      propertyCode.selected = false;
    })
  }

  comparePropertyCode(a: PropertyCode, b: PropertyCode) {
    if (a.code < b.code) {
      return -1;
    }
    if (a.code > b.code) {
      return 1;
    }
    return 0;
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.form?.controls;
  }

  displayFieldValueSelection = (selection: string): string => {
    let selectionString = selection?.toString();
    let displayValue: string = selectionString;

    if (selectionString == this.INFINITY + '') {
      displayValue = this.INFINITY_VALUE_DISPLAY;
    } else if (selectionString == this.BUILD_AREA_INFINITY + '') {
      displayValue = this.BUILD_AREA_INFINITY_VALUE_DISPLAY;
    } else {
      displayValue = displayValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return displayValue;
  }

  private updateSliderValues = () => {
    ['minAmount', 'maxAmount', 'minAssessmentAmount','maxAssessmentAmount'].forEach(value => {
      this.updateSliderValue(value, this.formControls[value].getRawValue());
    })
  }

  private updateSliderValue = (controlName: string, value: string) => {
    switch (controlName) {

      case 'minAmount':
        this.priceAmountSlider.thumbStartValue.set(StringUtility.safeNumber(value));
        this.priceAmountSlider.startValue = this.priceAmountSlider.thumbStartValue();
        break;

      case 'maxAmount':
        if (value == this.maxPriceRange[this.maxPriceRange.length - 1].value || value == this.maxPriceRange[this.maxPriceRange.length - 1].id.toString()) {
          this.priceAmountSlider.thumbEndValue.set(this.INFINITY_SLIDER);
        } else {
          this.priceAmountSlider.thumbEndValue.set(StringUtility.safeNumber(value));
        }

        this.priceAmountSlider.endValue = this.priceAmountSlider.thumbEndValue();
        break;

      case 'minAssessmentAmount':
        this.assessmentAmountSlider.thumbStartValue.set(StringUtility.safeNumber(value));
        this.assessmentAmountSlider.startValue = this.assessmentAmountSlider.thumbStartValue();
        break;

      case 'maxAssessmentAmount':
        if (value == this.maxAssessmentRange[this.maxAssessmentRange.length - 1].value || value == this.maxAssessmentRange[this.maxAssessmentRange.length - 1].id.toString()) {
          this.assessmentAmountSlider.thumbEndValue.set(this.INFINITY_SLIDER);
        } else {
          this.assessmentAmountSlider.thumbEndValue.set(StringUtility.safeNumber(value));
        }

        this.assessmentAmountSlider.endValue = this.assessmentAmountSlider.thumbEndValue();
        break;
    }
  }

  onBlurInput = (event: any) => {
    let value: string = _.trim(event.currentTarget.value);
    let controlName: string = event.currentTarget.id;

    switch (controlName) {
      case 'minDate':
        if (value == '') this.formControls['minDate'].setValue(new Date());
        break;

      case 'maxDate':
        if (value == '') this.formControls['maxDate'].setValue(new Date());
        break;

      case 'minBuildTotalArea':
        if (value == '') this.formControls['minBuildTotalArea'].setValue(this.totalBuildAreas[0].value);
        break;

      case 'maxBuildTotalArea':
        if (value == '') this.formControls['maxBuildTotalArea'].setValue(this.totalBuildAreas[this.totalBuildAreas.length - 1].value);
        break;

      case 'minAmount':
        if (value == '') this.formControls[controlName].setValue(this.minPriceRange[0].id);
        this.updateSliderValue(controlName, value.trim());
        break;

      case 'maxAmount':
        if (value == '') this.formControls[controlName].setValue(this.maxPriceRange[this.maxPriceRange.length - 1].id);
        this.updateSliderValue(controlName, value.trim());
        break;

      case 'minAssessmentAmount':
        if (value == '') this.formControls['minAssessmentAmount'].setValue(this.minAssessmentRange[0].id);
        this.updateSliderValue(controlName, value.trim());
        break;

      case 'maxAssessmentAmount':
        if (value == '') this.formControls['maxAssessmentAmount'].setValue(this.maxAssessmentRange[this.maxAssessmentRange.length - 1].id);
        this.updateSliderValue(controlName, value.trim());
        break;

      case 'minYearBuilt':
        if (value == '') this.formControls['minYearBuilt'].setValue(this.yearBuiltFrom[this.yearBuiltFrom.length - 1]);
        break;

      case 'maxYearBuilt':
        if (value == '') this.formControls['maxYearBuilt'].setValue(this.yearBuiltTo[0]);
        break;
    }
  }

  customFieldRangeValidator = (minControlName: string, maxControlName: string): ValidatorFn | ValidationErrors => {

    return (group: FormGroup): ValidationErrors | null => {
      const minControl = group.controls[minControlName];
      const maxControl = group.controls[maxControlName];

      minControl.setErrors(null);
      maxControl.setErrors(null);

      if (minControlName != 'minDate' && maxControlName != 'maxDate') {
        if (minControl.value != this.INFINITY_VALUE_DISPLAY && minControl.value != this.LESS_THAN_1901) {
          let validFormat: boolean = this.numberFormatRegexExp.test(minControl.value);
          if (!validFormat) {
            minControl.setErrors({invalidNumberFormat: true});
          }
        }

        if (maxControl.value != this.INFINITY_VALUE_DISPLAY) {
          let validFormat = this.numberFormatRegexExp.test(maxControl.value);
          if (!validFormat) {
            maxControl.setErrors({invalidNumberFormat: true});
          }
        }

      } else {
        if (minControlName == 'minDate') {
          if (!dayjs(minControl?.value, this.saleDateFormFormat, true).isValid()) {
            minControl.setErrors({invalidDate: true});
          }
        }
        if (maxControlName == 'maxDate') {
          if (!dayjs(maxControl?.value, this.saleDateFormFormat, true).isValid()) {
            maxControl.setErrors({invalidDate: true});
          }
        }
      }

      if (minControlName != 'minDate' && maxControlName != 'maxDate') {
        if (minControl.errors == null && maxControl.errors == null) {
          const minValue = this.toNumber(minControl?.value);
          const maxValue = this.toNumber(maxControl?.value);
          if (minValue > this.INFINITY) {
            minControl.setErrors({exceededMaximumValue: true});
          } else if (maxValue > this.INFINITY) {
            maxControl.setErrors({exceededMaximumValue: true});
          } else {
            if (minControlName == 'minYearBuilt' && minValue > this.thisYear) {
              minControl.setErrors({exceededMaximumYear: true});
            } else if (maxControlName == 'maxYearBuilt' && maxValue > this.thisYear) {
              maxControl.setErrors({exceededMaximumYear: true});
            } else if (minValue > maxValue) {
              minControl.setErrors({invalidValueRange: true});
              maxControl.setErrors({invalidValueRange: true});
            } else {
              minControl.setErrors(null);
              maxControl.setErrors(null);
            }
          }
        }
      } else {
        if (dayjs(minControl?.value, this.saleDateFormFormat, true).isAfter(dayjs(maxControl?.value, this.saleDateFormFormat, true))) {
          minControl.setErrors({invalidValueRange: true});
          maxControl.setErrors({invalidValueRange: true});
        }
      }

      return null;
    };
  }

  buildAreaFieldRangeValidator = (minControlName: string, maxControlName: string): ValidatorFn | ValidationErrors => {

    return (group: FormGroup): ValidationErrors | null => {
      const minControl = group.controls[minControlName];
      const maxControl = group.controls[maxControlName];

      minControl.setErrors(null);
      maxControl.setErrors(null);

      let validFormat: boolean = this.numberFormatRegexExp.test(minControl.value);
      if (!validFormat) {
        minControl.setErrors({invalidNumberFormat: true});
      }

      if (maxControl.value != this.BUILD_AREA_INFINITY_VALUE_DISPLAY) {
        let validFormat = this.numberFormatRegexExp.test(maxControl.value);
        if (!validFormat) {
          maxControl.setErrors({invalidNumberFormat: true});
        }
      }

      if (minControl.errors == null && maxControl.errors == null) {
        const minValue = this.toNumber(minControl?.value);
        const maxValue = this.toNumber(maxControl?.value);
        if (minValue > this.BUILD_AREA_INFINITY) {
          minControl.setErrors({exceededMaximumValue: true});
        } else if (maxValue > this.BUILD_AREA_INFINITY) {
          maxControl.setErrors({exceededMaximumValue: true});
        } else {
          if (minValue > maxValue) {
            minControl.setErrors({invalidValueRange: true});
            maxControl.setErrors({invalidValueRange: true});
          } else {
            minControl.setErrors(null);
            maxControl.setErrors(null);
          }
        }
      }

      return null;
    };
  }

  toNumber = (value: any): number => {
    return Number(value?.toString().replace(/\D/g, ''));
  }

  getCircularSearchBufferCenter = () => {
    let currentCircleCentroid: any = this.distanceWidget.getCircleCenter();
    let circleCenter: Centroid = new Centroid(currentCircleCentroid.lat(), currentCircleCentroid.lng());
    return circleCenter;
  }

  async onSubmitForm() {
    //prepare and validate the request
    let formData = new ComparableSalesRequest();
    let goodToSubmit: boolean = false;

    // for companies that have a license PackageId check that the counter is not 0
    if (!this.comparablesSearchService.checkAvailableLicense()){
      return;
    }

    if (this.selectedSearchBy == this.SEARCH_BY_POLYGON && this.mainMapService?.getRenderedPolygonsCount() == 0) {
      //this.postSubmitForm();
      this.openSnackBarError(DataService.INCOMPLETE_POLYGON);
      return;
    }

    try {
      this.preSubmitForm();

      if (this.selectedSearchBy == this.SEARCH_BY_RADIUS && !this.sameCondoSearch && !this.circularSearchBufferReady) {
        this.postSubmitForm();
        this.openSnackBarError(DataService.SEARCH_COMPARABLES_CIRCULAR_SEARCH_BUFFER_NOT_READY);
        return;
      }

      if (this.form.invalid) {
        this.postSubmitForm();
        this.openSnackBarError(ErrorUtil.FORM_ERRORS_FOUND);
        return;
      }

      if (this.selectedSearchBy == this.SEARCH_BY_MUNICIPALITY && this.selectedMunicipalities.length == 0) {
        this.postSubmitForm();
        this.openSnackBarError(DataService.NO_SEARCH_MUNICIPALITIES_SELECTED);
        return;
      }

      this.searchBusyIndicatorService.showMainMapBusyIndicator();

      formData.mps = this.isMpsUser();
      formData.lro = _.toNumber(this.lroPolygonsService.getCurrentLro());
      formData.saveAsDefault = true;

      //set the default non-mps freehold and condo form atttributes for radius and polygon search
      if (!formData.mps) {
        //note: the value case is deliberate as those values are expected by the server
        switch (this.form.get('propertyType')?.value) {
          case 'ALL':
            formData.freehold = true;
            formData.condo = true;
            break;
          case 'Freehold':
            formData.freehold = true;
            formData.condo = false;
            break;
          case 'condo':
            formData.freehold = false;
            formData.condo = true;
            break;
        }
      }

      //set the default mps freehold and condo form atttributes for radius and polygon search
      if (formData.mps) {
        formData.freehold = true;
        formData.condo = false;
      }

      if (this.selectedSearchBy == this.SEARCH_BY_RADIUS) {
        //overwrite the freehold and condo form attributes
        if (this.sameCondoSearchOption) {
          //focus of the search is a condo

          await this.findFreeholdOrCondoSearchFocus()
            .then(async (fcSearchFocusState) => {
              if (!fcSearchFocusState.isCanCreateCircularSearchBuffer()) { //focus is on a condo
                if (this.sameCondoSearch) { //"same condo" option on
                  formData.point = new Centroid(fcSearchFocusState.centroid.lat(), fcSearchFocusState.centroid.lng());
                  formData.condo = true;
                  formData.freehold = false;
                  this.loggerService.logDebug(`search properties in same condo only`);
                } else {
                  //ensure the center of the circle is a condo
                  let circleCenter: Centroid = this.getCircularSearchBufferCenter();
                  let pii: Pii = await lastValueFrom(this.spatialServiceService.getPIIByLatLng(circleCenter.latitude, circleCenter.longitude), {defaultValue: new Pii()});
                  this.loggerService.logDebug(`center of circle a condo? ${this.piiService.isCondo(pii)} lat[${circleCenter.latitude}] lng[${circleCenter.longitude}]`);

                  if (pii && this.piiService.isCondo(pii)) {
                    this.loggerService.logDebug(`center of circle is a condo, found address ${pii?.address?.streetAddress}`);
                    formData.center = circleCenter;
                    formData.radiusInMeters = this.getSelectedRadiusInMeters();

                    if (!formData.mps) {
                      switch (this.selectedPropertyType) {
                        case 'ALL':
                          formData.condo = true;
                          formData.freehold = true;
                          this.loggerService.logDebug(`search condo and freehold properties within the circle`);
                          break;
                        case 'Freehold':
                          formData.condo = false;
                          formData.freehold = true;
                          this.loggerService.logDebug(`search freehold properties only within the circle`);
                          break;
                        case 'condo':
                          formData.condo = true;
                          formData.freehold = false;
                          this.loggerService.logDebug(`search condo properties only within the circle`);
                          break;
                      }
                    }

                    if (formData.mps) {
                      formData.condo = true;
                      formData.freehold = false;
                      this.loggerService.logDebug(`search condo properties only within the circle`);
                    }

                  } else {
                    let error: string = `no condo found in the center of the circle`;
                    this.loggerService.logError(error);
                    throw new Error('search comparables error: ' + error);
                  }
                }
              }
            });

        } else {
          //focus of the search is not a condo
          formData.center = this.getCircularSearchBufferCenter();
          formData.radiusInMeters = this.getSelectedRadiusInMeters();

          if (!formData.mps) {
            switch (this.selectedPropertyType) {
              case 'ALL':
                formData.condo = true;
                formData.freehold = true;
                this.loggerService.logDebug(`search condo and freehold properties within the circle`);
                break;
              case 'Freehold':
                formData.condo = false;
                formData.freehold = true;
                this.loggerService.logDebug(`search freehold properties only within the circle`);
                break;
              case 'condo':
                formData.condo = true;
                formData.freehold = false;
                this.loggerService.logDebug(`search condo properties only within the circle`);
                break;
            }
          }

          if (formData.mps) {
            formData.condo = true;
            formData.freehold = true;
            this.loggerService.logDebug(`search condo and freehold properties within the circle`);

            //for mps, in what scenario does the circle contain non-condo properties only?
            //formData.condo = false;
            //formData.freehold = true;
            //seems like this is only possible by filtering by property codes 301, 305, 309, 311
          }

        }
      }

      if (this.selectedSearchBy == this.SEARCH_BY_POLYGON) {
        formData.polygons = this.mainMapService.getRenderedPolygons();

        if (!formData.mps) {
          switch (this.selectedPropertyType) {
            case 'ALL':
              formData.condo = true;
              formData.freehold = true;
              this.loggerService.logDebug(`search condo and freehold properties within the polygon(s)`);
              break;
            case 'Freehold':
              formData.condo = false;
              formData.freehold = true;
              this.loggerService.logDebug(`search freehold properties only within the polygon(s)`);
              break;
            case 'condo':
              formData.condo = true;
              formData.freehold = false;
              this.loggerService.logDebug(`search condo properties only within the polygon(s)`);
              break;
          }
        }

        if (formData.mps) {
          await this.findFreeholdOrCondoSearchFocus()
            .then(async (fcSearchFocusState) => {
              if (!fcSearchFocusState.isCanCreateCircularSearchBuffer()) { //focus is on a condo
                formData.condo = true;
                formData.freehold = false;
                this.loggerService.logDebug(`search condo properties only within the polygon(s)`);
              } else {
                formData.condo = false;
                formData.freehold = true;
                this.loggerService.logDebug(`search freehold properties only within the polygon(s)`);
              }

              //for mps, in what scenario does the polygon contain both condo and non-condo properties?
              //formData.condo = true;
              //formData.freehold = true;
            })
        }
      }

      //the parameters lastDays and minDate/maxDate are mutually exclusive
      if (this.selectedSalePeriodMethod == 'preset') {
        switch (this.form.get('lastDays')?.value) {
          case '30D':
            formData.lastDays = 30;
            break;
          case '3M':
            formData.lastDays = 90;
            break;
          case '6M':
            formData.lastDays = 183;
            break;
          case '1Y':
            formData.lastDays = 365;
            break;
        }
      } else if (this.selectedSalePeriodMethod == 'calendar') {

        let _minDate = this.form.get('minDate')?.value;
        let _maxDate = this.form.get('maxDate')?.value;

        if (_.isEmpty(_minDate)) {
          formData.minDate = dayjs().startOf('day').format(this.saleDateTimeFormFormat);  //default to today, start of day
        } else {
          formData.minDate = dayjs(_minDate.set({h: 0, m: 0})).format(this.saleDateTimeFormFormat);  //set as start of day
        }

        if (_.isEmpty(_maxDate)) {
          formData.maxDate = dayjs().endOf('day').format(this.saleDateTimeFormFormat);  //default to today, end of day
        } else {
          formData.maxDate = dayjs(_maxDate.set({h: 23, m: 59})).format(this.saleDateTimeFormFormat); //set as end of day
        }
      }

      formData.minAmount = this.toNumber(this.form.get('minAmount')?.value);
      formData.maxAmount = this.toNumber(this.form.get('maxAmount')?.value);

      //lot sizes
      let minAreaMetricValue: any = {};
      let maxAreaMetricValue: any = {};
      let selectedMinAreaValue = this.form.get('minArea')?.value;
      let selectedMaxAreaValue = this.form.get('maxArea')?.value;

      //the backend is always expecting the metric value that's based on the imperial display value
      if (this.measurementUnitService.isUomInMeters) {
        minAreaMetricValue = comparablesLotSizeValues.find((element) => {
          return (element.metricValue == selectedMinAreaValue);
        });

        maxAreaMetricValue = comparablesLotSizeValues.find((element) => {
          return (element.metricValue == selectedMaxAreaValue);
        });

      } else {
        minAreaMetricValue = comparablesLotSizeValues.find((element) => {
          return (element.imperialValue == selectedMinAreaValue);
        });

        maxAreaMetricValue = comparablesLotSizeValues.find((element) => {
          return (element.imperialValue == selectedMaxAreaValue);
        });
      }

      formData.minArea = minAreaMetricValue.value;
      formData.maxArea = maxAreaMetricValue.value;

      //add MPS request parameters
      if (formData.mps) {
        if (this.form.get('propertyTypeMPS')?.value == this.propertyTypesMPS[0].code) {
          formData.propertyType = null;
        } else {
          formData.propertyType = this.form.get('propertyTypeMPS')?.value.toString();
        }

        formData.buildTotalAreaFrom = this.toNumber(this.form.get('minBuildTotalArea')?.value);
        formData.buildTotalAreaTo = this.toNumber(this.form.get('maxBuildTotalArea')?.value);

        formData.minAssessmentValue = this.toNumber(this.form.get('minAssessmentAmount')?.value);
        formData.maxAssessmentValue = this.toNumber(this.form.get('maxAssessmentAmount')?.value);

        formData.buildYearFrom = this.form.get('minYearBuilt')?.value;
        if (formData.buildYearFrom == this.LESS_THAN_1901) {
          formData.buildYearFrom = 0;
        } else {
          formData.buildYearFrom = this.toNumber(formData.buildYearFrom);
        }
        formData.buildYearTo = this.toNumber(this.form.get('maxYearBuilt')?.value);

        formData.propertyCodeList = [];
        if (this.selectedPropertyCodes.length > 0) {
          if (this.propertyCodes.length == 0) { //all property codes have been added
            //this implicitly means search by all property codes
            formData.propertyCodeList[0] = -1;
            formData.propertyTypeCodeListSize = 0;
          } else {
            formData.propertyCodeList.push(...this.selectedPropertyCodes.map(propertyCode => {
              return +propertyCode.code;
            }));
            formData.propertyTypeCodeListSize = this.selectedPropertyCodes.length;
          }
        } else {
          //this implicitly means search by all property codes
          formData.propertyCodeList[0] = -1;
          formData.propertyTypeCodeListSize = 0;
        }

        if (this.selectedSearchBy == this.SEARCH_BY_RADIUS ||
          this.selectedSearchBy == this.SEARCH_BY_POLYGON) {
          formData.municipalityList = [""]; //[]
          formData.municipalityListSize = 0;
        } else {
          formData.munPolygons = [];
          formData.municipalityList = [];
          /*
          if (this.selectedMunicipalities.length == 0) {
            //explicitly search by all municipalities
            this.addAllMunicipalities();
          }
          */
          formData.municipalityList.push(...this.selectedMunicipalities.map(municipality => {
            return Number(municipality.munId);
          }));
          formData.municipalityListSize = formData.municipalityList.length;
        }
      }

      this.loggerService.logDebug(`search comparables form ${JSON.stringify(formData, null, 2)}`);
      goodToSubmit = true;

    } catch (e) {
      goodToSubmit = false;
      this.loggerService.logError(`error preparing the search comparables request`, e);
      this.openSnackBarError(ErrorUtil.SEARCH_COMPARABLES_SUBMIT_FORM_ERROR);
      this.postSubmitForm();
    }

    //submit the request
    if (goodToSubmit) {
      try {
        let comparables: any;

        this.loggerService.logDebug('search comparables started');

        this.mainMapService.closeAllMarkerBubbles();

        //remove previous search result markers
        this.mainMapService.clearSearchComparableMarkers();

        if (this.selectedSearchBy == this.SEARCH_BY_RADIUS) {
          if (this.sameCondoSearch) {
            comparables = await lastValueFrom(this.comparablesSearchService.getComparableSalesWithinSameCondo(formData));
          } else {
            comparables = await lastValueFrom(this.comparablesSearchService.getComparableSalesByRadius(formData));
          }
        } else if (this.selectedSearchBy == this.SEARCH_BY_POLYGON) {
          comparables = await lastValueFrom(this.comparablesSearchService.getComparableSalesByPolygon(formData));
        } else if (this.selectedSearchBy == this.SEARCH_BY_MUNICIPALITY) {
          comparables = await lastValueFrom(this.comparablesSearchService.getComparableSalesByMunicipality(formData));
        }

        if (this.screenManager.isScreenVisible(ScreenNameEnum.SEARCH_COMPARABLES_FORM)) {
          if (comparables.salesCount > 0) {
            this.screenManager.showScreen(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS);

            setTimeout(() => {
              //update the payload for the search comparables results
              let results: ComparableSalesResultPayload = new ComparableSalesResultPayload();
              let formWrapper: SearchComparablesFormWrapper = new SearchComparablesFormWrapper();

              try {
                results.formWrapper = formWrapper;

                formWrapper.form = _.cloneDeep(this.form);
                this.loggerService.logDebug(`search comparables form cloned`);

                formWrapper.selectedSearchBy = this.selectedSearchBy;
                formWrapper.radiusOption = this.getSelectedRadius();
                formWrapper.sameCondoSearch = this.sameCondoSearch;
                if (formWrapper.selectedSearchBy == this.SEARCH_BY_RADIUS) {
                  if (!formWrapper.sameCondoSearch) {
                    formWrapper.circleCenter = this.getCircularSearchBufferCenter();
                  } else {
                    formWrapper.circleCenter = null;
                    formWrapper.sameCondoLocation = new google.maps.LatLng(formData.point.latitude, formData.point.longitude);
                  }
                }
                formWrapper.municipalities = _.cloneDeep(this.municipalities);
                formWrapper.selectedMunicipalities = _.cloneDeep(this.selectedMunicipalities);
                formWrapper.totalMunicipalitiesCount = _.cloneDeep(this.totalMunicipalitiesCount);
                formWrapper.propertyCodes = _.cloneDeep(this.propertyCodes);
                formWrapper.selectedPropertyCodes = _.cloneDeep(this.selectedPropertyCodes);

                formWrapper.searchCriteriaCrumbs = this.getSearchCriteriaDisplayCrumbs(formData);
                formWrapper.searchShapes = new ComparableSalesShape();
                formWrapper.searchShapeBounds = new ComparableSalesShapeBounds();

                if (this.selectedSearchBy == this.SEARCH_BY_RADIUS) {
                  if (formData.point) { //"same condo" option on
                    formData.searchCenter = formData.point;
                  } else {
                    formData.searchCenter = formData.center;
                  }
                  results.formWrapper.searchShapeBounds.searchCircleBounds = this.distanceWidget.getCircle().getBounds();
                } else if (this.selectedSearchBy == this.SEARCH_BY_POLYGON) {
                  formData.searchCenter = new Centroid(this.mainMapService.getUserDrawnPolygonBounds().getCenter().lat(), this.mainMapService.getUserDrawnPolygonBounds().getCenter().lng());
                  results.formWrapper.searchShapes.polygonOverlays = this.mainMapService.getUserDrawnPolygons();
                  results.formWrapper.searchShapes.polygonMarkerXRef = this.mainMapService.getRenderedPolygonMarkerXref();
                  results.formWrapper.searchShapeBounds.searchPolygonBounds = this.mainMapService.getUserDrawnPolygonBounds();
                } else if (this.selectedSearchBy == this.SEARCH_BY_MUNICIPALITY) {
                  formData.searchCenter = new Centroid(this.mainMapService.getSelectedMunicipalityBounds().getCenter().lat(), this.mainMapService.getSelectedMunicipalityBounds().getCenter().lng());
                  results.formWrapper.searchShapeBounds.searchMunicipalityBounds = this.mainMapService.getSelectedMunicipalityBounds();
                }

              } catch (e) {
                this.loggerService.logError(`error cloning the search comparables form wrapper for snapshot recovery`);
              }

              results.request = formData;
              results.response = comparables;
              results.formWrapper = formWrapper;

              this.searchComparablesResultService.updateSearchResultsPayload(results);

              this.screenManager.hideScreen(ScreenNameEnum.SEARCH_COMPARABLES_FORM);

              this.submitted = false;
            }, 200);

          } else {
            this.openSnackBarError(DataService.SEARCH_RESULTS_NOT_FOUND);

            //send an empty payload to clear the results screen
            if (this.screenManager.isScreenVisible(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS)) {
              this.searchComparablesResultService.updateSearchResultsPayload(new ComparableSalesResultPayload());
            }
          }
        } else {
          //Either the user closed the form or some other screens which opened auto-closed this form.
          //Either way, we treat this scenario as if the user cancelled the search.
          this.loggerService.logWarning('search comparables form already closed and cannot display the results');
        }

        this.submitted = false;

      } catch (e) {//TODO how do we differentiate data or load issue from a system error? should we differentiate by http codes?
        this.loggerService.logError(`error getting the search comparables results`, e);
        if ((<HttpErrorResponse>e)?.error == ErrorUtil.BAD_REQUEST_ERROR_MESSAGE) {
          this.showAreaTooLargeError(ErrorUtil.SEARCH_AREA_TOO_LARGE, 8000);  //note: this may not be the real cause of the error
        } else {
          this.showAreaTooLargeError(ErrorUtil.DEFAULT_ERROR_MESSAGE, 8000);
        }

      } finally {
        this.loggerService.logDebug('search comparables ended');
        this.postSubmitForm();
      }
    }

  }

  private getSearchCriteriaDisplayCrumbs = (request: ComparableSalesRequest) => {
    let searchCriteriaCrumbs: SearchComparablesCriteriaCrumb[] = [];

    //prepare some search criteria breadcrumbs for the results screen

    let saleDateRangeLabel: string = '';
    if (this.selectedSalePeriodMethod == 'preset') {
      switch (this.selectedSalePeriod) {
        case '30D':
          saleDateRangeLabel = '30 Days';
          break;
        case '3M':
          saleDateRangeLabel = '3 Months';
          break;
        case '6M':
          saleDateRangeLabel = '6 Months';
          break;
        case '1Y':
          saleDateRangeLabel = '1 Year';
          break;
      }
    }
    if (this.selectedSalePeriodMethod == 'calendar') {
      saleDateRangeLabel = moment(this.form.get('minDate')?.value).format('MMM DD, YYYY') + ' - ' + moment(this.form.get('maxDate')?.value).format('MMM DD, YYYY');
    }

    let lotAreaRangeLabel: string = this.getConvertedLotSizeLabel(this.form.get('minArea')?.value) + ' - ' + this.getConvertedLotSizeLabel(this.form.get('maxArea')?.value);

    let minAmountLabel: string = '';
    minAmountLabel = this.currencyPipe.transform(NumberUtility.removingLeadingZero(StringUtility.safeNumber(this.form.get('minAmount')?.value)), '', 'symbol', '1.0-0')!;
    let maxAmountLabel: string = '';
    if (this.form.get('maxAmount')?.value == this.INFINITY) {
      maxAmountLabel = '$' + this.INFINITY_VALUE_DISPLAY;
    } else {
      maxAmountLabel = this.currencyPipe.transform(NumberUtility.removingLeadingZero(StringUtility.safeNumber(this.form.get('maxAmount')?.value)), '', 'symbol', '1.0-0')!;
    }
    let priceAmountRangeLabel: string = minAmountLabel + ' - ' + maxAmountLabel;
    searchCriteriaCrumbs.push(new SearchComparablesCriteriaCrumb('saleDateRange', saleDateRangeLabel));
    searchCriteriaCrumbs.push(new SearchComparablesCriteriaCrumb('priceAmountRange', priceAmountRangeLabel));
    searchCriteriaCrumbs.push(new SearchComparablesCriteriaCrumb('lotAreaRange', lotAreaRangeLabel));

    return searchCriteriaCrumbs;
  }

  preSubmitForm = () => {
    this.submitted = true;
    this.mainMapService.closeAllMarkerBubbles();
    this.mainMapService.cancelSearchComparablesPolygonDrawing();
    this.form.controls['minAmount'].disable();
    this.form.controls['maxAmount'].disable();
    this.form.controls['minAssessmentAmount'].disable();
    this.form.controls['maxAssessmentAmount'].disable();
    this.form.controls['minYearBuilt'].disable();
    this.form.controls['maxYearBuilt'].disable();
    this.form.controls['minBuildTotalArea'].disable();
    this.form.controls['maxBuildTotalArea'].disable();
    this.form.controls['radiusInMeters'].disable();
  }

  postSubmitForm = () => {
    this.submitted = false;
    this.searchBusyIndicatorService.hideMainMapBusyIndicator();
    this.form.controls['minAmount'].enable();
    this.form.controls['maxAmount'].enable();
    this.form.controls['minAssessmentAmount'].enable();
    this.form.controls['maxAssessmentAmount'].enable();
    this.form.controls['minYearBuilt'].enable();
    this.form.controls['maxYearBuilt'].enable();
    this.form.controls['minBuildTotalArea'].enable();
    this.form.controls['maxBuildTotalArea'].enable();
    this.form.controls['radiusInMeters'].enable();
  }

  onSelectedFromSaleDateChange = (dateObject: any) => {
    //quick workaround to convert the <mat-datepicker> output to the 'yyyy/mm/dd hh:mm' format
    let stringified = JSON.stringify(dateObject.value);
    let temp = stringified.substring(1, 11);
    temp = _.replace(temp, '-', '/');
    temp = temp + '00:00';
    this.selectedFromSaleDate = temp;
  }

  onSelectedToSaleDateChange = (dateObject: any) => {
    //quick workaround to convert the <mat-datepicker> output to the 'yyyy/mm/dd hh:mm' format
    let stringified = JSON.stringify(dateObject.value);
    let temp = stringified.substring(1, 11);
    temp = _.replace(temp, '-', '/');
    temp = temp + '23:59';
    this.selectedToSaleDate = temp;
  }

  //ported and modified from 2g
  get buildAreaLabel() {
    // 2g is always returns imperial uom
    return 'Build Total Area (sq. ft.)';
    //return 'Build Total Area (sq. m.)';
  }

  //ported and modified from 2g
  get lotSizeLabel() {
    return this.measurementUnitService.isUomInMeters ?
      'Lot Size (sq. m.)' :
      'Lot Size (sq. ft.)';
  }

  //ported and modified from 2g
  getConvertedLotSize = (oneBasedIndex: any) => {
    let value: string = '';

    for (let i = 0; i < comparablesLotSizeValues.length; i++) {
      let lotSize = comparablesLotSizeValues[i];
      if (lotSize.id == oneBasedIndex) {
        value = this.measurementUnitService.isUomInMeters ? lotSize.metricDisplayValue : lotSize.imperialDisplayValue;
        break;
      }
    }

    return value;
  }

  getConvertedLotSizeValue = (oneBasedIndex: any) => {
    let value: string = '';

    for (let i = 0; i < comparablesLotSizeValues.length; i++) {
      let lotSize = comparablesLotSizeValues[i];
      if (lotSize.id == oneBasedIndex) {
        value = this.measurementUnitService.isUomInMeters ? lotSize.metricValue : lotSize.imperialValue;
        //value = lotSize.value;  //the backend is always expecting the metric value that's based on the imperial display value
        break;
      }
    }

    return value;
  }

  getConvertedLotSizeLabel = (value: string) => {
    let label: string = '';

    for (let i = 0; i < comparablesLotSizeValues.length; i++) {
      let lotSize = comparablesLotSizeValues[i];
      if (!this.measurementUnitService.isUomInMeters && lotSize.imperialValue == value) {
        label = lotSize.imperialDisplayValue;
        break;
      } else if (this.measurementUnitService.isUomInMeters && lotSize.metricValue == value) {
        label = lotSize.metricDisplayValue + ' m²';
        break;
      }
    }

    return label;
  }

  //ported and modified from 2g
  changeFromlotSize = (index: string) => {
    /* error checking */
    var fromLotSize = String(DataService.addCommas(this.getConvertedLotSize(index)));
    var toLotSize = String(this.toLotSize);
    var validLotSize = true;

    if (fromLotSize.search('acre') != -1 && toLotSize.search('sqft') != -1) {			// acre - sqft
      validLotSize = false;
    } else if (fromLotSize.search('sqft') != -1) {
      if (toLotSize.search('sqft') != -1) {		// sqft - sqft
        var minSize = parseFloat(String(fromLotSize).replace(/,/g, ''));		//removing the comma and parsing to number
        var maxSize = parseFloat(String(toLotSize).replace(/,/g, ''));
        validLotSize = (minSize > maxSize) ? false : true;
      }
    } else {	//acre - acre
      var minSize = parseFloat(String(fromLotSize).replace(/,/g, ''));		//removing the comma and parsing to number
      var maxSize = parseFloat(String(toLotSize).replace(/,/g, ''));
      validLotSize = (minSize > maxSize) ? false : true;
    }

    if (!validLotSize) {
      //$('#lot-error-message-from').show().delay(2000).fadeOut(500);
    } else {
      var value = this.getConvertedLotSize(index);
      this.fromLotSize = DataService.addCommas(value);
    }
  }

  isCustomRadiusSelected = (): boolean => {
    let customRadius: boolean = false;

    let radius = this.getSelectedRadius();
    if (radius) {
      customRadius = radius.customRadius;
    }

    return customRadius;
  }

  getSelectedRadius = (): SearchComparablesRadiusOption => {
    return this.formControls['radiusInMeters'].value;
  }

  getSelectedRadiusValue = (): string => {
    let radiusValue: SearchComparablesRadiusOption = this.getSelectedRadius();
    let radius: string = '';

    radius = radiusValue.metricValue; //see notes in getConvertedRadius()

    return radius;
  }

  getSelectedRadiusInMeters = (): number => {
    let radiusInMeters: any;
    let radiusValue = this.getSelectedRadiusValue();

    if (radiusValue != null && radiusValue.indexOf("km") > 0) {
      //@ts-ignore
      radiusInMeters = radiusValue.substr(0, radiusValue.indexOf("km")) * 1000;
    } else if (radiusValue != null && radiusValue.indexOf("m") > 0) {
      //@ts-ignore
      radiusInMeters = radiusValue.substr(0, radiusValue.indexOf("m"));
    } else {
      radiusInMeters = 250;
    }

    this.loggerService.logDebug(`selected radius in meters ${radiusInMeters}`);
    return radiusInMeters;
  }

  closeCircularSearchBuffer() {
    this.screenManager.hideScreen(ScreenNameEnum.SEARCH_COMPARABLES_FORM);
  }

  destroyCircularSearchBuffer() {
    this.distanceWidget = null;
  }

  /**
   * Creates the circular search buffer.
   *
   * @param circleCenter
   * @param radiusKm
   */
  handleCircleRadiusChange = (circleCenter: google.maps.LatLng, radiusKm: number) => {
    //@ts-ignore
    this.distanceWidget = new DistanceWidget(this.mainMapService.getMap(), circleCenter, radiusKm, this.closeCircularSearchBuffer);
    this.mainMapService.setCircularSearchAreaBufferStorage(this.distanceWidget.getRenderedCircularBufferObjects());   //get handle to the widget objects so we can clear them anywhere outside of the widget
    google.maps.event.addListener(this.distanceWidget, 'distance_changed', () => {
      setTimeout(() => {
        this.updateCustomRadiusValue(this.distanceWidget);
      }, 10);
    });
  }

  handleCircleLocationChange = () => {
    google.maps.event.addListener(this.distanceWidget.get('circularBufferCenterMarker'), 'dragend', async () => {
      //is there a condo in the center of the circle after the user dragged it?
      let circleCenter: Centroid = this.getCircularSearchBufferCenter();
      let pii: Pii = await lastValueFrom(this.spatialServiceService.getPIIByLatLng(circleCenter.latitude, circleCenter.longitude), {defaultValue: new Pii()});

      this.mainMapService.closeAllMarkerBubbles();
      this.mainMapService.clearSearchComparablesPIIPolygonObjects();
      this.mainMapService.clearClickMarkers();
      this.mainMapService.clearClickPolygons();
      this.mainMapService.closeAllMarkerBubbles();

      if (pii) {
        this.loggerService.logDebug(`new center of circle a condo? ${this.piiService.isCondo(pii)}, found address ${(this.piiService.isCondo(pii))? pii?.address?.streetAddress : pii?.address?.fullAddress}`);
        this.renderPIIPolygon(pii);

        if (this.piiService.isCondo(pii)) {
          this.sameCondoSearchOption = true;
          this.sameCondoSearch = true;
          this.setSameCondoTooltipText(pii?.address.streetAddress);
          this.mainMapService.clearSearchComparablesCircleObjects();
        } else {
          this.sameCondoSearchOption = false;
          this.sameCondoSearch = false;
          this.setFreeholdTooltipText(pii?.address?.fullAddress? pii.address.fullAddress : DataService.ADDRESS_NOT_AVAILABLE);
        }

        if (this.screenManager.isScreenVisible(ScreenNameEnum.SEARCH_COMPARABLES_FORM)) {
          this.mainMapService.setMapCenter(new google.maps.LatLng(this.getCircularSearchBufferCenter().latitude, this.getCircularSearchBufferCenter().longitude));
        }
      }
    });
  }

  handleCircleClose = () => {
    let circleCloseMarker: google.maps.Marker = this.distanceWidget.getCircleCloseMarker();
    google.maps.event.addListener(circleCloseMarker, 'click', async () => {
      //is there a condo in the center of the circle prior to closing it?
      let circleCenter: Centroid = this.getCircularSearchBufferCenter();
      let pii: Pii = await lastValueFrom(this.spatialServiceService.getPIIByLatLng(circleCenter.latitude, circleCenter.longitude), {defaultValue: new Pii()});

      this.mainMapService.clearSearchComparablesCircleObjects();
      this.mainMapService.closeAllMarkerBubbles();
      this.removeCustomRadius();

      if (pii) {
        if (this.piiService.isCondo(pii)) {
          this.sameCondoSearchOption = true;
          this.sameCondoSearch = true;
        } else {
          this.sameCondoSearchOption = false;
          this.sameCondoSearch = false;
          this.screenManager.hideScreen(ScreenNameEnum.SEARCH_COMPARABLES_FORM);
        }
      }
    });
  }

  createCircularSearchBuffer = async () => {
    if (!this.formVisible) return;

    try {
      this.circularSearchBufferReady = false;

      //default the circle center to the map center
      let circleCenter: google.maps.LatLng = this.mainMapService.getMapCenter();

      //actual center of the circle is where the marker is, which could in fact be the map center if the marker was previously centered
      let markerToCenter: google.maps.Marker | undefined = undefined;

      if (this.mainMapService.isClickedMarkersExist()) {
        markerToCenter = this.mainMapService.getClickedMarkers()[0];
      } else if (this.mainMapService.isRenderedMarkersExist()) {
        markerToCenter = this.mainMapService.getRenderedMarkers()[0]; //this should essentially be similar to the call to this.propertyReportService.getSubjectPropertyLatLng();
      }

      if (markerToCenter) {
        try {
          //if there is an existing circle on the map, then the center of this circle takes priority over the marker position.
          //this is to support the use case where the user might have moved the circle away from its original position.
          let existingCircleCenter: Centroid = this.getCircularSearchBufferCenter();
          if (existingCircleCenter) {
            circleCenter = new google.maps.LatLng(existingCircleCenter.latitude, existingCircleCenter.longitude);
          }
        } catch (e) {
          //take the center of the circle from the marker position
          if (markerToCenter.getPosition()) {
            circleCenter = new google.maps.LatLng(markerToCenter.getPosition()!.lat(), markerToCenter.getPosition()!.lng());
          } else {
            //this is because we are not allowing two markers to occupy the same location, as in the case of the user clicking on a property which already has a marker,
            //in this case we captured the clicked location in 'markerPosition'.
            circleCenter = new google.maps.LatLng(markerToCenter.get('markerPosition').lat(), markerToCenter.get('markerPosition').lng());
          }
        }

        setTimeout(() => {
          this.mainMapService.panMapToCenter(circleCenter);
        }, 100);
      } else {
        this.loggerService.logDebug(`defaulting map center to center of viewport`);
        setTimeout(() => {
          this.mainMapService.panMapToCenter(new google.maps.LatLng(circleCenter));
        }, 100);
      }

      this.mainMapService.clearSearchComparablesObjects();

      let radiusKm = this.getSelectedRadiusInMeters() / 1000;
      this.loggerService.logDebug(`creating circular search buffer around lat ${circleCenter.lat()}, lng ${circleCenter.lng()}, radius ${radiusKm}km`);

      //create the circle and handle changing of radius
      this.handleCircleRadiusChange(circleCenter, radiusKm);

      //handle changing of circle center
      this.handleCircleLocationChange();

      //handle closing of circle
      this.handleCircleClose();

      this.circularSearchBufferReady = true;

    } catch (e) {
      this.circularSearchBufferReady = false;
      this.loggerService.logError(`error creating circular search buffer`, e);
    }

  }

  createCircularSearchBufferFromSnapshot = async (recoverySnapshot: SearchComparablesResultSnapshot) => {
    try {
      if (recoverySnapshot.results.formWrapper.circleCenter) {
        this.circularSearchBufferReady = false;

        let circleCenter: google.maps.LatLng;
        circleCenter = new google.maps.LatLng(recoverySnapshot.results.formWrapper.circleCenter.latitude, recoverySnapshot.results.formWrapper.circleCenter.longitude);

        setTimeout(() => {
          this.mainMapService.panMapToCenter(circleCenter);
        }, 100);

        let radiusKm = this.getSelectedRadiusInMeters() / 1000;
        this.loggerService.logDebug(`re-creating circular search buffer around lat ${circleCenter.lat()}, lng ${circleCenter.lng()}, radius ${radiusKm}km`);

        //create the circle and handle changing of radius
        this.handleCircleRadiusChange(circleCenter, radiusKm);

        //handle changing of circle center
        this.handleCircleLocationChange();

        //handle closing of circle
        this.handleCircleClose();

        this.circularSearchBufferReady = true;
      } else {
        this.loggerService.logDebug(`cannot create circle from snapshot as center of circle is missing`);
      }


    } catch (e) {
      this.circularSearchBufferReady = false;
      this.loggerService.logError(`error re-creating circular search buffer from snapshot`, e);
    }

  }

  removeCustomRadius = () => {
    if (this.radiusDropDown) {
      _.remove(this.radiusDropDown, radius => radius.customRadius == true);
    }
  }

  //ported and modified from 2g with lob comments preserved
  updateCustomRadiusValue(distanceWidget: any) {

    var dist = this.distanceWidget.get('distance');
    var updatedRadius: string;
    var info = document.getElementById('radiusbox');

    // temporarily remove metric/imperial conversion from comps search as per LOB request
    /*
     * // the distance widget returns map measurements in kilometers by default if ($rootScope.isMetricUOM()) { // change global unit of measurement to metric if (dist !=
     * null & dist < 1) { // less than 1km var m = distanceWidget.get('distance') * 1000; updatedRadius = m.toFixed(0) + "m"; } else { var m = distanceWidget.get('distance');
     * updatedRadius = m.toFixed(1) + "km"; }
     *
     * info.value = updatedRadius; } else if ($rootScope.isImperialUOM()) { // change global unit of measurement to imperial if (dist != null & dist < 1) { // distance is
     * less than 0.6mi (1km) var f = distanceWidget.get('distance') * 3280.84; updatedRadius = f.toFixed(0) + "ft"; } else { var f = distanceWidget.get('distance') * 0.62;
     * updatedRadius = f.toFixed(1) + "mi"; }
     *
     * info.value = updatedRadius; }
     */

    let m: number;
    if (dist != null && dist < 1) { // less than 1km
      m = this.distanceWidget.get('distance') * 1000;
      updatedRadius = m.toFixed(0) + "m";
    } else {
      m = this.distanceWidget.get('distance');
      updatedRadius = m.toFixed(1) + "km";
    }

    //todo: determine zoom level for this custom radius and convert meters to imperial value
    this.customRadius = new SearchComparablesRadiusOption(250, updatedRadius, 16, updatedRadius, "", true);
    this.loggerService.logDebug(`custom radius changed to ${this.customRadius.metricValue}`);

    setTimeout(() => {
      //remove any custom radius
      this.removeCustomRadius();

      //add the new custom radius
      this.radiusDropDown.unshift(this.customRadius);

      //show the new custom radius
      this.formControls['radiusInMeters'].setValue(this.customRadius);
    }, 10);
  }

  get userDrawingPolygon() {
    return this.mainMapService.isUserDrawingPolygon();
  }

  drawUserPolygon = () => {
    this.selectedSearchBy = this.SEARCH_BY_POLYGON;
    this.preDrawUserPolygon();

    if (!this.mainMapService.isMaximumSearchPolygonsReached()) {
      this.mainMapService.continuePolygonDrawing();
    } else {
      let message: string = `The maximum number of ${this.maximumSearchPolygonsAllowed} polygon search areas have been reached.`;
      this._snackBar.open(message, 'Close', defaultErrorMatSnackBarConfig);
    }
  }

  cancelPolygonDrawing = () => {
    this.mainMapService.cancelSearchComparablesPolygonDrawing();
  }

  openSnackBarError(msg: string) {
    this._snackBar.open(msg, 'Close', defaultErrorMatSnackBarConfig);
  }

  showAreaTooLargeError(msg: string, duration: number) {
    defaultErrorMatSnackBarConfig['duration'] = duration;
    this._snackBar.open(msg, 'Close', defaultErrorMatSnackBarConfig);
  }

  createFormGroup = () => {
    this.form = this.formBuilder.group(
      {
        radiusInMeters: [''],
        lastDays: [''],
        minDate: [''],
        maxDate: [''],
        propertyType: [''],
        propertyTypeMPS: [''],
        minBuildTotalArea: [''],
        maxBuildTotalArea: [''],
        minAmount: [''],
        maxAmount: [''],
        minYearBuilt: [''],
        maxYearBuilt: [''],
        minAssessmentAmount: [''],
        maxAssessmentAmount: [''],
        minArea: [''],
        maxArea: ['']
      }, {
        validators: this.customValidators
      }
    );
  }

  resetForm(): void {
    this.submitted = false;
    this.form.reset();
  }

  getPreferredSearchCriteriaValue = (preferredSearchCriteria: ComparableSearchCriteria, key: PREFERENCE_KEY): any => {
    switch (key) {
      case 'search-area':
        // @ts-ignore
        return SearchComparablesEnum[EnumUtility.getEnumKeyByEnumValue(SearchComparablesEnum, _.capitalize(preferredSearchCriteria?.defaultSearchAreaSelection))];

      case 'sale-period-method':
        return _.lowerCase(preferredSearchCriteria?.lastDaysDateRangeType);

      case 'sale-period':
        switch (preferredSearchCriteria?.lastDaysDateRange) {
          case 'LAST_30_DAYS':
            return '30D';
          case 'LAST_90_DAYS':
            return '3M';
          case 'LAST_6_MONTHS':
            return '6M';
          case 'LAST_YEAR':
            return '1Y';
        }
        break;

      case 'property-type':
        //note: the value case is deliberate as those values are expected by the server
        switch (preferredSearchCriteria?.freeholdOrCondoOrAll) {
          case 'ALL':
            return 'ALL';
          case 'CONDO':
            return 'condo';
          case 'FREEHOLD':
            return 'Freehold';
        }
        break;

      case 'minimum-price-amount':
        return preferredSearchCriteria?.minAmount;

      case 'maximum-price-amount':
        return preferredSearchCriteria?.maxAmount;

      case 'minimum-lot-area':
        return this.getConvertedLotSizeValue(preferredSearchCriteria?.minArea);

      case 'maximum-lot-area':
        return this.getConvertedLotSizeValue(preferredSearchCriteria?.maxArea);
    }

  }

  closeForm = () => {
    this.screenManager.hideScreen(ScreenNameEnum.SEARCH_COMPARABLES_FORM);
    this.visible.emit(false);
  }

  resetFormData() {
    this.initializeForm(this.getPreferredSearchCriteria());
  }

  isMpsUser = () => {
    return this.userAccessControls.isMpsUser;
  }

  get userPreferenceForSearchByArea(): string {
    return this.getPreferredSearchCriteriaValue(this.getPreferredSearchCriteria(), 'search-area');
  }

  initializeForm = (preferredSearchCriteria: ComparableSearchCriteria) => {
    // @ts-ignore
    let lroId: string = this.lroPolygonsService.getCurrentLroState().lroId!;
    this.selectedSearchBy = this.getPreferredSearchCriteriaValue(preferredSearchCriteria, 'search-area');
    this.selectedPropertyTypeMPS = this.propertyTypesMPS[0].code;
    this.selectedSalePeriodMethod = this.getPreferredSearchCriteriaValue(preferredSearchCriteria, 'sale-period-method');
    this.selectedSalePeriod = this.getPreferredSearchCriteriaValue(preferredSearchCriteria, 'sale-period');

    this.removeCustomRadius();
    this.formControls['radiusInMeters'].setValue(this.radiusDropDown[0]);
    this.formControls['lastDays'].setValue(this.selectedSalePeriod);
    this.formControls['minDate'].setValue(new Date());
    this.formControls['maxDate'].setValue(new Date());
    this.formControls['propertyType'].setValue(this.getPreferredSearchCriteriaValue(preferredSearchCriteria, 'property-type'));
    this.formControls['propertyTypeMPS'].setValue(this.selectedPropertyTypeMPS);
    this.formControls['minBuildTotalArea'].setValue(this.totalBuildAreas[0].value);
    this.formControls['maxBuildTotalArea'].setValue(this.totalBuildAreas[this.totalBuildAreas.length - 1].value);
    this.formControls['minAmount'].setValue(this.getPreferredSearchCriteriaValue(preferredSearchCriteria, 'minimum-price-amount'));
    this.formControls['maxAmount'].setValue(this.getPreferredSearchCriteriaValue(preferredSearchCriteria, 'maximum-price-amount'));
    this.formControls['minAssessmentAmount'].setValue(this.minAssessmentRange[0].id);
    this.formControls['maxAssessmentAmount'].setValue(this.maxAssessmentRange[this.maxAssessmentRange.length - 1].id);
    this.formControls['minArea'].setValue(this.getPreferredSearchCriteriaValue(preferredSearchCriteria, 'minimum-lot-area'));
    this.formControls['maxArea'].setValue(this.getPreferredSearchCriteriaValue(preferredSearchCriteria, 'maximum-lot-area'));
    this.formControls['minYearBuilt'].setValue(this.yearBuiltFrom[this.yearBuiltFrom.length - 1]);
    this.formControls['maxYearBuilt'].setValue(this.yearBuiltTo[0]);

    this.mainMapService.closeSearchComparablesStartPolygonInstructions();
    this.mainMapService.cancelSearchComparablesPolygonDrawing();
    this.mainMapService.clearSearchComparablesPolygonObjects();

    if (this.isMpsUser()) {
      this.removeAllSelectedMunicipalities();
      this.unselectAllMunicipalities();
      this.mainMapService.clearSearchComparablesMunicipalityObjects();
      this.populateLroMunicipalities(lroId);
      this.removeAllSelectedPropertyCodes();
      this.unselectAllPropertyCodes();
      this.populateLroPropertyCodes(lroId);  //TODO: do property codes change across lros? if not, cache this.
    }

    this.findFreeholdOrCondoSearchFocus()
      .then((fcSearchFocusState) => {
        if (!fcSearchFocusState.isCanCreateCircularSearchBuffer()) {
          this.selectedPropertyType = 'condo';
          this.loggerService.logDebug(`non-mps property type set to ${this.selectedPropertyType}`);
        }
      });

    if (this.selectedSearchBy == this.SEARCH_BY_RADIUS) {
      this.resetCircularSearchAreaDisplay(false);
    }

    this.setSameCondoTooltipText(null);
    this.setFreeholdTooltipText(null);

    this.updateSliderValues();
  }

  initializeFormFromSnapshot = (recoverySnapshot: SearchComparablesResultSnapshot) => {
    this.loggerService.logDebug(`form recovery from snapshot started`);

    let formWrapper: SearchComparablesFormWrapper = recoverySnapshot.results.formWrapper;
    let savedForm: FormGroup = formWrapper.form;

    //restore form data
    this.form = savedForm;

    //restore all other data
    this.selectedSearchBy = formWrapper.selectedSearchBy;
    this.formControls['radiusInMeters'].setValue(formWrapper.radiusOption);

    this.municipalities = formWrapper.municipalities;
    this.selectedMunicipalities = formWrapper.selectedMunicipalities;
    this.totalMunicipalitiesCount = formWrapper.totalMunicipalitiesCount;
    this.propertyCodes = formWrapper.propertyCodes;
    this.selectedPropertyCodes = formWrapper.selectedPropertyCodes;

    //restore the search shapes
    this.mainMapService.setUserDrawnPolygons(recoverySnapshot.results.formWrapper.searchShapes.polygonOverlays);
    this.mainMapService.setRenderedPolygonMarkerXref(recoverySnapshot.results.formWrapper.searchShapes.polygonMarkerXRef);

    switch (this.selectedSearchBy) {
      case SearchComparablesEnum.SEARCH_BY_RADIUS:
        try {
          this.createCircularSearchBufferFromSnapshot(recoverySnapshot);
        } catch (e) {
          this.loggerService.logError(`error re-creating the search circle from snapshot`, e);
        }
        break;

      case SearchComparablesEnum.SEARCH_BY_POLYGON:
        try {
          let polygonOverlays: any[] = this.mainMapService.getUserDrawnPolygons();

          polygonOverlays.map((overlay) => {
            overlay.setMap(this.mainMapService.getMap());
            let path = overlay.getPath();
            let pathList = path.getArray();
            try {
              this.mainMapService.addSearchComparablesCustomPolygon(pathList);
            } catch (e) {
              this.loggerService.logError(`error re-creating the custom polygon from snapshot`);
            }
          });

        } catch (e) {
          this.loggerService.logError(`error re-creating the search polygons from snapshot`, e);
        }
        break;

      case SearchComparablesEnum.SEARCH_BY_MUNICIPALITY:
        setTimeout(() => {
          try {
            this.sortAndRenderMunicipalities(this.selectedMunicipalities);
          } catch (e) {
            this.loggerService.logError(`error re-creating the municipality polygons from snapshot`, e);
          }
        }, 100);
        break;
    }

    this.searchComparablesFormService.markSnapshotFormRecoveryAsFinished();
    this.loggerService.logDebug(`form recovery from snapshot finished`);
  }

  get remainingPolygons() {
    let count: number = this.maximumSearchPolygonsAllowed - this.mainMapService.getRenderedPolygonsCount();
    return count;
  }

  getPreferredSearchCriteria = (): ComparableSearchCriteria => {
    let preferredSearchCriteria: ComparableSearchCriteria = this.userService.getUserPreferencesFromLocalStorage()?.comparablesPreference?.searchCriteria;

    this.loggerService.logDebug(`search criteria preferences`, preferredSearchCriteria);
    return preferredSearchCriteria;
  }

  private renderPIIPolygon(pii: Pii) {
    try {
      this.mainMapService.renderPIIPolygon(pii, this.mainMapService.getCircularSearchAreaBufferStorage());
    } catch (e) {
      this.loggerService.logError(`error rendering pii polygon for pin ${pii?.pin}`);
    }
  }

  /**
   * Determines the focus of the search area and whether it is a freehold or condo.
   * Note that when determining a non-marker center, it takes the center of the map and not the center of the "Search by polygon" circle.
   *
   * @returns
   */
  findFreeholdOrCondoSearchFocus = async (): Promise<FreeholdOrCondoSearchFocusState> => {
    let marker: google.maps.Marker | undefined = undefined;
    let fcSearchFocusState: FreeholdOrCondoSearchFocusState = new FreeholdOrCondoSearchFocusState();

    if (this.mainMapService.isClickedMarkersExist()) {
      marker = this.mainMapService.getClickedMarkers()[0];  //the property the user last clicked on
    } else if (this.mainMapService.isRenderedMarkersExist() && this.mainMapService.getRenderedMarkersCount() == 1) {
      marker = this.mainMapService.getRenderedMarkers()[0]; //the property from the user's last search activity, only when the search results returned a single property
    }

    if (marker) {
      if (marker.getPosition()) {
        fcSearchFocusState.centroid = marker.getPosition()!;
      } else {
        //this is because we are not allowing two markers to occupy the same location, as in the case of the user clicking on a property which already has a marker,
        //in this case we captured the clicked location in 'markerPosition'.
        fcSearchFocusState.centroid = marker.get('markerPosition');
      }

      if (this.mainMapService.isCondoMarker(marker)) {
        fcSearchFocusState.propertyType = 'condo';

        if (marker.get('isCondoUnit')) {
          let block: string = this.piiService.getCondoBlockNumber(marker.get('pin'));
          let results: SearchResult = await lastValueFrom(this.omnibarSearchService.getPropertiesByBlock(block, 20, 0), {defaultValue: new SearchResult()});
          if (results) {
            let pii: Pii = results.searchResult[0];
            fcSearchFocusState.condoAddress = pii?.address?.streetAddress;
            this.loggerService.logDebug(`detected condo building address ${fcSearchFocusState.condoAddress}`);
          }
        } else {
          fcSearchFocusState.condoAddress = marker.get('address');
        }
      } else {
        fcSearchFocusState.propertyType = 'freehold';
        fcSearchFocusState.freeholdAddress = marker.get('address');
      }

    } else {
      //is there a condo in the center of the map?
      let center: google.maps.LatLng = this.mainMapService.getMapCenter();
      let pii: Pii = await lastValueFrom(this.spatialServiceService.getPIIByLatLng(center.lat(), center.lng()), {defaultValue: new Pii()});
      this.loggerService.logDebug(`center of circle a condo? ${this.piiService.isCondo(pii)} lat[${center.lat()}] lng[${center.lng()}]`);

      fcSearchFocusState.centroid = center;
      if (pii) {
        if (this.piiService.isCondo(pii)) {
          fcSearchFocusState.propertyType = 'condo';
          fcSearchFocusState.condoAddress = pii?.address?.streetAddress;
          this.setSameCondoTooltipText(fcSearchFocusState.condoAddress);
          this.loggerService.logDebug(`detected condo building address ${fcSearchFocusState.condoAddress}`);
        } else {
          fcSearchFocusState.propertyType = 'freehold';
          let address: string = pii?.address?.fullAddress? pii.address.fullAddress : DataService.ADDRESS_NOT_AVAILABLE;
          fcSearchFocusState.freeholdAddress = address;
          this.setFreeholdTooltipText(fcSearchFocusState.freeholdAddress);
        }
      }
    }

    this.loggerService.logDebug(`can create circular search buffer? ${fcSearchFocusState.isCanCreateCircularSearchBuffer()}`);
    return fcSearchFocusState;
  }

  onSameCondoSearchChange = (event: any) => {
    this.sameCondoSearch = event.checked;

    if (this.sameCondoSearch) {
      //remove the circular search buffer
      setTimeout(async () => {

        let marker: google.maps.Marker | undefined = undefined;
        let condoPosition: Centroid;

        if (this.mainMapService.isClickedMarkersExist()) {
          marker = this.mainMapService.getClickedMarkers()[0];
        } else if (this.mainMapService.isRenderedMarkersExist()) {
          marker = this.mainMapService.getRenderedMarkers()[0];
        }

        if (marker) {
          condoPosition = new Centroid(marker.getPosition()!.lat(), marker.getPosition()!.lng());
        } else {
          condoPosition = this.getCircularSearchBufferCenter();
          if (condoPosition) {
            //render the condo polygon
            let pii: Pii = await lastValueFrom(this.spatialServiceService.getPIIByLatLng(condoPosition.latitude, condoPosition.longitude), {defaultValue: new Pii()});
            if (pii && this.piiService.isCondo(pii)) {
              this.loggerService.logDebug(`draw polygon of the condo at lat[${condoPosition.latitude}] lng[${condoPosition.longitude}]`);
              this.renderPIIPolygon(pii);
            }
          }
        }

        this.loggerService.logDebug(`clear circular search buffer since same condo search is active`);
        this.destroyCircularSearchBuffer();
        this.mainMapService.clearSearchComparablesCircleObjects();

        if (condoPosition) {
          setTimeout(() => {
            this.mainMapService.setMapZoomLevel(DataService.AUTOSUGGEST_SINGLE_SEARCH_RESULT_DEFAULT_ZOOM_LEVEL);
          }, 100);

          setTimeout(() => {
            this.mainMapService.panMapToCenter(new google.maps.LatLng(condoPosition.latitude, condoPosition.longitude));
          }, 100);
        }
      }, 100);

    } else {
      //restore the circular search buffer
      setTimeout(() => {
        if (this.selectedSearchBy == this.SEARCH_BY_RADIUS) {
          this.loggerService.logDebug(`re-create circular search buffer to ${this.getSelectedRadiusValue()} since same condo search is off`);
          this.mainMapService.adjustMapZoomLevelForRadiusSetting(this.getSelectedRadiusValue());

          setTimeout(async () => {
            this.createCircularSearchBuffer();

            //render the polygon of the property in the center of circular search area
            let center: Centroid = this.getCircularSearchBufferCenter();
            let pii: Pii = await lastValueFrom(this.spatialServiceService.getPIIByLatLng(center.latitude, center.longitude), {defaultValue: new Pii()});
            this.loggerService.logDebug(`draw polygon of the property at lat[${center.latitude}] lng[${center.longitude}]`);

            if (pii) {
              this.renderPIIPolygon(pii);
            }
          }, 100);

        }
      }, 100);
    }
  }

  hasSameCondoTooltipText() {
    return !_.isEmpty(this.sameCondoTooltipText);
  }

  private setSameCondoTooltipText = (text: string | null) => {
    if (!_.isEmpty(text)) {
      this.sameCondoTooltipText = 'If selected, search properties within<b> ' + text + '</b>.<br/>If not selected, search condo properties in and around this location.';
    } else {
      this.sameCondoTooltipText = '';
    }
  }

  hasFreeholdTooltipText() {
    return !_.isEmpty(this.freeholdTooltipText);
  }

  private setFreeholdTooltipText = (text: string | null) => {
    if (!_.isEmpty(text)) {
      this.freeholdTooltipText = 'Search properties around<b> ' + text + '</b>.';
    } else {
      this.freeholdTooltipText = '';
    }
  }

  private resetCircularSearchAreaDisplay = (renderPolygon: boolean) => {
    setTimeout(() => {
      this.findFreeholdOrCondoSearchFocus()
        .then(fcSearchFocusState => {
          if (fcSearchFocusState) {
            if (fcSearchFocusState.isCanCreateCircularSearchBuffer()) {
              this.sameCondoSearchOption = false;
              this.sameCondoSearch = false;
              this.setFreeholdTooltipText(fcSearchFocusState.freeholdAddress);
              this.mainMapService.setMapCenter(fcSearchFocusState.centroid);
              this.mainMapService.adjustMapZoomLevelForRadiusSetting(this.getSelectedRadiusValue());
              this.createCircularSearchBuffer();
            } else {
              this.sameCondoSearchOption = true;
              this.sameCondoSearch = true;
              this.setSameCondoTooltipText(fcSearchFocusState.condoAddress);
              this.mainMapService.setMapCenter(fcSearchFocusState.centroid);
              this.mainMapService.setMapZoomLevel(DataService.AUTOSUGGEST_SINGLE_SEARCH_RESULT_DEFAULT_ZOOM_LEVEL);
              this.mainMapService.clearSearchComparablesCircleObjects();
            }

            //render the polygon of the property in the center of the map, regardless of whether there is a subject property or not
            if (renderPolygon) {
              setTimeout(async () => {
                let mapCenter: google.maps.LatLng = this.mainMapService.getMapCenter();
                let pii: Pii = await lastValueFrom(this.spatialServiceService.getPIIByLatLng(mapCenter.lat(), mapCenter.lng()), {defaultValue: new Pii()});
                this.loggerService.logDebug(`draw polygon of the property at lat[${mapCenter.lat()}] lng[${mapCenter.lng()}]`);

                if (pii) {
                  this.renderPIIPolygon(pii);
                }
              }, 100);
            }
          }
        })
        .catch(e => {
          this.loggerService.logError(`error in creating circular search buffer`, e);
        })
    }, 100);
  }

  ngOnInit(): void {
    this.findComparablesTooltipText = this.user.hasLicensePackageId() ? DataService.SEARCH_COMPARABLES_FIND_TOOLTIP2_TEXT : DataService.SEARCH_COMPARABLES_FIND_TOOLTIP_TEXT;
    this.userAccessControls = this.userService.getUserAccessControl();
    this.maximumSearchPolygonsAllowed = this.user.comparableSalesMultiplePolygonSearchMaxCount;

    if (this.userAccessControls.hasAccessToComparableSearch) {

      this.screenManager.getObservableScreen(ScreenNameEnum.SEARCH_COMPARABLES_FORM)!
        .pipe(skip(1), takeUntil(this.ngUnsubscribe))
        .subscribe(visible => {
          if (visible) {
            this.screenManager.closeScreensWhenThisOpened(ScreenNameEnum.SEARCH_COMPARABLES_FORM);
            this.formVisible = true;
            this.visible.emit(true);

            //the map needs to reset the form fields when the form becomes visible, but only when the search results are not visible
            if (!this.screenManager.isScreenVisible(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS)) {
              this.resetFormData();

              if (this.selectedSearchBy == this.searchByTypes[0]) {
                setTimeout(() => {
                  if (!this.mainMapService.isMarkersExist()) {
                    this.mainMapService.clearAllRenderedMapObjects(); //clears the previous circle search area and results markers, if any.
                  }

                  //by default, display the circular search area only if the focus of the search is not a condo
                  this.destroyCircularSearchBuffer();
                  this.resetCircularSearchAreaDisplay(true);
                }, 100);
              }
            }

            //todo: be able to specify which tab (search criteria or filter criteria) to open
            setTimeout(() => {
              this.activeTabIndex = 0;
              // for (let tab of this.accordion.tabs) {
              //   tab.selected = true;
              //   break;
              // }
            }, 100);

            if (!this.screenManager.isScreenVisible(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS)) {
              setTimeout( () => {
                if (this.userPreferenceForSearchByArea == this.SEARCH_BY_POLYGON) {
                  this.drawUserPolygon();
                }
              }, 100);
            }

          } else {
            this.visible.emit(false);
            this.formVisible = false;
            this.searchBusyIndicatorService.hideMainMapBusyIndicator();
            this.mainMapService.cancelSearchComparablesPolygonDrawing();
            this.mainMapService.toggleSearchComparablesDrawPolygonMode(false);
            this.searchComparablesFormService.promptAddPolygonSearchArea(false);

            this.screenManager.hideScreen(ScreenNameEnum.MAP_LAYER_POPUP);
            this.screenManager.hideScreen(ScreenNameEnum.MAP_TYPE_POPUP);

            if (this.searchComparablesResultService.isSearchResultsPayloadEmpty()) {
              this.destroyCircularSearchBuffer();
              this.mainMapService.clearSearchComparablesObjects();
              this.mainMapService.clearSearchComparableMarkers();
              this.screenManager.hideScreen(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS);
            }
          }
        })

      this.searchComparablesFormService.getSearchResultsSnapshotRecoveryObservable()
        .pipe(skip(1), takeUntil(this.ngUnsubscribe))
        .subscribe(recoverySnapshot => {
          if (recoverySnapshot) {
            this.initializeFormFromSnapshot(recoverySnapshot);
          }
        });

      this.lroPolygonsService.lroState$
        .pipe(skip(1), takeUntil(this.ngUnsubscribe))
        .subscribe(lroState => {
          this.removeAllSelectedMunicipalities();
          this.populateLroMunicipalities(lroState.lroId);
          this.populateLroPropertyCodes(lroState.lroId);  //TODO: do property codes change across lros? if not, cache this.

          if (this.formVisible) {
            this.mainMapService.adjustMapZoomLevelForRadiusSetting(this.getSelectedRadiusValue());

            //the map needs to show the search buffers when the lro changes
            setTimeout(() => {
              if (this.selectedSearchBy == this.searchByTypes[0]) {
                //if (this.isCanCreateCircularSearchBuffer()) {
                //todo: review this
                this.createCircularSearchBuffer();
                //}
              }
            }, 100);
          }
        });

      this.searchComparablesFormService.getMultiPolygonSearchObservable()
        .pipe(skip(1), takeUntil(this.ngUnsubscribe))
        .subscribe(addNewPolygon => {
          if (addNewPolygon && this.screenManager.isScreenVisible(ScreenNameEnum.SEARCH_COMPARABLES_FORM)) {
            this.gaService.openModal('SearchComparablesPromptForMorePolygons');
            const dialogData = new ConfirmDialogData('Search Comparables', [DataService.ADD_POLYGON_PROMPT], 'Yes', 'No');
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {data: dialogData})
              .afterClosed()
              .subscribe((response) => {
                  if (response == DialogReturnTypeEnum.FIRST_BUTTON) {
                    //yes, let the user draw the polygon
                    this.drawUserPolygon();
                  } else {
                    //no, stop prompting the user to more polygons
                    this.searchComparablesFormService.promptAddPolygonSearchArea(false);
                    setTimeout(() => {
                      this.mainMapService.setFocusOnUserDrawnPolygons();
                    }, 100);
                  }
                }
              );
          }
        });

      this.mainMapService.mainMapClickedForSearchComparablesObservable$
        .pipe(skip(1), takeUntil(this.ngUnsubscribe))
        .subscribe(async (coordinates) => {
          if (this.screenManager.isScreenVisible(ScreenNameEnum.SEARCH_COMPARABLES_FORM)) {
            if (this.selectedSearchBy == this.SEARCH_BY_RADIUS) { //attempt to draw the search circle only when search by radius is selected
              this.resetCircularSearchAreaDisplay(false);
            }
          }
        });
    }

    this.customValidators = [];
    if (this.isMpsUser()) {
      this.customValidators.push(
        this.customFieldRangeValidator('minDate', 'maxDate'),
        this.buildAreaFieldRangeValidator('minBuildTotalArea', 'maxBuildTotalArea'),
        this.customFieldRangeValidator('minAmount', 'maxAmount'),
        this.customFieldRangeValidator('minAssessmentAmount', 'maxAssessmentAmount'),
        this.customFieldRangeValidator('minArea', 'maxArea'),
        this.customFieldRangeValidator('minYearBuilt', 'maxYearBuilt'));
    } else {
      this.customValidators.push(
        this.customFieldRangeValidator('minDate', 'maxDate'),
        this.customFieldRangeValidator('minAmount', 'maxAmount'),
        this.customFieldRangeValidator('minArea', 'maxArea'),
        this.customFieldRangeValidator('minYearBuilt', 'maxYearBuilt'));
    }

    this.createFormGroup();
  }

  ngAfterViewInit(): void {
  }

  get resultsScreenVisible() {
    return this.screenManager.isScreenVisible(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS);
  }

  amountSliderValueChanged($event: MatSliderDragEvent, controlName: string) {
    setTimeout(() => {
      if ($event.value == this.INFINITY_SLIDER && ['maxAmount', 'maxAssessmentAmount'].includes(controlName)) {
        this.formControls[controlName]?.setValue(this.INFINITY);
      } else {
        this.formControls[controlName]?.setValue($event.value);
      }
    }, 100);
  }

  optionSelected($event: MatAutocompleteSelectedEvent, control: string) {
    this.updateSliderValue(control, $event.option.value);
  }

  formatLabel = (value: number): string => {
    if (value == this.INFINITY_SLIDER) {
      return this.INFINITY_VALUE_DISPLAY;
    } else {
      return `${value}`;
    }
  }
}
