import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DemographicsChartsUtility} from "../../../../../../shared/utility/fusion-charts/demographics-charts.utility";
import {DemographicsCultural} from "../../../../../../core/model/demographics/demographics-cultural";
import {DemographicsMetadata} from "../../../../../../core/model/demographics/demographics-metadata";
import {DataService} from "../../../../../../shared/service/data.service";
import {BaseChartComponent} from "../../../../../../core/component/base-chart/base-chart.component";

@Component({
  selector: 'gema3g-dominant-language',
  templateUrl: './dominant-language.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./dominant-language.component.scss']
})
export class DominantLanguageComponent extends BaseChartComponent {

  @Input() cultural = new DemographicsCultural();
  @Input() metadata: DemographicsMetadata[];

  override setHeightWidth() {
    //this.defaultWidth = "546";
    this.defaultHeight = "177"
    this.minWidth = 230;
    this.maxWidth = 546;
    this.minHeight = 177;
    this.maxHeight = 177;
  }

  override loadChartData() {
    if (this.cultural) {
      this.nbhDataSource = DemographicsChartsUtility.dominantLanguageDataSource(DataService.DEFAULT_CHART_FONT, 'NBH', this.cultural?.PRCDDA, this.metadata, window.innerWidth);
      this.comDataSource = DemographicsChartsUtility.dominantLanguageDataSource(DataService.DEFAULT_CHART_FONT, 'COM', this.cultural?.FSA, this.metadata, window.innerWidth);
      this.cityDataSource = DemographicsChartsUtility.dominantLanguageDataSource(DataService.DEFAULT_CHART_FONT, 'CITY', this.cultural?.PRCDCSD, this.metadata, window.innerWidth);
    }
  }

}
