import {Pii} from "../property/pii";
import {ConduitData} from "./conduit-data";
import {BaseModel} from "../base-model";
import {StringUtility} from "../../../shared/utility/string-utility";

export class CondoUnit extends BaseModel{
  commonName: string;
	conduitDatas: ConduitData[];
	corporationId: string;
	corporationName: string;
	createDate: string;
	instrumentNum: string;
	levelNumber: string;
	pii: Pii;
	pin: string;
	suitePin: string;
	type: string;
	unitNumber: string;
	updateDate: string;

  get fullAddress(): string {

    let result: string = '';

    if (this.pii?.address?.unitNumber) {
      result += this.pii.address.unitNumber + ' - ';
    }
    if (this.pii?.address?.streetName){
      //result += `${this.pii.address.streetNumber}${this.pii.address.streetSuffix} ${this.pii.address.streetName.toUpperCase()} ${this.pii.address.streetDesignation}. ${this.pii.address.streetDirection}, ${this.pii.address.city.toUpperCase()}, ${this.pii.address.postalCode}`;
      result = StringUtility.safeAppend(result, this.pii.address.streetNumber);
      result = StringUtility.safeAppend(result, this.pii.address.streetSuffix, ' ');
      result = StringUtility.safeAppend(result, this.pii.address.streetName?.toUpperCase(), ' ' );
      result = StringUtility.safeAppend(result, this.pii.address.streetDesignation, ' ' );
      result += '.';
      result = StringUtility.safeAppend(result, this.pii.address.streetDirection, ' ', ',');
      result = StringUtility.safeAppend(result, this.pii.address.city?.toUpperCase(), ' ', ',');
      result = StringUtility.safeAppend(result, this.pii.address.postalCode, ' ');
    } else {
      result += "N/A";
    }
    return result;
  }


  get arn(): string {
    return (this.pii?.arns?.length > 0)  ?  this.pii.arns[0] : '';
  }

  get hasPinOrArn(): boolean {
    return !!(this.pin || this.arn);
  }

  get legalDescription(): string {
    return ( this.pii) ? this.pii.shortLegalDescription : '';
  }

  get unitType(): string {
    return ( this.pii) ?this.pii.unitType :  '';
  }

  get streetName(): string {
    if (this.pii?.address?.streetName) {
      return this.pii.address.streetName;
    }
    return '';
  }

  get streetNumber(): string {
    if ( this.pii?.address?.streetNumber) {
      return this.pii.address.streetNumber;
    }
    return '';
  }

  // UI only
  detailsExpanded: boolean = false;
  arnExpanded: boolean = false;
  showExpansionImages: boolean = false;
}
