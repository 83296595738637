<div class="gema3g-ao-container">
  <div class="gema3g-ao-container__inner">
    <div class="col-0 mb-9">
      <mat-card class="purchases-card pt-4">
        <mat-card-header class="display-flex">
          <div>
            <h4 id="bp-h4-recent">Recent Purchases</h4>
          </div>
          <div class="flex-1"></div>
          <div>
            <a id="bp-link-all" class="anchor" routerLink="../payment-history">All purchases</a>
          </div>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content class="purchases-card-content">
          @if (isLoadingTransactions) {
            <mat-progress-bar id="bp-progress-bar" mode="indeterminate"></mat-progress-bar>
          }
          <table id="bp-tbl" [dataSource]="dataSource" class="mat-elevation-z8 standard-table" mat-table>
            <ng-container matColumnDef="date">
              <th id="bp-tbl-hdr-date" *matHeaderCellDef mat-header-cell>Date</th>
              <td id="bp-tbl-data-date-{{element.transactionId}}" *matCellDef="let element" mat-cell>
                {{element.date | date : 'mediumDate'}}
                <span class="time">
                  |  {{element.date | date:'h:mm a' : '+0000'}}
                </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="price">
              <th id="bp-tbl-hdr-price" *matHeaderCellDef mat-header-cell>Price</th>
              <td id="bp-tbl-data-price-{{element.transactionId}}" *matCellDef="let element" mat-cell>
                {{element.price | currency}}
              </td>
            </ng-container>
            <ng-container matColumnDef="numberOfProducts">
              <th id="bp-tbl-hdr-num" *matHeaderCellDef mat-header-cell># of Products</th>
              <td id="bp-tbl-data-num-{{element.transactionId}}" *matCellDef="let element" mat-cell> {{element.products.length}} </td>
            </ng-container>
            <ng-container matColumnDef="productDetails">
              <th id="bp-tbl-hdr-details" *matHeaderCellDef mat-header-cell>Product Details</th>
              <td id="bp-tbl-data-details-{{element.transactionId}}" *matCellDef="let element" mat-cell>
                {{element.getAllProducts()}}
              </td>
            </ng-container>
            <ng-container matColumnDef="transactionId">
              <th id="bp-tbl-hdr-tid" *matHeaderCellDef mat-header-cell>Transaction ID</th>
              <td *matCellDef="let element" mat-cell>
                <a id="bp-tbl-link-tid-{{element.transactionId}}" (click)="redirectToGWHInvoicePage(element.transactionId)" class="anchor">{{element.transactionId}}</a>
              </td>
            </ng-container>
            <tr id="bp-tbl-row-hdr" *matHeaderRowDef="displayedColumns" mat-header-row></tr>
            <tr id="bp-tbl-row-{{row.transactionId}}" *matRowDef="let row;
        let odd = odd;
        columns: displayedColumns;" mat-row></tr>
          </table>
          <mat-divider></mat-divider>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-0">
      @if (userPaymentMethod && userPaymentMethod.cardCompany) {
        <mat-card class="purchases-card pt-4">
          <mat-card-header class="display-flex">
            <div>
              <h4 id="bp-h4-ppm">Primary Payment Method</h4>
            </div>
            <div class="flex-1"></div>
            <div>
            </div>
          </mat-card-header>
          <mat-divider></mat-divider>
          <mat-card-content>
            <div class='payment-method-body'>
              @if (isLoadingVaultedCards) {
                <div class="payment-method-loading"><img id="bp-img-spinner" alt="Loading..." src="assets/img/icon-refresh.gif"/></div>
              }
              @if (!isLoadingVaultedCards && userPaymentMethod && userPaymentMethod.cardCompany) {
                <div class='payment-method-primary'>
                  <div class="top">
                    <div class="cc-company"><span id="bp-company">{{userPaymentMethod.cardCompany}}</span></div>
                  </div>
                  <div class="middle">
                    <div class="cc-logo">
                      @switch (userPaymentMethod.cardCompany) {
                        @case ('Visa') {
                          <img id="bp-img-visa" class="cc-img" src="assets/img/mygeo-icons/cardtype_0000_Visa.png"/>
                        }
                        @case ('Mastercard') {
                          <img id="bp-img-mc" class="cc-img" src="assets/img/mygeo-icons/cardtype_0001_Mastercard.png"/>
                        }
                        @case ('AMEX') {
                          <img id="bp-img-amex" class="cc-img" src="assets/img/mygeo-icons/cardtype_0002_Amex.png"/>
                        }
                      }
                    </div>
                    <div class="cc-number"><span id="bp-number">{{userPaymentMethod.cardNumber}}</span></div>
                  </div>
                  <div class="bottom">
                    <div class="cc-name"><span id="bp-name">{{userPaymentMethod.nameOnCard}}</span></div>
                    <div class="cc-expiry"><span id="bp-expiry">Expiry {{userPaymentMethod.formattedExpireDate}}</span></div>
                  </div>
                  <div class="action">
                    <div class="cc-remove">
                      <a id="bp-link-remove" (click)="deleteCreditCard(userPaymentMethod.externalPaymentId)" class='anchor'>Remove Card</a>
                    </div>
                  </div>
                </div>
              }
            </div>
          </mat-card-content>
        </mat-card>
      }
    </div>
  </div>
</div>
