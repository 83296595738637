<section class="main flex flex-col md:flex-row">

  @for (testimonial of landingPageSettings?.landingPageTestimonials?.testimonials; track testimonial) {
    <div class="box flex w-9/10 md:w-2/5">
      <div class="icon-box flex-col">
        <img id="estore-img-front-ftr-{{testimonial.agentName}}" alt="" src="{{testimonial.imageUrl}}">
      </div>
      <div id="estore-front-ftr-testimonial-{{testimonial.agentName}}" class="testimonial-box flex-col text-xs md:text-sm 2xl:text-base">
        {{testimonial.content}}
        <br>
          <span id="estore-front-ftr-agent-{{testimonial.agentName}}"> {{testimonial.agentName}}</span>
        </div>
      </div>
    }

  </section>
