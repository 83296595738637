import {BaseModel} from "../base-model";

export class LroDefaultPolygon extends BaseModel {

  constructor(polygon?: LroDefaultPolygon) {
    super(polygon);
    if (polygon?.list?.length) {
      this.list = polygon.list.map(value => {
        return new LroPolygon(value);
      });
    }
  }

  id: string;
  list: LroPolygon[] = [];
}

export class LroPolygon extends BaseModel {

  constructor(lroPolygon?: LroPolygon) {
    super(lroPolygon);
    if (lroPolygon) {
      this.polygon = lroPolygon.polygon.map(value => {
        return new LroCoordinate(value);
      });
    }
  }

  spatialIdentifier: string;
  name: string;
  spatialLayer: string;
  centroid: LroCoordinate;
  area: number;
  polygon: LroCoordinate[];
}

export class LroCoordinate extends BaseModel {
  lng: number;
  lat: number;
}


