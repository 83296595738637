<div class="demographics-header">
  <h4 id="dg-h4-hh">
    Households
  </h4>
</div>
@if (demographicsData) {
  <div class="flex flex-col md:flex-row">
    <div class="flex md:w-1/2 demographics-right-border py-9">
      <gema3g-structure-detail
        [houseHold]="demographicsData.houseHold"
        [selectedArea]="selectedArea"
        class="w-full"
        >
      </gema3g-structure-detail>
    </div>
    <div class="flex md:w-1/2 py-9">
      <gema3g-ownership [cityTotalPopulation]="demographicsData.population?.PRCDCSD?.totalPopulation"
        [houseHold]="demographicsData.houseHold"
        [selectedArea]="selectedArea"
        class="w-full"
        >
      </gema3g-ownership>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="flex flex-col md:flex-row">
    <div class="flex md:w-1/2 py-9">
      <gema3g-home-age [cityTotalPopulation]="demographicsData.population?.PRCDCSD?.totalPopulation"
        [houseHold]="demographicsData.houseHold"
        [selectedArea]="selectedArea"
        class="w-full"
        >
      </gema3g-home-age>
    </div>
    <div class="flex md:w-1/2 py-9">
      <gema3g-structural-type [cityTotalPopulation]="demographicsData.population?.PRCDCSD?.totalPopulation"
        [houseHold]="demographicsData.houseHold"
        [selectedArea]="selectedArea"
        class="w-full"
        >
      </gema3g-structural-type>
    </div>
  </div>
}
