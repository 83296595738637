import {ComparablesSalesReportPrintParamSection} from "./comparables-sales-report-print-param-section";
import {BaseModel} from "../../../base-model";

export class ComparablesSalesReportPrintParam implements BaseModel{
  subjectPropertySections: ComparablesSalesReportPrintParamSection[];
  comparableSections: ComparablesSalesReportPrintParamSection[];

  constructor(data?: ComparablesSalesReportPrintParam) {
    if (data) {
      this.subjectPropertySections = data.subjectPropertySections.map((section: ComparablesSalesReportPrintParamSection) => new ComparablesSalesReportPrintParamSection(section));
      this.comparableSections = data.comparableSections.map((section: ComparablesSalesReportPrintParamSection) => new ComparablesSalesReportPrintParamSection(section));
    }
  }

  getSelectedSections(): string {
    return    this.subjectPropertySections.filter(section => section.checked)
      .concat(this.comparableSections.filter(section => section.checked))
      .map(section => section.value).join(',');
  }
}
