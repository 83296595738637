import { AfterViewInit, Component, HostListener, inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { User } from "../../../core/model/user/user";
import { lastValueFrom, Observable } from "rxjs";
import { UserService } from "../../../shared/service/user.service";
import { DataService } from "../../../shared/service/data.service";
import { UserProfileTransfer } from "../../../core/model/user/user-profile-transfer";
import { FileUtility } from "../../../shared/utility/file.utility";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ComponentCanDeactivate } from "../../../core/modified-form/component-can-deactivate";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { defaultErrorMatSnackBarConfig, defaultMatSnackBarConfig, LocalStorageKey } from "../../../shared/constant/constants";
import { LoggerService } from '../../../shared/service/log/logger.service';
import { UserProfileComponentModeEnum } from '../../../core/enum/user-profile-component-mode.enum';
import { AuthenticationService } from '../../../shared/service/authentication.service';
import { SearchMethods } from '../../preference/preference/preference-mapping';
import { MainMapService } from '../../../../app/home/home/main-map/main-map.service';
import { NewUserProfileComponent } from '../../../../app/login/new-user-profile/new-user-profile.component';
import { SnackBarService } from "../../../shared/service/snack-bar.service";
import { ErrorUtil } from "../../../shared/service/error.util";


@Component({
  selector: 'gema3g-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, ComponentCanDeactivate, AfterViewInit {
  constructor() {
    this.lros = this.mainMapService.getLroPolygonService().getLroKeyValuePairs();
  }

  private fromBuilder = inject(FormBuilder);
  private userService = inject(UserService);
  private dataService = inject(DataService);
  private authService = inject(AuthenticationService);
  private fileUtility = inject(FileUtility);
  private sanitizer = inject(DomSanitizer);
  private loggerService = inject(LoggerService);
  private mainMapService = inject(MainMapService);
  snackBarService = inject (SnackBarService);

  @Input() formMode: UserProfileComponentModeEnum = UserProfileComponentModeEnum.EXISTING_USER;

  profileForm: FormGroup;
  provinces: string[];
  user: User = new User();
  userProfileTransfer: UserProfileTransfer;
  allowedPhotoExtensions: string[] = ['jpg', 'jpeg', 'png'];
  selectedPhotoExtensionsValid: boolean = true;
  selectedPhotoSizeValid: boolean = true;
  selectedProfilePhoto: any;
  saving: boolean = false;
  searchMethods = SearchMethods;
  defaultSearchMethod: string = SearchMethods[0].value;
  defaultLro: string = DataService.DEFAULT_LRO;
  lros: any[];

  ngOnInit(): void {
    this.provinces = this.dataService.provinces;
    this.profileForm = this.fromBuilder.group({
      firstName: ['', [Validators.required, Validators.maxLength(23)]],
      lastName: ['', [Validators.required, Validators.maxLength(23)]],
      position: ['', [Validators.maxLength(50)]],
      email: ['', [Validators.required, Validators.email, Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]{1,}$/)]],
      cellphone: ['', [Validators.maxLength(20), Validators.pattern(/^(\+1\s?|1\s?)?((\([0-9]{3}\)\s|[0-9]{3}\-)[0-9]{3}\-[0-9]{4}|[0-9]+)$/)]],
      twitterUrl: ['', Validators.maxLength(200)],
      linkedInUrl: ['', Validators.maxLength(200)],
      company: ['', [Validators.maxLength(75)]],
      address: ['', []],
      city: ['', [Validators.maxLength(75)]],
      province: ['', []],
      postalCode: ['', [Validators.pattern(/^[A-Za-z][0-9][A-Za-z]\s?[0-9][A-Za-z][0-9]$/)]],
      homePageURL: ['', [Validators.maxLength(250)]],
      phone: ['', [Validators.maxLength(20), Validators.pattern(/^(\+1\s?|1\s?)?((\([0-9]{3}\)\s|[0-9]{3}\-)[0-9]{3}\-[0-9]{4}|[0-9]+)(\s?x[0-9]+)?$/)]],
      fax: ['', [Validators.maxLength(15)]],
      receiveMail: ['', []],
      removeProfilePhoto: ['', []],
      searchMethod: ['', []],
      lro: ['', []],
      userId: [localStorage.getItem(LocalStorageKey.auth0UserId), []]

    });
    this.loadUser();
  }

  ngAfterViewInit(): void {
    if (this.isUserProfileFormForNewUser()) {
      //visually prompt the new user to fill in these required fields
      this.profileForm.get('firstName')?.markAsTouched();
      this.profileForm.get('lastName')?.markAsTouched();
      this.profileForm.get('email')?.markAsTouched();
    }
  }

  async loadUser() {
    this.user = await lastValueFrom(this.userService.getUser(), {defaultValue: new User()});
    this.userProfileTransfer = new UserProfileTransfer(this.user.userProfile);

    if (this.isUserProfileFormForExistingUser()) {
      this.profileForm.patchValue(this.userProfileTransfer);
    }
  }

  get formControls() {
    return this.profileForm.controls;
  }

  async submitProfile() {

    if (this.profileForm.valid) {
      try {
        this.saving = true;
        this.profileForm.controls['removeProfilePhoto']?.patchValue(this.userProfileTransfer.removeProfilePhoto);
        const userProfileFormData = new FormData();
        userProfileFormData.append("uploadedProfilePhoto", this.selectedProfilePhoto);
        userProfileFormData.append('userProfileTransfer', new Blob([JSON.stringify(this.profileForm.getRawValue())], {
          type: "application/json"
        }));

        const saved: User = await lastValueFrom(this.userService.saveUserProfile(userProfileFormData, this.formMode, this.profileForm.get('firstName') + ' ' + this.profileForm.get('lastName')));

        if (saved) {
          if(saved.auth0ResponseCode != null && saved.auth0ResponseCode != 200){
            this.snackBarService.displaySnackBarError(saved.auth0ReasonPhrase);
            if (this.isUserProfileFormForNewUser()) {
              this.authService.setUserProfileInitialized(false);
            }
          } else {
            this.openSnackBar(true);
            this.profileForm.markAsPristine();
            await this.userService.reloadUser();
            await this.loadUser();
            this.selectedProfilePhoto = null;

            if (this.isUserProfileFormForNewUser()) {
              localStorage.setItem(LocalStorageKey.INIT_PROFILE_SEARCH_METHOD_LOCAL_STORAGE_KEY, this.formControls['searchMethod'].value);
              localStorage.setItem(LocalStorageKey.INIT_PROFILE_LRO_LOCAL_STORAGE_KEY, this.formControls['lro'].value);
              await this.saveUserPreferences();
              this.authService.setUserProfileInitialized(true);
            }
          }
        } else {
          this.openSnackBar(false);
          if (this.isUserProfileFormForNewUser()) {
            this.authService.setUserProfileInitialized(false);
          }
        }
      } catch (e) {
        this.loggerService.logError('Error saving user profile', e);
        this.openSnackBar(false);
        if (this.isUserProfileFormForNewUser()) {
          this.authService.setUserProfileInitialized(false);
        }

      } finally {
        this.saving = false;
      }

    } else {
      this.loggerService.logInfo("Form not valid.")
    }
  }

  async saveUserPreferences(){
    let userPreference = await lastValueFrom(this.userService.getPreferences());
    userPreference.genericPreference.lro = this.formControls['lro'].value;
    userPreference.genericPreference.searchMethod =  this.formControls['searchMethod'].value;
    const saved = await lastValueFrom(this.userService.saveUserPreferences(userPreference));
    if(!saved){
      this.snackBarService.displaySnackBarError(ErrorUtil.COULD_NOT_SAVE_PREFS);
    }
  }

  openSnackBar(success: boolean) {
    let action: string = (this.formMode == UserProfileComponentModeEnum.EXISTING_USER)? 'saved' : 'created';

    if (success) {
      this.snackBarService.displaySnackBarMessage(`Your profile has been ${action}.`);
    } else {
      this.snackBarService.displaySnackBarError(`Your profile could not be ${action} at this time, please try again later.`);
    }
  }

  get profilePhotoUrl(): SafeUrl | string {
    if (this.selectedPhotoSizeValid && this.selectedPhotoExtensionsValid && this.selectedProfilePhoto) {
      return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.selectedProfilePhoto));
    }
    if (!this.userProfileTransfer?.removeProfilePhoto && this.user?.profilePhotoUrl) {
      return this.user.profilePhotoUrlRandomized!;
    }
    return this.dataService.defaultProfilePhotoSource;
  }

  get hasProfilePhoto(): boolean {
    if ((this.selectedPhotoSizeValid && this.selectedPhotoExtensionsValid && this.selectedProfilePhoto) || (!this.userProfileTransfer?.removeProfilePhoto && this.user?.profilePhotoUrl)) {
      return true;
    }
    return false;
  }

  removePhoto() {
    this.userProfileTransfer.removeProfilePhoto = true;
    this.selectedProfilePhoto = undefined;
    this.profileForm.markAsDirty({onlySelf: true});
  }

  onFileSelected(event: Event) {
    if (event && event.target) {
      // @ts-ignore
      const file = event?.target?.files[0];
      const extension = this.fileUtility.getExtension(file.name);
      this.selectedPhotoExtensionsValid = this.allowedPhotoExtensions.some(value => value.toLowerCase() == extension?.toLowerCase());
      this.selectedPhotoSizeValid = file.size < (500 * 1024);
      if (this.selectedPhotoSizeValid && this.selectedPhotoExtensionsValid) {
        this.selectedProfilePhoto = file;
        this.userProfileTransfer.removeProfilePhoto = false;
        this.profileForm.markAsDirty({onlySelf: true});
      }
    }
  }

  get saveButtonDisabled(): boolean {
    return !this.profileForm?.valid || !this.profileForm?.dirty || this.saving;
  }

  isUserProfileFormForExistingUser = (): boolean => {
    return this.formMode == UserProfileComponentModeEnum.EXISTING_USER;
  }

  isUserProfileFormForNewUser = (): boolean => {
    return this.formMode == UserProfileComponentModeEnum.NEW_USER;
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean | Observable<boolean> {
    return !this.profileForm.dirty;
  }
}
