import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BaseMap } from "../../../../../core/component/base-map/base-map";
import { Polygon } from "../../../../../core/model/property/polygon";
import { MapTileEnum } from "../../../../../core/enum/map-tile-enum";
import { Centroid } from "../../../../../core/model/spatial/centroid";
import { PropertyDetail } from "../../../../../core/model/property/property-detail";
import { takeUntil } from "rxjs";

@Component({
  selector: 'gema3g-map-parcel',
  standalone: true,
  imports: [],
  templateUrl: './map-parcel.component.html',
  styleUrl: './map-parcel.component.scss'
})
export class MapParcelComponent extends BaseMap implements OnInit, AfterViewInit {

  constructor() {
    super();
    this.componentPrefix = 'parcel';
    this.parentMapContainerElementId = 'parcel-map-container-id';
    this.mapCopyUINotify = true;
  }

  @ViewChild('parcelMapContainer', {static: false}) parcelElementRef: ElementRef;
  assessmentLine: google.maps.Polyline;
  propertyReportpolygon: google.maps.Polygon;

  private initializeDrawAssessmentListener() {
    this.mainMapService.drawAssessmentRequest$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((arn) => {
        this.mapAssessmentOnParcelMap(arn);
      });
  }

  mapAssessmentOnParcelMap(arn: string) {
    let assessment = this.subjectProperty?.assessments?.find(value => value.rollNumber == arn);
    if (assessment) {
      if (this.propertyReportpolygon) {
        this.propertyReportpolygon.setMap(null);
      }
      this.propertyReportpolygon = this.mainMapService.drawPropertyReportPolygon(assessment.spatial[0]?.polygon, '#ff9c00', 0, 0, '#000000', 0.10, this.googleMap);

      if (this.assessmentLine) {
        this.assessmentLine.setMap(null);
      }

      this.initializeMap(); //reset the map with the right boundaries and center for the new assessment
      setTimeout(() => {
        this.assessmentLine = this.mainMapService.drawAssessment(assessment.spatial[0]?.polygon, this.googleMap);
      }, 100);
    }
  }

  override initializeMap = async () => {
    this.loggerService.logDebug("initializing parcel map");
    try {
      if (this.parcelElementRef !== undefined) {
        let centroid = await this.propertyReportSearchService.getCentroidForMap(this.subjectProperty);
        let lat = (await centroid).latitude;
        let lng = (await centroid).longitude;

        this.googleMap = new google.maps.Map(this.parcelElementRef.nativeElement, await this.getMapOptions(this.subjectProperty, google.maps.MapTypeId.ROADMAP));
        
        let mapControls = this.addMapControls(true);
        // @ts-ignore
        this.googleMap.controls[google.maps.ControlPosition.TOP_RIGHT].push(mapControls);

        super.initializeMap();
        
        setTimeout(() => {
          this.propertyReportMapService.renderPolygon(this.googleMap, this.getPolygon(this.subjectProperty));
          this.mainMapService.addSubjectPropertyRegistryMapLayer(this.googleMap, MapTileEnum.ASSESSMENT_TILE);
          this.mainMapService.addPinInfoBox(this.subjectProperty?.pii?.pin, this.googleMap, lat!, lng!);
          this.subjectProperty?.assessments?.forEach(arn => {
            this.mainMapService.addArnInfoBox(arn.rollNumber, <Centroid>arn.spatial[0]?.centroid, lat!, lng!, this.googleMap);
          })
        }, 100);
      } else {
        this.loggerService.logError('Registry :: parcelElementRef not available yet');
      }
    } catch (e) {
      this.loggerService.logError(`error initializing parcel map of pin ${this.subjectProperty?.pii?.pin}`);
    }
  }


  ngAfterViewInit(): void {
    this.initializeMap();
    this.startListenerOnFullScreen(this.parcelElementRef);
    this.initializeDrawAssessmentListener();
  }

}
