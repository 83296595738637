import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrimPipe } from './pipe/trim.pipe';
import { ShortDatePipe } from './pipe/short-date.pipe';
import { ClickCursorDirective } from "./directive/cursor-on-click.directive";
import { CutTextPipe } from "./pipe/cut-text.pipe";
import { MetersToFeetPipe } from './pipe/meters-to-feet.pipe';
import { SquareMetersToSquareFeetPipe } from './pipe/sq-meters-to-sq-feet.pipe';
import { MetersToKilometersPipe } from './pipe/meters-to-kilometers.pipe';
import { MetersToMilesPipe } from './pipe/meters-to-miles.pipe';
import { SquareMetersToSquareFeetWithUomPipe } from "./pipe/square-meter-to-square-feet-with-uom.pipe";
import { MetersToFeetWithUomPipe } from "./pipe/meters-to-feet-with-uom.pipe";
import { MediumDatePipe } from "./pipe/medium-date.pipe";
import { SquareMetersToAcersWithUomPipe } from "./pipe/square-meter-to-acres-with-uom.pipe";
import { MetersToMilesWithUomPipe } from "./pipe/meters-to-miles-with-uom.pipe";
import { CompactNumberPipe } from './pipe/compact-number.pipe';
import {CapitalizePipe} from "./pipe/capitalize.pipe";
import {NgxPrintModule} from "ngx-print";


@NgModule({
  declarations: [
    TrimPipe,
    ShortDatePipe,
    MediumDatePipe,
    ClickCursorDirective,
    CutTextPipe,
    MetersToFeetPipe,
    SquareMetersToSquareFeetPipe,
    MetersToKilometersPipe,
    MetersToMilesPipe,
    SquareMetersToSquareFeetWithUomPipe,
    SquareMetersToAcersWithUomPipe,
    MetersToFeetWithUomPipe,
    MetersToMilesWithUomPipe,
    CompactNumberPipe,
    CapitalizePipe
  ],
  imports: [
    CommonModule,
    NgxPrintModule
  ],
  exports: [
    TrimPipe,
    ShortDatePipe,
    MediumDatePipe,
    ClickCursorDirective,
    CutTextPipe,
    MetersToFeetPipe,
    SquareMetersToSquareFeetPipe,
    MetersToKilometersPipe,
    MetersToMilesPipe,
    SquareMetersToSquareFeetWithUomPipe,
    SquareMetersToAcersWithUomPipe,
    MetersToFeetWithUomPipe,
    MetersToMilesWithUomPipe,
    CompactNumberPipe,
    CapitalizePipe
  ]
})
export class SharedModule {
}
