import {Component, inject, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UserAccessControl} from "../../../model/user/user-access-control";
import {UserService} from "../../../../shared/service/user.service";
import {LoggerService} from '../../../../shared/service/log/logger.service';
import {ComparablesReportService} from "../../../../shared/service/comparables-report.service";
import {lastValueFrom} from "rxjs";
import {ComparablesSalesReportPrintParam} from "../../../model/comparables/report/print/comparables-sales-report-print-param";

@Component({
  selector: 'gema3g-comparables-report-sections-print',
  templateUrl: './comparables-report-sections-print.html',
  styleUrls: ['./comparables-report-sections-print.scss']
})
export class ComparablesReportSectionsPrint implements OnInit {
  userAccessControls: UserAccessControl;

  constructor(public dialogRef: MatDialogRef<ComparablesReportSectionsPrint>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private userService: UserService,
              private loggerService: LoggerService) {
    this.userAccessControls = this.userService.getUserAccessControl();
  }

  comparableSaleReportService = inject(ComparablesReportService);
  sections: ComparablesSalesReportPrintParam;

  async ngOnInit(): Promise<void> {
    this.sections = await lastValueFrom((this.comparableSaleReportService.getPrintParamSections()));
  }


}
