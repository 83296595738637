import { HttpClient } from '@angular/common/http';
import { baseUrl } from "../system";
import {inject, Injectable} from '@angular/core';
import {map, catchError, throwError, Observable, of} from 'rxjs';
import * as _ from 'lodash';
import { AutoSuggestRecentResult } from 'src/app/core/model/interface/autosuggest-recent-result';
import { LoggerService } from '../log/logger.service';
import {SearchActivity} from "../../../core/model/omnibar/search-activity";

@Injectable({
  providedIn: 'root'
})
export class AutoSuggestSearchService {
    private https = inject(HttpClient);
    private loggerService = inject(LoggerService);

    getRecentlySearchedProperties = (): Observable<AutoSuggestRecentResult[]> => {
        const url = baseUrl + '/user/activity/search';
        this.loggerService.logDebug(`calling ${url} to get recently searched properties`);

        return this.https.get<AutoSuggestRecentResult[]>(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).pipe(
            map(response => {
                if (!_.isEmpty(response)) {
                    return <AutoSuggestRecentResult[]> response;
                } else {
                    return [];
                }
            }),
            catchError((err) => {
                this.loggerService.logError('error retrieving recently searched properties', err);
                return throwError(err);
            })
        );
    }

    getRecentlyViewedProperties = (): Observable<AutoSuggestRecentResult[]> => {
        const url = baseUrl + '/user/activity/report';
        this.loggerService.logDebug(`calling ${url} to get recently viewed properties`);

        return this.https.get<AutoSuggestRecentResult[]>(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).pipe(
            map(response => {
                if (!_.isEmpty(response)) {
                    return <AutoSuggestRecentResult[]> response;
                } else {
                    return [];
                }
            }),
            catchError((err) => {
                this.loggerService.logError('error retrieving recently viewed properties', err);
                return throwError(err);
            })
        );
    }

    getAutoSuggestSearchResults = (lroId: string, searchText: string): Observable<AutoSuggestRecentResult[]> => {
        const url = baseUrl + '/autosuggest?lro=' + lroId + '&searchText=' + searchText;
        this.loggerService.logDebug(`calling ${url} to get autosuggest search results`);

        return this.https.get<AutoSuggestRecentResult[]>(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).pipe(
            map(response => {
                if (!_.isEmpty(response)) {
                    return <AutoSuggestRecentResult[]> response;
                } else {
                    return [];
                }
            }),
            catchError((err) => {
                this.loggerService.logError('error retrieving autosuggest search results', err);
                return throwError(err);
            })
        );
    }

    saveRecentSearchActivity = (searchActivity: SearchActivity): Observable<boolean> => {
      if(searchActivity) {
        const url = baseUrl + '/user/activity/search';

        searchActivity.cleanBeforeSave();

        return this.https.post(url, searchActivity).pipe(
          map(() => {
            return true;
          }),
          catchError((err) => {
            this.loggerService.logError(err);
            return throwError(err);
          })
        );
      } else {
        return of(false)
      }
    }

}
