import { Component } from '@angular/core';

@Component({
  selector: 'gema3g-main-map-streetview',
  templateUrl: './main-map-streetview.component.html',
  styleUrls: ['./main-map-streetview.component.scss']
})
export class MainMapStreetviewComponent {
  readyToDropPegman: boolean;
}
