import {EStoreProduct} from "./e-store-product";

export class EStoreProductPage {

  constructor(eStoreProductPage?: EStoreProductPage) {
    if (eStoreProductPage) {
      this.products = [];
      eStoreProductPage.products?.forEach((value, index) => {
        this.products.push(new EStoreProduct(value, index));
      })
    }
  }

  products: EStoreProduct[];
}
