<h1 id="warning-dialog-title" mat-dialog-title>
  &#9888; {{ data.title }}
</h1>

<div id="warning-dialog-content" class="dialog-content" mat-dialog-content>
  @if (data.content?.length) {
    <div class="flex items-center justify-content-center min-h-[150px]">
      @for (item of data.content; track $index) {
        <div class="flex items-center">
          <mat-label id="warning-label-{{item}}">{{ item }}</mat-label>
        </div>
      }
    </div>
  }
  @if (data.innerHtmlContent) {
    <div>
      <mat-label id="warning-label-inner" [innerHtml]="data.innerHtmlContent | safeHtml"></mat-label>
    </div>
  }
  <div></div>
</div>

@if (data.secondButtonText?.length || data.firstButtonText?.length) {
  <mat-divider></mat-divider>

  <div align="end" mat-dialog-actions>
    @if (data.showFirstButton) {
      <button id="warning-first-btn"
              type="button"
              mat-button
              mat-dialog-close
              class="first-btn mat-green"
              (click)="onFirstButtonClick()"
      >
        {{ data.firstButtonText }}
      </button>
    }
    <button id="warning-second-btn"
            type="button"
            mat-button
            mat-dialog-close
            class="second-btn mat-orange"
            (click)="onSecondButtonClick()"
    >
      {{ data.secondButtonText }}
    </button>
  </div>
}

