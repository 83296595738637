import {BaseModel} from "../../base-model";

export class ComparableSalesReportOption extends BaseModel {
  constructor(reportId: number, label: string) {
    super();
    this.label = label;
    this.reportId = reportId
  }

  reportId: number;
  label: string;

}
