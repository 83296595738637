import { inject, Injectable } from "@angular/core";
import { catchError, map, Observable, throwError } from "rxjs";
import { baseUrl } from "./system";
import { HttpClient } from "@angular/common/http";
import { Municipality } from "../../core/model/property/municipality";
import * as _ from 'lodash';
import { LoggerService } from "./log/logger.service";

@Injectable({
  providedIn: 'root'
})
export class MunicipalityService {

  private https = inject(HttpClient);
  private loggerService = inject(LoggerService);

  getMunicipalityDetails(municipalities: string[]): Observable<Municipality[]> {
    const url = `${baseUrl}/lotcon/municipalityDetail?munIds=${municipalities.join(',')}`;

    return this.https.get<Municipality[]>(url).pipe(
      map(resp => {
        //this.loggerService.logInfo(resp);
        if(resp) {
          return resp.map((municipality: Municipality) => {
            return new Municipality(municipality);
          });
        } else {
          return [];
        }
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }

  getMunicipalitiesByLro = (lro: string): Observable<any> => {
    const url = `${baseUrl}/lotcon/municipalities?lro=${_.trimStart(lro, "0")}`;

    return this.https.get(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).pipe(
      map(response => {
        if (!_.isEmpty(response)) {
          return response;
        } else {
          return {};
        }
      }),
      catchError((err) => {
        this.loggerService.logError(`error retrieving municipalities in lro ${lro}`, err);
        return throwError(err);
      })
    );
  }

  getMunicipalityPolygons(municipalities: any[]) {
    const url = `${baseUrl}/spatial/municipality/list?munIds=${municipalities.join(',')}`;

    return this.https.get(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).pipe(
      map(response => {
        if (!_.isEmpty(response)) {
          return response;
        } else {
          return {};
        }
      }),
      catchError((err) => {
        this.loggerService.logError(`error retrieving municipality polygons`, err);
        return throwError(err);
      })
    );
  }

}
