import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UserActivity } from "../../../model/user/user-activity";
import { UserAccessControl } from "../../../model/user/user-access-control";
import { UserService } from "../../../../shared/service/user.service";
import { LoggerService } from '../../../../shared/service/log/logger.service';

@Component({
  selector: 'gema3g-selected-property',
  templateUrl: './selected-property-dialog.component.html',
  styleUrls: ['./selected-property-dialog.component.scss']
})
export class SelectedPropertyDialogComponent {
  userAccessControls: UserAccessControl;
  constructor(public dialogRef: MatDialogRef<SelectedPropertyDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: UserActivity[],
              private userService: UserService,
              private loggerService: LoggerService) {
    this.userAccessControls = this.userService.getUserAccessControl();
  }

  setSubjectProperty(pResult: UserActivity) {
    this.loggerService.logInfo(pResult);
  }
}
