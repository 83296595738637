import { Component, OnInit, Inject, HostListener, inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FontAwesomeModule2 } from '../../fontawesome.module';
import { ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { MaterialModule } from '../../material.module';
import { Input } from '@angular/core';
import { OnChanges } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { PropertyReportService } from '../../../shared/service/property-report.service';
import { BaseUnsubscribe } from '../base-unsubscribe/base-unsubscribe';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'gema3g-top-scroller',
  standalone: true,
  imports: [FontAwesomeModule2, MaterialModule],
  templateUrl: './top-scroller.component.html',
  styleUrl: './top-scroller.component.scss'
})
export class ScrollToTopComponent extends BaseUnsubscribe implements OnInit, OnDestroy, OnChanges {

  constructor(@Inject(DOCUMENT) private document: Document) {
    super();
    
    this.window = this.document.defaultView;
    window.addEventListener('scroll', this.scroll, true); //third parameter
  }

  propertyReportService: PropertyReportService = inject(PropertyReportService);
  
  @ViewChild("topScrollerButton") topScrollerButtonRef: ElementRef;
  @Input() elementToCheckForVisibility: any;
  @Input() elementToReturnFocus: any;

  window: any;

  scroll = (event: any): void => {
    this.toggleScrollerControl();
  }

  private toggleScrollerControl = () => {
    if (this.elementToCheckForVisibility) {
      if (!this.isElementVisible(this.elementToCheckForVisibility)) {
        this.topScrollerButtonRef.nativeElement.style.display = "block";
      } else {
        this.topScrollerButtonRef.nativeElement.style.display = "none";
      }
    }
  }

  isElementVisible(element: any) {
    var rect = element.getBoundingClientRect();
    var elemmentTop = rect.top;
    var elementBottom = rect.bottom;

    //only completely visible elements return true:
    var isVisible = (elemmentTop >= 0) && (elementBottom <= window.innerHeight);

    //partially visible elements return true:
    //isVisible = elemmentTop < window.innerHeight && elementBottom >= 0;

    return isVisible;
  }

  //when the user clicks on the button, scroll to the top of the page
  scrollToTopOfPage = () => {
    this.propertyReportService.scrollToTopOfPage();
  }

  goToTop() {
    if (this.elementToReturnFocus) {
      this.elementToReturnFocus.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    }

    this.toggleScrollerControl();
    this.propertyReportService.cancelScrollToTopOfPage();
  }

  ngOnChanges(changes: SimpleChanges): void {
    let element: any = changes['elementToCheckForVisibility']?.currentValue;
    if (element) {
      this.elementToCheckForVisibility = element;
    }

    element = changes['elementToReturnFocus']?.currentValue;
    if (element) {
      this.elementToReturnFocus = element;
    }
  }
  
  override ngOnDestroy() {
    super.ngOnDestroy();
    this.window.removeEventListener('scroll', this.scroll, true);
  }

  ngOnInit() {
    this.propertyReportService.propertyReportTopScroller$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((scroll) => {
      if (scroll) {
        this.goToTop();
      }
    });
  }
}
