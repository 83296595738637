import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'cutText'
})
export class CutTextPipe implements PipeTransform {

  transform(value: any,  maximumLength: number, wordWise: boolean = true, suffix: string = '...'): any {
    if (!value) return '';
    if(!maximumLength) return value;

    if (value.length <= maximumLength) return value;

    value = value.substring(0, maximumLength);
    if (wordWise) {
      let lastSpace = value.lastIndexOf(' ');
      if (lastSpace != -1) {
        value = value.substring(0, lastSpace);
      }
    }
    return value + suffix;
  }
}
