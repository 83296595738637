import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DemographicsAreaEnum} from "../../../../../../core/enum/demographics-area.enum";
import {DemographicsHouseHold} from "../../../../../../core/model/demographics/demographics-house-hold";
import {DecimalPipe} from "@angular/common";
import {CoreModule} from "../../../../../../core/core.module";


@Component({
  selector: 'gema3g-structure-detail',
  standalone: true,
  templateUrl: './structure-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DecimalPipe,
    CoreModule
  ],
  styleUrls: ['./structure-detail.component.scss']
})
export class StructureDetailComponent {
  @Input() selectedArea: DemographicsAreaEnum[] = [];
  @Input() houseHold: DemographicsHouseHold;

  demographicsAreaEnum = DemographicsAreaEnum;
}
