<div class="gema3g-ao-container">
  <div class="gema3g-ao-container__inner">

    <div class="col-0">
      <mat-card class="purchases-card pt-4">
        <mat-card-content class="purchases-card-content pt-4">
          @if (isLoading) {
            <mat-progress-bar id="bp-progress-bar-ph" mode="indeterminate"></mat-progress-bar>
          }
          <table id="bp-tbl-ph" [dataSource]="dataSource" class="mat-elevation-z8 standard-table" mat-table matSort (matSortChange)="getReport('sort', $event)">

            <ng-container matColumnDef="date">
              <th id="bp-tbl-hdr-ph-date" *matHeaderCellDef mat-header-cell mat-sort-header="creationdate">Date</th>
              <td id="bp-tbl-data-ph-date-{{element.transactionId}}" *matCellDef="let element" mat-cell>
                {{element.date | date : 'mediumDate'}}
                <span class="time">
                  |  {{element.date | date:'h:mm a' : '+0000'}}
                </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="price">
              <th id="bp-tbl-hdr-ph-price" *matHeaderCellDef mat-header-cell>Price</th>
              <td id="bp-tbl-data-ph-price-{{element.transactionId}}" *matCellDef="let element" mat-cell>
                {{element.price | currency}}
              </td>
            </ng-container>

            <ng-container matColumnDef="numberOfProducts">
              <th id="bp-tbl-hdr-ph-num" *matHeaderCellDef mat-header-cell># of Products</th>
              <td id="bp-tbl-data-ph-num-{{element.transactionId}}" *matCellDef="let element" mat-cell> {{element.products.length}} </td>
            </ng-container>

            <ng-container matColumnDef="productDetails">
              <th id="bp-tbl-hdr-ph-details" *matHeaderCellDef mat-header-cell>Product Details</th>
              <td id="bp-tbl-data-ph-details-{{element.transactionId}}" *matCellDef="let element" mat-cell>
                {{element.getAllProducts()}}
              </td>
            </ng-container>

            <ng-container matColumnDef="transactionId">
              <th id="bp-tbl-hdr-ph-tid" *matHeaderCellDef mat-header-cell>Transaction ID</th>
              <td *matCellDef="let element" mat-cell>
                <a id="bp-tbl-link-ph-tid-{{element.transactionId}}" (click)="redirectToGWHInvoicePage(element.transactionId)" class="anchor">{{element.transactionId}}</a>
              </td>
            </ng-container>

            <tr id="bp-tbl-row-ph-hdr" *matHeaderRowDef="displayedColumns" mat-header-row></tr>
            <tr id="bp-tbl-row-ph-{{row.transactionId}}" *matRowDef="let row;
        let odd = odd;
        columns: displayedColumns;" mat-row></tr>
          </table>
          <mat-divider></mat-divider>
          <mat-paginator
            id="bp-paginator-ph"
            [length]="length"
            [pageIndex]="pageIndex"
            [pageSizeOptions]="pageSizes"
            (page)="pageEvent = getReport('pagination', $event)"
            showFirstLastButtons
          ></mat-paginator>
        </mat-card-content>
      </mat-card>
    </div>

  </div>
</div>