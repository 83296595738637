import {Directive, HostBinding} from "@angular/core";


/*
* This is used to set cursor on all elements with (click) binding
* */
@Directive({
  selector: '[click]'
})
export class ClickCursorDirective {
  @HostBinding('style.cursor') cursor: string = 'pointer';
}
