export class PushPinMarkerBubble {
    identifier: string;
    bubble: google.maps.InfoWindow;

    constructor(identifier: string, bubble: google.maps.InfoWindow) {
        this.identifier = identifier;
        this.bubble = bubble;
    }


  public toString = () : string => {
    return `identifier=${this.identifier}; position=${this.bubble?.getPosition()?.toString()}`;
  }
}
