<h1 id="image-h1-title" mat-dialog-title>
  {{data.title}}
</h1>

<div class="text-center">
  <img id="image-img-main" class="max-w-full min-h-[600px]"
       [src]="data.imageUrl" />
</div>

<mat-divider></mat-divider>

<div align="end" mat-dialog-actions>
  <button id="image-btn-close" mat-button mat-dialog-close class="dialog-close-btn" type="button">Close</button>
</div>