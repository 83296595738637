export class UrlUtility {

    public static replaceScheme = (url: string, from: string, to: string): string => {
        if (url) {
            if (from == 'http' && to == 'https') {
                return url.replace(/^http:\/\//i, 'https://');
            }
            if (from == 'https' && to == 'http') {
                return url.replace(/^https:\/\//i, 'http://');
            }
        }

        return url;
    }

    public static isHttpsScheme(url: string) {
        const regex = new RegExp(/https:\/\//g);
        return regex.test(url);
    }

    public static isHttpScheme(url: string) {
        const regex = new RegExp(/http:\/\//g);
        return regex.test(url);
    }

    public static isUrlHasParameter(url: URL, parameter: string) {
        let exists: boolean = false;

        let urlParams = new URLSearchParams(url.search);
        if (urlParams.has(parameter)) {
            exists = true;
        }

        return exists;
    }

    public static isUrlHasParameterAndValue(url: URL, parameter: string, value: string) {
        let exists: boolean = false;

        let urlParams = new URLSearchParams(url.search);
        if (urlParams.has(parameter) && urlParams.get(parameter) == value) {
            exists = true;
        }

        return exists;
    }

    public static getParameterValue(url: URL, parameter: string) {
        let value: string | null = null;

        let urlParams = new URLSearchParams(url.search);
        if (urlParams.has(parameter)) {
            value = urlParams.get(parameter);
        }

        return value;
    }

    public static setUrlParameterAndValue(url: URL, parameter: string, value: string): string {
        let urlParams = new URLSearchParams(url.search);
        urlParams.set(parameter, value);

        return urlParams.toString();
    }

    public static getParametersString(url: string) {
        let params: string = '';

        try {
            params = url.substring(url.indexOf('?') + 1);
        } catch (e) {
        } finally {
            return params;
        }
    }

    public static replaceParameters(url: string, parameters: string) {
        let _url: string = url;
        try {
            _url = url.substring(0, url.indexOf('?') + 1) + parameters;
        } catch (e) {
        } finally {
            return _url;
        }
    }
}