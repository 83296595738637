import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DemographicsAreaEnum} from "../../../../../core/enum/demographics-area.enum";
import {Demographics} from "../../../../../core/model/demographics/demographics";
import {DemographicsSocioEconomic} from "../../../../../core/model/demographics/demographics-socio-economic";

@Component({
  selector: 'gema3g-demographics-socio-economic',
  templateUrl: './demographics-socio-economic.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./demographics-socio-economic.component.scss']
})
export class DemographicsSocioEconomicComponent {
  demographicsAreaEnum = DemographicsAreaEnum;
  @Input() selectedArea: DemographicsAreaEnum[] = [];
  @Input() demographicsData: Demographics = new Demographics();


  get socioEconomic() : DemographicsSocioEconomic {
    return this.demographicsData?.socioEconomic;
  }
}
