<section class="footer-container">

  @if (userAccessControls?.restrictedAccess) {
    <div class="subscr-renewal-overlay"></div>
  }

  <div class="sm:flex copyright">
    <small id="ftr-copyright" class="sm:mr-4">Copyright &copy; 2002-{{ thisYear }} Teranet Inc. and its suppliers. All rights reserved.</small>
    <ul class="flex">

      <li class="first"><a id="ftr-privacy" href="{{urlPrivacyStatementUrl}}" target="_blank">Privacy Statement</a></li>
      <li><a id="ftr-terms" href="{{termsOfUseUrl}}" target="_blank">Terms of Use</a></li>
      <li class="" target="_blank"><a id="ftr-security" href="{{securityStatementUrl}}" target="_blank">Security Statement</a></li>
      <li id="ftr-build-version">Build: {{ buildVersion?.version }}</li>
      <!--
      @if (env) {
        <li id="ftr-build-env">Env: {{ env }}</li>
      }
      @if (!isProd) {
        <li id="ftr-build-usr">User: {{ userInfo }}</li>
      }
      -->
    </ul>
  </div>
</section>

