export enum RataSortKeyEnum {
CHECKBOXES="CHECKBOXES",
LRO_OFFICE_NAME='LroOfficeName',
PIN='Pin',
ARN="Arn",
INSTRUMENT_TYPE='InstrumentSubTypeCode',
CHARGE_TYPE='ChargeInstrumentSubTypeCode',
TRANSFER_TYPE='TransferInstrumentSubTypeCode',
REGISTRATION_DATE='RegistrationDate',
CHARGE_DATE='ChargeRegistrationDate',
TRANSFER_DATE='TransferRegistrationDate',
CONSIDERATION_AMOUNT='ConsiderationAmount',
CHARGE_AMOUNT='ChargeConsiderationAmount',
TRANSFER_AMOUNT='TransferConsiderationAmount',
SUM_AMOUNT='SumConsideration',
PARTY_FROM='PartyFrom',
PARTY_TO='PartyTo'
}