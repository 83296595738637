import {Pipe, PipeTransform} from '@angular/core';
import * as _ from "lodash";

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  transform(inputValue: string): string {
    if (inputValue) {
      return _.capitalize(inputValue);
    }
    return '';
  }
}
