
  <div class="demographics-header">
    <h4 id="dg-h4-pop">
      Population
    </h4>
  </div>

  @if (demographicsPopulation) {
    <ul class="demographics-data-header">
      <li class="title"><h5 id="dg-h5-pop-tp">Total Population</h5></li>
      <li id="dg-pop-nbh-label">NBH
        @if (demographicsPopulation.PRCDDA?.totalPopulation) {
          <span id="dg-pop-nbh">{{ demographicsPopulation.PRCDDA?.totalPopulation | fusionChartsNumber }}</span>
        } @else {
          <span id="dg-pop-nbh-na" class="demographics-values-na">Not Available</span>
        }
      </li>
      <li id="dg-pop-com-label">COM
        @if (demographicsPopulation.FSA?.totalPopulation) {
          <span id="dg-pop-com">{{ demographicsPopulation.FSA?.totalPopulation | fusionChartsNumber }}</span>
        } @else {
          <span id="dg-pop-com-na" class="demographics-values-na">Not Available</span>
        }
      </li>
      <li id="dg-pop-city-label">CITY
        @if (demographicsPopulation.PRCDCSD?.totalPopulation) {
          <span id="dg-pop-city">{{ demographicsPopulation.PRCDCSD?.totalPopulation | fusionChartsNumber }}</span>
        } @else {
          <span id="dg-pop-city-na" class="demographics-values-na">Not Available</span>
        }
      </li>
    </ul>
  }

  <div class="flex flex-row">
    <div class="flex flex-col md:flex-row pb-9">
      <div class="flex md:w-1/2 md:border-r border-gray-300">
        <div class="flex flex-col w-full">
          <span id="dg-pop-gs-label" class="demographics-sub-header">Gender</span>
          <div>
            <gema3g-gender-status
              [population]="demographicsPopulation"
              [selectedArea]="selectedArea"
            >
            </gema3g-gender-status>
          </div>
        </div>
      </div>

      <div class="flex md:w-1/2 md:pl-2">
        <div class="flex flex-col">
          <span id="dg-pop-fs-label" class="demographics-sub-header">Family Status</span>
          <div>
            <gema3g-family-status
              [demographicsPopulation]="demographicsPopulation"
              [selectedArea]="selectedArea"
            >
            </gema3g-family-status>
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>

  <div class="flex flex-row">
    <div class="flex flex-col md:flex-row pb-9 w-full">
      <div class="flex md:w-1/2">
        <div class="flex flex-col w-full">
          <span id="dg-pop-ms-label" class="demographics-sub-header">Marital Status</span>
          <div>
            <gema3g-marital-status
              [demographicsPopulation]="demographicsPopulation"
              [selectedArea]="selectedArea"

            >
            </gema3g-marital-status>
          </div>
        </div>
      </div>

      <div class="flex md:w-1/2 md:pl-2">
        <div class="flex flex-col w-full">
          <span id="dg-pop-ad-label" class="demographics-sub-header">Age Distribution</span>
          <div>
            <gema3g-age-distribution
              [demographicsPopulation]="demographicsPopulation"
              [selectedArea]="selectedArea"

            >
            </gema3g-age-distribution>
          </div>
        </div>
      </div>
    </div>
  </div>
