import {BaseModel} from "../base-model";

export class GarageStructure extends BaseModel{
  constructor(garageStrucutre: GarageStructure) {
    super(garageStrucutre);
  }

  structureDescription: string;
  totalGarageSpacesCount: number;
}
