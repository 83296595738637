import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { LoggerService } from '../../../../shared/service/log/logger.service';
import { DialogReturnTypeEnum } from "../../../enum/dialog-return-type-enum";
import { StreetViewDialogData } from './street-view-dialog-data';

@Component({
  selector: 'gema3g-street-view',
  templateUrl: './street-view-dialog.component.html',
  styleUrls: ['./street-view-dialog.component.scss']
})
export class StreetViewDialogComponent implements AfterViewInit {
  constructor(
    public dialogRef: MatDialogRef<StreetViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public svDialogData: StreetViewDialogData,
    private loggerService: LoggerService
  ) {
  }

  @ViewChild('streetViewMapContainer', { static: false }) gmapElementRef: ElementRef;
  streetViewMap: google.maps.Map;
  streetViewService = new google.maps.StreetViewService();
  returnType = DialogReturnTypeEnum;


  initializeMap = () => {

    let coordinates = new google.maps.LatLng(this.svDialogData.centroid.latitude, this.svDialogData.centroid.longitude);

    let mapOptions: google.maps.MapOptions = {
      center: coordinates,
      zoom: 15,
      disableDefaultUI: true
    };

    this.streetViewMap = new google.maps.Map(this.gmapElementRef.nativeElement, mapOptions);

    //@ts-ignore
    var panorama: google.maps.StreetViewPanorama = this.streetViewMap.getStreetView();
    let me = this;
    this.streetViewService.getPanorama({location : coordinates}, function (data, status) {
      me.loggerService.logInfo('streetview service status', status);

      if (status == google.maps.StreetViewStatus.OK) {
        //panorama.setPosition(data.location.latLng);
        //@ts-ignore
        panorama.setPosition(coordinates);
        //@ts-ignore
        panorama.set('addressControl', false);
        //@ts-ignore
        panorama.set('panControl', false);
        //@ts-ignore
        panorama.set('zoomControl', false);
        //@ts-ignore
        panorama.set('enableCloseButton', false);
        //this.panorama.setPano(data.location.pano);
        //@ts-ignore
        panorama.setPov({
          heading: 270,
          pitch: 0
        });
        //@ts-ignore
        panorama.setVisible(true);
      } else {
        //@ts-ignore
        panorama.setVisible(false);
      }
    }
  );
  }

  ngAfterViewInit() {
    this.initializeMap();
  }
}
