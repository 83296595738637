<div class="gema3g-ss-container">
  <div class="gema3g-ss-container__inner">
    <div class="gema3g-ss-container__inner-grid">


      <div class="l-column">
        <h4 id="ss-h4-res-{{assessment.rollNumber}}">Residential Structure Information</h4>
      </div>


      <div class="m-column">
        <div id="ss-res-yb-label-{{assessment.rollNumber}}" class="flex">
          <h4 id="ss-h4-res-yb-{{assessment.rollNumber}}">Year Built </h4>
          <span id="ss-res-yb-{{assessment.rollNumber}}" class="ml-auto text-right">{{assessment.siteDetail?.yearBuilt}}</span>
        </div>
      </div>


      <div class="r-column">
        <div id="ss-res-tfa-label-{{assessment.rollNumber}}" class="flex">
          <h4 id="ss-h4-res-tfa-{{assessment.rollNumber}}">Total Floor Area </h4>
          @if (userAccessControls.resFloorAreaBillable) {
            <span id="ss-res-tfa-{{assessment.rollNumber}}" class="ml-auto text-right">
              @if (assessment?.siteDetail?.isResidentialByPropTypeCode && userAccessControls.geowarehouseStore && userAccessControls.residentialFloorAreaReport) {
                @if (userAccessControls.residentialFloorAreaReportTxnl) {
                  <a id="ss-link-res-purchase-{{assessment.rollNumber}}" (click)="openStore()" class="anchor">
                    Click to Purchase
                  </a>
                }
                @if (!userAccessControls.residentialFloorAreaReportTxnl) {
                  <a id="ss-link-res-view-{{assessment.rollNumber}}" (click)="openStore()" class="anchor">
                    Click to View
                  </a>
                }
              }
              @if (!assessment?.siteDetail?.isResidentialByPropTypeCode && userAccessControls.geowarehouseStore && userAccessControls.residentialFloorAreaReport) {
                N/A
              }
            </span>
          }
        </div>
      </div>


    </div>
  </div>
</div>