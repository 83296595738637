import {inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {LoggerService} from "../service/log/logger.service";
import {SnackBarService} from "../service/snack-bar.service";

@Injectable({
  providedIn: 'root'
})
export class FileUtility {

  private https = inject(HttpClient);
  private loggerService = inject(LoggerService);
  private snackBarService = inject(SnackBarService);

  getExtension(fileName: string): string | undefined {
    if (fileName && fileName.indexOf('.') > -1) {
      return fileName.split('.').pop();
    }
    return '';
  }

  getFileNameFromUrl(url: string) {
    if (url?.indexOf('/') > 0) {
      const urlParts = url.split('/');
      return decodeURIComponent(urlParts[urlParts.length - 1]);
    }
    return '';
  }

  displayBrowserDownloadPrompt = (fileName: string, data: Blob) => {
    const a = document.createElement('a');
    const objectUrl = URL.createObjectURL(data);
    a.href = objectUrl;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(objectUrl); //discard the blob
  }


  downloadFileFromUrl(url: string): void {
    const outputFileName = this.getFileNameFromUrl(url)
    this.downloadFile(url, outputFileName);
  }

  downloadFile(url: string, outputFileName: string): void {
    url = this.changeUrlDomain(url);
    this.https.get(url, {responseType: 'blob'
      // ,
      // headers: {
      //   'Access-Control-Allow-Origin' : '*',
      //   'Origin' : '*',
      //   'Referer' : '*'
      // }
    }).subscribe(
      (blob) => {
        this.displayBrowserDownloadPrompt(outputFileName, blob);
        this.snackBarService.displaySnackBarMessage(`File "${outputFileName}" downloaded successfully`);
      },
      (error) => {
        this.loggerService.logError('File download failed', error);
        this.snackBarService.displaySnackBarMessage(`There was an error downloading file "${outputFileName}". Please try again later.`);
      }
    );
  }

  changeUrlDomain(url: string) {
    url = url.replace("http://", "https://");
    const devRegex = new RegExp("localhost|dev");
    const uatRegex = new RegExp("uat");
    const subdomain = "www.";
    if (devRegex.test(window.location.hostname)) {
      return url.replace(subdomain, "dev.");
    } else if (uatRegex.test(window.location.hostname)) {
      return url.replace(subdomain, "seuat.");
    }
    return url;
  }

  saveArrayBufferToFile(arrayBuffer: ArrayBuffer, fileName: string): void {
    const blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }
}
