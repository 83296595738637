import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {Observable, tap} from "rxjs";
import {LoggerService} from "../log/logger.service";
import {AuthenticationService} from "../authentication.service";
import {baseUrl} from "../system";

@Injectable({
  providedIn: 'root'
})
export class HttpCacheInterceptor implements HttpInterceptor{

  constructor(private loggerService: LoggerService,
              ) {
  }

  private urlsToBeCached : string[] = [
    baseUrl + '/lotcon/municipalities?',
    baseUrl + '/lotcon/township?',

    baseUrl + '/plan/easementCount?',
    baseUrl + '/plan/sp?',

    baseUrl + '/property/details?',
    baseUrl + '/property/demographics?',
    baseUrl + '/property/parentchild?',
    baseUrl + '/property/map/imagelist?',
    baseUrl + '/property/condoLevel?',
    baseUrl + '/comparableSales/md',
    baseUrl + '/tiles/zoom',
    baseUrl + '/spatial/polygons/'
  ];
  private loginURl = baseUrl + '/authenticate';
  private logoutUrl = baseUrl + '/logout'
  private cachedResponses: Map<string, HttpEvent<any>> = new Map<string, HttpEvent<any>>();

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.method === 'GET') { // make sure only GETs are cached
      //this.loggerService.logDebug(`HttpCacheInterceptor intercepted ${req.url}`);
      const cachedResponse = this.cachedResponses.get(req.url);
      if (cachedResponse) {
        // this.loggerService.logDebug(`retrieving response from cache for ${req.url} call`);
        return new Observable<HttpEvent<any>>(observer => {
          observer.next(cachedResponse);
          observer.complete();
        })
      }
    } else if (req.method === 'POST' && (req.url === this.loginURl || req.url === this.logoutUrl)) {
      this.clearCache();
    }
    return next.handle(req).pipe(
      tap((event) => {
        const cacheResponse = this.urlsToBeCached.some(url => {
          return req.url.startsWith(url);
        })
        if (cacheResponse && event instanceof HttpResponse && req.method === 'GET') {
          this.cachedResponses.set(req.url, event);
          // this.loggerService.logDebug(`HttpCacheInterceptor cached response for ${req.url}`);
        }
      })
    );
  }

  clearCache(){
    this.loggerService.logDebug('HttpCacheInterceptor::clearing cache');
    this.cachedResponses.clear();
  }
}
