import {PdfReportConfigurationBase} from "./pdf-report-configuration-base";

/**
 * This is used to capture user's configuration for "Client" report
 * */
export class PdfReportClientReportConfiguration extends PdfReportConfigurationBase{



}
