import {CountyMunicipality, Pin} from "../property/property-detail";
import {BaseInfo} from "./base-info";
import {MpacAssessment} from "./mpac-assessment";
import {EnhancedAssessment} from "./enhanced-assessment";
import {BaseStructure} from "../mpac/base-structure";
import {SiteDetail} from "../property/site/site-detail";
import {Spatial} from "../spatial/spatial";

export class Assessment {

  constructor(assessment?: Assessment) {

    if (assessment) {
      for (let property in assessment) {
        if (assessment.hasOwnProperty(property)) {
          // @ts-ignore
          this[property] = assessment[property];
        }
      }

      if (Array.isArray(assessment.commercialStructureAssessments)) {
        this.commercialStructureAssessments = assessment.commercialStructureAssessments.map(val => { return new BaseStructure(val); });
      }

      if (Array.isArray(assessment.industrialStructureAssessments)) {
        this.industrialStructureAssessments = assessment.industrialStructureAssessments.map(val => { return new BaseStructure(val); });
      }

      if (Array.isArray(assessment.spatial)) {
        this.spatial = assessment.spatial.map(val => { return new Spatial(val); });
      }

      if (assessment.enhancedAssessment) {
        this.enhancedAssessment = new EnhancedAssessment(assessment.enhancedAssessment);
      }

      if (assessment.mpacAssessment) {
        this.mpacAssessment = new MpacAssessment(assessment.mpacAssessment);
      }

      if (assessment.siteDetail) {
        this.siteDetail = new SiteDetail(assessment.siteDetail);
      }

      if (assessment.baseInfo) {
        this.siteDetail = new SiteDetail(assessment.siteDetail);
      }
    }

  }

  rollNumber: string;
  baseInfo: BaseInfo;
  commercialAssessment: any[];
  commercialLandAssessments: any[];
  commercialStructureAssessments: BaseStructure[];
  countyMunicipality: CountyMunicipality;
  industrialAssessment: any[];
  industrialLandAssessments: any[];
  industrialStructureAssessments: BaseStructure[];
  legalDescription: string;
  pins: Pin[];
  sales: any[];
  spatial: Spatial[];
  streetDirection?: any;
  streetName: string;
  streetNumber: string;
  streetType: string;
  unitNo: string;
  enhancedAssessment: EnhancedAssessment;
  mpacAssessment: MpacAssessment;
  siteDetail: SiteDetail;
  mpacSubscriptionType: string;
}
