import {Centroid} from "../property/centroid";
import {BaseModel} from "../base-model";
import {Polygon} from "../property/polygon";

export class PlanSurveyMapItData extends BaseModel{

  constructor(planSurveyMapItData? : PlanSurveyMapItData) {
    super(planSurveyMapItData);
  }

  area: number;
  centroid: Centroid;
  geom: string;
  id: string;
  name: string;
  polygon: Polygon[];
  spatialId: string;
}
