export class EnumUtility {

  public static getEnumKeyByEnumValue = (_enum: any, enumValue: any) => {
    let keys = Object.keys(_enum).filter(x => _enum[x] == enumValue);
    let key = keys.length > 0 ? keys[0] : null;
    return key;
  }

  public static getEnumKeyByEnumValue2 = (_enum: any, enumValue: any) => {
    return Object.keys(_enum)[Object.values(_enum).indexOf(enumValue)];
  }

  public static getEnumByValue = (_enum: any, enumValue: any) => {
    const __enum = _enum[enumValue as keyof typeof _enum];
    return __enum;
  }

// This method filters out disallowed numeric values from an enumeration and returns an array of the remaining values.
  public static numericEnumFilterOut = <R extends number, T extends { [key: string]: R }>(myEnum: T, disallowedValues: R[]): R[] => {
    return Object.entries(myEnum)
      .filter((type: [string, string | R]): boolean =>
        typeof type[1] === 'number' && !disallowedValues.includes(type[1]))
      .map((type: [string, R]): [string, R] => type as [string, R])
      .map(([, value]: [string, R]): R => value);
  }
}
