import {Injectable, WritableSignal, signal, inject} from '@angular/core';
import { baseUrl } from "./system";
import * as _ from 'lodash';
import { HttpClient } from "@angular/common/http";
import { UserService } from "./user.service";
import { LoggerService } from './log/logger.service';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { BannerMessage } from '../../core/model/message-center/banner-message';

@Injectable({
  providedIn: 'root'
})

export class MessageCenterService {

  constructor() {
  }
  private userService = inject(UserService);
  private https = inject(HttpClient);
  private loggerService = inject(LoggerService);

  private _bannerMessages = new BehaviorSubject<BannerMessage | null>(null);
  bannerMessages$ = this._bannerMessages.asObservable();

  private _expiredBannerMessages = new BehaviorSubject<BannerMessage | null>(null);
  expiredBannerMessages$ = this._expiredBannerMessages.asObservable();

  private bannerMessagesLoadingSignal: WritableSignal<boolean> = signal(false);

  setBannerMessages = (bannerMessages: BannerMessage | null) => {
    this._bannerMessages.next(bannerMessages);
  }

  getBannerMessagesForDisplay = () => {
    return this._bannerMessages.getValue();
  }

  setExpiredBannerMessages = (bannerMessages: BannerMessage | null) => {
    if (!_.isEmpty(bannerMessages)) {
      bannerMessages.setMessagestoExpired();
    }
    this._expiredBannerMessages.next(bannerMessages);
  }

  getExpiredBannerMessagesForDisplay = () => {
    return this._expiredBannerMessages.getValue();
  }

  getBannerMessages(): Observable<BannerMessage> {
    let beId = this.userService.getBEID();
    let backendEndpoint = baseUrl + `/messagecenter/bannermessages/${beId}`;

    if (!beId) {
      return throwError(() => new Error(`missing beid when retrieving new banner messages`));
    }

    return this.https.get<BannerMessage>(backendEndpoint).pipe(
      map(response => {
        if (!_.isEmpty(response)) {
          return new BannerMessage(<BannerMessage>response);
        } else {
          return new BannerMessage();
        }
      }),
      catchError((err) => {
        this.loggerService.logError(`error retrieving new banner messages for beid ${beId}`, err);
        return throwError(err);
      })
    );
  }

  getExpiredBannerMessages(): Observable<BannerMessage> {
    let beId = this.userService.getBEID();
    let backendEndpoint = baseUrl + `/messagecenter/expiredbannermessages/${beId}`;

    if (!beId) {
      return throwError(() => new Error(`missing beid when retrieving expired banner messages`));
    }

    return this.https.get<BannerMessage>(backendEndpoint).pipe(
      map(response => {
        if (!_.isEmpty(response)) {
          return new BannerMessage(<BannerMessage>response);
        } else {
          return new BannerMessage();
        }
      }),
      catchError((err) => {
        this.loggerService.logError(`error retrieving expired banner messages for beid ${beId}`, err);
        return throwError(err);
      })
    );
  }

  showBannerMessagesLoading = () => {
    this.bannerMessagesLoadingSignal.set(true);
  }

  hideBannerMessagesLoading = () => {
    this.bannerMessagesLoadingSignal.set(false);
  }

  isBannerMessagesLoading = (): boolean => {
    return this.bannerMessagesLoadingSignal();
  }

}
