import {Assessment2, OverallPhaseIn, ResidentialStructure} from "./property-detail";
import {BaseAssessmentProperty} from "./base-assessment-property";
import {DataService} from "../../../shared/service/data.service";
import {GarageStructure} from "./garage-structure";
import {Abut} from "./abut";
import {Proximity} from "./proximity";
import {Description} from "./description";
import {OnSite} from "./on-site";
import {Waterfront} from "./waterfront";


export class MpacAssessmentProperty extends BaseAssessmentProperty {

  constructor(mpacAssessmentProperty?: MpacAssessmentProperty) {
    super(mpacAssessmentProperty);
    if (mpacAssessmentProperty) {

      if (mpacAssessmentProperty.garageStructures) {
        this.garageStructures = mpacAssessmentProperty.garageStructures.map(value => {
          return new GarageStructure(value)
        });
      }
      if (Array.isArray(mpacAssessmentProperty.abuts)) {
        this.abuts = mpacAssessmentProperty.abuts.map(value => {
          return new Abut(value);
        });
      }
      if (Array.isArray(mpacAssessmentProperty.residentialStructures)) {
        this.residentialStructures = mpacAssessmentProperty.residentialStructures.map(value => {
          return new ResidentialStructure(value);
        });
      }
      if (Array.isArray(mpacAssessmentProperty.garageStructures)) {
        this.garageStructures = mpacAssessmentProperty.garageStructures.map(value => {
          return new GarageStructure(value);
        });
      }
      if (Array.isArray(mpacAssessmentProperty.proximity)) {
        this.proximity = mpacAssessmentProperty.proximity.map(value => {
          return new Proximity(value);
        });
      }
      if (Array.isArray(mpacAssessmentProperty.onsite)) {
        this.onsite = mpacAssessmentProperty.onsite.map(value => {
          return new OnSite(value);
        });
      }
      if (Array.isArray(mpacAssessmentProperty.waterfront)) {
        this.waterfront = mpacAssessmentProperty.waterfront.map(value => {
          return new Waterfront(value);
        });
      }
    }
  }

  assessment: Assessment2;
  drivewayTypeCode: string;
  overallPhaseIn: OverallPhaseIn;
  residentialStructures: ResidentialStructure[];
  sanitaryServiceCode: string;
  valuationDate: string;
  waterServiceCode: string;
  municipalityDescription: string;
  actualFrontage: string;
  actualFrontageUom: string;
  lastNoticeDate: string;
  rfrAppealDate: string;
  actualLotSize: string;
  garageStructures: GarageStructure[];
  abuts: Abut[];
  proximity: Proximity[];
  onsite: OnSite[];
  waterfront: Waterfront[];

  get frontage(): string {
    if (this.actualFrontageUom != null) {
      if (this.actualFrontage && this.actualFrontage != DataService.NOT_APPLICABLE) {
        if (this.actualFrontageUom == 'F') {
          return (parseFloat(this.actualFrontage) * DataService.feetToMeterFactor).toString();
        } else {
          return parseFloat(this.actualFrontage).toFixed(2);
        }
      } else {
        return DataService.NOT_APPLICABLE;
      }
    }
    return DataService.NOT_APPLICABLE;
  }

  get garageType(): string {
    if (this.propertyCodeDescription?.toLowerCase()?.includes("condominium") || this.propertyTypeDescription?.toLowerCase()?.includes("condominium")) {
      if (Array.isArray(this.residentialStructures) && this.residentialStructures.length > 0) {
        return this.residentialStructures[0]?.condoGarageTypeDescription || DataService.NOT_APPLICABLE;
      } else {
        DataService.NOT_APPLICABLE
      }
    } else if (Array.isArray(this.garageStructures)) {
      return this.getGarageType();
    }
    return DataService.NOT_APPLICABLE;
  }

  getGarageType(): string {
    if (this.garageStructures?.length > 0) {
      return this.garageStructures?.map(value => {
        return value?.structureDescription
      }).filter(Boolean).join(', ');
    }
    return DataService.NOT_APPLICABLE;
  }

  get garageStructure(): string {
    if (Array.isArray(this.garageStructures)) {
      return this.garageStructures.map(garagStructure => garagStructure.totalGarageSpacesCount).join(', ');
    }
    return DataService.NOT_APPLICABLE;
  }

  get abutDetails(): string {
    return this.getDescriptions(this.abuts);
  }

  get proximityDetails(): string {
    return this.getDescriptions(this.proximity);
  }

  get onSiteDetails(): string {
    return this.getDescriptions(this.onsite);
  }

  get waterfrontDetails(): string {
    return this.getDescriptions(this.waterfront);
  }

  getDescriptions(descr: Description[]): string {
    if (Array.isArray(descr) && descr.length) {
      return descr.map(value => {
        return value.description
      }).join(', ');
    }
    return DataService.NOT_APPLICABLE;
  }
}
