@if (error) {

  <div>
    {{ error }}
  </div>

} @else {

  <div class="h-screen flex items-center justify-content-center">

    <h1 class="text-3xl font-bold">Please wait while we log you in</h1>

  </div>

}
