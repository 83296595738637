import { inject, Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
    providedIn: 'root'
})
export class BrowserDetectorService {

    constructor() { }

    private deviceService = inject(DeviceDetectorService);

    getBrowserName(): string {
        return this.deviceService.browser;
    }

    getUserAgent(): string {
        return this.deviceService.userAgent;
    }

    isMobile(): boolean {
        return this.deviceService.isMobile();
    }

    public static isSafari = () => {
        return /^((?!chrome|android).)*safari/i.test(navigator?.userAgent);
    }

    public static isNotFirefox = () => {
        return navigator.userAgent.indexOf("Firefox") < 0;
    }

}