<h1 id="pvi-h1-title" mat-dialog-title>
    Property Viewed Insights
</h1>

<mat-dialog-content class="mat-typography">
    <div class="charts-container">
        <canvas id="pvi-chart-unique-users-viewed-count" class="pvi-chart-unique-users-viewed-count">{{uniqueUsersViewedCountChart}}</canvas>

        <canvas id="pvi-chart-users-viewed-count" class="pvi-chart-users-viewed-count">{{allUsersViewedCountChart}}</canvas>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button id="pvi-btn-close" mat-button mat-dialog-close class="dialog-close-btn" type="button">Close</button>
</mat-dialog-actions>