import {FulfillmentItemPK} from "../estore/fulfillment-item-pk";
import {EstorePybReport} from "../estore/estore-pyb-report";

export class FulfillmentItem {

  constructor(fulfillmentItem? : FulfillmentItem) {
    if(fulfillmentItem){
      for(let property in fulfillmentItem){
        if(fulfillmentItem.hasOwnProperty(property)){
          // @ts-ignore
          this[property] = fulfillmentItem[property];
        }
      }
    }
  }

  address: string;
  arn: string;
  businessEntityId: number;
  cartDesc: string;
  contentFileName: string;
  contentType: string;
  createdDate: Date;
  paid: string;
  pin: string;
  pk: FulfillmentItemPK;
  transactionId: string;
  rref: string;
  productName: string;
  productCategoryName: string;
  estorePybReport: EstorePybReport | null;
  pybRptETA: string;
  pybRptTypId: string;
  productId: number;

  get cartFullDescription(): string {
    if ( !this.cartDesc){
      return this.cartDesc;
    } else if (this.pin){
      return `PIN: ${this.pin}`;
    } else if (this.arn){
      return `ARN: ${this.arn}`;
    }
    return '';
  }


}
