import {Component, Input} from '@angular/core';
import {Assessment} from "../../../../../core/model/assessment/assessment";
import {UserAccessControl} from "../../../../../core/model/user/user-access-control";

@Component({
  selector: 'gema3g-site-structure-structure',
  templateUrl: './site-structure-structure.component.html',
  styleUrls: ['./site-structure-structure.component.scss']
})
export class SiteStructureStructureComponent {

  @Input() assessment: Assessment = new Assessment();
  @Input() userAccessControls: UserAccessControl;

  get propertyCode(): string {
    if (this.userAccessControls.MPACSubscriptionPremiumOrEnhanced) {
      return this.assessment?.mpacAssessment?.property?.propertyCode || 'N/A';
    } else {
      return this.assessment?.baseInfo?.propertyType?.code;
    }
  }

  get propertyDescription(): string {

    // use this to test if the logWithStack feature stops sending the same error message
    //throw new Error('Simulating an error in getter');

    if (this.userAccessControls.MPACSubscriptionPremiumOrEnhanced) {
      return this.assessment.mpacAssessment?.property?.propertyCodeDescription || 'N/A';
    } else {
      return this.assessment.baseInfo?.propertyType?.description;
    }
  }
}
