import {inject, Injectable} from "@angular/core";
import * as _ from 'lodash';
import dayjs from "dayjs";
import { LoggerService } from "../service/log/logger.service";

@Injectable({
  providedIn: 'root'
})
export class DateUtilityService {

  private loggerService = inject(LoggerService);


  getDaysBetween = (fromMillis: number, toMillis: number): number => {
    return _.round((new Date(toMillis).getTime() - new Date(fromMillis).getTime()) / (1000 * 3600 * 24), 0);
  }

  getDate = (millis: number): number => {
    return new Date(millis).getDate();
  }

  getMonth = (millis: number): number => {
    return new Date(millis).getMonth();
  }

  getYear = (millis: number): number => {
    return new Date(millis).getFullYear();
  }

  customFormatDate(value: any, formatDate: string, ignoreTimeStamp?: boolean): any {
    if (!value) {
      return '';
    }
    try {
      if (ignoreTimeStamp) {
        const parsedDate = new Date(value);
        parsedDate.setMinutes(parsedDate.getMinutes() + parsedDate.getTimezoneOffset())
        return dayjs(parsedDate.toString()).format(formatDate);
      }
      return dayjs(value).format(formatDate);
    } catch (e) {
      this.loggerService.logError(e);
      return '';
    }
  }

  diffInSeconds2(dateFrom: number, dateTo : number): number {
    const djDateFrom = dayjs(dateFrom);
    const djDateTo = dayjs(dateTo);
    return djDateTo.diff(djDateFrom, "seconds").valueOf();
  }

  diffInSeconds(dateFrom: Date, dateTo : Date): number {
    const djDateFrom = dayjs(new Date(dateFrom));
    const djDateTo = dayjs(new Date(dateTo));
    return djDateTo.diff(djDateFrom, "seconds").valueOf();
  }
}
