import { Injectable, Inject, inject } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { LoggerService } from "../../../shared/service/log/logger.service";
import { DOCUMENT } from '@angular/common';
import { UserService } from "../../../shared/service/user.service";
import { AuthenticationService } from "../../../shared/service/authentication.service";

/**
 * This Auth guard restricts routes to only non-sso users who are not logged in.
 *
 */
@Injectable({ providedIn: 'root' })
export class UserNotLoggedInAuthGuard {
  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  private loggerService = inject(LoggerService);
  private authService = inject(AuthenticationService);
  private userService = inject(UserService);
  private router = inject(Router);
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.isLoggedIn) {
      this.loggerService.logInfo(`non-sso user ${this.userService.user.businessEntityId} is already logged in.`);

      //send the user back to the landing page
      this.router.navigate(['/home']);
      return false;
    }

    return true;
  }
}
