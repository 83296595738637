import { BaseModel } from "../base-model";

export class UserActivity extends BaseModel {
  constructor(userActivity?: UserActivity) {
    super(userActivity);
  }

  activityEnum: string;
  beid: number;
  pin: string;
  text: string;
  timestamp: number;
  activityType: string;
  reportCategory: boolean;
}
