import {Preference} from "./preference";

export class GenericPreference {
  constructor(genericPreference: GenericPreference) {
    if (genericPreference) {
      for (let property in genericPreference) {
        if (genericPreference.hasOwnProperty(property)) {
          // @ts-ignore
          this[property] = genericPreference[property];
        }
      }
      this.layers = [];
      if (genericPreference.layers) {
        genericPreference.layers.forEach(layer => this.layers.push(new Preference(layer.key, layer.value)));

      }
    }
  }

  layers: Preference[];
  lro: string;
  mapType: string;
  searchMethod: string;
  uomDistance: string;
  uomPropertySize: string;

  get isMetric(): boolean{
    return (!!this.uomDistance) && this.uomDistance.toUpperCase().charAt(0) == 'M';
  }

  setLayer(key: string, value: string | boolean) {
    const layer = this.layers.find(pref => pref.key == key);
    if (layer) {
      layer.value = value.toString();
    } else {
      this.layers.push(new Preference(key, value.toString()));
    }
  }

  patchFromUserInput(genericPreference?: GenericPreference) {
    if (genericPreference) {
      this.mapType = genericPreference.mapType;
      this.lro = genericPreference.lro;
      this.searchMethod = genericPreference.searchMethod;
      this.uomDistance = genericPreference.uomDistance;
      genericPreference.layers.forEach( layer => {
        this.setLayer(layer.key, layer.booleanValue);
      })
    }
  }

  makeAdjustmentsOnSystemDefaultValues() {

  }
}
