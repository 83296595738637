import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ElementRef, inject } from '@angular/core';
import { PropertyDetail } from '../../../../core/model/property/property-detail';
import { PropertyReportComponentBase } from '../property-report-component-base';
import { MatDialog } from "@angular/material/dialog";
import { UserAccessControl } from '../../../../core/model/user/user-access-control';
import { PropertyReportSectionEnum } from '../../../../core/enum/property-report-section-enum';
import { AddressCorrectionDialogComponent } from "../../../../core/component/modal/address-correction-dialog/address-correction-dialog.component";
import { AddressCorrectionDialogData } from "../../../../core/component/modal/address-correction-dialog/address-correction-dialog-data";
import { DataService } from '../../../../shared/service/data.service';
import { NumberUtility } from "../../../../shared/utility/number.utility";
import * as _ from 'lodash';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { MatSnackBar } from '@angular/material/snack-bar';
import { defaultErrorMatSnackBarConfig } from '../../../../shared/constant/constants';
import { lastValueFrom, takeUntil } from "rxjs";
import { LeftSidebarComponent } from '../left-sidebar/left-sidebar.component';
import { RightSidebarComponent } from '../right-sidebar/right-sidebar.component';
import { MetersToFeetWithUomPipe } from "../../../../shared/pipe/meters-to-feet-with-uom.pipe";
import { SquareMetersToSquareFeetWithUomPipe } from "../../../../shared/pipe/square-meter-to-square-feet-with-uom.pipe";
import { faMapLocationDot, faCircleInfo, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { MainMapService } from '../../main-map/main-map.service';
import { PropertyDetailSectionEnum } from '../../../../core/enum/property-detail-section-enum';
import { GoogleAnalyticsService } from "../../../../shared/service/google-analytics.service";
import { environment } from "../../../../../environments/environment";
import { PropertyViewedInsightsDialogComponent } from '../../../../core/component/modal/property-viewed-insights-dialog/property-viewed-insights-dialog.component';
import { PropertyViewedInsight } from '../../../../core/model/property/property-viewed-insight';
import { ErrorUtil } from '../../../../shared/service/error.util';
import { CompanyAccountTypeEnum } from '../../../../core/enum/company-account-type.enum';
import { UserService } from '../../../../shared/service/user.service';

declare function scrollToElementById0(elementId: string): void;

@Component({
  selector: 'gema3g-property-report-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
  providers: [MetersToFeetWithUomPipe, SquareMetersToSquareFeetWithUomPipe]
})
export class SummaryComponent extends PropertyReportComponentBase implements OnInit, AfterViewInit {

  constructor() {
    super();
  }

  private dialog = inject(MatDialog);
  private _snackBar = inject(MatSnackBar);
  private mainMapService = inject(MainMapService);
  private metersToFeetWithUom = inject(MetersToFeetWithUomPipe);
  private squareMetersToSquareFeetWithUom = inject(SquareMetersToSquareFeetWithUomPipe);
  private userService = inject(UserService);
  private gaService = inject(GoogleAnalyticsService);

  @Input() userAccessControls: UserAccessControl;
  subjectProperty: PropertyDetail;
  squareFeetUnitDisplay: string = DataService.SQUARE_FEET_UNIT_DISPLAY;
  propertyReportSection = PropertyReportSectionEnum.SUMMARY;
  lastSaleAmount: string = DataService.NOT_APPLICABLE;
  lastSaleDate: string = DataService.NOT_APPLICABLE;
  polygonArea: string = DataService.NOT_APPLICABLE;
  polygonPerimeter: string = DataService.NOT_APPLICABLE;
  ANA: string = DataService.ADDRESS_NOT_AVAILABLE;
  frontage: any;
  depth: any;
  legalDescription: string;
  visibleLegalDescription: string;
  lotSizeNotAvailable: boolean = false;
  frontageNotAvailable: boolean = false;
  depthNotAvailable: boolean = false;
  assessmentLabelMissing: boolean = true;
  firstAssessmentLabels: any;
  phasedInValue: string;
  destinationValue: string;
  arnCount: number = 0;
  ownerNames: string;
  hasMoreOwners: boolean = false;
  phasedInValueToolTip: string;
  centerPropertyOnMapToolTip: string;
  propertyViewedInsightsToolTip: string;
  partyTo: string;
  partyToAvailable: boolean = false;
  copiedLegalDescription: string = "Copied";
  @ViewChild("copyLegalDescriptionPopover") copyLegalDescriptionPopover: any;
  @ViewChild('lSideBar') lSideBar: LeftSidebarComponent;
  @ViewChild('rSideBar') rSideBar: RightSidebarComponent;
  @ViewChild('addressSummaryContainer', {static: false}) addressSummaryContainer: ElementRef;
  showLSideBar = false;
  showRSideBar = false;
  showLegalDescriptionFullView: boolean = false;
  currentTaxYear: number = new Date().getFullYear();
  faMapLocationDot = faMapLocationDot;
  faCircleInfo = faCircleInfo;
  faChartLine = faChartLine;
  @ViewChild('phasedInTaxYearPopover', { static: false }) phasedInTaxYearPopover: any;

  suggestAddressCorrection() {
    try {
      const data: AddressCorrectionDialogData = new AddressCorrectionDialogData();
      data.pin = this.subjectProperty.pii.pin;
      data.propertyAddress = this.subjectProperty.pii.address?.fullAddress || this.ANA;
      data.propertyARN = this.subjectProperty.pii.arns[0];
      data.propertyLro = this.subjectProperty.pii.lro;
      this.gaService.openModal('AddressCorrectionDialog');
      this.dialog.open(AddressCorrectionDialogComponent, { data: data });
    }
    catch (e) {
      this.loggerService.logError("failed to open address correction dialog ", e);
    }
  }

  scrollToPropertyDetails(elementId: string) {
    // Simulate a click to expand the panel
    this.propertyReportService.scrolltoSectionPanel(PropertyDetailSectionEnum.PROPERTY_DETAILS);
    // Delay the scroll operation to ensure it occurs after the expansion animation
    setTimeout(() => {
      //Call the globally declared function for scrolling
      scrollToElementById0(elementId);
    }, 500);
  }

  refreshUI = () => {

    //TODO: access to last sale requires //142:// "PROPERTY ASSESSMENT REPORT": or 148:// "GEMA PROPERTY ASSESSMENT REPORT":
    try {
      this.lotSizeNotAvailable = false;
			if (this.subjectProperty.ownerNames != null && this.subjectProperty.ownerNames.length > 0) {
				this.ownerNames = this.subjectProperty.ownerNames.join("; ");
			} else {
				this.ownerNames = DataService.NOT_APPLICABLE;
			}

			if (this.ownerNames != null && this.ownerNames.length > 50) {
				this.ownerNames = this.ownerNames.slice(0, 50) + "...";
				this.hasMoreOwners = true;
			}

      this.lastSaleAmount = NumberUtility.formatWithCommas(this.subjectProperty.salesHistory?.[0]?.polInstrument?.considerationAmt);

      let saleDate: any = this.subjectProperty.salesHistory?.[0]?.polInstrument?.registrationDate;
      dayjs.extend(utc);
      if (saleDate) {
        this.lastSaleDate = dayjs(saleDate).utc().format('MMM DD, YYYY');
      }

      let baseInfo = this.subjectProperty.assessments?.[0]?.baseInfo;

      if (this.subjectProperty.assessments != null && this.subjectProperty.assessments.length > 0) {
        this.arnCount = this.subjectProperty.assessments.length;

        if (baseInfo != null) {
          if (baseInfo.actualUnitOfMeasure != null) {
            //@ts-ignore
            if (baseInfo.frontage != 0 && baseInfo.frontage != null && baseInfo.frontage != DataService.NOT_APPLICABLE) {
              if (baseInfo.actualUnitOfMeasure == 'F') {
                //@ts-ignore
                this.frontage = parseFloat(baseInfo.frontage) * 0.3048;

              } else {
                //@ts-ignore
                this.frontage = parseFloat(baseInfo.frontage).toFixed(2);
              }
            } else {
              this.frontage = DataService.NOT_APPLICABLE;
            }
            //@ts-ignore
            if (baseInfo.depth != 0 && baseInfo.depth != null && baseInfo.depth != DataService.NOT_APPLICABLE) {
              if (baseInfo.actualUnitOfMeasure == 'F') {
                //@ts-ignore
                this.depth = parseFloat(baseInfo.depth) * 0.3048;

              } else {
                //@ts-ignore
                this.depth = parseFloat(baseInfo.depth);
              }
            } else {
              this.depth = DataService.NOT_APPLICABLE;
            }
          } else {
            this.lotSizeNotAvailable = true;
          }

          this.assessmentLabelMissing = false;
          if (baseInfo.labelsBaseShort == null && baseInfo.labelsPhasedInShort == null) {
            this.assessmentLabelMissing = true;
          }
          this.firstAssessmentLabels = {
            baseShort: baseInfo.labelsBaseShort != null ? baseInfo.labelsBaseShort : DataService.NOT_APPLICABLE,
            phasedInShort: baseInfo.labelsPhasedInShort != null ? baseInfo.labelsPhasedInShort : DataService.NOT_APPLICABLE
          }

          this.destinationValue = NumberUtility.formatWithCommas(baseInfo.destinationValue);
          this.phasedInValue = NumberUtility.formatWithCommas(baseInfo.currentValue);

          if ((this.depthNotAvailable == true) && (this.frontageNotAvailable == true)) {
            this.lotSizeNotAvailable = true;
          }
        } else {
          this.lotSizeNotAvailable = true;
        }

      } else {
        this.lotSizeNotAvailable = true;
        this.depth = DataService.NOT_APPLICABLE;
        this.frontage = DataService.NOT_APPLICABLE;
        this.destinationValue = DataService.NOT_APPLICABLE;
        this.phasedInValue = DataService.NOT_APPLICABLE;
      }

      if (this.frontage == DataService.NOT_APPLICABLE && this.depth == DataService.NOT_APPLICABLE) {
        this.lotSizeNotAvailable = true;
      }

			if (this.subjectProperty.pii?.pinXy != null) {
				if (this.subjectProperty?.pii?.pinXy?.area != null) {
					this.polygonArea = this.subjectProperty.pii.pinXy.area.toString();
				}
				if (this.subjectProperty?.pii?.pinXy?.perimeter != null) {
					this.polygonPerimeter = this.subjectProperty.pii?.pinXy.perimeter.toString();
				}
			}

      if (!_.isEmpty(this.subjectProperty?.latestPartyTos)) {
				this.partyTo = this.subjectProperty.latestPartyTos.join("; ");
			} else {
        this.partyTo = '';
      }

      if (this.subjectProperty.pii?.longLegalDescription) {
        this.legalDescription = this.subjectProperty.pii.longLegalDescription;
      } else if (this.subjectProperty.pii?.shortLegalDescription) {
        this.legalDescription = this.subjectProperty.pii.shortLegalDescription;
      }

      this.partyToAvailable = !this.subjectProperty.ownerSameAsPartyTo;

      this.setVisibleLegalDescription();

    } catch (e) {
      this.loggerService.logError(`error initializing summary component for subject property pin ${this.subjectProperty?.pii?.pin}`);
    }
  }

  setVisibleLegalDescription(){
    this.visibleLegalDescription =
      this.showLegalDescriptionFullView ?
        this.legalDescription:
        _.truncate(
          this.legalDescription, {
            'length': DataService.DEFAULT_LEGAL_DESCRIPTION_VISIBLE_LENGTH,
            'omission': '...'
          }
        );
  }

  updateLegalDescriptionViewSize(){
    this.showLegalDescriptionFullView = !this.showLegalDescriptionFullView;
    this.setVisibleLegalDescription();
  }
  get showLegalDescriptionLengthMarker(): boolean{
    return this.legalDescription?.length > DataService.DEFAULT_LEGAL_DESCRIPTION_VISIBLE_LENGTH;
  }
  get legalDescriptionLengthMarker(): string{
    return this.showLegalDescriptionFullView ?
      'less' :
      'more';
  }

  copyLegalDescription = () => {
    navigator?.clipboard?.
    writeText(this.legalDescription)
      .then(() => {
        this.copiedLegalDescription = "Copied";
        this.copyLegalDescriptionPopover.open();
        setTimeout(() => {
          this.copyLegalDescriptionPopover.close();
        }, 4000);
      });
  }

  initializeSubjectPropertyListener = () => {
    this.propertyReportService.subjectProperty$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((newPropertyDetail) => {
      this.subjectProperty = newPropertyDetail;
      this.refreshUI();
    });
  }

  centerPropertyOnMap = () => {
    this.mainMapService.centerSubjectPropertyOnMap();
  }

  showPropertyViewedInsights = async () => {

    try {
      let insights: PropertyViewedInsight = await lastValueFrom(this.propertyReportService.getPropertyViewedInsights(this.subjectProperty?.pii?.pin));
      //this.loggerService.logDebug(`property viewed count insights found for pin ${this.subjectProperty?.pii?.pin}? ${insights?.hasInsights()}`);
      
      if (insights) {
        insights.pin = this.subjectProperty?.pii?.pin;
        const dialogRef = this.dialog.open(PropertyViewedInsightsDialogComponent, {data: insights, minWidth: 400, minHeight: 300});
  
        dialogRef.afterClosed().subscribe(result => {
          this.loggerService.logDebug(`Dialog result: ${result}`);
        });
      }
    } catch (e) {
      this._snackBar.open(ErrorUtil.PROPERTY_VIEWED_INSIGHTS_NOT_AVAILABLE, 'Close', defaultErrorMatSnackBarConfig);
    }
  }

  ngOnInit(): void {
    this.initializeSubjectPropertyListener();

    //formatted html tooltip
    this.phasedInValueToolTip = `The Assessed Value and Phase-In Value information is determined by Municipal Property Assessment Corporation (MPAC).<br/><br/>`+
    `MPAC will not be conducting new Property Assessments in ${this.currentTaxYear}, and the assessments which were displayed in ${this.currentTaxYear - 1} will remain the same for this year.<br/><br/>`+
    `For further information on the data provided, please go to <a target='_blank' class='anchor-white' href=${environment.url.MPAC_UNDERSTANDING_YOUR_ASSESSMENT}>${environment.url.MPAC_UNDERSTANDING_YOUR_ASSESSMENT}</a>.`;
    
    this.centerPropertyOnMapToolTip = DataService.PROPERTY_REPORT_SUMMARY_SECTION_CENTER_ON_MAP;
    this.propertyViewedInsightsToolTip = DataService.PROPERTY_REPORT_SUMMARY_SECTION_PROPERTY_VIEWED_INSIGHTS;
  }

  ngAfterViewInit(): void {
    //this.initializeSubjectPropertyListener();
    this.showLSideBar = this.lSideBar?.newAdBanners?.length > 0;
    this.showRSideBar = this.lSideBar?.newAdBanners?.length > 0;

    this.phasedInTaxYearPopover?.opened.subscribe(() => {
      window.addEventListener('scroll', this.onScroll, true);
    });

    this.phasedInTaxYearPopover?.closed.subscribe(() => {
      window.removeEventListener('scroll', this.onScroll, true);
    });
  }

  onScroll = (event: Event) => {
    this.phasedInTaxYearPopover.close();
  }

  get frontageWithUom(): string {
    return this.metersToFeetWithUom.transform(this.frontage, 2);
  }

  get polygonAreaWithUom(): string {
    return this.squareMetersToSquareFeetWithUom.transform(this.polygonArea, 0);
  }

  get depthWithUom(): string {
    return this.metersToFeetWithUom.transform(this.depth, 2);
  }

  get polygonPerimeterWithUom(): string {
    return this.metersToFeetWithUom.transform(this.polygonPerimeter, 0);
  }

  get assessmentAccess() {
    let accountTypeName: string | undefined = this.userService.user?.userProfile?.accountTypeName;
    let validCompanyAccountType: boolean | undefined = !_.isEmpty(accountTypeName) && accountTypeName?.toUpperCase().includes(CompanyAccountTypeEnum.NON_REB_WITH_MPAC.toString().toUpperCase());
    return this.userAccessControls.isMpsUser || validCompanyAccountType;
  }

  //returns the element for the ScrollToTopComponent component to scroll up to.
  public get propertyReportScrollToTopElement() {
    return document.getElementById('mainmap-controls-container');
  }
}

