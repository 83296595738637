import {BaseModel} from "../../../model/base-model";
import {PdfReportTypeEnum} from "../../../enum/pdf-report-type-enum";
import {PdfReportMyPropertyReportConfiguration} from "../../../model/pdf-report/input-param/pdf-report-my-property-report-configuration";
import {PdfReportBasicReportConfiguration} from "../../../model/pdf-report/input-param/pdf-report-basic-report-configuration";
import {PdfReportClientReportConfiguration} from "../../../model/pdf-report/input-param/pdf-report-client-report-configuration";


export class GeneratePdfReportUiDefinition extends BaseModel{
  radioButtonLabel: string; // make sure this is unique
  visible: boolean;
  description: string;
  customizable: boolean;
  selected: boolean;
  expanded: boolean;
  type: PdfReportTypeEnum;
  reportParam: PdfReportMyPropertyReportConfiguration | PdfReportBasicReportConfiguration | PdfReportClientReportConfiguration;
}
