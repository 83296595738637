import { BaseModel } from "../base-model";
import { PropertyViewedInsightRecord } from "./property-viewed-insight-record";

export class PropertyViewedInsight extends BaseModel {
    constructor(pvi?: PropertyViewedInsight) {
        super(pvi);

        if (pvi) {
            if (Array.isArray(pvi.trackingRecords)) {
                this.trackingRecords = pvi.trackingRecords.map(value => {
                    return new PropertyViewedInsightRecord(value);
                });
            }
        }
    }

    public pin: string;
    public trackingRecords: PropertyViewedInsightRecord[];

    public hasInsights = () => {
        return this.trackingRecords && this.trackingRecords.length > 0;
    }

}