import {BaseModel} from "../base-model";
import {DataService} from "../../../shared/service/data.service";

export class CondoSummary extends BaseModel{

  constructor( block: string, fullAddress?: string | null) {
    super();
    this.block = block;
    this.fullAddress = fullAddress ? fullAddress : DataService.STREETVIEW_ADDRESS_NOT_AVAILABLE;
  }

  block: string;
  fullAddress: string;
}
