export class Address {

  constructor(adr?: Address) {
    if (adr) {
      for (let property in adr) {
        if (adr.hasOwnProperty(property)) {
          // @ts-ignore
          this[property] = adr[property];
        }
      }
    }
  }

  unparsedAddress: string;
  streetNumber: string;
  streetName: string;
  streetType: string;
  municipality: string;
  postalCode: string;
  unitDesignator: string;
  unitNumber: string;
  province: string;

  get fullAddress(): string {
    return [
      this.unparsedAddress,
      this.municipality,
      this.province,
      this.postalCode]
      .filter(Boolean).join(' ');
  }
}
