import { AfterViewInit, Component, inject, NgModule, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { SSOService } from '../../shared/service/sso.service';
import { SSOOpenIDConnectJson } from '../../core/model/sso/sso-oidc-json';
import * as _ from "lodash";
import { EstoreService } from '../../shared/service/estore.service';
import { EstoreProductDetailTypeEnum } from 'src/app/core/enum/estore-product-detail-type-enum';
import { LoggerService } from '../../shared/service/log/logger.service';
import { BaseUnsubscribe } from '../../core/component/base-unsubscribe/base-unsubscribe';
import { environment } from "../../../environments/environment";

@Component({
  selector: 'gema3g-cart-main',
  templateUrl: './cart-main.component.html',
  styleUrls: ['./cart-main.component.scss']
})
export class CartMainComponent extends BaseUnsubscribe {

  constructor() {
    super();
  }

  private ssoService = inject(SSOService);
  private estoreService = inject(EstoreService);
  private loggerService = inject(LoggerService);

  ssoOIDCJson: SSOOpenIDConnectJson;
  oidcAccessToken: string;
  estoreUrl: string;

  getSSOTokenForEstore = async () => {

    try {
      this.loggerService.logInfo("calling sso service");
      this.ssoOIDCJson = await lastValueFrom(this.ssoService.getOpenIDConnectJson(), {defaultValue: new SSOOpenIDConnectJson()});
      this.oidcAccessToken = this.ssoOIDCJson.accessToken;
      this.loggerService.logInfo('successfully acquired estore access token', this.oidcAccessToken);

      if (!_.isEmpty(this.oidcAccessToken)) {
        //todo: fix domain
        this.estoreUrl = environment.url.E_STORE + this.estoreService.getProductDetailUrl(EstoreProductDetailTypeEnum.SHOPPING_CART, this.oidcAccessToken);
        this.loggerService.logInfo(`next estore url ${this.estoreUrl}`);
      }

    } catch (e) {
      this.loggerService.logError(e);
    } finally {
    }
  }


  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.getSSOTokenForEstore();  //TODO: this one goes to cart contents by default
    //throw new Error('Method not implemented.');
  }
}

//test urls
//https://dev.geowarehouse.ca/gwhweb/estore/ProductDetailFrame.jsp?gemasso=1&userId=kchua01&beid=81191&defaultTitle=&nextUrl=/gwhweb/viewCart.do
//https://dev.geowarehouse.ca/gwhweb/estore/ProductDetailFrame.jsp?gemasso=1&userId=kchua01&beid=81191&defaultTitle=&nextUrl=/gwhweb/purchasePYBReport.do%3FproductId%3D1101%26assessmentRollNumber%3D193601001014800%26pin%3D030200107%26rrf%3D6d710865-f834-4741-a388-a6687706d947
