import {Subject} from "rxjs";
import {Component, OnDestroy} from "@angular/core";

@Component({
  template: ''
})
export abstract class BaseUnsubscribe implements OnDestroy {
  protected ngUnsubscribe = new Subject<void>();

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}

