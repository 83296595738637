import { Component } from '@angular/core';

@Component({
  selector: 'gema3g-store-retail',
  templateUrl: './store-retail.component.html',
  styleUrls: ['./store-retail.component.scss']
})
export class StoreRetailComponent {

}
