import {Centroid} from "../spatial/centroid";
import {BaseModel} from "../base-model";

export class PinXY extends BaseModel{

  constructor(pinxy? : PinXY) {
    super(pinxy);
    if(pinxy){
      if (pinxy.centroid){
        this.centroid = new Centroid(pinxy.centroid.latitude, pinxy.centroid.longitude);
      }
    }
  }

  area: number;
  centroid: Centroid;
  commercial: boolean;
  condo: boolean;
  creationDate: string;
  muniId: number;
  nhoodId: number;
  perimeter: number;
  pin: string;
  spatialPin: string;
}
