export enum AutoSuggestSearchMode {
    //autosuggest shows both SHOW_RECENTLY_SEARCHED_RESULTS and SHOW_RECENTLY_VIEWED_RESULTS
    //when the search text length is less than OmnibarSearchService.MINIMUM_CHARACTERS_FOR_AUTOSUGGEST_SEARCH
    SHOW_RECENT_RESULTS_ONLY,

    //autosuggest shows the records found in lucene
    //when the search text length is equal to or greater than OmnibarSearchService.MINIMUM_CHARACTERS_FOR_AUTOSUGGEST_SEARCH
    SHOW_AUTOSUGGEST_RESULTS_ONLY,

    //free-text search
    WILDCARD_SEARCH,

    GET_RECENTLY_SEARCHED_SUGGESTED_RESULT,
    GET_RECENTLY_VIEWED_SUGGESTED_RESULT,

    GET_AUTO_SUGGESTED_OWNER_RESULT,
    GET_AUTO_SUGGESTED_FREEHOLD_RESULT,
    GET_AUTO_SUGGESTED_CONDO_RESULT,
    GET_AUTO_SUGGESTED_BLOCK_RESULT,
    GET_AUTO_SUGGESTED_MUNICIPALITY_RESULT,
    GET_AUTO_SUGGESTED_NOT_APPLICABLE_RESULT
}