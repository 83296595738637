 @if((searchResult && searchResult.length > 0) || (ownerToPropertyMap && ownerToPropertyMap.length > 0)){
  <gema3g-sort-search-results
    (onSortingSelected)="applySorting($event)"
    class="sr-sorting-container"
  >
  </gema3g-sort-search-results>
 }

<div (scroll)="onScroll($event)" class="sr-results-child">
  <gema3g-owner-to-property-map-search-results
    [ownerToPropertyMap]="ownerToPropertyMap"
    [isMobileFullScreen]="isMobileFullScreen"
    (onOwnerClick)="openOwnerView($event)"
    (onPropertyClick)="goToProperty($event)">

  </gema3g-owner-to-property-map-search-results>

  @if(searchResult && searchResult.length > 0){
  <gema3g-pii-search-results
    [searchResult]="searchResult"
    [isMobileFullScreen]="isMobileFullScreen"
    (onPropertyClick)="goToProperty($event)"
    (onCondoClick)="goToCondo($event)"
  >
  </gema3g-pii-search-results>
  }
</div>
