import {ProductCategoryGroup} from "./product-category-group";
import {BaseModel} from "../../base-model";
import {ProductCategoryItem} from "./product-category-item";

export class ProductCategory extends BaseModel {

  constructor(pc?: ProductCategory) {
    super(pc);
    if (pc) {
      this.groups = pc.groups.map(g => new ProductCategoryGroup(g));
    }
  }

  groups: ProductCategoryGroup[];

  addGroup(item1: ProductCategoryItem, item2: ProductCategoryItem, item3: ProductCategoryItem) {
    this.groups.push(new ProductCategoryGroup({item1, item2, item3}));
  }
}
