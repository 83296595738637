import {BaseModel} from "../../base-model";
import {lotSizeValues} from "../../../../shared/constant/constants";
import _ from "lodash";

export type DateRangeValues = 'LAST_30_DAYS' | 'LAST_90_DAYS' | 'LAST_6_MONTHS' | 'LAST_YEAR';
export type DateRangeType = 'CALENDAR' | 'PRESET';
export type OwnershipType = 'CONDO' | 'FREEHOLD' | 'ALL';

export class ComparableSearchCriteria extends BaseModel {
  constructor(searchCriteria?: ComparableSearchCriteria) {
    super(searchCriteria);
    this.lastDaysDateRangeType = (!!this.lastDaysDateRange) ? 'PRESET' : 'CALENDAR';
    if (this.minAmount) {
      this.minAmountDisplay = `${this.minAmount}`;
    } else {
      this.minAmountDisplay = `$0`;
    }
    if (this.maxAmount == 2147483647) {
      this.maxAmountDisplay = `$2,500,000+`;
    } else {
      this.maxAmountDisplay = `${this.maxAmount}`;
    }
    if(this.minArea < lotSizeValues[0].id){
      this.minArea = lotSizeValues[0].id;
    }
    if(this.maxArea > lotSizeValues[lotSizeValues.length - 1].id){
      this.maxArea = lotSizeValues[lotSizeValues.length - 1].id;
    }
    if(this.defaultSearchAreaSelection){
      // if not set (for first time users) backend grabs the value from sysdefault, which is capitalized
      this.defaultSearchAreaSelection = _.lowerCase(this.defaultSearchAreaSelection);
    }
  }

  calculateMunicipality: boolean;
  currentBuildingOnly: boolean;

  condo?: boolean | null;
  freehold?: boolean | null;

  get freeholdOrCondoOrAll(): OwnershipType {
    if (!!this.condo && !!this.freehold) {
      return 'ALL';
    }
    if (!!this.condo) {
      return 'CONDO';
    }
    if (!!this.freehold) {
      return 'FREEHOLD'
    }
    return 'ALL';
  }

  lastDaysDateRange?: DateRangeValues | null;
  lastDaysDateRangeType: DateRangeType;
  maxArea: number;
  minArea: number;
  minAmount: number;
  minAmountDisplay: string;
  maxAmount: number;
  maxAmountDisplay: string;
  radiusInMeters: string;
  defaultSearchAreaSelection: string;

  patchFromUserInput(searchCriteria?: ComparableSearchCriteria) {
    if (searchCriteria) {
      if (searchCriteria.freeholdOrCondoOrAll) {
        this.condo = searchCriteria.freeholdOrCondoOrAll == 'CONDO';
        this.freehold = searchCriteria.freeholdOrCondoOrAll == "FREEHOLD";
      }
      this.lastDaysDateRange = searchCriteria.lastDaysDateRangeType == 'CALENDAR' ? null : searchCriteria.lastDaysDateRange;
      this.minAmount = Number(searchCriteria.minAmountDisplay?.replace(/\D/g, '').replace(/^0+/, ''));
      this.maxAmount = Number(searchCriteria.maxAmountDisplay?.replace(/\D/g, '').replace(/^0+/, ''));
      this.maxArea = searchCriteria.maxArea;
      this.minArea = searchCriteria.minArea;
      this.defaultSearchAreaSelection = searchCriteria.defaultSearchAreaSelection;
    }
  }

  get minAreaImperialValue(): number {
    const lotSizeValue =  lotSizeValues.find(lotSize => lotSize.id == this.minArea);
    return (lotSizeValue) ? Number(lotSizeValue.imperialValue) : 0;
  }

  get minAreaMetricValue(): number {
    const lotSizeValue =  lotSizeValues.find(lotSize => lotSize.id == this.minArea);
    return (lotSizeValue) ? Number(lotSizeValue.metricValue) : 0;
  }

  get maxAreaImperialValue(): number {
    const lotSizeValue =  lotSizeValues.find(lotSize => lotSize.id == this.maxArea);
    return (lotSizeValue) ? Number(lotSizeValue.imperialValue) : 0;
  }

  get maxAreaMetricValue(): number {
    const lotSizeValue =  lotSizeValues.find(lotSize => lotSize.id == this.maxArea);
    return (lotSizeValue) ? Number(lotSizeValue.metricValue) : 0;
  }

  get minAreaImperialDisplayValue(): string {
    const lotSizeValue =  lotSizeValues.find(lotSize => lotSize.id == this.minArea);
    return (lotSizeValue) ? lotSizeValue.imperialDisplayValue : '';
  }

  get minAreaMetricDisplayValue(): string {
    const lotSizeValue =  lotSizeValues.find(lotSize => lotSize.id == this.minArea);
    return (lotSizeValue) ? lotSizeValue.metricDisplayValue : '';
  }

  get maxAreaImperialDisplayValue(): string {
    const lotSizeValue =  lotSizeValues.find(lotSize => lotSize.id == this.maxArea);
    return (lotSizeValue) ? lotSizeValue.imperialDisplayValue : '';
  }

  get maxAreaMetricDisplayValue(): string {
    const lotSizeValue =  lotSizeValues.find(lotSize => lotSize.id == this.maxArea);
    return (lotSizeValue) ? lotSizeValue.metricDisplayValue : '';
  }

  get lastDaysDateRangeNumber(): number {
    switch (this.lastDaysDateRange){
      case 'LAST_30_DAYS': return 30;
      case "LAST_90_DAYS": return 90;
      case "LAST_6_MONTHS": return 183;
      case "LAST_YEAR": return 365;
    }
    return 0;
  }

  get lastDaysDateRangeString(): string {
    switch (this.lastDaysDateRange){
      case 'LAST_30_DAYS': return 'Last 30 Days';
      case "LAST_90_DAYS": return 'Last 6 Months';
      case "LAST_6_MONTHS": return 'Last 90 Days';
      case "LAST_YEAR": return 'Last Year';
    }
    return '';
  }

  makeAdjustmentsOnSystemDefaultValues() {
    // "default system preference" has this in capital letters, converting it back
    if (this.defaultSearchAreaSelection){
      this.defaultSearchAreaSelection = this.defaultSearchAreaSelection.toLowerCase();
    }
    // minArea and maxArea do not come as indexes on lotSizeValues but as values
    const defaultMinArea = this.minArea;
    const defaultMaxArea = this.maxArea;
    this.minArea = lotSizeValues[0].id;
    this.maxArea = lotSizeValues[lotSizeValues.length-1].id;
    try {
      const defaultLostSizeValMinArea = lotSizeValues.find(lsv => lsv.value == defaultMinArea.toString(2));
      if(defaultLostSizeValMinArea){
        this.minArea = defaultLostSizeValMinArea.id;
      }
    } catch (e) {
      // we're going to use defaults on errors
    }
    try {
      const defaultLostSizeValMaxArea = lotSizeValues.find(lsv => lsv.value == defaultMaxArea.toString(2));
      if(defaultLostSizeValMaxArea){
        this.maxArea = defaultLostSizeValMaxArea.id;
      }
    } catch (e) {
      // we're going to use defaults on errors
    }
  }
}
