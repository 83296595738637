@if (structureAssessment && structureAssessment.length > 0) {
  <table id="ss-tbl-si-{{assessment.rollNumber}}" [dataSource]="structureInfoDataSource" class="h-[100%]" class="mat-elevation-z2 standard-table" mat-table>
    <ng-container matColumnDef="floorLevel">
      <th id="ss-tbl-hdr-si-fl-{{assessment.rollNumber}}" *matHeaderCellDef mat-header-cell>FLOOR LEVEL</th>
      <td id="ss-tbl-data-si-fl-{{assessment.rollNumber}}-{{element.floorLevel}}" *matCellDef="let element" mat-cell>
        {{(element?.baseInfo?.floorLevel) ? element.baseInfo.floorLevel : "N/A"}}
      </td>
    </ng-container>
    <ng-container matColumnDef="clearHeight">
      <th id="ss-tbl-hdr-si-ch-{{assessment.rollNumber}}" *matHeaderCellDef mat-header-cell>CLEAR HEIGHT</th>
      <td id="ss-tbl-data-si-ch-{{assessment.rollNumber}}-{{element.floorLevel}}" *matCellDef="let element" mat-cell>
        {{(element?.baseInfo?.height) ? element.baseInfo.height : "N/A"}}
      </td>
    </ng-container>
    <ng-container matColumnDef="buildingArea">
      <th id="ss-tbl-hdr-si-ba-{{assessment.rollNumber}}" *matHeaderCellDef mat-header-cell>BUILDING AREA</th>
      <td id="ss-tbl-data-si-ba-{{assessment.rollNumber}}-{{element.floorLevel}}" *matCellDef="let element" mat-cell>
        {{(element?.baseInfo?.floorArea != null) ? element.baseInfo.floorArea : "N/A"}}
      </td>
    </ng-container>
    <ng-container matColumnDef="interiorOfficeSpace">
      <th id="ss-tbl-hdr-si-ios-{{assessment.rollNumber}}" *matHeaderCellDef mat-header-cell>INTERIOR OFFICE SPACE</th>
      <td id="ss-tbl-data-si-ios-{{assessment.rollNumber}}-{{element.floorLevel}}" *matCellDef="let element" mat-cell>
        {{(element?.baseInfo?.interiorOfficeArea) ? element.baseInfo.interiorOfficeArea : "N/A"}}
      </td>
    </ng-container>
    <ng-container matColumnDef="yearBuilt">
      <th id="ss-tbl-hdr-si-yb-{{assessment.rollNumber}}" *matHeaderCellDef mat-header-cell>YEAR BUILT</th>
      <td id="ss-tbl-data-si-yb-{{assessment.rollNumber}}-{{element.floorLevel}}" *matCellDef="let element" mat-cell>
        {{(element?.baseInfo?.yearBuilt != null) ? element.baseInfo.yearBuilt : "N/A"}}
      </td>
    </ng-container>
    <tr id="ss-tbl-row-si-header-{{assessment.rollNumber}}" *matHeaderRowDef="structureInfoDisplayedColumns" mat-header-row></tr>
    <tr id="ss-tbl-row-si-{{assessment.rollNumber}}-{{row.floorLevel}}" *matRowDef="let row; columns: structureInfoDisplayedColumns;" mat-row></tr>
  </table>
}
