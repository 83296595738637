import {BaseModel} from "../base-model";

export class SpatialPoint extends BaseModel{
  public static MIN_LATITUDE = -85.05112878;
  public static MAX_LATITUDE = 85.05112878;
  public static MIN_LONGITUDE = -180;
  public static MAX_LONGITUDE = 180;

  latitude: number;
  longitude: number;

  clip(n: number, minValue: number, maxValue: number) {
    return Math.min(Math.max(n, minValue), maxValue);
  }

  truncatePoint() {
    this.longitude = this.clip(this.longitude, SpatialPoint.MIN_LONGITUDE, SpatialPoint.MAX_LONGITUDE);
    this.latitude = this.clip(this.latitude, SpatialPoint.MIN_LATITUDE, SpatialPoint.MAX_LATITUDE);
  }

  get latitudeRadians(): number {
    return this.latitude * Math.PI / 180;
  }

  get longitudeRadians(): number {
    return this.longitude * Math.PI / 180;
  }
}
