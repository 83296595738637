@if (hasPropertyInfo) {

  @if (inputParam.propertyInfo.hasProperty) {
    <div class="property-info">
      <div class="property-info-column">
        <div class="property-info-column-row">
          <div id="pdf-client-prop-details-label" class="property-info-column-row-item !text-left">
            Subject Property Address
          </div>
        </div>
      </div>
      <div class="property-info-column col-span-2">
        <div class="property-info-column-row">
          <div id="pdf-client-prop-details-info" class="property-info-column-row-item !text-left">
            {{ inputParam.propertyInfo.propertyDescription }} | {{ inputParam.propertyInfo.propertyPinOrArn }}
          </div>
        </div>
      </div>


      <div class="property-info-column">

        <div class="property-info-column-row">
          <div id="pdf-client-pd-label" class="property-info-column-row-item">
            Property Details
          </div>
          <div class="property-info-column-row-item">
            <mat-slide-toggle id="pdf-toggle-info-section-pd" [(ngModel)]="inputParam.propertyInfo.propertyDetail.enabled" color="primary"></mat-slide-toggle>
          </div>
        </div>

        @if (inputParam.propertyInfo.propertyDetail.enabled && inputParam.propertyInfo.propertyDetail.propertyHasStreetView) {
          <div class="property-info-column-row">
            <div class="text-end">
              <mat-checkbox id="pdf-is-sv" [(ngModel)]="inputParam.propertyInfo.propertyDetail.streetView">
                Street View
              </mat-checkbox>
            </div>
          </div>
        }

        @if (inputParam.propertyInfo.propertyDetail.enabled && inputParam.propertyInfo.propertyDetail.propertyHasAerialMapView) {
          <div class="property-info-column-row">
            <div class="text-end">
              <mat-checkbox id="pdf-is-av" [(ngModel)]="inputParam.propertyInfo.propertyDetail.aerialMapView">
                Aerial Map View
              </mat-checkbox>
            </div>
          </div>
        }

      </div>

      <div class="property-info-column">

        <div class="property-info-column-row">
          <div id="pdf-info-section-os-label" class="property-info-column-row-item">
            Ownership
          </div>
          <div class="property-info-column-row-item">
            <mat-slide-toggle id="pdf-toggle-info-section-os" [(ngModel)]="inputParam.propertyInfo.ownership" color="primary"></mat-slide-toggle>
          </div>
        </div>

        <mat-divider></mat-divider>

        @if (inputParam.propertyInfo.propertyHasLegalDescription) {
          <div class="property-info-column-row">
            <div id="pdf-info-section-ld-label" class="property-info-column-row-item">
              Legal Description
            </div>
            <div class="property-info-column-row-item">
              <mat-slide-toggle id="pdf-toggle-info-section-ld" [(ngModel)]="inputParam.propertyInfo.legalDescription" color="primary"></mat-slide-toggle>
            </div>
          </div>

          <mat-divider></mat-divider>
        }

        @if (inputParam.propertyInfo.propertyIsCondo) {
          <div class="property-info-column-row">
            <div id="pdf-info-section-ls-label" class="property-info-column-row-item">
              Condo Details
            </div>
            <div class="property-info-column-row-item">
              <mat-slide-toggle id="pdf-toggle-info-section-condo-details" [(ngModel)]="inputParam.propertyInfo.condo" color="primary"></mat-slide-toggle>
            </div>
          </div>
          <mat-divider></mat-divider>
        }

        @if (inputParam.propertyInfo.hasLotSize) {
          <div class="property-info-column-row">
            <div class="property-info-column-row-item">
              Lot Size
            </div>
            <div class="property-info-column-row-item">
              <mat-slide-toggle [(ngModel)]="inputParam.propertyInfo.lotSize" color="primary" id="pdf-toggle-info-section-lot-size"></mat-slide-toggle>
            </div>
          </div>
        }
      </div>

      <div class="property-info-column">
        @if (inputParam.propertyInfo.canDisplayAssessmentInfo) {
          <div class="property-info-column-row">
            <div id="pdf-info-section-ai-label" class="property-info-column-row-item">
              Assessment Information
            </div>
            <div class="property-info-column-row-item">
              <mat-slide-toggle id="pdf-toggle-info-section-ai" [(ngModel)]="inputParam.propertyInfo.assessmentInfo" color="primary"></mat-slide-toggle>
            </div>
          </div>
          <mat-divider></mat-divider>
        }

        @if (inputParam.propertyInfo.canDisplaySalesHistory) {
          <div class="property-info-column-row">
            <div id="pdf-info-section-sh-label" class="property-info-column-row-item">
              Sales History
            </div>
            <div class="property-info-column-row-item">
              <mat-slide-toggle id="pdf-toggle-info-section-sh" [(ngModel)]="inputParam.propertyInfo.salesHistory" color="primary"></mat-slide-toggle>
            </div>
          </div>

          <mat-divider></mat-divider>
        }

        <div class="property-info-column-row">
          @if (inputParam.propertyInfo.canDisplayDemographic) {
            <div id="pdf-info-section-dg-label" class="property-info-column-row-item">
              Demographics
            </div>
            <div class="property-info-column-row-item">
              <mat-slide-toggle id="pdf-toggle-info-section-dg" [(ngModel)]="inputParam.propertyInfo.demographic" color="primary"></mat-slide-toggle>
            </div>
          }
        </div>


      </div>

      @if (inputParam.propertyInfo.canDisplayDemographic && inputParam.propertyInfo.demographic) {
        <div class="col-span-3">
          <div class="text-end">
            <mat-checkbox id="pdf-checkbox-client-dmg" [(ngModel)]="inputParam.propertyInfo.dominantMarketGroup">
              Dominant Market Group
            </mat-checkbox>
          </div>
        </div>
      }

    </div>
  } @else {
    <div>No Subject Property Selected. The report will not contain any property information.</div>
    <div>Use a recently viewed property</div>
    <div class="property-list">
      @if (recentlyViewedProperties$ | async; as rvps) {
        @for (item of rvps; track item.text; let i = $index) {
          <div class="property-list-item cursor-pointer" (click)="setSubjectProperty(item.pin)">
            <a id="pdf-link-use-rvp-{{i}}">
              <p id="pdf-use-rvp-arn-{{i}}" *ngIf="item.pin == null && item.text.length === 15">Address Not Available<br/>ARN : {{ item.text }}</p>
              <p id="pdf-use-rvp-text-{{i}}" *ngIf="item.pin != null &&  item.text!=''">{{ item.text }}</p>
              <p id="pdf-use-rvp-pin-{{i}}" *ngIf="item.pin != null &&  item.text==''">Address Not Available<br/>PIN : {{ item.pin }}</p>
            </a>
          </div>
        }
      } @else {
        <div>Loading ...</div>
      }
    </div>
    <div>Want to generate reports for another property? <a (click)="goToComparableSales();" class="anchor">Search for a property in GeoWarehouse.</a></div>
  }
}

