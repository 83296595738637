import {BaseModel} from "../base-model";

export class SaleHistoryDetailed extends BaseModel{

  constructor(saleHistory?: SaleHistoryDetailed) {
    super(saleHistory);
    if (saleHistory) {
      if(Array.isArray(this.deededTogetherPins)){
        this.deededTogetherPins = [];
        this.deededTogetherPins.push(...saleHistory.deededTogetherPins);
      }
    }
  }

  registrationDate: Date;
  considerationAmt: number;
  instrumentNum: string;
  instrumentSubTypeCode: string;
  partyTo: string;
  notes: string;
  deededTogetherPins: string[];

  get instrumentSubTypeDescription() {

    if (this.instrumentSubTypeCode) {
      if (this.instrumentSubTypeCode === 'T') {
        return 'Transfer';
      } else if (this.instrumentSubTypeCode === 'TPSA') {
        return 'Transfer Under Power of Sale (Grant)';
      } else if (this.instrumentSubTypeCode === 'TRAPL') {
        return 'Transmission by Personal Representative (Land)';
      } else if (this.instrumentSubTypeCode === 'TLP') {
        return 'Transfer by Partnership';
      } else if (this.instrumentSubTypeCode === 'TPR') {
        return 'Transfer by Personal Representative';
      } else if (this.instrumentSubTypeCode === 'TTRBK') {
        return 'Transfer by Trustee in Bankruptcy';
      } else if (this.instrumentSubTypeCode === 'TRO') {
        return 'Transfer By Religious Organization';
      } else if (this.instrumentSubTypeCode === 'TAD') {
        return 'Transfer Administrators (Grant)';
      } else if (this.instrumentSubTypeCode === 'TAPOINT') {
        return 'Transfer And Appointment (Grant)';
      } else if (this.instrumentSubTypeCode === 'TEXEC') {
        return 'Transfer Executors (Grant)';
      } else if (this.instrumentSubTypeCode === 'TUSES') {
        return 'Transfer To Uses (Grant)';
      } else if (this.instrumentSubTypeCode === 'CGPLA') {
        return 'Crown Grant Public Lands Act';
      } else if (this.instrumentSubTypeCode === 'QC') {
        return 'Quit Claim - Transfer';
      }
    }

    return '';
  }
}
