import { BaseModel } from "../base-model";
import { RataReportRecord } from "./report-record";
import { RataSearchPageInfo } from "./search-page-info";

export class RataSearchResponse extends BaseModel {

    constructor(rsResponse?: RataSearchResponse) {
        super(rsResponse);

        if (rsResponse) {
            if (Array.isArray(rsResponse.reportRecords)) {
                this.reportRecords = rsResponse.reportRecords.map(value => {
                    return new RataReportRecord(value);
                });
            }
        }
    }

    public pageInfo: RataSearchPageInfo;
    public reportRecords: RataReportRecord[];
    public processId: number;

    public isRecordsExist() {
        return (this.reportRecords && this.reportRecords.length > 0) ? true : false;
    }
}