<div class="gema3g-ss-container">
  <div class="gema3g-ss-container__inner">
    <div class="gema3g-ss-container__inner-grid">


      <div class="l-column">
        <h4 id="ss-h4-vs-{{assessment.rollNumber}}">Valuation and Sales Information</h4>        
      </div>


      <div class="m-column">
        <div id="ss-vs-sd-label-{{assessment.rollNumber}}" class="flex">
          <h4 id="ss-h4-vs-sd-{{assessment.rollNumber}}">Last Valid Sale Date </h4>
          <span id="ss-vs-sd-{{assessment.rollNumber}}" class="ml-auto text-right">{{assessment.siteDetail?.lastValidSaleDate}}</span>
        </div>
        <div id="ss-vs-sa-label-{{assessment.rollNumber}}" class="flex">
          <h4 id="ss-h4-vs-sa-{{assessment.rollNumber}}">Last Valid Sale Amount </h4>
          <span id="ss-vs-sa-{{assessment.rollNumber}}" class="ml-auto text-right">{{assessment.siteDetail?.lastValidSaleAmount}}</span>
        </div>
      </div>


      <div class="r-column">
        <div id="ss-vs-lv-label-{{assessment.rollNumber}}" class="flex">
          <h4 id="ss-h4-vs-lv-{{assessment.rollNumber}}">Total Land Value </h4>
          <span id="ss-vs-lv-{{assessment.rollNumber}}" class="ml-auto text-right">{{assessment.siteDetail?.totalLandValue}}</span>
        </div>
        <div id="ss-vs-by-label-{{assessment.rollNumber}}" class="flex">
          <h4 id="ss-h4-vs-by-{{assessment.rollNumber}}">Total Land Value Base Year </h4>
          <span id="ss-vs-by-{{assessment.rollNumber}}" class="ml-auto text-right">{{assessment.siteDetail?.totalLandValueBaseYear}}</span></div>
      </div>


    </div>
  </div>
</div>