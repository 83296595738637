<div class="gema3g-ss-container">
  <div class="gema3g-ss-container__inner">
    <div class="gema3g-ss-container__inner-grid">

      <div class="l-column">
        <h4 id="ss-h4-ad-{{assessment.rollNumber}}">Assessment Details</h4>
      </div>

      <div class="m-column">
        <div id="ss-ad-cav-label-{{assessment.rollNumber}}" class="flex">
          <h4 id="ss-h4-ad-cav-{{assessment.rollNumber}}">Current Assessed Value </h4>
          <span id="ss-ad-cav-{{assessment.rollNumber}}" class="ml-auto">{{assessment.mpacAssessment?.property?.assessment?.value != null ? (assessment.mpacAssessment.property.assessment.value | currency : 'USD' : 'symbol' : '1.0-0') : "N/A"}}</span>
        </div>
        <div id="ss-ad-vd-label-{{assessment.rollNumber}}" class="flex">
          <h4 id="ss-h4-ad-vd-{{assessment.rollNumber}}">Valuation Date </h4>
          <span id="ss-ad-vd-{{assessment.rollNumber}}" class="ml-auto">{{assessment.mpacAssessment?.property?.valuationDate != null ? assessment.mpacAssessment.property.valuationDate : "N/A"}}</span>
        </div>
        @if (userAccessControls.MPACSubscriptionPremium) {
          <div id="ss-ad-nd-label-{{assessment.rollNumber}}" class="flex">
            <h4 id="ss-h4-ad-nd-{{assessment.rollNumber}}">Last Property Assessment Notice Date </h4>
            <span id="ss-ad-nd-{{assessment.rollNumber}}" class="ml-auto min-w-[100px] lg:min-w-[200px] text-right">{{assessment.mpacAssessment?.property?.lastNoticeDate != null ? assessment.mpacAssessment.property.lastNoticeDate : "N/A"}}</span>
          </div>
        }
      </div>

      <div class="r-column">
        @if (userAccessControls.MPACSubscriptionPremiumOrEnhanced) {
          <div id="ss-ad-pav-label-{{assessment.rollNumber}}" class="flex">
            <h4 id="ss-h4-ad-pav-{{assessment.rollNumber}}">Previous Assessed Value </h4>
            <span id="ss-ad-pav-{{assessment.rollNumber}}" class="ml-auto">{{assessment.mpacAssessment?.property?.assessment?.previousAssessment?.assessmentValue != null ? (assessment.mpacAssessment.property.assessment.value | currency : 'USD' : 'symbol' : '1.0-0') : "N/A"}}</span>
          </div>
        }
        @if (userAccessControls.MPACSubscriptionPremium) {
          <div id="ss-ad-rd-label-{{assessment.rollNumber}}" class="flex">
            <h4 id="ss-h4-ad-rd-{{assessment.rollNumber}}">Request for Reconsideration and Appeal Date </h4>
            <span id="ss-ad-rd-{{assessment.rollNumber}}" class="ml-auto min-w-[100px] lg:min-w-[200px] text-right">{{assessment.mpacAssessment?.property?.rfrAppealDate != null ? assessment.mpacAssessment.property.rfrAppealDate : "N/A"}}</span>
          </div>
        }
        @if (userAccessControls.MPACSubscriptionBasic) {
          <div id="ss-ad-ty-label-{{assessment.rollNumber}}" class="flex">
            <h4 id="ss-h4-ad-ty-{{assessment.rollNumber}}">Tax Year </h4>
            <span id="ss-ad-ty-{{assessment.rollNumber}}" class="ml-auto">{{assessment.mpacAssessment?.property?.overallPhaseIn?.phaseInTaxYear != null ? assessment.mpacAssessment.property.overallPhaseIn.phaseInTaxYear : "N/A"}}</span>
          </div>
        }
      </div>

    </div>
  </div>
</div>
