import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {DemographicsSocioEconomicItem} from "../../../../../../core/model/demographics/demographics-socio-economic-item";
import {DemographicsMetadata} from "../../../../../../core/model/demographics/demographics-metadata";
import {DemographicsChartsUtility} from "../../../../../../shared/utility/fusion-charts/demographics-charts.utility";
import {FusionChartDataSource} from "../../../../../../core/model/fusion-chart/fusion-chart-data-source";
import {DataService} from "../../../../../../shared/service/data.service";

@Component({
  selector: 'gema3g-employment',
  templateUrl: './employment.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./employment.component.scss']
})
export class EmploymentComponent implements OnChanges {
  @Input() socioEconomic: DemographicsSocioEconomicItem;
  @Input() metadata: DemographicsMetadata[];
  @Input() key: string;
  employmentStatus: FusionChartDataSource = new FusionChartDataSource();
  secondaryDominantProfessionCode: string = '';
  primaryDominantProfessionCode: string = '';

  calculateOtherValue(): number {
    return 100 - this.socioEconomic.percentageWithUniversityDegree -
      this.socioEconomic.percentageWithCollegeDiploma -
      this.socioEconomic.percentageWithHighSchoolDiploma;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.employmentStatus = DemographicsChartsUtility.employmentStatusDataSource(DataService.DEFAULT_CHART_FONT, this.key, this.socioEconomic, this.metadata, window.innerWidth);
    this.primaryDominantProfessionCode = DemographicsChartsUtility.getDescriptionOfMetadata(this.metadata, this.socioEconomic?.primaryDominantProfessionCode);
    this.secondaryDominantProfessionCode = DemographicsChartsUtility.getDescriptionOfMetadata(this.metadata, this.socioEconomic?.secondaryDominantProfessionCode);
  }
}
