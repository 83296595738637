<section class="store-front-container">
  <gema3g-header></gema3g-header>
  <gema3g-omnibar-search
    [searchEnabled]='false'
    [searchComparablesEnabled]='false'
    [renewNowButtonEnabled]='false'
  >
  </gema3g-omnibar-search>
  <div class="store-front-main">
    <gema3g-store-front-main></gema3g-store-front-main>
  </div>
</section>
