import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import {DataService} from "../service/data.service";

@Pipe(
  {
    name: 'fusionChartsNumber'
  }
)
export class FusionChartsNumberPipe implements PipeTransform {

  transform(input: any) {
    if (input === DataService.NOT_APPLICABLE){
      return DataService.NOT_APPLICABLE;
    }
    input = parseFloat(input);
    input = input.toFixed(0);
    return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}







