import {Component, Input} from '@angular/core';
import {CATALOGUE_TYPE, ProductCategoryItem} from "../../../../../core/model/store-front/product-category/product-category-item";
import {Route, Router} from "@angular/router";

@Component({
  selector: 'gema3g-store-front-product-category-item',
  templateUrl: './store-front-product-category-item.component.html',
  styleUrls: ['./store-front-product-category-item.component.scss']
})
export class StoreFrontProductCategoryItemComponent {

  constructor(private router: Router) {
  }

  @Input() productCategoryItem: ProductCategoryItem | undefined;

  openEstoreCatalogue(link: string | undefined, linkParam: CATALOGUE_TYPE | undefined) {
    if (link) {
      this.router.navigate([link, ProductCategoryItem.getLinkParam(<CATALOGUE_TYPE>linkParam)], );
    }
  }
}
