import { Component, effect, input, Input, OnChanges, OnInit, signal, SimpleChanges, WritableSignal } from '@angular/core';
import { MainMapService } from '../../../../home/home/main-map/main-map.service';
import { AutoSuggestRecentResult } from '../../../model/interface/autosuggest-recent-result';
import { LoggerService } from '../../../../shared/service/log/logger.service';
import { BaseUnsubscribe } from '../../base-unsubscribe/base-unsubscribe';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { baseUrl } from "../../../../shared/service/system";
import { environment } from "../../../../../environments/environment";
import * as _ from 'lodash';
import { UrlUtility } from '../../../../shared/utility/url.utility';
import { PIIService } from '../../../../shared/service/pii.service';
import { lastValueFrom } from 'rxjs';
import { MpacAssessmentService } from '../../../../shared/service/mpac-assessment.service';
import { XrefId } from '../../../../core/model/property/property-detail';
import { DataService } from '../../../../shared/service/data.service';
import { PinRollNumberXref } from '../../../../core/model/mpac/pin-rollnumber-xref';

@Component({
  selector: 'gema3g-recent-result',
  templateUrl: './recent-result.component.html',
  styleUrl: './recent-result.component.scss'
})
export class RecentResultComponent extends BaseUnsubscribe {

  constructor(private loggerService: LoggerService,
      private mainMapService: MainMapService,
      private piiService: PIIService,
      private mpacAssessmentService: MpacAssessmentService) {
    super();

    effect(() => {
      let usage: string | undefined = this.usage();
      let result: AutoSuggestRecentResult | undefined= this.result();
      
      this.prepareResultRow(usage, result);
    });
  }

  usage = input<string>();
  result = input<AutoSuggestRecentResult>();

  viewUsage: string;
  resultRow: AutoSuggestRecentResult;
  staticStreetViewThumbnailImageUrl: WritableSignal<string | null> = signal(null);
  faCircle = faCircle;
  
  prepareResultRow = async (usage: string | undefined, result: AutoSuggestRecentResult | undefined) => {
    if (usage && result) {
      this.viewUsage = usage;
      this.resultRow = result;

      let pin: string | null = null;
      let value: string | null = null;

      if (usage == 'recentlyViewed') {
        if (this.resultRow.pin) {
          pin = this.resultRow.pin;
        } else {
          if (this.piiService.isArn(this.resultRow.text)) {
            //todo get the pin
          }
        }
      }

      if (usage == 'recentlySearched') {
        //extract the pin from the result
        try {
          let url: URL = new URL('/' + baseUrl + '/' + this.resultRow.url, environment.url.ORIGIN);
          
          if (this.resultRow.url.includes('/property/pin?')) {
            pin = UrlUtility.getParameterValue(url, 'value');
            this.loggerService.logDebug(`omnibar result row pin ${pin}`);
          }

          if (this.resultRow.url.includes('/properties/block?')) {
            value = UrlUtility.getParameterValue(url, 'value');
            this.loggerService.logDebug(`omnibar result row block ${value}`);

            if (value && this.piiService.isBlockNumber(value)) {
              pin = this.piiService.getCondoBlockPin(value);
              this.loggerService.logDebug(`omnibar result row block pin ${pin}`);
            }
          }

          if (this.resultRow.url.includes('omnibar/properties')) {
            value = UrlUtility.getParameterValue(url, 'searchText');
            this.loggerService.logDebug(`omnibar result row property value ${value}`);

            if (value) {
              if (this.piiService.isPin(value)) {
                pin = value;
                this.loggerService.logDebug(`omnibar result row property pin ${pin}`);

              } else if (this.piiService.isArn(value)) {
                this.loggerService.logDebug(`omnibar result row property arn ${value}`);
                let xrefArray: PinRollNumberXref[] = await lastValueFrom(this.mpacAssessmentService.getAssessmentXRefByArn(value));

                if (!_.isEmpty(xrefArray)) {
                  let xref: PinRollNumberXref = xrefArray[0];
                  pin = xref.xrefId?.pin;
                  this.loggerService.logDebug(`omnibar result property xref arn ${value} pin ${pin}`);
                }
              }

            }
          }

        } catch (e) {
          this.loggerService.logError(`error getting pin from omnibar result url: usage ${usage}`, e);
        }
      }

      if (pin) {
        setTimeout(() => {
          this.displayPinThumbnail(pin);
        }, 100);
      }
    }
  }

  displayPinThumbnail = (pin: string | null) => {
    if (pin) {
      this.mainMapService.getSignedStaticStreetViewThumbnailImageUrl(pin, 100, 60).then((signedUrl) => {
        this.staticStreetViewThumbnailImageUrl.set(null);

        if (!_.isEmpty(signedUrl)) {
          this.staticStreetViewThumbnailImageUrl.set(signedUrl);
          this.loggerService.logDebug(`static streetview image url for omnibar recently viewed pin ${pin}: ${this.staticStreetViewThumbnailImageUrl}`);
        }
      });
    }
  }

  get noThumbnailImage() {
    return DataService.NO_STREETVIEW_IMAGE;
  }
}

