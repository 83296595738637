import {Pipe, PipeTransform} from "@angular/core";
import {DataService} from "../service/data.service";

@Pipe({
  standalone: true,
  name: 'addressOrNa'
})
export class AddressOrNaPipe implements PipeTransform {

  transform(value: any): any {
    if (value) {
      return value;
    }
    return DataService.ADDRESS_NOT_AVAILABLE;
  }
}
