@if (showResults) {
  <div id="src-if-show-results" class="sr-container">
    @if (!isSearching) {
      @if (totalSearchResults > 0) {
        <div id="src-if-show-all-results" class="header">
          <div class="header-left">
            @if (viewType == 'owner' || (viewType == 'condo' && !backButtonDisabledForCondo)) {
              <div (click)="backToMainView()">
                <gema3g-svg-icon id="src-icon-chevronleft" [fill]="'#ffffff'" [height]="34" [icon]="'chevronleft'" [width]="34"></gema3g-svg-icon>
              </div>
            }
          </div>
          <div class="header-middle">
            <div class="flex flex-col">
              @if (viewType == 'condo') {
                <ng-container id="src-condo-units">
                  Condo Units
                </ng-container>
              }
              @if (viewType !== 'condo') {
                <div>
                  <span id="src-search-results">
                    Search Results
                  </span>
                </div>
                @if (viewType === 'main' || viewType === 'instrument'){
                  <div id="src-search-resuts-counter" class="text-sm" [ngClass]="isMobileFullScreen ? 'mobile-visible' : 'mobile-hidden'">
                    {{ searchResultsCounter }}
                  </div>
                }
              }
              <div id="src-search-results-mobile" class="text-sm" [ngClass]="isMobileFullScreen ? 'mobile-hidden' : 'mobile-visible'">
                Expand to view results
              </div>
            </div>
          </div>
          <div class="header-right mobile-hidden">
            @if (expanded) {
              <div (click)="expanded = !expanded" class="chevron-up-down">
                <gema3g-svg-icon id="src-icon-chevronup" [fill]="'#ffffff'" [height]="34" [icon]="'chevronup'" [width]="34"></gema3g-svg-icon>
              </div>
            } @else {
              <div (click)="expanded = !expanded" class="chevron-up-down">
                <gema3g-svg-icon id="src-icon-chevrondown" [fill]="'#ffffff'" [height]="34" [icon]="'chevrondown'" [width]="34"></gema3g-svg-icon>
              </div>
            }
          </div>
          <div class="header-right mobile-visible">
            @if (!isMobileFullScreen) {
              <div (click)="mobileFullScreenMode(true)" class="chevron-up-down">
                <gema3g-svg-icon id="src-icon-chevronup" [fill]="'#ffffff'" [height]="34" [icon]="'expand'" [width]="34"></gema3g-svg-icon>
              </div>
            } @else {
              <div (click)="mobileFullScreenMode(false)" class="chevron-up-down">
                <gema3g-svg-icon id="src-icon-chevrondown" [fill]="'#ffffff'" [height]="34" [icon]="'collapse'" [width]="34"></gema3g-svg-icon>
              </div>
            }
          </div>
        </div>
      }
      @if (showNoResultsMessage) {
        <!--
        <div class="no-results">
          <div id="src-no-results-found">No Results Found</div>
          <div id="src-adjust-search-criteria">Please adjust your search criteria</div>
        </div>
        -->
        <div>{{ noResultsFound() }}</div>
      }
      <div [@expandDiv]="expanded ? 'in' : 'out'" [ngClass]="[expanded ? 'z-index-10' : 'z-index-0', isMobileFullScreen ? 'mobile-visible' : 'mobile-hidden']" class="search-results">
        @if (viewType == 'main') {
          <ng-container id="src-container-omnibar">
            <gema3g-omnibar-search-results
              (onOwnerClick)="onOwnerClick($event)"
              (onPropertyClick)="goToProperty($event)"
              (onCondoClick)="goToCondo($event)"
              (onDivScroll)="onScroll($event)"
              [ownerToPropertyMap]="ownerToPropertyMap"
              [searchResult]="searchResult.searchResult"
              [isMobileFullScreen]="isMobileFullScreen"
              >
            </gema3g-omnibar-search-results>
          </ng-container>
        }
        @if (viewType == 'owner') {
          <ng-container id="src-container-owner-search">
            <gema3g-owner-search-results
              (onPropertyClick)="goToProperty($event)"
              (onDivScroll)="onScroll($event)"
              [ownerToPropertyMap]="ownerWithMultiPropertyMap"
              [isMobileFullScreen]="isMobileFullScreen"
              >
            </gema3g-owner-search-results>
          </ng-container>
        }
        @if (viewType == 'condo') {
          <ng-container id="src-container-condo-search">
            <gema3g-condo-search-results
              (onPropertyClick)="goToProperty($event)"
              [condoLevels]="condoLevels"
              [condoAddress]="condoAddress"
              [isMobileFullScreen]="isMobileFullScreen"
              >
            </gema3g-condo-search-results>
          </ng-container>
        }
        @if (viewType == 'instrument') {
          <ng-container id="src-container-instrument-search">
            <gema3g-instrument-search-results
              (onDivScroll)="onScroll($event)"
              (onPropertyClick)="goToProperty($event)"
              [searchResult]="searchResult"
              >
            </gema3g-instrument-search-results>
          </ng-container>
        }
        @if(isSearchingForNextPage){
          <div class="searching">
            <img id="src-img-wait-spinner" alt="Searching..." src="/assets/img/icon-refresh.gif"/>
          </div>
        } @else if (viewType == 'main' && showNoMoreResultsMessage){
          <div>
            No more results
          </div>
        }
      </div>
    }
    @if (isSearching) {
      <div id="src-searching" class="searching">
        <img id="src-img-refresh" alt="Searching..." src="assets/img/icon-refresh.gif"/>
      </div>
    }
  </div>
}
