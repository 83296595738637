import {DataService} from "../service/data.service";

export class NumberUtility{

  //ported from gema 2g
  //TODO replace with currency pipe 1.0-0 if possible
  public static formatWithCommas = (amount: number | null): string => {
    if (amount == null) {
      return DataService.NOT_APPLICABLE;
    }
    var x = amount.toString().split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return '$' + x1 + x2;
  }

  public static isNotNullOrUndefined(input: any){
    return input !== null && typeof input !== 'undefined';
  }

  public static removingLeadingZero(input: any){
    if(NumberUtility.isNotNullOrUndefined(input)){
      return input.toString().replace(/^0+(?=\d)/, '');
    }
    return '0';
  }

}
