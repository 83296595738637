import {AfterViewInit, Component, inject, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogReturnTypeEnum} from "../../../enum/dialog-return-type-enum";
import {LoggerService} from "../../../../shared/service/log/logger.service";
import {ProgressBarWithTimeoutDialogData} from "./progress-bar-with-timeout-data";

@Component({
  selector: 'gema3g-progressbar-with-tinmeout-dialog',
  templateUrl: './progress-bar-with-timeout-dialog.component.html',
  styleUrls: ['./progress-bar-with-timeout-dialog.component.scss']
})
export class ProgressBarWithTimeoutDialogComponent implements AfterViewInit {
  constructor(
    public dialogRef: MatDialogRef<ProgressBarWithTimeoutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProgressBarWithTimeoutDialogData,
  ) {
  }

  loggerService = inject(LoggerService);

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.dialogRef.close(DialogReturnTypeEnum.FIRST_BUTTON);
      this.loggerService.logDebug(`Progress Bar With Timeout Dialog: ${this.data.title} completed`);
    }, this.data?.timeout ?? 1000);
  }
}
