import { SearchComparablesEnum } from "../../../core/enum/search-comparables.enum";
import { SearchComparablesRadiusOption } from "../../../core/model/comparables/search-comparables-radius-option";
import { BaseUnsubscribe } from "../../../core/component/base-unsubscribe/base-unsubscribe";

export class SearchComparablesFormBase extends BaseUnsubscribe {

    constructor() {
        super();

        this.yearBuiltFrom = [];
        for (let i = this.thisYear; i > 1900; i--) {
            this.yearBuiltFrom.push(i as unknown as string);
        }
        this.yearBuiltFrom.push(this.LESS_THAN_1901);

        this.yearBuiltTo = [];
        for (let i = this.thisYear; i > 1900; i--) {
            this.yearBuiltTo.push(i as unknown as string);
        }
    }

    SEARCH_BY_RADIUS: SearchComparablesEnum = SearchComparablesEnum.SEARCH_BY_RADIUS;
    SEARCH_BY_POLYGON: SearchComparablesEnum = SearchComparablesEnum.SEARCH_BY_POLYGON;
    SEARCH_BY_MUNICIPALITY: SearchComparablesEnum = SearchComparablesEnum.SEARCH_BY_MUNICIPALITY;
    searchByTypes: SearchComparablesEnum[] = [this.SEARCH_BY_RADIUS, this.SEARCH_BY_POLYGON, this.SEARCH_BY_MUNICIPALITY];
    INFINITY: number = 2147483647;
    INFINITY_VALUE_DISPLAY: string = "2,500,000+";
    INFINITY_SLIDER: number = 2500000;
    BUILD_AREA_INFINITY: number = 10000000;
    BUILD_AREA_INFINITY_VALUE_DISPLAY: string = "10,000,000+";
    LESS_THAN_1901: string = '< 1901';
    thisYear: number = new Date().getFullYear();
    yearBuiltFrom: string[];
    yearBuiltTo: string[];

    radiusDropDown = [
        new SearchComparablesRadiusOption(250, "250m", 16, "250m", "820ft", false),
        new SearchComparablesRadiusOption(500, "500m", 15, "500m", "1640ft", false),
        new SearchComparablesRadiusOption(750, "750m", 15, "750m", "2460ft", false),
        new SearchComparablesRadiusOption(1000, "1km", 15, "1km", "0.62mi", false),
        new SearchComparablesRadiusOption(2500, "2.5km", 13, "2.5km", "1.6mi", false),
        new SearchComparablesRadiusOption(5000, "5km", 12, "5km", "3.1mi", false),
        new SearchComparablesRadiusOption(10000, "10km", 11, "10km", "6.2mi", false)
    ];

    totalBuildAreas = [
        { "value": 0, "display": "0" },
        { "value": 100, "display": "100" },
        { "value": 500, "display": "500" },
        { "value": 1000, "display": "1,000" },
        { "value": 2000, "display": "2,000" },
        { "value": 5000, "display": "5,000" },
        { "value": 10000, "display": "10,000" },
        { "value": 15000, "display": "15,000" },
        { "value": 30000, "display": "30,000" },
        { "value": 50000, "display": "50,000" },
        { "value": 100000, "display": "100,000" },
        { "value": 200000, "display": "200,000" },
        { "value": 500000, "display": "500,000" },
        { "value": 1000000, "display": "1,000,000" },
        { "value": 5000000, "display": "5,000,000" },
        { "value": this.BUILD_AREA_INFINITY, "display": this.BUILD_AREA_INFINITY_VALUE_DISPLAY }
    ];

    minPriceRange = [
        {
            "id": 0,
            "value": "0"
        }, {
            "id": 100000,
            "value": "100,000"
        }, {
            "id": 200000,
            "value": "200,000"
        }, {
            "id": 300000,
            "value": "300,000"
        }, {
            "id": 400000,
            "value": "400,000"
        }, {
            "id": 500000,
            "value": "500,000"
        }, {
            "id": 600000,
            "value": "600,000"
        }, {
            "id": 700000,
            "value": "700,000"
        }, {
            "id": 800000,
            "value": "800,000"
        }, {
            "id": 900000,
            "value": "900,000"
        }, {
            "id": 1000000,
            "value": "1,000,000"
        }
    ];

    maxPriceRange = [
        {
            "id": 200000,
            "value": "200,000"
        }, {
            "id": 300000,
            "value": "300,000"
        }, {
            "id": 400000,
            "value": "400,000"
        }, {
            "id": 500000,
            "value": "500,000"
        }, {
            "id": 600000,
            "value": "600,000"
        }, {
            "id": 700000,
            "value": "700,000"
        }, {
            "id": 800000,
            "value": "800,000"
        }, {
            "id": 900000,
            "value": "900,000"
        }, {
            "id": 1000000,
            "value": "1,000,000"
        }, {
            "id": 1500000,
            "value": "1,500,000"
        }, {
            "id": this.INFINITY,
            "value": this.INFINITY_VALUE_DISPLAY
        }
    ];

    minAssessmentRange = [
        {
            "id": 0,
            "value": "0"
        }, {
            "id": 100000,
            "value": "100,000"
        }, {
            "id": 200000,
            "value": "200,000"
        }, {
            "id": 300000,
            "value": "300,000"
        }, {
            "id": 400000,
            "value": "400,000"
        }, {
            "id": 500000,
            "value": "500,000"
        }, {
            "id": 600000,
            "value": "600,000"
        }, {
            "id": 700000,
            "value": "700,000"
        }, {
            "id": 800000,
            "value": "800,000"
        }, {
            "id": 900000,
            "value": "900,000"
        }, {
            "id": 1000000,
            "value": "1,000,000"
        }
    ];

    maxAssessmentRange = [
        {
            "id": 200000,
            "value": "200,000"
        }, {
            "id": 300000,
            "value": "300,000"
        }, {
            "id": 400000,
            "value": "400,000"
        }, {
            "id": 500000,
            "value": "500,000"
        }, {
            "id": 600000,
            "value": "600,000"
        }, {
            "id": 700000,
            "value": "700,000"
        }, {
            "id": 800000,
            "value": "800,000"
        }, {
            "id": 900000,
            "value": "900,000"
        }, {
            "id": 1000000,
            "value": "1,000,000"
        }, {
            "id": 1500000,
            "value": "1,500,000"
        }, {
            "id": this.INFINITY,
            "value": this.INFINITY_VALUE_DISPLAY
        }
    ];

    //renamed to propertyTypesMPS to not confuse ourselves with the non-mps property types
    propertyTypesMPS = [{
        "label": "All Property Types",
        "code": -1
    },
    {
        "label": "Commercial",
        "code": 3
    },
    {
        "label": "Farm",
        "code": 6
    },
    {
        "label": "Industrial",
        "code": 4
    },
    {
        "label": "Multi-Residential",
        "code": 2
    },
    {
        "label": "Residential",
        "code": 1
    },
    ];

}
