import { Injectable, Inject, inject } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { LoggerService } from "../../../shared/service/log/logger.service";
import { DOCUMENT } from '@angular/common';
import { UserService } from "../../../shared/service/user.service";
import { AuthenticationService } from "../../../shared/service/authentication.service";
import { lastValueFrom } from "rxjs";
import { UrlService } from "../../../shared/service/url.service";
import { User } from "../../model/user/user";

/**
 * This Auth guard restricts routes to valid users in the system.
 * A valid user:
 * 1. must be logged in,
 * 2. must have accepted the terms and conditions and
 * 3. must have initialized the user profile record.
 */
@Injectable({ providedIn: 'root' })
export class ValidUserAuthGuard {
  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  private loggerService = inject(LoggerService);
  private authService = inject(AuthenticationService);
  private userService = inject(UserService);
  private urlService = inject(UrlService);
  private router = inject(Router);

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree> {
    let user: User | undefined;
    let isUserLoggedIn: boolean = false;
    let isLegalAgreementAccepted: boolean = false;
    let isUserProfileInitialized: boolean = false;
    let companyTermsAndConditionsAcceptanceRequired: boolean = false;
    let loggedInBeId: number = this.userService.user?.businessEntityId;

    try {
      user = this.userService.user;

      isUserLoggedIn = this.authService.isLoggedIn;
      isLegalAgreementAccepted = user.isLegalAgreementAccepted;
      isUserProfileInitialized = user.isProfileAccepted;
      companyTermsAndConditionsAcceptanceRequired = user.requireLegalAgreement;
      this.userService.user?.businessEntityId;

      this.loggerService.logDebug(`user ${loggedInBeId} logged in? ${isUserLoggedIn}, legal agreement accepted? ${isLegalAgreementAccepted}, user profile initialized? ${isUserProfileInitialized}`);
      this.loggerService.logDebug(`company ${user.companyBeid} terms and conditions required? ${companyTermsAndConditionsAcceptanceRequired}`);

      /*
      if (isUserLoggedIn && (!companyTermsAndConditionsAcceptanceRequired || (isLegalAgreementAccepted && isUserProfileInitialized))) {
        return true;
      }
      */

      if (isUserLoggedIn && isLegalAgreementAccepted && isUserProfileInitialized) {
        return true;
      }

    } catch (e) {
      this.loggerService.logDebug('error occurred in valid user authguard', e);
    }

    this.loggerService.logWarning(`redirecting user ${loggedInBeId} to the requested route ${route.url} encountered issues`);

    if (!isUserLoggedIn) {
      //send the user back to the landing page
      //TODO sso and non-sso have different landing pages in this scenario
      this.urlService.goToLoginPage();

    } else if (!isLegalAgreementAccepted) {
      this.loggerService.logDebug(`redirecting user ${loggedInBeId} to accept the legal agreement`);
      this.urlService.goToAcceptTermsAndConditionsPage();

    } else if (!isUserProfileInitialized) {
      this.loggerService.logDebug(`redirecting user ${loggedInBeId} to initialize user profile`);
      this.urlService.goToInitializeUserProfilePage();
    }

    return false;
  }
}
