import { Component, inject, ViewChild } from '@angular/core';
import { UserService } from "../../../shared/service/user.service";
import { lastValueFrom, Observable } from "rxjs";
import { UserReportCounter } from "../../../core/model/user/user-report-counter";
import { UserAccessControl } from "../../../core/model/user/user-access-control";
import { Router } from "@angular/router";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { TrackingRecord } from "../../../core/model/user/tracking-record";
import { MatDialog } from "@angular/material/dialog";
import { LoggerService } from '../../../shared/service/log/logger.service';
import { DataService } from "../../../shared/service/data.service";
import { RouteMappingUtility } from "../../../shared/utility/route-mapping-utility";
import { EstoreProductCategoryEnum } from "../../../core/enum/estore-product-category-enum";
import { PropertyReportService } from 'src/app/shared/service/property-report.service';

@Component({
  selector: 'gema3g-report-history',
  templateUrl: './report-history.component.html',
  styleUrls: ['./report-history.component.scss']
})
export class ReportHistoryComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  userReportCounter$: Observable<UserReportCounter>;
  userAccessControls: UserAccessControl;
  isLoading: boolean = true;
  displayedColumns: string[] = ['date', 'source', 'propertyAddress', 'pin_arn'];
  dataSource = new MatTableDataSource<TrackingRecord>();
  items: TrackingRecord[];
  pageEvent: Promise<void>;
  pageIndex: number;
  pageSize: number;
  length: number = 0;
  pageSizes = [5, 10, 20];
  hasAccessToMoreReports: boolean = false;
  openPropertyReportTooltipText: string = DataService.PROPERTY_REPORT_CLICK_TO_OPEN;

  warningMessage: string = DataService.ACCOUNT_PROPERTY_REPORT_HISTORY_WARNING_MESSAGE;
  warningThreshold: number = DataService.ACCOUNT_PROPERTY_REPORT_HISTORY_WARNING_THRESHOLD;

  constructor() {

    this.load().then(() => {
      this.isLoading = false;
    });
    setTimeout(() => {
      this.getReport('', null);
    }, 100);

  }

  private userService = inject(UserService);
  private router = inject(Router);
  private dialog = inject(MatDialog);
  private loggerService = inject(LoggerService);
  private dataService = inject(DataService);
  private propertyReportService = inject(PropertyReportService);

  async load() {
    this.userReportCounter$ = this.userService.getUserReportCounter();
    this.userAccessControls = this.userService.getUserAccessControl();
    let user = await lastValueFrom(this.userService.getUser(false));
    this.hasAccessToMoreReports = this.userAccessControls.hasTopUpAccess;
  }

  openEstoreCatalogue() {
    this.router.navigate(['../catalogue', RouteMappingUtility.eStoreCategoryToRoute(EstoreProductCategoryEnum.ALL)]);
  }

  async getReport(source: string, $event: any) {
    this.loggerService.logInfo($event);

    this.isLoading = true;
    try {
      const download = await lastValueFrom(this.userService.getViewedReportHistory(this.paginator.pageIndex, this.paginator.pageSize));
      this.items = [];
      this.items.push(...download.activities.map(value => {
        return new TrackingRecord(value);
      }));
      this.dataSource = new MatTableDataSource(this.items);
      this.length = download.totalItems;
      this.pageSize = this.paginator?.pageSize;
    } finally {
      this.isLoading = false;
    }
  }

  async goToPinOrArn(element: TrackingRecord) {
    let qp = {};
    if (this.dataService.isPin(element.pin)) {
      qp = {
        pin: element.pin
      }
    } else if (this.dataService.isArn(element.pin)) {
      qp = {
        arn: element.pin
      }
    }
    await this.router.navigate(['/home'], {
      queryParams: qp
    });
  }
}
