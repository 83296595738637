import {BaseModel} from "../../base-model";

export class ComparableSalesReportCompsReportEntry extends BaseModel{
  compId: number;
  reportId: number;
  pin: string;
  assessmentRollNumber: string;
  xml: any;
  modifiedDate: number;
}
