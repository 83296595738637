import { HttpClient } from '@angular/common/http';
import { baseUrl } from "../system";
import {inject, Injectable} from '@angular/core';
import { map, catchError, throwError, Observable, lastValueFrom } from 'rxjs';
import * as _ from 'lodash';
import { LoggerService } from '../log/logger.service';
import { Spatial } from '../../../core/model/spatial/spatial';
import { Pii } from '../../../core/model/property/pii';

@Injectable({
    providedIn: 'root'
})
export class SpatialSearchService {

    private https = inject(HttpClient);
    private loggerService = inject(LoggerService);

    getPINByLatLng = async (latitude: number, longitude: number) => {
        let pii: Pii = await lastValueFrom(this.getPIIByLatLng(latitude, longitude), {defaultValue: new Pii()});
        return pii?.pin;
    }

    getPIIByLatLng = (latitude: number, longitude: number): Observable<Pii> => {
        const url = baseUrl + '/property/pii/xy?lat=' + latitude + '&lon=' + longitude;
        this.loggerService.logDebug(`calling ${url} to get pii by latitude ${latitude}, longitude ${longitude}`);

        return this.https.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).pipe(
            map(response => {
                if (!_.isEmpty(response)) {
                    return new Pii(<Pii>response);
                } else {
                    return new Pii();
                }
            }),
            catchError((err) => {
                this.loggerService.logError(`error getting pii by latitude ${latitude}, longitude ${longitude}`, err);
                return throwError(err);
            })
        );
    }

    getARNByLatLng = (latitude: number, longitude: number): Observable<any> => {
        const url = baseUrl + '/assessment/number?lat=' + latitude + '&lon=' + longitude;
        this.loggerService.logDebug(`calling ${url} to get ARN by latitude ${latitude}, longitude ${longitude}`);

        return this.https.get(url, {
            headers: {
                'Content-Type': 'application/text'
            },
            responseType: 'text'
        }).pipe(
            map(response => {
                    return response;
            }),
            catchError((err) => {
                this.loggerService.logError(`error getting ARN by latitude ${latitude}, longitude ${longitude}`, err);
                return throwError(err);
            })
        );
    }

    getPolygonsByBlock = (block: string): Observable<Spatial[]> => {
        const url = baseUrl + `/spatial/polygons/${block}`;
        this.loggerService.logDebug(`calling ${url} to get polygons by block ${block}`);

        return this.https.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).pipe(
            map(response => {
                if (!_.isEmpty(response)) {
                    return response as Spatial[];
                } else {
                    return [] as Spatial[];
                }
            }),
            catchError((err) => {
                this.loggerService.logError(`error getting polygons by block ${block}`, err);
                return throwError(err);
            })
        );
    }

    getBlockCentroid = (block: string): Observable<Spatial> => {
        const url = baseUrl + `/spatial/centroid/${block}`;
        this.loggerService.logDebug(`calling ${url} to get centroid by block ${block}`);

        return this.https.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).pipe(
            map(response => {
                if (!_.isEmpty(response)) {
                    return response as Spatial;
                } else {
                    return {} as Spatial;
                }
            }),
            catchError((err) => {
                this.loggerService.logError(`error getting centroid by block ${block}`, err);
                return throwError(err);
            })
        );
    }
}
