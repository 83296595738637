export class FusionChartDatasetData{
  value: number | string;
  valuePosition: string;
  valueBgColor: string;
  valueFontSize: number;

  constructor(value: number | string) {
    this.value = value;
  }
}
