import {BaseModel} from "../../base-model";

export class  ComparableSalesReportIncomeData extends BaseModel{
  arn: string;
  numberOfStories: string;
  officeMTFATotal: number;
  officeSTFATotal: number;
  retailTotalGLA: number;
  yearBuilt: string;
}
