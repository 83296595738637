import {AdBannerActionEnum} from "../../enum/ad-banner-action-enum";

export class AdBannerAction {

  constructor(actionType: AdBannerActionEnum = AdBannerActionEnum.NAVIGATE_TO_ROUTE, navigateTo: string[] = [], openUrl?: string) {

    if (Array.isArray(navigateTo) && navigateTo.length > 0) {
      this.actionType = AdBannerActionEnum.NAVIGATE_TO_ROUTE;
      this.navigateTo = navigateTo;
    } else if (openUrl) {
      this.actionType = AdBannerActionEnum.OPEN_URL;
      this.openUrl = openUrl;
    } else {
      this.actionType = actionType;
    }

  }

  actionType: AdBannerActionEnum;
  navigateTo: string[] = [];
  openUrl: string;
}
