import { BuildVersion } from "../../model/miscellaneous/build-version";

export class AboutDialogData {
    constructor(title: string, buildVersion: BuildVersion, closebuttonText: string) {
        this.title = title;
        this.buildVersion = buildVersion;
        this.buildText = this.buildVersion?.version;
        this.closebuttonText = closebuttonText;
    }

    title: string;
    buildVersion: BuildVersion;
    buildText: string;
    closebuttonText: string;
    thisYear = new Date().getFullYear();
}
  