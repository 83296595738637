export enum RataFilterKeyEnum {
    LRO = 'lro',
    LRO_NUMBER = 'lroNumber',
    INSTRUMENT_TYPE = 'instrumentType',
    CHARGE_INSTRUMENT_TYPE = 'chargeInstrumentType',
    TRANSFER_INSTRUMENT_TYPE = 'transferInstrumentType',
    INSTRUMENT_TYPE_DESCRIPTION = 'instrumentDescription',
    CHARGE_INSTRUMENT_TYPE_DESCRIPTION = 'chargeTypeFilterDescription',
    TRANSFER_INSTRUMENT_TYPE_DESCRIPTION = 'transferTypeFilterDescription',
    PIN = 'pin',
    PARTY_FROM = 'partyFrom',
    PARTY_TO = 'partyTo',
    REGISTRATION_DATE = 'registrationDate',
    CHARGE_REGISTRATION_DATE = 'chargeRegistrationDate',
    TRANSFER_REGISTRATION_DATE = 'transferRegistrationDate'
}