@if (product?.title) {
  <div class="catalogue-product-container">
    <mat-card-header>
      @if (product.hasRibbons) {
        <div class="cp-special-offer-ribbon">
          <span id="cp-ribbon-label-{{product.prodId}}">{{ product.ribbonText }}</span>
        </div>
      }
    </mat-card-header>

    <mat-card-content>
      <div class="block sm:flex flex-row min-h-[180px]">
        <div class="flex flex-col w-1/3">
          <img id="cp-img-{{product.prodId}}" [ngClass]="imageClass()" [src]="product.gema3Image">
          @if (product.category === 'catGwhRSubspn' && product.licensePackageId > 0 && product.pricedesc.desc) {
            <div class="cp-middle-ribbon text-xxs">
              {{ product.pricedesc.desc }}
            </div>
          }
          @if (product.samplelink?.trim() && !product.hasLicensePackageId) {
            <a
              href="{{product.samplelink}}"
              target="_blank"
              class="anchor text-[9px] md:text-[10px] lg:text-[12px]"
              id="cp-link-sample-{{product.prodId}}">
              View Sample
            </a>
          }
        </div>
        <div class="pl-1 w-2/3">
          <div id="cp-title-label-{{product.prodId}}" class="text-os-bold mr-1.5" [ngClass]="product.licensePackageId > 0 ? 'text-os-bold cp-green' : 'text-os-bold'" [innerHTML]="product.title"></div>
          @if (product.description) {
            <div id="cp-desc-start-{{product.prodId}}" [innerHtml]="product.description.starthtml" class="cp-start-html"></div>
            @if (product.description.bullets.length > 0) {
              <ul [ngClass]="{'ul-after-text':product.description.starthtml}">
                @for (bullet of product.description.bullets; track $index) {
                  <li class="cp-bullet"><span id="cp-desc-bullet-{{bullet}}-{{product.prodId}}" [innerHtml]="bullet"></span></li>
                }
              </ul>
            }
          }
          <div id="cp-desc-end-{{product.prodId}}" [innerHtml]="product?.description?.endhtml" class="cp-end-html"></div>
        </div>

      </div>
      @if (product.category === 'catGwhRSubspn' && product.licensePackageId > 0 && product.pricedesc.noOfPropertyReports) {
        <div>
          <span class="text-os-bold" id="cp-prod-pr-desc-nr-pr-rep">{{ product.pricedesc.noOfPropertyReports }}</span>
        </div>
      }
    </mat-card-content>

    <mat-card-actions align="end" class="mt-auto ml-auto">
      <div class="block sm:flex items-center">
        <div class="flex gap-1 pr-3">
          <button id="cp-prev-item-btn" class="mat-green rounded small-button" mat-raised-button [disabled]="isButtonDisabled" (click)="previousItem()">&#60;</button>
          <button id="cp-next-item-btn" class="mat-green rounded small-button" mat-raised-button [disabled]="isButtonDisabled" (click)="nextItem()">&#62;</button>
        </div>
        <div class="get-dense-3 text-right">

          <button id="cp-btn-details-{{product.prodId}}-{{product.index}}"
                  (click)="buttonClicked()"
                  [ngClass]="getButtonColor()"
                  mat-raised-button
                  [disabled]="isButtonDisabled">
            {{ buttonCaption || product.action }}
          </button>

        </div>
      </div>
    </mat-card-actions>
  </div>
}

@if (!product.title) {
  <div class="w-72"></div>
}
