import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DemographicsPopulation} from "../../../../../core/model/demographics/demographics-population";
import {DemographicsAreaEnum} from "../../../../../core/enum/demographics-area.enum";
import {Demographics} from "../../../../../core/model/demographics/demographics";
import {DemographicsSocioEconomic} from "../../../../../core/model/demographics/demographics-socio-economic";
import {GenderStatusComponent} from "./gender-status/gender-status.component";
import {FamilyStatusComponent} from "./family-status/family-status.component";
import {MaterialModule} from "../../../../../core/material.module";
import {MaritalStatusComponent} from "./marital-status/marital-status.component";
import {AgeDistributionComponent} from "./age-distribution/age-distribution.component";
import {CoreModule} from "../../../../../core/core.module";


@Component({
  selector: 'gema3g-demographics-population',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './demographics-population.component.html',
  imports: [
    GenderStatusComponent,
    FamilyStatusComponent,
    MaterialModule,
    MaritalStatusComponent,
    AgeDistributionComponent,
    CoreModule
  ],
  styleUrls: ['./demographics-population.component.scss']
})
export class DemographicsPopulationComponent {
  @Input() demographicsData: Demographics = new Demographics();
  demographicsAreaEnum = DemographicsAreaEnum;
  @Input() selectedArea: DemographicsAreaEnum[] = [];

  get demographicsPopulation(): DemographicsPopulation {
    return this.demographicsData?.population;
  }

  get socioEconomic(): DemographicsSocioEconomic {
    return this.demographicsData?.socioEconomic;
  }
}
