import {BaseModel} from "../base-model";
import {PlanSurveyMapItData} from "./plan-survey-map-it-data";

export class PlanSurvey extends BaseModel {

  constructor(planSurvey?: PlanSurvey) {
    super(planSurvey);
    if (planSurvey) {
      if (planSurvey.mapItData) {
        this.mapItData = new PlanSurveyMapItData(planSurvey.mapItData);
      }
    }
  }

  registrationYear: number;
  documentNumber: string;
  originalDocumentNumber: string;
  planCategory: string;
  planDesc: string;
  lro: string;

  mapItData: PlanSurveyMapItData;
  companyBEId: number;
  companyFullName: string;
  companySurveyId: number;
  surveyImageId: string;
  enabledForSale: string;
  surveyTypeDesc: string;
  surveyDate: string;
  surveyTypeCode: string;
  originalSurveyDate: string;
  planSubCategory: any;
  token: string;
  tokenStr: string;
  spatial: boolean;
  price: number;
  mockedSurveyDate: string;
}
