import {ComparableSalesReportPropertyAssessment} from "./comparable-sales-report-property-assessment";
import {ComparableSalesReportPii} from "./comparable-sales-report-pii";
import {BaseModel} from "../../base-model";
import {DataService} from "../../../../shared/service/data.service";

export class ComparableSalesReportSubjectArn extends BaseModel {
  mpacPropertyAssessmentVO: ComparableSalesReportPropertyAssessment;
  pii: ComparableSalesReportPii;
  labelsBase: string;
  labelsBaseShort: string;
  labelsPhasedIn: string;
  labelsPhasedInShort: string;

  constructor(csrsa?: ComparableSalesReportSubjectArn) {
    super(csrsa);
    if (csrsa) {
      this.mpacPropertyAssessmentVO = new ComparableSalesReportPropertyAssessment(csrsa.mpacPropertyAssessmentVO);
      this.pii = new ComparableSalesReportPii(csrsa.pii);
    }
  }

  get propertyType(): string {
    if(this.mpacPropertyAssessmentVO) {
      if (this.mpacPropertyAssessmentVO.commercial){
        return 'Commercial';
      }
      if (this.mpacPropertyAssessmentVO.industrial){
        return 'Industrial';
      }
      return 'Residential';
    }
    return DataService.NOT_APPLICABLE;
  }
}
