import { HttpClient } from '@angular/common/http';
import {AfterViewInit, Component, inject, Inject} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BaseUnsubscribe } from '../../base-unsubscribe/base-unsubscribe';
import { RegistrationTrackerViewInstrumentDialogData } from './registration-tracker-view-instrument-dialog-data';
import { map, catchError, throwError, Observable, lastValueFrom, BehaviorSubject } from 'rxjs';
import * as _ from "lodash";
import { LoggerService } from '../../../../shared/service/log/logger.service';

@Component({
  selector: 'gema3g-registration-tracker-view-instrument-dialog',
  standalone: false,
  templateUrl: './registration-tracker-view-instrument-dialog.component.html',
  styleUrl: './registration-tracker-view-instrument-dialog.component.scss'
})
export class RegistrationTrackerViewInstrumentDialogComponent extends BaseUnsubscribe implements AfterViewInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: RegistrationTrackerViewInstrumentDialogData,
    private sanitizer: DomSanitizer) {
    super();
  }

  private https = inject(HttpClient);
  private loggerService = inject( LoggerService);

  safeUrl: SafeUrl;
  xhr = new XMLHttpRequest();

  ngAfterViewInit() {

    if (this.dialogData?.url) {
      this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.dialogData.url);
      this.safeUrl = this.sanitizer.bypassSecurityTrustHtml(this.dialogData.url);
      this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.dialogData.url);
      this.safeUrl = this.sanitizer.bypassSecurityTrustScript(this.dialogData.url);
    }

    this.https.get(this.dialogData.url, {
      headers: {
          'Content-Type': 'text/plain'
      }
    }).subscribe(pageContent => {
      this.loggerService.logDebug(`instrument page content`, pageContent);
    });


  }
}
