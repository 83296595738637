<div class="rata-pii-container">

  <div class="header">
    <div class="left">
      <div id="rata-pii-label" class="label">Property Information</div>
    </div>

    <div class="right">
      <mat-slide-toggle id="rata-toggle-pii-overlap" [(ngModel)]="isOverlapDisplay" (change)="togglePiiDisplay($event)">Overlap</mat-slide-toggle>
      <span id="rata-pii-close-button" (click)="closePii()">
        <fa-icon class="fa-lg text-white" [icon]="faCircleXmark"></fa-icon>
      </span>
    </div>
  </div>

  @if (piiBusy) {
    <div class="busy">
      <img id="rata-pii-refresh-icon" src="assets/img/icon-refresh.gif">
    </div>
  }

  @if (!piiBusy) {
    <div class="data-container">
      <div class="row">
        <mat-form-field id="rata-ff-pii-pin" appearance="outline" class="field flex-1" subscriptSizing="dynamic" floatLabel="always">
          <mat-label id="rata-label-pii-pin">PIN</mat-label>
          <input id="rata-tf-pii-pin" matInput value="{{selectedPii.pin}}" readonly>
        </mat-form-field>
        <mat-form-field id="rata-ff-pii-arn" appearance="outline" class="field flex-1" subscriptSizing="dynamic" floatLabel="always">
          <mat-label id="rata-label-pii-arn">Assessment Roll Number</mat-label>
          <input id="rata-tf-pii-arn" matInput value="{{selectedPii.arns}}" readonly>
        </mat-form-field>
      </div>
      <mat-form-field id="rata-ff-pii-addr" appearance="outline" class="field" subscriptSizing="dynamic" floatLabel="always">
        <mat-label id="rata-label-pii-addr">Address</mat-label>
        <input id="rata-tf-pii-addr" matInput value="{{selectedPii.address}}" readonly>
      </mat-form-field>
      <div class="row">
        <mat-form-field id="rata-ff-pii-muni" appearance="outline" class="field" subscriptSizing="dynamic" floatLabel="always">
          <mat-label id="rata-label-pii-muni">Municipality</mat-label>
          <input id="rata-tf-pii-muni" matInput value="{{selectedPii.municipality}}" readonly>
        </mat-form-field>
        <mat-form-field id="rata-ff-pii-lro" appearance="outline" class="field" subscriptSizing="dynamic" floatLabel="always">
          <mat-label id="rata-label-pii-lro">LRO</mat-label>
          <input id="rata-tf-pii-lro" matInput value="{{selectedPii.lro}}" readonly>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field id="rata-ff-pii-area" appearance="outline" class="field" subscriptSizing="dynamic" floatLabel="always">
          <mat-label id="rata-label-pii-area">Area (m²)</mat-label>
          <input id="rata-tf-pii-area" matInput value="{{selectedPii.area}}" readonly>
        </mat-form-field>
        <mat-form-field id="rata-ff-pii-perimeter" appearance="outline" class="field" subscriptSizing="dynamic" floatLabel="always">
          <mat-label id="rata-label-pii-perimeter">Perimeter (m)</mat-label>
          <input id="rata-tf-pii-perimeter" matInput value="{{selectedPii.perimeter}}" readonly>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field id="rata-ff-pii-rt" appearance="outline" class="field" subscriptSizing="dynamic" floatLabel="always">
          <mat-label id="rata-label-pii-rt">Registration Type</mat-label>
          <input id="rata-tf-pii-rt" matInput value="{{selectedPii.registrationType}}" readonly>
        </mat-form-field>
        <mat-form-field id="rata-ff-pii-lrs" appearance="outline" class="field" subscriptSizing="dynamic" floatLabel="always">
          <mat-label id="rata-label-pii-lrs">Land Registry Status</mat-label>
          <input id="rata-tf-pii-lrs" matInput value="{{selectedPii.landRegistryStatus}}" readonly>
        </mat-form-field>
      </div>
      <mat-form-field id="rata-ff-pii-desc" appearance="outline" class="field" subscriptSizing="dynamic" floatLabel="always">
        <mat-label id="rata-label-pii-desc">Description</mat-label>
        <textarea id="rata-tf-pii-desc" matInput value="{{selectedPii.desc}}" readonly cdkAutosizeMinRows="1" cdkAutosizeMaxRows="3"></textarea>
      </mat-form-field>
      <label id="rata-label-pii-ri">Related Instruments</label>
      <div class="row">
        <table id="rata-tbl-pii-ri" class="mat-elevation-z2 standard-table" mat-table [dataSource]="instrumentsDataSource" matSort>
          <ng-container matColumnDef="registrationDate">
            <th id="rata-tbl-hdr-pii-ri-rd" mat-header-cell *matHeaderCellDef mat-sort-header>DATE</th>
            <td id="rata-tbl-data-pii-ri-rd-{{row.instrumentNumber}}" mat-cell *matCellDef="let row" class="centered">{{row.registrationDate | date : 'mediumDate'}}</td>
          </ng-container>
          <ng-container matColumnDef="instrumentSubTypeCode">
            <th id="rata-tbl-hdr-pii-ri-type" mat-header-cell *matHeaderCellDef mat-sort-header>TYPE</th>
            <td id="rata-tbl-data-pii-ri-type-{{row.instrumentNumber}}" mat-cell *matCellDef="let row" class="centered">{{row.instrumentSubTypeCode}}</td>
          </ng-container>
          <ng-container matColumnDef="considerationAmount">
            <th id="rata-tbl-hdr-pii-ri-amount" mat-header-cell *matHeaderCellDef mat-sort-header>AMOUNT</th>
            <td id="rata-tbl-data-pii-ri-amount-{{row.instrumentNumber}}" mat-cell *matCellDef="let row" class="centered">{{row.considerationAmount | currency : 'USD' : 'symbol' : '1.0-0'}}</td>
          </ng-container>
          <ng-container matColumnDef="instrumentNumber">
            <th id="rata-tbl-hdr-pii-ri-in" mat-header-cell *matHeaderCellDef mat-sort-header></th>
            <td mat-cell *matCellDef="let row" class="centered"><a id="rata-tbl-link-pii-ri-in-{{row.instrumentNumber}}" (click)="viewInstrument(row)" class="anchor">View Instrument</a></td>
          </ng-container>
          <tr id="rata-tbl-row-pii-ri-hdr" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr id="rata-tbl-row-pii-ri-{{row.instrumentNumber}}" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  }
</div>
