import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DemographicsAreaEnum} from "../../../../../core/enum/demographics-area.enum";
import {DemographicsCultural} from "../../../../../core/model/demographics/demographics-cultural";
import {DemographicsMetadata} from "../../../../../core/model/demographics/demographics-metadata";
import {Demographics} from "../../../../../core/model/demographics/demographics";

@Component({
  selector: 'gema3g-demographics-cultural',
  templateUrl: './demographics-cultural.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./demographics-cultural.component.scss']
})
export class DemographicsCulturalComponent {
  @Input() demographicsData: Demographics = new Demographics();
  @Input() selectedArea: DemographicsAreaEnum[] = [];

  get cultural(): DemographicsCultural {
    return this.demographicsData?.cultural;
  }

  get metadata(): DemographicsMetadata[] {
    return this.demographicsData?.metaData;
  }
}
