import { ComparableSalesResultPayload } from "../comparables/comparable-sales-result-payload";
import { PropertyDetail } from "../property/property-detail";
import { SortColumn } from "./sort-column";

export class SearchComparablesResultSnapshot {
  pin: string; //the pin which initiated the snapshot
  ready: boolean; //render the snapshot results only when true

  originalSubjectProperty: PropertyDetail;
  results: ComparableSalesResultPayload;
  sortColumn: SortColumn;
  selectedProperties: string[];

  saveSelectedProperties = () => {
    this.selectedProperties = this.results?.response?.sales?.filter(sale => sale.selected).map(sale => sale.pin);
  }

  reselectProperties = () => {
    this.results?.response?.sales?.map(sale => {
      sale.selected = this.selectedProperties.includes(sale.pin);
    }
    );
  }
}
