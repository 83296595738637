import {Injectable} from "@angular/core";
import {defaultResponsiveOptions} from "../constant/constants";

@Injectable({
  providedIn: 'root'
})
export class CarouselService {

  MAX_VISIBLE_CAROUSEL_ITEMS = 3;

  showNavigators(itemsNo: number | undefined){
    if(itemsNo == undefined){
      return defaultResponsiveOptions[0]?.breakpointNo > window.innerWidth;
    }
    return defaultResponsiveOptions[0]?.breakpointNo > window.innerWidth || itemsNo > this.MAX_VISIBLE_CAROUSEL_ITEMS;
  }
}
