<div class="gema3g-ao-container">
  <div class="gema3g-ao-container__inner">

    <div class="col-0 flex justify-between mb-9">
      @if (userReportCounter$ | async; as userReportCounter) {
        <div id="sub-rh-total" class="geo-flex-column report-number">
          {{userReportCounter.maxLimit}}
          <span id="sub-rh-total-label">Total Reports</span>
        </div>
        <div id="sub-rh-viewed" class="geo-flex-column report-number">
          {{userReportCounter.reportsViewed}}
          <span id="sub-rh-viewed-label">Viewed</span>
        </div>
        <div id="sub-rh-remaining" class="geo-flex-column report-number last">
          {{userReportCounter.availableReports}}
          <span id="sub-rh-remaining-label">Remaining</span>
        </div>
        <div class="report-button">
          @if(hasAccessToMoreReports){
            @if (userReportCounter.availableReports <= warningThreshold){
              <span class="m-1">{{warningMessage}}</span>
            }
            <a (click)="openEstoreCatalogue()"
              class="btn btn-getmore"
              id="account-report-history-button">
              Get More Reports
            </a>
          }
        </div>
      } @else {
        <div class="m-1">
          Loading...
        </div>
      }
    </div>

    <h3 id="sub-h3-rh" class="title">Viewed Property Reports</h3>
    <p id="sub-rh-desc" class="sub-title">The Viewed Property Reports list will display your 500 most recently viewed reports, within the last two years.</p>

    <div class="col-0">
      @if (isLoading) {
        <mat-progress-bar id="sub-progress-bar-rh" mode="indeterminate"></mat-progress-bar>
      }
      <table id="sub-tbl-rh" [dataSource]="dataSource" class="mat-elevation-z8 standard-table" mat-table>

        <ng-container matColumnDef="date">
          <th id="sub-tbl-hdr-rh-date" *matHeaderCellDef mat-header-cell>Date</th>
          <td id="sub-tbl-data-rh-date-{{element.count}}" *matCellDef="let element" mat-cell>
            {{element.createdDate | date : 'mediumDate'}}
            <span class="time">
              |  {{element.createdDate | date:'h:mm a' : '+0000'}}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="source">
          <th id="sub-tbl-hdr-rh-source" *matHeaderCellDef mat-header-cell>Source</th>
          <td id="sub-tbl-data-rh-source-{{element.count}}" *matCellDef="let element" mat-cell>
            {{element.source}}
          </td>
        </ng-container>

        <ng-container matColumnDef="propertyAddress">
          <th id="sub-tbl-hdr-rh-addr" *matHeaderCellDef mat-header-cell>Property Address</th>
          <td id="sub-tbl-data-rh-addr-{{element.count}}" *matCellDef="let element" mat-cell>
            @if (element.address=='') {
              Address Not Available
            }
            @if (element.address!='') {
              {{element.address}}
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="pin_arn">
          <th id="sub-tbl-hdr-rh-pa" *matHeaderCellDef mat-header-cell>PIN/ARN</th>
          <td id="sub-tbl-data-rh-pa-{{element.count}}" *matCellDef="let element" mat-cell>
            @if (element.pin) {
              @if (userAccessControls.propertyReportAccess) {
                <a id="sub-tbl-link-rh-pa-{{element.count}}" (click)="goToPinOrArn(element)" class="anchor" target="_blank"
                [satPopoverAnchor]="pinPopover" (mouseover)="pinPopover.open()" (mouseout)="pinPopover.close()">{{element.pin}}</a>
                <sat-popover #pinPopover [autoFocus]="false" [restoreFocus]="false" verticalAlign="above"
                  openTransition="500ms ease-out" closeTransition="100ms ease-in">
                  <gema3g-tooltip [content]="openPropertyReportTooltipText"></gema3g-tooltip>
                </sat-popover>
              } @else {
                <span id="sub-tbl-link-rh-pa-{{element.count}}">{{element.pin}}</span>
              }
            }
          </td>
        </ng-container>

        <tr id="sub-tbl-row-rh-hdr" *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr id="sub-tbl-row-rh-{{row.count}}" *matRowDef="let row;
            let odd = odd;
            columns: displayedColumns;" mat-row>
        </tr>
      </table>
      <mat-divider></mat-divider>
      <mat-paginator
        id="sub-paginator-rh"
        (page)="pageEvent = getReport('pagination', $event)"
        [length]="length"
        [pageIndex]="pageIndex"
        [pageSizeOptions]="pageSizes"
        [pageSize]="pageSizes[1]"
        showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>
</div>
