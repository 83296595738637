<div class="up-container">

    <div class="header">
        <div class="flex flex-row justify-between justify-center items-center">
            <div class="flex">
                <img id="nup-gwh-logo" alt="" class="geo-header-logo" src="assets/img/geowarehouse-logo1.png">
            </div>
            <div class="flex pt-3 pr-3">
                <a id="nup-link-logout" (click)="logout()">Logout</a>
            </div>
        </div>
    </div>

    <div class="welcome">
        <div class="container-fluid m-8">
            <div class="flex flex-row">
                <div class="">
                    <p id="nup-subline" class="user-profile-subline">GeoWarehouse® Online Service</p>
                </div>
            </div>
            <div class="flex flex-row">
                <div class="w-full">
                    <p id="nup-header" class="user-profile-header">USER PROFILE</p>
                </div>
            </div>
            <div class="flex flex-row">
                <div class="">
                    <p id="nup-welcome-text" class="user-profile-text">
                        {{welcome}}
                        <br /><br />
                        @for (text of welcomeText; track $index) {
                            {{text}}<br />
                        }
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="form">
        <div class="flex flex-row justify-content-center gap-2">
            <gema3g-profile [formMode]="userProfileMode"></gema3g-profile>
        </div>
    </div>

    <gema3g-footer></gema3g-footer>
</div>