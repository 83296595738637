import { BaseModel } from "../base-model";

export class RataReportType extends BaseModel {
    constructor(rrType?: RataReportType) {
        super(rrType);
    }
    
    reportTypeId: string;   //todo: this should be converted to RataReportTypeEnum
    name: string;
    description: string;
}