@if (socioEconomic) {
  <div class="flex flex-row flex-wrap pt-[10%]">
    <div class="socio-economic-box">
      <div id="chart-employment-{{key}}">
        <fusioncharts
          type="doughnut2d"
          [dataSource]="employmentStatus"
          dataFormat="json"
          height="177"
          width="177"
        ></fusioncharts>
      </div>
      @if (socioEconomic.percentageOfEmploymentRate) {
        <div id="dg-se-emp-unempld-{{key}}">
          {{100 - socioEconomic.percentageOfEmploymentRate | number : '1.0-0'}}%
          <br/>Unemployed
        </div>
      }
    </div>
    <div class="profession">
      @if (socioEconomic.percentageOfEmploymentRate) {
        <div id="dg-se-emp-empld-label-{{key}}" class="employed" >
          <span id="dg-se-emp-empld-{{key}}" class="percentage-employment-rate">{{socioEconomic.percentageOfEmploymentRate | number : '1.0-0'}}%</span>
          <br/>Employed
          <div id="dg-se-emp-tick" class="tick"></div>
        </div>
      }
      @if (socioEconomic.percentageOfEmploymentRate) {
        <ul
          style="display:list-item;">
          <li>
            <div id="dg-se-emp-tick" class="tick"></div>
            <div class="text first">
              <span id="dg-se-emp-dp1-label-{{key}}" class="e-title">Dominant Profession #1</span>
              <p id="dg-se-emp-dp1-{{key}}">{{primaryDominantProfessionCode}}</p>
            </div>
            <div class="clear"></div>
          </li>
          <li class="last">
            <div id="dg-se-emp-tick" class="tick"></div>
            <div class="text">
              <span id="dg-se-emp-dp2-label-{{key}}" class="e-title">Dominant Profession #2</span>
              <p id="dg-se-emp-dp2-{{key}}">{{secondaryDominantProfessionCode}}</p>
            </div>
          </li>
        </ul>
      }
      <div class="clear"></div>
    </div>
    <div class="clear"></div>
  </div>
}
