export enum EstoreProductTypeEnum {
  SHOPPING_CART = "SHOPPING CART",
  PARCEL_REGISTER = "ParcelRegister",
  PARCEL_REGISTER_TITLE_CHECK = "ParcelRegisterWithTitleCheck",
  PLAN_IMAGE = "PlanImage",
  HOME_VERIFIED_REPORT = "HOME VERIFIED REPORT",
  INSTRUMENT_IMAGE = "InstrumentImage",
  PROPERTY_SURVEY_IMAGE_JDB = "PropertySurveyImageJDB",
  PROPERTY_SURVEY_IMAGE_RPE = "PropertySurveyImageRPE",
  PROPERTY_SURVEY_IMAGE_LSR = "PropertySurveyImageLSR",
  PROPERTY_SURVEY_IMAGE_SRI = "PropertySurveyImageSRI",
  INSTRUMENT_IMAGE_DOCUMENT = "InstrumentImage-Document",
  HOODQ_SCHOOL_CATCHMENT_MAP = "HoodQSchoolCatchmentMap",
  HOODQ_ADDRESS_REPORT = "HoodQAddressReport",
  HOODQ_DETAILED_REPORT = "HoodQDetailedReport",
  HOODQ_SCHOOL_REPORT = "HoodQSchoolReport",
  CONDO_STATUS_CERTIFICATE = "CondoStatusCertificate",
  CONDO_SUITE_SQUARE_FOOTAGE_REPORT = "CondoSuiteSquareFootageReport",
  CONDO_SUITE_SQUARE_FOOTAGE_BUNDLE = "CondoSuiteSquareFootageBundle",
  RESIDENTIAL_RELOCATION_REPORT_LRO = "ResidentialRelocationReportLro",
  RESIDENTIAL_RELOCATION_REPORT_MUNICIPALITY = "ResidentialRelocationReportMunicipality",
  RESIDENTIAL_RELOCATION_REPORT_FSA = "ResidentialRelocationReportFsa",
  AVM_RANGE_REPORT = "PropertyInsightsReport",
  PREMIUM_AVM_COMPARABLE_REPORT = "EnhancedCompsReport",
  PYB_BUYER_REPORT = "PYBBuyerReport",
  PYB_EASEMENT_REPORT = "PYBEasementReport",
  PYB_SELLER_REPORT = "PYBSellerReport",
  PYB_SURVEY = "PYBSurvey",
  MPAC_AVM_RANGE_REPORT = "AVMRangeReport",
  MPAC_PREMIUM_AVM_COMPARABLE_REPORT = "PremiumAVMComparableReport",
  MPAC_RESIDENTIAL_DETAILED_REPORT_LEVEL_1 = "ResidentialDetailedReportLevel1",
  MPAC_RESIDENTIAL_DETAILED_REPORT_LEVEL_2 = "ResidentialDetailedReportLevel2",
  MPAC_AVM_BASIC_RESIDENTIAL_REPORT = "AvmBasicResidentialReport",
  MPAC_AVM_ENHANCED_RESIDENTIAL_REPORT = "AvmEnhancedResidentialReport",
  MPAC_INDUSTRIAL_DETAILED_REPORT = "IndustrialDetailedReport",
  MPAC_COMMERCIAL_DETAILED_REPORT = "CommercialDetailedReport",
  MPAC_ICI_COMMERCIAL_INDUSTRIAL_DETAILED_REPORT = "IciCommercialAndIndustrialDetailedRpt",
  MPAC_REB_REPORT = "RebReport",
  MPAC_CORPORATE_NAME_ADDRESS_REPORT = "CorporateNameAndAddressReport",
  MPAC_AVM_COMPARABLE_REPORT = "Avm-comparableReport",
  MPAC_RESIDENTIAL_FLOOR_AREA_REPORT = "ResidentialFloorAreaReport",
  MPAC_FOUR_YEAR_PHASED_IN_REPORT = "FourYrPhaseInReport",
  MPAC_REB_RESIDENTIAL_DETAILED_REPORT = "REBResidentialDetailedReport",
  MPAC_REB_RESIDENTIAL_DETAIL_REPORT_RANGE = "REBResidentialDetailReportRange",
  MPAC_AVM_MARKET_SALES_REPORT = "AVMMarketSalesReport",
  MPAC_DISCOUNTED_ICI_COMMERCIAL_INDUSTRIAL_DETAILED_REPORT = "DiscountedIciCommercialAndIndustrialDetailedRpt",
  MPAC_AVM_RESIDENTIAL_COMPARABLE_REPORT = "AVM-ResidentialComparableReport",
  MPAC_NEW_RESIDENTIAL_FLOOR_AREA_REPORT = "NewResidentialFloorAreaReport",
  MPAC_RESIDENTIAL_FLOOR_AREA_RANGE_REPORT = "ResidentialFloorAreaRangeReport",
  MPAC_RESIDENTIAL_DETAILED_REPORT_LEVEL_2_RANGE = "ResidentialDetailedReportLevel2Range",
  MPAC_AVM_RESIDENTIAL_COMPARABLE_REPORT_BUNDLE_10 = "AVMResiComparableReportBundle10",
  MPAC_AVM_MARKET_SALES_REPORT_BUNDLE = "AVMMarketSalesReportBundle",
  MPAC_REB_RESIDENTIAL_DETAIL_REPORT_BUNDLE_1 = "REBResiDetailReportBundle1",
  MPAC_REB_RESIDENTIAL_DETAIL_REPORT_BUNDLE_2 = "REBResiDetailReportBundle2",
  MPAC_RESIDENTIAL_FLOOR_AREA_REPORT_BUNDLE_1 = "ResiFloorAreaRptBundle1",
  MPAC_RESIDENTIAL_FLOOR_AREA_REPORT_BUNDLE_2 = "ResiFloorAreaRptBundle2",
  TERANET_LOT_DETAILS = "TeranetLotDetails",
  COMPARABLE_SEARCH_REPORT_TOPUP_50 = "GWHgeoComparableSearchReportTopUp50",
  COMPARABLE_SEARCH_REPORT_TOPUP_100 = "GWHgeoComparableSearchReportTopUp100",
  COMPARABLE_SEARCH_REPORT_TOPUP_150 = "GWHgeoComparableSearchReportTopUp150",
  COMPARABLE_SEARCH_REPORT_TOPUP_200 = "GWHgeoComparableSearchReportTopUp200",
  COMPARABLE_SEARCH_REPORT_TOPUP_250 = "GWHgeoComparableSearchReportTopUp250",
  REPORT_TOPUP_100_STANDARD = "GWHgeoReportTopUp100Standard",
  REPORT_TOPUP_250_STANDARD = "GWHgeoReportTopUp250Standard",
  REPORT_TOPUP_500_STANDARD = "GWHgeoReportTopUp500Standard",
  REPORT_TOPUP_PACKAGE = "GWHgeoReportTopUpPackage",
  REPORT_TOPUP_1000_PROPERTY = "GWHgeoReportTopUp1000Property",
  REPORT_TOP_2500_PROPERTY = "GWHgeoReportTop2500Property",
  REPORT_TOP_5000_PROPERTY = "GWHgeoReportTop5000Property",
  REPORT_TOPUP_1000_PROPERTY_MPAC = "GWHgeoReportTopUp1000PropertyMPAC",
  REPORT_TOP_2500_PROPERTY_MPAC = "GWHgeoReportTop2500PropertyMPAC",
  REPORT_TOP_5000_PROPERTY_MPAC = "GWHgeoReportTop5000PropertyMPAC",
  REPORT_TOPUP_200_PROPERTY_MPAC = "GWHgeoReportTopUp200PropertyMPAC",
  REPORT_TOPUP_500_PROPERTY_MPAC = "GWHgeoReportTopUp500PropertyMPAC",
  REPORT_TOPUP_200_PROPERTY = "GWHgeoReportTopUp200Property",
  REPORT_TOPUP_500_PROPERTY = "GWHgeoReportTopUp500Property",
  REPORT_TOPUP_5000_MPS_COMMERCIAL = "GWHgeoReportTopUp5000MPSCommercial",
  REPORT_TOPUP_2500_MPS_COMMERCIAL  = "GWHgeoReportTopUp2500MPSCommercial",
  REPORT_TOPUP_1000_MPS_COMMERCIAL = "GWHgeoReportTopUp1000MPSCommercial",
  REPORT_TOPUP_5000_MPS_RESIDENTIAL = "GWHgeoReportTopUp5000MPSResidential",
  REPORT_TOPUP_2500_MPS_RESIDENTIAL = "GWHgeoReportTopUp2500MPSResidential",
  REPORT_TOPUP_1000_MPS_RESIDENTIAL = "GWHgeoReportTopUp1000MPSResidential",
  NON_TRANSACTIONAL_MPAC_REPORT = "NonTransactionalMpacReport",
  LICENSE_SUBSCRIPTION_RENEWAL = "GWHLicenseSubscriptionRenewal",
  COMP_LICENSE_SUBSCRIPTION_RENEWAL = "GWHCompLicenseSubscriptionRenewal",
  GEOWAREHOUSE_REPORT_PACKAGE = "GEOWAREHOUSE REPORT PACKAGE",
  HOME_VERIFIED_REPORT_SUBSCRIPTION = "HOME VERIFIED REPORT SUBSCRIPTION",
  ASSESSMENT_REPORTS = "AssessmentReports",
  STREETSCAPE_IMAGERY_LICENSE = "StreetscapeImageryLicense",
  STREETSCAPEIMAGERY_AND_PIVOTVW_LICENSE = "StreetscapeImageryAndPivotVwLicense",
  NON_TRANSACTIONAL_CONDO_REPORT = "NonTransactionalCondoReport",
  AERIAL_IMAGERY_REPORT = "AerialImageryReport",
  REPORT_TOPUP_100_STANDARD_MPAC = "GWHgeoReportTopUp100StandardMPAC",
  REPORT_TOPUP_250_STANDARD_MPAC = "GWHgeoReportTopUp250StandardMPAC",
  REPORT_TOPUP_500_STANDARD_MPAC = "GWHgeoReportTopUp500StandardMPAC",
  COMPARABLE_SEARCH_REPORT_TOPUP_50_MPAC = "GWHgeoComparableSearchReportTopUp50MPAC",
  COMPARABLE_SEARCH_REPORT_TOPUP_100_MPAC = "GWHgeoComparableSearchReportTopUp100MPAC",
  COMPARABLE_SEARCH_REPORT_TOPUP_150_MPAC = "GWHgeoComparableSearchReportTopUp150MPAC",
  COMPARABLE_SEARCH_REPORT_TOPUP_200_MPAC = "GWHgeoComparableSearchReportTopUp200MPAC",
  COMPARABLE_SEARCH_REPORT_TOPUP_250_MPAC = "GWHgeoComparableSearchReportTopUp250MPAC",
}
