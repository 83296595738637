import {PropertyDetail} from "./property-detail";
import {EStoreProperty} from "./e-store-property";
import {Centroid} from "../spatial/centroid";

export class SelectedProperty {

  constructor(selProp?: SelectedProperty) {
    if (selProp) {
      for (let property in selProp) {
        if (selProp.hasOwnProperty(property)) {
          // @ts-ignore
          this[property] = selProp[property];
        }
      }
    }
  }

  isSubjectPropertyMultipleARN: boolean;
  arn: string;
  address: string;
  muniId?: number | null;
  pin: string;
  landRegistryStatus: string;
  pincode: string;
  legaldescription: string;
  province: string;
  lro: string | null;
  city: string | null;
  postalCode: string | null;
  isFromRecent: boolean = false;
  isSubjectPropertyARNonly: boolean;
  isSelected: boolean = false;
  centroid: Centroid;
  arns: string[];
  isSSOPin: boolean;

  get isAddressNotAvailable(): boolean{
    return this.address == 'Address Not Available' ;
  }

  /*
  //Note: PropertyIdentificationInformation has been replaced by the newer Pii class
  loadFromPii(propertyIdInfo: PropertyIdentificationInformation, isFromRecent: boolean) {
    this.isFromRecent = isFromRecent;
    this.isSubjectPropertyMultipleARN = false;
    this.arn = 'N/A';
    this.pin = 'N/A';
    this.address = 'Address Not Available';
    this.pincode = '';
    this.province = '';
    this.legaldescription = 'N/A';
    this.landRegistryStatus = "ACTIVE";

    this.lro = null;
    this.city = null;
    this.postalCode = null;

    this.muniId = null;
    if (propertyIdInfo != null) {

      if (propertyIdInfo.pin != null) {
        this.pin = propertyIdInfo.pin;
        //generateStreetView(propertyDetail.pin);
      }

      if (propertyIdInfo.address != null && propertyIdInfo.address.fullAddress) {
        this.address = propertyIdInfo.address.fullAddress;
        this.pincode = '';
        this.province = propertyIdInfo.address.province;
        this.city = propertyIdInfo.address.city;
        this.postalCode = propertyIdInfo.address.postalCode;
      }

      if (propertyIdInfo.shortLegalDescription != null) {
        this.legaldescription = propertyIdInfo.shortLegalDescription;
      }

      if (propertyIdInfo.arns != null && propertyIdInfo.arns.length > 1) {
        this.isSubjectPropertyMultipleARN = true;
      } else if (propertyIdInfo.arns != null && propertyIdInfo.arns.length === 1) {
        this.arn = propertyIdInfo.arns[0];
      }

      if (propertyIdInfo.landRegistryStatus != null && propertyIdInfo.landRegistryStatus.length > 1) {
        this.landRegistryStatus = propertyIdInfo.landRegistryStatus;
      }

      if (propertyIdInfo.lro && propertyIdInfo.lro != null) {
        this.lro = propertyIdInfo.lro;
      }

      if (typeof propertyIdInfo.pinXy !== 'undefined' && propertyIdInfo.pinXy && typeof propertyIdInfo.pinXy.muniId !== 'undefined' && propertyIdInfo.pinXy.muniId) {
        this.muniId = propertyIdInfo.pinXy.muniId;
      }
    }
  }
  */

  loadFromPropertyDetail(propertyDetail: PropertyDetail, isFromRecent: boolean) {
    this.isFromRecent = isFromRecent;
    this.isSubjectPropertyMultipleARN = false;
    this.arn = 'N/A';
    this.pin = 'N/A';
    this.address = 'Address Not Available';
    this.pincode = '';
    this.province = 'ON'; // default for MPAC
    this.legaldescription = 'N/A';
    this.landRegistryStatus = "ACTIVE";
    this.lro = null;
    this.city = null;
    this.postalCode = null;

    if (propertyDetail != null && propertyDetail.pii != null) {

      this.muniId = propertyDetail.pii.pinXy?.muniId;

      if (propertyDetail.pii.pin != null) {
        this.pin = propertyDetail.pii.pin;
      }

      if (propertyDetail.pii.address != null && propertyDetail.pii.address.fullAddress) {
        this.address = propertyDetail.pii.address.fullAddress;
        this.pincode = '';
        this.province = propertyDetail.pii.address.province;
        this.city = propertyDetail.pii.address.city;
        this.postalCode = propertyDetail.pii.address.postalCode;
      }


      if (propertyDetail.pii.shortLegalDescription != null) {
        this.legaldescription = propertyDetail.pii.shortLegalDescription;
      }

      if (propertyDetail.pii.arns && propertyDetail.pii.arns.length > 0) {
        this.arn = propertyDetail.pii.arns[0];
        this.arns = [];
        this.arns.push(...propertyDetail.pii.arns);

        this.isSubjectPropertyMultipleARN = propertyDetail.pii.arns.length > 1;
      }

      if (propertyDetail.pii?.landRegistryStatus != null && propertyDetail.pii?.landRegistryStatus.length > 1) {
        this.landRegistryStatus = propertyDetail.pii.landRegistryStatus;
      }

      if (propertyDetail.pii.lro && propertyDetail.pii.lro != null) {
        this.lro = propertyDetail.pii.lro;
      }
      if(propertyDetail.pii?.pinXy?.centroid){
        this.centroid = new Centroid(propertyDetail.pii.pinXy.centroid.latitude, propertyDetail.pii.pinXy.centroid.longitude);
      }
    }
  }

  loadFromPropertyOpenedInMainScreen(propertyDetails: PropertyDetail){
    const eStoreProperty = new EStoreProperty();
    eStoreProperty.loadFromPropertyDetails(propertyDetails);
    this.isSubjectPropertyARNonly = false;
    this.isSubjectPropertyMultipleARN = false;
    this.arn = 'N/A';
    this.pin = 'N/A';
    this.address = 'N/A';
    this.pincode = 'N/A';
    this.legaldescription = 'N/A';
    this.landRegistryStatus = "ACTIVE";
    //displaystreetview = false;
    if (eStoreProperty != null) {

      this.pin = eStoreProperty.pin;
      this.address = eStoreProperty.address;
      this.pincode = eStoreProperty.pincode;
      this.legaldescription = eStoreProperty.legalDescription;
      this.landRegistryStatus = eStoreProperty.landRegistryStatus;

      if (eStoreProperty.arnonly) {
        this.isSubjectPropertyARNonly = true;
        this.arn = eStoreProperty.arn;

      } else if (eStoreProperty.assessments != null && eStoreProperty.assessments.length > 1) {
        this.isSubjectPropertyMultipleARN = true;

      } else if (eStoreProperty.assessments != null && eStoreProperty.assessments.length === 1) {
        this.arn = eStoreProperty.assessments[0].rollNumber;

      }
    }
  }


  getPinToolTip = (): string => {
    return (this.pin) ? "PIN " + this.pin : "";
  }
}
