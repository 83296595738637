import {BaseModel} from "../base-model";

export class LicensePackage extends BaseModel {

  constructor(licensePackage?: LicensePackage) {
    super(licensePackage);
  }

  licensePackageId: number;
  name: string;
  propertyReportCounter: number;
  comparableSearchCounter: number;
  templateUserBeid: number;
  createdDate: Date;
  createdBy: string;
  lastUpdate: Date;
  updatedBy: string;
  eStoreCatalogueProductId: number;
}
