//Weight refers to the importance of an advertisement banner.
//Highest weight being assigned to an ad that should be displayed most often.
//Lowest weight being assigned to an ad that should be displayed the least.
export enum AdBannerWeightEnum {
    HIGHEST,
    HIGHER,
    HIGH,
    MEDIUM,
    LOW,
    LOWER,
    LOWEST
}