export class SiteDetailInfoItem {

  constructor(siteDetailInfoItem? : SiteDetailInfoItem) {
    if (siteDetailInfoItem) {
      for (let property in siteDetailInfoItem) {
        if (siteDetailInfoItem.hasOwnProperty(property)) {
          // @ts-ignore
          this[property] = siteDetailInfoItem[property];
        }
      }
    }
  }

  label: string;
  value: string;
}
