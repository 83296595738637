import { Component, Inject, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DebuggerDialogData } from './debugger-dialog-data';
import { environment } from "../../../../../environments/environment";
import { AuthenticationService } from '../../../../shared/service/authentication.service';
import { lastValueFrom } from 'rxjs';
import { UserService } from '../../../../shared/service/user.service';
import { LocalStorageKey } from '../../../../shared/constant/constants';
import { VERSION } from '@angular/core';
import {LoggerService} from "../../../../shared/service/log/logger.service";

declare var moment: any;

@Component({
  selector: 'gema3g-debugger-dialog',
  standalone: true,
  imports: [MatDialogModule],
  templateUrl: './debugger-dialog.component.html',
  styleUrl: './debugger-dialog.component.scss'
})
export class DebuggerDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DebuggerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DebuggerDialogData) {
  }

  private userService = inject(UserService);
  private authService = inject(AuthenticationService);
  private loggerService = inject(LoggerService);

  isOktaLogin: boolean = false;
  userInfo: string;

  async ngOnInit() {
    this.isOktaLogin = this.authService.oktaLogin;
    let user = await lastValueFrom(this.userService.getUser(false));
    this.userInfo = user.summaryInfo;
    this.loggerService.setLogLevelToDebug();
  }

  get env(): string {
    return `${environment.text}${this.isOktaLogin ? ' (OKTA)' : ''}`;
  }

  get lastLoggedIn(): string {
    let lastLoggedIn: string | null = localStorage.getItem(LocalStorageKey.LAST_LOGGED_IN);

    return (lastLoggedIn)? moment(Number(lastLoggedIn)).format('LLLL') : "";
  }

  get version(): string {
    return VERSION.full;
  }
}
