<div class="gema3g-ss-container">
  <div class="gema3g-ss-container__inner">

    <div class="gema3g-ss-container__inner-grid-2">
      <div class="l-column">
        <h4 id="ss-h4-rpt-{{assessment.rollNumber}}">Residential Property Tax Details</h4>
      </div>

      <div class="r-column">
        <table id="ss-tbl-rpt-{{assessment.rollNumber}}" class="h-[100%]" [dataSource]="taxEstimateDataSource" class="mat-elevation-z2 standard-table" mat-table matSort>

          <ng-container matColumnDef="taxYear">
            <th id="ss-tbl-hdr-rpt-ty-{{assessment.rollNumber}}" *matHeaderCellDef mat-header-cell mat-sort-header>Year</th>
            <td id="ss-tbl-data-rpt-ty-{{assessment.rollNumber}}-{{element.taxYear}}" *matCellDef="let element" mat-cell>
              {{element.taxYear}}
            </td>
          </ng-container>

          <ng-container matColumnDef="taxEstimate">
            <th id="ss-tbl-hdr-rpt-te-{{assessment.rollNumber}}" *matHeaderCellDef mat-header-cell mat-sort-header>Tax Estimate</th>
            <td id="ss-tbl-data-rpt-te-{{assessment.rollNumber}}-{{element.taxYear}}" *matCellDef="let element" mat-cell>
              {{element.taxEstimate !=null ? (element.taxEstimate | currency : 'USD' : 'symbol' : '1.0-0') : "N/A" }}
            </td>
          </ng-container>

          <tr id="ss-tbl-row-rpt-header-{{assessment.rollNumber}}" mat-header-row *matHeaderRowDef="columns"></tr>
          <tr id="ss-tbl-row-rpt-{{assessment.rollNumber}}-{{row.taxYear}}" mat-row *matRowDef="let row; columns: columns;"></tr>
        </table>
      </div>
    </div>
  </div>
</div>
