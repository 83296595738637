<div class="gema3g-pr-container">
  <div class="gema3g-pr-container__inner">
    <!-- header-content -->
    <ng-content id="layout-header" select="[header-content]"></ng-content>
    <!-- main-content -->
    <div id="layout-main-content" class="gema3g-pr-container__inner-grid">
      <!-- left column -->
      <div id="layout-left-column" class="l-column no-print">
        <gema3g-left-sidebar
          #lSideBar
          [propertyReportSection]="propertyReportSection">
        </gema3g-left-sidebar>
      </div>
      <!-- middle content -->
      <div id="layout-middle-content" class="m-column">
        <ng-content></ng-content>
      </div>
      <!-- right column -->
      <div id="layout-right-column" class="r-column">
        @if (legend) {
          @for (item of legend; track $index) {
            <p id="layout-right-column-item-{{item.body}}" class="legend-item">
              <span id="layout-right-column-item-{{item.title}}" class="text-os-bold">{{ item.title }}</span>
              {{ item.body }}
              <span id="layout-right-column-item-{{item.code}}" class="legend-item__code text-os-bold"><!--{{ item.code }}--></span>
            </p>
          }
        }
        @if (demographicsSection) {
          <p id="layout-right-column-demo-help" class="help">
            For questions, please contact <a id="layout-support-email" class="anchor-standard-blue" href="mailto:{{DataService.SUPPORT_EMAIL}}" target="_blank">{{DataService.SUPPORT_EMAIL}}</a>.
          </p>
        }
        <gema3g-right-sidebar
          #rSideBar
          [propertyReportSection]="propertyReportSection">
        </gema3g-right-sidebar>
      </div>
    </div>
    <!-- footer content -->
    <ng-content id="layout-footer" select="[footer-content]"></ng-content>
  </div>
</div>
