import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatInputModule} from "@angular/material/input";
import {MaterialModule} from "../../../../material.module";
import {SvgIconComponent} from "../../../svg-icon/svg-icon.component";
import {PdfReportBasicReportConfiguration} from "../../../../model/pdf-report/input-param/pdf-report-basic-report-configuration";
import {PdfReportClientReportConfiguration} from "../../../../model/pdf-report/input-param/pdf-report-client-report-configuration";
import {PdfReportMyPropertyReportConfiguration} from "../../../../model/pdf-report/input-param/pdf-report-my-property-report-configuration";
import {GeneratePdfReportPropertyInfoSectionComponent} from "../sections/generate-pdf-report-property-info-section/generate-pdf-report-property-info-section.component";
import {GeneratePdfReportAreaSalesInfoSectionComponent} from "../sections/generate-pdf-report-area-sales-info-section/generate-pdf-report-area-sales-info-section.component";


@Component({
  selector: 'gema3g-generate-pdf-client-report',
  standalone: true,
  imports: [
    MatInputModule,
    MaterialModule,
    SvgIconComponent,
    GeneratePdfReportPropertyInfoSectionComponent,
    GeneratePdfReportAreaSalesInfoSectionComponent
  ],
  templateUrl: './generate-pdf-client-report.component.html',
  styleUrl: './generate-pdf-client-report.component.scss'
})
export class GeneratePdfClientReportComponent {
  @Input() inputParam: PdfReportMyPropertyReportConfiguration | PdfReportBasicReportConfiguration | PdfReportClientReportConfiguration;
  @Input() openedFromComparablesReport: boolean = false;
  @Output() collapseAdditionalParams = new EventEmitter<boolean>();
  @Output() reloadProperty = new EventEmitter<string>();

  closeAdditionalParams() {
    this.collapseAdditionalParams.emit(true);
  }

  goReloadProperty(pin?: string){
    this.reloadProperty.emit(pin);
  }
  protected readonly PdfReportClientReportParameter = PdfReportClientReportConfiguration;
}
