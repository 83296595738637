import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreFrontComponent } from './store-front/store-front.component';
import { StoreRetailComponent } from './store-retail/store-retail.component';
import { CoreModule } from '../core/core.module';
import { StoreFrontMainComponent } from './store-front/store-front-main/store-front-main.component';
import { StoreRetailMainComponent } from './store-retail/store-retail-main/store-retail-main.component';
import { StoreFrontProductCategoriesComponent } from './store-front/store-front-main/store-front-product-categories/store-front-product-categories.component';
import { MaterialModule } from '../core/material.module';
import { StoreFrontFooterComponent } from './store-front/store-front-main/store-front-footer/store-front-footer.component';
import { StoreFrontProductGroupComponent } from './store-front/store-front-main/store-front-product-categories/store-front-product-category-group/store-front-product-group.component';
import { StoreFrontProductCategoryItemComponent } from './store-front/store-front-main/store-front-product-categories/store-front-product-category-item/store-front-product-category-item.component';
import {MatListModule} from "@angular/material/list";
import {SharedModule} from "../shared/shared.module";


@NgModule({
  declarations: [
    StoreFrontComponent,
    StoreFrontMainComponent,
    StoreFrontProductCategoriesComponent,
    StoreRetailComponent,
    StoreRetailMainComponent,
    StoreFrontFooterComponent,
    StoreFrontProductGroupComponent,
    StoreFrontProductCategoryItemComponent,
  ],
    imports: [
        CommonModule,
        CoreModule,
        MaterialModule,
        MatListModule,
        SharedModule
    ],
  exports: [
    StoreFrontComponent,
    StoreRetailComponent
  ]
})
export class StoreModule { }
