import {Component, inject, OnInit} from '@angular/core';
import {UserAccessControl} from "../../../model/user/user-access-control";
import {UserService} from "../../../../shared/service/user.service";
import {DataService} from "../../../../shared/service/data.service";
import {lastValueFrom} from "rxjs";
import {BuildVersion} from "../../../model/miscellaneous/build-version";
import {environment} from "../../../../../environments/environment";
import {VersioningService} from "../../../../shared/service/versioning-service";
import {AuthenticationService} from "../../../../shared/service/authentication.service";

@Component({
  selector: 'gema3g-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  private userService = inject(UserService);
  private versioningService = inject(VersioningService);
  private authService = inject (AuthenticationService);

  userAccessControls: UserAccessControl;
  thisYear = new Date().getFullYear();
  buildVersion: BuildVersion;
  urlPrivacyStatementUrl: string = environment.url.PRIVACY_STATEMENT_URL;
  termsOfUseUrl: string = environment.url.TERMS_OF_USE_URL;
  securityStatementUrl: string = environment.url.SECURITY_STATEMENT;
  isOktaLogin: boolean = false;
  userInfo: string;

  async ngOnInit() {
    this.buildVersion = await lastValueFrom(this.versioningService.getLocalBuildVersion());
    this.userAccessControls = this.userService.getUserAccessControl();
    this.isOktaLogin = this.authService.oktaLogin;
    let user = await lastValueFrom(this.userService.getUser(false));
    this.userInfo = user.summaryInfo;
  }

  get env(): string {
    return `${environment.text}${this.isOktaLogin ? ' (OKTA)' : ''}`;
  }

  get isProd(): boolean {
    return environment.text === 'PROD' ;
  }


}
