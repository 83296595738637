import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as _ from "lodash";
import { lastValueFrom, takeUntil } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { BaseUnsubscribe } from '../../../core/component/base-unsubscribe/base-unsubscribe';
import { LoggerService } from '../../../shared/service/log/logger.service';
import { RegistrationTrackerService } from '../../../shared/service/registration-tracker.service';
import { RataPii } from '../../../core/model/rata/pii';
import { MatTableDataSource } from '@angular/material/table';
import { RataPiiInstrument } from '../../../core/model/rata/pii-instrument';
import { MatSort } from '@angular/material/sort';
import { SSOService } from '../../../shared/service/sso.service';
import { SSOOpenIDConnectJson } from '../../../core/model/sso/sso-oidc-json';
import { MatDialog } from '@angular/material/dialog';
import { RegistrationTrackerViewInstrumentDialogData } from 'src/app/core/component/modal/registration-tracker/registration-tracker-view-instrument-dialog-data';
import { UrlUtility } from 'src/app/shared/utility/url.utility';
import { Router } from '@angular/router';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'gema3g-registration-tracker-pii',
  templateUrl: './registration-tracker-pii.component.html',
  styleUrls: ['./registration-tracker-pii.component.scss']
})
export class RegistrationTrackerPiiComponent extends BaseUnsubscribe implements OnInit, AfterViewInit {

  constructor(
    private loggerService: LoggerService,
    private rataService: RegistrationTrackerService,
    private ssoService: SSOService,
    private router: Router,
    private viewInstrumentDialog: MatDialog) {
      super();
  }

  @Input() piiBusy: boolean;
  @Output() piiOverlapMode = new EventEmitter<boolean>();
  @Output() piiClosed = new EventEmitter();
  selectedPii: RataPii;
  isOverlapDisplay: boolean = true;
  sort: MatSort;
  displayedColumns: string[];
  instrumentsDataSource = new MatTableDataSource<RataPiiInstrument>();
  ssoOIDCJson: SSOOpenIDConnectJson;
  oidcAccessToken: string;
  estoreUrl: string;
  faCircleXmark = faCircleXmark;
  
  populateInstrumentsTable = () => {
    this.instrumentsDataSource = new MatTableDataSource(this.selectedPii.instruments);
  }

  initializeInstrumentsColumns = () => {
    this.displayedColumns = [];
    this.displayedColumns.push('registrationDate');
    this.displayedColumns.push('instrumentSubTypeCode');
    this.displayedColumns.push('considerationAmount');
    this.displayedColumns.push('instrumentNumber');
  }

  togglePiiDisplay = (event: MatSlideToggleChange) => {
    (event.checked)? this.piiOverlapMode.emit(true) : this.piiOverlapMode.emit(false);
  }

  viewInstrument = (record: RataPiiInstrument) => {
    let instrumentToken: string = record.token;
  
    this.ssoService.getOpenIDConnectJson()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(response => {
        var iFrameUrl = response.accessTokenLoginUrl + "&resource=" + encodeURIComponent("/documentImage.do?action=displayDocumentDetailsRata&token=" + instrumentToken);
        if (iFrameUrl) {
          if (UrlUtility.isHttpsScheme(window.location.href) && UrlUtility.isHttpScheme(iFrameUrl)) {
            iFrameUrl = UrlUtility.replaceScheme(iFrameUrl, 'http', 'https');
          } else if (UrlUtility.isHttpScheme(window.location.href) && UrlUtility.isHttpsScheme(iFrameUrl)) {
            iFrameUrl = UrlUtility.replaceScheme(iFrameUrl, 'https', 'http');
          }
        }

        const dialogData = new RegistrationTrackerViewInstrumentDialogData(iFrameUrl, 'Close');
        
        //const dialogRef = this.viewInstrumentDialog.open(RegistrationTrackerViewInstrumentDialogComponent, {data: dialogData}).afterClosed();
        window.open(iFrameUrl, 'RATA View Instrument')?.focus();
    });
  }

  //This setter will fire once matSort in the view changes. 
  //I.e. when it is defined the first time. It will not fire when you change the sorting by clicking on the arrows. 
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.sort = sort;
    this.setDataSort();
  }

  setDataSort = () => {
    this.instrumentsDataSource.sort = this.sort;

    if (this.sort) {
      this.sort.sort({
        id: 'registrationDate',
        start: 'asc',
        disableClear: true
      });
      this.sort.disableClear = true;
    }

    this.instrumentsDataSource.sortingDataAccessor = (item, property: string) => {
      switch (property) {
        case 'registrationDate':
          return new Date(item.registrationDate).getTime();
        default:
          let loweredFirstCharProperty: string = property.charAt(0).toLowerCase() + property.slice(1);
          // @ts-ignore
          return item[loweredFirstCharProperty];
      }
    }
  }
  
  closePii = () => {
    this.piiClosed.emit();
  }

  ngOnInit(): void {
    this.rataService.pii$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(pii => {
        if (pii) {
          this.selectedPii = pii;
          this.populateInstrumentsTable();
          this.initializeInstrumentsColumns();
        }
    });

  }

  ngAfterViewInit(): void {
  }

}
