export class BasicAssessment{

  constructor(basicAssessment? : BasicAssessment) {
    if(basicAssessment) {
      this.taxYear = basicAssessment.taxYear;
      this.assessmentValue = basicAssessment.assessmentValue;
    }
  }

  taxYear: string;
  assessmentValue: string;
}
