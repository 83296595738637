import { HttpClient } from '@angular/common/http';
import { baseUrl } from "../system";
import {inject, Inject, Injectable} from '@angular/core';
import { map, catchError, throwError, Observable, BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { OmnibarSearchTypeEnum } from '../../../core/enum/omnibar-search-type-enum';
import { AutoSuggestSearchMode } from '../../../core/enum/autosuggest-search-mode';
import { LoggerService } from '../log/logger.service';
import { CondoSearchParameters, InstrumentSearchParameters, LotSearchParameters, OwnerFullNameSearchParameters, OwnerSearchParameters, SearchParameters, WildcardSearchParameters } from "./search-parameters";
import { of } from 'rxjs';
import { SearchResult } from '../../../core/model/search-result/search-result';
import { CondoLevel } from "../../../core/model/search-result/condo-level";
import { CondoUnit } from "../../../core/model/search-result/condo-unit";
import { CondoSummary } from "../../../core/model/property/condo-summary";
import { UrlUtility } from '../../utility/url.utility';
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class OmnibarSearchService {

  private https = inject(HttpClient);
  private loggerService = inject(LoggerService);

  private _lastSearchResults = new BehaviorSubject<any>(null);
  lastSearchResults$ = this._lastSearchResults.asObservable();

  private _lastSearchParams = new BehaviorSubject<LotSearchParameters | WildcardSearchParameters | OwnerSearchParameters | InstrumentSearchParameters | CondoSearchParameters |  null | any>(null);
  lastSearchParams$ = this._lastSearchParams.asObservable();

  private _lastSecondLevelSearchResults = new BehaviorSubject<any>(null);
  lastSecondLevelSearchResults$ = this._lastSecondLevelSearchResults.asObservable();

  private _lastSecondLevelSearchParams = new BehaviorSubject<OwnerFullNameSearchParameters | CondoSearchParameters |  null | any>(null);
  lastSecondLevelSearchParams$ = this._lastSecondLevelSearchParams.asObservable();

  private _wildCardSearch = new BehaviorSubject<string>('');
  wildCardSearch$ = this._wildCardSearch.asObservable();

  private _requestToOpenCompSearch = new BehaviorSubject<string>('');
  requestToOpenCompSearch$ = this._requestToOpenCompSearch.asObservable();

  public static MINIMUM_CHARACTERS_FOR_AUTOSUGGEST_SEARCH = 3;

  PAGE_SIZE: number = 20;

  searchPropertiesByAutoSuggest = (searchMode: AutoSuggestSearchMode, providedUrl: string | null, lro: string): Observable<any> => {
    let url = '';

    switch (searchMode) {

      case AutoSuggestSearchMode.GET_RECENTLY_SEARCHED_SUGGESTED_RESULT:
        url = baseUrl + '/' + providedUrl;
        let modifiedProvidedUrl = providedUrl!;

        if (!_.isEmpty(modifiedProvidedUrl)) {
          let _url: URL = new URL(modifiedProvidedUrl!, environment.url.ORIGIN);

          if (!UrlUtility.isUrlHasParameter(_url, 'lro') && !_.isEmpty(lro)) {
            let params = UrlUtility.setUrlParameterAndValue(_url, 'lro', lro);
            modifiedProvidedUrl = UrlUtility.replaceParameters(modifiedProvidedUrl, params);
          }

          _url = new URL(modifiedProvidedUrl!, environment.url.ORIGIN);

          if (!UrlUtility.isUrlHasParameterAndValue(_url, 'pageSize', '20')) {
            let params = UrlUtility.setUrlParameterAndValue(_url, 'pageSize', '20');
            let modifiedProvidedUrl = UrlUtility.replaceParameters(providedUrl!, params);
            if (!_.isEmpty(modifiedProvidedUrl)) {
              providedUrl = modifiedProvidedUrl;
              url = baseUrl + '/' + providedUrl;
            }
          }
        }

        this.loggerService.logDebug(`recent search url modified to ${url}`);

        break;

      case AutoSuggestSearchMode.GET_RECENTLY_VIEWED_SUGGESTED_RESULT:
        throw new Error('recently viewed property not implemented in omnibar search service');

      case AutoSuggestSearchMode.GET_AUTO_SUGGESTED_OWNER_RESULT:
        url = baseUrl + '/' + providedUrl; //providedUrl contains the next rest url endpoint

        //deal with edge case scenarios in the provided url endpoint
        if (url.endsWith('pageSize=') && !_.includes(url, 'lro')) {
          url = `${url}${this.PAGE_SIZE}&lro=${lro}`;
        }
        break;

      case AutoSuggestSearchMode.GET_AUTO_SUGGESTED_FREEHOLD_RESULT:
        url = baseUrl + '/' + providedUrl; //providedUrl contains the next rest url endpoint
        break;

      case AutoSuggestSearchMode.GET_AUTO_SUGGESTED_CONDO_RESULT:
        url = baseUrl + '/' + providedUrl; //providedUrl contains the next rest url endpoint
        break;

      case AutoSuggestSearchMode.GET_AUTO_SUGGESTED_BLOCK_RESULT:
        url = baseUrl + '/' + providedUrl; //providedUrl contains the next rest url endpoint

        //deal with edge case scenarios in the provided url endpoint
        if (url.endsWith('pageSize=')) {
          url = url + `${this.PAGE_SIZE}&page=0`;
        }
        break;

      case AutoSuggestSearchMode.GET_AUTO_SUGGESTED_MUNICIPALITY_RESULT:
        url = baseUrl + '/' + providedUrl; //providedUrl contains the next rest url endpoint
        break;

      case AutoSuggestSearchMode.GET_AUTO_SUGGESTED_NOT_APPLICABLE_RESULT:
        //TODO does N/A always resolve to a search by pin?
        url = baseUrl + '/' + providedUrl;
        break;

    }

    this.loggerService.logDebug(`calling ${url} to search properties by search type ${OmnibarSearchTypeEnum.ALL_DISPLAY}`);

    return this.https.get(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).pipe(
      map(response => {
        if (!_.isEmpty(response)) {
          let searchResult = new SearchResult(<SearchResult>response);
          const wildcardSearchParams = new WildcardSearchParameters(lro, 0);
          wildcardSearchParams.searchText = searchResult.searchText;
          this._lastSearchParams.next(wildcardSearchParams);
          this._lastSearchResults.next(searchResult);
          return searchResult;
        } else {
          return "{}";
        }
      }),
      catchError((err) => {
        this.loggerService.logError('omnibar search properties by search type', OmnibarSearchTypeEnum.ALL_DISPLAY, 'failed');
        return throwError(err);
      })
    );
  }

  nextWildCardSearch = (searchString: string) => {
    this._wildCardSearch.next(searchString);
  }

  searchPropertiesByWildcardParam = (wildcardParam: WildcardSearchParameters, nextItemAddress?: number, nextItemOwner?: number): Observable<any> => {
    return this.searchPropertiesByWildcard(wildcardParam.searchText, wildcardParam.lro, wildcardParam.page, false, nextItemAddress, nextItemOwner);
  }

  searchPropertiesByWildcard = (searchText: string, lro: string, page: number = 0, noTracking: boolean = false, nextItemAddress?: number, nextItemOwner?: number): Observable<SearchResult> => {

    let url = baseUrl + '/omnibar/properties?searchText=' + searchText + `&page=${page}&pageSize=${this.PAGE_SIZE}&lro=${lro}`;
    if(_.isNumber(nextItemAddress)){
      url += '&nextItemAddress='+nextItemAddress;
    }
    if(_.isNumber(nextItemOwner)){
      url += '&nextItemOwner='+nextItemOwner;
    }
    this.loggerService.logDebug(`calling ${url} to search properties by search type ${OmnibarSearchTypeEnum.ALL_VALUE}`);

    return this.https.get(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).pipe(
      map(response => {
        if (!_.isEmpty(response)) {
          let searchResult = new SearchResult(<SearchResult>response);
          const wildcardSearchParams = new WildcardSearchParameters(lro, page);
          wildcardSearchParams.searchText = searchText;
          if (! noTracking) {
            this._lastSearchParams.next(wildcardSearchParams);
            this._lastSearchResults.next(searchResult);
          }
          return searchResult;
        } else {
          return new SearchResult();
        }
      }),
      catchError((err) => {
        this.loggerService.logError('omnibar search properties by wildcard ' + searchText, OmnibarSearchTypeEnum.ALL_VALUE, 'failed');
        return throwError(err);
      })
    );
  }

  searchPropertiesByOwnerParam = (ownerParams: OwnerSearchParameters, nextItemOwner?: number): Observable<SearchResult> => {
    return this.searchPropertiesByOwner(ownerParams.firstName, ownerParams.lastOrCorpName, ownerParams.lro, ownerParams.page, ownerParams.noTracking, nextItemOwner);
  }
  searchPropertiesByOwner = (firstName: string, lastOrCorpName: string, lro: string, page: number = 0, noTracking: boolean = false, nextItemOwner?: number): Observable<SearchResult> => {
    let url = `${baseUrl}/omnibar/advancedOwner?firstName=${firstName}&lastName=${lastOrCorpName}&pageSize=${this.PAGE_SIZE}&lro=${lro}`;
    if(_.isNumber(nextItemOwner)){
      url += `&nextItemOwner=${nextItemOwner}`;
    }
    this.loggerService.logDebug(`calling ${url} to search properties by search type ${OmnibarSearchTypeEnum.OWNER_DISPLAY}`);

    return this.https.get(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).pipe(
      map(response => {
        if (!_.isEmpty(response)) {
          let searchResult = new SearchResult(<SearchResult>response);
          if (! noTracking) {
            this._lastSearchResults.next(searchResult);
          }
          const ownerSearchParams = new OwnerSearchParameters(lro, page);
          ownerSearchParams.firstName = firstName;
          ownerSearchParams.lastOrCorpName = lastOrCorpName;
          ownerSearchParams.nextItemOwner = searchResult.nextItemOwner;
          ownerSearchParams.noTracking = noTracking;
          if (! noTracking) {
            this._lastSearchParams.next(ownerSearchParams);
          }
          return searchResult;
        } else {
          return new SearchResult();
        }
      }),
      catchError((err) => {
        this.loggerService.logError('omnibar search properties by search type', OmnibarSearchTypeEnum.OWNER_VALUE, 'failed');
        return throwError(err);
      })
    );
  }


  searchPropertiesByOwnerFullNameParam = (searchParam: OwnerFullNameSearchParameters): Observable<SearchResult> => {
    return this.searchPropertiesByOwnerFullName(searchParam.ownerFullName, searchParam.lro, searchParam.page);
  }
  // looking for all properties of an owner
  // collaboration-uat.geowarehouse.ca/gema-rest/rest/omnibar/properties/ownerfullname?ownerName=CHALLACOMBE%2C%20JOHN&lro=80&pageSize=20
  searchPropertiesByOwnerFullName = (ownerFullName: string, lro: string, page: number = 0): Observable<SearchResult> => {
    const url = `${baseUrl}/omnibar/properties/ownerfullname?ownerName=${encodeURIComponent(ownerFullName)}&page=${page}&pageSize=${this.PAGE_SIZE}&lro=${lro}`;
    this.loggerService.logDebug(`calling ${url} to search for properties of owner ${ownerFullName}`);

    return this.https.get(url)
      .pipe(
      map(response => {
        if (!_.isEmpty(response)) {
          let searchResult = new SearchResult(<SearchResult>response);
          const searchParams = new OwnerFullNameSearchParameters(lro,  page);
          searchParams.ownerFullName = ownerFullName;
          this._lastSecondLevelSearchParams.next(searchParams);
          this._lastSecondLevelSearchResults.next(searchResult);
          return searchResult;
        } else {
          return new SearchResult();
        }
      }),
      catchError((err) => {
        this.loggerService.logError('omnibar search properties by search type', OmnibarSearchTypeEnum.OWNER_VALUE, 'failed');
        return throwError(err);
      })
    );
  }

  searchPropertiesByInstrumentParam = (instrumenParams: InstrumentSearchParameters): Observable<SearchResult> => {
    return this.searchPropertiesByInstrument(instrumenParams.instrument, instrumenParams.lro, instrumenParams.page);
  }

  searchPropertiesByInstrument = (instrument: string, lro: string, page: number = 0): Observable<SearchResult> => {
    const url = baseUrl + '/omnibar/properties/instrument?value=' + instrument + `&page=${page}&pageSize=${this.PAGE_SIZE}&lro=${lro}`;
    this.loggerService.logDebug(`calling ${url} to search properties by search type ${OmnibarSearchTypeEnum.INSTRUMENT_DISPLAY}`);

    return this.https.get(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).pipe(
      map(response => {
        if (!_.isEmpty(response)) {
          let searchResult = new SearchResult(<SearchResult>response);
          this._lastSearchResults.next(searchResult);
          const instrumentSearchParameters = new InstrumentSearchParameters(lro, page);
          instrumentSearchParameters.instrument = instrument;
          this._lastSearchParams.next(instrumentSearchParameters);
          return searchResult;
        } else {
          return new SearchResult();
        }
      }),
      catchError((err) => {
        this.loggerService.logError('omnibar search properties by search type', OmnibarSearchTypeEnum.INSTRUMENT_DISPLAY, 'failed');
        return throwError(err);
      })
    );
  }

  searchPropertiesByLotConcessionParams = (lotParams: LotSearchParameters): Observable<SearchResult> => {
    return this.searchPropertiesByLotConcession(lotParams.lot, lotParams.lotId);
  }

  searchPropertiesByLotConcession = (lot: string, lotId: number): Observable<SearchResult> => {
    const url = baseUrl + '/lotcon/properties?lot=' + lot + '&lotId=' + lotId + `&pageSize=${this.PAGE_SIZE}`;
    this.loggerService.logDebug(`calling ${url} to search properties by search type ${OmnibarSearchTypeEnum.LOT_CONCESSION_DISPLAY}`);

    return this.https.get(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).pipe(
      map(response => {
        if (!_.isEmpty(response)) {
          const lotSearchParams = new LotSearchParameters('',0);
          lotSearchParams.lot = lot;
          lotSearchParams.lotId = lotId;
          this._lastSearchParams.next(<LotSearchParameters> lotSearchParams);
          let searchResults = new SearchResult(<SearchResult>response);
          this._lastSearchResults.next(searchResults);
          return searchResults;
        } else {
          return new SearchResult();
        }
      }),
      catchError((err) => {
        this.loggerService.logError('omnibar search properties by search type', OmnibarSearchTypeEnum.LOT_CONCESSION_DISPLAY, 'failed');
        return throwError(err);
      })
    );
  }

  getNextPageForLastSearch(): Observable<any> {
    const lastSearch = this._lastSearchParams.getValue();
    const lastSearchResult = <SearchResult>this._lastSearchResults.getValue();
    if (lastSearch && lastSearch instanceof SearchParameters) {
      lastSearch.page ++;
      switch (lastSearch.searchType) {
        case OmnibarSearchTypeEnum.ALL_VALUE:
          return this.searchPropertiesByWildcardParam(<WildcardSearchParameters>lastSearch, lastSearchResult.nextItemAddress, lastSearchResult.nextItemOwner);
        case OmnibarSearchTypeEnum.INSTRUMENT_VALUE:
          return this.searchPropertiesByInstrumentParam(<InstrumentSearchParameters>lastSearch);
        case OmnibarSearchTypeEnum.OWNER_VALUE:
          return this.searchPropertiesByOwnerParam(<OwnerSearchParameters>lastSearch, lastSearchResult.nextItemOwner);
        case OmnibarSearchTypeEnum.LOT_CONCESSION_VALUE:
          return this.searchPropertiesByLotConcessionParams(<LotSearchParameters>lastSearch);
      }
    }
    return of(null);
  }

  getNextPageForSecondLevelLastSearch(): Observable<any> {
    const lastSearch = this._lastSecondLevelSearchParams.getValue();
    if (lastSearch && lastSearch instanceof SearchParameters) {
      lastSearch.page ++;
      switch (lastSearch.searchType) {
        case OmnibarSearchTypeEnum.OWNER_VALUE:
          return this.searchPropertiesByOwnerFullNameParam(<OwnerFullNameSearchParameters>lastSearch);
      }
    }
    return of(null);
  }

  getTownshipPolygon = (lot: string, lotId: number): Observable<any> => {
    const url = baseUrl + '/lotcon/polygon?lot=' + lot + '&lotId=' + lotId;
    this.loggerService.logDebug(`calling ${url} to get polygon for lot ${lot}, lot id ${lotId}`);

    return this.https.get(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).pipe(
      map(response => {
        if (!_.isEmpty(response)) {
          return response;
        } else {
          return "{}";
        }
      }),
      catchError((err) => {
        this.loggerService.logError(`get polygon for lot ${lot}, lot id ${lotId} failed`);
        return throwError(err);
      })
    );
  }

  getCondoLevelsCounterByBlock = (condoSummary: CondoSummary): Observable<CondoLevel[]> => {
    const url = baseUrl + `/property/condoLevels/list/count?condoBlock=${condoSummary.block}`;
    return this.https.get(url).pipe(
      map(response => {
        if (!_.isEmpty(response) &&  Array.isArray(response)) {
          const result = response.map(value => { return new CondoLevel(condoSummary.block, value[0], value[1])});
          this._lastSecondLevelSearchResults.next(result);
          this._lastSecondLevelSearchParams.next(new CondoSearchParameters(condoSummary.block, condoSummary.fullAddress));
          return result;
        } else {
          return [];
        }
      }),
      catchError((err) => {
        this.loggerService.logError(`failed to retrieve condo levels counters for block ${condoSummary.block}`);
        return throwError(err);
      })
    );
  }

  getCondosByBlockAndLevel = (block: string, level: string | number): Observable<CondoUnit[]> => {
    const url = baseUrl + `/property/condoLevel?condoBlock=${block}&level=${level}`;
    return this.https.get(url).pipe(
      map(response => {
        if (!_.isEmpty(response) && Array.isArray(response)) {
          let result: CondoUnit[] = [];
          response.forEach((value : any) => {
            if (value.level == level) {
              value.units.forEach( (value : any) => {
                  result.push(new CondoUnit(<CondoUnit>value));
                }
              )
            }
          })
          return result;
        } else {
          return [];
        }
      }),
      catchError((err) => {
        this.loggerService.logError(`failed to retrieve condo units for level ${level} from block ${block}`);
        return throwError(err);
      })
    );
  }

  getPropertiesByBlock = (blockNumber: string, pageSize: number, pageNumber: number): Observable<any> => {
    const url = baseUrl + `/omnibar/properties/block?value=${blockNumber}&pageSize=${pageSize}&page=${pageNumber}`;

    this.loggerService.logDebug(`calling ${url} to get properties by block ${blockNumber}`);

    return this.https.get(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).pipe(
      map(response => {
        if (!_.isEmpty(response)) {
          let searchResult = new SearchResult(<SearchResult>response);
          return searchResult;
        } else {
          return new SearchResult();
        }
      }),
      catchError((err) => {
        this.loggerService.logError(`error getting properties by block ${blockNumber}`, err);
        return throwError(err);
      })
    );
  }

  getPropertiesByBlockNumber = async (blockNumber: string, pageSize: number, pageNumber: number): Promise<SearchResult> => {
    const url = baseUrl + `/omnibar/properties/block?value=${blockNumber}&pageSize=${pageSize}&page=${pageNumber}`;
    this.loggerService.logDebug(`calling ${url} to get properties by block ${blockNumber}`);

    this.https.get(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).pipe(
      map(response => {
        if (!_.isEmpty(response)) {
          let searchResult = new SearchResult(<SearchResult>response);
          //return searchResult;

          return new Promise(resolve => {
            resolve(searchResult);
          });

        } else {
          //return new SearchResult();

          return new Promise(resolve => {
            resolve(new SearchResult());
          });

        }
      }),
      catchError((err) => {
        this.loggerService.logError(`error getting properties by block ${blockNumber}`, err);
        return throwError(err);
      })
    );

    return new Promise(resolve => {
      resolve(new SearchResult());
    });
  }

}
