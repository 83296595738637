import {TransactionHistoryDetail} from "./transaction-history-detail";

export class TransactionHistory {

  constructor(transactionHistory? : TransactionHistory) {
    if(transactionHistory){
      for(let property in transactionHistory){
        if(transactionHistory.hasOwnProperty(property)){
          // @ts-ignore
          this[property] = transactionHistory[property];
        }
      }
    }
  }

  date: string;
  price: number;
  products: TransactionHistoryDetail[];
  transactionId: number;

  getAllProducts() : string {
    if (this.products && this.products.length){
      return this.products
        .map(product => {return product.productName.toUpperCase()})
        .join(', ');
    }
    return '';
  }
}
