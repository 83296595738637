import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BaseUnsubscribe } from '../../core/component/base-unsubscribe/base-unsubscribe';
import { LoggerService } from '../../shared/service/log/logger.service';
import { takeUntil } from "rxjs";

@Component({
  selector: 'gema3g-sso-deeplink',
  standalone: false,
  templateUrl: './sso-deeplink.component.html',
  styleUrl: './sso-deeplink.component.scss'
})
export class SSODeeplinkComponent extends BaseUnsubscribe implements OnInit {
  constructor(private loggerService: LoggerService,
              private route: ActivatedRoute) {

    super();

    this.route.queryParams
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((params) => {
        this.params = params;
      }
    );
  }

  params: Params;

  ngOnInit(): void {
  }
}
