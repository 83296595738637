import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {DemographicsSocioEconomicItem} from "../../../../../../core/model/demographics/demographics-socio-economic-item";
import {FusionChartDataSource} from "../../../../../../core/model/fusion-chart/fusion-chart-data-source";
import {DemographicsChartsUtility} from "../../../../../../shared/utility/fusion-charts/demographics-charts.utility";
import {DataService} from "../../../../../../shared/service/data.service";

@Component({
  selector: 'gema3g-education-level',
  templateUrl: './education-level.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./education-level.component.scss']
})
export class EducationLevelComponent implements OnChanges {
  @Input() socioEconomic : DemographicsSocioEconomicItem;
  @Input() key : string;
  educationStatus: FusionChartDataSource = new FusionChartDataSource();

  calculateOtherValue() {
    return 100 -
      Math.round(this.socioEconomic.percentageWithUniversityDegree) -
      Math.round(this.socioEconomic.percentageWithCollegeDiploma) -
      Math.round(this.socioEconomic.percentageWithHighSchoolDiploma);
  }

  ngOnChanges(changes: SimpleChanges): void {
      this.educationStatus = DemographicsChartsUtility.educationStatusDataSource(DataService.DEFAULT_CHART_FONT, this.key, this.socioEconomic, window.innerWidth);
  }
}
