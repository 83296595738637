import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyAccountComponent } from './my-account/my-account/my-account.component';
import { ProfileComponent } from './profile/profile/profile.component';
import { PasswordComponent } from './profile/password/password.component';
import { AccountDownloadComponent } from './download/account-download/account-download.component';
import { SubscriptionComponent } from './subscription/subscription/subscription.component';
import { ReportHistoryComponent } from './subscription/report-history/report-history.component';
import { BillingPaymentComponent } from './billing-payment/billing-payment/billing-payment.component';
import { PaymentHistoryComponent } from './billing-payment/payment-history/payment-history.component';
import { PreferenceComponent } from './preference/preference/preference.component';
import { CoreModule } from "../core/core.module";
import { RouterLink, RouterOutlet } from "@angular/router";
import { AccountOverviewComponent } from './my-account/account-overview/account-overview.component';
import { MatCardModule } from "@angular/material/card";
import { MatInputModule } from "@angular/material/input";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatDividerModule } from "@angular/material/divider";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { SharedModule } from "../shared/shared.module";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatIconModule } from "@angular/material/icon";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "../core/material.module";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { TooltipModule } from "primeng/tooltip";
import { MatButtonModule } from '@angular/material/button';
// import {NgIf} from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import {ComparablesSearchReportHistoryComponent} from "./subscription/comparables-search-report-history/comparables-search-report-history.component";

@NgModule({
  declarations: [
    MyAccountComponent,
    ProfileComponent,
    PasswordComponent,
    AccountDownloadComponent,
    SubscriptionComponent,
    ReportHistoryComponent,
    ComparablesSearchReportHistoryComponent,
    BillingPaymentComponent,
    PaymentHistoryComponent,
    PreferenceComponent,
    AccountOverviewComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    RouterOutlet,
    MatCardModule,
    RouterLink,
    MatInputModule,
    MatTableModule,
    MatTabsModule,
    MatDividerModule,
    MatSelectModule,
    MatButtonToggleModule,
    SharedModule,
    MatProgressBarModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    MaterialModule,
    NgxDaterangepickerMd,
    MatPaginatorModule,
    MatSortModule,
    TooltipModule,
    MatSidenavModule,
    // NgIf,
    MatButtonModule
  ],
  exports: [
    ProfileComponent
  ]
})
export class AccountModule {
}
