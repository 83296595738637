<div class="main-container">

    <div class="top">
        <div class="logo">
            <img id="pnf-imf-logo" src="assets/img/geowarehouse-g-logo.png"/>
        </div>

        <div class="oops">
            <div id="pnf-div-404" class="four-zero-four">
                404
            </div>
        
            <div id="pnf-div-not-found" class="page-not-found text-os-bold">
                {{pageNotFoundText}}
            </div>        
        </div>
    </div>

    <div id="pnf-div-sorry" class="sorry">
        {{pageNotFoundTextDescription}}
    </div>

    <div class="home-page">
        <button id="pnf-btn-sign-in" (click)="home()" class="sign-in-btn" mat-raised-button>
        Go to home page
      </button>
    </div>
</div>