<div class="gema3g-ss-container">
  <div class="gema3g-ss-container__inner">
    <div class="gema3g-ss-container__inner-grid">

      <div class="l-column">
        <h4 id="ss-h4-ci-{{assessment.rollNumber}}">Condominium Information</h4>
      </div>

      @for (structureData of assessment.mpacAssessment?.property?.residentialStructures; track structureData) {
        <div class="m-column">
          <div id="ss-ci-locker-label-{{assessment.rollNumber}}-{{structureData.structureNumber}}" class="flex">
            <h4 id="ss-h4-ci-locker-{{assessment.rollNumber}}-{{structureData.structureNumber}}">Locker </h4>
            <span id="ss-ci-locker-{{assessment.rollNumber}}-{{structureData.structureNumber}}" class="ml-auto text-right">{{structureData?.extraStandardStgLockersCount | na}}</span>
          </div>
          <div id="ss-ci-ps-label-{{assessment.rollNumber}}-{{structureData.structureNumber}}" class="flex">
            <h4 id="ss-h4-ci-ps-{{assessment.rollNumber}}-{{structureData.structureNumber}}">Parking Spaces </h4>
            <span id="ss-ci-ps-{{assessment.rollNumber}}-{{structureData.structureNumber}}" class="ml-auto text-right">{{structureData.condoParkingSpaces | na}}</span>
          </div>
        </div>

        <div class="r-column">
          <div id="ss-ci-ufl-label-{{assessment.rollNumber}}-{{structureData.structureNumber}}" class="flex">
            <h4 id="ss-h4-ci-ufl-{{assessment.rollNumber}}-{{structureData.structureNumber}}">Unit Floor Level </h4>
            <span id="ss-ci-ufl-{{assessment.rollNumber}}-{{structureData.structureNumber}}" class="ml-auto text-right">{{structureData.condoFloorLevel | na}}</span>
          </div>
          @if (userAccessControls.MPACSubscriptionPremiumOrEnhanced) {
            <div id="ss-ci-ips-label-{{assessment.rollNumber}}-{{structureData.structureNumber}}" class="flex">
              <h4 id="ss-h4-ci-ips-{{assessment.rollNumber}}-{{structureData.structureNumber}}">Indoor Parking Spaces </h4>
              <span id="ss-ci-ips-{{assessment.rollNumber}}-{{structureData.structureNumber}}" class="ml-auto text-right">{{structureData.condoParkingIndoorSpaces | na}}</span>
            </div>
          }
        </div>
      }
    </div>
  </div>
</div>
