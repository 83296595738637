<div class="flex flex-col py-2">
  <div class="flex flex-row">
    <h4 id="ss-ed-label-{{assessment.rollNumber}}" class="pr-site-structure-section-label h-full align-top">Enhanced Data</h4>
  </div>
  @if (assessment?.enhancedAssessment?.structures?.length) {
    <div class="flex flex-col">
      <h4 class="pr-data-label mr-2">Structures</h4>
      <div class="flex flex-row dense-2 pb-2 overflow-auto">
        <table id="ss-tbl-ed-{{assessment.rollNumber}}" [dataSource]="structuresDataSource" class="h-[100%]" class="mat-elevation-z2 standard-table" mat-table matSort>
          <ng-container matColumnDef="structureNumber">
            <th id="ss-tbl-hdr-ed-sn-{{assessment.rollNumber}}" *matHeaderCellDef mat-header-cell mat-sort-header>#</th>
            <td id="ss-tbl-data-ed-sn-{{assessment.rollNumber}}-{{element.structureNumber}}" *matCellDef="let element" mat-cell>
              {{element.structureNumber | na}}
            </td>
          </ng-container>
          <ng-container matColumnDef="structureDescription">
            <th id="ss-tbl-hdr-ed-sd-{{assessment.rollNumber}}" *matHeaderCellDef mat-header-cell mat-sort-header>Description</th>
            <td id="ss-tbl-data-ed-sd-{{assessment.rollNumber}}-{{element.structureNumber}}" *matCellDef="let element" mat-cell>
              {{element.structureDescription | na}}
            </td>
          </ng-container>
          <ng-container matColumnDef="actualYearBuilt">
            <th id="ss-tbl-hdr-ed-ayb-{{assessment.rollNumber}}" *matHeaderCellDef mat-header-cell mat-sort-header>Year<br/>Built</th>
            <td id="ss-tbl-data-ed-ayb-{{assessment.rollNumber}}-{{element.structureNumber}}" *matCellDef="let element" mat-cell>
              {{element.actualYearBuilt | na}}
            </td>
          </ng-container>
          <ng-container matColumnDef="totalBedroomsCount">
            <th id="ss-tbl-hdr-ed-tbc-{{assessment.rollNumber}}" *matHeaderCellDef mat-header-cell mat-sort-header>Bedrooms</th>
            <td id="ss-tbl-data-ed-tbc-{{assessment.rollNumber}}-{{element.structureNumber}}" *matCellDef="let element" mat-cell>
              {{element.totalBedroomsCount | na}}
            </td>
          </ng-container>
          <ng-container matColumnDef="totalFullBathsCount">
            <th id="ss-tbl-hdr-ed-tfbc-{{assessment.rollNumber}}" *matHeaderCellDef mat-header-cell mat-sort-header>Full<br/>Bathrooms</th>
            <td id="ss-tbl-data-ed-tfbc-{{assessment.rollNumber}}-{{element.structureNumber}}" *matCellDef="let element" mat-cell>
              {{element.totalFullBathsCount | na}}
            </td>
          </ng-container>
          <ng-container matColumnDef="totalHalfBathsCount">
            <th id="ss-tbl-hdr-ed-thbc-{{assessment.rollNumber}}" *matHeaderCellDef mat-header-cell mat-sort-header>Half<br/>Bathrooms</th>
            <td id="ss-tbl-data-ed-thbc-{{assessment.rollNumber}}-{{element.structureNumber}}" *matCellDef="let element" mat-cell>
              {{element.totalHalfBathsCount | na}}
            </td>
          </ng-container>
          <ng-container matColumnDef="totalFullStoreysCount">
            <th id="ss-tbl-hdr-ed-tfsc-{{assessment.rollNumber}}" *matHeaderCellDef mat-header-cell mat-sort-header>Full<br/>Stories</th>
            <td id="ss-tbl-data-ed-tfsc-{{assessment.rollNumber}}-{{element.structureNumber}}" *matCellDef="let element" mat-cell>
              {{element.totalFullStoreysCount | na}}
            </td>
          </ng-container>
          <ng-container matColumnDef="partStoreysDescription">
            <th id="ss-tbl-hdr-ed-psd-{{assessment.rollNumber}}" *matHeaderCellDef mat-header-cell mat-sort-header>Partial<br/>Stories</th>
            <td id="ss-tbl-data-ed-psd-{{assessment.rollNumber}}-{{element.structureNumber}}" *matCellDef="let element" mat-cell>
              {{element.partStoreysDescription | na}}
            </td>
          </ng-container>
          <ng-container matColumnDef="splitLevelTypeDescription">
            <th id="ss-tbl-hdr-ed-sltd-{{assessment.rollNumber}}" *matHeaderCellDef mat-header-cell mat-sort-header>Split<br/>Level</th>
            <td id="ss-tbl-data-ed-sltd-{{assessment.rollNumber}}-{{element.structureNumber}}" *matCellDef="let element" mat-cell>
              {{element.splitLevelTypeDescription | na}}
            </td>
          </ng-container>
          <ng-container matColumnDef="totalFirePlacesCount">
            <th id="ss-tbl-hdr-ed-tfpc-{{assessment.rollNumber}}" *matHeaderCellDef mat-header-cell mat-sort-header>Fireplaces</th>
            <td id="ss-tbl-data-ed-tfpc-{{assessment.rollNumber}}-{{element.structureNumber}}" *matCellDef="let element" mat-cell>
              {{element.totalFirePlacesCount | na}}
            </td>
          </ng-container>
          <tr id="ss-tbl-row-ed-header-{{assessment.rollNumber}}" *matHeaderRowDef="columns" mat-header-row></tr>
          <tr id="ss-tbl-row-ed-{{assessment.rollNumber}}-{{row.structureNumber}}" *matRowDef="let row; columns: columns;" mat-row></tr>
        </table>
      </div>
    </div>
  }

  <div class="gema3g-ss-container__inner-grid">
    <div class="l-column">
      @if(assessment?.enhancedAssessment?.structures?.length == 0){
        <h4 id="ss-ed-struct-label-{{assessment.rollNumber}}" class="pr-data-label mr-2 w-1/2">Structures</h4>
      }
    </div>
    <div class="m-column">
      <div class="flex flex-row">
        <h4 id="ss-ed-ld-label-{{assessment.rollNumber}}" class="pr-data-label mr-2 w-1/2">Assessment Roll Legal Description</h4>
        <span id="ss-ed-ld-{{assessment.rollNumber}}" class="pr-data-value w-1/2">{{assessment.enhancedAssessment?.property?.legalDescription | na}}</span>
      </div>
      <div class="flex flex-row">
        <h4 id="ss-ed-ma-label-{{assessment.rollNumber}}" class="pr-data-label mr-2 w-1/2">MPAC Address</h4>
        <span id="ss-ed-ma-{{assessment.rollNumber}}" class="pr-data-value w-1/2">{{ assessment.enhancedAssessment?.address?.fullAddress | na}}</span>
      </div>
      <div class="flex flex-row">
        <h4 id="ss-ed-zoning-label-{{assessment.rollNumber}}" class="pr-data-label mr-2 w-1/2">Zoning</h4>
        <span id="ss-ed-zoning-{{assessment.rollNumber}}" class="pr-data-value w-1/2">{{assessment.enhancedAssessment?.property?.zoningList | na}}</span>
      </div>
      <div class="flex flex-row">
        <h4 id="ss-ed-pt-label-{{assessment.rollNumber}}" class="pr-data-label mr-2 w-1/2">Property Type</h4>
        <span id="ss-ed-pt-{{assessment.rollNumber}}" class="pr-data-value w-1/2">{{assessment.enhancedAssessment?.property?.propertyTypeDescription | na}}</span>
      </div>
      @if (userAccessControls.assessmentDetailsReport) {
        <div class="flex flex-row">
          <h4 id="ss-ed-sa-label-{{assessment.rollNumber}}" class="pr-data-label mr-2 w-1/2">Site Area</h4>
          <span id="ss-ed-sa-{{assessment.rollNumber}}" class="pr-data-value w-1/2">{{assessment.enhancedAssessment?.property?.actualLotSizeSys | na }}{{assessment.enhancedAssessment?.property?.actualLotSizeUom || ''}}</span>
        </div>
      }
      <div class="flex flex-row">
        <h4 id="ss-ed-sv-label-{{assessment.rollNumber}}" class="pr-data-label mr-2 w-1/2">Site Variance</h4>
        <span id="ss-ed-sv-{{assessment.rollNumber}}" class="pr-data-value w-1/2">{{assessment.enhancedAssessment?.property?.actualVarianceDescription | na}}</span>
      </div>
    </div>
    <div class="r-column">
      <div class="flex flex-row">
        <h4 id="ss-ed-dt-label-{{assessment.rollNumber}}" class="pr-data-label mr-2 w-1/2">Driveway Type</h4>
        <span id="ss-ed-dt-{{assessment.rollNumber}}" class="pr-data-value w-1/2">{{assessment.enhancedAssessment?.property?.drivewayTypeDescription | na}}</span>
      </div>
      <div class="flex flex-row">
        <h4 id="ss-ed-gt-label-{{assessment.rollNumber}}" class="pr-data-label mr-2 w-1/2">Garage Type</h4>
        <span id="ss-ed-gt-{{assessment.rollNumber}}" class="pr-data-value w-1/2">{{assessment.enhancedAssessment?.property?.garageType | na}}</span>
      </div>
      <div class="flex flex-row">
        <h4 id="ss-ed-gs-label-{{assessment.rollNumber}}" class="pr-data-label mr-2 w-1/2">Garage Spaces</h4>
        <span id="ss-ed-gs-{{assessment.rollNumber}}" class="pr-data-value w-1/2">{{assessment.enhancedAssessment?.property?.totalGarageSpaces | na}}</span>
      </div>
      <div class="flex flex-row">
        <h4 id="ss-ed-wst-label-{{assessment.rollNumber}}" class="pr-data-label mr-2 w-1/2">Water Service Type</h4>
        <span id="ss-ed-wst-{{assessment.rollNumber}}" class="pr-data-value w-1/2">{{assessment.enhancedAssessment?.property?.waterServiceDescription | na}}</span>
      </div>
      <div class="flex flex-row">
        <h4 id="ss-ed-st-label-{{assessment.rollNumber}}" class="pr-data-label mr-2  w-1/2">Sanitation Type</h4>
        <span id="ss-ed-st-{{assessment.rollNumber}}" class="pr-data-value  w-1/2">{{assessment.enhancedAssessment?.property?.sanitaryServiceDescription | na}}</span>
      </div>
      <div class="flex flex-row">
        <h4 id="ss-ed-pool-label-{{assessment.rollNumber}}" class="pr-data-label mr-2 w-1/2">Pool</h4>
        <span id="ss-ed-pool-{{assessment.rollNumber}}" class="pr-data-value w-1/2">{{assessment.enhancedAssessment?.property?.pool | na}}</span>
      </div>
      <div class="flex flex-row">
        <h4 id="ss-ed-locker-label-{{assessment.rollNumber}}" class="pr-data-label mr-2 w-1/2">Locker</h4>
        <span id="ss-ed-locker-{{assessment.rollNumber}}" class="pr-data-value w-1/2">{{assessment.enhancedAssessment?.property?.totalStandardLockers | na}}</span>
      </div>
    </div>

  </div>
</div>
