import {BaseModel} from "../../base-model";

export class EStoreParamAdditional extends BaseModel{
  city : string | null;
  municipality : string | undefined;
  postalCode : string | null;
  fromDate : string;
  toDate : string;
  quarter: string;

  get first3CharPostalCode() : string {
    return this.postalCode ? this.postalCode.substr(0,3) : '';
  }

  lroCode : string | null;
  lroNameFromLroCode : string | null;

  get lroName() : string {
    let lroName = this.lroNameFromLroCode ? this.lroNameFromLroCode.toUpperCase() : '';
    if(lroName){
      lroName =  lroName === 'HAMILTON-WENTWORTH' ? 'WENTWORTH' : lroName;
      lroName = lroName.replace(/ /g, '_');
    }
    return lroName
  }
}
