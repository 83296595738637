import {BaseModel} from "../../base-model";

export class ComparableSalesReportItem extends BaseModel{
  reportId: number;
  reportName: string;
  modifiedDate: number;
  clientName: string;
  jobNumber: string;
  comments: string;
  numberOfComparables: number;
  subjectPin: string;
  address: string;
}
