<div class="gema3g-ao-container">
  <div class="gema3g-ao-container__inner">

    <div class="col-0 flex justify-between mb-9">
      @if (userLicenseCounters$ | async; as userReportCounter) {
        @if (userReportCounter.comparablesSearchCounter && userReportCounter.comparablesSearchCounter.licenseTotal > 0 && hasAccessToMoreReports) {
          <div id="sub-comp-search-total" class="geo-flex-column report-number">
            {{ userReportCounter.comparablesSearchCounter?.licenseTotal }}
            <span id="sub-comp-search-total-label">Total Search</span>
          </div>
          <div id="sub-comp-search-viewed" class="geo-flex-column report-number">
            {{ userReportCounter.comparablesSearchCounter?.licenseUsed }}
            <span id="sub-comp-search-viewed-label">Viewed</span>
          </div>
          <div id="sub-comp-search-remaining" class="geo-flex-column report-number last">
            {{ userReportCounter.comparablesSearchCounter?.licenseRemaining }}
            <span id="sub-comp-search-remaining-label">Remaining</span>
          </div>
          <div class="report-button">
            @if (userReportCounter.comparablesSearchCounter.licenseRemaining <= warningThreshold){
            <span class="m-1">{{warningMessage}}</span>
            }
            <a (click)="openEstoreCatalogue()"
               class="btn btn-getmore"
               id="account-report-history-button">
              Get More Reports
            </a>
          </div>
        }
      } @else {
        <div class="m-1">
          Loading...
        </div>
      }
    </div>

    <h3 id="sub-h3-rh" class="title">{{ title }}</h3>
    <p id="sub-comp-search-desc" class="sub-title">{{ subTitle }}</p>

    <div class="col-0">
      @if (isLoading) {
        <mat-progress-bar id="sub-progress-bar-rh" mode="indeterminate"></mat-progress-bar>
      }
      <table id="sub-tbl-rh" [dataSource]="dataSource" class="mat-elevation-z8 standard-table" mat-table>

        <ng-container matColumnDef="date">
          <th id="sub-tbl-hdr-comp-search-date" *matHeaderCellDef mat-header-cell>Date</th>
          <td id="sub-tbl-data-comp-search-date-{{element.count}}" *matCellDef="let element" mat-cell>
            {{ element.createdDate | date : 'mediumDate' }}
            <span class="time">
              |  {{ element.createdDate | date:'h:mm a' : '+0000' }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="activityDisplayName">
          <th id="sub-tbl-hdr-comp-search-source" *matHeaderCellDef mat-header-cell>Search Type</th>
          <td id="sub-tbl-data-comp-search-source-{{element.count}}" *matCellDef="let element" mat-cell>
            {{ element.activityDisplayName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="count">
          <th id="sub-tbl-hdr-comp-search-addr" *matHeaderCellDef mat-header-cell>Count</th>
          <td id="sub-tbl-data-comp-search-addr-{{element.count}}" *matCellDef="let element" mat-cell>

            <ng-container> {{ element.count }}</ng-container>
          </td>
        </ng-container>

        <tr id="sub-tbl-row-comp-search-hdr" *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr id="sub-tbl-row-comp-search-{{row.count}}" *matRowDef="let row;
            let odd = odd;
            columns: displayedColumns;" mat-row>
        </tr>
      </table>
      <mat-divider></mat-divider>
      <mat-paginator
        id="sub-paginator-rh"
        (page)="pageEvent = getReport()"
        [length]="length"
        [pageIndex]="pageIndex"
        [pageSizeOptions]="pageSizes"
        [pageSize]="pageSizes[1]"
        showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>
</div>
