export class FusionChartAnnotationGroupItem {
  type: string;
  text: string;
  x: string;
  y: string;
  color: string;
  align: string;

  constructor(type: string, text: string, x: string, y: string, color: string, align: string) {
    this.type = type;
    this.text = text;
    this.x = x;
    this.y = y;
    this.color = color;
    this.align = align;
  }
}
