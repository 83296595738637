import {BaseModel} from "../../base-model";

export class ComparableSalesReportPii extends BaseModel{
  lro: string;
  pin: string;
  assessmentRollNumber: string;
  registrationType: string;
  municipality: string;
  landRegistryStatus: string;
  postalCode: string | null;
  area: any;
  perimeter: any;
  streetNumber: string;
  streetName: string;
  streetDesignation: string | null;
  streetDirection: string | null;
  streetSuffix: string | null;
  unitNo: string;
  fullStreetName: string;
  shortDescription: string;
  longDescription: string;
  address: any;
  spatialInformation: any;

}
