<section class="home-container" [ngClass]="{'mobile-full-screen' : isMobileFullScreen}">
  <section class="sticky-components-1">
    <gema3g-header></gema3g-header>

    <!-- pass value from search to map -->
    <gema3g-omnibar-search [searchEnabled]='true'
                           [searchComparablesEnabled]='userAccessControls?.hasAccessToComparableSearch'
                           [renewNowButtonEnabled]="loggedInUser?.hasUpdatableLicense"
                           (searchComparablesFormOpened)='onHoodQMapClosed()'
                           (moveMapControls)='moveMapControls($event)'
                           (hoodQMapClosed)="onHoodQMapClosed()">
    </gema3g-omnibar-search>

    <gema3g-ticker-tape [bannerMessages]="bannerMessages"></gema3g-ticker-tape>

    <div class="map-horizontal-loading-indicator">
      <!-- use div instead of ng-container to avoid the map from jumping -->
      <!-- not sure why background color only works with the style attribute -->
      @if (mainMapSearchInProgress) {
        <p-progressBar id="home-progress-bar" [style]="{'background':'white'}" mode="indeterminate"></p-progressBar>
      }
    </div>

    <!-- pass value to map -->
    <gema3g-main-map [ngStyle]="{display: hoodQMapVisible ? 'none' : 'block'}"
                     (hoodQMapOpened)='onHoodQMapOpened($event)'
                     [isMapControlsMovedInput]="isMapControlsMoved"
                     (mobileFullScreen)="mobileFullScreenMode($event)">
    </gema3g-main-map>

    <gema3g-hoodq-map [ngStyle]="{display: hoodQMapVisible ? 'block' : 'none'}"
                      (hoodQMapClosed)='onHoodQMapClosed()'
                      [mainMapStatus]="mainMapState">
    </gema3g-hoodq-map>
  </section>

  <gema3g-footer></gema3g-footer>
</section>
