
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { CoreModule } from "../core/core.module";
import { MaterialModule } from '../core/material.module';
import { SharedModule } from "../shared/shared.module";
import { LegalAgreementComponent } from './legal-agreement/legal-agreement.component';
import { FontAwesomeModule2 } from '../core/fontawesome.module';
import { NewUserProfileComponent } from './new-user-profile/new-user-profile.component';
import { AccountModule } from '../account/account.module';

@NgModule({
  declarations: [
    LoginComponent,
    LegalAgreementComponent,
    NewUserProfileComponent
  ],
    imports: [
        CommonModule,
        CoreModule,
        MaterialModule,
        SharedModule,
        AccountModule,
        FontAwesomeModule2
    ],
  exports: [
    LoginComponent
  ]
})
export class LoginModule { }
