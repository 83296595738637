import { RataPiiInstrument } from "./pii-instrument";

export class RataPii {
    public static readonly NOT_APPLICABLE: string = "N/A";
    public address: string = RataPii.NOT_APPLICABLE;
    public area?: number;
    public arns: string = RataPii.NOT_APPLICABLE;
    public beid?: number;
    public desc?: string;
    public instruments?: RataPiiInstrument[];
    public landRegistryStatus?: string;
    public lro?: string;
    public municipality: string = RataPii.NOT_APPLICABLE;
    public perimeter?: number;
    public pin?: string;
    public processId?: string;
    public registrationType?: string;
    public reportFileType?: string;
    public viewLinkAllowed: boolean;

    public recordId: number;
}