import {ComparableSearchCriteria} from "./comparable-search-criteria";
import {ReportPreference} from "./report-preference";

export class ComparablePreference {
  constructor(comparablesPreference?: ComparablePreference) {
    if(comparablesPreference){
      this.reportPreferences = new ReportPreference(comparablesPreference.reportPreferences);
      this.searchCriteria = new ComparableSearchCriteria(comparablesPreference.searchCriteria);
    }
  }

  reportPreferences: ReportPreference;
  searchCriteria: ComparableSearchCriteria;

  patchFromUserInput(comparablesPreference: ComparablePreference) {
    if(comparablesPreference){
      this.searchCriteria.patchFromUserInput(comparablesPreference.searchCriteria);
    }
  }

  makeAdjustmentsOnSystemDefaultValues() {
    this.reportPreferences?.makeAdjustmentsOnSystemDefaultValues();
    this.searchCriteria?.makeAdjustmentsOnSystemDefaultValues();
  }
}
