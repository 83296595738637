export class ProgressBarDialogData {

  constructor(title: string, asyncFunction: (param:any) => Promise<void>, param: any, content?: string[], innerHtmlContent?: string, buttonText?: string) {
    if (title != null) {
      this.title = title;
    }
    if (content && content.length > 0) {
      this.content = content;
    }
    if (innerHtmlContent != null) {
      this.innerHtmlContent = innerHtmlContent;
    }
    if (buttonText != null) {
      this.buttonText = buttonText;
    }
    if (asyncFunction != null) {
      this.asyncFunction = asyncFunction;
    }
    if(param != null) {
      this.param = param;
    }
  }

  title: string = '';
  content: string[] | undefined = [];
  innerHtmlContent: string = '';
  buttonText: string = 'Close';

  param: any;
  asyncFunction: (param: any) => Promise<void>;
}
