import {BaseModel} from "../base-model";
import {Spatial} from "../spatial/spatial";
import {Address2} from "./address2";
import {MeasuredLineSegment} from "./measured-line-segment";
import {DataService} from "../../../shared/service/data.service";
import {PinXY} from "./pin-x-y";

export class Pii extends BaseModel {
  constructor(pii?: Pii, initialIndex?: number) {
    super(pii);

    if (pii) {
      if (pii.pinXy) {
        this.pinXy = new PinXY(pii.pinXy);
      }
      if (pii.address) {
        this.address = new Address2(pii.address);
      }
      if (Array.isArray(pii.measuredLineSegment)) {
        this.measuredLineSegment = pii.measuredLineSegment.map(value => {
          return new MeasuredLineSegment(value)
        });
      }
      if (Array.isArray(pii.spatial)) {
        this.spatial = pii.spatial.map(value => {
          return new Spatial(value)
        });
      }
    }
    if (!isNaN(<number>initialIndex)){
      this.initialIndex = initialIndex;
    }
  }

  initialIndex: number | undefined;
  address: Address2;
  arns: string[];
  childPinEventType?: any;
  childPins?: any;
  creationDate: string;
  includeOwners: boolean;
  landRegistryStatus: string;
  longLegalDescription: string;
  lotMeasurementAccuracy: string;
  lro: string;
  measuredLineSegment: MeasuredLineSegment[];
  ownerNames: string[];
  parentPinEventType?: any;
  parentPins?: any;
  pin: string;
  pinXy?: PinXY;
  propertyType: string;
  registrationType: string;
  shortLegalDescription: string;
  spatial: Spatial[];
  streetViewProjection?: any;
  unitType: string;
  condo: boolean;
  onDemandPii: boolean = false;

  get registryStatus(): string {
    return (this.landRegistryStatus == 'A') ? 'Active' : 'Not Active';
  }

  get registryType(): string {
    if (this.registrationType == 'LT') {
      return 'Certified (Land Titles)';
    } else if (this.registrationType == 'R') {
      return 'Registry';
    }
    return DataService.NOT_APPLICABLE;
  }

  get ownershipType(): string {
    return (this.condo) ?  'Condo' : 'Freehold';
  }

  get fullAddress(): string {

    if (this.address && this.address.fullAddress) {
      return this.address.fullAddress;
    }
    return "Address Not Available";
  }

  get legalDescription(): string {
    return ( this.shortLegalDescription) ? this.shortLegalDescription : '';
  }

  get arn(): string {
    return (this.arns?.length > 0)  ?  this.arns[0] : '';
  }

  get hasPinOrArn(): boolean {
    return !!(this.pin || this.arn);
  }

  get hasArn(): boolean {
    return !!(this.arn);
  }

  getPinOrArn(): string {
    if (this.hasPinOrArn){
      return this.pin || this.arn;
    }
    return '';
  }

  // used on sorting
  get streetName(): string {
    if (this.address?.streetName) {
      return this.address.streetName;
    }
    return '';
  }

  get streetNumber(): string {
    if (this.address?.streetNumber) {
      return this.address.streetNumber;
    }
    return '';
  }

  get streetNumLength(): number {
    if (this.address?.streetNumber) {
      return this.address.streetNumber.length;
    }
    return 0;
  }

  get streetDesignation(): string {
    if (this.address?.streetDesignation) {
      return this.address.streetDesignation;
    }
    return '';
  }

  get streetDirection(): string {
    if (this.address?.streetDirection) {
      return this.address.streetDirection;
    }
    return '';
  }

  get unitNumber(): string {
    if (this.address?.unitNumber) {
      return this.address.unitNumber;
    }
    return '';
  }

  get unitNumLength(): number {
    if (this.address?.unitNumber) {
      return this.address.unitNumber.length;
    }
    return 0;
  }

  get city(): string {
    if (this.address?.city) {
      return this.address.city;
    }
    return '';
  }

  get hasLegalDescription(): boolean{
    return (!!this.shortLegalDescription || !!this.longLegalDescription);
  }

  //UI only
  showExpansionImages: boolean = false;
  detailsExpanded: boolean = false;
  arnExpanded: boolean = false;
}
