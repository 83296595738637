@if (cultural) {
  <div class="flex flex-col">
    @if (selectedArea.includes(demographicsAreaEnum.NBH)) {
      <div>
        <div class="flex flex-row flex-wrap gap-1">
          <div class="flex-1">
            <div id="chart-immigration-status-nbh">
              <fusioncharts type="doughnut2d" [dataSource]="nbhDataSource" dataFormat="json" height="{{defaultHeight}}"
                width="{{defaultWidth}}" [dataEmptyMessage]="ds.nbhDataEmptyMessage" (initialized)="initialized($event)">
              </fusioncharts>
            </div>
          </div>
          <div class="flex-1 flex flex-col justify-center gap-1">
            <div class="flex flex-wrap content-center">
              <div>
                @if (getPercentageImmigrant(demographicsAreaEnum.NBH)) {
                  <div id="dg-cul-is-imgrt-label-nbh">
                    @if ((getPercentageImmigrant(demographicsAreaEnum.NBH) % 0.5) != 0) {
                      <span id="dg-cul-is-imgrt-nbh" class="percentage-text">
                        {{(getPercentageImmigrant(demographicsAreaEnum.NBH)) | number : '1.0-0'}}%
                      </span>
                    }
                    @if ((getPercentageImmigrant(demographicsAreaEnum.NBH) % 0.5) == 0) {
                      <span id="dg-cul-is-imgrt-nbh" class="percentage-text">
                        {{(getPercentageImmigrant(demographicsAreaEnum.NBH) - 0.01) | number : '1.0-0'}}%
                      </span>
                    }
                    <br />Immigrant
                  </div>
                }
              </div>
            </div>
            <div class="flex flex-wrap content-center">
              @if (getPercentageNonImmigrant(demographicsAreaEnum.NBH)) {
                <div id="dg-cul-is-ni-label-nbh">
                  @if ((getPercentageNonImmigrant(demographicsAreaEnum.NBH) % 0.5) != 0) {
                    <span id="dg-cul-is-ni-nbh" class="percentage-text">
                      {{(getPercentageNonImmigrant(demographicsAreaEnum.NBH)) | number : '1.0-0'}}%
                    </span>
                  }
                  @if ((getPercentageNonImmigrant(demographicsAreaEnum.NBH) % 0.5) == 0) {
                    <span id="dg-cul-is-ni-nbh" class="percentage-text">
                      {{(getPercentageNonImmigrant(demographicsAreaEnum.NBH) + 0.01) | number : '1.0-0'}}%
                    </span>
                  }
                  <br />Non-Immigrant
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    }
    @if (selectedArea.includes(demographicsAreaEnum.COM)) {
      <div>
        <div class="flex flex-row flex-wrap gap-1">
          <div class="flex-1">
            <div class="" id="chart-immigration-status-com">
              <fusioncharts type="doughnut2d" [dataSource]="comDataSource" dataFormat="json" height="{{defaultHeight}}"
                width="{{defaultWidth}}" [dataEmptyMessage]="ds.comDataEmptyMessage" (initialized)="initialized($event)">
              </fusioncharts>
            </div>
          </div>
          <div class="flex-1 flex flex-col justify-center gap-1">
            <div class="flex flex-wrap content-center">
              <div>
                @if (getPercentageImmigrant(demographicsAreaEnum.COM)) {
                  <div id="dg-cul-is-imgrt-label-com">
                    @if ((getPercentageImmigrant(demographicsAreaEnum.COM) % 0.5) != 0) {
                      <span id="dg-cul-is-imgrt-com" class="percentage-text">
                        {{(getPercentageImmigrant(demographicsAreaEnum.COM)) | number : '1.0-0'}}%
                      </span>
                    }
                    @if ((getPercentageImmigrant(demographicsAreaEnum.COM) % 0.5) == 0) {
                      <span id="dg-cul-is-imgrt-com" class="percentage-text">
                        {{(getPercentageImmigrant(demographicsAreaEnum.COM) - 0.01) | number : '1.0-0'}}%
                      </span>
                    }
                    <br />Immigrant
                  </div>
                }
              </div>
            </div>
            <div class="flex flex-wrap content-center">
              @if (getPercentageNonImmigrant(demographicsAreaEnum.COM)) {
                <div id="dg-cul-is-ni-label-com">
                  @if ((getPercentageNonImmigrant(demographicsAreaEnum.COM) % 0.5) != 0) {
                    <span id="dg-cul-is-ni-com" class="percentage-text">
                      {{(getPercentageNonImmigrant(demographicsAreaEnum.COM)) | number : '1.0-0'}}%
                    </span>
                  }
                  @if ((getPercentageNonImmigrant(demographicsAreaEnum.COM) % 0.5) == 0) {
                    <span id="dg-cul-is-ni-com" class="percentage-text">
                      {{(getPercentageNonImmigrant(demographicsAreaEnum.COM) + 0.01) | number : '1.0-0'}}%
                    </span>
                  }
                  <br />Non-Immigrant
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    }
    @if (selectedArea.includes(demographicsAreaEnum.CITY)) {
      <div>
        <div class="flex flex-row flex-wrap gap-1">
          <div class="flex-1">
            <div class="" id="chart-immigration-status-city">
              <fusioncharts type="doughnut2d" [dataSource]="cityDataSource" dataFormat="json" height="{{defaultHeight}}"
                width="{{defaultWidth}}" [dataEmptyMessage]="ds.cityDataEmptyMessage" (initialized)="initialized($event)">
              </fusioncharts>
            </div>
          </div>
          <div class="flex-1 flex flex-col justify-center gap-1">
            <div class="flex flex-wrap content-center">
              <div>
                @if (getPercentageImmigrant(demographicsAreaEnum.CITY)) {
                  <div id="dg-cul-is-imgrt-label-city">
                    @if ((getPercentageImmigrant(demographicsAreaEnum.CITY) % 0.5) != 0) {
                      <span id="dg-cul-is-imgrt-city" class="percentage-text">
                        {{(getPercentageImmigrant(demographicsAreaEnum.CITY)) | number : '1.0-0'}}%
                      </span>
                    }
                    @if ((getPercentageImmigrant(demographicsAreaEnum.CITY) % 0.5) == 0) {
                      <span id="dg-cul-is-imgrt-city" class="percentage-text">
                        {{(getPercentageImmigrant(demographicsAreaEnum.CITY) - 0.01) | number : '1.0-0'}}%
                      </span>
                    }
                    <br />Immigrant
                  </div>
                }
              </div>
            </div>
            <div class="flex flex-wrap content-center">
              @if (getPercentageNonImmigrant(demographicsAreaEnum.CITY)) {
                <div id="dg-cul-is-ni-label-city">
                  @if ((getPercentageNonImmigrant(demographicsAreaEnum.CITY) % 0.5) != 0) {
                    <span id="dg-cul-is-ni-city" class="percentage-text">
                      {{(getPercentageNonImmigrant(demographicsAreaEnum.CITY)) | number : '1.0-0'}}%
                    </span>
                  }
                  @if ((getPercentageNonImmigrant(demographicsAreaEnum.CITY) % 0.5) == 0) {
                    <span id="dg-cul-is-ni-city" class="percentage-text">
                      {{(getPercentageNonImmigrant(demographicsAreaEnum.CITY) + 0.01) | number : '1.0-0'}}%
                    </span>
                  }
                  <br />Non-Immigrant
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    }
  </div>
}