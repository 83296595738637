import {Component, Input} from '@angular/core';
import {Assessment} from "../../../../../core/model/assessment/assessment";
import {UserAccessControl} from "../../../../../core/model/user/user-access-control";

@Component({
  selector: 'gema3g-site-structure-previous-assessment',
  templateUrl: './site-structure-previous-assessment.component.html',
  styleUrls: ['./site-structure-previous-assessment.component.scss']
})
export class SiteStructurePreviousAssessmentComponent {
  @Input() assessment: Assessment = new Assessment();
  @Input() userAccessControls: UserAccessControl;
}
