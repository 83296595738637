import {BehaviorSubject, Observable} from "rxjs";
import {ScreenNameEnum} from "../../core/enum/screen-name.enum";

export class ScreenManagerHelper{
  constructor() {
    this.screenManagers.set(ScreenNameEnum.OMNIBAR_SEARCH, new BehaviorSubject<boolean>(false));
    this.screenManagers.set(ScreenNameEnum.OMNIBAR_SEARCH_RESULTS, new BehaviorSubject<boolean>(false));
    //this.screenManagers.set(ScreenNameEnum.OMNIBAR_MAIN_SEARCH_RESULTS, new BehaviorSubject<boolean>(false));
    //this.screenManagers.set(ScreenNameEnum.OMNIBAR_OWNER_SEARCH_RESULTS, new BehaviorSubject<boolean>(false));
    this.screenManagers.set(ScreenNameEnum.OMNIBAR_CONDO_SEARCH_RESULTS, new BehaviorSubject<boolean>(false));
    this.screenManagers.set(ScreenNameEnum.PROPERTY_REPORT, new BehaviorSubject<boolean>(false));
    this.screenManagers.set(ScreenNameEnum.SEARCH_COMPARABLES_FORM, new BehaviorSubject<boolean>(false));
    this.screenManagers.set(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS, new BehaviorSubject<boolean>(false));
    this.screenManagers.set(ScreenNameEnum.MAIN_MAP_STREETVIEW, new BehaviorSubject<boolean>(false));
    this.screenManagers.set(ScreenNameEnum.HOODQ, new BehaviorSubject<boolean>(false));
    this.screenManagers.set(ScreenNameEnum.MAP_LAYER_POPUP, new BehaviorSubject<boolean>(false));
    this.screenManagers.set(ScreenNameEnum.MAP_TYPE_POPUP, new BehaviorSubject<boolean>(false));
    this.screenManagers.set(ScreenNameEnum.COMPARABLES_REPORT, new BehaviorSubject<boolean>(false));

  }
  screenManagers: Map<ScreenNameEnum, BehaviorSubject<boolean>> = new Map<ScreenNameEnum, BehaviorSubject<boolean>>();
}
