import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {DemographicsPopulation} from "../../../../../../core/model/demographics/demographics-population";
import {DemographicsChartsUtility} from "../../../../../../shared/utility/fusion-charts/demographics-charts.utility";
import { DataService } from 'src/app/shared/service/data.service';
import {BaseChartComponent} from "../../../../../../core/component/base-chart/base-chart.component";
import {FusionChartsModule} from "angular-fusioncharts";

@Component({
  selector: 'gema3g-age-distribution',
  standalone: true,
  templateUrl: './age-distribution.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FusionChartsModule
  ],
  styleUrls: ['./age-distribution.component.scss']
})
export class AgeDistributionComponent extends BaseChartComponent{
  @Input() demographicsPopulation = new DemographicsPopulation();


  override setHeightWidth() {
    //this.defaultWidth = "546";
    this.defaultHeight = "190"
    this.minWidth = 230;
    this.maxWidth = 546;
    this.minHeight = 190;
    this.maxHeight = 190;
  }

  override loadChartData() {
    if(!this.pinOrArn || this.pinOrArn != this.demographicsPopulation?.pinOrArn) {
      this.nbhDataSource = DemographicsChartsUtility.ageDistributionDataSource(DataService.DEFAULT_CHART_FONT, 'NBH', this.demographicsPopulation?.PRCDDA, window.innerWidth);
      this.comDataSource = DemographicsChartsUtility.ageDistributionDataSource(DataService.DEFAULT_CHART_FONT, 'COM', this.demographicsPopulation?.FSA, window.innerWidth);
      this.cityDataSource = DemographicsChartsUtility.ageDistributionDataSource(DataService.DEFAULT_CHART_FONT, 'CITY', this.demographicsPopulation?.PRCDCSD, window.innerWidth);
      this.pinOrArn = this.demographicsPopulation?.pinOrArn;
    }
  }

}
