import { SiteDetailInfoItem } from "./site-detail-info-item";

export class SiteDetailInfo {

  constructor(siteDetailInfo? : SiteDetailInfo) {
    if (siteDetailInfo){
      if(siteDetailInfo.field1){
        this.field1 = new SiteDetailInfoItem(siteDetailInfo.field1);
      }
      if(siteDetailInfo.field2){
        this.field2 = new SiteDetailInfoItem(siteDetailInfo.field2);
      }
    }
  }

  field1: SiteDetailInfoItem;
  field2: SiteDetailInfoItem;
}
