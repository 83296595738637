<table id="na-tbl" class="assistance-table">
  <tr id="na-tbl-row-top">
    <td class="assistance-table-top">
      <img id="na-tbl-img-questionmark" alt="question" src="assets/img/mygeo-icons/icon_questionmark.png"/>
      <br/><br/>
      <h3 id="na-tbl-h3">Need Assistance?</h3>
    </td>
  </tr>
  <tr id="na-tbl-row-bottom">
    <td class="assistance-table-bottom">
      <p id="na-tbl-data-call">Call us at <br/>{{DataService.SUPPORT_PHONE}}<br/><br/> or toll-free at <br/>{{DataService.SUPPORT_TOLL_FREE}}.<br/><br/></p>
      <p id="na-tbl-data-hours" class="nowrap">Monday - Friday:<br/> 9am - 6pm</p>
      <br/>
      <p id="na-tbl-data-help">Or visit our<br/>
        <a id="na-tbl-link-help" (click)="redirectToZendesk()" class="anchor" target="_blank">Help Centre.</a>
      </p>
    </td>
  </tr>
</table>
<form id="jwtForm" name="jwtForm" #zendeskForm="ngForm" [action]="zendeskAction" target="_blank" method="post">
  <input type="hidden" id="jwtInput" name="jwt">
  <input type="hidden" id="returnInput" name="return_to">
  <button #submitButton id="submitButton" type="submit" style="display: none;">Submit</button>
</form>
