@if (searchResult?.document || (searchResult?.searchResult?.length)) {
  <ng-container id="isr-container-search-results">
  @if (searchResult?.document?.name) {
    <div class="instrument-name-section">
      <div class="instrument-image">
        <img id="isr-img-propertytype-plans-white" alt="single instrument header" src="assets/img/svg/icons/property/icon_propertytype_plans_white.svg">
      </div>
      <h3 id="isr-h3-instrument-name" class="instrument-name"><span>{{ searchResult?.document?.name | na }}</span></h3>
      <div class="instrument-view-details">
        @if (userAccessControls?.instrumentImage) {
          <a id="isr-link-open-details" (click)="openDetails()">View Details</a>
        }
      </div>
    </div>
    <div class="instrument-name-section2">
      <div class="instrument-empty"></div>
      <div id="isr-registration-date" class="instrument-label">
        Registration Date:
      </div>
      <div id="isr-registration-instrument-description" class="instrument-description">
        {{ searchResult?.document?.registrationDate | longDateOrNa }}
      </div>
    </div>
    <div class="instrument-name-section2">
      <div class="instrument-empty"></div>
      <div id="isr-document-type" class="instrument-label">
        Document Type:
      </div>
      <div id="isr-document-instrument-description" class="instrument-description">
        {{ searchResult?.document?.documentSubTypeDesc | na }}
      </div>
    </div>
  }
  @if (searchResult?.searchResult && searchResult.searchResult.length) {
    <gema3g-sort-search-results
      (onSortingSelected)="applySorting($event)"
      [sortingOptions]="sortingOptions"
      [defaultSortingOption]="defaultSortingOption"
      class="sr-sorting-container"
    >
    </gema3g-sort-search-results>
    <div (scroll)="onScroll($event)" class="sr-results-child">
      <gema3g-pii-search-results
        [searchResult]="searchResult.searchResult"
        (onPropertyClick)="goToProperty($event)"
      >
      </gema3g-pii-search-results>
    </div>
  }
  </ng-container>
}
