import {AccountMenuSubItem} from "./account-menu-subitem";
import {BaseModel} from "../../../core/model/base-model";

export class AccountMenuItem extends BaseModel{


  constructor(ami?: AccountMenuItem) {
    super(ami);
    if(ami){
      if(ami?.children?.length){
        this.children = ami.children.map(value => { return new AccountMenuSubItem(value); });
      }
    }
  }

  id: string;
  title: string;
	img: string;
  imgGrey: string;
	redirectTo: string;
	children: AccountMenuSubItem[] = [];
  collapsed: boolean;
}
