import {AfterViewInit, Component, inject, OnInit} from '@angular/core';
import {AuthService} from "@auth0/auth0-angular";
import {BaseUnsubscribe} from "../../core/component/base-unsubscribe/base-unsubscribe";
import {LoggerService} from "../../shared/service/log/logger.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationService} from "../../shared/service/authentication.service";
import {takeUntil} from "rxjs";

@Component({
  selector: 'gema3g-auth-callback',
  standalone: true,
  imports: [],
  templateUrl: './auth-callback.component.html',
  styleUrl: './auth-callback.component.scss'
})
export class AuthCallbackComponent {


  route = inject(ActivatedRoute);
  router = inject(Router);
  authService = inject(AuthenticationService);
  auth0Service = inject(AuthService);
  loggerService = inject(LoggerService);

  error: string = '';

  ngOnInit(): void {

    this.loggerService.logInfo('I am in callback');

    // this.route.queryParams
    //   .pipe(takeUntil(this.ngUnsubscribe))
    //   .subscribe((params) => {
    //       const code = params['code'];
    //       const state = params['state'];
    //       this.loggerService.logDebug('code=' + code);
    //       this.loggerService.logDebug('state=' + state);
    //     }
    //   );
  }
  //
  // private async continueLoggingIn() {
  //   this.loggerService.logInfo('I am in callback continue');
  //   this.auth0Service.isAuthenticated$.subscribe(
  //     (isAuthenticated) => {
  //       if (isAuthenticated) {
  //         this.error = 'Done';
  //         this.loggerService.logInfo('I am authenticated');
  //       } else {
  //         this.loggerService.logInfo('I am not authenticated');
  //         this.error = 'There was a problem trying to log you in. Please try again later.'
  //       }
  //     },
  //     error1 => {
  //       this.loggerService.logError('there was an error', error1);
  //     }
  //   );
  //   this.loggerService.logInfo('I am in callback continue end');
  //
  //   // try {
  //   //   const isAuthenticated = await lastValueFrom(this.auth0Service.isAuthenticated$);
  //   //   if (isAuthenticated) {
  //   //     const token = await lastValueFrom(this.auth0Service.getAccessTokenSilently());
  //   //     if (token) {
  //   //       await this.authService.continueLoggingIn(false);
  //   //       return;
  //   //     }
  //   //   }
  //   //   localStorage.clear();
  //   //   this.auth0Service.loginWithRedirect();
  //   // } catch (err) {
  //   //   this.loggerService.logError(err);
  //   //   this.error = 'error';
  //   // }
  //
  //   // this.auth0Service.getAccessTokenSilently()
  //   //   .pipe(takeUntil(this.ngUnsubscribe))
  //   //   .subscribe(
  //   //     (token) => {
  //   //       if (token) {
  //   //         localStorage.setItem(LocalStorageKey.auth0Token, token);
  //   //         // ToDo complete login
  //   //         this.router.navigate(['/home']);
  //   //       } else {
  //   //         this.loggerService.logError('Cannot retrieve Auth0 token');
  //   //         this.router.navigate(['/login']);
  //   //       }
  //   //     },
  //   //     err => {
  //   //       this.loggerService.logError(err);
  //   //     }
  //   //   )
  // }
  //
  // ngAfterViewInit(): void {
  //   // setTimeout(() => {
  //   //   this.continueLoggingIn();
  //   // }, 1000);

  //}
}
