<gema3g-property-report-layout [propertyReportSection]="propertyReportSection">
  <section class="pr-shutter-middle-body-full sm:pr-shutter-middle-body sm:ml-20 sm:mr-20">

    <div class="flex flex-col">

      <!-- <ng-container *ngIf="planSurveyDataExists">
      <h1 *ngIf="!userAccessControls.easementIndicatorAccess">Plans & Surveys</h1>
      <h1 *ngIf="userAccessControls.easementIndicatorAccess">Plans, Surveys & Easements</h1>
    </ng-container> -->

    @if (!planSurveyDataExists()) {
      <div id="ps-no-data" class="pr-no-data-gray-background">
        Plans and Surveys Not Available
      </div>
    }

    @if (planSurveyDataExists()) {
      <div class="flex flex-col xl:flex-row gap-4 mb-8">
        <div class="plan-survey-map-wrapper">
          <gema3g-map-plans-survey
            [selectedPlanNumber]="selectedPlanNumber"
            [selectPlanLabel]="selectPlanLabel"
            [subjectProperty]="subjectProperty"
            >
          </gema3g-map-plans-survey>
        </div>

        <div class="w-[100%] md:max-h-[300px]">
          <div class="flex flex-col h-full gap-1">
            <div class="flex flex-row justify-between bg-gray-100 p-2">
              <label id="ps-label-plans-surveys" class="text-os-bold">Plans & Surveys</label>
              <div class="flex flex-row gap-4">
                <div><span id="ps-legend-R" class="legend-box">R</span><span id="ps-registered" class="ml-2">Registered</span></div>
                <div><span id="ps-legend-P" class="legend-box">P</span><span id="ps-private" class="ml-2">Private</span></div>
              </div>
            </div>
            <div class="flex-grow dense-2 overflow-auto">
              <table id="ps-tbl" [dataSource]="planSurveyDataSource" class="h-[100%]" class="mat-elevation-z2 standard-white-table" mat-table matSort>

                <ng-container matColumnDef="registrationYear">
                  <th id="ps-tbl-hdr-year" *matHeaderCellDef mat-header-cell mat-sort-header>Year</th>
                  <td id="ps-tbl-data-year-{{element.documentNumber}}" *matCellDef="let element" mat-cell>
                    @if (element.registrationYear != -1) {
                      {{element.registrationYear}}
                    }
                    @if (element.registrationYear === -1) {
                      Year Not Available
                    }
                  </td>
                </ng-container>

                <ng-container matColumnDef="planCategory">
                  <th id="ps-tbl-hdr-cat" *matHeaderCellDef mat-header-cell mat-sort-header></th>
                  <td *matCellDef="let element" mat-cell>
                    @if (element.planCategory === 'R') {
                      <span id="ps-tbl-data-cat-{{element.documentNumber}}" class="legend-box">R</span>
                    }
                    @if (element.planCategory === 'P') {
                      <span id="ps-tbl-data-cat-{{element.documentNumber}}" class="legend-box">P</span>
                    }
                  </td>
                </ng-container>

                <ng-container matColumnDef="documentNumber">
                  <th id="ps-tbl-hdr-doc-num" *matHeaderCellDef mat-header-cell mat-sort-header>Document Number</th>
                  <td *matCellDef="let element" mat-cell>
                    @if (element.planCategory === 'R') {
                      <div class="flex flex-col">
                        <div id="ps-tbl-data-doc-num-{{element.documentNumber}}" class="font-medium">{{element.documentNumber}}</div>
                        <div id="ps-tbl-data-plan-desc-{{element.documentNumber}}">{{element.planDesc}}</div>
                      </div>
                    }
                    @if (element.planCategory === 'P') {
                      <div class="flex flex-col">
                        <div id="ps-tbl-data-survey-type-{{element.documentNumber}}" class="font-medium">{{element.surveyTypeDesc}}</div>
                        <div id="ps-tbl-data-plan-desc-{{element.documentNumber}}">{{element.planDesc}}</div>
                      </div>
                    }
                    @if (element.companySurveyId !=null && element.companyBEId === 42) {
                      <br>
                        <a id="ps-tbl-link-preview-{{element.documentNumber}}" (click)="displayLSRSurveySample(element.companySurveyId)" class="anchor">View Preview</a>
                      }
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="price">
                    <th id="ps-tbl-hdr-price" *matHeaderCellDef mat-header-cell mat-sort-header></th>
                    <td id="ps-tbl-data-price-{{element.documentNumber}}" *matCellDef="let element" mat-cell>
                      @if (element.price) {
                        {{element.price | currency : 'USD' : 'symbol' : '1.0-0'}}*
                      }
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="buyOrView">
                    <th id="ps-tbl-hdr-view-or-buy" *matHeaderCellDef mat-header-cell mat-sort-header></th>
                    <td *matCellDef="let element" mat-cell>
                      <a id="ps-tbl-link-view-or-buy-{{element.documentNumber}}" (click)="viewOrBuyReport(element)" class="anchor">
                        {{getBuyOrViewLabel(element)}}
                      </a>
                    </td>
                  </ng-container>

                  <!-- <tr mat-header-row *matHeaderRowDef="planSurveyDisplayedColumns"></tr> -->
                  <tr id="ps-tbl-row-{{row.documentNumber}}" *matRowDef="let row; columns: planSurveyDisplayedColumns;" mat-row
                    (click)="!selection.isSelected(row) && mapPlanSurvey(row)"
                    [ngClass]="{'mat-table-highlighted-row': selection.isSelected(row) }"
                    >
                  </tr>
                </table>
              </div>
              <div class="flex flex-row justify-end border-t border-slate-300 border-solid pr-1">
                <label id="ps-label-taxes" class="italic font-medium">* Plus applicable taxes</label>
              </div>
            </div>
          </div>

        </div>
      }

      @if (userAccessControls.easementIndicatorAccess) {
        <ng-container>
          <h3 id="ps-h3-easements" class="mb-8">Easements</h3>
          <div class="pr-body-text">
            @if (!isEasementApiUnavailable) {
              <div id="ps-easements-count">The Protect Your Boundaries<sup>TM</sup> Easement Indicator detects that there may be <span class="text-os-bold">{{easementCount}} {{easementCount == 1 ? "easement" : "easements"}}</span> on this property.</div>
            }
            <br>
              <div id="ps-easements-msg">Purchase a PYB Easement Report to verify whether or not this property has easements, obtain the official documents that describe the easement, and see a plain English description of any easements found. Report
                includes: Parcel Register and official Legal Instruments.
              </div>
              <br>
                @if (isEasementError) {
                  <div id="ps-easements-err">The Easement indicator is not available for this property.</div>
                }
                @if (isEasementApiUnavailable) {
                  <div id="ps-easements-api-na">The Easement Indicator service is temporarily unavailable. Please try again later.</div>
                }
              </div>
            </ng-container>
          }

          <h3 id="ps-h3-ph" class="pt-2 mb-8">Property History</h3>

          @if (!parentPinEventExists && !childPinEventExists) {
            <div id="ps-ph-no-data-msg" class="pr-body-text">There have been no splits, consolidations or re-entries on this Subject Property since automation in the Electronic Land Registration System.</div>
          }

          <div class="flex flex-col lg:flex-row gap-4 w-[100%]">
            @if (parentPinEventExists) {
              <div class="flex-1 flex flex-col gap-4">
                <div>
                  <div class="flex flex-row justify-start items-center gap-1">
                    <div id="ps-ph-parents-title" class="pr-body-text">
                      Parent Properties for the Subject Property {{subjectProperty.pii.pin}}
                    </div>
                    <div [satPopoverAnchor]="parentPropertyPopover" (mouseover)="parentPropertyPopover.open()" (mouseout)="parentPropertyPopover.close()">
                      <fa-icon
                        id="ps-icon-ph-parents-tooltip"
                        class="fa-lg text-[#428BCA]"
                        [icon]="faCircleInfo">
                      </fa-icon>
                      <sat-popover #parentPropertyPopover [autoFocus]="false" [restoreFocus]="false" verticalAlign="above" openTransition="500ms ease-out" closeTransition="100ms ease-in">
                        <gema3g-tooltip [content]="parentPinToolTip"></gema3g-tooltip>
                      </sat-popover>
                    </div>
                  </div>
                </div>
                <div class="flex flex-row w-full dense-2 table-container">
                  <table id="ps-tbl-ph-parents" [dataSource]="parentPropertyHistoryDataSource" class="h-[100%]" class="mat-elevation-z2 standard-table" mat-table>
                    <ng-container matColumnDef="creationYear">
                      <th id="ps-tbl-hdr-ph-parents-year" *matHeaderCellDef mat-header-cell>Year</th>
                      <td id="ps-tbl-data-ph-parents-year-{{element.pin}}" *matCellDef="let element; index as i;" mat-cell>
                        {{i == 0 ? parentPinCreationYear : ""}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="eventType">
                      <th id="ps-tbl-hdr-ph-parents-et" *matHeaderCellDef mat-header-cell>Event Type</th>
                      <td id="ps-tbl-data-ph-parents-et-{{element.pin}}" *matCellDef="let element; index as i;" mat-cell>
                        {{i == 0 ? element.eventType : ""}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="pin">
                      <th id="ps-tbl-hdr-ph-parents-pin" *matHeaderCellDef mat-header-cell>Parent Properties</th>
                      <td *matCellDef="let element" mat-cell>
                        <a id="ps-tbl-link-ph-parents-pin-{{element.pin}}" (click)="openPropertyReport(element.pin)" class="anchor mr-2">{{element.pin}}</a>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                      <th id="ps-tbl-hdr-ph-parents-status" *matHeaderCellDef mat-header-cell>Status</th>
                      <td id="ps-tbl-data-ph-parents-status-{{element.pin}}" *matCellDef="let element" mat-cell>
                        {{element.status}}
                      </td>
                    </ng-container>
                    <tr id="ps-tbl-row-ph-parents-hdr" *matHeaderRowDef="parentPropertyHistoryDisplayedColumns" mat-header-row></tr>
                    <tr id="ps-tbl-row-ph-parents-{{row.pin}}" *matRowDef="let row; columns: parentPropertyHistoryDisplayedColumns; let i = dataIndex;" mat-row></tr>
                  </table>
                </div>
                @if(parentPropertyHistoryListItems && parentPropertyHistoryListItems.length > 3){
                  <div>
                    <mat-paginator
                      (page)="handlePageEvent($event)"
                      [pageSize]="3"
                      [showFirstLastButtons]="false"
                      [pageSizeOptions]="[]"
                      [hidePageSize]="true"
                      [pageIndex]="parentPropertyHistoryPageIndex"
                      aria-label="Select page">
                    </mat-paginator>
                  </div>
                }
              </div>
            }

            @if (childPinEventExists) {
              <div class="flex-1 flex flex-col gap-4">
                <div>
                  <div class="flex flex-row justify-start items-center gap-1">
                    <div id="ps-ph-children-title" class="pr-body-text">
                      Child Properties for the Subject Property {{subjectProperty.pii.pin}}
                    </div>
                    <div [satPopoverAnchor]="childPropertyPopover" (mouseover)="childPropertyPopover.open()" (mouseout)="childPropertyPopover.close()">
                      <fa-icon
                        id="ps-icon-ph-children-tooltip"
                        class="fa-lg text-[#428BCA]"
                        [icon]="faCircleInfo">
                      </fa-icon>
                      <sat-popover #childPropertyPopover [autoFocus]="false" [restoreFocus]="false" verticalAlign="above" openTransition="500ms ease-out" closeTransition="100ms ease-in">
                        <gema3g-tooltip [content]="childPinToolTip"></gema3g-tooltip>
                      </sat-popover>
                    </div>
                  </div>
                </div>
                <div class="flex flex-row w-full dense-2 table-container">
                  <table id="ps-tbl-ph-children" [dataSource]="childPropertyHistoryDataSource" class="h-[100%]" class="mat-elevation-z2 standard-table" mat-table>
                    <ng-container matColumnDef="creationYear">
                      <th id="ps-tbl-hdr-ph-children-year" *matHeaderCellDef mat-header-cell>Year</th>
                      <td id="ps-tbl-data-ph-children-year-{{element.pin}}" *matCellDef="let element; index as i;" mat-cell>
                        {{element.creationYear}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="eventType">
                      <th id="ps-tbl-hdr-ph-children-et" *matHeaderCellDef mat-header-cell>Event Type</th>
                      <td id="ps-tbl-data-ph-children-et-{{element.pin}}" *matCellDef="let element; index as i;" mat-cell>
                        {{i == 0 ? element.eventType : ""}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="pin">
                      <th id="ps-tbl-hdr-ph-children-pin" *matHeaderCellDef mat-header-cell>Child Properties</th>
                      <td *matCellDef="let element" mat-cell>
                        <a id="ps-tbl-link-ph-children-pin-{{element.pin}}" (click)="openPropertyReport(element.pin)" class="anchor mr-2">{{element.pin}}</a>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                      <th id="ps-tbl-hdr-ph-children-status" *matHeaderCellDef mat-header-cell>Status</th>
                      <td id="ps-tbl-data-ph-children-status-{{element.pin}}" *matCellDef="let element" mat-cell>
                        {{element.status}}
                      </td>
                    </ng-container>
                    <tr id="ps-tbl-row-ph-children-hdr" *matHeaderRowDef="childPropertyHistoryDisplayedColumns" mat-header-row></tr>
                    <tr id="ps-tbl-row-ph-children-{{row.pin}}" *matRowDef="let row; columns: childPropertyHistoryDisplayedColumns; let i = dataIndex;" mat-row></tr>
                  </table>
                </div>
                @if (childPropertyHistoryListItems.length > 3) {
                  <div #moreOrLessPropertyHistory class="flex w-1/2 justify-content-center">
                    <a id="ps-tbl-link-ph-children-m-or-l" (click)="changeChildPropertyHistoryListViewSize()" class="anchor">{{childPropertyHistoryListViewAction}}</a>
                  </div>
                }
              </div>
            }
          </div>

          <div class="py-4">
            <mat-divider></mat-divider>
          </div>

          <h2 id="ps-h2-reports" class="mt-7 no-print">{{productsForPurchaseLabel}}</h2>

          @if (carouselProducts && carouselProducts.length == 0) {
            <div class="carousel-products-loading">
              <div id="ps-no-reports-label">{{noProductsForPurchaseLabel}}</div>
            </div>
          }

          @if (carouselProductsLoading) {
            <div class="carousel-products-loading">
              <img id="ps-img-refresh" src="assets/img/icon-refresh.gif">
            </div>
          }

          @if (!carouselProductsLoading && carouselProducts && carouselProducts.length > 0) {
            <div id="plans-survey"  class="carousel-container no-print">
              <p-carousel id="ps-reports-carousel" [circular]="false"
                [numScroll]="1"
                [numVisible]="3"
                [responsiveOptions]="responsiveOptions"
                [showIndicators]="false"
                [showNavigators]="showNavigators"
                [value]="carouselProducts">
                <ng-template let-product pTemplate="item">
                  <div class="carousel-card">
                    <gema3g-single-catalogue-product
                      class="carousel-card__block"
                      [product]="product"
                      [selectedProperty]="selectedProperty">
                    </gema3g-single-catalogue-product>
                  </div>
                </ng-template>
              </p-carousel>
            </div>
          }

        </div>

      </section>
    </gema3g-property-report-layout>
