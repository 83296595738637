export class ChangePasswordRequest {

  newpw: string;
  oldpw: string;
  confpw: string;
  oktauserid: string | null;

  get hasUpperCase(): boolean {
    return !!this.newpw && /[A-Z]+/.test(this.newpw);
  }

  get hasLowerCase(): boolean {
    return !!this.newpw && /[a-z]+/.test(this.newpw);
  }

  get hasNumber(): boolean {
    return !!this.newpw && /[0-9]+/.test(this.newpw);
  }

  get inRange(): boolean {
    return ((!!this.newpw) && this.newpw.length >= 8 && this.newpw.length <= 16);
  }

  get hasSpecialChar(): boolean {
    return !!this.newpw && /[@%\+\/'!#\$\^\?:,\(\)\{\}\[\]~`\-_\.]+/.test(this.newpw);
  }

  get hasInvalidChars(): boolean {
    return !this.newpw || /[^A-Za-z0-9@%\+\/'!#\$\^\?:,\(\)\{\}\[\]~`\-_\.]+/.test(this.newpw);
  }

  get passwordsMatch(): boolean {
    return !!this.newpw && !!this.confpw && this.newpw == this.confpw;
  }

  get samePasswordUsed(): boolean {
    return (!!this.oldpw && !!this.confpw  && this.newpw == this.oldpw);
  }

  get errorMessage(): string {
    if(this.samePasswordUsed){
      return "Your new password is the same as your current password. Please choose a different password.";
    }
    return '';
  }

  get canContinue(): boolean{
    return (!!this.oldpw && this.hasUpperCase && this.hasLowerCase && !this.hasInvalidChars && this.hasNumber && this.hasSpecialChar && this.passwordsMatch && !this.samePasswordUsed);
  }

  get edited():boolean{
    return !!this.newpw || !!this.confpw || !!this.oldpw;
  }
}
