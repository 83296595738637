import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { EstoreService } from '../../../shared/service/estore.service';
import { MainMapState } from '../../../core/model/spatial/main-map-state';
import { LoggerService } from '../../../shared/service/log/logger.service';
import { EstoreProductCategoryEnum } from '../../../core/enum/estore-product-category-enum';
import { ScreenManager } from '../../../shared/service/screen-manager.service';
import { CoreModule } from "../../../core/core.module";

@Component({
  selector: 'gema3g-hoodq-map',
  standalone: true,
  templateUrl: './hoodq-map.component.html',
  imports: [
    CoreModule
  ],
  styleUrls: ['./hoodq-map.component.scss']
})
export class HoodqMapComponent implements AfterViewInit, OnDestroy, OnChanges {

  @ViewChild('hoodqIframe', { static: false }) hoodqIframe: ElementRef;
  @Output() hoodQMapClosed = new EventEmitter<string>();
  @Input() mainMapStatus: MainMapState;
  private unlistener: () => void;
  hoodqUrl: string = 'assets/html/schoolq.html';

  constructor(private renderer2: Renderer2,
              private estoreService: EstoreService,
              private loggerService: LoggerService) {
  }

  onHoodQMapClosed = () => {
    this.hoodQMapClosed.emit('*');
  }

  ngAfterViewInit(): void {
    //listen for messages originating from the iframe
    this.unlistener = this.renderer2.listen("window", "message", event => {
      if (event.data == 'hoodq.ad.clicked') {
        this.loggerService.logDebug('forward hoodq product request to estore');
        this.estoreService.goToCatalogueCategory(EstoreProductCategoryEnum.HOODQ);

      } else if (event.data == 'hoodq.iframe.loaded') {
        this.loggerService.logDebug('hoodq iframe has finished loading');

        //center the hoodq map where the main map is centered
        this.setMapCenter(this.mainMapStatus);

      } else if (event.data == 'hoodq.close') {
        this.loggerService.logDebug('hoodq screen closed');
        this.onHoodQMapClosed();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['mainMapStatus'] && this.hoodqIframe) {
      this.setMapCenter(this.mainMapStatus);
    }
  }

  private setMapCenter(mMS: MainMapState): void {
    let data = { latitude: mMS?.centroid.latitude, longitude: mMS?.centroid.longitude, zoomLevel: mMS?.zoomLevel };
    this.hoodqIframe.nativeElement.contentWindow.postMessage(data);
  }

  ngOnDestroy() {
    this.unlistener();
  }
}
