import {SpatialPoint} from "./spatial-point";

export class Centroid extends SpatialPoint{
  constructor(latitude?: number, longitude?: number) {
    super();
    if(latitude) {
      this.latitude = latitude;
    }
    if(longitude) {
      this.longitude = longitude;
    }
  }
}
