import { BaseModel } from "../base-model";

export class RataFilterCriteriaInstrumentType extends BaseModel {

    constructor(rfcInstrumentType: RataFilterCriteriaInstrumentType) {
        super(rfcInstrumentType);
    }

    public instrumentSubTypeCode: string;
    public instrumentDescription: string;
}