<section class="comparables-report-container">
  <div class="first-section">
    <gema3g-header></gema3g-header>
    <gema3g-omnibar-search></gema3g-omnibar-search>
    <gema3g-comparables-main></gema3g-comparables-main>
  </div>

  <gema3g-footer></gema3g-footer>
</section>

