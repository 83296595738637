import {ErrorHandler, inject, Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { baseUrl } from "../system";
import { catchError, lastValueFrom, map, Observable, of } from "rxjs";
import { RemoteError } from "../../../core/model/error/remote-error";
import { StackFrame } from "../../../core/model/error/stack-frame";
import { AuthenticationService } from "../authentication.service";
import { LoggerService } from '../log/logger.service';

@Injectable({
  providedIn: 'root'
})
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  private https = inject(HttpClient);
  private loggerService = inject(LoggerService);
  private authService = inject(AuthenticationService);

  private lastReportedError: Error;
  private lastReportedErrorCount: number = 0;

  // ToDo extend this later
  async handleError(error: Error) {
    const functionName = this.extractFunctionName(error);
    if(this.lastReportedError && JSON.stringify(this.lastReportedError) == JSON.stringify(error)){
      this.lastReportedErrorCount ++;
      this.loggerService.logError(`Error ${error.message} from ${functionName} repeated ${this.lastReportedErrorCount} times.`);
      return;
    } else {
      this.lastReportedError = error;
      this.lastReportedErrorCount = 0;
      this.loggerService.logError("Global Error Handler", error, error.stack);
    }

    const remoteError = new RemoteError();
    remoteError.message = error.message;
    const stackFrame: StackFrame = new StackFrame();
    // ToDo maybe add a new endpoint? or somehow extract from current error.stack (a string) to old StackFrame ?!?!
    stackFrame.functionName = functionName;
    stackFrame.fileName = <string>error.stack;
    remoteError.stack = [stackFrame];

    const sent = await lastValueFrom(this.sendError(remoteError));
    if (!sent){
      this.loggerService.logError("Could not send error to backend.");
    }

  }

  extractFunctionName(error: Error): string{
    if(error?.stack) {
      const match = error.stack.match(/at\s+(.*?)\s+\(/);
      return match ? match[1] : '';
    }
    return '';
  }

  sendError(remoteError: RemoteError){
    this.loggerService.logInfo("Sending error " + remoteError);
    const url = baseUrl + '/remoteLogger/logWithStack';
    if(this.authService.isLoggedIn) {
      return this.https.post(url, remoteError)
        .pipe(
          map(resp => {
            return true;
          }));
    }
    return of(false);
  }
}


export const errorHandlerProviders = [
  {provide: ErrorHandler, useClass: GlobalErrorHandler, multi: false}
];
