import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {WarningDialogData} from "./warning-dialog-data";
import {DialogReturnTypeEnum} from "../../../enum/dialog-return-type-enum";

@Component({
  selector: 'gema3g-warning-dialog',
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['./warning-dialog.component.scss']
})
export class WarningDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<WarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WarningDialogData,
  ) {
  }

  onFirstButtonClick() {
    this.dialogRef.close(DialogReturnTypeEnum.FIRST_BUTTON);
  }

  onSecondButtonClick() {
    this.dialogRef.close(DialogReturnTypeEnum.SECOND_BUTTON);
  }

}
