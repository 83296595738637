<div class="l-container">

  <div class="header">
    <div class="flex flex-row justify-between justify-center items-center">
      <div class="flex">
        <img id="legal-gwh-logo" alt="" class="geo-header-logo" src="assets/img/geowarehouse-logo1.png">
      </div>
      <div class="flex pt-3 pr-3">
        <a id="legal-link-logout" (click)="logout()">Logout</a>
      </div>
    </div>
  </div>

  <div class="body">
    <div class="container-fluid m-8">
      <div class="flex flex-row">
        <div class="">
          <p id="legal-subline" class="legal-subline">GeoWarehouse&#174; Online Service</p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="w-full">
          <p id="legal-page-header" class="legal-page-header">LEGAL TERMS AND CONDITIONS</p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="">
          <p id="legal-row-intro.1" class="legal-row">
            Welcome to the GeoWarehouse&#174; Online Service (the "Site").
            <br/><br/>
            PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS SITE.<br/><br/>
            Wherever used herein, "Teranet" means Teranet Inc. (referred to as "Teranet," "we," "us,"
            or "our" herein) and the "Teranet Group" means Teranet and Teranet's related or affiliated entities, suppliers and licensors, and their respective directors, officers, employees, consultants, representatives and agents.
            "Agreement" means a combination of these Legal Terms and Conditions and the Subscription Application approved by Teranet. The GeoWarehouse Online Service Application Form is available on the Site. "You" and "your" means the
            subscriber identified on the GeoWarehouse Online Subscription Application form (the "Subscription Application"). "User" means a person, at least eighteen (18) years of age designated by you on the Subscription Application,
            which user may access, browse and use the Site on your behalf using a user ID and password provided by Teranet. These Legal Terms and Conditions shall take priority over all attachments to the Agreement, including the
            Subscription Application, except as otherwise expressly set out in the Legal Terms and Conditions.
          </p>
          <p id="legal-row-intro.2" class="legal-row">
            By accessing, browsing and using this Site and its content, you and your Users are signifying consent to be bound by the Agreement. If you or your users do not agree to the Agreement, including the Legal Terms and Conditions,
            please exit this Site.
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="">
          <p id="legal-row-1" class="legal-row">
            1. RESTRICTIONS ON ACCESS
          </p>
          <p id="legal-row-1.1" class="legal-row">
            USERS THAT ARE ACCESSING THIS SITE UNDER A SUBLICENSE AGREEMENT WITH A REAL ESTATE BOARD MUST BE A REAL ESTATE BROKER OR SALES PERSON REGISTERED WITH THE REAL ESTATE COUNCIL OF ONTARIO OR A PERMANENT EMPLOYEE OF A REAL ESTATE
            BOARD.
          </p>
          <p id="legal-row-1.2" class="legal-row">
            User groups whose primary commercial activity consists of any of the following: financial institutions, government agencies, credit bureaus or reporting agencies, collection agencies, lawyers, law firms, paralegal service
            firms, conveyancers, mortgage brokers, investment analysts, marketing companies, mapping or geospatial services companies, Multiple Listing Service (&#8220;MLS&#174;&#8221;) system vendors, software developers, re-marketers,
            re-sellers, assemblers, wholesalers or distributors of information or data products, media companies, appraisers, surveyors, retailers or honorary members of the Board may not access this Site under a Real Estate Board
            Sublicense Agreement.
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="">
          <p id="legal-row-2" class="legal-row">
            2. RESTRICTIONS ON USE OF MATERIALS
          </p>
          <p id="legal-row-2.1" class="legal-row">
            THE INFORMATION CONTAINED WITHIN THIS SITE IS TO BE USED FOR INTERNAL BUSINESS PURPOSES ONLY. UNAUTHORIZED COLLECTION OR REDISTRIBUTION OF SITE DATA OR IMAGERY MAY RESULT IN TERMINATION OF THE AGREEMENT.
          </p>
          <p id="legal-row-2.2" class="legal-row">
            REAL ESTATE BOARD SUBLICENSEES MAY ONLY USE THIS SITE FOR REASONS THAT ARE DIRECTLY RELATED TO THE BUSINESS OF TRADING IN REAL ESTATE IN ONTARIO, NAMELY, THE DISPOSITION OR ACQUISITION OF OR TRANSACTION IN REAL ESTATE AND THE
            PREPARATION OF ANY OFFER TO LIST REAL ESTATE IN CONNECTION WITH SUCH A DISPOSITION OR TRANSACTION AND FOR NO OTHER PURPOSE WHATSOEVER.
          </p>
          <p id="legal-row-2.3" class="legal-row">
            This Site is a service of Teranet which is controlled and operated by Teranet. All content included on this Site, such as text, graphics, logos, button icons, images, audio and video clips, software (as defined below),
            materials, information, products and services, (collectively, "Content") is the property of Teranet, or other parties that have licensed their materials to Teranet, and is protected by Canadian and international copyright
            laws. You shall not otherwise copy, adapt, publish, compile, create derivative products (the "Derivative Products") in any manner in any format. Unless otherwise expressly stated, no Derivative Products, no Content from this
            Site or any content on any website owned, operated, licensed or controlled by Teranet may be copied, reproduced, republished, uploaded, posted, transmitted, framed, commercially exploited or distributed in any way or by any
            means whatsoever. Where expressly permitted hereunder you and/or your Users may download or print one copy of certain of the Content for your personal, non-commercial use only and in accordance with purposes that may be set
            out in the Subscription Agreement and/or posted on the Site, provided all copyright and other proprietary notices are maintained. Any other use by you and your Users must be agreed to in advance and in writing by Teranet.
            Modification of the Content or use of the Content for any other purpose (including use of any such content on any other website or networked computer environment) is a violation of copyright and other proprietary rights. You
            may not use the Site, the Derivative Products and Content for any unauthorized, improper or illegal purpose or in connection with the commission of any criminal or otherwise illegal act. You and your Users agree to abide by
            all copyright notices, information and restrictions contained in any Content on the Site and any content accessed through the Site.
          </p>
          <p id="legal-row-2.4" class="legal-row">
            "GeoWarehouse" and the "GeoWarehouse design" and "Ontario Mortgage Insight" ("OMI") are trademarks of Teranet. "POLARIS" and Teranet are registered trademarks and the Gateway design is a trademark of Teranet used under
            license. Other trademarks or trade names mentioned herein are the trademarks or trade names of their respective owners and are used under license.
          </p>
          <p id="legal-row-2.5" class="legal-row">
            In the event you and/or your Users download software from the Site, the software, including any files, images incorporated in or generated by the software and data accompanying the software (collectively, the "Software") are
            licensed to you by Teranet. The license is personal, non-exclusive, non-transferable and non-assignable. Title to the Software is not being transferred to you. You own the medium on which the Software is recorded, but Teranet,
            or others, retain full and complete copyright in and title to the Software and all other intellectual property rights therein. You and your Users may not redistribute, sell, decompile, reverse-engineer or disassemble the
            Software.
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="">
          <p id="legal-row-3" class="legal-row">
            3. TERMINATION AND SUSPENSION
          </p>
          <p id="legal-row-3.1" class="legal-line"> (a) This Agreement is effective until it is terminated by you or Teranet.</p>
          <p id="legal-row-3.2" class="legal-line"> (b) You may terminate this Agreement or disable a user ID and password at any time by contacting Teranet customer support. A User may disable its assigned user ID and password at any time by contacting
            Teranet customer support. Information on how to contact Teranet customer support is available on the Site.</p>
          <p id="legal-row-3.3" class="legal-line"> (c) Teranet may terminate this Agreement and/or disable a user ID and password immediately on notice to you at any time.</p>
          <p id="legal-row-3.4" class="legal-line"> (d) This Agreement will terminate immediately without notice to you from Teranet if, in Teranet's sole discretion, you or a User fail to comply with any material term or provision hereof or if Teranet
            believes that you have or a User has, by conduct, violated any applicable law or are acting contrary to the interests of Teranet.</p>
          <p id="legal-row-3.5" class="legal-line"> (e) For clarity, a suspension hereunder does not constitute termination of this Agreement and you shall continue to be bound by the terms hereof during the period of such suspension. If your account has
            been suspended you may not access the Site through the use of an account. All of the provisions of this Agreement shall survive termination of this Agreement and you and your Users agree to remain bound by those provisions
            that survive termination of this Agreement. Termination of this Agreement shall include disablement of all IDs and passwords provided to you and your Users.</p>
          <p id="legal-row-3.6" class="legal-row">
            Upon termination of this Agreement, You and your Users must permanently erase or otherwise destroy the Content and all copies made of same and, at the request of Teranet, provide Teranet with written certification that these
            actions have been carried out.
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="">
          <p id="legal-row-4" class="legal-row">
            4. DISCLAIMER
          </p>
          <p id="legal-row-4.1" class="legal-row">
            Unless otherwise provided, the Site and Content on this Site are provided on an "as is" and "as available" basis and without warranties of any kind, either express or implied, statutory or otherwise. To the fullest extent
            permitted by applicable law, the Teranet Group disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose, with respect to the Site
            and Content contained thereon and content accessible there from and any website with which the Site is linked. The Teranet Group does not warrant the functions, and links contained on the Site or that the Content will meet
            your requirements of you and your users, that the Site, or the Content, are fit for any particular purpose or that the operations of the network over which the Site and Content is made available, or the Content will be
            uninterrupted or error-free or accurate, that defects will be corrected, or that the Site or the server(s) that makes the Site and the Content available are free of viruses, worms or other harmful components. The Teranet Group
            does not warrant or make any representations regarding the use or the results of the use of the Content (including, without limitation, services for the transfer of information and data) on the Site in terms of its
            correctness, accuracy, reliability, security or otherwise. You (and not the Teranet Group) assume the entire cost to you and your Users of any necessary servicing, repair or correction. Applicable law may not allow the
            exclusion of implied warranties, so the above exclusion may not apply to you and your Users.
          </p>
          <p id="legal-row-4.2" class="legal-row">
            The Site is controlled and operated at offices in Toronto, Ontario, Canada. Certain Content may be hosted on computers in other jurisdictions. The Teranet Group makes no representation that the Site or Content on the Site is
            appropriate or available for use in other locations. If you or your Users choose to access this Site and the Content from other locations, you and your Users do so on your and their own initiative and you and your Users are
            responsible for compliance with local laws, if and to the extent local laws are applicable.
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="">
          <p id="legal-row-5" class="legal-row">
            5. LIMITATION OF LIABILITY
          </p>
          <p id="legal-row-5.1" class="legal-row">
            Under no circumstances, including, but not limited to, negligence, shall the Teranet Group or its suppliers be liable for any direct, indirect, special, consequential or other damages that result from the use of, or the
            inability to use, the Site and the Content on the Site, even if Teranet, or a Teranet authorized representative has been advised of the possibility of such damages. Applicable law may not allow the limitation or exclusion of
            liability for incidental or consequential damages, so the above limitation or exclusion may not apply to you and your Users. In no event shall the Teranet Group's total aggregate liability to you and your Users for all
            damages, losses, and causes of action (whether in contract, tort, including but not limited to negligence, or otherwise) exceed the lesser of: (a) CDN$10,000 (provided that in the case of Teranet suppliers, that amount shall
            be $1.00); and (b) or the charges and fees, if any, paid to Teranet by you and your Users during the 12 months previous to the date of the loss or claim; excluding, without limitation, any statutory fees and charges for the
            use of any Content provided by persons other than Teranet. This section shall apply whether or not liability results from negligence, a breach of a fundamental term or condition or a fundamental breach of this Agreement.
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="">
          <p id="legal-row-6" class="legal-row">
            6. LINKS TO THIRD PARTY SITES
          </p>
          <p id="legal-row-6.1" class="legal-row">
            The links on the Site will enable you and your Users to leave the Site and connect directly to linked sites. Teranet is providing these links only as a convenience. Such linked sites are entirely independent of the Site, and
            shall not be construed as any reflection on, any affiliation with, approval of, or endorsement by the Teranet Group of any such site or entity, or its respective information, publications, products or services. The Teranet
            Group shall not be responsible for the contents of any site linked to a Teranet site or for any link contained in a linked site. When linking to a third party site, you are leaving the privacy coverage of this Site and are
            subject to the privacy coverage of the third party site. Teranet cannot and does not guarantee that the third party website privacy statement is the same or contains the same privacy statement of Teranet.
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="">
          <p id="legal-row-7" class="legal-row">
            7. SUBMISSIONS
          </p>
          <p id="legal-row-7.1" class="legal-row">
            We would like to hear from you and your Users regarding Teranet's products and services. Please note that if you and your Users send us suggestions, ideas, or other information (collectively, "Submissions"), unfortunately we
            will not be able to return the Submissions. Once submitted, you and your Users grant us a non-exclusive, worldwide, royalty-free license that is sub-licensable and transferable, to make, use, sell, have made, offer to sell,
            import, reproduce, publicly display, distribute, modify, or publicly perform the Submissions in any manner without any obligation, royalty or restriction based on intellectual property rights or otherwise..
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="">
          <p id="legal-row-8" class="legal-row">
            8. JURISDICTION AND SEVERABILITY
          </p>
          <p id="legal-row-8.1" class="legal-row">
            You and your Users agree and acknowledge that use of this Site and Content shall be deemed to have occurred and taken place solely in the Province of Ontario, Canada. You and your Users further agree and acknowledge that this
            Agreement, and any disputes in connection with use of this Site and the Content, will be governed by the laws of the Province of Ontario, Canada, and the laws of Canada applicable therein. You and your Users expressly consent
            to the exclusive forum, jurisdiction, and venue of the courts of Ontario in any and all actions, disputes, or controversies relating hereto. If any provision of this Agreement shall be found to be unlawful, void or
            unenforceable, then such provision shall be deemed severable here from and will not affect the validity and enforceability of any remaining provisions.
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="">
          <p id="legal-row-9" class="legal-row">
            9. AMENDMENTS
          </p>
          <p id="legal-row-9.1" class="legal-row">
            Teranet reserves the right to make changes to the Site, the Content and pricing and to modify, alter or otherwise update this Agreement at any time with or without notice. Teranet will use reasonable efforts to provide you
            with notice of these changes, which could include by way of notices on the Site, email and/or Site pop ups. It is your responsibility to ensure sure you and your Users keep your email address updated with us and that any
            browser pop up blocker you use does not block these notifications. You and your Users should also review this link on a regular basis for any such changes. Continued use of the Site or Content by you and your Users shall
            constitute acceptance of any revisions to this Agreement and pricing.
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="">
          <p id="legal-row-10" class="legal-row">
            10. LANGUAGE
          </p>
          <p id="legal-row-10.1" class="legal-row">
            The parties have requested that this Agreement and all documents contemplated hereby be drawn up in English. Les parties aux pr&#233;sentes ont exig&#233; que cette entente et tous autres documents envisag&#233;s par les pr&#233;sentes
            soient r&#233;dig&#233;s en anglais.
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="">
          <p id="legal-row-11" class="legal-row">
            11. INDEMNIFICATION
          </p>
          <p id="legal-row-11.1" class="legal-row">
            To the maximum extent permitted by applicable law, you will defend, indemnify and hold the Teranet Group harmless from and against all claims, liability, and expenses, including all legal fees and costs, arising out of: (i) a
            breach of any provision of this Agreement by you and your Users, and (ii) the use of the Site and the Content by you and your Users.
          </p>
          <p id="legal-row-11.2" class="legal-row">
            Teranet reserves the right, in its sole discretion and at its own expense, to assume the exclusive defence and control of any action, claim or matter otherwise subject to indemnification by you. You and your Users will
            co-operate as fully as reasonably required in the defence of any claim.
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="">
          <p id="legal-row-12" class="legal-row">
            12. USER PRIVACY AND SECURITY POLICY AND CONFIDENTIALITY
          </p>
          <p id="legal-row-12.1" class="legal-row">
            Use of this Site is subject to the terms of Teranet's Privacy Policy accessible on this Site at http://www.geowarehouse.ca/Privacy_Statement.htm as amended from time to time without notice. Without limiting the generality of
            the Privacy Policy, by using the Site or Content, you and your Users expressly consent to the disclosure by Teranet of any of your personal information of you and your Users that may be included within or with respect to
            access to or use of the Site to the Province of Ontario, its ministries or agencies, for the purposes of ministry or agency use, and to the disclosure of such personal information to the Province of Ontario, its ministries or
            agencies and to any law enforcement authority having jurisdiction, in connection with any investigation by any of them relating to you and your Users use of the Site and the Content. You and your Users consent to Teranet's use
            of any personal information entered into the Site by you or your Users to validate that: (i) your use of the Site conforms to these Legal Terms and Conditions; (ii) for the development, enhancement, provision and support of
            the Site and Content; (iii) for administrative, data back-up or processing purposes; (iv) for the purposes of statistical and market analysis; and (v) to meet contractual reporting and audit obligations to suppliers whose
            products form part of or are otherwise related to the Site and Content. In addition to those disclosures set out in the privacy policy, we may disclose any of your personal information to: (i) suppliers of services or products
            purchased by you through the Site and Content in relation to such purchases; (ii) our affiliates, partners and subcontractors that carry out certain functions for or provide certain services to us; and (iii) any law
            enforcement authority or regulator having jurisdiction in connection with any investigation relating to you. You and your Users recognize and agree that: (i) the Internet is not a secure medium, and neither the privacy of
            communications, nor visits to this Site, can be guaranteed; (ii) the nature of Internet communications means that communications may be susceptible to data corruption, unauthorized access, interception and delays; (iii) the
            Site and Content may include incomplete information, inaccuracies or typographical errors; (iv) Teranet and any other persons involved in the management of the Site and the Content may make changes in the Site and Content at
            any time without notice; (v) the Teranet Group shall not be responsible for any detrimental reliance you and your Users may place on the Site or the Content; and (vi) use of the Site and the Content is on an "as is" and "as
            available" basis and is strictly at the risk of you and your Users.
          </p>
          <p id="legal-row-12.2" class="legal-row">
            Use of this Site and Content is also subject to the terms of Teranet's Security Policy accessible on this Site at www.geowarehouse.ca/Security_Statement.htm. You and your Users recognize and agree that although Teranet
            security efforts are generally consistent with industry practice in Canada, complete privacy, confidentiality and security associated with any communications is not yet possible over the Internet, and therefore cannot be
            guaranteed. As a result, the Teranet Group shall not be responsible or liable for any loss of privacy, disclosure of information, harm, damage or loss that may result from the transmission of any information in any connection
            with the Site or the Content.
          </p>
          <p id="legal-row-12.3" class="legal-row">
            You and your Users must not attempt to violate the security of the Site, including without limitation: (i) attempting to log into an account or server which you and your Users are not authorized to access; or (ii) attempting
            to interfere with the Content provided by the Site through means of a virus or flooding or otherwise overloading the Site's servers, or by any other similar or dissimilar means.
          </p>
          <p id="legal-row-12.4" class="legal-row">
            You and your Users acknowledge that some or all of the Content may be based on information obtained from the public and collected by the Province of Ontario (the &#8220;Province&#8221;). You and your users agree to comply with
            all applicable laws, including without limitation any privacy and access to information laws, relating to the use of such Content. At all times, you and your Users shall abide by any and all directions by Teranet to you with
            respect to the collection, use, dissemination and sale of information about and obtained from the public.
          </p>
          <p id="legal-row-12.5" class="legal-row">
            The confidentiality of any material or information provided to You and your Users under this Agreement, including confidential information concerning Teranet, may be subject to the provisions of applicable protection of
            privacy and access to information legislation. For the purposes of such applicable law, Teranet, You and your Users agree that disclosure of the Content or any confidential information concerning Teranet, the Site or the
            Content could reasonably be expected to significantly prejudice the competitive position of Teranet in the marketplace or interfere significantly with other contractual negotiations of Teranet. In the event that a request for
            all or part of any material or information provided to You or your Users, or the Site or Content is made to You or your Users pursuant to applicable law, You shall provide notice of such a request to Teranet within 3 business
            days of the request being made, and shall co-operate with Teranet in handling such a request.
          </p>
          <p id="legal-row-12.6" class="legal-row">
            You and your Users shall take all reasonable precautions to prevent any confidential information provided to it by Teranet or by way of the Site from being used, accessed or acquired by any unauthorized person or persons. You
            and your Users shall disclose Teranet's confidential information only to such of its employees and agents who have a need to know for the purposes of this Agreement and are subject to obligations of confidence with respect
            thereto or as otherwise permitted by this Agreement.
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="">
          <p id="legal-row-13" class="legal-row">
            13. U.S. GOVERNMENT END USERS
          </p>
          <p id="legal-row-13.1" class="legal-row">
            The Software available on this Site is a "Commercial Item," as that term is defined at 48 C.F.R. 2.101, consisting of "Commercial Computer Software" as such term may be used in 48 C.F.R. 12.212 or 48 C.F.R. 227.7202, as
            applicable. Consistent with 48 C.F.R. 12.212 or 48 C.F.R. 227.7202-1 through 227.7202-4, as applicable, the Commercial Computer Software is licensed to U.S. Government end users (i) only as Commercial Items and (ii) with only
            those rights as are granted to all other end users pursuant to the terms and conditions herein.
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="">
          <p id="legal-row-14" class="legal-row">
            14. PRODUCTS/SERVICES SUPPLIED FROM OUTSIDE ONTARIO
          </p>
          <p id="legal-row-14.1" class="legal-row">
            You and your Users acknowledge and agree that certain of the Content available on the Site may be supplied by third parties resident and operating outside the Province of Ontario and/or may be hosted on computers located
            outside of the Province of Ontario.
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="">
          <p id="legal-row-15" class="legal-row">
            15. PAYMENT FOR SITE SUBSCRIPTION, PRODUCTS AND/OR SERVICES
          </p>
          <p id="legal-row-15.1" class="legal-row">
            Charges. Charges and fees for accessing, browsing and using the Site and Content contained on the Site will be in accordance with the rates and pricing plans established by Teranet and its suppliers from time to time plus all
            additional applicable taxes. Fees, charges and allowable search volumes may be set out in the Subscription Application and/or posted on the Site together with changes thereto.
          </p>
          <p id="legal-row-15.2" class="legal-row">
            Payment Methods. Fees for a subscription for access to the Site and certain Content may be paid by cheque or be charged to a credit card. If payment is to be made by cheque, you shall deliver cheques payable in the required
            amounts to Teranet. If payment is to be made by credit card, you will be asked to provide credit card information as part of a Subscription Application or as you and your Users use Content that generates a fee or charge.
            Payment methods may vary depending on the materials, information or products or services.
          </p>
          <p id="legal-row-15.3" class="legal-row">
            Payment of Subscription Fees. Fees to access the Site and Content ("Subscription Fees") may be paid by one method: a single payment by cheque or credit card covering the period commencing April 1 and ending March 31
            ("Subscription Period"), with such fee being partially prorated where the subscription begins part way through the Subscription Period (the "Annual Payment Plan").
          </p>
          <p id="legal-row-15.4" class="legal-row">
            Online Orders By Credit Card. We do not store credit card information except for payment and refund purposes (based on the Refund Policy set out below). If you or a User logs out of the Site or submits a new order, you or the
            User will be required to re-enter credit card information. Each submission or order online for material, information, a product or service for which a fee is charged is deemed to be an authorization to debit predetermined sums
            representing applicable statutory and service fees, charges, and applicable taxes from the credit card tendered for payment. You or the User will be informed of the total charges and asked to confirm the transaction before it
            is charged to the credit card tendered for payment.
          </p>
          <p id="legal-row-15.5" class="legal-row">
            Obligation to Pay and Interest. You are responsible for all charges, fees and taxes incurred by you and your Users. Payment obligations for Subscription Fees and online orders are material terms of this Agreement. If we are
            unable for any reason to collect the charges, fees and/or taxes from a credit card or cheque tendered as payment, you are responsible to pay Teranet directly the fees, charges and taxes incurred by you and your Users plus any
            other charges (including bank charges and the costs of collection) incurred by Teranet. Outstanding charges, fees and taxes will be subject to a one percent per month interest charge after 30 calendar days calculated on a
            monthly basis (an effective annual rate of 12.7%).
          </p>
          <p id="legal-row-15.6" class="legal-row">
            Suspension of Service. Teranet may suspend without notice the Service to any Authorized User for non-payment of fees.
          </p>
          <p id="legal-row-15.7" class="legal-row">
            Statutory Fees. If any statutory fees are collected from you or your Users by Teranet as a result of usage of this Site and the Content, Teranet shall remit the required payments to the Crown in right of Ontario (the "Crown").
            You and your Users acknowledge that Teranet is acting as an agent of the Crown and that the Crown shall have recourse against you for any unpaid statutory fees.
            Refund Policy. Before submitting any request for material, information, products or services please verify that the name and other information have been entered correctly. We cannot refund the cost of material, information, a
            product or service if the request has been inputted incorrectly. Once a subscription fee is paid or a submission or order is submitted, there are NO REFUNDS, EXCHANGES OR CANCELLATIONS except as follows:
          </p>
          <p id="legal-row-15.8" class="legal-row">(a) If this Agreement is terminated by Teranet as set out in section 3(c) above, you shall receive a pro-rata refund of any unused prepaid subscription fees. If a user ID and password is disabled by Teranet
            as set out in section 3(c) above, you shall receive a pro-rata refund of any unused prepaid subscription fees for that User whose user ID and password has been disabled.</p>
  
          <p id="legal-row-15.9" class="legal-row"> (b) If any attempted use of Property Information Services (as defined in section 16 below) or other Content by you or your Users fails through a fault of the Site, Property Information Services or other
            Content or Teranet's facilities, Teranet's sole liability, at your written request, shall be to refund the charges imposed by us for the failed use of Property Information Services or other Content to the holder of the credit
            card used for payment. This section shall apply whether or not the liability results from negligence, a breach of a fundamental term or condition, or a fundamental breach of this Agreement. You are responsible for notifying
            Teranet of the failed use of Property Information Services or other Content within 30 calendar days of the failed transaction. After any such 30 calendar day period, you, your Users and the holder of the credit card tendered
            for payment shall forfeit any recourse to Teranet to claim a refund.</p>
          <p id="legal-row-15.10" class="legal-row">
            Record. Teranet records all transactions on the Site for the purposes of administration of the Site and the Content.
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="">
          <p id="legal-row-16" class="legal-row">
            16. This Site provides you and your Users with online remote access to material, information, products and services extracted from the POLARIS database, (the "Property Information Services") and other Content. Property
            Information Services are listed in section 24 below.
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="">
          <p id="legal-row-17" class="legal-row">
            17. Unless otherwise stated, no Derivative Products and no Content, including the Property Information Services, may be copied, reproduced, republished, uploaded, posted, transmitted, framed, commercially exploited or
            distributed in any way or by any means whatsoever, except that you and your Users may, as expressly permitted on the Site, use and print one copy of the Content including the Property Information Services for your internal use
            and purposes only, provided you and your users maintain all copyright and other proprietary notices. Data may not be extracted from, or copies made of, the data in Property Information Services or other Content. The foregoing
            prohibitions expressly include, but are not limited to, the practice of screen scraping, database scraping or any such practice or activity; the purpose of which is to obtain data or portions thereof, portions of databases
            from Property Information Services in any manner or any quantities not expressly authorized hereunder. Property Information Services and access to Property Information Services and other Content delivered through this, may not
            be used: (a) in a computer service or timesharing business; (b) to create a database in electronic or other format or for the purposes of data aggregation or dissemination (otherwise than for your internal archival use); (c)
            for the purpose of sale, resale or the licensing of the data and information or components thereof contained in Property Information Services and other Content; or (d) for any unauthorized, improper or illegal purpose or in
            connection with the commission of any criminal or otherwise illegal act. Property Information Services or other Content may not be tampered with through attempts to translate, reverse engineer, decompile, disassemble, modify
            or merge Property Information Services with any other system. Pursuant to Teranet's agreements with its suppliers, you and your Users shall not: (a) sub-license access to Property Information Services or other Content to the
            Government of the United States or any regulatory body or agency thereof; (b) export your access to Property Information Services or other Content outside Canada; or (c) infringe any copyright, patent, trademark or trade
            secret of Teranet or its suppliers. Modification of the Content or use of the Content, which includes Property Information Services for any other purpose is a violation of copyright and other proprietary rights. Unauthorized
            access to, reproduction, and/or distribution of Content or Property Information Services, or any portion of it, may result in serious civil and criminal penalties, and will be prosecuted to the maximum extent of the law.
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="">
          <p id="legal-row-18" class="legal-row">
            18. Use of personal information (which is data about an identifiable individual such as name, address and financial information), contained in Property Information Services, shall relate directly to the purpose for which the
            data appears in land registry records and is subject to all applicable privacy legislation in respect of personal information. Such information shall not be used for marketing to a named individual.
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="">
          <p id="legal-row-19" class="legal-row">
            19. Property Information Services and other Content accessible through this Site may be operated and maintained by persons other than Teranet. The Teranet Group cannot, and does not, warrant the accuracy, completeness,
            currency, merchantability or fitness for a particular purpose of Property Information Services, or other Content and services available through the Site. Due to the number of sources from which Property Information Services
            and other Content are obtained, there may be delays, omissions or inaccuracies in such Property Information Services and other Content.
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="">
          <p id="legal-row-20" class="legal-row">
            20. All Content, including Property Information Services, obtained through the GeoWarehouse Online service is provided "as is" and "as available". Property Information Services are not the official government record and will
            not necessarily reflect the current status of interests in land. You hereby acknowledge that Teranet and its suppliers are not required to provide any updates, fixes, new versions, new releases, maintenance and/or support of
            any kind for Property Information Services or other Content to you and your Users.
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="">
          <p id="legal-row-21" class="legal-row">
            21. You and your Users acknowledge that the Province makes no warranties, express or implied, with respect to Property Information Services, or other Content supplied by or through this Site, and all risk as to the results and
            performance of Property Information Services and other Content is assumed by you and your Users. The Teranet Group and the Province shall each not have any liability to you and your Users or any other person or entity for any
            claims, actions, loss, damage including, without limitation, loss of revenue or profit or savings, lost or damaged data, or other commercial or economic loss, or any direct, indirect or incidental, special or consequential
            damages whatsoever, even if Teranet or the Province has been advised of the possibility of such damages, or for claims by a third party. This section shall apply whether or not the liability results from a breach of a
            fundamental term or condition or a fundamental breach. You and your Users acknowledge that Teranet accesses records filed with the Province and this clause is included for the benefit of and can be relied on by the Province.
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="">
          <p id="legal-row-22" class="legal-row">
            22. Notwithstanding anything in these Terms and Conditions, neither the Teranet Group nor any person whose database or other product is operated or distributed by Teranet, guarantees that a database or any data or document
            within a database is complete and wholly accurate, nor that any service will function without failure or interruption. The entire risk to you and your Users as to the results and performance of Property Information Services,
            and any other Content provided by Teranet is assumed by you.
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="">
          <p id="legal-row-23" class="legal-row">
            23. If you or your users have access to this Site and certain of the Property Information Services or other Content listed below through a combination of the Agreement and an evaluation agreement (which includes an Evaluator
            Acknowledgement); to the extent that there is any inconsistency between the Agreement and an evaluation agreement, the evaluation agreement shall prevail with respect to use of the Property Information Services.
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="">
          <p id="legal-row-24" class="legal-row">
            24. Property Information Services may include: (a) Sales History, (b) Find Comparable Sales, (c) Neighbourhood Sales, (d) Mapping Information, (e) Parcel Register (f) Assessment Information and MPAC Propertyline&#8482;
            Reports, (g) Demographics, (h) Plans, (i) Surveys, (j) Instruments and Maps.
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="">
          <p id="legal-row-25" class="legal-row">
            25. Notwithstanding section 2, if you are a Real Estate Board Sublicensee, you may provide Assessment Information and MPAC Propertyline&#8482; Reports directly to your clients (sellers, purchasers, lessors and lessees of
            residential real estate), and you may include on a MLS&#174; or other real estate listing (including a private listing) only the Assessment Roll Number, and the defined square footage ranges and defined year built ranges
            described in Appendix A to these Terms and Conditions; you may not, and will not permit others, to include the exact square footage or year built of a subject property provided in the Assessment Information on a MLS&#174; or
            the real estate listing (including a private listing).
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="">
          <p id="legal-row-26" class="legal-row">
            26. You and your Users are responsible for maintaining the confidentiality of you and your Users' usernames and passwords. You are responsible for all activities that occur under your account. You agree to: (a) immediately
            notify us of any unauthorized use of your account or any other breach of security, and (b) ensure that you and your Users exit from the account at the end of each session. Teranet will not be liable for any loss or damage
            arising from you or your Users failure to comply with this section.
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="">
          <p id="legal-row-27" class="legal-row">
            27. Audit. Teranet, or its authorized representatives, acting reasonably, may upon written request to you and/or your Users examine and inspect during normal business hours, the materials and records of you and/or your Users
            relating to GeoWarehouse, the Site and Content and this Agreement. Subject to section 12 hereof, Teranet shall keep confidential, and not disclose, information concerning the business and affairs of you and/or your Users.
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="">
          <p id="legal-row-28" class="legal-row">
            28. Parcel Mapping shown on the site was compiled using plans and documents recorded in the Land Registry System and has been prepared for property indexing purposes only. It is not a Plan of Survey. For actual dimensions of
            property boundaries, see recorded plans and documents.
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="">
          <p id="legal-row-29" class="legal-row">
            29. Additional Terms and Conditions for Use of GeoWarehouse Beta. Where you are granted access to any in-development version of GeoWarehouse (&#8220;GeoWarehouse Beta&#8221;) the terms of this Agreement will be applicable
            subject the following. Use of GeoWarehouse Beta available, at no charge, for evaluation purposes only and your access to GeoWarehouse Beta or all any part of GeoWarehouse Beta may be withdrawn at any time, without notice.
            GeoWarehouse Beta is furnished &#8220;AS IS&#8221;. Teranet makes no representations or warranties as to the operation, performance or non-performance. There are no warranties, express or implied, whether of merchantability or
            fitness for the intended use or purpose. Teranet shall have no direct or indirect liability to you or any third party in relation to your use of GeoWarehouse Beta.
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="">
          <p id="legal-row-30" class="legal-row">
            30. By using Google Maps by way of the Site and part of the Content, You and your Users are subject to the Google Maps/Google Earth Additional Terms of Service (including the Google Privacy Policy) at
          </p>
          <p id="legal-line-30.1" class="legal-line">
            https://www.google.com/intl/en US_US/help/terms_maps.html and https://www.google.com/intl/ALL/policies/privacy/index.html respectively.
          </p>
          <p id="legal-row-A" class="legal-row"> Appendix A - Defined square footage & year built ranges</p>
  
          <p id="legal-row-A.1" class="legal-row"> Acceptable Square Footage Ranges (Residential) </p>
  
          <p id="legal-line-A.2" class="legal-line"> Approximate Square Footage: <em class="legalAgreement">0-400</em></p>
          <p id="legal-line-A.3" class="legal-line"> Approximate Square Footage: <em class="legalAgreement">400-700</em></p>
          <p id="legal-line-A.4" class="legal-line"> Approximate Square Footage: <em class="legalAgreement">700-1100</em></p>
          <p id="legal-line-A.5" class="legal-line"> Approximate Square Footage: <em class="legalAgreement">1100-1500</em></p>
          <p id="legal-line-A.6" class="legal-line"> Approximate Square Footage: <em class="legalAgreement">1500-2000</em></p>
          <p id="legal-line-A.7" class="legal-line"> Approximate Square Footage: <em class="legalAgreement">2000-2500</em></p>
          <p id="legal-line-A.8" class="legal-line"> Approximate Square Footage: <em class="legalAgreement">2500-3000 </em></p>
          <p id="legal-line-A.9" class="legal-line"> Approximate Square Footage: <em class="legalAgreement">3000-3500</em></p>
          <p id="legal-line-A.10" class="legal-line"> Approximate Square Footage: <em class="legalAgreement">3500-5000</em></p>
          <p id="legal-line-A.11" class="legal-line"> Approximate Square Footage: <em class="legalAgreement">5000-6500</em></p>
          <p id="legal-line-A.12" class="legal-line"> Approximate Square Footage: <em class="legalAgreement">6500+</em></p>
  
          <p id="legal-line-A.13" class="legal-row"> Acceptable Square Footage Ranges (Residential Condominiums)</p>
  
          <p id="legal-line-A.14" class="legal-line"> Approximate Square Footage:<em class="legalAgreement">0-499</em></p>
          <p id="legal-line-A.15" class="legal-line"> Approximate Square Footage:<em class="legalAgreement">500-599</em></p>
          <p id="legal-line-A.16" class="legal-line"> Approximate Square Footage:<em class="legalAgreement">600-699</em></p>
          <p id="legal-line-A.17" class="legal-line"> Approximate Square Footage:<em class="legalAgreement">700-799</em></p>
          <p id="legal-line-A.18" class="legal-line"> Approximate Square Footage:<em class="legalAgreement">800-899</em></p>
          <p id="legal-line-A.19" class="legal-line"> Approximate Square Footage:<em class="legalAgreement">900-999</em></p>
          <p id="legal-line-A.20" class="legal-line"> Approximate Square Footage:<em class="legalAgreement">1000-1199</em></p>
          <p id="legal-line-A.21" class="legal-line"> Approximate Square Footage:<em class="legalAgreement">1200-1399</em></p>
          <p id="legal-line-A.22" class="legal-line"> Approximate Square Footage:<em class="legalAgreement">1400-1599</em></p>
          <p id="legal-line-A.23" class="legal-line"> Approximate Square Footage:<em class="legalAgreement">1600-1799</em></p>
          <p id="legal-line-A.24" class="legal-line"> Approximate Square Footage:<em class="legalAgreement">1800-1999</em></p>
          <p id="legal-line-A.25" class="legal-line"> Approximate Square Footage:<em class="legalAgreement">2000-2249</em></p>
          <p id="legal-line-A.26" class="legal-line"> Approximate Square Footage:<em class="legalAgreement">2250-2499</em></p>
          <p id="legal-line-A.27" class="legal-line"> Approximate Square Footage:<em class="legalAgreement">2500-2749</em></p>
          <p id="legal-line-A.28" class="legal-line"> Approximate Square Footage:<em class="legalAgreement">2750-2999</em></p>
          <p id="legal-line-A.29" class="legal-line"> Approximate Square Footage:<em class="legalAgreement">3000-3249</em></p>
          <p id="legal-line-A.30" class="legal-line"> Approximate Square Footage:<em class="legalAgreement">3250-3499</em></p>
          <p id="legal-line-A.31" class="legal-line"> Approximate Square Footage:<em class="legalAgreement">3500-3749</em></p>
          <p id="legal-line-A.32" class="legal-line"> Approximate Square Footage:<em class="legalAgreement">3750-3999</em></p>
          <p id="legal-line-A.33" class="legal-line"> Approximate Square Footage:<em class="legalAgreement">4000-4249</em></p>
          <p id="legal-line-A.34" class="legal-line"> Approximate Square Footage:<em class="legalAgreement">4250-4499</em></p>
          <p id="legal-line-A.35" class="legal-line"> Approximate Square Footage:<em class="legalAgreement">4500-4749</em></p>
          <p id="legal-line-A.36" class="legal-line"> Approximate Square Footage:<em class="legalAgreement">4750-4999</em></p>
          <p id="legal-line-A.37" class="legal-line"> Approximate Square Footage:<em class="legalAgreement">5000+</em></p>
  
          <p id="legal-row-A.38" class="legal-row"> Acceptable Year Built Ranges </p>
  
          <p id="legal-line-A.39" class="legal-line"> Year Built:<em class="legalAgreement">New</em></p>
          <p id="legal-line-A.40" class="legal-line"> Year Built:<em class="legalAgreement">0-5 years old</em></p>
          <p id="legal-line-A.41" class="legal-line"> Year Built:<em class="legalAgreement">6-15 years old</em></p>
          <p id="legal-line-A.42" class="legal-line"> Year Built:<em class="legalAgreement">16-30 years old</em></p>
          <p id="legal-line-A.43" class="legal-line"> Year Built:<em class="legalAgreement">31-50 years old</em></p>
          <p id="legal-line-A.44" class="legal-line"> Year Built:<em class="legalAgreement">51-99 years old</em></p>
          <p id="legal-line-A.45" class="legal-line"> Year Built:<em class="legalAgreement">100+ years old</em></p>
          <p class="legal-row"></p>
        </div>
      </div>
    </div>


  </div>

  <div class="buttons">
    @if (attemptsNo > 0) {
      <div class="flex flex-row justify-content-center gap-2">
        <button id="legal-btn-accept" (click)="accept()"
                [ngClass]="{'button-disabled' : inProgress}"
                color="primary"
                mat-raised-button>
          I Accept
        </button>
        <button id="legal-btn-decline" (click)="decline()"
                class="mat-orange"
                [ngClass]="{'button-disabled' : inProgress}"
                mat-raised-button>
          I Decline
        </button>
      </div>
    }  
  </div>

  <gema3g-footer></gema3g-footer>
</div>


