import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Assessment } from "../../../../../core/model/assessment/assessment";
import { EstoreService } from "../../../../../shared/service/estore.service";
import { UserAccessControl } from "../../../../../core/model/user/user-access-control";
import { MainMapState } from "../../../../../core/model/spatial/main-map-state";
import { MainMapService } from "../../../main-map/main-map.service";
import { PropertyReportService } from '../../../../../shared/service/property-report.service';
import { MatBadgeModule } from '@angular/material/badge';

@Component({
  selector: 'gema3g-site-structure-assessment-number',
  templateUrl: './site-structure-assessment-number.component.html',
  styleUrls: ['./site-structure-assessment-number.component.scss']
})
export class SiteStructureAssessmentNumberComponent {
  @Input() assessment: Assessment = new Assessment();
  @Input() userAccessControls: UserAccessControl;
  @Input() assessmentIndex: number = 0;
  @Input() assessmentTotalNumber: number = 1;
  @Output() scrollToAssessment = new EventEmitter<number>();
  @ViewChild("copyArnPopOver") copyArnPopOver: any;
  copiedArn: string = "Copied";

  constructor(private eStoreService: EstoreService,
              private element: ElementRef,
              private mainMapService: MainMapService,
              private propertyReportService: PropertyReportService) {
  }

  openPropertyLine(rollNumber: string) {
    this.eStoreService.openPropertyLine(rollNumber);
  }

  drawAssessmentPolygon(assessment: Assessment) {
    this.propertyReportService.requestSiteStructureMapIt(true);
    this.mainMapService.addNewDrawAssessmentRequest(assessment.rollNumber);
  }

  goToNextAssessment() {
    this.scrollToAssessment.emit(this.assessmentIndex + 1);
  }

  goToPreviousAssessment() {
    this.scrollToAssessment.emit(this.assessmentIndex - 1);
  }

  copyArn = (rollNumber: string) => {
    navigator?.clipboard?.
    writeText(rollNumber)
      .then(() => {
        this.copiedArn = "Copied";
        this.copyArnPopOver.open();
        setTimeout(() => {
          this.copyArnPopOver.close();
        }, 4000);
      });
  }

}
