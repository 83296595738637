import {Component, OnInit} from '@angular/core';
import {BaseCatalogueProductComponent} from "../base-catalogue-product.component";

@Component({
  selector: 'gema3g-multi-catalogue-product',
  templateUrl: './multi-catalogue-product.component.html',
  styleUrl: './multi-catalogue-product.component.scss'
})
export class MultiCatalogueProductComponent extends BaseCatalogueProductComponent implements OnInit{
  index: number = 0;

  ngOnInit(): void {
    if(this.products?.length > 0) {
      this.product = this.products[0];
    }
  }

  buttonClicked() {
    this.runReport();
  }


  previousItem() {
    this.index = (this.index == 0) ? this.products.length - 1 : this.index - 1;
    this.product = this.products[this.index];
  }

  nextItem() {
    this.index = (this.index == this.products.length - 1) ? 0 : this.index + 1;
    this.product = this.products[this.index];
  }
}
