export class QuarterSelect{
  constructor(display: string, value: QuarterSelectValue) {
    this.display = display;
    this.value = value;
  }

  display: string;
  value: QuarterSelectValue;
}

export class QuarterSelectValue{
  constructor(fromDate: string, toDate: string, quarter: string) {
    this.fromDate = fromDate;
    this.toDate = toDate;
    this.quarter = quarter;
  }

  fromDate: string;
  toDate: string;
  quarter: string;
}
