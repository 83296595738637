import {Component, inject} from '@angular/core';
import { Observable } from "rxjs";
import { User } from "../../../core/model/user/user";
import { UserService } from "../../../shared/service/user.service";
import { DownloadService } from "../../../shared/service/download.service";
import { EstoreService } from "../../../shared/service/estore.service";
import { UserAccessControl } from "../../../core/model/user/user-access-control";
import { UserLicense } from "../../../core/model/user/user-license";
import dayjs from "dayjs";
import { LoggerService } from '../../../shared/service/log/logger.service';
import {UrlService} from "../../../shared/service/url.service";
import {EstoreProductCategoryEnum} from "../../../core/enum/estore-product-category-enum";
import { LicenseCounters } from '../../../core/model/user/license-counters';

@Component({
  selector: 'gema3g-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent {

  isLoading = true;
  user$: Observable<User>;
  userAccessControls: UserAccessControl;
  userLicenseCounters$: Observable<LicenseCounters>;

  constructor() {
    this.loadUser().then(r => {
      this.isLoading = false
    });
    this.userAccessControls = this.userService.getUserAccessControl();
  }

  private urlService = inject(UrlService);
  private userService = inject(UserService);
  private eStoreService = inject(EstoreService);
  private downloadService = inject(DownloadService);
  //private loggerService = inject(LoggerService);

  async loadUser() {
    this.user$ = this.userService.getUser();
    this.userLicenseCounters$ = this.userService.getUserLicenseCounters();
  }

  async renewSubscription() {
    await this.urlService.openEstoreCatalogueWithCategory(EstoreProductCategoryEnum.GWH_RENEW_SUBSCRIPTION);
  }

  downloadAccountStatement(type: string, quantity: number) {
    this.downloadService.downloadAccountStatement(type, quantity);
  }

  timeLeftDate(userLicense: UserLicense): string {
    const today = new Date().getTime();
    const millisecondDiff = new Date(userLicense.licenseEndDate).getTime() - today;
    const daysDiff = (millisecondDiff / (1000 * 60 * 60 * 24));
    if (daysDiff <= 0) {
      return "Your plan has expired.";
    } else {
      return `You have ${daysDiff.toFixed(0)} days left to renew`;
    }
  }

}
