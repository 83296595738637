import { SortDirection } from "@angular/material/sort";

export class SortColumn {

    constructor(columnName: string, sortDirection: SortDirection) {
        this.columnName = columnName;
        this.sortOrder = sortDirection;
    }

    columnName: string;
    sortOrder: SortDirection;
}