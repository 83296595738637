import { User } from "./user";

export class UserAccessControl {
  ssoDeepLinkAccess: boolean = false;
  propertyReportAccess: boolean = false;
  propertyReportAssessmentAccess: boolean = false;
  propertyReportDemographicsAccess: boolean = false;
  comparablesFeatureAccess: boolean = false;
  registrationTrackerAccess: boolean = false;
  GWHRSubscriptionExpired: boolean = false;
  parcelRegisterActive: boolean = false;
  parcelRegisterActiveTxnl: boolean = false;
  parcelRegisterInActive: boolean = false;
  neighbourhoodSalesReport: boolean = false;
  neighbourhoodSalesReportTxnl: boolean = false;
  parcelRegisterInActiveTxnl: boolean = false;
  residentialDtldRptL1Txnl: boolean = false;
  residentialDtldRptL2Txnl: boolean = false;
  AVMBasicResidentialRptTxnl: boolean = false;
  AVMEnhancedResidentialRptTxnl: boolean = false;
  indstrlDetailRptTxnl: boolean = false;
  commercialDetailRptTxnl: boolean = false;
  ICICommercialAndInstrlDetailRptTxnl: boolean = false;
  REBRptTxnl: boolean = false;
  corpNameAndAddrRptTxnl: boolean = false;
  AVMCmprbleRptTxnl: boolean = false;
  residentialFloorAreaReportTxnl: boolean = false;
  geowarehouse4YrPhaseInRptTxnl: boolean = false;
  REBResiDetailedRptTxnl: boolean = false;
  REBResiDetailRptRangeTxnl: boolean = false;
  avmMarketSalesRptTxnl: boolean = false;
  discountedICICommercialAndInstrlDetailRptTxnl: boolean = false;
  AVMResiCmprbleRptTxnl: boolean = false;
  NewResidentialFloorAreaReportTxnl: boolean = false;
  ResidentialFloorAreaRangeReportTxnl: boolean = false;
  ResidentialDetailedReportLevel2RangeTxnl: boolean = false;
  AVMResiCmprbleBndle250Fr10RptTxnl: boolean = false;
  AVMMarketSalesBndle350Fr10RptTxnl: boolean = false;
  REBResiBndle160Fr25RptTxnl: boolean = false;
  REBResiBndle280Fr50RptTxnl: boolean = false;
  ResiFlorAreaBndle100Fr25RptTxnl: boolean = false;
  ResiFlorAreaBndle175Fr50RptTxnl: boolean = false;
  HoodQAddressReportTxnl: boolean = false;
  HoodQDetailedReportTxnl: boolean = false;
  HoodQSchoolReportTxnl: any;
  avmRangeReportTxnl: any;
  premiumAvmComparableReportTxnl: boolean = false;
  pybBuyersReportTxnl: boolean = false;
  pybEasmentReportTxnl: boolean = false;
  pybSellersReportTxnl: boolean = false;
  topUpPackageReportTxnl: boolean = false;
  reportTopUp1000PropertyTxnl: boolean = false;
  reportTopUp2500PropertyTxnl: boolean = false;
  reportTopUp5000PropertyTxnl: boolean = false;
  mpacReportTopUp1000PropertyTxnl: boolean = false;
  mpacReportTopUp2500PropertyTxnl: boolean = false;
  mpacReportTopUp5000PropertyTxnl: boolean = false;
  mpacReportTopUp200PropertyTxnl: boolean = false;
  mpacReportTopUp500PropertyTxnl: boolean = false;
  reportTopUp200PropertyTxnl: boolean = false;
  reportTopUp500PropertyTxnl: boolean = false;
  CondoStatusCertificateTxnl: boolean = false;
  CondoSuiteSqrFootageTxnl: boolean = false;
  CondoSqrFootageBundleTxnl: boolean = false;
  reportTopUp5000MPSCommercialTxnl: boolean = false;
  reportTopUp2500MPSCommercialTxnl: boolean = false;
  reportTopUp1000MPSCommercialTxnl: boolean = false;
  reportTopUp5000MPSResidentialTxnl: boolean = false;
  reportTopUp2500MPSResidentialTxnl: boolean = false;
  reportTopUp1000MPSResidentialTxnl: boolean = false;
  companyLicenseRenewalLicenseCount: any;
  geowarehouseSubscriptionRenewalAccess: boolean = false;
  geowarehouseStore: boolean = false;
  geowarehouseStoreTxnl: boolean = false;
  private homeVerifiedReportSubscription: boolean = false;
  private homeVerifiedReportSubscriptionTxnl: boolean = false;
  private homeVerifiedReport: boolean = false;
  private homeVerifiedReportTxnl: boolean = false;
  private geowarehouseSubscriptionRenewalAccessTxnl: boolean;
  private geowarehouseCompanySubscriptionRenewalAccess: boolean;
  private geowarehouseCompanySubscriptionRenewalAccessTxnl: boolean;
  private avmRangeReport: boolean;
  private premiumAvmComparableReport: boolean;
  private geowarehouseReportPackage: boolean;
  private geowarehouseReportPackageTxnl: boolean;
  planImage: boolean;
  planImageTxnl: boolean;
  instrumentImage: boolean;
  instrumentImageTxnl: boolean;
  propertySurveyImageJDB: boolean;
  propertySurveyImageJDBTxnl: boolean;
  propertySurveyImageRPE: boolean;
  propertySurveyImageRPETxnl: boolean;
  propertySurveyImageLSR: boolean;
  propertySurveyImageLSRTxnl: boolean;
  propertySurveyImageSRI: boolean;
  propertySurveyImageSRITxnl: boolean;
  private residentialDtldRptL1: boolean;
  private AVMBasicResidentialRpt: boolean;
  private AVMEnhancedResidentialRpt: boolean;
  private indstrlDetailRpt: boolean;
  private commercialDetailRpt: boolean;
  private ICICommercialAndInstrlDetailRpt: boolean;
  private REBRpt: boolean;
  private corpNameAndAddrRpt: boolean;
  private AVMCmprbleRpt: boolean;
  residentialFloorAreaReport: boolean;
  private geowarehouse4YrPhaseInRpt: boolean;
  private REBResiDetailedRpt: boolean;
  private avmMarketSalesRpt: boolean;
  private enhancedDetailsRpt: boolean;
  private enhancedDetailsRptTxnl: boolean;
  private discountedICICommercialAndInstrlDetailRpt: boolean;
  private AVMResiCmprbleRpt: boolean;
  private NewResidentialFloorAreaReport: boolean;
  private ResidentialFloorAreaRangeReport: boolean;
  private ResidentialDetailedReportLevel2Range: boolean;
  private AVMResiCmprbleBndle250Fr10Rpt: boolean;
  private AVMMarketSalesBndle350Fr10Rpt: boolean;
  private REBResiBndle160Fr25Rpt: boolean;
  private REBResiBndle280Fr50Rpt: boolean;
  private ResiFlorAreaBndle100Fr25Rpt: boolean;
  private ResiFlorAreaBndle175Fr50Rpt: boolean;
  private REBResiDetailRptRange: boolean;
  private streetScapeImgLicense: boolean;
  private streetScapeImgLicenseTxnl: boolean;
  private streetScapeImgAndPivotVwLicense: boolean;
  private streetScapeImgAndPivotVwLicenseTxnl: boolean;
  private HoodQAddressReport: boolean;
  private HoodQDetailedReport: boolean;
  private HoodQSchoolReport: boolean;
  pybBuyersReport: boolean;
  pybEasmentReport: boolean;
  pybSellersReport: boolean;
  private AerialImageryReport: boolean;
  private AerialImageryReportTxnl: boolean;
  condoInfoReportAccess: boolean;
  CondoStatusCertificate: boolean;
  private GeoReportTopUpPackageAccess: boolean;
  private reportTopUp1000PropertyAccess: boolean;
  private reportTopUp2500PropertyAccess: boolean;
  private reportTopUp5000PropertyAccess: boolean;
  private mpacReportTopUp1000PropertyAccess: boolean;
  private mpacReportTopUp2500PropertyAccess: boolean;
  private mpacReportTopUp5000PropertyAccess: boolean;
  private mpacReportTopUp200PropertyAccess: boolean;
  private mpacReportTopUp500PropertyAccess: boolean;
  private reportTopUp200PropertyAccess: boolean;
  private reportTopUp500PropertyAccess: boolean;
  residentialRelocationReportLro: boolean;
  residentialRelocationReportLroTxnl: boolean;
  residentialRelocationReportMunicipality: boolean;
  residentialRelocationReportMunicipalityTxnl: boolean;
  residentialRelocationReportFsa: boolean;
  residentialRelocationReportFsaTxnl: boolean;
  CondoSuiteSqrFootage: boolean;
  CondoBuildingSqrFootage: boolean;
  private CondoBuildingSqrFootageTxnl: boolean;
  private CondoSqrFootageBundle: boolean;
  private reportTopUp1000MPSResidential: boolean;
  private reportTopUp2500MPSResidential: boolean;
  private reportTopUp5000MPSResidential: boolean;
  private reportTopUp1000MPSCommercial: boolean;
  private reportTopUp2500MPSCommercial: boolean;
  private reportTopUp5000MPSCommercial: boolean;
  isMpsUser: boolean = false;
  zoom2ItAerialPhoto: boolean = false;
  siteStructureShutter: boolean = false;
  hoodqSchoolCatchmentMap: boolean = false;
  hoodqPropertyReportShutterAccess: boolean = false;
  MPACSubscriptionEnabled: boolean = false;
  MPACSubscriptionType: string;
  MPACSubscriptionPremiumOrEnhanced: boolean = false;
  MPACSubscriptionPremium: boolean = false;
  MPACSubscriptionEnhanced: boolean = false;
  MPACSubscriptionBasic: boolean = false;
  private propertyReportCarouselAccess: boolean = false;
  buyerMobilityPropertyReportShutterAccess: boolean = false;
  assessedValueGraphAccess: boolean = false;
  TeranetLotDetails: boolean = false;
  GWHRSubscriptionAccess: boolean = false;
  googleStreetViewAccess: boolean = false;
  displayPartyTo: boolean = false;
  displayInstrumentNum: boolean = false;
  propertyLineAccess: boolean;
  easementIndicatorAccess: boolean = false;
  mpacEnhancedDetailsAccess: boolean = false;
  assessmentDetailsReport: boolean = false;
  iciAssessmentInformation: boolean = false;
  resFloorAreaBillable: boolean = false;
  PurviewPropertyTypeAccess: boolean = false;
  showHeatmapAverageSales: boolean = false;
  showHeatmapGrowthRate: boolean = false;
  showHeatmapMarketTurnover: boolean = false;
  comparableSalesMultiPolygonSearch: boolean = false;
  FBSAccess: boolean = false;
  mpsThematicMapping: boolean = false;
  mpsComparablesReportsAccess: boolean = false;
  reportTopUp100StandardPropertyAccess: boolean = false;
  reportTopUp100StandardPropertyTxnl: boolean = false;
  reportTopUp250StandardPropertyAccess: boolean = false;
  reportTopUp250StandardPropertyTxnl: boolean = false;
  reportTopUp500StandardPropertyAccess: boolean = false;
  reportTopUp500StandardPropertyTxnl: boolean = false;

  reportTopUp50ComparableSearchAccess: boolean = false;
  reportTopUp50ComparableSearchTxnl: boolean = false;
  reportTopUp100ComparableSearchAccess: boolean = false;
  reportTopUp100ComparableSearchTxnl: boolean = false;
  reportTopUp150ComparableSearchAccess: boolean = false;
  reportTopUp150ComparableSearchTxnl: boolean = false;
  reportTopUp200ComparableSearchAccess: boolean = false;
  reportTopUp200ComparableSearchTxnl: boolean = false;
  reportTopUp250ComparableSearchAccess: boolean = false;
  reportTopUp250ComparableSearchTxnl: boolean = false;



  heatmapRentalPercentGeo:boolean = false;
  heatmapRentalPercentRealm: boolean = false;
  // users with disabled accounts are not allowed to login while using regular process, but will login with Auth0/OKTA
  // this flag is for Auth0/OKTA users
  userActive: boolean = true;
  parcelRegisterWithTitleCheck: boolean = false;

  reportTopUp100MPACStandardPropertyAccess: boolean = false;
  reportTopUp100MPACStandardPropertyTxnl: boolean = false;
  reportTopUp250MPACStandardPropertyAccess: boolean = false;
  reportTopUp250MPACStandardPropertyTxnl: boolean = false;
  reportTopUp500MPACStandardPropertyAccess: boolean = false;
  reportTopUp500MPACStandardPropertyTxnl: boolean = false;

  comparableTopUp50MPACStandardPropertyAccess: boolean = false;
  comparableTopUp50MPACStandardPropertyTxnl: boolean = false;
  comparableTopUp100MPACStandardPropertyAccess: boolean = false;
  comparableTopUp100MPACStandardPropertyTxnl: boolean = false;
  comparableTopUp150MPACStandardPropertyAccess: boolean = false;
  comparableTopUp150MPACStandardPropertyTxnl: boolean = false;
  comparableTopUp200MPACStandardPropertyAccess: boolean = false;
  comparableTopUp200MPACStandardPropertyTxnl: boolean = false;
  comparableTopUp250MPACStandardPropertyAccess: boolean = false;
  comparableTopUp250MPACStandardPropertyTxnl: boolean = false;

  private initFlags(){
    Object.keys(this)?.forEach(key => {
      if (typeof this[key as keyof this] === 'boolean') {
        this[key as keyof this] = false as any;
      }
    });
  }

  initializeForUser(user: User) {
    this.initFlags();
    if (user.licenses?.GWH_RENEWAL?.licenseStatus) {
      this.GWHRSubscriptionExpired = user.licenses.GWH_RENEWAL.licenseStatus == 'E';
      //this.GWHRSubscriptionExpired = true;
    }
    this.userActive = !!user?.userActive;

    if (user.products) {
      user.products.forEach(product => {
        switch (product.productId) {

          case -101:// DISPLAY_PARTY_TO("dispPartyTo", -101l);
            this.displayPartyTo = true;
            break;

          case -102:// "DISPLAY_INSTRUMENT_NUM("dispINum", -102l)":
            this.displayInstrumentNum = true;
            break;

          case 126:
            this.assessmentDetailsReport = true;
            break;

          case 130:
            this.mpsComparablesReportsAccess = true;
            break;

          case 131:
            this.registrationTrackerAccess = true;
            break;

          //PROPERTY DETAILS REPORT
          case 141:// "PROPERTY DETAILS REPORT":
            this.propertyReportAccess = true;
            break;

          case 142:// "PROPERTY ASSESSMENT REPORT":
            this.propertyReportAssessmentAccess = true;
            break;

          case 145:// "DEMOGRAPHICS":
            this.propertyReportDemographicsAccess = true;
            break;

          case 148:// "GEMA PROPERTY ASSESSMENT REPORT":
            this.propertyReportAssessmentAccess = true;
            break;

          case 160: // "GWH Subscription Renewal License "
            this.geowarehouseSubscriptionRenewalAccess = true;
            this.geowarehouseSubscriptionRenewalAccessTxnl = product.transactional;
            if (user.licenses != null && user.licenses["GWH_RENEWAL"] != null) {
              if (user.licenses["GWH_RENEWAL"].licenseAvailableForRenew) {
                this.GWHRSubscriptionAccess = true;
              }
            }
            break;
          case 164: // "GWh Company Subscription Renewal License" - available only when user has access and in renewable period
            this.geowarehouseCompanySubscriptionRenewalAccess = true;
            this.geowarehouseCompanySubscriptionRenewalAccessTxnl = product.transactional;
            if (user.licenses != null && user.licenses["GWH_RENEWAL"] != null) {
              if (user.licenses["GWH_RENEWAL"].licenseAvailableForRenew) {
                this.GWHRSubscriptionAccess = true;
              }
            }
            break;

          case 400: // "SSO_DEEP_LINK_ACCESS"
            this.ssoDeepLinkAccess = true;
            break;

          // store products
          case 608:// "GEOWAREHOUSE STORE":
            this.geowarehouseStore = true;
            this.geowarehouseStoreTxnl = product.transactional;
            break;

          case 610: //GOOGLE_STREETVIEW
            this.googleStreetViewAccess = true;
            break;

          case 631: //HOODQ SCHOOL CATCHMENT MAP
            this.hoodqSchoolCatchmentMap = true;
            break;

          case 641:// "HOME VERIFIED REPORT SUBSCRIPTION":
            this.homeVerifiedReportSubscription = true;
            this.homeVerifiedReportSubscriptionTxnl = product.transactional;
            break;

          case 640:// "HOME VERIFIED REPORT":
            this.homeVerifiedReport = true;
            this.homeVerifiedReportTxnl = product.transactional;
            break;

          case 100:// "PARCEL REGISTER ACTIVE":
            this.parcelRegisterActive = true;
            this.parcelRegisterActiveTxnl = product.transactional;
            break;

          case 101:// "PARCEL REGISTER INACTIVE":
            this.parcelRegisterInActive = true;
            this.parcelRegisterInActiveTxnl = product.transactional;
            break;

          case 1207:// "PARCEL REGISTER TITLE CHECK":
            this.parcelRegisterWithTitleCheck = true;
            break;

          case 106:// "NEIGHBOURHOOD_SALES_REPORT":
            this.neighbourhoodSalesReport = true;
            this.neighbourhoodSalesReportTxnl = product.transactional;
            break;

          case 113:// "Aerial Imagery Report":
            this.zoom2ItAerialPhoto = true;
            this.AerialImageryReport = true;
            this.AerialImageryReportTxnl = product.transactional;
            break;

          case 170:// "AVM RANGE REPORT" aka, "PROPERTY INSIGHTS REPORT"
            this.avmRangeReport = true;
            this.avmRangeReportTxnl = product.transactional;
            break;

          case 171:// "PREMIUM AVM COMPARABLE REPORT" aka, "ENHANCED COMPARABLE REPORT"
            this.premiumAvmComparableReport = true;
            this.premiumAvmComparableReportTxnl = product.transactional;
            break;

          case 950:// "GEOWAREHOUSE REPORT PACKAGE": //top up bundle
            this.geowarehouseReportPackage = true;
            this.geowarehouseReportPackageTxnl = product.transactional;
            break;

          case 133:// "PLAN IMAGE":
            this.planImage = true;
            this.planImageTxnl = product.transactional;
            break;

          case 134:// "INSTRUMENT IMAGE":
            this.instrumentImage = true;
            this.instrumentImageTxnl = product.transactional;
            break;

          /* Property Survey images START */
          case 601:// "PROPERTY_SURVEY_IMAGE_JD_BARNES_LTD":
            this.propertySurveyImageJDB = true;
            this.propertySurveyImageJDBTxnl = product.transactional;
            break;

          case 602:// PROPERTY_SURVEY_IMAGE_RADY_PENTEK_EDWARD_SURVEYING_LTD
            this.propertySurveyImageRPE = true;
            this.propertySurveyImageRPETxnl = product.transactional;
            break;

          case 603:// PROPERTY_SURVEY_IMAGE_LAND_SURVEY_RECORDS_LSR
            this.propertySurveyImageLSR = true;
            this.propertySurveyImageLSRTxnl = product.transactional;
            break;

          case 604:// PROPERTY_SURVEY_IMAGE_SRI_SURVEY_RECORDS
            this.propertySurveyImageSRI = true;
            this.propertySurveyImageSRITxnl = product.transactional;
            break;

          /* Property Survey images END */

          case 703:// " TREB PROPERTY LINE ":
            this.propertyLineAccess = true;
            break;

          /* MPAC reports START */
          case 567:// "GEOWAREHOUSE RESIDENTIAL DETAILED REPORT (LEVEL 1)":
            this.residentialDtldRptL1 = true;
            this.residentialDtldRptL1Txnl = product.transactional;
            break;

          case 557:// "GEOWAREHOUSE RESIDENTIAL DETAILED REPORT (LEVEL 2)":
            this.residentialDtldRptL1 = true;
            this.residentialDtldRptL2Txnl = product.transactional;
            break;

          case 558:// " Geowarehouse AVM BASIC RESIDENTIAL REPORT":
            this.AVMBasicResidentialRpt = true;
            this.AVMBasicResidentialRptTxnl = product.transactional;
            break;

          case 559:// " AVM ENHANCED RESIDENTIAL REPORT":
            this.AVMEnhancedResidentialRpt = true;
            this.AVMEnhancedResidentialRptTxnl = product.transactional;
            break;

          case 560:// " INDUSTRIAL DETAILED REPORT":
            this.indstrlDetailRpt = true;
            this.indstrlDetailRptTxnl = product.transactional;
            break;

          case 561:// " COMMERCIAL DETAILED REPORT":
            this.commercialDetailRpt = true;
            this.commercialDetailRptTxnl = product.transactional;
            break;

          case 568:// " ICI COMMERCIAL AND INDUSTRIAL DETAILED REPORT":
            this.ICICommercialAndInstrlDetailRpt = true;
            this.ICICommercialAndInstrlDetailRptTxnl = product.transactional;
            break;

          case 562:// " GEOWAREHOUSE REB REPORT":
            this.REBRpt = true;
            this.REBRptTxnl = product.transactional;
            break;

          case 563:// " CORPORATE NAME AND ADDRESS REPORT":
            this.corpNameAndAddrRpt = true;
            this.corpNameAndAddrRptTxnl = product.transactional;
            break;

          case 564:// "AVM COMPARABLE REPORT":
            this.AVMCmprbleRpt = true;
            this.AVMCmprbleRptTxnl = product.transactional;
            break;

          case 565:// "RESIDENTIAL FLOOR AREA REPORT":
            this.residentialFloorAreaReport = true;
            this.resFloorAreaBillable = true;
            this.residentialFloorAreaReportTxnl = product.transactional;
            break;

          case 566:// "FOUR YEAR PHASE IN REPORT":
            this.geowarehouse4YrPhaseInRpt = true;
            this.geowarehouse4YrPhaseInRptTxnl = product.transactional;
            break;

          case 569:// "REB Residential Detail Report":
            this.REBResiDetailedRpt = true;
            this.REBResiDetailedRptTxnl = product.transactional;
            break;

          case 570:// "AVM MARKET SALES REPORT":
            this.avmMarketSalesRpt = true;
            this.avmMarketSalesRptTxnl = product.transactional;
            break;

          case 571:// "ENHANCED DETAILS REPORT":
            this.mpacEnhancedDetailsAccess = true
            this.enhancedDetailsRpt = true;
            this.enhancedDetailsRptTxnl = product.transactional;
            break;

          case 573:// "DISCOUNTED ICI COMMERCIAL AND INDUSTRIAL DETAILED REPORT":
            this.discountedICICommercialAndInstrlDetailRpt = true;
            this.discountedICICommercialAndInstrlDetailRptTxnl = product.transactional;
            break;

          case 574:// "AVM Residential Comparable Report":
            this.AVMResiCmprbleRpt = true;
            this.AVMResiCmprbleRptTxnl = product.transactional;
            break;

          case 575:// "New Residential Floor Area Report":
            this.NewResidentialFloorAreaReport = true;
            this.NewResidentialFloorAreaReportTxnl = product.transactional;
            break;
          case 582:// "Residential Floor Area Range Report":
            this.ResidentialFloorAreaRangeReport = true;
            this.ResidentialFloorAreaRangeReportTxnl = product.transactional;
            break;
          case 584:// "Residential Detailed Report Level 2 Range":
            this.ResidentialDetailedReportLevel2Range = true;
            this.ResidentialDetailedReportLevel2RangeTxnl = product.transactional;
            break;
          case 576:// "AVM Residential Comparable Report Bundle 250 For 10":
            this.AVMResiCmprbleBndle250Fr10Rpt = true;
            this.AVMResiCmprbleBndle250Fr10RptTxnl = product.transactional;
            break;

          case 577:// "AVM Market Sales Report Bundle 350 For 10":
            this.AVMMarketSalesBndle350Fr10Rpt = true;
            this.AVMMarketSalesBndle350Fr10RptTxnl = product.transactional;
            break;

          case 578:// "REB Residential Detail Report Bundle- 25 for $160":
            this.REBResiBndle160Fr25Rpt = true;
            this.REBResiBndle160Fr25RptTxnl = product.transactional;
            break;

          case 579:// "REB Residential Detail Report Bundle- 50 for $280":
            this.REBResiBndle280Fr50Rpt = true;
            this.REBResiBndle280Fr50RptTxnl = product.transactional;
            break;

          case 580:// "Residential Floor Area Report Bundle - 25 for $100":
            this.ResiFlorAreaBndle100Fr25Rpt = true;
            this.ResiFlorAreaBndle100Fr25RptTxnl = product.transactional;
            break;

          case 581:// "Residential Floor Area Report Bundle - 50 for $175":
            this.ResiFlorAreaBndle175Fr50Rpt = true;
            this.ResiFlorAreaBndle175Fr50RptTxnl = product.transactional;
            break;

          case 583:// "REB Residential Detail Report Range report":
            this.REBResiDetailRptRange = true;
            this.REBResiDetailRptRangeTxnl = product.transactional;
            break;
          /* MPAC reports END */

          case 609:// "COMPARABLES_SEARCH"
            this.isMpsUser = true;
            break;

          case 620:// " ICI ASSESSMENT INFORMATION ":
            this.iciAssessmentInformation = true;
            break;

          case 630:// MPS - THEMATIC MAPPING   (630)
            this.mpsThematicMapping = true;
            break;

          /* Streetscape images START */
          case 701:// "STREETSCAPE IMAGERY LICENSE":
            this.streetScapeImgLicense = true;
            this.streetScapeImgLicenseTxnl = product.transactional;
            break;

          case 702:// "STREETSCAPE IMAGERY AND PIVOT VIEW LICENSE":
            this.streetScapeImgAndPivotVwLicense = true;
            this.streetScapeImgAndPivotVwLicenseTxnl = product.transactional;
            break;
          /* Streetscape images END */

          /* HoodQ reports START */
          case 585:// "HoodQ Address Report":
            this.HoodQAddressReport = true;
            this.HoodQAddressReportTxnl = product.transactional;
            break;
          case 586:// "HoodQ Detailed Report":
            this.HoodQDetailedReport = true;
            this.HoodQDetailedReportTxnl = product.transactional;
            break;
          case 587:// "HoodQ School Report":
            this.HoodQSchoolReport = true;
            this.HoodQSchoolReportTxnl = product.transactional;
            break;
          /* HoodQ reports END */

          case 850: // Block Heatmap Average sales
            this.showHeatmapAverageSales = true;
            break;
          case 851: // Block HeatmapGrowth Rate
            this.showHeatmapGrowthRate = true;
            break;
          case 852: // Block Heatmap Market Turnover
            this.showHeatmapMarketTurnover = true;
            break;
          case 891: // COMPARABLES_ONLINE_MULTIPLE_POLYGON_SEARCH
            this.comparableSalesMultiPolygonSearch = true;
            break;
          case 1103:// "Easement Indicator":
            this.easementIndicatorAccess = true;
            break;
          case 1104:// "FBS Enhanced Imagery Access":
            this.FBSAccess = true;
            break;

          /* PYB reports START */
          case 1100:// "PYB BUYER'S REPORT":
            this.pybBuyersReport = true;
            this.pybBuyersReportTxnl = product.transactional;
            break;
          case 1101:// "PYB EASEMENT REPORT":
            this.pybEasmentReport = true;
            this.pybEasmentReportTxnl = product.transactional;
            break;
          case 1102:// "PYB SELLER'S REPORT":
            this.pybSellersReport = true;
            this.pybSellersReportTxnl = product.transactional;
            break;
          /* PYB reports END */

          case 1107:// "Purview Property Type":
            this.PurviewPropertyTypeAccess = true;
            break;

          case 605:// "CONDOMINIUM INFORMATION REPORT"
            this.condoInfoReportAccess = true;
            break;
          case 606:// "Condo Status Certificate - conduit":
            this.CondoStatusCertificate = true;
            this.CondoStatusCertificateTxnl = product.transactional;
            break;
          case 1108:// "GEO REPORT TOP-UP PACKAGE":
            this.GeoReportTopUpPackageAccess = true;
            this.topUpPackageReportTxnl = product.transactional;
            break;
          /* GEO reports END */

          /* GEO REPORT TOP-UP Products START */

          case 1111:// "Report Top-Up Package (1000 reports)"
            this.reportTopUp1000PropertyAccess = true;
            this.reportTopUp1000PropertyTxnl = product.transactional;
            break;
          case 1112:// "Report Top-Up Package (2,500 reports)"
            this.reportTopUp2500PropertyAccess = true;
            this.reportTopUp2500PropertyTxnl = product.transactional;
            break;
          case 1113:// "Report Top-Up Package (5,000 reports)"
            this.reportTopUp5000PropertyAccess = true;
            this.reportTopUp5000PropertyTxnl = product.transactional;
            break;
          case 1114:// "Report Top-Up Package with MPAC Data (1000 reports)"
            this.mpacReportTopUp1000PropertyAccess = true;
            this.mpacReportTopUp1000PropertyTxnl = product.transactional;
            break;
          case 1115:// "Report Top-Up Package with MPAC Data (2,500 reports)"
            this.mpacReportTopUp2500PropertyAccess = true;
            this.mpacReportTopUp2500PropertyTxnl = product.transactional;
            break;
          case 1116:// "Report Top-Up Package with MPAC Data (5,000 reports)"
            this.mpacReportTopUp5000PropertyAccess = true;
            this.mpacReportTopUp5000PropertyTxnl = product.transactional;
            break;
          case 1117: //COMPARABLES FEATURE
            this.comparablesFeatureAccess = true;
            break;
          case 1126:// "Report Top-Up Package with MPAC Data (200 reports)"
            this.mpacReportTopUp200PropertyAccess = true;
            this.mpacReportTopUp200PropertyTxnl = product.transactional;
            break;
          case 1127:// "Report Top-Up Package with MPAC Data (500 reports)"
            this.mpacReportTopUp500PropertyAccess = true;
            this.mpacReportTopUp500PropertyTxnl = product.transactional;
            break;
          case 1128:// "Report Top-Up Package (200 reports)"
            this.reportTopUp200PropertyAccess = true;
            this.reportTopUp200PropertyTxnl = product.transactional;
            break;
          case 1129:// "Report Top-Up Package (500 reports)"
            this.reportTopUp500PropertyAccess = true;
            this.reportTopUp500PropertyTxnl = product.transactional;
            break;
          /* GEO REPORT TOP-UP Products END */
          case 1119:// "LRO Residential Relocation Reports":
            this.residentialRelocationReportLro = true;
            this.residentialRelocationReportLroTxnl = product.transactional;
            break;
          case 1120:// "Municipality Residential Relocation Reports":
            this.residentialRelocationReportMunicipality = true;
            this.residentialRelocationReportMunicipalityTxnl = product.transactional;
            break;
          case 1121:// "FSA Residential Relocation Reports":
            this.residentialRelocationReportFsa = true;
            this.residentialRelocationReportFsaTxnl = product.transactional;
            break;
          case 1123:// "Condo suite square footage reports":
            this.CondoSuiteSqrFootage = true;
            this.CondoSuiteSqrFootageTxnl = product.transactional;
            break;
          case 1125:// "Condo suite square footage bundle":
            this.CondoSqrFootageBundle = true;
            this.CondoSqrFootageBundleTxnl = product.transactional;
            break;
          case 1139:// "Report Top-Up Package (1000 reports)"
            this.reportTopUp1000MPSResidential = true;
            this.reportTopUp1000MPSResidentialTxnl = product.transactional;
            break;
          case 1138:// "Report Top-Up Package (2,500 reports)"
            this.reportTopUp2500MPSResidential = true;
            this.reportTopUp2500MPSResidentialTxnl = product.transactional;
            break;
          case 1137:// "Report Top-Up Package (5,000 reports)"
            this.reportTopUp5000MPSResidential = true;
            this.reportTopUp5000MPSResidentialTxnl = product.transactional;
            break;
          case 1136:// "Report Top-Up Package (5,000 reports)"
            this.reportTopUp1000MPSCommercial = true;
            this.reportTopUp1000MPSCommercialTxnl = product.transactional;
            break;
          case 1135:// "Report Top-Up Package (5,000 reports)"
            this.reportTopUp2500MPSCommercial = true;
            this.reportTopUp2500MPSCommercialTxnl = product.transactional;
            break;
          case 1134:// "Report Top-Up Package (5,000 reports)"
            this.reportTopUp5000MPSCommercial = true;
            this.reportTopUp5000MPSCommercialTxnl = product.transactional;
            break;
          case 1109:// "HoodQ Property Report Shutter":
            this.hoodqPropertyReportShutterAccess = true;
            break;
          case 1110:// "PROPERTY REPORT CAROUSEL":
            this.propertyReportCarouselAccess = true;
            break;
          case 1118:// "BUYER MOBILITY PROPERTY REPORT SHUTTER":
            this.buyerMobilityPropertyReportShutterAccess = true;
            break;
          case 1122:// "ASSESSED VALUE GRAPH":
            this.assessedValueGraphAccess = true;
            break;
          case 1124:// "CONDO BUILDING SQUARE FOOTAGE REPORT":
            this.CondoBuildingSqrFootage = true;
            this.CondoBuildingSqrFootageTxnl = product.transactional;
            break;
          case 1130:// "MPAC BASIC PACKAGE ":
            this.MPACSubscriptionEnabled = true;
            this.MPACSubscriptionType = "BASIC";
            this.MPACSubscriptionBasic = true;
            break;
          case 1131:// "MPAC PREMIUM PACKAGE ":
            this.MPACSubscriptionEnabled = true;
            this.MPACSubscriptionType = "PREMIUM";
            this.MPACSubscriptionPremiumOrEnhanced = true;
            this.MPACSubscriptionPremium = true;
            break;
          case 1132:// "MPAC ENHANCED  PACKAGE ":
            this.MPACSubscriptionEnabled = true;
            this.MPACSubscriptionType = "ENHANCED";
            this.MPACSubscriptionPremiumOrEnhanced = true;
            this.MPACSubscriptionEnhanced = true;
            break;
          case 1133:// "Teranet Lot Details":
            this.TeranetLotDetails = true;
            break;
          case 1140:// "Teranet Lot Details":
            this.siteStructureShutter = true;
            break;

          case 1250:// "Report Top-Up Package (100 reports)"
            this.reportTopUp100StandardPropertyAccess = true;
            this.reportTopUp100StandardPropertyTxnl = product.transactional;
            break;
          case 1251:// "Report Top-Up Package (250 reports)"
            this.reportTopUp250StandardPropertyAccess = true;
            this.reportTopUp250StandardPropertyTxnl = product.transactional;
            break;
          case 1252:// "Report Top-Up Package (500 reports)"
            this.reportTopUp500StandardPropertyAccess = true;
            this.reportTopUp500StandardPropertyTxnl = product.transactional;
            break;

          case 1260:// "Comparables Report Top-Up Package (50 reports)"
            this.reportTopUp50ComparableSearchAccess = true;
            this.reportTopUp50ComparableSearchTxnl = product.transactional;
            break;
          case 1261:// "Comparables Report Top-Up Package (100 reports)"
            this.reportTopUp100ComparableSearchAccess = true;
            this.reportTopUp100ComparableSearchTxnl = product.transactional;
            break;
          case 1262:// "Comparables Report Top-Up Package (150 reports)"
            this.reportTopUp150ComparableSearchAccess = true;
            this.reportTopUp150ComparableSearchTxnl = product.transactional;
            break;
          case 1263:// "Comparables Report Top-Up Package (200 reports)"
            this.reportTopUp200ComparableSearchAccess = true;
            this.reportTopUp200ComparableSearchTxnl = product.transactional;
            break;
          case 1264:// "Comparables Report Top-Up Package (250 reports)"
            this.reportTopUp250ComparableSearchAccess = true;
            this.reportTopUp250ComparableSearchTxnl = product.transactional;
            break;
          case 1273:// "Heatmap Average Rental access for Geo"
            this.heatmapRentalPercentGeo = true;
            break;
          case 1274:// "Heatmap Average Rental access for Realm"
            this.heatmapRentalPercentRealm = true;
            break;

          case 1265:// "Report Top-Up Package (100 reports) MPAC"
            this.reportTopUp100MPACStandardPropertyAccess = true;
            this.reportTopUp100MPACStandardPropertyTxnl = product.transactional;
            break;
          case 1266:// "Report Top-Up Package (250 reports) MPAC"
            this.reportTopUp250MPACStandardPropertyAccess = true;
            this.reportTopUp250MPACStandardPropertyTxnl = product.transactional;
            break;
          case 1267:// "Report Top-Up Package (500 reports) MPAC"
            this.reportTopUp500MPACStandardPropertyAccess = true;
            this.reportTopUp500MPACStandardPropertyTxnl = product.transactional;
            break;
          case 1268:// "Comparable Top-Up Package (50 reports) MPAC"
            this.comparableTopUp50MPACStandardPropertyAccess = true;
            this.comparableTopUp50MPACStandardPropertyTxnl = product.transactional;
            break;
          case 1269:// "Comparable Top-Up Package (100 reports) MPAC"
            this.comparableTopUp100MPACStandardPropertyAccess = true;
            this.comparableTopUp100MPACStandardPropertyTxnl = product.transactional;
            break;
          case 1270:// "Comparable Top-Up Package (150 reports) MPAC"
            this.comparableTopUp150MPACStandardPropertyAccess = true;
            this.comparableTopUp150MPACStandardPropertyTxnl = product.transactional;
            break;
          case 1271:// "Comparable Top-Up Package (200 reports) MPAC"
            this.comparableTopUp200MPACStandardPropertyAccess = true;
            this.comparableTopUp200MPACStandardPropertyTxnl = product.transactional;
            break;
          case 1272:// "Comparable Top-Up Package (250 reports) MPAC"
            this.comparableTopUp250MPACStandardPropertyAccess = true;
            this.comparableTopUp250MPACStandardPropertyTxnl = product.transactional;
            break;

          default:
            break;
        }
      })
    }
  }

  get hasTopUpAccess(): boolean {
    return ((this.reportTopUp1000PropertyAccess)
      || (this.reportTopUp2500PropertyAccess)
      || (this.reportTopUp5000PropertyAccess)
      || (this.reportTopUp100StandardPropertyAccess)
      || (this.reportTopUp250StandardPropertyAccess)
      || (this.reportTopUp500StandardPropertyAccess)
      || (this.mpacReportTopUp1000PropertyAccess)
      || (this.mpacReportTopUp2500PropertyAccess)
      || (this.mpacReportTopUp5000PropertyAccess)
      || (this.reportTopUp100MPACStandardPropertyAccess)
      || (this.reportTopUp250MPACStandardPropertyAccess)
      || (this.reportTopUp500MPACStandardPropertyAccess)
      || (this.comparableTopUp50MPACStandardPropertyAccess)
      || (this.comparableTopUp100MPACStandardPropertyAccess)
      || (this.comparableTopUp150MPACStandardPropertyAccess)
      || (this.comparableTopUp200MPACStandardPropertyAccess)
      || (this.comparableTopUp250MPACStandardPropertyAccess));
  }
  /*Use this to disable access for various sections of the app*/
  get restrictedAccess(): boolean {
    return this.GWHRSubscriptionExpired || !this.userActive;
  }

  get restrictedAccessPointerEvents(): string {
    return this.restrictedAccess ? 'none' : 'all';
  }

  get hasAccessToComparableSearch(): boolean {
    return this.neighbourhoodSalesReport && this.comparablesFeatureAccess;
  }
}
