import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AssessmentSelectionData } from "./assessment-selection-data";
import { PropertyReportSearchService } from "../../../../shared/service/search/property-report-search.service";
import { lastValueFrom } from "rxjs";
import { PropertyReportService } from "../../../../shared/service/property-report.service";
import { StreetViewUtility } from "../../../../shared/utility/street-view.utility";
import { LoggerService } from '../../../../shared/service/log/logger.service';

@Component({
  selector: 'gema3g-assessment-selection-dialog',
  templateUrl: './assessment-selection-dialog.component.html',
  styleUrls: ['./assessment-selection-dialog.component.scss']
})
export class AssessmentSelectionDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AssessmentSelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public selectionData: AssessmentSelectionData,
    private propertyService : PropertyReportSearchService,
    private propertyReportService: PropertyReportService,
    private loggerService: LoggerService
  ) {

  }

  @ViewChild('streetViewDiv', { static: false }) streetViewElementRef: ElementRef;
  streetViewMap: google.maps.Map;
  streetViewService = new google.maps.StreetViewService();
  isLoading: boolean = true;

  initializeMap = async() => {

    const streetViewPoint = await lastValueFrom(this.propertyReportService.getStreetViewPoint(this.selectionData?.selectedProperty?.pin));
    let centroid = streetViewPoint?.projection;
    if(centroid) {
      let coordinates = new google.maps.LatLng(centroid.latitude, centroid.longitude);

      let mapOptions: google.maps.MapOptions = {
        center: coordinates,
        zoom: 15,
        disableDefaultUI: true
      };

      this.streetViewMap = new google.maps.Map(this.streetViewElementRef.nativeElement, mapOptions);

      //let heading = StreetViewUtility.computeHeading(streetViewPoint);
      let panorama: google.maps.StreetViewPanorama = this.streetViewMap.getStreetView();
      if(panorama) {
        let me = this;
        this.streetViewService.getPanorama( {location : coordinates}, function (data, status) {
            me.loggerService.logInfo('streetview service status', status);

            if (status == google.maps.StreetViewStatus.OK) {
              panorama.setPosition(coordinates);
              panorama.set('zoom', 0.2);
              panorama.set('cacheHeading',false);
              panorama.set('addressControl', false);
              panorama.set('showControls', false);
              panorama.set('panControl', false);
              panorama.set('zoomControl', false);
              panorama.set('enableCloseButton', false);
              panorama.set('imageDateControl',false);
              panorama.set('navigationControl', false);
              panorama.set('linksControl', false);
              panorama.set('pitch', 0);
              panorama.set('homePageGSVOverlayEnabled',true);
              panorama.setPov({
                heading: StreetViewUtility.calculateHeading(data?.location?.latLng?.lat(), data?.location?.latLng?.lng(), streetViewPoint.centroid.latitude, streetViewPoint.centroid.longitude ),
                pitch: 0,
              });
              panorama.setVisible(true);
            } else {
              panorama.setVisible(false);
            }
          }
        );
      }
    }
  }

  get streetViewLoaded() : boolean {
    if (!this.streetViewMap?.getStreetView()){
      return true;
    }
    return this.streetViewMap?.getStreetView()?.getVisible();
  }


  arnSelected(arn: string) {
    this.dialogRef.close({selectedArn : arn});
  }

  async ngOnInit() {
    await this.initializeMap();
  }
}
