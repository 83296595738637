import { Centroid } from "./centroid";
import { BaseModel } from "../base-model";

export class StreetViewPoint extends BaseModel {
  constructor(streetViewPoint?: StreetViewPoint) {
    super(streetViewPoint);
    if (streetViewPoint) {
      if (streetViewPoint.centroid) {
        this.centroid = new Centroid(streetViewPoint.centroid.latitude, streetViewPoint.centroid.longitude);
      }
      if (streetViewPoint.projection) {
        this.projection = new Centroid(streetViewPoint.projection.latitude, streetViewPoint.projection.longitude);
      }
    }
  }

  centroid: Centroid;
  pin: string;
  projection: Centroid;
}