import { MapTileEnum } from "../../../../core/enum/map-tile-enum";
import { TileBase } from "./tile.base";

export class AssessmentTile extends TileBase implements google.maps.MapType {

    constructor(public override available: boolean, public override tileServerHttpProtocol: string | undefined, public override tileServerUrl: string | undefined, public override titleAuthenticationKey: string | undefined) {
        super(available, tileServerHttpProtocol, tileServerUrl, titleAuthenticationKey);

        this.name = MapTileEnum.ASSESSMENT_TILE;
        this.minZoom = AssessmentTile.MIN_ZOOM;
        this.maxZoom = AssessmentTile.MAX_ZOOM;
    }

    alt: string | null;
    projection: google.maps.Projection | null;
    radius: number;

    public static MIN_ZOOM: number = 14;
    public static MAX_ZOOM: number = 21;
    tileSize: google.maps.Size = this.defaultTileSize;

    getTile(tileCoordinates: google.maps.Point, zoomLevel: number, ownerDocument: Document): Element {

        if (this.isDisplayPermitted() && this.isWithinAllowableZoomLevels(this.minZoom, this.maxZoom, zoomLevel)) {
            return this.getMapTile(this.tileSize, tileCoordinates, zoomLevel, ownerDocument, MapTileEnum.ASSESSMENT_TILE);
        } else {
            return this.getEmptyMapTile(this.tileSize, tileCoordinates, zoomLevel, ownerDocument, MapTileEnum.ASSESSMENT_TILE);
        }

        //throw new Error("Method not implemented.");
    }

    releaseTile(tile: Element): void {
        //throw new Error("Method not implemented.");
    }

}
