import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InsightsReportDialogData } from './insights-report-dialog-data';

@Component({
  selector: 'gema3g-insights-report-dialog',
  templateUrl: './insights-report-dialog.component.html',
  styleUrl: './insights-report-dialog.component.scss'
})
export class InsightsReportDialogComponent {
  constructor(public dialogRef: MatDialogRef<InsightsReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InsightsReportDialogData) {
  }
}
