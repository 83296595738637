@defer (on timer(20s)) {
<section class="hoodq-container">
    <iframe #hoodqIframe id="hoodqIframe" [src]="hoodqUrl | safeUrl" frameborder="0" width="100%"
        height="100%"></iframe>

    <!--
    <div class="school-overlay-back-btn">
        <div class="close-school-map">
            <img id="hqm-back-icon" src="assets/img/hoodq/icon_map_schoolview_back_orange.png" (click)="onHoodQMapClosed()">
        </div>
    </div>
    -->
</section>
} @placeholder {
  <section class="hoodq-container"><span class="loading">Loading HoodQ school map...</span></section>
}
