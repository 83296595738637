import { Pipe, PipeTransform } from "@angular/core";
import {DataService} from "../service/data.service";
@Pipe(
  {
    standalone: true,
    name: 'prefixWithDollarSign'
  }
)
export class PrefixWithDollarSignPipe implements PipeTransform {

  transform(input: any) {
    if (typeof input !== 'string' && typeof input !== 'number') {
      return input;
    }
    if (input === DataService.NOT_APPLICABLE){
      return DataService.NOT_APPLICABLE;
    }
    const inputStr = input.toString();
    return inputStr.startsWith('$') ? inputStr : '$' + inputStr;
  }
}
