import { MapTileEnum } from "../../../../core/enum/map-tile-enum";
import { PlanSurvey } from "../../../../core/model/plan/plan-survey";
import { TileBase } from "./tile.base";

export class SurveyPlanTile extends TileBase implements google.maps.MapType {

    constructor(public override available: boolean, public override tileServerHttpProtocol: string | undefined, public override tileServerUrl: string | undefined, public override titleAuthenticationKey: string | undefined, planSurvey: PlanSurvey) {
        super(available, tileServerHttpProtocol, tileServerUrl, titleAuthenticationKey);
        this.planSurvey = planSurvey;

        this.name = MapTileEnum.LRO_SURVEY_PLAN_TILE;
        this.minZoom = SurveyPlanTile.MIN_ZOOM;
        this.maxZoom = SurveyPlanTile.MAX_ZOOM;
    }

    alt: string | null;
    projection: google.maps.Projection | null;
    radius: number;

    public static MIN_ZOOM: number = 15;
    public static MAX_ZOOM: number = 21;
    tileSize: google.maps.Size = this.defaultTileSize;
    planSurvey: PlanSurvey;

    getTile(tileCoordinates: google.maps.Point, zoomLevel: number, ownerDocument: Document): Element {

        if (this.isDisplayPermitted() && this.isWithinAllowableZoomLevels(this.minZoom, this.maxZoom, zoomLevel)) {
            let extraParams = new Map<string, any>();
            extraParams.set('lroNum', this.planSurvey.lro);
            extraParams.set('companyBeid', this.planSurvey.companyBEId);
            extraParams.set('imageId', this.planSurvey.mapItData)

            return this.getMapTile(this.tileSize, tileCoordinates, zoomLevel, ownerDocument, MapTileEnum.LRO_SURVEY_PLAN_TILE, extraParams);
        } else {
            return this.getEmptyMapTile(this.tileSize, tileCoordinates, zoomLevel, ownerDocument, MapTileEnum.LRO_SURVEY_PLAN_TILE);
        }

        //throw new Error("Method not implemented.");
    }

    releaseTile(tile: Element): void {
        //throw new Error("Method not implemented.");
    }

}
