import {PdfReportRequestReportConfig} from "./pdf-report-request-report-config";
import {PdfReportRequestParam} from "./pdf-report-request-param";
import {BaseModel} from "../base-model";

export class PdfReportRequestPropRequest extends BaseModel{
  constructor(pin?: string, arn?: string) {
    super();
    if (pin) {
      this.pin = pin;
    }
    if (arn) {
      this.arn = arn;
    }
  }

  pin: string;
  arn: string;
  reportConfig: PdfReportRequestReportConfig;
}
