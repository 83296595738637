export class ConduitData{

  constructor(conduitData? : ConduitData) {
    if (conduitData) {
      for (let property in conduitData) {
        if (conduitData.hasOwnProperty(property)) {
          // @ts-ignore
          this[property] = conduitData[property];
        }
      }
    }
  }

  conduitModificationDate: Date;
  levelNum: string;
  unitId: number;
  unitLegalLevel: string;
  unitName: string;
  unitNum: string;
  unitPin: string;
  unitType: string;
}
