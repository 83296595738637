import {ComparableSalesReportCreationParam} from "./comparable-sales-report-creation-param";
import {PinOrArn} from "../../property/pin-or-arn";
import {BaseModel} from "../../base-model";

export class ComparableSalesReportCreationRequest extends BaseModel{
  constructor(csrcr?: ComparableSalesReportCreationRequest) {
    super(ComparableSalesReportCreationRequest);
    if (csrcr) {
      this.reportParam = new ComparableSalesReportCreationParam(csrcr.reportParam);
      this.comparablePins = csrcr.comparablePins.map(pa => new PinOrArn(pa.pin, pa.arn));
      this.subjectPin = new PinOrArn(csrcr.subjectPin?.pin, csrcr.subjectPin?.arn);
    }
  }

  reportParam: ComparableSalesReportCreationParam;
  comparablePins: PinOrArn[] = [];
  subjectPin: PinOrArn;
}
