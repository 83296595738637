<h1 id="warning-dialog-title" class="font-normal" mat-dialog-title>
  {{ data.title }}
</h1>

<div id="warning-dialog-content" class="dialog-content" mat-dialog-content>
  @if (data.content?.length) {
    <div class="flex items-center justify-content-center min-h-[150px]">
      @for (item of data.content; track $index) {
        <div class="flex items-center">
          <mat-label id="warning-label-{{item}}">{{ item }}</mat-label>
        </div>
      }
    </div>
  }
  @if (data.innerHtmlContent) {
    <div>
      <mat-label id="warning-label-inner" [innerHtml]="data.innerHtmlContent | safeHtml"></mat-label>
    </div>
  }
  <div>
    <div class="mt-4">
      <p-progressBar mode="indeterminate" [style]="{'height': '6px'}" />
    </div>
  </div>
</div>

@if (data.buttonText?.length) {
  <mat-divider></mat-divider>

  <div align="end" mat-dialog-actions>
    <button id="warning-second-btn"
            type="button"
            mat-button
            mat-dialog-close
            class="main-btn mat-orange"
    >
      {{ data.buttonText }}
    </button>
  </div>
}

