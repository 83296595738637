import { SiteDetailInfo } from "./site-detail-info";
import {DataService} from "../../../../shared/service/data.service";

export class SiteDetail {

  constructor(siteDetail? : SiteDetail) {

    if (siteDetail) {
      for (let property in siteDetail) {
        if (siteDetail.hasOwnProperty(property)) {
          // @ts-ignore
          this[property] = siteDetail[property];
        }
      }

      if(Array.isArray(siteDetail.industrialInfo)){
        this.industrialInfo = siteDetail.industrialInfo.map(value => {return new SiteDetailInfo(value);});
      }

      if(Array.isArray(siteDetail.commercialInfo)){
        this.commercialInfo = siteDetail.commercialInfo.map(value => {return new SiteDetailInfo(value);});
      }
    }

  }

  arn: string;
  propertyAddress: string;
  unitNumber: string;
  municipality: string;
  propertyType: string;
  legalDescription: string;
  corporateName: string;
  corporateMailingAddress: string;
  lastValidSaleDate: string;
  lastValidSaleAmount: string;
  totalLandValue: string;
  totalLandValueBaseYear: string;
  yearBuilt: string;
  propertyTypeCode: string;
  zoning: string;
  industrialInfo: SiteDetailInfo[];
  commercialInfo: SiteDetailInfo[];

  get isResidentialByPropTypeCode(): boolean{
    return this.propertyTypeCode?.charAt(0) == DataService.mpacPropertyTypeCodeStartsWith;
  }
}
