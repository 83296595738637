import { Component, ElementRef, EventEmitter, inject, OnInit, Output, ViewChild } from '@angular/core';
import { UserService } from '../../../shared/service/user.service';
import { LoggerService } from '../../../shared/service/log/logger.service';
import { RegistrationTrackerService } from '../../../shared/service/registration-tracker.service';
import { lastValueFrom, takeUntil, UnaryFunction } from 'rxjs';
import { RataReportTypes } from '../../../core/model/rata/report-types';
import { BaseUnsubscribe } from '../../../core/component/base-unsubscribe/base-unsubscribe';
import { User } from '../../../core/model/user/user';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import * as _ from 'lodash';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RataReportModels } from '../../../core/model/rata/report-models';
import { RataReportPeriods } from '../../../core/model/rata/reporting-periods';
import { RataSearchResponse } from '../../../core/model/rata/search-response';
import { RataSearchRequest } from '../../../core/model/rata/search-request';
import { RataReportRecord } from '../../../core/model/rata/report-record';
import { RataPii } from '../../../core/model/rata/pii';
import { RataSortKeyEnum } from '../../../core/enum/registration-tracker-sort-key.enum';
import { RataReportTypeEnum } from '../../../core/model/rata/report-type.enum';
import { RataReportDropDownKeyEnum } from '../../../core/enum/registration-tracker-report-dropdown-key.enum';
import { RataFilterKeyEnum } from '../../../core/enum/registration-tracker-filter-key.enum';
import { RataFilterCriteriaResponse } from '../../../core/model/rata/filter-criteria-response';
import { RataFilterErrorStateMatcher } from '../filter-error-state-matcher';
import { RataFilterCriteriaInstrumentType } from '../../../core/model/rata/filter-criteria-instrument-type';
import { RataFilterCriteriaLro } from '../../../core/model/rata/filter-criteria-lro';
import { CustomValidatorService } from '../../../shared/service/custom-validator.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from '../../../shared/service/data.service';
import { defaultErrorMatSnackBarConfig, defaultIndefiniteErrorMatSnackBarConfig, defaultMatSnackBarConfig } from '../../../shared/constant/constants';
import { Router } from '@angular/router';
import { MatOptionSelectionChange } from '@angular/material/core';
import { FileUtility } from '../../../shared/utility/file.utility';
import { MatSliderDragEvent } from '@angular/material/slider';
import { RataReportPeriod } from '../../../core/model/rata/reporting-period';
import { MediumDatePipe } from '../../../shared/pipe/medium-date.pipe';
import { RataReportType } from 'src/app/core/model/rata/report-type';
import { RataReportModel } from 'src/app/core/model/rata/report-model';
import { DateUtilityService } from 'src/app/shared/utility/date.utility';
import { ErrorUtil } from "../../../shared/service/error.util";
import { faCircleXmark, faArrowDown, faDownload, faFileArrowDown, faFileImport, faFilter } from '@fortawesome/free-solid-svg-icons';

declare var moment: any;

@Component({
  selector: 'gema3g-registration-tracker-main',
  templateUrl: './registration-tracker-main.component.html',
  styleUrls: ['./registration-tracker-main.component.scss'],
  providers: [MediumDatePipe]
})
export class RegistrationTrackerMainComponent extends BaseUnsubscribe implements OnInit {

  constructor() {
    super();
  }

  private loggerService = inject(LoggerService);
  private router = inject(Router);
  private userService = inject(UserService);
  private mediumDatePipe = inject(MediumDatePipe);
  private _snackBar = inject(MatSnackBar);
  private rataService = inject(RegistrationTrackerService);
  private customValidatorService = inject(CustomValidatorService);
  private fileUtility = inject(FileUtility);
  private dateUtility = inject(DateUtilityService);

  @ViewChild('rataSideNavPIIContainer') piiSideBar: MatSidenav;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('reportTypePopover') reportTypePopover: any;
  @ViewChild('reportModelPopover') reportModelPopover: any;
  @ViewChild('reportPeriodPopover') reportPeriodPopover: any;
  @ViewChild("copyPinPopOver") copyPinPopOver: any;
  //@ViewChild('primeNgPaginator', {static: false}) primeNgPaginator: Paginator;

  RSKE = RataSortKeyEnum;
  RFKE = RataFilterKeyEnum;
  RRDDKE = RataReportDropDownKeyEnum;
  user: User;
  showRunReport: boolean = false;
  showDescription: boolean = true;
  showSearchResults: boolean = false;
  showPii: boolean = false;
  showFilter: boolean = false;
  openFilter: boolean = false;
  reportRunning: boolean = false;
  reportTypes: RataReportTypes = {} as RataReportTypes;
  reportModels: RataReportModels = {} as RataReportModels;
  reportPeriods: RataReportPeriods = {} as RataReportPeriods;
  selectedReportTypeObj: RataReportType;
  selectedReportType: string;
  selectedReportTypeToolTip: string = 'Select a report type';
  selectedReportModel: RataReportModel | undefined;
  selectedReportModelToolTip: string = 'Select a report model';
  selectedReportPeriod: RataReportPeriod | undefined;
  selectedReportPeriodToolTip: string = 'Select a report period';
  selectedProcessId: string;
  selectedPii: RataPii;
  selectedLroFilter: RataFilterCriteriaLro | null;
  selectedInstrumentFilter: RataFilterCriteriaInstrumentType | null;
  selectedChargeInstrumentFilter: RataFilterCriteriaInstrumentType | null;
  selectedTransferInstrumentFilter: RataFilterCriteriaInstrumentType | null;
  selectedRegistrationDateFilterFormatted: string | null;
  selectedChargeRegistrationDateFilterFormatted: string | null;
  selectedTransferRegistrationDateFilterFormatted: string | null;
  selectedRecordIds: number[];
  searchResponse: RataSearchResponse;
  displayedColumns: string[];
  dataSource = new MatTableDataSource<RataReportRecord>();
  filterCriteria: RataFilterCriteriaResponse;
  filterForm: FormGroup;
  filterErrorStateMatcher = new RataFilterErrorStateMatcher();
  filterSummary: string | null;
  FILTER_DATE_FORMAT: string = 'MM-DD-YYYY';
  REPORT_DOWNLOAD_DATE_FORMAT: string = 'MMM-DD-YYYY';
  reportDropDownForm: FormGroup;
  saveRecordsForm: FormGroup;
  sort: MatSort;
  piiSideBarMode: MatDrawerMode = 'side';
  piiBusy: boolean;
  pin: string;
  copiedPin: string = "Copied";
  maxCalendarDate = new Date();
  static SORT_ASCENDING: SortDirection = 'asc';
  static SORT_DESCENDING: SortDirection = 'desc';
  currentSortColumn: string = RataSortKeyEnum.PIN.toString();
  currentSortDirection: SortDirection = RegistrationTrackerMainComponent.SORT_ASCENDING;
  lastSortColumn: string = this.currentSortColumn;
  lastSortDirection: SortDirection = this.currentSortDirection;
  lastSearchRequest: RataSearchRequest;
  pageEvent: PageEvent;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];
  hidePageSize = false;
  showPageSizeOptions = false;
  showFirstLastButtons = true;

  pageNumbers: number[];
  totalRecords: number;
  totalPages: number;
  rowsPerPage: number;
  currentPageNumber: number;
  processOnPageChangeEvent: boolean = true;
  first: number = 0;
  rows: number = 0;
  faCircleXmark = faCircleXmark;
  faArrowDown = faArrowDown;
  faDownload = faDownload;
  faFileArrowDown = faFileArrowDown;
  faFileImport = faFileImport;
  faFilter = faFilter;
  
  reset($event: any) {
    //this.paginator.changePageToFirst($event);
    //this.paginator.changePage(7);
  }

  getReportTypes = async (businessEntityId: string) => {
    try {

      if (businessEntityId != 'undefined') {
        this.showRunReport = false;

        this.setReportDropDownFormValue(RataReportDropDownKeyEnum.REPORT_TYPE, null);
        this.setReportDropDownFormValue(RataReportDropDownKeyEnum.REPORT_MODEL, null);
        this.setReportDropDownFormValue(RataReportDropDownKeyEnum.REPORT_PERIOD, null);

        this.reportTypes.reportTypes = [];
        this.reportModels.models = [];
        this.reportPeriods.reportingPeriods = [];

        this.reportTypes = await lastValueFrom(this.rataService.getReportTypes(businessEntityId), { defaultValue: new RataReportTypes() });
        this.loggerService.logDebug(`report types for beid ${businessEntityId} refreshed`, this.reportTypes);

        if (!this.reportTypes.isReportTypesExist()) {
          this.showError(DataService.RATA_NO_REPORT_TYPES_FOUND, true);
        }
      }

    } catch (e) {
      this.loggerService.logError(e);
      this.showError(ErrorUtil.RATA_GENERAL_ERROR_OCCURRED, false);

    } finally {
    }
  }

  getReportModels = async (businessEntityId: string, reportType: RataReportType) => {
    try {

      this.showRunReport = false;

      this.setReportDropDownFormValue(RataReportDropDownKeyEnum.REPORT_MODEL, null);
      this.setReportDropDownFormValue(RataReportDropDownKeyEnum.REPORT_PERIOD, null);

      this.reportModels.models = [];
      this.reportPeriods.reportingPeriods = [];
      this.selectedReportModelToolTip = 'Select a report model';
      this.selectedReportPeriodToolTip = 'Select a report period';

      this.reportModels = await lastValueFrom(this.rataService.getReportModels(businessEntityId, reportType.name), { defaultValue: new RataReportModels() });

      //remove duplicate models
      let tempModels : RataReportModel[] = [];
      const highestModels: { [key: string]: number } = {};
      let modelNames : String[] = [];
      for (const model of this.reportModels.models) {
        highestModels[model.name] = Number(model.modelId);
        this.reportModels.models.forEach(({name, modelId}) => {
          if ((name == model.name) && (highestModels[name] < Number(modelId))) {
            highestModels[name] = Number(modelId);
          }
        });

        if (!modelNames.includes(model.name)) {
          modelNames.push(model.name);
        }
      }
      modelNames = [];
      for (const model of this.reportModels.models) {
        if ((Number(model.modelId) == highestModels[model.name]) &&(!modelNames.includes(model.name))) {
          tempModels.push(model);
          modelNames.push(model.name);
        }
      }

      this.reportModels.models = tempModels;

      this.loggerService.logDebug(`report models for beid ${businessEntityId}, report type ${reportType.name} refreshed`, this.reportModels);

      if (!this.reportModels.isReportModelsExist()) {
        this.showError(`${DataService.RATA_NO_REPORT_MODELS_FOUND_PREFIX} ${this.selectedReportTypeObj.description}.`, true);
      }

    } catch (e) {
      this.loggerService.logError(e);
      this.showError(ErrorUtil.RATA_GENERAL_ERROR_OCCURRED, false);

    } finally {
    }
  }

  getReportPeriods = async (model: RataReportModel) => {
    try {

      this.setReportDropDownFormValue(RataReportDropDownKeyEnum.REPORT_PERIOD, null);

      this.selectedReportPeriod = undefined;
      this.reportPeriods.reportingPeriods = [];

      this.reportPeriods = await lastValueFrom(this.rataService.getReportPeriods(model.modelId), { defaultValue: new RataReportPeriods() });
      this.loggerService.logDebug(`report periods for model id ${model.modelId} refreshed`, this.reportPeriods);

      let listOfDates: string[] = [];
      const highestPeriods: { [key: string]: number } = {};
      let tempPeriods: RataReportPeriod[] = [];

      for (const report of this.reportPeriods.reportingPeriods) {
        let fromYear = this.dateUtility.getYear(report.reportingPeriodStart);
        let toYear  = this.dateUtility.getYear(report.reportingPeriodEnd);
        let fromMonth = this.dateUtility.getMonth(report.reportingPeriodStart);
        let toMonth = this.dateUtility.getMonth(report.reportingPeriodEnd);
        let fromDay = this.dateUtility.getDate(report.reportingPeriodStart);
        let toDay = this.dateUtility.getDate(report.reportingPeriodEnd);

        let fromDate = fromYear + '/' + fromMonth + '/' + fromDay;
        let toDate = toYear + '/' + toMonth + '/' + toDay;
        let date = fromDate + '-' + toDate;

        highestPeriods[date] = Number(report.processId);
        this.reportPeriods.reportingPeriods.forEach(({processId}) => {
          if (highestPeriods[date] < Number(processId)) {
            highestPeriods[date] = Number(processId);
          }
        });

        if (!listOfDates.includes(date)) {
          listOfDates.push(date);
          tempPeriods.push(report);
        }
      }

      this.reportPeriods.reportingPeriods = tempPeriods;

      if (!this.reportPeriods.isReportPeriodsExist()) {
        this.showError(`${DataService.RATA_NO_REPORT_PERIODS_FOUND_PREFIX} ${this.selectedReportModel?.name}.`, true);
      }

    } catch (e) {
      this.loggerService.logError(e);
      this.showError(ErrorUtil.RATA_GENERAL_ERROR_OCCURRED, false);

    } finally {
    }
  }

  getPii = async (record: RataReportRecord) => {
    try {
      this.piiBusy = true;
      this.selectedPii = await lastValueFrom(this.rataService.getPii(this.selectedProcessId, record.pin), { defaultValue: new RataPii() });
      this.loggerService.logDebug(`pii for id ${record.pin}, process id ${this.selectedProcessId} retrieved`, this.selectedPii);

      this.selectedPii.recordId = record.recordId;

      this.rataService.updateObservablePiiValue(this.selectedPii);
      this.displayPIISidebar(true);

    } catch (e) {
      this.loggerService.logError(e);
      this.displayPIISidebar(false);
      this.showError(ErrorUtil.RATA_GENERAL_ERROR_OCCURRED, false);

    } finally {
      this.piiBusy = false;
    }
  }

  getFilterCriteria = async () => {
    try {
      this.showFilter = false;
      this.resetFilterCriteria();

      this.filterCriteria = await lastValueFrom(this.rataService.getFilterCriteria(this.selectedProcessId), { defaultValue: new RataFilterCriteriaResponse() });
      this.loggerService.logInfo(`filter criteria for process id ${this.selectedProcessId} refreshed`, this.filterCriteria);
      this.showFilter = true;

    } catch (e) {
      this.loggerService.logError(e);
      this.showFilter = false;
      this.showError(ErrorUtil.RATA_GENERAL_ERROR_OCCURRED, false);

    } finally {
    }
  }

  resetFilterCriteria = () => {
    this.setFilterFormValue(RataFilterKeyEnum.LRO, null);
    this.setFilterFormValue(RataFilterKeyEnum.PIN, null);
    this.setFilterFormValue(RataFilterKeyEnum.PARTY_FROM, null);
    this.setFilterFormValue(RataFilterKeyEnum.PARTY_TO, null);
    this.setFilterFormValue(RataFilterKeyEnum.INSTRUMENT_TYPE, null);
    this.setFilterFormValue(RataFilterKeyEnum.CHARGE_INSTRUMENT_TYPE, null);
    this.setFilterFormValue(RataFilterKeyEnum.TRANSFER_INSTRUMENT_TYPE, null);
    this.setFilterFormValue(RataFilterKeyEnum.REGISTRATION_DATE, null);
    this.setFilterFormValue(RataFilterKeyEnum.CHARGE_REGISTRATION_DATE, null);
    this.setFilterFormValue(RataFilterKeyEnum.TRANSFER_REGISTRATION_DATE, null);

    this.selectedInstrumentFilter = null;
    this.selectedChargeInstrumentFilter = null;
    this.selectedTransferInstrumentFilter = null;
    this.selectedRegistrationDateFilterFormatted = null;
    this.selectedChargeRegistrationDateFilterFormatted = null;
    this.selectedTransferRegistrationDateFilterFormatted = null;

  }

  search = (searchFromReportType: boolean, searchFromFilter: boolean, searchFromPaginator: boolean, searchFromSort: boolean) => {

    this.showDescription = false;
    this.reportRunning = true;
    this.showSearchResults = false;

    if (this.showFilter && (searchFromFilter || searchFromPaginator || searchFromSort)) {
      this.showFilter = true; //keep showing the filter criteria
    } else {
      this.showFilter = false;
    }

    if (!searchFromPaginator && !searchFromSort) {
      this.totalPages = 0;
    }

    if (this.isPIISidebarOpened()) {
      this.displayPIISidebar(false);
    }

    let searchRequest: RataSearchRequest = new RataSearchRequest();

    //mandatory request parameters for all search requests
    searchRequest.setProperty('isDescending', (this.currentSortDirection == 'desc')? true : false);
    searchRequest.setProperty('processId', this.selectedProcessId);
    searchRequest.setProperty('sortBy', this.currentSortColumn);

    if (searchFromReportType || searchFromFilter) {
      this.currentPageNumber = 1;
    }

    if (!searchFromReportType && this.lastSearchRequest?.hasFilterRequestProperties()) {
      searchRequest.setFilterRequestProperties(this.lastSearchRequest.getFilterRequestProperties());
    }

    //on-demand filter request parameters based on report type
    if (searchFromFilter) {
      this.filterSummary = null;
      
      if (!_.isEmpty(this.getFilterFormValue(RataFilterKeyEnum.LRO))) {
        searchRequest.setFilterRequestProperty(RataFilterKeyEnum.LRO_NUMBER, this.getFilterFormValue(RataFilterKeyEnum.LRO));
      } else {
        searchRequest.unsetFilterRequestProperty(RataFilterKeyEnum.LRO_NUMBER);
      }

      if (!_.isEmpty(this.getFilterFormValue(RataFilterKeyEnum.PIN))) {
        searchRequest.setFilterRequestProperty(RataFilterKeyEnum.PIN, this.getFilterFormValue(RataFilterKeyEnum.PIN).trim());
      } else {
        searchRequest.unsetFilterRequestProperty(RataFilterKeyEnum.PIN);
      }

      if (!_.isEmpty(this.getFilterFormValue(RataFilterKeyEnum.PARTY_FROM))) {
        searchRequest.setFilterRequestProperty(RataFilterKeyEnum.PARTY_FROM, this.getFilterFormValue(RataFilterKeyEnum.PARTY_FROM).trim());
      } else {
        searchRequest.unsetFilterRequestProperty(RataFilterKeyEnum.PARTY_FROM);
      }

      if (!_.isEmpty(this.getFilterFormValue(RataFilterKeyEnum.PARTY_TO))) {
        searchRequest.setFilterRequestProperty(RataFilterKeyEnum.PARTY_TO, this.getFilterFormValue(RataFilterKeyEnum.PARTY_TO).trim());
      } else {
        searchRequest.unsetFilterRequestProperty(RataFilterKeyEnum.PARTY_TO);
      }

      if (!_.isEmpty(this.selectedInstrumentFilter)) {
        searchRequest.setFilterRequestProperty(RataFilterKeyEnum.INSTRUMENT_TYPE_DESCRIPTION, this.selectedInstrumentFilter.instrumentDescription);
      } else {
        searchRequest.unsetFilterRequestProperty(RataFilterKeyEnum.INSTRUMENT_TYPE_DESCRIPTION);
      }

      if (!_.isEmpty(this.selectedChargeInstrumentFilter)) {
        searchRequest.setFilterRequestProperty(RataFilterKeyEnum.CHARGE_INSTRUMENT_TYPE_DESCRIPTION, this.selectedChargeInstrumentFilter.instrumentDescription);
      } else {
        searchRequest.unsetFilterRequestProperty(RataFilterKeyEnum.CHARGE_INSTRUMENT_TYPE_DESCRIPTION);
      }

      if (!_.isEmpty(this.selectedTransferInstrumentFilter)) {
        searchRequest.setFilterRequestProperty(RataFilterKeyEnum.TRANSFER_INSTRUMENT_TYPE_DESCRIPTION, this.selectedTransferInstrumentFilter.instrumentDescription);
      } else {
        searchRequest.unsetFilterRequestProperty(RataFilterKeyEnum.TRANSFER_INSTRUMENT_TYPE_DESCRIPTION);
      }

      if (!_.isEmpty(this.selectedRegistrationDateFilterFormatted)) {
        searchRequest.setFilterRequestProperty(RataFilterKeyEnum.REGISTRATION_DATE, this.selectedRegistrationDateFilterFormatted);
      } else {
        searchRequest.unsetFilterRequestProperty(RataFilterKeyEnum.REGISTRATION_DATE);
      }

      if (!_.isEmpty(this.selectedChargeRegistrationDateFilterFormatted)) {
        searchRequest.setFilterRequestProperty(RataFilterKeyEnum.CHARGE_REGISTRATION_DATE, this.selectedChargeRegistrationDateFilterFormatted);
      } else {
        searchRequest.unsetFilterRequestProperty(RataFilterKeyEnum.CHARGE_REGISTRATION_DATE);
      }

      if (!_.isEmpty(this.selectedTransferRegistrationDateFilterFormatted)) {
        searchRequest.setFilterRequestProperty(RataFilterKeyEnum.TRANSFER_REGISTRATION_DATE, this.selectedTransferRegistrationDateFilterFormatted);
      } else {
        searchRequest.unsetFilterRequestProperty(RataFilterKeyEnum.TRANSFER_REGISTRATION_DATE);
      }

      this.setFilterSummary(searchRequest);
    }

    if (this.currentPageNumber > 0) {
      searchRequest.setProperty('pageNo', this.currentPageNumber);
    }

    this.lastSearchRequest = searchRequest;

    if (searchFromReportType) {
      this.clearSelectedRecords();
    }

    this.runReport(searchRequest, searchFromFilter, searchFromPaginator, searchFromSort);
  }

  runReport = async (searchRequest: RataSearchRequest, searchFromFilter: boolean, searchFromPaginator: boolean, searchFromSort: boolean) => {
    try {
      this.loggerService.logDebug(`rata search request`, searchRequest.getJSON());
      this.searchResponse = await lastValueFrom(this.rataService.getReport(searchRequest), { defaultValue: new RataSearchResponse() });
      this.loggerService.logDebug(`rata search response`, this.searchResponse);

      if (this.searchResponse && this.searchResponse.isRecordsExist()) {
        this.initializeReportColumns();
        this.populateRegistrationTable();
        this.setupPaginatorValues();
        this.showSearchResults = true;

        //paginator values
        if (this.searchResponse.pageInfo) {
          this.totalRecords = this.searchResponse.pageInfo.recordCount;
          this.rowsPerPage = _.round(this.searchResponse.pageInfo.recordCount / this.searchResponse.pageInfo.pageCount);
          this.totalPages = _.round(this.totalRecords / this.rowsPerPage);
          this.currentPageNumber = 1;
        }

      } else {
        this.currentPageNumber = 0;
        this.totalPages = 0;

        if (searchFromFilter) {
          this.showError(DataService.RATA_FILTERED_RECORDS_NOT_FOUND, true);
        } else if (searchFromPaginator) {
          this.showError(DataService.RATA_PAGE_RECORDS_NOT_FOUND, true);
        } else {
          this.showError(DataService.RATA_NO_ACTIVITIES_FOUND, true);
        }
      }

    } catch (e) {
      this.loggerService.logError(e);
      this.showSearchResults = false;
      this.currentPageNumber = 0;
      this.totalPages = 0;
      this.showError(ErrorUtil.RATA_GENERAL_ERROR_OCCURRED, false);

    } finally {
      this.reportRunning = false;
      this.processOnPageChangeEvent = true;
    }
  }

  handlePageEvent = (e: PageEvent) => {
    this.pageEvent = e;
    this.rowsPerPage = e.pageSize;
    this.pageIndex = e.pageIndex;

    this.processOnPageChangeEvent = false;
    this.currentPageNumber = this.pageIndex + 1;
    this.search(false, false, true, false);
  }
  
  onPageChangedEvent = (event: any) => {
    if (this.processOnPageChangeEvent) {
      if (event.isUserInput && event.source.value) { //this is necessary since mat-select onSelectionChange event fires twice, we're only interested in the new selected value
        this.currentPageNumber = event.source.value;
        this.search(false, false, true, false);
      }
    }
  }

  formatPageNumberLabel = (): string => {
    let label: string = '';
    if (this.currentPageNumber >= 0) {
      label = this.currentPageNumber + '/' + this.totalPages;
    } else {
      label = this.currentPageNumber + '';
    }

    return label;
  }

  onPageSliderDragEnd = (event: MatSliderDragEvent) => {
    this.search(false, false, true, false);
  }

  setFilterSummary(searchRequest: RataSearchRequest) {
    if (!_.isEmpty(searchRequest?.getFilterRequestProperties())) {
      this.filterSummary = '';

      for (let [key, value] of searchRequest?.getFilterRequestProperties()) {
        this.filterSummary = ' ' + this.filterSummary + ' ' + key + ' ' + value + ','
      }

      this.filterSummary = this.filterSummary.slice(0, -1);
    }
  }

  onFilterFormSubmitted = () => {
    this.loggerService.logDebug(`filter form`, this.filterForm);
    this.search(false, true, false, false);
  }

  private initializeFilterForm() {
    //all form field names must match those in RataFilterKeyEnum
    this.filterForm = new FormGroup({
      lro: new FormControl(''),
      instrumentType: new FormControl(''),
      chargeInstrumentType: new FormControl(''),
      transferInstrumentType: new FormControl(''),
      pin: new FormControl('', this.customValidatorService.pinValidator()),
      partyFrom: new FormControl(''),
      partyTo: new FormControl(''),
      registrationDate: new FormControl(''),
      chargeRegistrationDate: new FormControl(''),
      transferRegistrationDate: new FormControl('')
    });
  }

  private getFilterFormValue(formField: string): any {
    return this.filterForm.controls[formField].getRawValue();
  }

  private setFilterFormValue(formField: string, value: any) {
    this.filterForm.controls[formField].setValue(value);
  }

  private initializeReportDropDownForm() {
    //all form field names must match those in RataReportDropDownKeyEnum
    this.reportDropDownForm = new FormGroup({
      reportType: new FormControl(''),
      reportModel: new FormControl(''),
      reportPeriod: new FormControl('')
    });
  }

  private initializeSaveRecordsForm() {
    this.saveRecordsForm = new FormGroup({
      rbSaveSelectedRecords: new FormControl(''),
      rbSaveAllRecords: new FormControl(''),
      rbSaveHidden: new FormControl('')
    });
  }

  private getReportDropDownFormValue(formField: string): any {
    return this.reportDropDownForm.controls[formField].getRawValue();
  }

  private setReportDropDownFormValue(formField: string, value: any) {
    this.reportDropDownForm.controls[formField].setValue(value);
  }

  //visible report columns depend on the report type
  private initializeReportColumns = () => {
    this.displayedColumns = [];
    this.displayedColumns.push(RataSortKeyEnum.CHECKBOXES);
    this.displayedColumns.push(RataSortKeyEnum.LRO_OFFICE_NAME);
    this.displayedColumns.push(RataSortKeyEnum.PIN);
    this.displayedColumns.push(RataSortKeyEnum.ARN);
    this.displayedColumns.push(RataSortKeyEnum.INSTRUMENT_TYPE);
    this.displayedColumns.push(RataSortKeyEnum.REGISTRATION_DATE);
    this.displayedColumns.push(RataSortKeyEnum.CHARGE_TYPE);
    this.displayedColumns.push(RataSortKeyEnum.TRANSFER_TYPE);
    this.displayedColumns.push(RataSortKeyEnum.CHARGE_DATE);
    this.displayedColumns.push(RataSortKeyEnum.TRANSFER_DATE);
    this.displayedColumns.push(RataSortKeyEnum.CHARGE_AMOUNT);
    this.displayedColumns.push(RataSortKeyEnum.TRANSFER_AMOUNT);
    this.displayedColumns.push(RataSortKeyEnum.CONSIDERATION_AMOUNT);
    this.displayedColumns.push(RataSortKeyEnum.SUM_AMOUNT);
    this.displayedColumns.push(RataSortKeyEnum.PARTY_FROM);
    this.displayedColumns.push(RataSortKeyEnum.PARTY_TO);

    //remove columns based on report type
    switch (this.selectedReportType?.toLowerCase()) {
      case RataReportTypeEnum.FILE_1.toLowerCase():
        var includedColumns = this.displayedColumns.filter(c => {
          //exclude the columns below
          if (c !== RataSortKeyEnum.ARN &&
              c !== RataSortKeyEnum.CHARGE_TYPE &&
              c !== RataSortKeyEnum.TRANSFER_TYPE &&
              c !== RataSortKeyEnum.CHARGE_DATE &&
              c !== RataSortKeyEnum.TRANSFER_DATE &&
              c !== RataSortKeyEnum.CHARGE_AMOUNT &&
              c !== RataSortKeyEnum.TRANSFER_AMOUNT &&
              c !== RataSortKeyEnum.SUM_AMOUNT) {
                return c;
              }
            return;
        });

        this.displayedColumns = [];
        this.displayedColumns = Array.from(includedColumns);
        break;

      case RataReportTypeEnum.FILE_2.toLowerCase():
        var includedColumns = this.displayedColumns.filter(c => {
          //exclude the columns below
          if (c !== RataSortKeyEnum.ARN &&
              c !== RataSortKeyEnum.CHARGE_TYPE &&
              c !== RataSortKeyEnum.TRANSFER_TYPE &&
              c !== RataSortKeyEnum.CHARGE_DATE &&
              c !== RataSortKeyEnum.TRANSFER_DATE &&
              c !== RataSortKeyEnum.CHARGE_AMOUNT &&
              c !== RataSortKeyEnum.TRANSFER_AMOUNT) {
                return c;
              }
            return;
        });

        this.displayedColumns = [];
        this.displayedColumns = Array.from(includedColumns);
        break;

      case RataReportTypeEnum.FILE_3.toLowerCase():
        var includedColumns = this.displayedColumns.filter(c => {
          //exclude the columns below
          if (c !== RataSortKeyEnum.ARN &&
              c !== RataSortKeyEnum.INSTRUMENT_TYPE &&
              c !== RataSortKeyEnum.REGISTRATION_DATE &&
              c !== RataSortKeyEnum.CONSIDERATION_AMOUNT &&
              c !== RataSortKeyEnum.SUM_AMOUNT) {
                return c;
              }
            return;
        });

        this.displayedColumns = [];
        this.displayedColumns = Array.from(includedColumns);
        break;

      case RataReportTypeEnum.FILE_4.toLowerCase():
        var includedColumns = this.displayedColumns.filter(c => {
          //exclude the columns below
          if (c !== RataSortKeyEnum.CHARGE_TYPE &&
              c !== RataSortKeyEnum.TRANSFER_TYPE &&
              c !== RataSortKeyEnum.CHARGE_DATE &&
              c !== RataSortKeyEnum.TRANSFER_DATE &&
              c !== RataSortKeyEnum.CHARGE_AMOUNT &&
              c !== RataSortKeyEnum.TRANSFER_AMOUNT) {
                return c;
              }
            return;
        });

        this.displayedColumns = [];
        this.displayedColumns = Array.from(includedColumns);
        break;

      case RataReportTypeEnum.FILE_5.toLowerCase():
        var includedColumns = this.displayedColumns.filter(c => {
          //exclude the columns below
          if (c !== RataSortKeyEnum.INSTRUMENT_TYPE &&
              c !== RataSortKeyEnum.REGISTRATION_DATE &&
              c !== RataSortKeyEnum.CONSIDERATION_AMOUNT &&
              c !== RataSortKeyEnum.SUM_AMOUNT) {
                return c;
              }
            return;
        });

        this.displayedColumns = [];
        this.displayedColumns = Array.from(includedColumns);
        break;

    }
  }

  isUIElementVisible(uiElementName: string): boolean {
    let visible: boolean = true;

    switch (this.selectedReportType?.toLowerCase()) {
      case RataReportTypeEnum.FILE_1.toLowerCase():
        //exclude the elements below
        if (uiElementName == RataFilterKeyEnum.CHARGE_INSTRUMENT_TYPE ||
          uiElementName == RataFilterKeyEnum.TRANSFER_INSTRUMENT_TYPE ||
          uiElementName == RataFilterKeyEnum.CHARGE_REGISTRATION_DATE ||
          uiElementName == RataFilterKeyEnum.TRANSFER_REGISTRATION_DATE ||
          uiElementName == RataSortKeyEnum.ARN ||
          uiElementName == RataSortKeyEnum.CHARGE_TYPE ||
          uiElementName == RataSortKeyEnum.TRANSFER_TYPE ||
          uiElementName == RataSortKeyEnum.CHARGE_DATE ||
          uiElementName == RataSortKeyEnum.TRANSFER_DATE) {
          visible = false;
        }
        break;

      case RataReportTypeEnum.FILE_2.toLowerCase():
        //exclude the elements below
        if (uiElementName == RataSortKeyEnum.ARN ||
          uiElementName == RataFilterKeyEnum.CHARGE_INSTRUMENT_TYPE ||
          uiElementName == RataFilterKeyEnum.TRANSFER_INSTRUMENT_TYPE ||
          uiElementName == RataFilterKeyEnum.CHARGE_REGISTRATION_DATE ||
          uiElementName == RataFilterKeyEnum.TRANSFER_REGISTRATION_DATE) {
          visible = false;
        }
        break;

      case RataReportTypeEnum.FILE_3.toLowerCase():
        //exclude the elements below
        if (uiElementName == RataSortKeyEnum.ARN ||
          uiElementName == RataFilterKeyEnum.INSTRUMENT_TYPE ||
          uiElementName == RataFilterKeyEnum.REGISTRATION_DATE ||
          uiElementName == RataSortKeyEnum.CONSIDERATION_AMOUNT ||
          uiElementName == RataSortKeyEnum.SUM_AMOUNT) {
          visible = false;
        }
        break;

      case RataReportTypeEnum.FILE_4.toLowerCase():
        //exclude the elements below
        if (uiElementName == RataFilterKeyEnum.CHARGE_INSTRUMENT_TYPE ||
          uiElementName == RataFilterKeyEnum.TRANSFER_INSTRUMENT_TYPE ||
          uiElementName == RataFilterKeyEnum.CHARGE_REGISTRATION_DATE ||
          uiElementName == RataFilterKeyEnum.TRANSFER_REGISTRATION_DATE ||
          uiElementName == RataSortKeyEnum.CHARGE_TYPE ||
          uiElementName == RataSortKeyEnum.TRANSFER_TYPE ||
          uiElementName == RataSortKeyEnum.CHARGE_DATE ||
          uiElementName == RataSortKeyEnum.TRANSFER_DATE) {
          visible = false;
        }
        break;

      case RataReportTypeEnum.FILE_5.toLowerCase():
        //exclude the elements below
        if (uiElementName == RataFilterKeyEnum.INSTRUMENT_TYPE ||
          uiElementName == RataFilterKeyEnum.REGISTRATION_DATE ||
          uiElementName == RataSortKeyEnum.CONSIDERATION_AMOUNT ||
          uiElementName == RataSortKeyEnum.SUM_AMOUNT) {
          visible = false;
        }
        break;

    }

    return visible;
  }

  populateRegistrationTable = () => {
    this.dataSource = new MatTableDataSource(this.searchResponse.reportRecords);
  }

  setupPaginatorValues = () => {
    if (this.searchResponse.pageInfo) {
      this.totalRecords = this.searchResponse.pageInfo.recordCount;
      this.rowsPerPage = _.round(this.searchResponse.pageInfo.recordCount / this.searchResponse.pageInfo.pageCount);
      this.totalPages = _.round(this.totalRecords / this.rowsPerPage);
      this.pageNumbers = Array.from({length: this.totalPages}, (_, i) => i + 1);
      this.currentPageNumber = 1;
    }
  }

  showFilterCriteria = (toggleEvent: MatSlideToggleChange) => {
    if (this.isPIISidebarOpened()) {
      this.displayPIISidebar(false);
    }

    this.showFilter = toggleEvent.checked;
    if (this.showFilter) {
      this.openFilter = true;
      this.getFilterCriteria();
    } else {
      this.openFilter = false;
      this.currentPageNumber = 0;
      this.filterSummary = null;
      this.currentSortColumn = RataSortKeyEnum.PIN.toString();
      this.currentSortDirection = RegistrationTrackerMainComponent.SORT_ASCENDING;
      this.lastSortColumn = this.currentSortColumn;
      this.lastSortDirection = this.currentSortDirection;
      this.search(true, false, false, false);
  }
  }

  onRegistrationDateChanged = (data: any) => {
    if (data.value) {
      this.selectedRegistrationDateFilterFormatted = moment(data.value).format(this.FILTER_DATE_FORMAT);
    } else {
      this.selectedRegistrationDateFilterFormatted = null;
    }
  }

  onChargeRegistrationDateChanged = (data: any) => {
    if (data.value) {
      this.selectedChargeRegistrationDateFilterFormatted = moment(data.value).format(this.FILTER_DATE_FORMAT);
    } else {
      this.selectedChargeRegistrationDateFilterFormatted = null;
    }
  }

  onTransferRegistrationDateChanged = (data: any) => {
    if (data.value) {
      this.selectedTransferRegistrationDateFilterFormatted = moment(data.value).format(this.FILTER_DATE_FORMAT);
    } else {
      this.selectedTransferRegistrationDateFilterFormatted = null;
    }
  }

  clearFilterDropdownSelection = ($event: any, key: string) => {
    $event.stopPropagation();

    switch (key) {
      case RataFilterKeyEnum.LRO:
        this.setFilterFormValue(RataFilterKeyEnum.LRO, null);
        this.selectedLroFilter = null;
        break;

      case RataFilterKeyEnum.INSTRUMENT_TYPE:
        this.setFilterFormValue(RataFilterKeyEnum.INSTRUMENT_TYPE, null);
        this.selectedInstrumentFilter = null;
        break;

      case RataFilterKeyEnum.CHARGE_INSTRUMENT_TYPE:
        this.setFilterFormValue(RataFilterKeyEnum.CHARGE_INSTRUMENT_TYPE, null);
        this.selectedChargeInstrumentFilter = null;
        break;

      case RataFilterKeyEnum.TRANSFER_INSTRUMENT_TYPE:
        this.setFilterFormValue(RataFilterKeyEnum.TRANSFER_INSTRUMENT_TYPE, null);
        this.selectedTransferInstrumentFilter = null;
        break;
    }
  }

  downloadAllReportActivities = () => {
    try {
      this.notifyUser(false, DataService.RATA_REPORT_DOWNLOAD_IN_PROGRESS, true);
      this.rataService.downloadAllReportActivities(this.selectedProcessId, this.currentSortColumn, this.isCurrentSortDescending())
        .subscribe(reportBlob => {
          this.loggerService.logDebug(`all report activities for download retrieved`);

          //trigger the browser download prompt
          this.fileUtility.displayBrowserDownloadPrompt('rata-report-' + moment().format(this.REPORT_DOWNLOAD_DATE_FORMAT) + '.txt', reportBlob);
        });

    } catch (e) {
      this.loggerService.logError(e);
      this.showError(ErrorUtil.RATA_GENERAL_ERROR_OCCURRED, true);
    }
    this.clearSelectedRecords();
  }

  downloadSelectedReportActivities = () => {
    try {
      if (_.isEmpty(this.selectedRecordIds)) {
        this.showError(DataService.RATA_NO_RECORDS_SELECTED, true);
      } else {
        this.notifyUser(false, DataService.RATA_REPORT_DOWNLOAD_IN_PROGRESS, true);
        this.rataService.downloadSelectedReportActivities(this.selectedProcessId, this.selectedRecordIds)
          .subscribe(reportBlob => {
            this.loggerService.logDebug('all report activities for download retrieved');
            this.fileUtility.displayBrowserDownloadPrompt('rata-report-' + moment().format(this.REPORT_DOWNLOAD_DATE_FORMAT) + '.txt', reportBlob);
          })
      }

    } catch (e) {
      this.loggerService.logError(e);
      this.showError(ErrorUtil.RATA_GENERAL_ERROR_OCCURRED, true);
    }
    this.clearSelectedRecords();
  }

  onRecordSelected(recordId: number, event: any) {
    event.stopPropagation();

    let element: HTMLInputElement = (event.target as HTMLInputElement);
    let isSelected: boolean = element.checked;

    if (isSelected) {
      this.selectedRecordIds.push(recordId);
    } else {
      _.remove(this.selectedRecordIds, id => id == recordId);
    }
  }

  isRecordSelected = (recordId: number): boolean => {
    return this.selectedRecordIds.includes(recordId);
  }

  get hasRecordsSelected(): boolean {
    return !_.isEmpty(this.selectedRecordIds);
  }

  get selectedRecordsCount(): number {
    return this.selectedRecordIds.length;
  }

  clearSelectedRecords = () => {
    this.selectedRecordIds = [];
  }

  onReportTypeSelected = (event: MatOptionSelectionChange, reportType: RataReportType) => {
    if (event.source.selected) {
      this.showFilter = false;
      this.showRunReport = false;
      this.showSearchResults = false;
      this.selectedProcessId = '';
      this.currentSortColumn = RataSortKeyEnum.PIN.toString();
      this.currentSortDirection = RegistrationTrackerMainComponent.SORT_ASCENDING;
      this.lastSortColumn = this.currentSortColumn;
      this.lastSortDirection = this.currentSortDirection;
      this.selectedReportTypeObj = reportType;
      this.selectedReportType = Object.keys(RataReportTypeEnum)[Object.values(RataReportTypeEnum).indexOf(reportType.name as unknown as RataReportTypeEnum)];
      this.selectedReportModel = undefined;
      this.selectedReportPeriod = undefined;
      this.selectedReportTypeToolTip = reportType.description;
      this.getReportModels(this.user.companyBeid + '', reportType);
    }
  }

  onReportModelSelected = (event: MatOptionSelectionChange, model: RataReportModel) => {
    if (event.source.selected) {
      this.showFilter = false;
      this.showRunReport = false;
      this.showSearchResults = false;
      this.selectedReportModel = model;
      this.selectedReportModelToolTip = model.name;
      this.getReportPeriods(model);
    }
  }

  onReportPeriodSelected = (event: MatOptionSelectionChange, reportPeriod: RataReportPeriod) => {
    if (event.source.selected) {
      this.selectedReportPeriod = reportPeriod;
      this.selectedProcessId = reportPeriod.processId;
      this.selectedReportPeriodToolTip = this.mediumDatePipe.transform(this.selectedReportPeriod.reportingPeriodStart) + ' to ' + this.mediumDatePipe.transform(this.selectedReportPeriod.reportingPeriodEnd);
      this.showRunReport = true;
    }
  }

  onLroFilterChanged = (event: MatOptionSelectionChange, lro: RataFilterCriteriaLro) => {
    if (event.source.selected) {
      this.selectedLroFilter = lro;
    }
  }

  onInstrumentFilterChanged = (event: MatOptionSelectionChange, instrument: RataFilterCriteriaInstrumentType) => {
    if (event.source.selected) {
      this.selectedInstrumentFilter = instrument;
    }
  }

  onChargeInstrumentFilterChanged = (event: MatOptionSelectionChange, instrument: RataFilterCriteriaInstrumentType) => {
    if (event.source.selected) {
      this.selectedChargeInstrumentFilter = instrument;
    }
  }

  onTransferInstrumentFilterChanged = (event: MatOptionSelectionChange, instrument: RataFilterCriteriaInstrumentType) => {
    if (event.source.selected) {
      this.selectedTransferInstrumentFilter = instrument;
    }
  }

  getLroToolTip = (lro: RataFilterCriteriaLro | null) => {
    if (lro) {
      return lro.lroOfficeName + '(' + lro.lroNumber + ')';
    }

    return '';
  }

  getInstrumentTypeToolTip = (instrument: RataFilterCriteriaInstrumentType | null) => {
    if (instrument) {
      return instrument.instrumentDescription + ' (' + instrument.instrumentSubTypeCode + ')';
    }

    return '';
  }

  showError = (message: string, autoClose: boolean) => {
    this.notifyUser(true, message, autoClose);
  }

  notifyUser = (isError: boolean, message: string, autoClose: boolean) => {
    let closeLabel: string = 'Close';

    if (isError) {
      if (!autoClose) {
        this._snackBar.open(message, closeLabel, defaultIndefiniteErrorMatSnackBarConfig);
      } else {
        this._snackBar.open(message, closeLabel, defaultErrorMatSnackBarConfig);
      }
    } else {
      this._snackBar.open(message, closeLabel, defaultMatSnackBarConfig);
    }

  }

  displayPII = (event: any, record: RataReportRecord) => {
    event.stopPropagation();

    if (this.rataService.getObservablePiiIdValue() && this.rataService.getObservablePiiIdValue() == record.recordId) {
      //basically, if the user is selecting the same table record id as the pii, then make clicking on the table row pin act as a pii sidebar toggle
      (this.isPIISidebarOpened())? this.displayPIISidebar(false) : this.displayPIISidebar(true);

    } else {
      this.getPii(record);
    }
  }

  togglePIISideDisplayMode = (overlapMode: boolean) => {
    if (overlapMode) {
      this.piiSideBar.mode = 'over';
    } else {
      this.piiSideBar.mode = 'side';
    }
  }

  togglePIISideBar = () => {
    this.showPii = !this.showPii;
  }

  displayPIISidebar = (visible: boolean) => {
    this.showPii = visible;
    //if (this.showPii) this.piiSideBar.opened = true;
    this.piiSideBar.opened = this.showPii;
  }

  isPIISidebarOpened = (): boolean => {
    return this.piiSideBar?.opened;
  }

  copyPin = () => {
    navigator?.clipboard?.
    writeText(this.pin)
      .then(() => {
        this.copiedPin = "Copied";
        this.copyPinPopOver.open();
        setTimeout(() => {
          this.copyPinPopOver.close();
        }, 2000);
      });
  }

  closeRata = () => {
    this.router.navigate(['../home']);
  }

  //This setter will fire once matSort in the view changes.
  //I.e. when it is defined the first time. It will not fire when you change the sorting by clicking on the arrows.
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.sort = sort;
    this.setDataSort();
  }

  setDataSort = () => {
    this.dataSource.sort = this.sort;

    if (this.sort) {
      this.sort.sort({
        id: this.currentSortColumn,
        start: this.currentSortDirection,
        disableClear: true
      });
      this.lastSortColumn = this.currentSortColumn;
      this.lastSortDirection = this.currentSortDirection;
      this.sort.disableClear = true;
    }

    //item refers to the table row RataReportRecord instance
    //property refers to the matColumnDef name (sort header id) in the template
    this.dataSource.sortingDataAccessor = (item, property: string) => {

      // this.currentSortColumn = this.sort.active;
      // this.currentSortDirection = this.sort.direction;

      switch (property) {
        case RataSortKeyEnum.REGISTRATION_DATE:
          return new Date(item.registrationDate).getTime();
        case RataSortKeyEnum.CHARGE_DATE:
          return new Date(item.chargeRegistrationDate).getTime();
        case RataSortKeyEnum.TRANSFER_DATE:
          return new Date(item.transferRegistrationDate).getTime();
        default:
          let loweredFirstCharProperty: string = property.charAt(0).toLowerCase() + property.slice(1);
          // @ts-ignore
          return item[loweredFirstCharProperty];
      }
    }
  }

  onSortChanged = (event: Sort) => {
    this.currentSortColumn = event.active;
    this.currentSortDirection = event.direction;

    if (this.currentSortColumn != this.lastSortColumn || this.currentSortDirection != this.lastSortDirection) {
      this.lastSortColumn = this.currentSortColumn;
      this.lastSortDirection = this.currentSortDirection;
      this.search(false, false, false, true);
    }
  }

  isCurrentSortDescending = (): boolean => {
    return (this.currentSortDirection == RegistrationTrackerMainComponent.SORT_DESCENDING)? true : false;
  }

  onMouseOver = (record: RataReportRecord) => {
  }

  onMouseOut = (record: RataReportRecord) => {
  }

  ngOnInit(): void {

    this.initializeReportDropDownForm();
    this.initializeFilterForm();
    this.initializeSaveRecordsForm();

    this.userService.userObservable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(value => {
        if (value) {
          this.user = value;

          this.loggerService.logDebug(`user company beid for rata reports: ${this.user.companyBeid}`);
          this.getReportTypes(this.user.companyBeid + '');
        }
      });
  }

}
