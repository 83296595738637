export class AddressCorrectionDialogData {
  pin: string;
  propertyLro: string;
  propertyAddress: string;
  propertyARN: string;

  get pinOrARNLabel() {
    return (this.pin) ? "PIN: " : "ARN: ";
  }

  get pinOrARN() {
    return (this.pin) ? this.pin : this.propertyARN;
  }

}
