import {Address} from "../property/address";
import {EnhancedAssessmentProperty} from "../property/enhanced-assessment-property";
import {BasicAssessment} from "./basic-assessment";
import {Structure} from "./structure";

export class EnhancedAssessment {

  constructor(assessment?: EnhancedAssessment) {
    if (assessment) {
      for (let property in assessment) {
        if (assessment.hasOwnProperty(property)) {
          // @ts-ignore
          this[property] = assessment[property];
        }
      }
      if(assessment.address){
        this.address = new Address(assessment.address);
      }
      if(assessment.property){
        this.property = new EnhancedAssessmentProperty(assessment.property);
      }
      if(Array.isArray(assessment.structures)){
        this.structures = assessment.structures.map(value => { return new Structure(value)});
      }
      if(assessment.lastSale){
        this.lastSale = new LastSale(assessment.lastSale);
      }
      if(assessment.lastValidSale){
        this.lastValidSale = new LastSale(assessment.lastValidSale);
      }
      if(assessment.assessment){
        this.assessment = new EnhancedAssessmentAssessment(assessment.assessment);
      }
    }
  }

  roll: string;
  address: Address;
  property: EnhancedAssessmentProperty;
  structures: Structure[];
  lastSale: LastSale;
  lastValidSale: LastSale;
  assessment: EnhancedAssessmentAssessment;
  reportDate: string;
}

export class LastSale {
  constructor(lastSale?: LastSale) {
    if(lastSale){
      this.saleAmount = lastSale.saleAmount;
      this.saleDate = lastSale.saleDate;
    }
  }

  saleAmount: string;
  saleDate: string;
}

export class EnhancedAssessmentAssessment {

  constructor(eaa? : EnhancedAssessmentAssessment) {
    if(eaa) {
      this.previousAssessment = new BasicAssessment(eaa.previousAssessment);
      if (Array.isArray(eaa.phaseIn)){
        this.phaseIn = eaa.phaseIn.map(value => { return new BasicAssessment(value);});
      }
    }
  }

  previousAssessment: BasicAssessment;
  phaseIn: BasicAssessment[];
}
