import {BaseModel} from "../base-model";
import {DataService} from "../../../shared/service/data.service";
import {formatNumber} from "@angular/common";

export class ComparableSalesResponse extends BaseModel {

  constructor(csr?: ComparableSalesResponse) {
    super(csr);
    if (csr) {
      if (csr.centroid) {
        this.centroid = new Centroid(csr.centroid);
      }
      if (csr.sales) {
        this.sales = [];
        csr.sales.forEach(sale => {
          this.sales.push(new ComparableSale(sale));
        });
      }
    } else {
      this.amountLeft = -1; // will force the counter comparable-search.service#comparableSearchCounter to not get updated if not set on the server
    }
  }

  areaDownsized: boolean;
  averagePrice: number;
  blockNumber: null;
  centroid: Centroid;
  downsizedAreaRadius: null;
  maxPrice: number;
  medianPrice: number;
  minPrice: number;
  sales: ComparableSale[];
  salesCount: number;
  salesTruncated: boolean;
  standardDiv: number;
  totalSalesCount: number;
  amountLeft: number;
  amountUsed: number;

  get selectedSales(): ComparableSale[] {
    return this.sales.filter(sale => sale.selected);
  }

  calculatedMinPrice(): number {
    return this.selectedSales?.length ? this.selectedSales
        .reduce((min, sale) => sale.considerationAmount < min ? sale.considerationAmount : min, this.selectedSales[0].considerationAmount)
      : 0;
  }

  calculatedMaxPrice(): number {
    return this.selectedSales?.length ?
      this.selectedSales
        .reduce((max, sale) => sale.considerationAmount > max ? sale.considerationAmount : max, this.selectedSales[0].considerationAmount)
      : 0;
  }

  calculatedAveragePrice(): number {
    return this.selectedSales?.length ?
      this.selectedSales
        .reduce((sum, sale) => sum + sale.considerationAmount, 0) / this.selectedSales.length
      : 0;
  }

  calculatedMedianPrice(): number {
    if (this.selectedSales?.length) {
      let sorted = this.selectedSales.sort((a, b) => a.considerationAmount - b.considerationAmount);
      let med = Math.floor(sorted.length / 2);
      if (sorted.length % 2) {
        return sorted[med].considerationAmount;
      } else {
        return (sorted[med - 1].considerationAmount + sorted[med].considerationAmount) / 2;
      }
    }
    return 0;
  }

  calculatedStandardDeviation(): number {
    if (this.selectedSales?.length && this.selectedSales?.length > 1) {
      const average = this.calculatedAveragePrice();
      return Math.sqrt(
        this.selectedSales
          .reduce((sum, sale) =>
            sum + Math.pow(sale.considerationAmount - average, 2), 0) / (this.selectedSales.length - 1)
      );
    }
    return 0;
  }

  computePricePerArea = (rateSquareMetersToSquareFeet: number, rateSquareMetersToAcres: number, useImperial: boolean, useAcres: boolean) => {
    this.sales.forEach((sale) => {
      sale.pricePerArea = sale.considerationAmount / sale.area;
      sale.areaInFeet = Math.round(sale.area * rateSquareMetersToSquareFeet);
      sale.pricePerAreaInFeet = sale.considerationAmount / sale.areaInFeet;
      sale.calculatedAreaInAcres = Math.round(sale.area * rateSquareMetersToAcres * 100) / 100;
      sale.calculatedPricePerAreaInAcres = sale.considerationAmount / sale.calculatedAreaInAcres;

      if (sale.condo !== 'Y') {
        if (useImperial) {
          if (useAcres) {
            sale.displayArea = formatNumber(sale.calculatedAreaInAcres, 'en-US', '1.2-2');
            if (sale.calculatedPricePerAreaInAcres !== 0) {
              sale.displayPricePerArea = formatNumber(sale.calculatedPricePerAreaInAcres, 'en-US', '1.0-0');
            }
          } else {
            sale.displayArea = formatNumber(sale.areaInFeet, 'en-US', '2.0-0');
            if (sale.pricePerAreaInFeet !== 0) {
              sale.displayPricePerArea = formatNumber(sale.pricePerAreaInFeet, 'en-US', '1.0-0');
            }
          }
        } else {
          sale.displayArea = formatNumber(sale.area, 'en-US', '2.0-0');
          if (sale.pricePerArea !== 0) {
            sale.displayPricePerArea = formatNumber(sale.pricePerArea, 'en-US', '1.0-0');
          }
        }
      }
    })
  }
}

export class Centroid extends BaseModel {
  latitude: number;
  longitude: number;
}

export class ComparableSale extends BaseModel {

  constructor(cs?: ComparableSale) {
    super(cs);
    if (cs?.centroid) {
      this.centroid = new Centroid(cs.centroid);
    }
  }


  area: number;
  centroid: Centroid;
  condo: Condo;
  considerationAmount: number;
  distance: number;
  pin: string;
  registrationDate: string;
  yearBuilt: null;
  propertyCode: null;
  rollNumber: string | null;
  streetViewUrl: null;
  teranetPropertyType: null;
  areaInAcres: number;
  id: number;
  lro: string;
  useAcres: boolean;
  saleAddressStr: string;
  pricePerArea: number;
  areaInFeet: number;
  pricePerAreaInFeet: number;
  calculatedAreaInAcres: number;
  calculatedPricePerAreaInAcres: number;

  //UI only
  selected: boolean = true;
  displayArea: string = DataService.NOT_APPLICABLE;
  displayPricePerArea: string = DataService.NOT_APPLICABLE;

  get arn(): string {
    return this.rollNumber ?? '';
  }
}

export enum Condo {
  N = "N",
  Y = "Y",
}
