import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {DemographicsAreaEnum} from "../../../../../core/enum/demographics-area.enum";
import {Demographics} from "../../../../../core/model/demographics/demographics";
import {FileUtility} from "../../../../../shared/utility/file.utility";

@Component({
  selector: 'gema3g-demographics-dominant-market-group',
  templateUrl: './demographics-dominant-market-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./demographics-dominant-market-group.component.scss']
})
export class DemographicsDominantMarketGroupComponent {
  demographicsAreaEnum = DemographicsAreaEnum;
  @Input() selectedArea: DemographicsAreaEnum[] = [];
  @Input() demographicsData: Demographics = new Demographics();

  private fileUtility = inject(FileUtility);

  get prizmPdfLink(): string {
    return `/ui${this.demographicsData?.prizmSegment?.pdfFolder}/${this.prizmName}.pdf`;
  }

  downloadPrizmPdf() {
    this.fileUtility.downloadFile(this.prizmPdfLink, `${this.prizmName}.pdf`);
  }

  get prizmName(): string {
    return this.demographicsData?.prizmSegment?.number?.toString();
  }
}
