import { AfterViewInit, Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserProfileComponentModeEnum } from '../../core/enum/user-profile-component-mode.enum';
import { LoggerService } from '../../shared/service/log/logger.service';
import { AuthenticationService } from '../../shared/service/authentication.service';
import { UserService } from '../../shared/service/user.service';
import { ProfileComponent } from '../../account/profile/profile/profile.component';
import { DataService } from '../../shared/service/data.service';
import { BaseUnsubscribe } from '../../core/component/base-unsubscribe/base-unsubscribe';
import { lastValueFrom, takeUntil } from 'rxjs';
import { User } from '../../core/model/user/user';
import { UrlService } from '../../shared/service/url.service';

@Component({
  selector: 'gema3g-new-user-profile',
  templateUrl: './new-user-profile.component.html',
  styleUrl: './new-user-profile.component.scss'
})
export class NewUserProfileComponent extends BaseUnsubscribe implements OnInit {

  constructor(private authService: AuthenticationService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private userService: UserService,
    private urlService: UrlService,
    private loggerService: LoggerService) {
    super();
  }

  loggedInBeId: number;
  welcome: string = DataService.USER_PROFILE_WELCOME_HEADER;
  welcomeText: string[] = DataService.USER_PROFILE_WELCOME_TEXT;

  get userProfileMode() {
    return UserProfileComponentModeEnum.NEW_USER;
  }

  async logout() {
    this.authService.setUserProfileInitialized(false);
    await this.authService.logoutWithoutRedirect();
    await this.urlService.goToMarketingSitePage();
  }

  async ngOnInit() {
    this.loggedInBeId = this.userService.user.businessEntityId;

    this.authService.userProfileInitialized$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((initialized) => {
        if (initialized) {
          this.loggerService.logInfo(`user ${this.loggedInBeId} has initialized the user profile.`);
          this.urlService.goToLandingPage();
        }
      });

    const user: User = await lastValueFrom(this.userService.getUser(), { defaultValue: new User() });
    if (user.isProfileAccepted) {
      this.loggerService.logInfo(`user ${this.loggedInBeId} has already initialized the user profile.`);
      await this.urlService.goToLandingPage();
    }
  }

}
