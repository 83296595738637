import { BaseModel } from "../base-model";
import { RataReportType } from "./report-type";

export class RataReportTypes extends BaseModel {
    constructor(rrTypes?: RataReportTypes) {
        super(rrTypes);

        if (rrTypes) {
            if (Array.isArray(rrTypes.reportTypes)) {
                this.reportTypes = rrTypes.reportTypes.map(value => {
                    return new RataReportType(value);
                });
            }
        }
    }

    public reportTypes: RataReportType[];

    public isReportTypesExist() {
        return (this.reportTypes && this.reportTypes.length > 0) ? true : false;
    }
}