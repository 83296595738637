@for (result of searchResult; track result; let i = $index) {
  <div>
    @if (result.fullAddress) {
      <div id="psr-property-container-{{i}}" (mouseout)="onMouseOut(result)" (mouseover)="onMouseOver(result)" class="sr-property-container !border-b-2 !border-solid">
        <div class="sr-item-image-container">
          @if (result.pin == null || result.pin.length>=6) {
            <gema3g-svg-icon id="psr-icon-location-{{i}}" [fill]="'#fcbc77'" [height]="24" [icon]="'location'" [width]="24"></gema3g-svg-icon>
          } @else {
            <gema3g-svg-icon id="psr-icon-building-{{i}}" [fill]="'#3a8fef'" [height]="24" [icon]="'building'" [width]="24"></gema3g-svg-icon>
          }
        </div>
        <div class="sr-property-description-container">
          <div class="sr-property-description-first-item">
            <div id="psr-result-full-address-{{i}}" class="text-os-bold">{{result.fullAddress}}</div>
            <div class="flex-1"></div>
            @if(result.pin && result.pin.length < 6){
              <div id="psr-open-condo-view-{{i}}" (click)="openCondoView(result)" class="h-[24px]">
                <gema3g-svg-icon id="psr-icon-chevronright"
                  [fill]="'#9e9e9e'"
                  [height]="24"
                  [hidden]="!result.showExpansionImages && !isMobileFullScreen"
                  [icon]="'chevronright'"
                  [width]="34"
                  >
                </gema3g-svg-icon>
              </div>
            } @else {
              <div id="psr-pin-or-arn-{{i}}" [ngClass]="result.hasPinOrArn ? 'block' : 'hidden'" class="h-[24px]">
                @if (result.detailsExpanded) {
                  <div id="psr-details-expand-{{i}}" (click)="result.detailsExpanded = !result.detailsExpanded" class="text-left">
                    <gema3g-svg-icon id="psr-icon-chevronup-{{i}}"
                      [fill]="'#9e9e9e'"
                      [height]="24"
                      [hidden]="!result.showExpansionImages && !isMobileFullScreen"
                      [icon]="'chevronup'"
                      [width]="34"
                      >
                    </gema3g-svg-icon>
                  </div>
                }
                @if (!result.detailsExpanded) {
                  <div id="psr-details-expanded-{{i}}" (click)="result.detailsExpanded = !result.detailsExpanded" class="text-left">
                    <gema3g-svg-icon id="psr-icon-chevrondown-{{i}}"
                      [fill]="'#9e9e9e'"
                      [height]="24"
                      [hidden]="!result.showExpansionImages && !isMobileFullScreen"
                      [icon]="'chevrondown'"
                      [width]="34"
                      >
                    </gema3g-svg-icon>
                  </div>
                }
              </div>
            }
          </div>
          <div id="psr-legal-description-{{i}}">{{result.legalDescription | cutText:43: false}}</div>
          @if(result.pin && result.pin.length < 6){
            <div id="psr-condo-info-block-{{i}}"><strong>BLOCK:</strong> {{result.pin}}</div>
          }
          @if (result.detailsExpanded) {
            @if (result.pin) {
              <div id="psr-results-pin-{{i}}" class="flex flex-row">
                <div id="psr-details-label-pin-{{i}}" class="sr-details-label text-os-bold">PIN:</div>
                <div id="psr-pin-results-{{i}}">{{result.pin}}</div>
              </div>
            }
            @if (result.arns && result.arns.length > 0) {
              @if (result.arns && result.arns.length == 1) {
                <div class="flex flex-row">
                  <div id="psr-details-label-arn-{{i}}" class="sr-details-label text-os-bold">ARN:</div>
                  <div id="psr-arn-result-{{i}}">{{result.arns[0]}}</div>
                </div>
              }
              @if (result.arns && result.arns.length > 1) {
                <div>
                  <a id="psr-link-multiple-arns-{{i}}" (click)="result.arnExpanded = !result.arnExpanded" class="anchor" target="_blank">
                    Multiple ARNS
                  </a>
                </div>
                @if (result.arnExpanded) {
                  @for (arn of result.arns; track arn) {
                    <div class="flex flex-row">
                      <div id="psr-details-label-arns-{{arn}}-{{i}}" class="sr-details-label text-os-bold">ARN:</div>
                      <div id="psr-arns-result-{{arn}}-{{i}}">{{arn}}</div>
                    </div>
                  }
                }
              }
            }
            @if (result.unitType) {
              <ng-container class="flex flex-row">
                <div id="psr-details-label-type-{{i}}" class="sr-details-label text-os-bold">Type:</div>
                @if (result.unitType == 'LOCKER_OR_PARKING') {
                  <div id="psr-unit-type-other-{{i}}">Other</div>
                }
                @if (result.unitType != 'LOCKER_OR_PARKING') {
                  <div id="psr-unit-type-locker-or-parking-{{i}}">{{result.unitType}}</div>
                }
              </ng-container>
            }
          }
          @if (propertyReportAccess && (result.hasArn || ( result.pin && result.pin.length > 6))) {
            @if (result.hasPinOrArn) {
              <div id="psr-has-pin-or-arn-{{i}}">
                <a id="psr-link-property-report-{{i}}" (click)="goToProperty(result)" class="anchor" target="_blank">Property Report</a>
              </div>
            }
          }
        </div>
      </div>
    }
  </div>
}
