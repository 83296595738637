@for (result of ownerToPropertyMap; track result; let i = $index) {
  <div>
    <div id="otpmsr-show-expansion-{{i}}" (mouseout)="onMouseOut(result)" (mouseover)="onMouseOver(result)" class="sr-property-container !border-b-2 !border-solid">
      <div class="sr-item-image-container">
        @if (result.hasOneItem) {
          <img id="otpmsr-img-singleowner-{{i}}" class="sr-item-image" src="/assets/img/svg/icons/property/icon_propertytype_singleowner.svg">
        }
        @if (result.hasMultipleItems) {
          <img id="otpmsr-img-multipleowner-{{i}}" class="sr-item-image" src="/assets/img/svg/icons/property/icon_propertytype_multipleowner.svg">
        }
      </div>
      <div class="sr-property-description-container">
        <div class="sr-property-description-first-item">
          <div id="otpmsr-result-name-{{i}}" class="text-os-bold">
            {{result.name}}
          </div>
          <div class="flex-1"></div>
          <div id="otpmsr-result-has-one-item-{{i}}" [ngClass]="result.hasOneItem ? 'block' : 'hidden'" class="h-[24px]">
            @if (result.detailsExpanded) {
              <div id="otpmsr-result-details-expanded-{{i}}" (click)="result.detailsExpanded = !result.detailsExpanded" class="text-left">
                <gema3g-svg-icon id="otpmsr-icon-chevronup-{{i}}"
                  [fill]="'#9e9e9e'"
                  [height]="24"
                  [hidden]="!result.showExpansionImages && !isMobileFullScreen"
                  [icon]="'chevronup'"
                  [width]="34"
                  >
                </gema3g-svg-icon>
              </div>
            }
            @if (!result.detailsExpanded) {
              <div id="otpmsr-details-expand-{{i}}" (click)="result.detailsExpanded = !result.detailsExpanded" class="text-left">
                <gema3g-svg-icon id="otpmsr-icon-chevrondown-{{i}}"
                  [fill]="'#9e9e9e'"
                  [height]="24"
                  [hidden]="!result.showExpansionImages && !isMobileFullScreen"
                  [icon]="'chevrondown'"
                  [width]="34"
                  >
                </gema3g-svg-icon>
              </div>
            }
          </div>
          <div id="otpmsr-open-owner-view-{{i}}" (click)="openOwnerView(result)" [ngClass]="result.hasMultipleItems ? 'block' : 'hidden'" class="h-[24px]">
            <gema3g-svg-icon id="otpmsr-icon-chevronright-{{i}}"
              [fill]="'#9e9e9e'"
              [height]="24"
              [hidden]="!result.showExpansionImages && !isMobileFullScreen"
              [icon]="'chevronright'"
              [width]="34"
              >
            </gema3g-svg-icon>
          </div>
        </div>
        @if (result.hasOneItem) {
          <div id="otpmsr-full-address-{{i}}" class="text-os-bold">{{result.fullAddress}}</div>
          <div id="otpmsr-legal-description-{{i}}">{{result.legalDescription | cutText:43: false}}</div>
          @if (result.detailsExpanded) {
            @if (result.pin) {
              <div class="flex flex-row">
                <div id="otpmsr-details-label-pin-{{i}}" class="sr-details-label text-os-bold">PIN:</div>
                <div id="otpmsr-pin-results-{{i}}" class="sr-details-text">{{result.pin}}</div>
              </div>
            }
            @for (arn of result.arns; track arn) {
              <div class="flex flex-row">
                <div id="otpmsr-details-label-arn-{{arn}}-{{i}}" class="sr-details-label text-os-bold">ARN:</div>
                <div id="otpmsr-results-arn-{{arn}}-{{i}}" class="sr-details-text">{{arn}}</div>
              </div>
            }
            @if (result.unitType) {
              <ng-container class="flex flex-row">
                <div id="otpmsr-details-label-type-{{i}}" class="sr-details-label text-os-bold">Type:</div>
                @if (result.unitType == 'LOCKER_OR_PARKING') {
                  <div id="otpmsr-unit-type-other-{{i}}" class="sr-details-text">Other</div>
                }
                @if (result.unitType != 'LOCKER_OR_PARKING') {
                  <div id="otpmsr-unit-type-locker-or-parking-{{i}}" class="sr-details-text">{{result.unitType}}</div>
                }
              </ng-container>
            }
          }
          @if(userAccessControls.propertyReportAccess && (result.pin || result.arn)){
            <div id="otpmsr-pin-or-arn-{{i}}">
              <a id="otpmsr-link-property-report-{{i}}" (click)="goToProperty(result)" class="anchor" target="_blank">Property Report</a>
            </div>
          }
        }
        @if (result.hasMultipleItems) {
          <div class="flex flex-row">
            <div id="otpmsr-details-label-properties-{{i}}" class="sr-details-label text-os-bold">Properties:</div>
            <div id="otpmsr-number-of-properties-{{i}}" class="sr-details-text">{{result.numberOfProperties}}</div>
          </div>
        }
      </div>
    </div>
  </div>
}
