import {Component, inject, OnInit, ViewChild} from '@angular/core';
import {UserService} from "../../../shared/service/user.service";
import {lastValueFrom, Observable} from "rxjs";
import {UserAccessControl} from "../../../core/model/user/user-access-control";
import {Router} from "@angular/router";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {TrackingRecord} from "../../../core/model/user/tracking-record";
import {LoggerService} from '../../../shared/service/log/logger.service';
import {DataService} from "../../../shared/service/data.service";
import {RouteMappingUtility} from "../../../shared/utility/route-mapping-utility";
import {EstoreProductCategoryEnum} from "../../../core/enum/estore-product-category-enum";
import {LicenseCounters} from "../../../core/model/user/license-counters";
import {LicenseCounterTypeEnum} from "../../../core/enum/license-counter-type-enum";

@Component({
  selector: 'gema3g-comparables-search-report-history',
  templateUrl: './comparables-search-report-history.component.html',
  styleUrls: ['./comparables-search-report-history.component.scss']
})
export class ComparablesSearchReportHistoryComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  userLicenseCounters$: Observable<LicenseCounters>;
  userAccessControls: UserAccessControl;
  isLoading: boolean = true;
  displayedColumns: string[] = ['date', 'activityDisplayName', 'count'];
  dataSource = new MatTableDataSource<TrackingRecord>();
  items: TrackingRecord[];
  pageEvent: Promise<void>;
  pageIndex: number;
  pageSize: number;
  length: number = 0;
  pageSizes = [5, 10, 20];
  hasAccessToMoreReports: boolean = false;

  title: string = DataService.ACCOUNT_SEARCH_COMPARABLES_HISTORY_TITLE;
  subTitle: string = DataService.ACCOUNT_SEARCH_COMPARABLES_HISTORY_SUB_TITLE;
  warningMessage: string = DataService.ACCOUNT_SEARCH_COMPARABLES_HISTORY_WARNING_MESSAGE;
  warningThreshold: number = DataService.ACCOUNT_SEARCH_COMPARABLES_HISTORY_WARNING_THRESHOLD;

  private userService = inject(UserService);
  private router = inject(Router);

  async ngOnInit(): Promise<void> {
    this.userLicenseCounters$ = this.userService.getUserLicenseCounters();
    this.userAccessControls = this.userService.getUserAccessControl();
    let user = await lastValueFrom(this.userService.getUser(false));
    this.hasAccessToMoreReports = (!!user.userProfile.licensePackageId);
    await this.getReport();
  }

  openEstoreCatalogue() {
    this.router.navigate(['../catalogue', RouteMappingUtility.eStoreCategoryToRoute(EstoreProductCategoryEnum.ALL)]);
  }

  async getReport() {
    this.isLoading = true;
    try {
      const download = await lastValueFrom(this.userService.getViewedReportHistory(this.paginator.pageIndex, this.paginator.pageSize, LicenseCounterTypeEnum.COMPARABLES_SEARCH));
      this.items = [];
      this.items.push(...download.activities.map(value => {
        return new TrackingRecord(value);
      }));
      this.dataSource = new MatTableDataSource(this.items);
      this.length = download.totalItems;
      this.pageSize = this.paginator?.pageSize;
    } finally {
      this.isLoading = false;
    }
  }
}
