export class LoginError{
  badCredentials: boolean = false;
  genericLoginError: boolean = false;
  ssoDeeplinkAccessError: boolean = false;
  backendNotResponding: boolean = false;

  get anyBlueErrors(): boolean {
    return this.badCredentials || this.backendNotResponding;
  }

  resetErrors(){
    this.badCredentials = false;
    this.genericLoginError = false;
    this.ssoDeeplinkAccessError = false;
  }
}
