import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ComparableSalesResultPayload } from "../../../core/model/comparables/comparable-sales-result-payload";
import { ScreenOrientation } from '../../../core/enum/screen-orientation-enum';
import { ScreenDisplay } from "../../../core/enum/screen-display-enum";
import { SearchComparablesResultSnapshot } from "../../../core/model/search-result/comparables-result-snapshot";
import { LoggerService } from "../log/logger.service";
import { SearchComparablesFormWrapper } from "../../../core/model/comparables/search-comparables-form-wrapper";

@Injectable({
    providedIn: 'root'
})
export class SearchComparablesResultService {
    constructor(private http: HttpClient, private loggerService: LoggerService) {
    }

    private _searchResultsPayload = new BehaviorSubject<ComparableSalesResultPayload | null>(null);
    private searchResultsPayload$ = this._searchResultsPayload.asObservable();

    private _searchResultsSnapshot = new BehaviorSubject<SearchComparablesResultSnapshot | null>(null);
    private searchResultsSnapshot$ = this._searchResultsSnapshot.asObservable();

    private _screenOrientation = new BehaviorSubject<ScreenOrientation | null>(null);
    screenOrientation$ = this._screenOrientation.asObservable();

    private _screenDisplay = new BehaviorSubject<ScreenDisplay | null>(null);
    screenDisplay$ = this._screenDisplay.asObservable();

    private _searchResultsMapBounds = new BehaviorSubject<SearchComparablesFormWrapper | null>(null);
    private searchResultsMapBounds$ = this._searchResultsMapBounds.asObservable();

    getSearchResultsPayloadObservable = () => {
        return this.searchResultsPayload$;
    }

    updateSearchResultsPayload = (results: ComparableSalesResultPayload) => {
        this._searchResultsPayload.next(results);
    }

    getSearchResultsSnapshotObservable = () => {
        return this.searchResultsSnapshot$;
    }

    updateSearchResultsSnapshot = (resultsSnapshot: SearchComparablesResultSnapshot | null) => {
        this._searchResultsSnapshot.next(resultsSnapshot);
    }

    getSearchResultsPayloadValue = () => {
        return this._searchResultsPayload.getValue();
    }

    getSearchResultsSnapshotValue = () => {
        return this._searchResultsSnapshot.getValue();
    }

    isSearchResultsSnapshotExistsForPin(pin: string): boolean {
        let exists: boolean = false;

        if (this._searchResultsSnapshot.getValue() != null && this._searchResultsSnapshot.getValue()?.pin == pin) {
            exists = true;
        }

        this.loggerService.logDebug(`search results snapshot exists for pin ${pin}? ${exists}`);
        return exists;
    }

    isSearchResultsPayloadEmpty = () => {
        let empty: boolean = false;
        if (this._searchResultsPayload.getValue() == null || this._searchResultsPayload.getValue()!.isEmpty()) {
            empty = true;
        }

        return empty;
    }

    setSearchResultsSnapshotReadyFlag(pin: string, ready: boolean) {
        if (this.isSearchResultsSnapshotExistsForPin(pin)) {
            let snapshot: SearchComparablesResultSnapshot = this._searchResultsSnapshot.getValue()!;
            snapshot.ready = ready;
        }
    }

    isSearchResultsReadySnapshotExists(): boolean {
        let exists: boolean = false;

        if (this._searchResultsSnapshot.getValue() != null && this._searchResultsSnapshot.getValue()?.ready) {
            exists = true;
        }

        this.loggerService.logDebug(`search results snapshot ready exists? ${exists}`);
        return exists;
    }

    getSearchResultsMapBoundsObservable = () => {
        return this.searchResultsMapBounds$;
    }

    clearSearchResultsSnapshot = () => {
        this._searchResultsSnapshot.next(null);
    }

    changeScreenOrientation = (orientation: ScreenOrientation) => {
        this._screenOrientation.next(orientation);
    }

    changeScreenDisplay = (display: ScreenDisplay) => {
        this._screenDisplay.next(display);
    }

    resetScreenOrientation = () => {
        this._screenOrientation.next(null);
    }

    resetScreenDisplay = () => {
        this._screenDisplay.next(null);
    }

    adjustSearchResultsMapBounds = (formWrapper: SearchComparablesFormWrapper | null) => {
        this._searchResultsMapBounds.next(formWrapper);
    }
}