export enum EstoreProductCategoryEnum{
  MPAC = 'catMPAC',
  MPAC_BUNDLE = 'catMPACBundle',
  TERANET_INSIGHTS = 'catTeranetInsights',
  STAT = 'catStat',
  HOODQ = 'catHoodq',
  GWH_RENEW_SUBSCRIPTION = 'catGwhRSubspn',
  PYB = 'catPYB',
  RESIDENTIAL_RELOCATION = 'catBuyerMobility',
  CONDO = 'catCondoReport',
  PROPERTY_SURVEY_IMAGE = 'catOT', // might not be in use anymore,
  ALL = 'catAll'
}
