import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DemographicsAreaEnum} from "../../../../../../core/enum/demographics-area.enum";
import {DemographicsPopulation} from "../../../../../../core/model/demographics/demographics-population";
import {BaseChartComponent} from "../../../../../../core/component/base-chart/base-chart.component";
import {CoreModule} from "../../../../../../core/core.module";

@Component({
  selector: 'gema3g-family-status',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './family-status.component.html',
  imports: [
    CoreModule
  ],
  styleUrls: ['./family-status.component.scss']
})
export class FamilyStatusComponent extends BaseChartComponent {
  @Input() demographicsPopulation = new DemographicsPopulation();
}
