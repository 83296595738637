import {Component, Input, SimpleChanges} from '@angular/core';
import {Assessment} from "../../../../../core/model/assessment/assessment";
import {UserAccessControl} from "../../../../../core/model/user/user-access-control";
import {MatTableDataSource} from "@angular/material/table";
import {Structure} from "../../../../../core/model/assessment/structure";
import {ResidentialStructure} from "../../../../../core/model/property/property-detail";

@Component({
  selector: 'gema3g-site-structure-mpac-subscription-enhanced-data',
  templateUrl: './site-structure-mpac-subscription-enhanced-data.component.html',
  styleUrls: ['./site-structure-mpac-subscription-enhanced-data.component.scss']
})
export class SiteStructureMpacSubscriptionEnhancedDataComponent {
  @Input() assessment: Assessment = new Assessment();
  @Input() userAccessControls: UserAccessControl;

  structuresDataSource: MatTableDataSource<ResidentialStructure>;
  columns: string[] = [
    'structureCode',
    'structureDescription',
    'actualYearBuilt',
    'totalBedroomsCount',
    'totalFullBathsCount',
    'totalHalfBathsCount',
    'totalFullStoreysCount',
    'partStoreysCode',
    'splitLevelTypeDescription',
    'totalFirePlacesCount',
    'indoorPool',
    'outdoorPool'
  ];

  ngOnChanges(changes: SimpleChanges): void {
    if (Array.isArray(this.assessment?.mpacAssessment?.property?.residentialStructures)) {
      this.structuresDataSource = new MatTableDataSource<ResidentialStructure>(this.assessment.mpacAssessment.property.residentialStructures);
    }
  }
}
