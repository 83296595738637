import {PropertyZoning} from "./property-detail";
import {BaseModel} from "../base-model";
import {DataService} from "../../../shared/service/data.service";

export class BaseAssessmentProperty extends BaseModel{

  constructor(baseAssessmentProperty? : BaseAssessmentProperty) {
    super(baseAssessmentProperty);
    if(baseAssessmentProperty){
      if(Array.isArray(baseAssessmentProperty.propertyZoning)){
        this.propertyZoning = baseAssessmentProperty.propertyZoning.map(value => { return new PropertyZoning(value)});
      }
    }
  }
  propertyCode: string;
  propertyTypeCode: string;
  propertyTypeDescription: string;
  propertyCodeDescription: string;
  legalDescription: string;
  drivewayTypeDescription: string;
  waterServiceDescription: string;
  sanitaryServiceDescription: string;
  propertyZoning: PropertyZoning[];
  actualLotSizeUom: string;
  actualVarianceDescription: string;

  get zoningList(): string {
    if (this.propertyZoning != null && this.propertyZoning?.length > 0) {
      return this.propertyZoning.filter(Boolean).map(value => { return value.zoning}) .join(", ");
    }
    return DataService.NOT_APPLICABLE;
  }
}
