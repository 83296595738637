import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchBusyIndicatorService {
  private _mainMapBusyFlag = new BehaviorSubject<boolean>(false);
  mainMapBusyFlag$ = this._mainMapBusyFlag.asObservable();

  private updateMainMapBusyIndicator = (busy: boolean) => {
    this._mainMapBusyFlag.next(busy);
  }

  hideMainMapBusyIndicator = () => {
      this.updateMainMapBusyIndicator(false);
  }

  showMainMapBusyIndicator = () => {
      this.updateMainMapBusyIndicator(true);
  }
}