<gema3g-property-report-layout [propertyReportSection]="propertyReportSection">

  <div #addressSummaryContainer class="mw-95 mt-8">
    <ng-container header-content>
      <div class="header">
        @if (subjectProperty.pii?.address?.fullAddress) {
          <h1 id="sum-h1-address">{{subjectProperty.pii?.address?.fullAddress}}</h1>
        } @else {
          <h1 id="sum-h1-no-address">Address Not Available</h1>
        }
        <div class="no-print">
          <a id="sum-link-sac" (click)="suggestAddressCorrection()" target="_blank" class="anchor">Suggest an address correction</a>
          <span id="sum-center-on-map-tooltip" class="hidden sm:block" (click)="centerPropertyOnMap()" [satPopoverAnchor]="centerPropertyOnMapPopover" (mouseover)="centerPropertyOnMapPopover.open()" (mouseout)="centerPropertyOnMapPopover.close()"><fa-icon id="sum-icon-center-property" class="fa-lg" [icon]="faMapLocationDot" [ngStyle]="{color: '#F78F1E'}"></fa-icon></span>
          <sat-popover #centerPropertyOnMapPopover [autoFocus]="false" [restoreFocus]="false" verticalAlign="above" openTransition="500ms ease-out" closeTransition="100ms ease-in">
            <gema3g-tooltip [content]="centerPropertyOnMapToolTip"></gema3g-tooltip>
          </sat-popover>
          <!-- <span id="sum-property-insights-tooltip" (click)="showPropertyViewedInsights()" [satPopoverAnchor]="propertyInsightsPopover" (mouseover)="propertyInsightsPopover.open()" (mouseout)="propertyInsightsPopover.close()"><fa-icon id="sum-icon-property-insights" class="fa-lg" [icon]="faChartLine" [ngStyle]="{color: '#004D94'}"></fa-icon></span>
          <sat-popover #propertyInsightsPopover [autoFocus]="false" [restoreFocus]="false" verticalAlign="above" openTransition="500ms ease-out" closeTransition="100ms ease-in">
            <gema3g-tooltip [content]="propertyViewedInsightsToolTip"></gema3g-tooltip>
          </sat-popover> -->
        </div>
      </div>
    </ng-container>
  </div>

  <div [ngClass]="assessmentAccess ? 'element-grid' : 'element-grid no-assessment'">
    <div class="element">
      <img id="sum-owner-icon" class="img-element no-print" src="assets/img/property-report/icon_owner.png" />
      <div id="sum-owner-label" class="label"><h4>Owner Name</h4></div>
      <div id="sum-owner-names" class="sum-owners">{{ownerNames}}</div>
      @if (hasMoreOwners) {
        <div id="sum-owner-show-more" class="anchor" onclick="scrollToElementById0('registry-owner-names')">
          More Owners
          <mat-icon id="sum-icon-owner-show-more" class="align-middle">input</mat-icon>
        </div>
      }
    </div>

    <div class="element">
      <img id="sum-lastsale-icon" class="img-element no-print" src="assets/img/property-report/icon_lastsale.png" />
      <div id="sum-lastsale-label" class="label"><h4>Last Sale</h4></div>
      <div id="sum-lastsale-value" class="num-value">{{lastSaleAmount}}</div>
      @if (lastSaleAmount != 'N/A') {
        <div id="sum-lastsale-date">{{lastSaleDate}}</div>
      }
    </div>

    <div class="element">
      <img id="sum-lotsize-icon" class="img-element no-print" src="assets/img/property-report/icon_lotsize.png"/>
      <div id="sum-lotsize-label" class="label"><h4>Lot Size</h4></div>
      <div class="lot-size">
        @if (lotSizeNotAvailable && userAccessControls.propertyReportAssessmentAccess) {
          <div
            class="flex-center">
            <div id="sum-lotsize-na" class="sum-owners">NOT AVAILABLE</div>
          </div>
        }
        @if (!(lotSizeNotAvailable && userAccessControls.propertyReportAssessmentAccess)) {
          <div class="flex-center">
            @if (!(lotSizeNotAvailable || !userAccessControls.propertyReportAssessmentAccess)) {
              <div class="flex flex-col gap-2">
                <div id="sum-lotsize-frontage" class="num-value">{{frontageWithUom}}</div>
                <div id="sum-lotsize-frontage-label">Frontage</div>
              </div>
            }
            @if (!userAccessControls.propertyReportAssessmentAccess) {
              <div class="flex flex-col gap-2">
                <div id="sum-lotsize-area" class="num-value">{{polygonAreaWithUom}}</div>
                <div id="sum-lotsize-area-label">Area</div>
              </div>
            }
          </div>
          <div class="flex-center">
            @if (!(lotSizeNotAvailable || !userAccessControls.propertyReportAssessmentAccess)) {
              <div class="flex flex-col gap-3">
                <div id="sum-lotsize-depth" class="num-value">{{depthWithUom}}</div>
                <div id="sum-lotsize-depth-label">Depth</div>
              </div>
            }
            @if (!userAccessControls.propertyReportAssessmentAccess) {
              <div class="flex flex-col gap-2">
                <div id="sum-lotsize-perimeter" class="num-value">{{polygonPerimeterWithUom}}</div>
                <div id="sum-lotsize-perimeter-label">Perimeter</div>
              </div>
            }
          </div>
        }
      </div>

      @if (userAccessControls.TeranetLotDetails) {
        <div class="view-measurements no-print">
          <a id="sum-link-lotsize-view-measurements" class="anchor" (click)="scrollToPropertyDetails('pr-expansion-panel-registry')">
            View Measurements
          </a>
          <mat-icon id="sum-icon-lotsize-view-measurements" aria-hidden="false" aria-label="View Measurements" [ngStyle]="{'color':'#428bca'}"
            fontIcon="arrow_circle_right" color="accent" (click)="scrollToPropertyDetails('pr-expansion-panel-registry')">
          </mat-icon>
        </div>
      }
    </div>

    @if (assessmentAccess) {
      <div class="element">
        <img id="sum-value-icon" class="img-element no-print" src="assets/img/property-report/icon_value.png" />

        @if (arnCount <= 1) {
          <ng-container>
            <div class="destination-value">
              <div class="destination-value__inner">
                <div id="sum-value-dv-label" class="label"><h4>Assessed Value</h4></div>
                <div id="sum-value-dv-value" class="num-value">{{destinationValue}}</div>
                <div id="sum-value-dv-date" class="label text-os-bold">{{firstAssessmentLabels?.baseShort}}</div>
              </div>
              <div class="phased-in-value">
                <div id="sum-value-piv-label" class="label"><h4>Phased-In Value</h4></div>
                <div id="sum-value-piv-value" class="num-value">{{phasedInValue}}</div>
                <div class="phased-in-value__inner">
                  <div id="sum-value-piv-date" class="label text-os-bold" [satPopoverAnchor]="phasedInTaxYearPopover" (mouseover)="phasedInTaxYearPopover.open()">
                    {{firstAssessmentLabels?.phasedInShort}}
                    <fa-icon id="sum-value-piv-tooltip-icon" class="fa-lg text-[#428BCA]" [icon]="faCircleInfo"></fa-icon>
                    <sat-popover #phasedInTaxYearPopover hasBackdrop="false" [autoFocus]="false" [restoreFocus]="false" verticalAlign="below" openTransition="500ms ease-out" closeTransition="100ms ease-in">
                      <gema3g-tooltip [content]="phasedInValueToolTip"></gema3g-tooltip>
                    </sat-popover>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        }

        @if (arnCount > 1) {
          <ng-container>
            <div class="flex-full-width">
              <span id="sum-value-dv-ma-label" class="label text-os-bold">Assessed Value</span>
            </div>
            <div class="flex-full-width">
              <span id="sum-value-dv-ma" class="num-value">MULTIPLE ARNS</span>
            </div>
            <div class="assessed-values flex-full-width">
              @if(userAccessControls.siteStructureShutter){
                <a id="sum-link-value-dv-vav" class="anchor" onClick="scrollToElementById0('siteStructurePanel')">
                  View Assessed Values
                </a>
                <mat-icon
                  id="sum-icon-value-dv-vav"
                  aria-hidden="false"
                  aria-label="View Assessed Values"
                  [ngStyle]="{'color':'#428bca'}"
                  fontIcon="arrow_circle_right"
                  onClick="scrollToElementById0('siteStructurePanel')">
                </mat-icon>
              }
            </div>
          </ng-container>
        }
      </div>
    }

  </div>

  <div class="mw-95 mt-5">
    <ng-container footer-content>
      @if (partyToAvailable) {
        <div class="party-to">
          <span id="sum-partyto-label" class="label text-os-bold">Party To: </span>
          <span id="sum-partyto-value">{{ partyTo }}</span>
        </div>
      }
      <div class="legal-description">
        <div id="sum-legal-label" class="legal-description-label">
          <div class="label text-os-bold">Legal Description</div>
          <div class="icon" [satPopoverAnchor]="copyLegalDescriptionPopover" (click)="copyLegalDescription()">
            <mat-icon id="um-icon-legal-copy-desc" class="" aria-hidden="false" aria-label="Copy legal description" fontIcon="content_copy">
            </mat-icon>
          </div>
          <sat-popover #copyLegalDescriptionPopover [autoFocus]="false" [restoreFocus]="false" horizontalAlign="after" openTransition="500ms ease-out" closeTransition="100ms ease-in">
            <gema3g-tooltip [content]="copiedLegalDescription"></gema3g-tooltip>
          </sat-popover>
        </div>
        <div class="legal-description__length">
          @if (subjectProperty.pii?.pin) {
            <div class="sm:inline-flex">
              <p id="sum-legal-desc">{{ visibleLegalDescription }}</p>
              @if (showLegalDescriptionLengthMarker) {
                &nbsp;
                <a id="sum-link-legal-update-size" class="anchor no-print" (click)="updateLegalDescriptionViewSize()">{{ legalDescriptionLengthMarker }}</a>
              }
            </div>
          } @else {
            <div id="sum-legal-desc-na">
              Legal Description Not Available
            </div>
          }
        </div>
      </div>
    </ng-container>
  </div>

  <gema3g-top-scroller [elementToCheckForVisibility]="addressSummaryContainer" [elementToReturnFocus]="propertyReportScrollToTopElement"></gema3g-top-scroller>

</gema3g-property-report-layout>
