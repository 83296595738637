import { Centroid } from "../spatial/centroid";
import {SearchComparablesEnum} from "../../enum/search-comparables.enum";

export class ComparableSalesRequest {

    //search by radius
    center: Centroid;
    point: Centroid;
    radiusInMeters: number;

    //search by polygon
    polygons: any[];

    //search by municipality
    municipalityList: any[];
    municipalityListSize: number;
    munPolygons: any[];

    //sale date
    lastDays: number;   //preset
    minDate: string;    //calendar
    maxDate: string;    //calendar

    //property type (non-mps)
    condo: boolean;
    freehold: boolean;

    //property type (mps)
    propertyType: number | null;

    //property code
    propertyCodeList: any[];
    propertyTypeCodeListSize: number;

    //total build area
    buildTotalAreaFrom: number | null;
    buildTotalAreaTo: number;

    //price amount
    minAmount: number;
    maxAmount: number;

    //assessment amount
    minAssessmentValue: number;
    maxAssessmentValue: number;

    //structure year built
    buildYearFrom: number | string;
    buildYearTo: number | string;

    //lot size
    minArea: string;
    maxArea: string;

    //others required by backend
    mps: boolean;
    lro: number;
    saveAsDefault: boolean;

    //other properties required for 3G are defined below

    //centroid regardless of search type
    searchCenter: Centroid;

    searchBy: SearchComparablesEnum;

    usesAcres: boolean = false;

}
