import { ConfirmDialogExtraData } from "./confirm-dialog-extra-data";

export class ConfirmDialogData extends ConfirmDialogExtraData {
  constructor(  title: string, content: string[], firstButtonText: string, secondButtonText: string, showFirstButton: boolean = true) {
    super();

    this.title = title;
    this.content = content;
    this.firstButtonText = firstButtonText;
    this.secondButtonText = secondButtonText;
    this.showFirstButton = showFirstButton;
  }

  title: string;
  content: string[];
  firstButtonText: string;
  secondButtonText: string;
  showFirstButton: boolean = true;
}
