import {Component, inject, OnInit} from '@angular/core';
import {UserService} from "../../../shared/service/user.service";
import {User} from "../../../core/model/user/user";
import {lastValueFrom, Observable} from "rxjs";
import {EstoreService} from "../../../shared/service/estore.service";
import {UserAccessControl} from "../../../core/model/user/user-access-control";
import {DataService} from "../../../shared/service/data.service";
import {Download} from "../../../core/model/download/download";
import {DownloadService} from "../../../shared/service/download.service";
import {FulfillmentItem} from "../../../core/model/download/fulfillment-item";
import {Router} from '@angular/router';
import {LicenseCounters} from "../../../core/model/user/license-counters";
import {UrlService} from "../../../shared/service/url.service";
import {EstoreProductCategoryEnum} from "../../../core/enum/estore-product-category-enum";
import {LicensePackage} from '../../../core/model/user/license-package';


@Component({
  selector: 'gema3g-account-overview',
  templateUrl: './account-overview.component.html',
  styleUrls: ['./account-overview.component.scss']
})
export class AccountOverviewComponent implements OnInit {

  constructor() {
    this.loadUser().then(r => { this.isLoading = false });
  }

  private urlService = inject(UrlService);
  private userService = inject(UserService);
  private downloadService = inject(DownloadService);
  private router = inject(Router);
  private eStoreService = inject(EstoreService);
  private dataService = inject(DataService);

  isLoading = true;
  user$: Observable<User>;
  user: User;
  download$: Observable<Download>;
  userLicenseCounters$: Observable<LicenseCounters>;
  userAccessControl: UserAccessControl;
  defaultProfilePhotoSource: string = this.dataService.defaultProfilePhotoSource;
  hasSearchComparableReports: boolean = false;
  userLicensePackageDetails$: Observable<LicensePackage>;
  
  async ngOnInit(): Promise<void> {
    this.userAccessControl = this.userService.getUserAccessControl();
    this.user = await lastValueFrom(this.userService.getUser());
    this.hasSearchComparableReports = !!this.user.userProfile.licensePackageId;
  }

  async loadUser() {
    this.user$ = this.userService.getUser();
    this.download$ = this.downloadService.getDefaultDownloads();
    this.userLicenseCounters$ = this.userService.getUserLicenseCounters();
    this.userLicensePackageDetails$ = this.userService.getUserLicensePackageDetails();
  }

  downloadReport(fulfillmentId: number | undefined, itemId: number | undefined) {
    this.downloadService.downloadReport(fulfillmentId, itemId);
  }

  openStatusPOPup(item: FulfillmentItem) {
    this.downloadService.openPYBReportStatus(item);
  }

  async redirectToGWHInvoicePage(transactionId: string | undefined) {
    await this.eStoreService.redirectToGWHInvoicePage(transactionId);
  }

  async renewSubscription() {
    await this.urlService.openEstoreCatalogueWithCategory(EstoreProductCategoryEnum.GWH_RENEW_SUBSCRIPTION);
  }

  downloadAccountStatement(type: string, quantity: number) {
    this.downloadService.downloadAccountStatement(type, quantity);
  }

  async openStoreFrontRetail() {
    await this.router.navigate(['/catalogue']).then(() => {
      this.eStoreService.scrollToCatalogueProductListAnchor(true);
    });
  }
}
