import { MapUtility } from "../../../../shared/utility/map.utility";
import { MapTileEnum } from "../../../../core/enum/map-tile-enum";
import { FirstBaseSolutionService } from "../../../../shared/service/first-base-solution.service";
import { LoggerService } from "../../../../shared/service/log/logger.service";

export class TileBase {

  constructor(public available: boolean, public tileServerHttpProtocol: string | undefined, public tileServerUrl: string | undefined, public titleAuthenticationKey: string | undefined, private loggerService?: LoggerService) {
  }

  useLocalGeoServer = false;
  defaultTileWidth: number = 256;
  defaultTileHeight: number = 256;
  defaultTileSize: google.maps.Size = new google.maps.Size(this.defaultTileWidth, this.defaultTileHeight);
  tileSizeUOM: string = 'px';
  borderWidth: string = '0' + this.tileSizeUOM;
  borderStyle: string = 'solid';
  imageDimension: string = '100%';
  name: string;
  minZoom: number = 0;
  maxZoom: number = 0;

  getMapTile(tileSize: google.maps.Size, tileCoordinates: google.maps.Point, zoomLevel: number, ownerDocument: Document, layer: string, extraParams?: Map<string, any>, opacity: number = 0.5): Element {

    var tile = ownerDocument.createElement('div');
    tile.style.width = tileSize.width + this.tileSizeUOM;
    tile.style.height = tileSize.height + this.tileSizeUOM;
    tile.style.borderStyle = this.borderStyle;
    tile.style.borderWidth = this.borderWidth;

    if (layer == MapTileEnum.THEMATIC_TILE) {
      tile.style.opacity = opacity.toString(2);
    }

    let appendParams: string = '';
    if (extraParams) {
      for (let [key, value] of extraParams.entries()) {
        appendParams = appendParams + '&' + key + '=' + value;
      }
    }

    var tileUrl;
    if (this.name == MapTileEnum.FIRST_BASE_SOLUTION_TILE) {
      tileUrl = this.getFBSTileUrl(tileCoordinates, zoomLevel, layer, appendParams);
    } else {
      tileUrl = this.getTileUrl(tileCoordinates, zoomLevel, layer, appendParams);
    }

    var img = document.createElement('img');
    img.src = tileUrl;
    img.style.width = this.imageDimension;
    img.style.height = this.imageDimension;
    img.style.position = 'absolute';

    img.onload = function () {
      tile.appendChild(img);
    }

    img.onerror = function () {
      // trap the 404 error to prevent the missing tile image error message from appearing
    }

    return tile;
  }


  private getTileUrl(tileCoordinates: google.maps.Point, zoomLevel: number, layer: string, appendParams: string) {
    if (this.useLocalGeoServer) {
      const quadKey = MapUtility.tileToQuadKey(tileCoordinates.x, tileCoordinates.y, zoomLevel);
      let baseUrl: string = "http://localhost:8080/geoserver/ne/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&STYLES&LAYERS=ne%3AGMAP_ARN_POLY&exceptions=application%2Fvnd.ogc.se_inimage&SRS=EPSG%3A3857&WIDTH=256&HEIGHT=256";
      const msve = `&viewparams=msve_id:${quadKey}`;
      const bbox = MapUtility.tileToBoundingBox(tileCoordinates.x, tileCoordinates.y, zoomLevel)?.getBBoxParam();

      let tileUrl = baseUrl + msve + bbox;
      this.loggerService?.logDebug(`local tile server url: ${tileUrl}`);
      return tileUrl;
    }

    let tileUrl: string = this.tileServerHttpProtocol + '://' + this.tileServerUrl + '/gwh_tiles/' + MapUtility.tileToQuadKey(tileCoordinates.x, tileCoordinates.y, zoomLevel) + '.png?layers=' + layer + appendParams;
    this.loggerService?.logDebug(`tile url: ${tileUrl}`);
    return tileUrl;
  }

  private getFBSTileUrl(tileCoordinates: google.maps.Point, zoomLevel: number, layer: string, appendParams: string) {
    var bound = Math.pow(2, zoomLevel);
    let tileUrl: string = FirstBaseSolutionService.TILE_URL + `/${zoomLevel}/` + tileCoordinates.x + '/' + (bound - tileCoordinates.y - 1) + '.' + 'png?UID=' + FirstBaseSolutionService.UNIQUE_ID;
    this.loggerService?.logDebug(`fbs tile url: ${tileUrl}`);
    return tileUrl;
  }

  getEmptyMapTile(tileSize: google.maps.Size, tileCoordinates: google.maps.Point, zoomLevel: number, ownerDocument: Document, layer: string): Element {
    var tile = ownerDocument.createElement('div');
    tile.style.width = tileSize.width + this.tileSizeUOM;
    tile.style.height = tileSize.height + this.tileSizeUOM;
    tile.style.borderStyle = this.borderStyle;
    tile.style.borderWidth = this.borderWidth;

    return tile;
  }

  isWithinAllowableZoomLevels = (minZoomLevel: number, maxZoomLevel: number, zoom: number): boolean => {
    return (zoom >= minZoomLevel && zoom <= maxZoomLevel) ? true : false;
  }

  isDisplayPermitted = (): boolean => {
    return this.available;
  }

  getLayerName = (): string => {
    return this.name;
  }
}
