<!-- <div #searchComparablesFormContainer class="search-comparables-form-container dense-2"> -->
<div class="flex flex-col gap-0">
  <div class="flex flex-row items-center bg-[#5e5e5e]">
    <div id="scf-sc-label" class="flex w-9/12 pl-2 py-2 text-white">
      Search Criteria
      @if (resultsScreenVisible) {
        (Modifying)
      }
    </div>
    <div class="flex w-3/12 justify-end pr-2">
      <span id="scf-icon-lm-close-form" (click)="closeForm()">
        <fa-icon class="fa-lg text-white" [icon]="faCircleXmark"></fa-icon>
      </span>
    </div>
  </div>
</div>
<div #searchComparablesFormContainer class="search-comparables-form-container">
  <div class="flex flex-col gap-1 get-dense-3">
    <form id="scf-form" [formGroup]="form" (ngSubmit)="onSubmitForm()">
      <p-accordion id="scf-accordion" #accordion [activeIndex]="0">
        <p-accordionTab id="scf-accordionTab-searchby" #searchByTab>
          <ng-template pTemplate="header">
            <div class="flex align-items-center">
              <span id="scf-searchby-label" class="vertical-align-middle">Search By</span>
            </div>
          </ng-template>
          <mat-radio-group id="scf-radio-group-searchby" [(ngModel)]="selectedSearchBy"
            [ngModelOptions]="{standalone: true}" (change)="onSearchByChange($event)"
            aria-labelledby="search by radius, polygon or municipality"
            class="mx-2 mt-2 mb-1 flex flex-col gap-3">
            @for (searchBy of searchByTypes; track searchBy) {
              <mat-radio-button [disabled]="submitted"
                id="{{'id-' + searchBy}}" [value]="searchBy" [checked]="selectedSearchBy == searchBy"
                              [ngClass]="{
                                'search-by-option-visible': searchBy == 'Radius' || searchBy == 'Polygon',
                                'search-by-option-hidden': (searchBy == 'Municipality' && !userAccessControls.isMpsUser)
                            }">
                @if (searchBy == 'Radius') {
                  <div class="flex flex-row items-center gap-3 radius">
                    <div id="scf-radius-label">{{ searchBy }}</div>
                    <mat-form-field id="scf-ff-radius" appearance="outline" [style.width.px]="140">
                      <mat-label id="scf-label-radius">Radius</mat-label>
                      <mat-select id="radiusSelect" #radiusSelect
                        [formControlName]="'radiusInMeters'"
                        (selectionChange)="onRadiusSelected($event, false)">
                        @for (radius of radiusDropDown; track radius) {
                          <mat-option id="scf-option-radius-{{getConvertedRadius(radius)}}"
                            [value]="radius">
                            {{ getConvertedRadius(radius) }}
                          </mat-option>
                        }
                      </mat-select>
                    </mat-form-field>
                    @if (sameCondoSearchOption) {
                      <div #sameCondoCheckBox class="flex flex-row items-center no-wrap gap-2">
                        <mat-checkbox id="scf-checkbox-sameCondo" color="primary" [disabled]="submitted"
                          [checked]="sameCondoSearch" (change)="onSameCondoSearchChange($event)">
                          <span id="scf-sameCondo-label">Same condo</span>
                        </mat-checkbox>
                        @if (hasSameCondoTooltipText()) {
                          <fa-icon class="fa-lg text-[#428BCA]" [icon]="faCircleInfo"
                            [satPopoverAnchor]="sameCondoPopoverTooltip"
                            (mouseover)="sameCondoPopoverTooltip.open()"
                            (mouseout)="sameCondoPopoverTooltip.close()">
                          </fa-icon>
                          <sat-popover #sameCondoPopoverTooltip [autoFocus]="false" [restoreFocus]="false"
                            verticalAlign="above" openTransition="500ms ease-out"
                            closeTransition="100ms ease-in">
                            <gema3g-tooltip [content]="sameCondoTooltipText"></gema3g-tooltip>
                          </sat-popover>
                        }
                      </div>
                    }
                    @if (!sameCondoSearchOption) {
                      <div class="flex flex-row items-center no-wrap gap-2">
                        <fa-icon class="fa-lg text-[#428BCA]" [icon]="faCircleInfo"
                          [satPopoverAnchor]="freeholdPopoverTooltip"
                          (mouseover)="freeholdPopoverTooltip.open()"
                          (mouseout)="freeholdPopoverTooltip.close()">
                        </fa-icon>
                        <sat-popover #freeholdPopoverTooltip [autoFocus]="false" [restoreFocus]="false"
                          verticalAlign="above" openTransition="500ms ease-out"
                          closeTransition="100ms ease-in">
                          <gema3g-tooltip [content]="freeholdTooltipText"></gema3g-tooltip>
                        </sat-popover>
                      </div>
                    }
                  </div>
                }
                @if (searchBy == 'Polygon') {
                  <div class="flex flex-row items-center gap-3">
                    <div id="scf-polygon-label">{{ searchBy }}</div>
                    <div class="flex flex-row justify-start items-center">
                      @if (userDrawingPolygon) {
                        <button id="scf-btn-cancel-polygon" #drawPolygonBtn color="primary" type="button"
                          mat-raised-button [disabled]="submitted" (click)="cancelPolygonDrawing()">
                          Cancel
                        </button>
                      } @else {
                        <button id="scf-btn-draw-polygon" #drawPolygonBtn color="primary" type="button"
                          mat-raised-button [disabled]="submitted" (click)="drawUserPolygon()">
                          Draw on the map
                        </button>
                      }
                      <!--
                      <span id="scf-polygon-count" class="polygons-count-label"
                        matBadge="{{remainingPolygons}}" matBadgeOverlap="true">Polygons
                        remaining
                      </span>
                      -->
                    </div>
                    <div id="scf-polygon-count">({{ remainingPolygons }} of {{ maximumSearchPolygonsAllowed }} remaining)</div>
                  </div>
                }
                @if (searchBy == 'Municipality') {
                  <div class="flex flex-row items-center gap-3">
                    <div id="scf-muni-label">{{ searchBy }}</div>
                    <div id="scf-municipality-count">
                      ({{ selectedMunicipalitiesCount }} of {{ municipalitiesCount }} selected)
                    </div>
                  </div>
                }
              </mat-radio-button>
            }
          </mat-radio-group>
          @if (userAccessControls.isMpsUser) {
            <div class="flex flex-col mx-2">
              <div class="multi-list-outer">
                <div class="multi-list-inner" (click)="focusMunicipalities()">
                  <mat-selection-list id="scf-selection-list-muni" [disabled]="submitted"
                    #municipalitiesList [multiple]="true"
                    (selectionChange)="onMunicipalitiesListSelectionChange($event)">
                    @for (municipality of municipalities; track municipality) {
                      <mat-list-option id="scf-list-option-muni-{{municipality.municipality}}"
                        [value]="municipality"
                        togglePosition="before" [disableRipple]="true">
                        <span pTooltip="{{municipality.municipality}}" tooltipPosition="top">
                        {{ municipality.municipality }}</span>
                      </mat-list-option>
                    }
                  </mat-selection-list>
                </div>
                <div class="multi-list-controls">
                  <button id="scf-btn-muni-add" color="primary" type="button" mat-raised-button
                    (click)="addMunicipality()" [disabled]="submitted"
                    [satPopoverAnchor]="addMunicipalityTooltipPopover"
                    (mouseover)="addMunicipalityTooltipPopover.open()"
                    (mouseout)="addMunicipalityTooltipPopover.close()">
                    <fa-icon id="scf-muni-add-icon" class="fa-sm text-white" [icon]="faArrowDown">
                    </fa-icon>
                  </button>
                  <sat-popover #addMunicipalityTooltipPopover [autoFocus]="false" [restoreFocus]="false"
                    verticalAlign="above" openTransition="500ms ease-out"
                    closeTransition="100ms ease-in">
                    <gema3g-tooltip [content]="DS.SEARCH_COMPARABLES_ADD_MUNICIPALITY_TOOLTIP_TEXT"></gema3g-tooltip>
                  </sat-popover>
                  <button id="scf-btn-muni-add-all" color="primary" type="button" mat-raised-button
                    (click)="focusMunicipalities(); addAllMunicipalities()" [disabled]="submitted"
                    [satPopoverAnchor]="addAllMunicipalityTooltipPopover"
                    (mouseover)="addAllMunicipalityTooltipPopover.open()"
                    (mouseout)="addAllMunicipalityTooltipPopover.close()">
                    <fa-icon id="scf-muni-add-all-icon" class="fa-sm text-white"
                      [icon]="faArrowDownWideShort">
                    </fa-icon>
                  </button>
                  <sat-popover #addAllMunicipalityTooltipPopover [autoFocus]="false"
                    [restoreFocus]="false" verticalAlign="above" openTransition="500ms ease-out"
                    closeTransition="100ms ease-in">
                    <gema3g-tooltip [content]="DS.SEARCH_COMPARABLES_ADD_ALL_MUNICIPALITIES_TOOLTIP_TEXT"></gema3g-tooltip>
                  </sat-popover>
                </div>
              </div>
              <div class="multi-list-outer">
                <div class="multi-list-inner">
                  <mat-selection-list id="scf-selection-list-selected-muni" [disabled]="submitted"
                    #selectedMunicipalitiesList [multiple]="true">
                    @for (selectedMunicipality of selectedMunicipalities; track selectedMunicipality) {
                      <mat-list-option
                        id="scf-list-option-selected-muni-{{selectedMunicipality.municipality}}"
                        [value]="selectedMunicipality" togglePosition="before" [disableRipple]="true">
                        <span pTooltip="{{selectedMunicipality.municipality}}" tooltipPosition="top">
                        {{ selectedMunicipality.municipality }}</span>
                      </mat-list-option>
                    }
                  </mat-selection-list>
                </div>
                <div class="multi-list-controls">
                  <button id="scf-btn-muni-remove" color="primary" type="button" mat-raised-button
                    (click)="removeSelectedMunicipality()" [disabled]="submitted"
                    [satPopoverAnchor]="removeMunicipalityTooltipPopover"
                    (mouseover)="removeMunicipalityTooltipPopover.open()"
                    (mouseout)="removeMunicipalityTooltipPopover.close()">
                    <fa-icon id="scf-muni-remove-icon" class="fa-sm text-white" [icon]="faArrowUp">
                    </fa-icon>
                  </button>
                  <sat-popover #removeMunicipalityTooltipPopover [autoFocus]="false"
                    [restoreFocus]="false" verticalAlign="above" openTransition="500ms ease-out"
                    closeTransition="100ms ease-in">
                    <gema3g-tooltip [content]="DS.SEARCH_COMPARABLES_REMOVE_MUNICIPALITY_TOOLTIP_TEXT"></gema3g-tooltip>
                  </sat-popover>
                  <button id="scf-btn-muni-remove-all" color="primary" type="button" mat-raised-button
                    (click)="removeAllSelectedMunicipalities()" [disabled]="submitted"
                    [satPopoverAnchor]="removeAllMunicipalityTooltipPopover"
                    (mouseover)="removeAllMunicipalityTooltipPopover.open()"
                    (mouseout)="removeAllMunicipalityTooltipPopover.close()">
                    <fa-icon id="scf-muni-remove-all-icon" class="fa-sm text-white"
                      [icon]="faArrowUpWideShort">
                    </fa-icon>
                  </button>
                  <sat-popover #removeAllMunicipalityTooltipPopover [autoFocus]="false"
                    [restoreFocus]="false" verticalAlign="above" openTransition="500ms ease-out"
                    closeTransition="100ms ease-in">
                    <gema3g-tooltip [content]="DS.SEARCH_COMPARABLES_REMOVE_ALL_MUNICIPALITIES_TOOLTIP_TEXT"></gema3g-tooltip>
                  </sat-popover>
                </div>
              </div>
            </div>
          }
        </p-accordionTab>
        <p-accordionTab id="scf-accordionTab-filterBy" #filterByTab>
          <ng-template pTemplate="header">
            <div class="flex align-items-center">
              <span id="scf-filterby-label" class="vertical-align-middle">Filter Results By</span>
            </div>
          </ng-template>

          <div class="submit-container py-4">
            <div class="controls">
              <a id="scf-link-reset-1" (click)="resetFormData()" class="anchor"
              [ngClass]="{'disabled-link': submitted}">Reset form</a>
              <button id="scf-btn-submit-1" type="submit" mat-raised-button color="primary"
                [disabled]="submitted">
                <span id="scf-submit-label-1" class="mr-1"
                  [satPopoverAnchor]="findComparablesPopoverTop"
                  (mouseover)="findComparablesPopoverTop.open()"
                (mouseout)="findComparablesPopoverTop.close()">Find Comparables</span>
                <sat-popover #findComparablesPopoverTop [autoFocus]="false" [restoreFocus]="false"
                  verticalAlign="above" openTransition="500ms ease-out"
                  closeTransition="100ms ease-in">
                  <gema3g-tooltip [content]="findComparablesTooltipText"></gema3g-tooltip>
                </sat-popover>
                @if (submitted) {
                  <mat-icon id="scf-icon-submitted" matSuffix>
                    <mat-spinner id="scf-spinner" diameter="18"></mat-spinner>
                  </mat-icon>
                }
              </button>
            </div>
          </div>

          <div class="flex flex-col mx-2">
            <div class="flex flex-row items-center">
              <div id="scf-sd-label" class="flex-1 mb-2">Sale Dates</div>
              <div class="flex-1 flex flex-row justify-end">
                <mat-radio-group id="scf-radio-group-sd" class="flex flex-row gap-2 items-center mb-2"
                  aria-label="search by preset settings or by calendar">
                  <mat-radio-button id="scf-radio-button-preset" value="preset"
                    [checked]="selectedSalePeriodMethod == 'preset'"
                    (click)="selectedSalePeriodMethod = 'preset'" [disabled]="submitted">Preset
                  </mat-radio-button>
                  <mat-radio-button id="scf-radio-button-calendar" value="calendar"
                    [checked]="selectedSalePeriodMethod == 'calendar'"
                    (click)="selectedSalePeriodMethod = 'calendar'" [disabled]="submitted">Calendar
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            @if (selectedSalePeriodMethod == 'preset') {
              <div class="flex flex-row items-center">
                <mat-button-toggle-group id="scf-button-toggle-group-sp" [value]="selectedSalePeriod"
                  formControlName="lastDays" class="flex-1" aria-label="Sale period">
                  <mat-button-toggle id="scf-button-toggle-30D" value="30D"
                    (click)="selectedSalePeriod = '30D'" class="flex-1" [disabled]="submitted">30
                    Days
                  </mat-button-toggle>
                  <mat-button-toggle id="scf-button-toggle-3M" value="3M"
                    (click)="selectedSalePeriod = '3M'" class="flex-1" [disabled]="submitted">3
                    Months
                  </mat-button-toggle>
                  <mat-button-toggle id="scf-button-toggle-6M" value="6M"
                    (click)="selectedSalePeriod = '6M'" class="flex-1" [disabled]="submitted">6
                    Months
                  </mat-button-toggle>
                  <mat-button-toggle id="scf-button-toggle-1Y" value="1Y"
                    (click)="selectedSalePeriod = '1Y'" class="flex-1" [disabled]="submitted">1
                    Year
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            }
            @if (selectedSalePeriodMethod == 'calendar') {
              <div class="flex flex-row items-center gap-1">
                <div class="flex-1">
                  <mat-form-field id="scf-ff-calendar-start" appearance="outline" [style.width.%]="80">
                    <mat-label id="scf-label-calendar-start">Start</mat-label>
                    <input class="max-w-[100px]" name="selectedFromSaleDate" matInput
                      [matDatepicker]="fromDatePicker" id="minDate" formControlName="minDate"
                      [max]="maxSaleDate" placeholder="YYYY/MM/DD"
                      (dateChange)="onSelectedFromSaleDateChange($event)" (blur)="onBlurInput($event)"
                      [disabled]="submitted">
                      <mat-datepicker-toggle id="scf-datepicker-toggle-calendar-start" matIconSuffix
                      [for]="fromDatePicker"></mat-datepicker-toggle>
                      <mat-datepicker id="scf-datepicker-calendar-start" #fromDatePicker></mat-datepicker>
                      @if (form.get('minDate')?.touched && form.get('minDate')?.errors?.['invalidDate']) {
                        <mat-error id="scf-error-calendar-start-invalid"
                          >
                          Invalid date
                        </mat-error>
                      }
                    </mat-form-field>
                  </div>
                  <div class="flex-1">
                    <mat-form-field id="scf-ff-calendar-end" appearance="outline" [style.width.%]="80">
                      <mat-label id="scf-label-calendar-end">End</mat-label>
                      <input class="max-w-[100px]" name="selectedToSaleDate" matInput
                        [matDatepicker]="toDatePicker" id="maxDate" formControlName="maxDate"
                        [max]="maxSaleDate" placeholder="YYYY/MM/DD"
                        (dateChange)="onSelectedToSaleDateChange($event)" (blur)="onBlurInput($event)"
                        [disabled]="submitted">
                        <mat-datepicker-toggle id="scf-datepicker-toggle-calendar-end" matIconSuffix
                        [for]="toDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker id="scf-datepicker-calendar-end" #toDatePicker></mat-datepicker>
                        @if (form.get('maxDate')?.touched && form.get('maxDate')?.errors?.['invalidDate']) {
                          <mat-error id="scf-error-calendar-end-invalid"
                            >
                            Invalid date
                          </mat-error>
                        }
                      </mat-form-field>
                    </div>
                  </div>
                }
                @if (form.get('minDate')?.errors?.['invalidValueRange'] && form.get('maxDate')?.errors?.['invalidValueRange']) {
                  <mat-error id="scf-error-calendar-range"
                    >
                    'To' date should be greater than 'From' date.
                  </mat-error>
                }
              </div>
              @if (!userAccessControls.isMpsUser) {
                <div class="flex flex-col mx-2 my-3">
                  <div id="scf-pt-not-mps-label" class="flex flex-row items-center mb-2">Property Type</div>
                  <div class="flex flex-row items-center">
                    <mat-button-toggle-group id="scf-button-toggle-group-pt" [value]="selectedPropertyType"
                      formControlName="propertyType" class="flex-1" aria-label="Property Type">
                      <mat-button-toggle id="scf-button-toggle-all" value="ALL"
                        (click)="selectedPropertyType = 'ALL'" class="flex-1" [disabled]="submitted">ALL
                      </mat-button-toggle>
                      <mat-button-toggle id="scf-button-toggle-freehold" value="Freehold"
                        (click)="selectedPropertyType = 'Freehold'" class="flex-1" [disabled]="submitted">
                        Freehold
                      </mat-button-toggle>
                      <mat-button-toggle id="scf-button-toggle-condo" value="condo"
                        (click)="selectedPropertyType = 'condo'" class="flex-1" [disabled]="submitted">Condo
                      </mat-button-toggle>
                    </mat-button-toggle-group>
                  </div>
                </div>
              }
              @if (userAccessControls.isMpsUser) {
                <div class="flex flex-col mx-2 my-3">
                  <div id="scf-pt-mps-label" class="flex flex-row items-center mb-2">Property Type</div>
                  <div class="flex flex-row items-center">
                    <mat-form-field id="scf-ff-pt-mps" appearance="outline" class="flex-1">
                      <!-- <mat-label>Property Type</mat-label> -->
                      <mat-select id="scf-select-pt-mps" formControlName="propertyTypeMPS"
                        [disabled]="submitted">
                        @for (propertyTypeMPS of propertyTypesMPS; track propertyTypeMPS) {
                          <mat-option id="scf-option-pt-mps-{{propertyTypeMPS.label}}"
                            [value]="propertyTypeMPS.code">
                            {{ propertyTypeMPS.label }}
                          </mat-option>
                        }
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              }
              @if (userAccessControls.isMpsUser) {
                <div class="flex flex-col mx-2 my-3">
                  <div id="scf-ba-label" class="flex flex-row items-center mb-2">{{ buildAreaLabel }}</div>
                  <div class="flex flex-row items-center">
                    <div class="flex-1">
                      <mat-form-field id="scf-ff-ba-from" appearance="outline" [style.width.%]="80">
                        <mat-label id="scf-label-ba-from">From</mat-label>
                        <input matInput type="text" #trigger="matAutocompleteTrigger" id="minBuildTotalArea"
                          formControlName="minBuildTotalArea" placeholder="Build area from"
                          [matAutocomplete]="autoMinBuildArea" (blur)="onBlurInput($event)">
                          <button mat-icon-button matSuffix type="button">
                            <mat-icon>arrow_drop_down</mat-icon>
                          </button>
                          <mat-autocomplete id="scf-autocomplete-ba-from" #matAutocomplete
                            #autoMinBuildArea="matAutocomplete" [displayWith]="displayFieldValueSelection">
                            @for (totalBuildArea of totalBuildAreas; track totalBuildArea) {
                              <mat-option id="scf-option-ba-from-{{totalBuildArea.display}}"
                                [value]="totalBuildArea.value">{{ totalBuildArea.display }}
                              </mat-option>
                            }
                          </mat-autocomplete>
                          @if (form.get('minBuildTotalArea')?.touched && form.get('minBuildTotalArea')?.errors?.['exceededMaximumValue']) {
                            <mat-error id="scf-error-ba-from-over-max"
                              >
                              Exceeded maximum value
                            </mat-error>
                          }
                          @if (form.get('minBuildTotalArea')?.touched && form.get('minBuildTotalArea')?.errors?.['invalidNumberFormat']) {
                            <mat-error id="scf-error-ba-from-invalid"
                              >
                              Invalid value
                            </mat-error>
                          }
                        </mat-form-field>
                      </div>
                      <div class="flex-1">
                        <mat-form-field id="scf-ff-ba-to" appearance="outline" [style.width.%]="80">
                          <mat-label id="scf-label-ba-to">To</mat-label>
                          <input matInput type="text" #trigger="matAutocompleteTrigger" id="maxBuildTotalArea"
                            formControlName="maxBuildTotalArea" placeholder="Build area to"
                            maxBuildTotalArea [matAutocomplete]="autoMaxBuildArea"
                            (blur)="onBlurInput($event)">
                            <button mat-icon-button matSuffix type="button">
                              <mat-icon>arrow_drop_down</mat-icon>
                            </button>
                            <mat-autocomplete id="scf-autocomplete-ba-to" #matAutocomplete
                              #autoMaxBuildArea="matAutocomplete" [displayWith]="displayFieldValueSelection">
                              @for (totalBuildArea of totalBuildAreas; track totalBuildArea) {
                                <mat-option id="scf-option-ba-to-{{totalBuildArea.display}}"
                                  [value]="totalBuildArea.value">{{ totalBuildArea.display }}
                                </mat-option>
                              }
                            </mat-autocomplete>
                            @if (form.get('maxBuildTotalArea')?.touched && form.get('maxBuildTotalArea')?.errors?.['exceededMaximumValue']) {
                              <mat-error id="scf-error-ba-to-over-max"
                                >
                                Exceeded maximum value
                              </mat-error>
                            }
                            @if (form.get('maxBuildTotalArea')?.touched && form.get('maxBuildTotalArea')?.errors?.['invalidNumberFormat']) {
                              <mat-error id="scf-error-ba-to-invalid"
                                >
                                Invalid value
                              </mat-error>
                            }
                          </mat-form-field>
                        </div>
                      </div>
                      @if (form.get('minBuildTotalArea')?.errors?.['invalidValueRange'] && form.get('maxBuildTotalArea')?.errors?.['invalidValueRange']) {
                        <mat-error id="scf-error-ba-range"
                          >
                          'To' area should be greater than 'From' area.
                        </mat-error>
                      }
                    </div>
                  }
                  <div class="flex flex-col mx-2 my-3">
                    <div class="value-slider">
                      <div id="scf-pa-label" class="left">Price Amount ($)</div>
                      <div class="right">
                        <mat-slider id="scf-slider-pa" class="flex-1"
                          [min]="priceAmountSlider.thumbStartValue()"
                          [max]="priceAmountSlider.thumbEndValue()"
                          [step]="priceAmountSlider.step"
                          [disabled]="submitted"
                          [discrete]="true"
                          [displayWith]="formatLabel">
                          <input id="scf-pa-slider-start" value="{{priceAmountSlider.startValue}}" (dragEnd)="amountSliderValueChanged($event, 'minAmount')" matSliderStartThumb>
                          <input id="scf-pa-slider-end" value="{{priceAmountSlider.endValue}}" (dragEnd)="amountSliderValueChanged($event, 'maxAmount')" matSliderEndThumb>
                        </mat-slider>
                      </div>
                    </div>
                        <div class="flex flex-row items-center gap-1">
                          <div class="flex-1">
                            <mat-form-field id="scf-ff-pa-from" appearance="outline" [style.width.%]="80">
                              <mat-label id="scf-label-pa-from">From</mat-label>
                              <!--                  <span matPrefix class="ml-6">$</span>-->
                              <input matInput type="text" #trigger="matAutocompleteTrigger" id="minAmount"
                                formControlName="minAmount" placeholder="Price amount from"
                                [matAutocomplete]="autoMinAmount" (blur)="onBlurInput($event)">
                                <button mat-icon-button matSuffix type="button">
                                  <mat-icon>arrow_drop_down</mat-icon>
                                </button>
                                <mat-autocomplete id="scf-autocomplete-pa-from" #matAutocomplete
                                  #autoMinAmount="matAutocomplete" [displayWith]="displayFieldValueSelection" (optionSelected)="optionSelected($event, 'minAmount')">
                                  @for (price of minPriceRange; track price) {
                                    <mat-option id="scf-option-pa-from-{{price.value}}"
                                      [value]="price.id">
                                      {{ price.value }}
                                    </mat-option>
                                  }
                                </mat-autocomplete>
                                @if (form.get('minAmount')?.touched && form.get('minAmount')?.errors?.['exceededMaximumValue']) {
                                  <mat-error id="scf-error-pa-from-over-max"
                                    >
                                    Exceeded maximum value
                                  </mat-error>
                                }
                                @if (form.get('minAmount')?.touched && form.get('minAmount')?.errors?.['invalidNumberFormat']) {
                                  <mat-error id="scf-error-pa-from-invalid"
                                    >
                                    Invalid value
                                  </mat-error>
                                }
                              </mat-form-field>
                            </div>
                            <div class="flex-1">
                              <mat-form-field id="scf-ff-pa-to" appearance="outline" [style.width.%]="80">
                                <mat-label id="scf-label-pa-to">To</mat-label>
                                <!--                  <span matPrefix class="ml-6">$</span>-->
                                <input matInput type="text" #trigger="matAutocompleteTrigger" id="maxAmount"
                                  formControlName="maxAmount" placeholder="Price amount to"
                                  [matAutocomplete]="autoMaxAmount" (blur)="onBlurInput($event)">
                                  <button mat-icon-button matSuffix type="button">
                                    <mat-icon>arrow_drop_down</mat-icon>
                                  </button>
                                  <mat-autocomplete id="scf-autocomplete-pa-to" #matAutocomplete
                                    #autoMaxAmount="matAutocomplete" [displayWith]="displayFieldValueSelection" (optionSelected)="optionSelected($event, 'maxAmount')">
                                    @for (price of maxPriceRange; track price) {
                                      <mat-option id="scf-option-pa-to-{{price.value}}"
                                        [value]="price.id">{{ price.value }}
                                      </mat-option>
                                    }
                                  </mat-autocomplete>
                                  @if (form.get('maxAmount')?.touched && form.get('maxAmount')?.errors?.['exceededMaximumValue']) {
                                    <mat-error id="scf-error-pa-to-over-max"
                                      >
                                      Exceeded maximum value
                                    </mat-error>
                                  }
                                  @if (form.get('maxAmount')?.touched && form.get('maxAmount')?.errors?.['invalidNumberFormat']) {
                                    <mat-error id="scf-error-pa-to-invalid"
                                      >
                                      Invalid value
                                    </mat-error>
                                  }
                                </mat-form-field>
                              </div>
                            </div>
                            @if (form.get('minAmount')?.errors?.['invalidValueRange'] && form.get('maxAmount')?.errors?.['invalidValueRange']) {
                              <mat-error id="scf-error-pa-range"
                                >
                                'To' amount should be greater than 'From' amount.
                              </mat-error>
                            }
                  </div>
                          @if (userAccessControls.isMpsUser) {
                            <div class="flex flex-col mx-2 my-3">
                              <div class="value-slider">
                                <div id="scf-av-label" class="left">Assessed Value Range ($)</div>
                                <div class="right">
                                  <mat-slider id="scf-slider-av" class="flex-1"
                                    [min]="assessmentAmountSlider.thumbStartValue()"
                                    [max]="assessmentAmountSlider.thumbEndValue()"
                                    [step]="assessmentAmountSlider.step"
                                    [disabled]="submitted"
                                    [discrete]="true"
                                    [displayWith]="formatLabel">
                                    <input id="scf-av-slider-start" value="{{assessmentAmountSlider.startValue}}" (dragEnd)="amountSliderValueChanged($event, 'minAssessmentAmount')" matSliderStartThumb>
                                    <input id="scf-av-slider-end" value="{{assessmentAmountSlider.endValue}}" (dragEnd)="amountSliderValueChanged($event, 'maxAssessmentAmount')" matSliderEndThumb>
                                  </mat-slider>
                                </div>
                              </div>
                              <div class="flex flex-row items-center gap-1">
                                <div class="flex-1">
                                  <mat-form-field id="scf-ff-av-from" appearance="outline" [style.width.%]="80">
                                    <mat-label id="scf-label-av-from">From</mat-label>
                                    <!--                  <span matPrefix class="ml-6">$</span>-->
                                    <input matInput type="text" #trigger="matAutocompleteTrigger"
                                      id="minAssessmentAmount" formControlName="minAssessmentAmount"
                                      placeholder="Assessment amount from" [matAutocomplete]="autoMinAssessedAmount"
                                      (blur)="onBlurInput($event)" #inputMinAssessedAmount>
                                      <button mat-icon-button matSuffix type="button">
                                        <mat-icon>arrow_drop_down</mat-icon>
                                      </button>
                                      <mat-autocomplete id="scf-autocomplete-av-from" #matAutocomplete
                                        #autoMinAssessedAmount="matAutocomplete"
                                        [displayWith]="displayFieldValueSelection"
                                        (optionSelected)="optionSelected($event, 'minAssessmentAmount')"
                                        >
                                        @for (assessment of minAssessmentRange; track assessment) {
                                          <mat-option id="scf-option-av-from-{{assessment.value}}"
                                            [value]="assessment.id">
                                            {{ assessment.value }}
                                          </mat-option>
                                        }
                                      </mat-autocomplete>
                                      @if (form.get('minAssessmentAmount')?.touched && form.get('minAssessmentAmount')?.errors?.['exceededMaximumValue']) {
                                        <mat-error id="scf-error-av-from-over-max"
                                          >
                                          Exceeded maximum value
                                        </mat-error>
                                      }
                                      @if (form.get('minAssessmentAmount')?.touched && form.get('minAssessmentAmount')?.errors?.['invalidNumberFormat']) {
                                        <mat-error id="scf-error-av-from-invalid"
                                          >
                                          Invalid value
                                        </mat-error>
                                      }
                                    </mat-form-field>
                                  </div>
                                  <div class="flex-1">
                                    <mat-form-field id="scf-ff-av-to" appearance="outline" [style.width.%]="80">
                                      <mat-label id="scf-label-av-to">To</mat-label>
                                      <!--                  <span matPrefix class="ml-6">$</span>-->
                                      <input matInput type="text" #trigger="matAutocompleteTrigger"
                                        id="maxAssessmentAmount" formControlName="maxAssessmentAmount"
                                        placeholder="Assessment amount to" [matAutocomplete]="autoMaxAssessedAmount"
                                        (blur)="onBlurInput($event)">
                                        <button mat-icon-button matSuffix type="button">
                                          <mat-icon>arrow_drop_down</mat-icon>
                                        </button>
                                        <mat-autocomplete id="scf-autocomplete-av-to" #matAutocomplete
                                          #autoMaxAssessedAmount="matAutocomplete"
                                          [displayWith]="displayFieldValueSelection"
                                          (optionSelected)="optionSelected($event, 'maxAssessmentAmount')"
                                          >
                                          @for (assessment of maxAssessmentRange; track assessment) {
                                            <mat-option id="scf-option-av-to-{{assessment.value}}"
                                              [value]="assessment.id">
                                              {{ assessment.value }}
                                            </mat-option>
                                          }
                                        </mat-autocomplete>
                                        @if (form.get('maxAssessmentAmount')?.touched && form.get('maxAssessmentAmount')?.errors?.['exceededMaximumValue']) {
                                          <mat-error id="scf-error-av-to-over-max"
                                            >
                                            Exceeded maximum value
                                          </mat-error>
                                        }
                                        @if (form.get('maxAssessmentAmount')?.touched && form.get('maxAssessmentAmount')?.errors?.['invalidNumberFormat']) {
                                          <mat-error id="scf-error-av-to-invalid"
                                            >
                                            Invalid value
                                          </mat-error>
                                        }
                                      </mat-form-field>
                                    </div>
                                  </div>
                                  @if (form.get('minAssessmentAmount')?.errors?.['invalidValueRange'] && form.get('maxAssessmentAmount')?.errors?.['invalidValueRange']) {
                                    <mat-error id="scf-error-av-range"
                                      >
                                      'To' amount should be greater than 'From' amount.
                                    </mat-error>
                                  }
                                </div>
                              }
                              @if (userAccessControls.isMpsUser) {
                                <div class="flex flex-col mx-2 my-3">
                                  <div id="scf-syb-label" class="lex flex-row items-center mb-2">Structure Year Built</div>
                                  <div class="flex flex-row items-center gap-1">
                                    <div class="flex-1">
                                      <mat-form-field id="scf-ff-syb-from" appearance="outline" [style.width.%]="80">
                                        <mat-label id="scf-label-syb-from">From</mat-label>
                                        <input matInput type="text" #trigger="matAutocompleteTrigger" id="minYearBuilt"
                                          formControlName="minYearBuilt" placeholder="Build year from"
                                          [matAutocomplete]="autoMinYearBuilt" (blur)="onBlurInput($event)">
                                          <button mat-icon-button matSuffix type="button">
                                            <mat-icon>arrow_drop_down</mat-icon>
                                          </button>
                                          <mat-autocomplete id="scf-autocomplete-syb-from" #matAutocomplete
                                            #autoMinYearBuilt="matAutocomplete">
                                            @for (yearBuilt of yearBuiltFrom; track yearBuilt) {
                                              <mat-option id="scf-option-syb-from-{{yearBuilt}}"
                                                [value]="yearBuilt">{{ yearBuilt }}
                                              </mat-option>
                                            }
                                          </mat-autocomplete>
                                          @if (form.get('minYearBuilt')?.touched && form.get('minYearBuilt')?.errors?.['exceededMaximumValue']) {
                                            <mat-error id="scf-error-syb-from-over-max"
                                              >
                                              Exceeded maximum value
                                            </mat-error>
                                          }
                                          @if (form.get('minYearBuilt')?.touched && form.get('minYearBuilt')?.errors?.['exceededMaximumYear']) {
                                            <mat-error id="scf-error-syb-from-over-current"
                                              >
                                              'From' year cannot exceed {{ thisYear }}
                                            </mat-error>
                                          }
                                          @if (form.get('minYearBuilt')?.touched && form.get('minYearBuilt')?.errors?.['invalidNumberFormat']) {
                                            <mat-error id="scf-error-syb-from-invalid"
                                              >
                                              Invalid value
                                            </mat-error>
                                          }
                                        </mat-form-field>
                                      </div>
                                      <div class="flex-1">
                                        <mat-form-field id="scf-ff-syb-to" appearance="outline" [style.width.%]="80">
                                          <mat-label id="scf-label-syb-to">To</mat-label>
                                          <input matInput type="text" #trigger="matAutocompleteTrigger" id="maxYearBuilt"
                                            formControlName="maxYearBuilt" placeholder="Build year to"
                                            [matAutocomplete]="autoMaxYearBuilt" (blur)="onBlurInput($event)">
                                            <button mat-icon-button matSuffix type="button">
                                              <mat-icon>arrow_drop_down</mat-icon>
                                            </button>
                                            <mat-autocomplete id="scf-autocomplete-syb-to" #matAutocomplete
                                              #autoMaxYearBuilt="matAutocomplete">
                                              @for (yearBuilt of yearBuiltTo; track yearBuilt) {
                                                <mat-option id="scf-option-syb-to-{{yearBuilt}}"
                                                  [value]="yearBuilt">{{ yearBuilt }}
                                                </mat-option>
                                              }
                                            </mat-autocomplete>
                                            @if (form.get('maxYearBuilt')?.touched && form.get('maxYearBuilt')?.errors?.['exceededMaximumValue']) {
                                              <mat-error id="scf-error-syb-to-over-max"
                                                >
                                                Exceeded maximum value
                                              </mat-error>
                                            }
                                            @if (form.get('maxYearBuilt')?.touched && form.get('maxYearBuilt')?.errors?.['exceededMaximumYear']) {
                                              <mat-error id="scf-error-syb-to-over-current"
                                                >
                                                'To' year cannot exceed {{ thisYear }}
                                              </mat-error>
                                            }
                                            @if (form.get('maxYearBuilt')?.touched && form.get('maxYearBuilt')?.errors?.['invalidNumberFormat']) {
                                              <mat-error id="scf-error-syb-to-invalid"
                                                >
                                                Invalid value
                                              </mat-error>
                                            }
                                          </mat-form-field>
                                        </div>
                                      </div>
                                      @if (form.get('minYearBuilt')?.errors?.['invalidValueRange'] && form.get('maxYearBuilt')?.errors?.['invalidValueRange']) {
                                        <mat-error id="scf-error-syb-range"
                                          >
                                          'To' year should be greater than 'From' year.
                                        </mat-error>
                                      }
                                    </div>
                                  }
                                  <div class="flex flex-col mx-2 my-3">
                                    <div id="scf-ls-label" class="flex flex-row items-center mb-2">{{ lotSizeLabel }}</div>
                                    <div class="flex flex-row items-center gap-1 mt-1">
                                      <div class="flex-1">
                                        <mat-form-field id="scf-ff-ls-from" appearance="outline" [style.width.%]="80">
                                          <mat-label id="scf-label-ls-from">From</mat-label>
                                          <mat-select id="scf-select-ls-from" formControlName="minArea"
                                            [disabled]="submitted">
                                            <mat-option id="scf-option-ls-from-{{getConvertedLotSize(1)}}"
                                              [value]="getConvertedLotSizeValue(1)">{{ getConvertedLotSize(1) }}
                                            </mat-option>
                                            <mat-option id="scf-option-ls-from-{{getConvertedLotSize(2)}}"
                                              [value]="getConvertedLotSizeValue(2)">{{ getConvertedLotSize(2) }}
                                            </mat-option>
                                            <mat-option id="scf-option-ls-from-{{getConvertedLotSize(3)}}"
                                              [value]="getConvertedLotSizeValue(3)">{{ getConvertedLotSize(3) }}
                                            </mat-option>
                                            <mat-option id="scf-option-ls-from-{{getConvertedLotSize(4)}}"
                                              [value]="getConvertedLotSizeValue(4)">{{ getConvertedLotSize(4) }}
                                            </mat-option>
                                            <mat-option id="scf-option-ls-from-{{getConvertedLotSize(5)}}"
                                              [value]="getConvertedLotSizeValue(5)">{{ getConvertedLotSize(5) }}
                                            </mat-option>
                                            <mat-option id="scf-option-ls-from-{{getConvertedLotSize(6)}}"
                                              [value]="getConvertedLotSizeValue(6)">{{ getConvertedLotSize(6) }}
                                            </mat-option>
                                            <mat-option id="scf-option-ls-from-{{getConvertedLotSize(7)}}"
                                              [value]="getConvertedLotSizeValue(7)">{{ getConvertedLotSize(7) }}
                                            </mat-option>
                                            <mat-option id="scf-option-ls-from-{{getConvertedLotSize(8)}}"
                                              [value]="getConvertedLotSizeValue(8)">{{ getConvertedLotSize(8) }}
                                            </mat-option>
                                            <mat-option id="scf-option-ls-from-{{getConvertedLotSize(9)}}"
                                              [value]="getConvertedLotSizeValue(9)">{{ getConvertedLotSize(9) }}
                                            </mat-option>
                                            <mat-option id="scf-option-ls-from-{{getConvertedLotSize(10)}}"
                                              [value]="getConvertedLotSizeValue(10)">{{ getConvertedLotSize(10) }}
                                            </mat-option>
                                            <mat-option id="scf-option-ls-from-{{getConvertedLotSize(11)}}"
                                              [value]="getConvertedLotSizeValue(11)">{{ getConvertedLotSize(11) }}
                                            </mat-option>
                                            <mat-option id="scf-option-ls-from-{{getConvertedLotSize(12)}}"
                                              [value]="getConvertedLotSizeValue(12)">{{ getConvertedLotSize(12) }}
                                            </mat-option>
                                            <mat-option id="scf-option-ls-from-{{getConvertedLotSize(13)}}"
                                              [value]="getConvertedLotSizeValue(13)">{{ getConvertedLotSize(13) }}
                                            </mat-option>
                                            <mat-option id="scf-option-ls-from-{{getConvertedLotSize(14)}}"
                                              [value]="getConvertedLotSizeValue(14)">{{ getConvertedLotSize(14) }}
                                            </mat-option>
                                          </mat-select>
                                          @if (form.get('minArea')?.touched && form.get('minArea')?.errors?.['exceededMaximumValue']) {
                                            <mat-error id="scf-error-ls-from-over-max"
                                              >
                                              Exceeded maximum value
                                            </mat-error>
                                          }
                                          @if (form.get('minArea')?.touched && form.get('minArea')?.errors?.['invalidNumberFormat']) {
                                            <mat-error id="scf-error-ls-from-invalid"
                                              >
                                              Invalid value
                                            </mat-error>
                                          }
                                        </mat-form-field>
                                      </div>
                                      <div class="flex-1">
                                        <mat-form-field id="scf-ff-ls-to" appearance="outline" [style.width.%]="80">
                                          <mat-label id="scf-label-ls-to-lbl">To</mat-label>
                                          <mat-select id="scf-select-ls-to" formControlName="maxArea" [disabled]="submitted">
                                            <mat-option id="scf-option-ls-to-{{getConvertedLotSize(2)}}"
                                              [value]="getConvertedLotSizeValue(2)" (click)="changeFromlotSize('2')">
                                              {{ getConvertedLotSize(2) }}
                                            </mat-option>
                                            <mat-option id="scf-option-ls-to-{{getConvertedLotSize(3)}}"
                                              [value]="getConvertedLotSizeValue(3)" (click)="changeFromlotSize('3')">
                                              {{ getConvertedLotSize(3) }}
                                            </mat-option>
                                            <mat-option id="scf-option-ls-to-{{getConvertedLotSize(4)}}"
                                              [value]="getConvertedLotSizeValue(4)" (click)="changeFromlotSize('4')">
                                              {{ getConvertedLotSize(4) }}
                                            </mat-option>
                                            <mat-option id="scf-option-ls-to-{{getConvertedLotSize(5)}}"
                                              [value]="getConvertedLotSizeValue(5)" (click)="changeFromlotSize('5')">
                                              {{ getConvertedLotSize(5) }}
                                            </mat-option>
                                            <mat-option id="scf-option-ls-to-{{getConvertedLotSize(6)}}"
                                              [value]="getConvertedLotSizeValue(6)" (click)="changeFromlotSize('6')">
                                              {{ getConvertedLotSize(6) }}
                                            </mat-option>
                                            <mat-option id="scf-option-ls-to-{{getConvertedLotSize(7)}}"
                                              [value]="getConvertedLotSizeValue(7)" (click)="changeFromlotSize('7')">
                                              {{ getConvertedLotSize(7) }}
                                            </mat-option>
                                            <mat-option id="scf-option-ls-to-{{getConvertedLotSize(8)}}"
                                              [value]="getConvertedLotSizeValue(8)" (click)="changeFromlotSize('8')">
                                              {{ getConvertedLotSize(8) }}
                                            </mat-option>
                                            <mat-option id="scf-option-ls-to-{{getConvertedLotSize(9)}}"
                                              [value]="getConvertedLotSizeValue(9)" (click)="changeFromlotSize('9')">
                                              {{ getConvertedLotSize(9) }}
                                            </mat-option>
                                            <mat-option id="scf-option-ls-to-{{getConvertedLotSize(10)}}"
                                              [value]="getConvertedLotSizeValue(10)" (click)="changeFromlotSize('10')">
                                              {{ getConvertedLotSize(10) }}
                                            </mat-option>
                                            <mat-option id="scf-option-ls-to-{{getConvertedLotSize(11)}}"
                                              [value]="getConvertedLotSizeValue(11)" (click)="changeFromlotSize('11')">
                                              {{ getConvertedLotSize(11) }}
                                            </mat-option>
                                            <mat-option id="scf-option-ls-to-{{getConvertedLotSize(12)}}"
                                              [value]="getConvertedLotSizeValue(12)" (click)="changeFromlotSize('121')">
                                              {{ getConvertedLotSize(12) }}
                                            </mat-option>
                                            <mat-option id="scf-option-ls-to-{{getConvertedLotSize(13)}}"
                                              [value]="getConvertedLotSizeValue(13)" (click)="changeFromlotSize('13')">
                                              {{ getConvertedLotSize(13) }}
                                            </mat-option>
                                            <mat-option id="scf-option-ls-to-{{getConvertedLotSize(14)}}"
                                              [value]="getConvertedLotSizeValue(14)" (click)="changeFromlotSize('14')">
                                              {{ getConvertedLotSize(14) }}
                                            </mat-option>
                                            <mat-option id="scf-option-ls-to-{{getConvertedLotSize(15)}}"
                                              [value]="getConvertedLotSizeValue(15)" (click)="changeFromlotSize('15')">
                                              {{ getConvertedLotSize(15) }}
                                            </mat-option>
                                          </mat-select>
                                          @if (form.get('maxArea')?.touched && form.get('maxArea')?.errors?.['exceededMaximumValue']) {
                                            <mat-error id="scf-error-ls-to-over-max"
                                              >
                                              Exceeded maximum value
                                            </mat-error>
                                          }
                                          @if (form.get('maxArea')?.touched && form.get('maxArea')?.errors?.['invalidNumberFormat']) {
                                            <mat-error id="scf-error-ls-to-invalid"
                                              >
                                              Invalid value
                                            </mat-error>
                                          }
                                        </mat-form-field>
                                      </div>
                                    </div>
                                    @if (form.get('minArea')?.errors?.['invalidValueRange'] && form.get('maxArea')?.errors?.['invalidValueRange']) {
                                      <mat-error id="scf-error-ls-range"
                                        >
                                        'To' size should be greater than 'From' size.
                                      </mat-error>
                                    }
                                  </div>
                                  @if (userAccessControls.isMpsUser) {
                                    <div class="flex flex-col mx-2 my-3">
                                      <div id="scf-pc-label" class="property-code-title"
                                        [satPopoverAnchor]="propertyCodeTooltipPopover"
                                        (mouseover)="propertyCodeTooltipPopover.open()"
                                        (mouseout)="propertyCodeTooltipPopover.close()">
                                        <div class="text">Property Code</div>
                                        <div id="scf-pc-count">
                                          ({{ selectedPropertyCodesCount }} of {{ propertyCodesCount }} selected)
                                        </div>
                                        <div class="info">
                                          <fa-icon id="scf-property-code-tooltip-icon" class="fa-sm text-[#428BCA]"
                                            [icon]="faCircleInfo">
                                          </fa-icon>
                                        </div>
                                        <sat-popover #propertyCodeTooltipPopover [autoFocus]="false" [restoreFocus]="false"
                                          verticalAlign="above" openTransition="500ms ease-out" closeTransition="100ms ease-in">
                                          <gema3g-tooltip [content]="propertyCodeTooltipText"></gema3g-tooltip>
                                        </sat-popover>
                                      </div>
                                      <div class="multi-list-outer">
                                        <div class="multi-list-inner">
                                          <mat-selection-list id="scf-selection-list-pc" [disabled]="submitted" #propertyCodesList
                                            [multiple]="true">
                                            @for (propertyCode of propertyCodes; track propertyCode) {
                                              <mat-list-option id="scf-list-option-pc-{{propertyCode.code}}"
                                                [value]="propertyCode"
                                                togglePosition="before" [disableRipple]="true">
                                                <span pTooltip="{{propertyCode.description}}"
                                                  tooltipPosition="top">{{ propertyCode.code }}
                                                {{ propertyCode.description }}</span>
                                              </mat-list-option>
                                            }
                                          </mat-selection-list>
                                        </div>
                                        <div class="multi-list-controls">
                                          <button id="scf-btn-pc-add" color="primary" type="button" mat-raised-button
                                            (click)="addPropertyCode()" [disabled]="submitted"
                                            [satPopoverAnchor]="addPropertyCodeTooltipPopover"
                                            (mouseover)="addPropertyCodeTooltipPopover.open()"
                                            (mouseout)="addPropertyCodeTooltipPopover.close()">
                                            <fa-icon id="scf-pc-add-icon" class="fa-sm text-white" [icon]="faArrowDown">
                                            </fa-icon>
                                          </button>
                                          <sat-popover #addPropertyCodeTooltipPopover [autoFocus]="false" [restoreFocus]="false"
                                            verticalAlign="above" openTransition="500ms ease-out"
                                            closeTransition="100ms ease-in">
                                            <gema3g-tooltip [content]="DS.SEARCH_COMPARABLES_ADD_PROPERTY_CODE_TOOLTIP_TEXT"></gema3g-tooltip>
                                          </sat-popover>
                                          <button id="scf-btn-pc-add-all" color="primary" type="button" mat-raised-button
                                            (click)="addAllPropertyCodes()" [disabled]="submitted"
                                            [satPopoverAnchor]="addAllPropertyCodeTooltipPopover"
                                            (mouseover)="addAllPropertyCodeTooltipPopover.open()"
                                            (mouseout)="addAllPropertyCodeTooltipPopover.close()">
                                            <fa-icon id="scf-pc-add-all-icon" class="fa-sm text-white"
                                              [icon]="faArrowDownWideShort">
                                            </fa-icon>
                                          </button>
                                          <sat-popover #addAllPropertyCodeTooltipPopover [autoFocus]="false"
                                            [restoreFocus]="false" verticalAlign="above" openTransition="500ms ease-out"
                                            closeTransition="100ms ease-in">
                                            <gema3g-tooltip [content]="DS.SEARCH_COMPARABLES_ADD_ALL_PROPERTY_CODES_TOOLTIP_TEXT"></gema3g-tooltip>
                                          </sat-popover>
                                        </div>
                                      </div>
                                      <div class="multi-list-outer">
                                        <div class="multi-list-inner">
                                          <mat-selection-list id="scf-selection-list-selected-pc" #selectedPropertyCodesList
                                            [multiple]="true" [disabled]="submitted">
                                            @for (selectedPropertyCode of selectedPropertyCodes; track selectedPropertyCode) {
                                              <mat-list-option id="scf-list-option-selected-pc-{{selectedPropertyCode.code}}"
                                                [value]="selectedPropertyCode" togglePosition="before" [disableRipple]="true">
                                                <span pTooltip="{{selectedPropertyCode.description}}"
                                                  tooltipPosition="top">{{ selectedPropertyCode.code }}
                                                {{ selectedPropertyCode.description }}</span>
                                              </mat-list-option>
                                            }
                                          </mat-selection-list>
                                        </div>
                                        <div class="multi-list-controls">
                                          <button id="scf-btn-pc-remove" color="primary" type="button" mat-raised-button
                                            (click)="removeSelectedPropertyCode()" [disabled]="submitted"
                                            [satPopoverAnchor]="removePropertyCodeTooltipPopover"
                                            (mouseover)="removePropertyCodeTooltipPopover.open()"
                                            (mouseout)="removePropertyCodeTooltipPopover.close()">
                                            <fa-icon id="scf-pc-remove-icon" class="fa-sm text-white" [icon]="faArrowUp">
                                            </fa-icon>
                                          </button>
                                          <sat-popover #removePropertyCodeTooltipPopover [autoFocus]="false"
                                            [restoreFocus]="false" verticalAlign="above" openTransition="500ms ease-out"
                                            closeTransition="100ms ease-in">
                                            <gema3g-tooltip [content]="DS.SEARCH_COMPARABLES_REMOVE_PROPERTY_CODE_TOOLTIP_TEXT"></gema3g-tooltip>
                                          </sat-popover>
                                          <button id="scf-btn-pc-remove-all" color="primary" type="button" mat-raised-button
                                            (click)="removeAllSelectedPropertyCodes()" [disabled]="submitted"
                                            [satPopoverAnchor]="removeAllPropertyCodeTooltipPopover"
                                            (mouseover)="removeAllPropertyCodeTooltipPopover.open()"
                                            (mouseout)="removeAllPropertyCodeTooltipPopover.close()">
                                            <fa-icon id="scf-pc-remove-all-icon" class="fa-sm text-white"
                                              [icon]="faArrowUpWideShort">
                                            </fa-icon>
                                          </button>
                                          <sat-popover #removeAllPropertyCodeTooltipPopover [autoFocus]="false"
                                            [restoreFocus]="false" verticalAlign="above" openTransition="500ms ease-out"
                                            closeTransition="100ms ease-in">
                                            <gema3g-tooltip [content]="DS.SEARCH_COMPARABLES_REMOVE_ALL_PROPERTY_CODES_TOOLTIP_TEXT"></gema3g-tooltip>
                                          </sat-popover>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                </p-accordionTab>
                              </p-accordion>
                              <!--------------------------------------------------------------------------->
                              <div class="submit-container">
                                <div class="controls">
                                  <a id="scf-link-reset-2" (click)="resetFormData()" class="anchor"
                                  [ngClass]="{'disabled-link': submitted}">Reset form</a>
                                  <button id="scf-btn-submit-2" type="submit" mat-raised-button color="primary"
                                    [disabled]="submitted">
                                    <span id="scf-submit-label-2" class="mr-1" [satPopoverAnchor]="findComparablesPopoverBottom"
                                      (mouseover)="findComparablesPopoverBottom.open()"
                                    (mouseout)="findComparablesPopoverBottom.close()">Find Comparables</span>
                                    <sat-popover #findComparablesPopoverBottom [autoFocus]="false" [restoreFocus]="false"
                                      verticalAlign="above" openTransition="500ms ease-out" closeTransition="100ms ease-in">
                                      <gema3g-tooltip [content]="findComparablesTooltipText"></gema3g-tooltip>
                                    </sat-popover>
                                    @if (submitted) {
                                      <mat-icon id="scf-icon-submitted" matSuffix>
                                        <mat-spinner id="scf-spinner" diameter="18"></mat-spinner>
                                      </mat-icon>
                                    }
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
