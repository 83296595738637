export class BaseStructure{

  constructor(baseStructure? : BaseStructure) {
    if (baseStructure) {
      for (let property in baseStructure) {
        if (baseStructure.hasOwnProperty(property)) {
          // @ts-ignore
          this[property] = baseStructure[property];
        }
      }
    }
  }

  floorLevel: string;
  clearHeight: number;
  buildingArea: number;
  interiorOfficeSpace: number;
  yearBuilt: number;
}
