import { AfterViewInit, Component, effect, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild, signal, WritableSignal, inject } from '@angular/core';
import { PropertyDetail } from '../../../../core/model/property/property-detail';
import * as _ from 'lodash';
import dayjs from "dayjs";
import { lastValueFrom, takeUntil } from 'rxjs';
import { PropertyReportMapService } from '../property-report-map.service';
import { PropertyReportSectionEnum } from '../../../../core/enum/property-report-section-enum';
import { AdBannerRotationService } from '../../../../shared/service/ad-banner-rotation.service';
import { AdBanner } from '../../../../core/model/banner/ad-banner';
import { AdBannerWeightEnum } from '../../../../core/enum/ad-banner-weight-enum';
import { PropertyReportComponentBase } from '../property-report-component-base';
import { UserAccessControl } from '../../../../core/model/user/user-access-control';
import { MatTableDataSource } from "@angular/material/table";
import { PlanSurvey } from '../../../../core/model/plan/plan-survey';
import { PlanSurveySearchService } from '../../../../shared/service/search/plan-survey-search.service';
import { PropertyHistory } from '../../../../core/model/property/property-history';
import { PropertyReportSearchService } from '../../../../shared/service/search/property-report-search.service';
import { PropertyReportService } from '../../../../shared/service/property-report.service';
import { EStoreProduct } from "../../../../core/model/product/e-store/e-store-product";
import { AdBannerAction } from "../../../../core/model/banner/ad-banner-action";
import { AdBannerActionEnum } from "../../../../core/enum/ad-banner-action-enum";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ImageDialogComponent } from "../../../../core/component/modal/image-dialog/image-dialog.component";
import { defaultErrorMatSnackBarConfig, defaultResponsiveOptions } from "../../../../shared/constant/constants";
import { SelectedProperty } from "../../../../core/model/property/selected-property";
import { EstoreService } from "../../../../shared/service/estore.service";
import { PlanSurveyMapItData } from '../../../../core/model/plan/plan-survey-map-it-data';
import { SelectionModel } from "@angular/cdk/collections";
import { CarouselService } from "../../../../shared/service/carousel.service";
import { LoggerService } from '../../../../shared/service/log/logger.service';
import { EStoreParam } from "../../../../core/model/product/e-store/e-store-param";
import { EstoreProductTypeEnum } from '../../../../core/enum/estore-product-type.enum';
import { MapPlansSurveyComponent } from "./map-plans-survey/map-plans-survey.component";
import { GoogleAnalyticsService } from "../../../../shared/service/google-analytics.service";
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { MatPaginator, PageEvent } from "@angular/material/paginator";

@Component({
  selector: 'gema3g-property-report-plans-surveys',
  templateUrl: './plans-surveys.component.html',
  styleUrls: ['./plans-surveys.component.scss']
})
export class PlansSurveysComponent extends PropertyReportComponentBase implements OnInit, AfterViewInit {
  constructor() {
    super();
  }

  private adBannerRotationService = inject(AdBannerRotationService);
  private planSurveySearchService = inject(PlanSurveySearchService);
  private propertyReportSearchService = inject(PropertyReportSearchService);
  private _snackBar = inject(MatSnackBar);
  private eStoreService = inject(EstoreService);
  private carouselService = inject(CarouselService);
  private gaService = inject(GoogleAnalyticsService);

  @ViewChild('moreOrLessPropertyHistory', {static: false}) moreOrLessPropertyHistoryElemRef: ElementRef;
  @ViewChild(MapPlansSurveyComponent) mapPlansSurveyComponent: MapPlansSurveyComponent;
  subjectProperty: PropertyDetail;
  @Input() selectedProperty: SelectedProperty;
  @Input() userAccessControls: UserAccessControl;
  @Input() planSurveyCarouselProducts: EStoreProduct[] = [];
  @Input() easementCarouselProducts: EStoreProduct[] = [];
  propertyReportSection: PropertyReportSectionEnum = PropertyReportSectionEnum.PLANS_AND_SURVEYS;
  plansSurveysMap: google.maps.Map;
  planSurveyListItems: PlanSurvey[] = [];
  planSurveyDataSource = new MatTableDataSource<PlanSurvey>();
  selection = new SelectionModel<PlanSurvey>(false, []);
  planSurveyDisplayedColumns: string[] = ['registrationYear', 'planCategory', 'documentNumber', 'price', 'buyOrView'];
  planSurveyDataExists: WritableSignal<boolean> = signal(false);
  planSurveyData: any[] = [];
  parentPinCreationYear: number;
  parentPropertyHistoryListItems: PropertyHistory[] = [];
  parentPropertyHistoryDataSource = new MatTableDataSource<PropertyHistory>();
  parentPropertyHistoryDisplayedColumns: string[] = ['creationYear', 'eventType', 'pin', 'status'];
  parentPropertyHistoryData: any[] = [];
  parentPinEventExists: boolean = false;
  parentPinToolTip: string = '';
  childPropertyHistoryListExpanded: boolean = false;
  childPropertyHistoryListItems: PropertyHistory[] = [];
  childPropertyHistoryDataSource = new MatTableDataSource<PropertyHistory>();
  childPropertyHistoryDisplayedColumns: string[] = ['creationYear', 'eventType', 'pin', 'status'];
  childPropertyHistoryData: any[] = [];
  childPinEventExists: boolean = false;
  childPinToolTip: string = '';
  parentPinEventType: string;
  childPinEventType: string;
  isEasementApiUnavailable: boolean = false;
  isEasementError: boolean = false;
  easementCount: number;
  responsiveOptions = defaultResponsiveOptions;
  selectPlanLabel: string = 'PLAN';
  selectedPlanNumber: string = 'Select a Plan to view on map';
  faCircleInfo = faCircleInfo;
  parentPropertyHistoryPageIndex: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  refreshUI = async () => {
    this.initializeAdBanners();
    this.getPlanAndSurveyDetails().then(() => {
      if (this.planSurveyDataExists()){
        setTimeout(() => {
          this.mapPlansSurveyComponent.initializeMap().then(() => {
            this.mapPlanSurvey(this.planSurveyListItems[0]);
          });
        }, 500);
      }
    });
    this.getPropertyHistory();
    this.getEasementCount();
  }

  getPlanAndSurveyDetails = async () => {

    //this.loggerService.logDebug('Plan survey :: getPlanAndSurveyDetails');
    if (this.subjectProperty !== undefined && this.subjectProperty.pii !== undefined) {
      this.planSurveyData = await lastValueFrom(this.planSurveySearchService.getPlanAndSurveyDetails(this.subjectProperty.pii?.pin), {defaultValue: {}});

      this.planSurveyDataExists.set(!_.isEmpty(this.planSurveyData));

      this.planSurveyListItems = [];
      if (this.planSurveyDataExists()) {
        //this.loggerService.logDebug('Plan survey :: planSurveyDataExists');
        this.planSurveyListItems.push(...this.planSurveyData.map(data => {

          let planSurvey: PlanSurvey = new PlanSurvey();

          // ported and modified code below from gema 2g
          // plan category
          if (data.planCategory) {
            if (data.planCategory === 'S') {
              planSurvey.planCategory = 'P';
            } else if (data.planCategory === 'R' || data.planCategory === 'P') {
              planSurvey.planCategory = data.planCategory;
            } else {
              planSurvey.planCategory = PropertyReportComponentBase.NOT_APPLICABLE;
            }
          } else {
            planSurvey.planCategory = PropertyReportComponentBase.NOT_APPLICABLE;
          }

          // registration year
          if (data.registrationDate) {
            planSurvey.registrationYear = dayjs(data.registrationDate).year();
          } else if (data.surveyDate && data.surveyDate != PropertyReportComponentBase.NOT_APPLICABLE) {
            planSurvey.registrationYear = parseInt(data.surveyDate);
          } else if (data.surveyDate && data.surveyDate == PropertyReportComponentBase.NOT_APPLICABLE) {
            planSurvey.registrationYear = parseInt('-1');
          } else {
            planSurvey.registrationYear = parseInt('-1');
          }

          // document number
          if (data.documentNumber) {
            planSurvey.documentNumber = data.documentNumber;
          } else if (data.planCategory === 'P') {
            planSurvey.documentNumber = 'Survey Plan';
          } else {
            planSurvey.documentNumber = PropertyReportComponentBase.NOT_APPLICABLE;
          }

          if (data.originalDocumentNumber) {
            planSurvey.originalDocumentNumber = data.originalDocumentNumber;
          }

          // plan description
          if (data.planDesc) {
            planSurvey.planDesc = data.planDesc;
          } else if (data.planCategory === 'P' && data.companyFullName != null) {
            planSurvey.planDesc = data.companyFullName;
          } else {
            planSurvey.planDesc = PropertyReportComponentBase.NOT_APPLICABLE;
          }

          // other fields
          if (data.lro) {
            planSurvey.lro = data.lro;
          } else {
            planSurvey.lro = this.subjectProperty.pii.lro;  //TODO need to verify this
          }

          if (data.token) {
            planSurvey.tokenStr = data.token;
          }
          
          if (data.mapItData) {
            planSurvey.mapItData = data.mapItData;
          }

          if (data.companyBEId) {
            planSurvey.companyBEId = data.companyBEId;
          }

          if (data.surveyImageId) {
            planSurvey.surveyImageId = data.surveyImageId;
          }

          if (data.enabledForSale) {
            planSurvey.enabledForSale = data.enabledForSale;
          }

          if (data.surveyTypeDesc) {
            planSurvey.surveyTypeDesc = data.surveyTypeDesc;
          }

          if (data.originalSurveyDate) {
            planSurvey.originalSurveyDate = data.originalSurveyDate;
          }

          if (data.companySurveyId) {
            planSurvey.companySurveyId = data.companySurveyId;
          }

          if (data.planSubCategory) {
            planSurvey.planSubCategory = data.planSubCategory;
          }

          planSurvey.price = data.price;

          return planSurvey;
        }));

        this.planSurveyDataSource = new MatTableDataSource(this.planSurveyListItems);
      }
    }
  }

  getEasementCount = async () => {
    //this.loggerService.logDebug('Plan survey :: getEasementCount');
    if (this.subjectProperty !== undefined && this.subjectProperty.pii !== undefined) {
      try {
        let searchResult: any = await lastValueFrom(this.planSurveySearchService.getEasementCount(this.subjectProperty?.pii?.pin), {defaultValue: {}});
        if (searchResult) {
          if(searchResult.hasOwnProperty('easement_count')) {
            this.easementCount = searchResult.easement_count;
          } else{
            this.isEasementError = true;
          }
        }
      } catch (e) {
        this.isEasementApiUnavailable = true;
      }
    }
  }

  getPropertyHistory = async () => {

    this.parentPropertyHistoryListItems = [];
    //this.loggerService.logDebug('Plan survey :: getPropertyHistory');
    if (this.subjectProperty !== undefined && this.subjectProperty.pii !== undefined) {
      let searchResults = await lastValueFrom(this.propertyReportSearchService.getPropertyHistoryByPin(this.subjectProperty?.pii?.pin), {defaultValue: {}});

      if (searchResults) {
        if (searchResults.creationDate) {
          this.parentPinCreationYear = new Date(searchResults.creationDate).getFullYear();
        }

        if (searchResults.parentPinEventType) {
          this.parentPinEventExists = true;
          this.parentPinEventType = searchResults.parentPinEventType;
          if (this.parentPinEventType == 'Split') {
            this.parentPinToolTip = "The subject property was created by dividing a larger parcel into two or more smaller property parcels.";
          } else if (this.parentPinEventType == 'Consolidation') {
            this.parentPinToolTip = "Two or more property parcels were combined to create the subject property.";
          } else if (this.parentPinEventType == 'Re-entry') {
            this.parentPinToolTip = "The subject property was created as a result of a re-entry (e.g. conversion from Registry to Land Title) within the Land Registration System of Ontario.<br/><br/>Please purchase a Parcel Register title record for complete information on the property.";
          }
          searchResults.parentPins.forEach((pin: any) => {
            let propertyHistory = new PropertyHistory();
            propertyHistory.eventType = searchResults.parentPinEventType;
            propertyHistory.pin = pin.pin;
            propertyHistory.creationYear = new Date(pin.creationDate).getFullYear();
            propertyHistory.status = pin.landRegistryStatus == 'A' ? 'Active' : 'Inactive';

            this.parentPropertyHistoryListItems.push(propertyHistory);
          })

          setTimeout( () => {
            this.parentPropertyHistoryDataSource = new MatTableDataSource(this.parentPropertyHistoryListItems);
            this.parentPropertyHistoryDataSource.paginator = this.paginator;
          },200);
        }

        this.childPropertyHistoryListItems = [];
        this.childPinEventExists = false;
        if (searchResults.childPinEventType) {
          this.childPinEventExists = true;
          this.childPinEventType = searchResults.childPinEventType;
          if (this.childPinEventType == 'Split') {
            this.childPinToolTip = "The subject property was created by dividing a larger parcel into two or more smaller property parcels.";
          } else if (this.childPinEventType == 'Consolidation') {
            this.childPinToolTip = "Two or more property parcels were combined to create the subject property.";
          } else if (this.childPinEventType == 'Re-entry') {
            this.childPinToolTip = "The subject property was created as a result of a re-entry (e.g. conversion from Registry to Land Title) within the Land Registration System of Ontario.<br/><br/>Please purchase a Parcel Register title record for complete information on the property.";
          }
          searchResults.childPins.forEach((pin: any) => {
            let propertyHistory = new PropertyHistory();
            propertyHistory.eventType = searchResults.childPinEventType;
            propertyHistory.pin = pin.pin;
            propertyHistory.creationYear = new Date(pin.creationDate).getFullYear();
            propertyHistory.status = pin.landRegistryStatus == 'A' ? 'Active' : 'Inactive';

            this.childPropertyHistoryListItems.push(propertyHistory);
          })
        }
        this.loadChildPropertyHistoryDataSource();
      }
    }

  }

  loadChildPropertyHistoryDataSource() {
    if (this.childPropertyHistoryListExpanded) {
      this.childPropertyHistoryDataSource = new MatTableDataSource(this.childPropertyHistoryListItems);
    } else {
      this.childPropertyHistoryDataSource = new MatTableDataSource(this.childPropertyHistoryListItems.slice(0, 3));
    }
  }

  changeChildPropertyHistoryListViewSize() {
    this.childPropertyHistoryListExpanded = !this.childPropertyHistoryListExpanded;
    this.loadChildPropertyHistoryDataSource();
    setTimeout(() => {
      if (this.moreOrLessPropertyHistoryElemRef && this.moreOrLessPropertyHistoryElemRef.nativeElement) {
        this.moreOrLessPropertyHistoryElemRef.nativeElement.scrollIntoView({behavior: 'smooth', block: 'center'});
      }
    }, 10);
  }

  get childPropertyHistoryListViewAction(): string {
    return this.childPropertyHistoryListExpanded ?
      'Less' :
      'More';
  }

  mapPlanSurvey = (planSurvey: PlanSurvey) => {
    this.selection.toggle(planSurvey);
    this.selectedPlanNumber = planSurvey.documentNumber;
    let mapItDataArray = planSurvey?.mapItData as unknown as PlanSurveyMapItData[];
    if (_.isEmpty(mapItDataArray)) {
      this.selectedPlanNumber = this.selectedPlanNumber + ' (No mapping data found)';
    }
    this.mapPlansSurveyComponent.mapPlanSurvey(planSurvey);
  }

  openPropertyReport = async (pin: string) => {
    this.propertyReportService.showPropertyReportByPinDeferData(pin);
  }

  initializeAdBanners = () => {
    try {
      this.adBannerRotationService.resetPropertyReportSectionAds(PropertyReportSectionEnum.PLANS_AND_SURVEYS, 'LEFT');
      this.adBannerRotationService.resetPropertyReportSectionAds(PropertyReportSectionEnum.PLANS_AND_SURVEYS, 'RIGHT');

      let rightAdBanners = new Set<AdBanner>();
      rightAdBanners.add(new AdBanner(AdBanner.IMAGE_AD, PropertyReportSectionEnum.PLANS_AND_SURVEYS, AdBannerWeightEnum.MEDIUM, 'assets/img/store-front/plans-surveys/aerial_1.jpg', new AdBannerAction(AdBannerActionEnum.OPEN_ZOOM2_IT)));
      rightAdBanners.add(new AdBanner(AdBanner.IMAGE_AD, PropertyReportSectionEnum.PLANS_AND_SURVEYS, AdBannerWeightEnum.MEDIUM, 'assets/img/store-front/plans-surveys/aerial_2.jpg', new AdBannerAction(AdBannerActionEnum.OPEN_ZOOM2_IT)));

      let newRightAdBanners: AdBanner[] = [];
      newRightAdBanners.push(this.adBannerRotationService.getNextAdBanner(rightAdBanners));
      this.adBannerRotationService.setNextRightSidebarAds(newRightAdBanners);

    } catch (e) {
      this.loggerService.logError(`error initializing ${PropertyReportSectionEnum.PLANS_AND_SURVEYS} ads`);
    }
  }

  initializeSubjectPropertyListener = () => {
    this.propertyReportService.subjectProperty$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((newPropertyDetail) => {
        this.subjectProperty = newPropertyDetail;
        this.refreshUI();
      });
  }

  ngOnInit(): void {
    this.initializeSubjectPropertyListener();
  }

  ngAfterViewInit(): void {

  }

  async displayLSRSurveySample(companySurveyId: string) {
    const imageUrl = this.planSurveySearchService.getLSRSurveySampleUrl(companySurveyId);
    if (imageUrl?.length > 0) {
      this.gaService.openModal('PropertySurveyImageLowResolutionPreview');
      /*
      const dialogData = new ImageDialogData(imageUrl, 'Property Survey Image Low Resolution Preview');
      const dialogRef = this.dialog.open(ImageDialogComponent, {data: dialogData}).afterClosed();
      */

      let lsrSurveySampleWindow = window.open(imageUrl, '_blank');
      if (lsrSurveySampleWindow) {
        lsrSurveySampleWindow.focus();

        lsrSurveySampleWindow.addEventListener("load", () => {
          lsrSurveySampleWindow.document.title = 'Property Survey Image Low Resolution Preview';
        });
      }
    } else {
      this.openSnackBarError('Something went wrong. Please try again later.');
    }
  }

  openSnackBarError(msg: string) {
    this._snackBar.open(msg, 'Close', defaultErrorMatSnackBarConfig);
  }

  get carouselProducts(): EStoreProduct[] {
    return this.userAccessControls.easementIndicatorAccess ?
      this.easementCarouselProducts :
      this.planSurveyCarouselProducts;
  }

  get showNavigators(): boolean {
    return this.carouselService.showNavigators(this.carouselProducts.length);
  }

  getBuyOrViewLabel(planSurvey: PlanSurvey): string {

    if (!this.userAccessControls.propertySurveyImageJDB && planSurvey.planCategory === 'P' && planSurvey.companyBEId === 20324) return 'Buy';
    if (!this.userAccessControls.propertySurveyImageRPE && planSurvey.planCategory === 'P' && planSurvey.companyBEId === 66870) return "Buy";
    if (!this.userAccessControls.propertySurveyImageLSR && planSurvey.planCategory === 'P' && planSurvey.companyBEId === 11111) return 'Buy';
    if (!this.userAccessControls.propertySurveyImageSRI && planSurvey.planCategory === 'P' && planSurvey.companyBEId === 99999) return 'Buy';

    if ((this.userAccessControls.propertySurveyImageJDB && this.userAccessControls.propertySurveyImageJDBTxnl) && planSurvey.planCategory === 'P' && planSurvey.companyBEId === 20324) return 'Buy';
    if ((this.userAccessControls.propertySurveyImageRPE && this.userAccessControls.propertySurveyImageRPETxnl) && planSurvey.planCategory === 'P' && planSurvey.companyBEId === 66870) return 'Buy';
    if ((this.userAccessControls.propertySurveyImageLSR && this.userAccessControls.propertySurveyImageLSRTxnl) && planSurvey.planCategory === 'P' && planSurvey.companyBEId === 11111) return 'Buy';
    if ((this.userAccessControls.propertySurveyImageSRI && this.userAccessControls.propertySurveyImageSRITxnl) && planSurvey.planCategory === 'P' && planSurvey.companyBEId === 99999) return 'Buy';

    //PYB plans purchase transaction link
    if ((this.userAccessControls.propertySurveyImageLSR && this.userAccessControls.propertySurveyImageLSRTxnl) && planSurvey.planCategory === 'P' && planSurvey.companyBEId === 42) return 'Buy';
    if ((this.userAccessControls.propertySurveyImageLSR && !this.userAccessControls.propertySurveyImageLSRTxnl) && planSurvey.planCategory === 'P' && planSurvey.companyBEId === 42) return 'View';

    if (!this.userAccessControls.planImage && planSurvey.planCategory === 'R') return 'Buy';
    if ((this.userAccessControls.planImage && this.userAccessControls.planImageTxnl) && planSurvey.planCategory === 'R') return 'Buy';

    //View links or non transaction links
    if ((this.userAccessControls.propertySurveyImageJDB && !this.userAccessControls.propertySurveyImageJDBTxnl) && planSurvey.planCategory === 'P' && planSurvey.companyBEId === 20324) return 'View';
    if ((this.userAccessControls.propertySurveyImageRPE && !this.userAccessControls.propertySurveyImageRPETxnl) && planSurvey.planCategory === 'P' && planSurvey.companyBEId === 66870) return 'View';
    if ((this.userAccessControls.propertySurveyImageLSR && !this.userAccessControls.propertySurveyImageLSRTxnl) && planSurvey.planCategory === 'P' && planSurvey.companyBEId === 11111) return 'View';
    if ((this.userAccessControls.propertySurveyImageSRI && !this.userAccessControls.propertySurveyImageSRITxnl) && planSurvey.planCategory === 'P' && planSurvey.companyBEId === 99999) return 'View';
    if ((this.userAccessControls.planImage && !this.userAccessControls.planImageTxnl) && planSurvey.planCategory === 'R') return 'View';
    return '';
  }


  async viewOrBuyReport(planSurvey: PlanSurvey) {

    const eStoreParam = new EStoreParam('',  this.subjectProperty?.pii?.pin, '', planSurvey.lro);
    
    eStoreParam.docNo = planSurvey.originalDocumentNumber;

    if (planSurvey.planCategory === 'P') {
      eStoreParam.token = planSurvey.token;
      eStoreParam.tokenStr = planSurvey.tokenStr;

      if (planSurvey.companyBEId === 20324) {
        eStoreParam.storeProductType = EstoreProductTypeEnum.PROPERTY_SURVEY_IMAGE_JDB;
        this.eStoreService.openReport(eStoreParam);
      }
      if (planSurvey.companyBEId === 66870) {
        eStoreParam.storeProductType = EstoreProductTypeEnum.PROPERTY_SURVEY_IMAGE_RPE;
        this.eStoreService.openReport(eStoreParam);
      }
      if (planSurvey.companyBEId === 11111 || planSurvey.companyBEId === 42) {
        eStoreParam.storeProductType = EstoreProductTypeEnum.PROPERTY_SURVEY_IMAGE_LSR;
        this.eStoreService.openReport(eStoreParam);
      }
      if (planSurvey.companyBEId === 99999) {
        eStoreParam.storeProductType = EstoreProductTypeEnum.PROPERTY_SURVEY_IMAGE_SRI;
        this.eStoreService.openReport(eStoreParam);
      }
    } else if (planSurvey.planCategory === 'R') {
      eStoreParam.storeProductType = EstoreProductTypeEnum.PLAN_IMAGE;
      this.eStoreService.openReport(eStoreParam);
    }
  }

  handlePageEvent($event: PageEvent) {
    this.parentPropertyHistoryPageIndex = $event.pageIndex;
  }
}
