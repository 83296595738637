import { inject, Injectable } from "@angular/core";
import { Pii } from "../../core/model/property/pii";
import { LoggerService } from "./log/logger.service";
import * as _ from "lodash";
import { catchError, map, Observable, throwError } from "rxjs";
import { baseUrl } from "./system";
import { PIIRequest } from "../../core/model/property/pii.request";
import { HttpClient } from "@angular/common/http";
import { DataService } from "./data.service";

@Injectable({
    providedIn: 'root'
})
export class PIIService {


    private https = inject(HttpClient);
    private loggerService = inject(LoggerService);
    private dataService= inject(DataService);

    isCondo = (pii: Pii): boolean | undefined => {
        if (pii) {
            if (pii.pinXy) {
                return pii.pinXy.condo;
            } else {
                return pii.condo;
            }
        }
        return undefined;
    }

    isCondoBlock = (pii: Pii): boolean | undefined => {
        return (this.isCondo(pii) && this.isBlockNumber(pii?.pin));
    }

    isCondoPin = (pii: Pii): boolean | undefined => {
        return (this.isCondo(pii) && this.isPin(pii?.pin) && !_.endsWith(pii.pin, '0000'));
    }

    isCondoBuilding = (pii: Pii): boolean | undefined => {
        return this.isCondoBlock(pii);
    }

    isCondoUnit = (pii: Pii): boolean | undefined => {
        return (this.isCondoPin(pii));
    }

    isPin = (pin: string): boolean => {
        return this.dataService.isPin(pin);
    }

    isArn = (arn: string): boolean => {
      return this.dataService.isArn(arn);
    }

    isBlockNumber = (block: string): boolean => {
        let rgx = /\b\d{5}\b/g;
        let isBlock: boolean = rgx.test(block);
        return isBlock;
    }

    isBlockPin = (pin: string): boolean => {
        let isBlockPin: boolean = this.isPin(pin) && !_.endsWith(pin, '0000');
        return isBlockPin;
    }

    getCondoBlockPin = (pinOrBlock: string) => {
        return this.getCondoBlockNumber(pinOrBlock) + '0000';
    }

    getCondoBlockNumber = (pinOrBlock: string) => {
        return pinOrBlock?.slice(0, 5);
    }

    getPii = (pin: string): Observable<Pii> => {
        const url = `${baseUrl}/property/pii?pin=${pin}`;

        return this.https.get<Pii>(url).pipe(
          map(response => {
            if (!_.isEmpty(response)) {
              //return <Pii>response;
              return new Pii(response);
            } else {
              return new Pii();
            }
          }),
          catchError((err) => {
            this.loggerService.logError(`error getting pii by pin ${pin}`, err);
            return throwError(err);
          })
        );
      }

      getPiiList = (piiRequest: PIIRequest): Observable<Pii[]> => {
        const url = `${baseUrl}/property/pii/list`;

        return this.https.post<Pii[]>(url, piiRequest).pipe(
          map(response => {
            if (!_.isEmpty(response)) {
              return <Pii[]>response;
            } else {
              return [];
            }
          }),
          catchError((err) => {
            this.loggerService.logError(`error getting list of pii`, err);
            return throwError(err);
          })
        );
      }
}
