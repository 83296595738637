import {StackFrame} from "./stack-frame";

export class RemoteError{
  message: string;
  stack: StackFrame[];

  public toString = () : string => {
    return this.message +
      (this.stack?.length ? this.stack.join(",") : '');
  }
}
