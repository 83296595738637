import {Component, inject, Input} from "@angular/core";
import {BaseUnsubscribe} from "../base-unsubscribe/base-unsubscribe";
import {EstoreProductCategoryEnum} from "../../enum/estore-product-category-enum";
import {DataService} from "../../../shared/service/data.service";
import {EStoreProduct} from "../../model/product/e-store/e-store-product";
import {MatSnackBar} from "@angular/material/snack-bar";
import {defaultErrorMatSnackBarConfig, LocalStorageKey} from "../../../shared/constant/constants";
import {SelectedProperty} from "../../model/property/selected-property";
import {UserActivity} from "../../model/user/user-activity";
import {SelectedPropertyDialogComponent} from "../modal/selected-property-dialog/selected-property-dialog.component";
import {PropertyDetail} from "../../model/property/property-detail";
import {lastValueFrom} from "rxjs";
import {PropertyReportSearchService} from "../../../shared/service/search/property-report-search.service";
import {LoggerService} from "../../../shared/service/log/logger.service";
import {GoogleAnalyticsService} from "../../../shared/service/google-analytics.service";
import {WarningDialogData} from "../modal/warning-dialog/warning-dialog-data";
import {EStoreParam} from "../../model/product/e-store/e-store-param";
import {MatDialog} from "@angular/material/dialog";
import {EstoreService} from "../../../shared/service/estore.service";
import {InsightsReportDialogComponent} from "../modal/insights-report-dialog/insights-report-dialog.component";
import {InsightsReportDialogData} from "../modal/insights-report-dialog/insights-report-dialog-data";
import {ResidentialRelocationAdditionalInfoData} from "../modal/residential-relocation-additional-info-dialog/residential-relocation-additional-info-data";
import {AssessmentSelectionData} from "../modal/assessment-selection-dialog/assessment-selection-data";
import {AssessmentSelectionDialogComponent} from "../modal/assessment-selection-dialog/assessment-selection-dialog.component";
import {ResidentialRelocationAdditionalInfoDialogComponent} from "../modal/residential-relocation-additional-info-dialog/residential-relocation-additional-info-dialog.component";
import {ResidentialRelocationAdditionalInfoResult} from "../modal/residential-relocation-additional-info-dialog/residential-relocation-additional-info-result";
import {EStoreParamAdditional} from "../../model/product/e-store/e-store-param-additional";
import {Municipality} from "../../model/property/municipality";
import {MunicipalityService} from "../../../shared/service/municipality.service";
import {PropertyReportService} from "../../../shared/service/property-report.service";
import {Router} from "@angular/router";
import {WarningService} from "../../../shared/service/warning.service";
import {LroPolygonsService} from "../../../shared/service/lro-polygons.service";


@Component({
  template: ''
})
export class BaseCatalogueProductComponent extends BaseUnsubscribe {

  @Input() product: EStoreProduct;
  @Input() products: EStoreProduct[];
  @Input() selectedProperty: SelectedProperty;
  @Input() recentProperties: UserActivity[];

  protected _snackBar = inject(MatSnackBar);
  protected propertyService  = inject(PropertyReportSearchService);
  protected loggerService= inject(LoggerService);
  protected gaService= inject(GoogleAnalyticsService);
  protected dialog = inject(MatDialog);
  protected eStoreService= inject(EstoreService);
  protected propertyReportSearchService= inject(PropertyReportSearchService);
  protected municipalityService = inject(MunicipalityService);
  protected propertyReportService = inject(PropertyReportService);
  protected router = inject (Router);
  protected warningService = inject(WarningService);
  private lroPolygonsService = inject(LroPolygonsService);

  irDialogRef: any;
  buttonCaption: string = '';
  isButtonDisabled: boolean = false;

  protected needsAPinForProperty(): boolean {
    return [EstoreProductCategoryEnum.PYB]
      .includes(this.product?.category);
  }

  protected openSnackBarError(err: string) {
    this._snackBar.open(err, 'Close', defaultErrorMatSnackBarConfig);
  }

  imageClass(): string {
    if (this.product?.category == 'catGwhRSubspn') {
      if (!this.product?.hasLicensePackageId) {
        return 'cp-product-image-subscription';
      }
      return 'cp-product-image-subscription height-auto';
    }
    return 'cp-product-image';
  }

  changeUrlDomain(samplelink: string) {
    var devRegex = new RegExp("localhost|dev");
    var uatRegex = new RegExp("uat");
    var protocalRegex = "www.";
    if (devRegex.test(window.location.hostname)) {
      return samplelink.toString().replace(protocalRegex, "dev.");
    } else if (uatRegex.test(window.location.hostname)) {
      return samplelink.toString().replace(protocalRegex, "seuat.");
    }
    return samplelink;
  }

  getButtonColor(): string {
    if (this.product?.category == EstoreProductCategoryEnum.GWH_RENEW_SUBSCRIPTION && this.product.licensePackageId > 0) {
      return 'mat-green'
    }
    return 'mat-primary';
  }

  needsASelectedProperty(): boolean {
    return [EstoreProductCategoryEnum.PYB,
      EstoreProductCategoryEnum.HOODQ,
      EstoreProductCategoryEnum.RESIDENTIAL_RELOCATION,
      EstoreProductCategoryEnum.PROPERTY_SURVEY_IMAGE,
      EstoreProductCategoryEnum.TERANET_INSIGHTS,
      EstoreProductCategoryEnum.STAT]
      .includes(this.product.category);
  }

  async runReport() {
    let categoryType = this.product.category;
    if (this.needsASelectedProperty()) {
      if (!this.selectedProperty?.isSelected) {
        this.gaService.openModal('SelectedPropertyDialogComponent');
        const dialogRef = await this.dialog.open(SelectedPropertyDialogComponent, {data: this.recentProperties})
          .afterClosed()
          .subscribe(async (resp: UserActivity) => {
            this.loggerService.logInfo(resp);
            if (resp instanceof UserActivity) {
              let propertyDetail: PropertyDetail;
              if (resp.pin && resp.pin != "N/A") {
                propertyDetail = await lastValueFrom(this.propertyReportSearchService.getPropertyDetailByPin(resp.pin));
              } else {
                propertyDetail = await lastValueFrom(this.propertyReportSearchService.getPropertyDetailByArn(resp.text));
              }
              this.propertyReportService.updateSubjectProperty(propertyDetail);
              await this.runReportWithSelectedProperty(categoryType);
            } else if (resp == "CLOSE_STORE") {
              await this.router.navigate(['../home']);
            }
          });
      } else {
        await this.runReportWithSelectedProperty(categoryType);
      }
    } else {
      this.loggerService.logInfo(this.selectedProperty);
      await this.runReportWithSelectedProperty(categoryType);
    }
  }

  protected async runReportWithSelectedProperty(categoryType: EstoreProductCategoryEnum) {
    if (this.selectedProperty) {
      if (this.needsAPinForProperty() && (!this.selectedProperty.pin || this.selectedProperty.pin == 'N/A')) {
        this.gaService.openModal("Cannot open report");
        const dialogData = new WarningDialogData("Cannot open report", ["PIN is not available for the current property."], '',"Close");
        await this.warningService.showWarning(dialogData);
        return;
      }
      if (categoryType == EstoreProductCategoryEnum.TERANET_INSIGHTS) {
        //this.eStoreService.startPropertyInsightsReportRunner(); //do not start the runner here as the subscriber is in this component, ie, it will trigger the subscriber for all products since this component is shared by all products
        this.runPropertyInsightsReport();
      } else if (categoryType == EstoreProductCategoryEnum.MPAC || categoryType == EstoreProductCategoryEnum.MPAC_BUNDLE) {
        this.eStoreService.openPropertyLine(this.selectedProperty?.arn);
      } else if (categoryType == EstoreProductCategoryEnum.HOODQ) {
        await this.openHoodQReportWindow();
      } else if (categoryType == EstoreProductCategoryEnum.RESIDENTIAL_RELOCATION) {
        await this.openResidentialRelocationReportWindow();
      } else {

        if(categoryType == EstoreProductCategoryEnum.GWH_RENEW_SUBSCRIPTION){
          // will need to reload userAccessControls after the user has renewed their subscription
          localStorage.setItem(LocalStorageKey.reloadUserAccessControls, 'true');
        }
        const eStoreParam = new EStoreParam(this.product.cta.deeplink, this.selectedProperty?.pin, this.selectedProperty?.arn, this.selectedProperty?.lro, this.product.prodId, '', this.product.licensePackageId);
        this.eStoreService.openReport(eStoreParam);
      }
    }
  }

  private async runPropertyInsightsReport() {
    this.loggerService.logInfo("running Teranet Insights reports");
    this.gaService.openModal('InsightsReportDialog');
    this.irDialogRef = this.dialog.open(InsightsReportDialogComponent, {data: new InsightsReportDialogData('Checking report availability...'), disableClose: true, minWidth: 300, maxWidth: 400});

    this.buttonCaption = 'Checking...';
    try {
      const reportAvailability = await lastValueFrom(this.propertyReportService.checkTeranetInsightsReportAvailability(this.selectedProperty?.pin, this.product));
      if (reportAvailability && reportAvailability.reportReference?.length) {
        const eStoreParam = new EStoreParam(this.product.cta.deeplink, this.selectedProperty?.pin, this.selectedProperty?.arn, this.selectedProperty?.lro, this.product.prodId);
        eStoreParam.reportReference = reportAvailability.reportReference
        this.eStoreService.openReport(eStoreParam);
        this.loggerService.logInfo(reportAvailability);
      } else if (reportAvailability.errorMessage?.length) {
        this.openSnackBarError(reportAvailability.errorMessage);
      } else {
        const reportType = this.propertyReportService.teranetInsightsReportType(this.product);
        if (reportType == 1) {
          this.openSnackBarError("Unable to generate Property Insights Report with AVM Range.");
        } else if (reportType == 2) {
          this.openSnackBarError("Unable to generate Enhanced AVM Comparable Report.");
        } else {
          this.openSnackBarError("Unable to generate report.");
        }
      }
    } finally {
      this.eStoreService.stopPropertyInsightsReportRunner();
      this.irDialogRef.close();
      this.buttonCaption = '';
    }
  }

  private async openResidentialRelocationReportWindow() {
    const inputData = new ResidentialRelocationAdditionalInfoData();
    inputData.prodId = this.product.prodId;
    let selectedArn = this.selectedProperty?.arn;

    //this.eStoreService.triggerResidentialRelocationReportTimeFramePrompt(-1);

    if (this.validateResidentialReports(this.product.prodId)) {

      if (this.selectedProperty.isSubjectPropertyMultipleARN) {
        let selectionData: AssessmentSelectionData = new AssessmentSelectionData();
        selectionData.selectedProperty = this.selectedProperty;
        selectionData.arnDescriptions = await lastValueFrom(this.propertyService.getPropertyAssessmentDescription(this.selectedProperty.pin));
        if (selectionData.arnDescriptions?.length > 0) {
          this.gaService.openModal('ResidentialRelocationAssessmentSelectionDialog');
          this.dialog.open(AssessmentSelectionDialogComponent, {data: selectionData})
            .afterClosed()
            .subscribe((resp) => {
                if (resp?.selectedArn) {
                  selectedArn = resp.selectedArn;
                  this.openResidentialRelocationAdditionalInfoDialog(inputData, selectedArn);
                }
              }
            );
        } else {
          this.openSnackBarError(DataService.RESIDENTIAL_RELOCATION_REPORT_INSUFFICIENT_DATA + " Assessments description cannot be retrieved at this time.");
        }
      } else {
        await this.openResidentialRelocationAdditionalInfoDialog(inputData, selectedArn);
      }
    }
  }

  private async openResidentialRelocationAdditionalInfoDialog(inputData: ResidentialRelocationAdditionalInfoData, selectedArn: string) {

    this.gaService.openModal('ResidentialRelocationAdditionalInfoDialog');
    const dialogRef = await this.dialog.open(ResidentialRelocationAdditionalInfoDialogComponent, {data: inputData})
      .afterClosed()
      .subscribe(async (resp: ResidentialRelocationAdditionalInfoResult) => {
        if (resp) {
          let additionalPropertyParam: EStoreParamAdditional = new EStoreParamAdditional();
          additionalPropertyParam.toDate = resp.toDate;
          additionalPropertyParam.fromDate = resp.fromDate;
          additionalPropertyParam.quarter = resp.quarter;
          additionalPropertyParam.city = this.selectedProperty?.city;
          additionalPropertyParam.lroCode = this.selectedProperty.lro;
          additionalPropertyParam.lroNameFromLroCode = this.lroPolygonsService.getNameById(this.selectedProperty.lro!);
          if (this.selectedProperty?.muniId) {
            const municipality: Municipality[] = await lastValueFrom(this.municipalityService.getMunicipalityDetails([this.selectedProperty?.muniId?.toString()]));
            additionalPropertyParam.municipality = municipality.find(value => value.munId == this.selectedProperty?.muniId?.toString())?.municipalityNoSpaces;
          }
          additionalPropertyParam.postalCode = this.selectedProperty?.postalCode;
          const eStoreParam = new EStoreParam(this.product.cta.deeplink, this.selectedProperty?.pin, selectedArn, this.selectedProperty?.lro, this.product.prodId);
          eStoreParam.additionalPropertyParam = new EStoreParamAdditional(additionalPropertyParam);
          await this.eStoreService.openReport(eStoreParam);
        }
      });
  }


  validateResidentialReports(prodId: string): boolean {
    let isValid: boolean = false;
    if (prodId == '1119' && !this.selectedProperty?.lro) {
      this.openSnackBarError(DataService.RESIDENTIAL_RELOCATION_REPORT_INSUFFICIENT_DATA + " LRO information is not available for the current property.");
    } else if (prodId == '1120' && !this.selectedProperty?.muniId) {
      this.openSnackBarError(DataService.RESIDENTIAL_RELOCATION_REPORT_INSUFFICIENT_DATA + " Municipality information is not available for the current property.");
    } else if (prodId == '1121' && !this.selectedProperty?.postalCode) {
      this.openSnackBarError(DataService.RESIDENTIAL_RELOCATION_REPORT_INSUFFICIENT_DATA + " FSA information is not available for the current property.");
    } else {
      isValid = true;
    }
    return isValid;
  }

  private async openHoodQReportWindow() {
    let selectedArn = this.selectedProperty?.arn;

    if (this.selectedProperty.isSubjectPropertyMultipleARN) {
      let selectionData: AssessmentSelectionData = new AssessmentSelectionData();
      selectionData.selectedProperty = this.selectedProperty;
      selectionData.arnDescriptions = await lastValueFrom(this.propertyService.getPropertyAssessmentDescription(this.selectedProperty.pin));
      if (selectionData.arnDescriptions?.length > 0) {
        this.gaService.openModal('HoodQAssessmentSelectionDialog');
        this.dialog.open(AssessmentSelectionDialogComponent, {data: selectionData})
          .afterClosed()
          .subscribe(async (resp) => {
              if (resp?.selectedArn) {
                selectedArn = resp.selectedArn;
                await this.openHoodQReportWindowWithSelectedArn(selectedArn);
              }
            }
          );
      } else {
        this.openSnackBarError(DataService.HOODQ_REPORT_INSUFFICIENT_DATA );
      }
    } else {
      await this.openHoodQReportWindowWithSelectedArn(selectedArn);
    }
  }

  private async openHoodQReportWindowWithSelectedArn(selectedArn: string) {

    const eStoreParam = new EStoreParam(this.product.cta.deeplink, this.selectedProperty?.pin, selectedArn, this.selectedProperty?.lro, this.product.prodId, '', this.product.licensePackageId);
    this.eStoreService.openReport(eStoreParam);
  }
}
