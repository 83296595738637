import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class OmnibarNoSearchResultsService {

    private _visibilityFlag = new BehaviorSubject<boolean>(false);
    visibilityFlag = this._visibilityFlag.asObservable();

    private updateMessageVisibility = (visible: boolean) => {
        this._visibilityFlag.next(visible);
    }

    hide = () => {
        this.updateMessageVisibility(false);
    }

    show = () => {
        this.updateMessageVisibility(true);
    }
}