import { lastValueFrom } from "rxjs";
import { FirstBaseSolutionService } from "../../../../../app/shared/service/first-base-solution.service";
import { MapTileEnum } from "../../../../core/enum/map-tile-enum";
import { TileBase } from "./tile.base";

export class FirstBaseSolutionTile extends TileBase implements google.maps.MapType {

    constructor(public override available: boolean, public override tileServerHttpProtocol: string | undefined, public override tileServerUrl: string | undefined, public override titleAuthenticationKey: string | undefined, private fbsService: FirstBaseSolutionService) {
        super(available, tileServerHttpProtocol, tileServerUrl, titleAuthenticationKey);

        this.name = MapTileEnum.FIRST_BASE_SOLUTION_TILE;
        this.minZoom = FirstBaseSolutionTile.MIN_ZOOM;
        this.maxZoom = FirstBaseSolutionTile.MAX_ZOOM;

        this.init();
    }

    alt: string | null;
    projection: google.maps.Projection | null;
    radius: number;

    public static MIN_ZOOM: number = -1;
    public static MAX_ZOOM: number = 22;
    tileSize: google.maps.Size = this.defaultTileSize;

    private init = async () => {
        try {
            this.minZoom = await this.fbsService.getMinimumFBSZoomLevel();
        } catch (e) {
            this.minZoom = FirstBaseSolutionService.DEFAULT_MINIMUM_ZOOM_LEVEL;
        }
    }

    getTile(tileCoordinates: google.maps.Point, zoomLevel: number, ownerDocument: Document): Element {

        if (this.isDisplayPermitted() && this.isWithinAllowableZoomLevels(this.minZoom, this.maxZoom, zoomLevel)) {
            return this.getMapTile(this.tileSize, tileCoordinates, zoomLevel, ownerDocument, MapTileEnum.FIRST_BASE_SOLUTION_TILE);
        } else {
            return this.getEmptyMapTile(this.tileSize, tileCoordinates, zoomLevel, ownerDocument, MapTileEnum.FIRST_BASE_SOLUTION_TILE);
        }

        //throw new Error("Method not implemented.");
    }

    releaseTile(tile: Element): void {
        //throw new Error("Method not implemented.");
    }

}
