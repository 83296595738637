<!-- @defer (on viewport) { -->
  <div id="dg-hh-st-label" class="demographics-sub-header">
    Structural Type
  </div>
  <div class="flex flex-col">
    @if (selectedArea.includes(demographicsAreaEnum.NBH)) {
      <div class="margin-auto" id="chart-home-age-nbh">
        <fusioncharts
          type="bar2d"
          (initialized)="initialized($event)"
          [dataEmptyMessage]="ds.nbhDataEmptyMessage"
          [dataSource]="nbhDataSource"
          dataFormat="json"
          height="{{defaultHeight}}"
          width="{{defaultWidth}}"
        ></fusioncharts>
      </div>
    }

    @if (selectedArea.includes(demographicsAreaEnum.COM)) {
      <div class="margin-auto" id="chart-home-age-com">
        <fusioncharts
          type="bar2d"
          (initialized)="initialized($event)"
          [dataEmptyMessage]="ds.comDataEmptyMessage"
          [dataSource]="comDataSource"
          dataFormat="json"
          height="{{defaultHeight}}"
          width="{{defaultWidth}}"
        ></fusioncharts>
      </div>
    }

    @if (selectedArea.includes(demographicsAreaEnum.CITY)) {
      <div class="margin-auto" id="chart-home-age-city">
        <fusioncharts
          type="bar2d"
          (initialized)="initialized($event)"
          [dataEmptyMessage]="ds.cityDataEmptyMessage"
          [dataSource]="cityDataSource"
          dataFormat="json"
          height="{{defaultHeight}}"
          width="{{defaultWidth}}"
        ></fusioncharts>
      </div>
    }
  </div>
<!-- } @placeholder {
  <div>Loading data ...</div>
} -->
