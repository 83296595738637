import {DemographicsCultural} from "./demographics-cultural";
import {DemographicsHouseHold} from "./demographics-house-hold";
import { DemographicsPopulation } from "./demographics-population";
import {DemographicsSocioEconomic} from "./demographics-socio-economic";
import {DemographicsPrizmSegment} from "./demographics-prizm-segment";
import {DemographicsMetadata} from "./demographics-metadata";
import {BaseModel} from "../base-model";


export class Demographics extends BaseModel{
	cultural: DemographicsCultural;
	houseHold: DemographicsHouseHold;
	population: DemographicsPopulation;
	socioEconomic: DemographicsSocioEconomic;
	metaData: DemographicsMetadata[];
	geoCodes: string[];
	prizmSegment: DemographicsPrizmSegment;

  // used to avoid accidentally regenerating all charts when the same component is loaded (part of optimization)
  pinOrArn: string;
  setPinOrArn(poa: string){
    this.pinOrArn = poa;
    if(this.cultural){
      this.cultural.pinOrArn = poa;
    }
    if(this.houseHold){
      this.houseHold.pinOrArn = poa;
    }
    if(this.population){
      this.population.pinOrArn = poa;
      if(this.population.PRCDCSD){
        this.population.PRCDCSD.pinOrArn = poa;
      }
      if(this.population.FSA){
        this.population.FSA.pinOrArn = poa;
      }
      if(this.population.PRCDDA){
        this.population.PRCDDA.pinOrArn = poa;
      }
    }
    if(this.socioEconomic){
      this.socioEconomic.pinOrArn = poa;
    }
  }
}
