import {AccountMenuItem} from "./account-menu-item";

export const accountMenuDefinitions: AccountMenuItem[] = [
  {
    title: "My Account",
    id: "MyAccountId",
    img: "",
    imgGrey: "",
    redirectTo: "overview",
    collapsed: false,
    children: []
  },
  {
    title: "Profile",
    id: "ProfileId",
    img: "assets/img/mygeo-icons/icon_profile.png",
    imgGrey: "assets/img/mygeo-icons/icon_profile-grey.png",
    redirectTo: "profile",
    collapsed: true,
    children: [{
      id: "PasswordId",
      title: "Password",
      redirectTo: "password",
      img: "assets/img/mygeo-icons/icon_profile.png",
      imgGrey: "assets/img/mygeo-icons/icon_profile-grey.png",
    }]
  },
  {
    title: "Downloads",
    id: "DownloadsId",
    img: "assets/img/mygeo-icons/icon_download.png",
    imgGrey: "assets/img/mygeo-icons/icon_download-grey.png",
    redirectTo: "downloads",
    collapsed: true,
    children: []
  },
  {
    title: "Subscriptions",
    id: "SubscriptionsId",
    img: "assets/img/mygeo-icons/icon_subscription.png",
    imgGrey: "assets/img/mygeo-icons/icon_subscription-grey.png",
    redirectTo: "subscription",
    collapsed: true,
    children: [{
      id: "ViewedReportsHistoryId",
      title: "Viewed Reports History",
      redirectTo: "report-history",
      img: "assets/img/mygeo-icons/icon_subscription.png",
      imgGrey: "assets/img/mygeo-icons/icon_subscription-grey.png",
    },
      {
        id: "SearchComparablesReportsHistoryId",
        title: "Search Comparable History",
        redirectTo: "comparables-search-report-history",
        img: "assets/img/mygeo-icons/icon_subscription.png",
        imgGrey: "assets/img/mygeo-icons/icon_subscription-grey.png",
      }]
  },
  {
    title: "Billing & Payments",
    id: "BillingAndPaymentsId",
    img: "assets/img/mygeo-icons/icon_payment.png",
    imgGrey: "assets/img/mygeo-icons/icon_payment-grey.png",
    redirectTo: "billing-payment",
    collapsed: true,
    children: [{
      id: "PaymentHistoryId",
      title: "Payment History",
      redirectTo: "payment-history",
      img: "assets/img/mygeo-icons/icon_payment.png",
      imgGrey: "assets/img/mygeo-icons/icon_payment-grey.png",
    }]
  },
  {
    title: "Preferences",
    id: "PreferencesId",
    img: "assets/img/mygeo-icons/icon_preference.png",
    imgGrey: "assets/img/mygeo-icons/icon_preference-grey.png",
    redirectTo: "preference",
    collapsed: true,
    children: []
  },
];
