<gema3g-property-report-layout [propertyReportSection]="propertyReportSection">

  <section>
    <!-- <div class="flex flex-col gap-2 divide-y divide-solid"> -->
    <div>
      <p id="ss-desc-label" class="pr-body-text">
        Please note, all information contained in the Site & Structure section of the Property
        Report is owned and maintained by MPAC. If any data in this section is missing or
        incorrect, please contact MPAC for assistance at <a id="ss-link-mpac-email" class="anchor-standard-blue" href="mailto:propertyline@mpac.ca" target="_blank">propertyline&#64;mpac.ca</a>.
      </p>

      @if ((userAccessControls.propertyReportAssessmentAccess || userAccessControls.MPACSubscriptionEnabled)
        && subjectProperty?.assessments?.length) {
        @for (assessment of subjectProperty?.assessments; track assessment; let i = $index) {
          <gema3g-site-structure-assessment-number
            [assessmentIndex]="i"
            [assessmentTotalNumber]="subjectProperty?.assessments?.length || 0"
            [assessment]="assessment"
            [userAccessControls]="userAccessControls"
            (scrollToAssessment)="scrollToAssessment($event)"
            #assessmentsRef>
          </gema3g-site-structure-assessment-number>
          <gema3g-site-structure-site [assessment]="assessment" [userAccessControls]="userAccessControls"></gema3g-site-structure-site>
          <gema3g-site-structure-structure [assessment]="assessment" [userAccessControls]="userAccessControls"></gema3g-site-structure-structure>
          @if (!userAccessControls.MPACSubscriptionEnabled) {
            <gema3g-site-structure-assessed-value [assessment]="assessment" [userAccessControls]="userAccessControls"></gema3g-site-structure-assessed-value>
          }
          @if (userAccessControls.mpacEnhancedDetailsAccess && !userAccessControls.MPACSubscriptionEnabled) {
            <gema3g-site-structure-previous-assessment [assessment]="assessment" [userAccessControls]="userAccessControls"></gema3g-site-structure-previous-assessment>
          }
          @if (userAccessControls.MPACSubscriptionEnabled) {
            <gema3g-site-structure-assessment-details [assessment]="assessment" [userAccessControls]="userAccessControls"></gema3g-site-structure-assessment-details>
          }
          @if (userAccessControls.mpacEnhancedDetailsAccess || userAccessControls.MPACSubscriptionPremiumOrEnhanced) {
            <gema3g-site-structure-four-year-phased-in [assessment]="assessment" [userAccessControls]="userAccessControls"></gema3g-site-structure-four-year-phased-in>
          }
          @if (!userAccessControls.mpacEnhancedDetailsAccess && userAccessControls.MPACSubscriptionPremium) {
            <gema3g-site-structure-residential-property-tax [assessment]="assessment" [userAccessControls]="userAccessControls"></gema3g-site-structure-residential-property-tax>
          }
          @if (!userAccessControls.MPACSubscriptionEnabled && userAccessControls.mpacEnhancedDetailsAccess) {
            <gema3g-site-structure-enhanced-data [assessment]="assessment" [userAccessControls]="userAccessControls"></gema3g-site-structure-enhanced-data>
          }
          @if (userAccessControls.MPACSubscriptionEnabled && userAccessControls.MPACSubscriptionPremiumOrEnhanced) {
            <gema3g-site-structure-mpac-subscription-enhanced-data [assessment]="assessment" [userAccessControls]="userAccessControls"></gema3g-site-structure-mpac-subscription-enhanced-data>
          }
          @if (userAccessControls.assessmentDetailsReport || userAccessControls.iciAssessmentInformation) {
            <gema3g-site-structure-assessment-property [assessment]="assessment" [userAccessControls]="userAccessControls"></gema3g-site-structure-assessment-property>
          }
          @if (userAccessControls.MPACSubscriptionEnabled) {
            <gema3g-site-structure-mpac-subscription-assessment-property [assessment]="assessment" [userAccessControls]="userAccessControls"></gema3g-site-structure-mpac-subscription-assessment-property>
          }
          @if (!userAccessControls.MPACSubscriptionEnabled) {
            <gema3g-site-structure-valuation-sales [assessment]="assessment" [userAccessControls]="userAccessControls"></gema3g-site-structure-valuation-sales>
          }
          @if (!userAccessControls.MPACSubscriptionEnabled && (userAccessControls.assessmentDetailsReport || userAccessControls.iciAssessmentInformation)) {
            <gema3g-site-structure-residential [assessment]="assessment" [userAccessControls]="userAccessControls"></gema3g-site-structure-residential>
          }
          @if (userAccessControls.iciAssessmentInformation && !userAccessControls.MPACSubscriptionEnabled) {
            <gema3g-site-structure-industrial [assessment]="assessment" [userAccessControls]="userAccessControls"></gema3g-site-structure-industrial>
          }
          @if (userAccessControls.iciAssessmentInformation && !userAccessControls.MPACSubscriptionEnabled) {
            <gema3g-site-structure-commercial [assessment]="assessment" [userAccessControls]="userAccessControls"></gema3g-site-structure-commercial>
          }
          @if (userAccessControls.MPACSubscriptionPremiumOrEnhanced && subjectProperty.condoDetail?.type == 'SUITE') {
            <gema3g-site-structure-condo-info [assessment]="assessment" [userAccessControls]="userAccessControls"></gema3g-site-structure-condo-info>
          }
        }
      }

    </div>

  </section>
</gema3g-property-report-layout>
