import {FusionChartDatasetData} from "./fusion-chart-dataset-data";

export class FusionChartDataset {
  data: FusionChartDatasetData[] = [];
  seriesname: string;
  renderAs: string;

  constructor(data: FusionChartDatasetData[]) {
    this.data = data;
  }
}
