import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {BaseStructure} from 'src/app/core/model/mpac/base-structure';
import {Assessment} from "../../../../../core/model/assessment/assessment";
import * as _ from "lodash";

@Component({
  selector: 'gema3g-site-structure-structure-info',
  templateUrl: './site-structure-structure-info.component.html',
  styleUrls: ['./site-structure-structure-info.component.scss']
})
export class SiteStructureStructureInfoComponent implements OnChanges {

  @Input() assessment: Assessment = new Assessment();
  @Input() structureAssessment: BaseStructure[] = [];
  structureInfoDisplayedColumns: string[] = ['floorLevel', 'clearHeight', 'buildingArea', 'interiorOfficeSpace', 'yearBuilt']
  structureInfoDataSource: MatTableDataSource<BaseStructure>;

  ngOnChanges(changes: SimpleChanges): void {

    if (this.structureAssessment && this.structureAssessment.length > 0) {
      this.structureAssessment = _.orderBy(this.structureAssessment, ['baseInfo.floorLevel', 'baseInfo.floorArea']);
      this.structureInfoDataSource = new MatTableDataSource(this.structureAssessment);
    }
  }
}
