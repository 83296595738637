import {BaseModel} from "../../base-model";

export class EStoreProductDescription extends BaseModel {
  constructor(espd?: EStoreProductDescription) {
    super(espd);
  }

  starthtml: string;
  bullets: string[];
  endhtml: string;

  addTextToBullets(text: string, useBold: boolean = false) {
    if (useBold) {
      this.bullets = this.bullets.map(bullet => {
        return `<strong>${bullet}</strong>    ${text}`;
      });
    } else {
      this.bullets = this.bullets.map(bullet => {
        return `${bullet}    ${text}`;
      });
    }

  }
}
