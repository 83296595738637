import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { baseUrl } from "./system";
import { map, catchError, throwError, Observable, lastValueFrom } from 'rxjs';
import * as _ from "lodash";
import { LotConcessionTownship } from "../../core/model/lotconcession/township";
import { LotConcession } from "../../core/model/lotconcession/concession";
import { Lot } from "../../core/model/lotconcession/lot";
import { LoggerService } from "./log/logger.service";

@Injectable({
    providedIn: 'root'
})
export class LotConcessionService {
    private https = inject(HttpClient);
    private loggerService = inject(LoggerService);

    getTownship = (lro: string): Observable<any> => {
        const url = baseUrl + '/lotcon/township?lro=' + lro;
        this.loggerService.logInfo(`calling ${url} to get townships in lro ${lro}`);

        return this.https.get<LotConcessionTownship>(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).pipe(
            map((response: LotConcessionTownship) => {
                if (!_.isEmpty(response)) {
                    return response;
                } else {
                    return new LotConcessionTownship();
                }
            }),
            catchError((err) => {
                this.loggerService.logError('unable to retrieve lot concession township results', err);
                return throwError(err);
            })
        );
    }

    getConcession = (lro: string, township: string): Observable<any> => {
        const url = baseUrl + '/lotcon/concession?lro=' + lro + '&township=' + township;
        this.loggerService.logInfo(`calling ${url} to get concessions in lro ${lro}, township ${township}`);

        return this.https.get<LotConcession>(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).pipe(
            map((response: LotConcession) => {
                if (!_.isEmpty(response)) {
                    return response;
                } else {
                    return new LotConcession();
                }
            }),
            catchError((err) => {
                this.loggerService.logError('unable to retrieve lot concession results', err);
                return throwError(err);
            })
        );
    }

    getLot = (lro: string, township: string, concession: string): Observable<any> => {
        const url = baseUrl + '/lotcon/lot?lro=' + lro + '&township=' + township + '&concession=' + concession;
        this.loggerService.logInfo(`calling ${url} to get concessions in lro ${lro}, township ${township}, concession ${concession}`);

        return this.https.get<Lot>(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).pipe(
            map((response: Lot) => {
                if (!_.isEmpty(response)) {
                    return response;
                } else {
                    return new Lot();
                }
            }),
            catchError((err) => {
                this.loggerService.logError('unable to retrieve lot concession lot results', err);
                return throwError(err);
            })
        );
    }
}
