<div class="gema3g-ao-container">
  <div class="gema3g-ao-container__inner">


    <div [ngClass]="hasSearchComparableReports ? 'profile-card-extended' : 'profile-card'">
      <mat-card class="subscriptions-card">
        <mat-card-header class="display-flex">
          <div>
            <h4 id="account-h4-ov-profile">Profile</h4>
          </div>
          <div class="flex-1"></div>
          <div>
            <a id="account-link-ov-profile-edit" class="anchor" routerLink="../profile">Edit profile</a>
          </div>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
          <div *ngIf="(user$ | async) as user; else loading">
            <div class="left-img">
              <div *ngIf="!user?.profilePhotoUrl">
                <img id="account-img-ov-profile-user-default" alt="" src="{{defaultProfilePhotoSource}}">
              </div>
              <div *ngIf="user?.profilePhotoUrl">
                <img id="account-img-ov-profile-user" [src]="user?.profilePhotoUrlRandomized" alt="" class="user-photo">
              </div>
            </div>
            <div class="right-text">
              <h4 id="account-h4-ov-profile-fullname" class="mb-3">{{ user?.fullName }}</h4>
              <p id="account-ov-profile-company">{{ user?.userProfile?.company }}</p>
              <p id="account-ov-profile-email">{{ user?.userProfile?.email | cutText : 36 }}</p>
            </div>
          </div>
          <ng-template #loading>Loading...</ng-template>
        </mat-card-content>
      </mat-card>
    </div>


    <div [ngClass]="hasSearchComparableReports ? 'viewed-reports-card-extended' : 'viewed-reports-card'">
      <mat-card-header class="display-flex">
        <div>
          <h4 id="account-h4-ov-vr">Viewed Reports</h4>
        </div>
        <div class="flex-1"></div>
        <div>
          <a id="account-link-ov-vr-details" class="anchor" routerLink="../report-history">Details</a>
        </div>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content>
        <div>
          <h3 id="account-h3-ov-vr-ru" class="padding-top-20">Property Reports</h3>
        </div>
        <div *ngIf="(user$ | async) as user;" class="display-flex">
          <div>
            <h4 id="account-h4-ov-vr-ru">{{ user.totalReportsUsed }}
              <span class="text-[var(--color-text-light-gray)]"> Used</span>
            </h4>
          </div>
          <div class="flex-1"></div>
          <div>
            <h4 id="account-h4-ov-vr-tr">{{ user.totalReports }}
              <span class="text-[var(--color-text-light-gray)]"> Total</span>
            </h4>
          </div>
        </div>
        <div *ngIf="(user$ | async) as user; else loading">
          <section class="progress-bar">
            <mat-progress-bar
              id="account-progress-bar-ov-vr"
              [value]="user.reportsUsedSoFar"
              color="primary"
              mode="determinate"
            >
            </mat-progress-bar>
          </section>
        </div>
        <ng-template #loading>Loading...</ng-template>
        <div class="display-flex justify-content-center padding-top-20">
          <a id="account-link-ov-vr-gmr" class="anchor" (click)="openStoreFrontRetail()">Get More Reports</a>
        </div>
        @if (userLicenseCounters$ | async; as licenseCounters) {
          @if (user$ | async; as user) {
            @if (licenseCounters?.comparablesSearchCounter && !!user?.userProfile?.licensePackageId) {
              <div>
                <h3 id="account-h3-ov-vr-ru-cs" class="padding-top-20">Search Comparable Reports</h3>
              </div>
              <div class="display-flex">
                <div>
                  <h4 id="account-h4-ov-vr-cs-ru">{{ licenseCounters?.comparablesSearchCounter?.licenseUsed }}
                    <span class="text-[var(--color-text-light-gray)]"> Used</span>
                  </h4>
                </div>
                <div class="flex-1"></div>
                <div>
                  <h4 id="account-h4-ov-vr-cs-tr">{{ licenseCounters?.comparablesSearchCounter?.licenseTotal }}
                    <span class="text-[var(--color-text-light-gray)]"> Total</span>
                  </h4>
                </div>
              </div>
              <div>
                <section class="progress-bar">
                  <mat-progress-bar
                    id="account-progress-bar-ov-cs-vr"
                    [value]="licenseCounters?.comparablesSearchCounter?.percentageOfReportsUsedSoFar"
                    color="primary"
                    mode="determinate"
                  >
                  </mat-progress-bar>
                </section>
              </div>
              <div class="display-flex justify-content-center padding-top-20">
                <a id="account-link-ov-vr-cs-gmr" class="anchor" (click)="openStoreFrontRetail()">Get More Reports</a>
              </div>
            }
          }
        }
      </mat-card-content>
    </div>

    <div [ngClass]="hasSearchComparableReports ? 'my-subscriptions-card-extended' : 'my-subscriptions-card'">
      <mat-card-header class="display-flex">
        <div>
          <h4 id="account-h4-ov-ms">Subscriptions</h4>
        </div>
        <div class="flex-1"></div>
        <div>
          <a id="account-link-ov-ms-details" class="anchor" routerLink="../subscription">Details</a>
        </div>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content>
        <ng-container *ngIf="(user$ | async) as user; else loading">
          <div class="p-bar">
            <div class="p-bar-left">
              <div class="p-bar-mysubs">
                <img id="account-ov-ms-gwh-logo" alt="" src="assets/img/geowarehouse-logo1.png">
                <span id="account-ov-ms-pr">Property Reports: {{ user.gwhReports }}</span>
                @if (user$ | async; as user) {
                  @if (userLicenseCounters$ | async; as licenseCounters) {
                    @if (licenseCounters?.comparablesSearchCounter && !!user.userProfile?.licensePackageId) {
                      <span id="account-ov-ms-cr">Comparable Reports: {{ licenseCounters?.comparablesSearchCounter?.licenseTotal }}</span>
                    }
                  }
                  @if (userLicensePackageDetails$ | async; as licensePackage) {
                    @if (licensePackage?.name) {
                      <span id="account-ov-ms-ps">Package Subscribed: {{ licensePackage?.name }}</span>
                    }
                  }
                }
                @if (user?.userProfile?.parentREB == false) {
                  <span id="account-ov-ms-sed">Subscription Expiration Date: {{ user.gwhEndDate | date:'MMM d, y' }}</span>
                }
                @if (userAccessControl.geowarehouseSubscriptionRenewalAccess && user.licenseAvailableForRenew) {
                  <a id="account-link-ov-ms-sub" (click)="renewSubscription()"
                     class="btn btn-primary mat-green btn-renew-profor">Renew
                    Subscriptions</a>
                  <br/>
                }
              </div>
            </div>
            <div class="p-bar-right">
              <div id="account-ov-ms-other" class="subsrightheader">Other Subscriptions</div>
              @if (user.licenseLength) {
                <div *ngFor="let item of user.licenses | keyvalue" class="otherlicenses">
                  <table id="account-tbl-ov-ms-other-{{item.value.licenseCategory}}" class="otherlicensestable" *ngIf="item.value.licenseCategory != 'GWH_RENEWAL'">
                    <tr id="account-tbl-row-ov-ms-other-license-cat-{{item.value.licenseCategory}}" *ngIf="item.value.licenseCategory === 'HVR_SUBSCRIPTION'">
                      <td id="account-tbl-data-ov-ms-other-license-cat-{{item.value.licenseCategory}}">Home Verified</td>
                    </tr>
                    <tr id="account-tbl-row-ov-ms-other-license-cat-{{item.value.licenseCategory}}" *ngIf="item.value.licenseCategory != 'HVR_SUBSCRIPTION'">
                      <td id="account-tbl-data-ov-ms-other-license-cat-{{item.value.licenseCategory}}">Other</td>
                    </tr>
                    <tr id="account-tbl-row-ov-ms-other-license-info-{{item.value.licenseCategory}}">
                      <td id="account-tbl-data-ov-ms-other-license-count-{{item.value.licenseCategory}}" *ngIf="item.value.licenseCountUnlimited==false">Reports: {{ item.value.licenseCountMax }}</td>
                      <td id="account-tbl-data-ov-ms-other-license-count-{{item.value.licenseCategory}}" *ngIf="item.value.licenseCountUnlimited==true">Reports: Unlimited</td>
                      <td id="account-tbl-data-ov-ms-other-license-ed-{{item.value.licenseCategory}}">End Date: {{ item.value.licenseEndDate | date:'MMM d, y' }}</td>
                    </tr>
                  </table>
                </div>
              }
              <div id="account-ov-ms-other-none" class="nosubsrightheader" ng-show="licensesLength === 1">You are not subscribed to any other
                GeoWarehouse services
              </div>
            </div>
          </div>
        </ng-container>
      </mat-card-content>
    </div>


    <div [ngClass]="hasSearchComparableReports ? 'recent-downloads-card-extended' : 'recent-downloads-card'">
      <mat-card-header class="display-flex">
        <div>
          <h4 id="account-h4-ov-rd">Recent Downloads</h4>
        </div>
        <div class="flex-1"></div>
        <div>
          <a id="account-link-ov-rd-ad" class="anchor" routerLink="../downloads">All Downloads</a>
        </div>
      </mat-card-header>
      <mat-card-content>
        <mat-divider></mat-divider>
        <div *ngIf="(download$ | async) as download; else loading">
          <p id="account-ov-rd-none-msg" class="pt-3" *ngIf="download?.items?.length == 0">You have not purchased recently any downloadable product (report,
            instrument, plan etc.)</p>
          <ng-container *ngIf="!isLoading && download.items.length > 0">
            <table id="account-tbl-ov-rd" class="overview-table">
              <tr id="account-tbl-row-ov-rd-{{item?.pk?.fulfillmentId}}" *ngFor="let item of download.items">
                <td id="account-tbl-data-ov-rd-date-{{item?.pk?.fulfillmentId}}" class="left-txt">{{ item?.createdDate | shortDate }}</td>
                <td class="right-txt"><p id="account-tbl-data-ov-rd-prod-{{item?.pk?.fulfillmentId}}">{{ item?.productName | uppercase }}</p>
                  <p id="account-tbl-data-ov-rd-addr-{{item?.pk?.fulfillmentId}}" *ngIf="item.address">{{ item.address }}</p>
                  <p id="account-tbl-data-ov-rd-pin-{{item?.pk?.fulfillmentId}}" *ngIf="!item.address && item.pin">PIN: {{ item.pin }}</p>
                  <p id="account-tbl-data-ov-rd-arn-{{item?.pk?.fulfillmentId}}" *ngIf="!item.address && !item.pin==null && item.arn">ARN: {{ item.arn }}</p>
                  <ul>
                    <li>
                      <a id="account-tbl-link-ov-rd-view-{{item?.pk?.fulfillmentId}}" *ngIf="item.contentType == 'txt'" class="anchor" href="{{item.contentFileName}}"
                         target="_blank">View</a>
                      <a id="account-tbl-link-ov-rd-download-{{item?.pk?.fulfillmentId}}" (click)="downloadReport(item?.pk?.fulfillmentId, item?.pk?.itemId)"
                         *ngIf="item.contentType != 'txt'  && item.productCategoryName != 'PYBRpt'" class="anchor">Download</a>
                      <a id="account-tbl-link-ov-rd-status-{{item?.pk?.fulfillmentId}}" (click)="openStatusPOPup(item)" *ngIf="item.productCategoryName == 'PYBRpt'"
                         class="anchor"
                         target="_blank">View Status</a>
                    </li>
                    <li><a id="account-tbl-link-ov-rd-invoice-{{item?.pk?.fulfillmentId}}" (click)="redirectToGWHInvoicePage(item?.transactionId)" class="anchor">Invoice</a></li>
                  </ul>
                  <div class="clear"></div>
                </td>
              </tr>
            </table>
          </ng-container>
        </div>
        <ng-template #loading>Loading...</ng-template>
      </mat-card-content>
    </div>


  </div>
</div>
