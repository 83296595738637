import {AfterViewInit, Component, inject} from '@angular/core';
import {AuthenticationService} from "../../shared/service/authentication.service";
import {LoggerService} from "../../shared/service/log/logger.service";

@Component({
  selector: 'gema3g-auth0-authentication',
  standalone: true,
  imports: [],
  templateUrl: './auth0-authentication.component.html',
  styleUrl: './auth0-authentication.component.scss'
})
export class Auth0AuthenticationComponent implements AfterViewInit {

  authService = inject(AuthenticationService);
  loggerService = inject(LoggerService);

  error: string = '';

  ngAfterViewInit() {
    try {
      setTimeout(() => {
        this.authService.loginWithOkta();
      }, 1 );
    } catch (e) {
      this.error = `There was an error logging in.`;
      this.loggerService.logError('There was an error while trying to log in with OKTA', e);
    }
  }
}
