export class WarningDialogData {

  constructor(title: string, content: string[], innerHtmlContent: string, secondButtonText: string, firstButtonText: string = '', showFirstButton: boolean = false) {
    this.title = title;
    this.content = content;
    this.firstButtonText = firstButtonText;
    this.secondButtonText = secondButtonText;
    this.innerHtmlContent = innerHtmlContent;
    this.showFirstButton = showFirstButton;
  }

  title: string;
  content: string[];
  firstButtonText: string;
  secondButtonText: string;
  innerHtmlContent: string;
  showFirstButton: boolean;

  showOnlyOnceForKey: string;
}
