import {BaseModel} from "../base-model";

export class BoundingBox extends BaseModel{
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;

  public getBBoxParam() {
    //return '&BBOX=' + [this.xMin, this.yMin, this.xMax, this.yMax].join('%2C');
    return '&BBOX=' + [this.xMin, this.yMax, this.xMax, this.yMin].join('%2C');
  }
}
