import {ComparableSalesReportCompsReportEntry} from "./comparable-sales-report-comps-report-entry";
import {ComparableSalesReportSubjectArn} from "./comparable-sales-report-subject-arn";

export class ComparableSalesReportComparableProperty extends ComparableSalesReportSubjectArn{

  compsReportEntry: ComparableSalesReportCompsReportEntry;

  constructor(comparableSalesReportCompsArn?: ComparableSalesReportComparableProperty) {
    super(comparableSalesReportCompsArn);
    if(comparableSalesReportCompsArn) {
      this.compsReportEntry = new ComparableSalesReportCompsReportEntry(comparableSalesReportCompsArn.compsReportEntry);
    }
  }
}
