import { map } from "lodash";

export class RataSearchRequest {
    constructor() {
        this.requestProperties = new Map();
        this.requestProperties.set('isDescending', false);
        this.requestProperties.set('processId', '');
        this.requestProperties.set('sortBy', '');

        this.filterRequestProperties = new Map();
    }

    private requestProperties: Map<string, any>;
    private filterRequestProperties: Map<string, any>;

    public setProperty(key: string, value: any) {
        this.requestProperties.set(key, value);
    }

    public unsetProperty(key: string) {
        this.requestProperties.delete(key);
    }

    public setFilterRequestProperty(key: string, value: any) {
        this.filterRequestProperties.set(key, value);
        this.setProperty(key, value);
    }

    public setFilterRequestProperties(requestProperties: Map<string, any>) {
        this.filterRequestProperties = requestProperties;

        for (let [key, value] of this.filterRequestProperties) {
            this.setProperty(key, value);
        }
    }

    public unsetFilterRequestProperty(key: string) {
        this.filterRequestProperties.delete(key);
        this.unsetProperty(key);
    }

    public getFilterRequestProperties() {
        return this.filterRequestProperties;
    }

    public hasFilterRequestProperties() {
        return (this.getFilterRequestProperties()?.size > 0);
    }

    public getJSON(): {} {
        return Object.fromEntries(this.requestProperties.entries());
    }
}