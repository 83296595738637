<div class="flex flex-col">
  @if (selectedArea.includes(demographicsAreaEnum.NBH)) {
    <div class="margin-auto" id="chart-dominant-language-nbh">
      <fusioncharts
        type="bar2d"
        [dataSource]="nbhDataSource"
        dataFormat="json"
        height="{{defaultHeight}}"
        width="{{defaultWidth}}"
        [dataEmptyMessage]="ds.nbhDataEmptyMessage"
        (initialized)="initialized($event)"
      ></fusioncharts>
    </div>
  }

  @if (selectedArea.includes(demographicsAreaEnum.COM)) {
    <div class="margin-auto" id="chart-dominant-language-com">
      <fusioncharts
        type="bar2d"
        [dataSource]="comDataSource"
        dataFormat="json"
        height="{{defaultHeight}}"
        width="{{defaultWidth}}"
        [dataEmptyMessage]="ds.comDataEmptyMessage"
        (initialized)="initialized($event)"
      ></fusioncharts>
    </div>
  }


  @if (selectedArea.includes(demographicsAreaEnum.CITY)) {
    <div class="margin-auto" id="chart-dominant-language-city">
      <fusioncharts
        type="bar2d"
        [dataSource]="cityDataSource"
        dataFormat="json"
        height="{{defaultHeight}}"
        width="{{defaultWidth}}"
        [dataEmptyMessage]="ds.cityDataEmptyMessage"
        (initialized)="initialized($event)"
      ></fusioncharts>
    </div>
  }
</div>
