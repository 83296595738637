<h1 id="sed-h1-title" mat-dialog-title>
  &#9888; Subscription Required
</h1>

<div class="p-2" mat-dialog-content>
  @if (!userAccessControls.GWHRSubscriptionAccess) {
    <div id="sed-nonsub-msg" class="content-text">
      <div class="text-os-bold">{{DataService.LICENSE_SUBSCRIPTION_EXPIRED}}</div>
      <div>{{DataService.LICENSE_SUBSCRIPTION_INFORM}}</div>
      <div>{{DataService.LICENSE_SUBSCRIPTION_PAYMENT}}</div>
    </div>
  }
  @if (userAccessControls.GWHRSubscriptionAccess) {
    <div id="sed-sub-msg" class="content-text">
      <div class="expired">{{DataService.LICENSE_SUBSCRIPTION_EXPIRED}}</div>
      <div>{{DataService.LICENSE_SUBSCRIPTION_RENEW}}</div>
    </div>
  }
</div>

<div align="center" mat-dialog-actions>
  @if (userAccessControls.GWHRSubscriptionAccess) {
    <button id="sed-btn-renew" (click)="goToSubscriptionRenewal()" mat-button class="renew-button">Renew Now</button>
  }
  <button id="sed-btn-logout" (click)="logoutUser()" mat-button class="dialog-close-btn" type="button">Log Out</button>
</div>
