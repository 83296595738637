import {inject, Injectable} from "@angular/core";
import {WarningDialogData} from "../../core/component/modal/warning-dialog/warning-dialog-data";
import {WarningDialogComponent} from "../../core/component/modal/warning-dialog/warning-dialog.component";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {DialogReturnTypeEnum} from "../../core/enum/dialog-return-type-enum";
import {LoggerService} from "./log/logger.service";
import {WarningTypeEnum} from "../../core/enum/warning-type.enum";

@Injectable({
  providedIn: 'root'
})
export class WarningService {

  private dialog = inject(MatDialog);
  private loggerService = inject(LoggerService);
  private isDialogDisplayed = false;

  lastDisplayedForKey: string;
  warningTypeSuffix = '-lastDisplayed';

  async showWarning(dialogData: WarningDialogData, disableClose: boolean = true, maxWidth?: number, onFirstButtonClick?: Function, onSecondButtonClick?: Function) {
    this.loggerService.logDebug(`this.lastDisplayedForKey= ${this.lastDisplayedForKey} ; dialogData.showOnlyOnceForKey = ${dialogData.showOnlyOnceForKey}`);

    if (dialogData.showOnlyOnceForKey && this.lastDisplayedForKey == dialogData.showOnlyOnceForKey) {
      this.loggerService.logDebug('Warning already displayed for key: ' + dialogData.showOnlyOnceForKey + '. Ignoring the request.');
      return;
    }

    if (this.isDialogDisplayed) {
      this.loggerService.logDebug('Attempt to display a dialog while another dialog is already displayed. Ignoring the request.');
    } else {
      this.isDialogDisplayed = true;
      if (dialogData.showOnlyOnceForKey) {
        this.lastDisplayedForKey = dialogData.showOnlyOnceForKey;
      }

      const dialogConfig = new MatDialogConfig<WarningDialogData>()
      dialogConfig.data = dialogData;
      dialogConfig.disableClose = disableClose;
      if ((!!maxWidth) && maxWidth > 0) {
        dialogConfig.maxWidth = maxWidth;
      }
      await this.dialog.open(WarningDialogComponent,
        dialogConfig)
        .afterClosed()
        .subscribe(async (resp) => {
          this.isDialogDisplayed = false;
          if (resp == DialogReturnTypeEnum.FIRST_BUTTON) {
            if (onFirstButtonClick) {
              onFirstButtonClick();
            }
          } else if (resp == DialogReturnTypeEnum.SECOND_BUTTON) {
            if (onSecondButtonClick) {
              onSecondButtonClick();
            }
          }
        });
    }
  }

  async showWarningOnceADay(warningType: WarningTypeEnum, dialogData: WarningDialogData, disableClose: boolean = true, maxWidth?: number, onFirstButtonClick?: Function, onSecondButtonClick?: Function) {

    if (this.isDialogDisplayed) {
      this.loggerService.logDebug('Attempt to display a dialog while another dialog is already displayed. Ignoring the request.');
    } else {
      let lastDisplayed = localStorage.getItem(warningType + this.warningTypeSuffix);
      if (lastDisplayed == new Date().toDateString()) {
        this.loggerService.logDebug('Warning already displayed today for: ' + warningType + '. Ignoring the request.');
        return;
      }
      localStorage.setItem(warningType + this.warningTypeSuffix, new Date().toDateString());
      this.isDialogDisplayed = true;
      const dialogConfig = new MatDialogConfig<WarningDialogData>()
      dialogConfig.data = dialogData;
      dialogConfig.disableClose = disableClose;
      if ((!!maxWidth) && maxWidth > 0) {
        dialogConfig.maxWidth = maxWidth;
      }
      await this.dialog.open(WarningDialogComponent,
        dialogConfig)
        .afterClosed()
        .subscribe(async (resp) => {
          this.isDialogDisplayed = false;
          if (resp == DialogReturnTypeEnum.FIRST_BUTTON) {
            if (onFirstButtonClick) {
              onFirstButtonClick();
            }
          } else if (resp == DialogReturnTypeEnum.SECOND_BUTTON) {
            if (onSecondButtonClick) {
              onSecondButtonClick();
            }
          }
        });
    }
  }
}
