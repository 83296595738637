<div class="gema3g-ss-container">
  <div class="gema3g-ss-container__inner">
    <div class="gema3g-ss-container__inner-grid">


      <div class="l-column">
        <h4 id="ss-h4-msap-{{assessment.rollNumber}}">Assessment Property Information</h4>
      </div>


      <div class="m-column">
        <div id="ss-msap-pa-label-{{assessment.rollNumber}}" class="flex">
          <h4 id="ss-h4-msap-pa-{{assessment.rollNumber}}">Property Address </h4>
          <span id="ss-msap-pa-{{assessment.rollNumber}}" class="ml-auto text-right">{{assessment.mpacAssessment?.address?.fullAddress}}</span>
        </div>
        <div id="ss-msap-muni-label-{{assessment.rollNumber}}" class="flex">
          <h4 id="ss-h4-msap-muni-{{assessment.rollNumber}}">Municipality </h4>
          <span id="ss-msap-muni-{{assessment.rollNumber}}" class="ml-auto text-right">{{assessment.mpacAssessment?.address?.municipality | na }}</span>
        </div>
        @if(userAccessControls.MPACSubscriptionBasic){
          <div id="ss-msap-rd-label-{{assessment.rollNumber}}" class="flex">
            <h4 id="ss-h4-msap-rd-{{assessment.rollNumber}}">Report Date </h4>
            <span id="ss-msap-rd-{{assessment.rollNumber}}" class="ml-auto text-right">{{assessment.mpacAssessment?.reportDate | na}}</span>
          </div>
        }
      </div>


      <div class="r-column">
        <div id="ss-msap-un-label-{{assessment.rollNumber}}" class="flex">
          <h4 id="ss-h4-msap-un-{{assessment.rollNumber}}">Unit Number </h4>
          <span id="ss-msap-un-{{assessment.rollNumber}}" class="ml-auto text-right">{{assessment.mpacAssessment?.address?.unitNumber | na}}</span>
        </div>
        @if(userAccessControls.MPACSubscriptionPremiumOrEnhanced){
          <div id="ss-msap-pt-label-{{assessment.rollNumber}}" class="flex">
            <h4 id="ss-h4-msap-pt-{{assessment.rollNumber}}">Property Type </h4>
            <span id="ss-msap-pt-{{assessment.rollNumber}}" class="ml-auto text-right">{{assessment.mpacAssessment?.property?.propertyTypeDescription | na}}</span>
          </div>
        }
        @if(userAccessControls.MPACSubscriptionPremium){
          <div id="ss-msap-on-label-{{assessment.rollNumber}}" class="flex">
            <h4 id="ss-h4-msap-on-{{assessment.rollNumber}}">Owner Names </h4>
            <span id="ss-msap-on-{{assessment.rollNumber}}" class="ml-auto text-right">{{assessment.mpacAssessment?.foi?.ownersList | na}}</span>
          </div>
          <div id="ss-msap-oma-label-{{assessment.rollNumber}}" class="flex">
            <h4 id="ss-h4-msap-oma-{{assessment.rollNumber}}">Owner Mailing Address </h4>
            <span id="ss-msap-oma-{{assessment.rollNumber}}" class="ml-auto text-right">{{assessment.mpacAssessment?.foi?.mailingAddress?.unparsedAddress | na}}</span>
          </div>
        }
      </div>


    </div>
  </div>
</div>
