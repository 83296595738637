<h1 id="rr-h1-asd-title" mat-dialog-title>
  Select Your Assessment of Interest
</h1>

<div class="flex flex-col px-4 pt-4">
  <p id="rr-asd-multi-assessment-msg">There are multiple assessments associated with your property of interest.</p>
  <p id="rr-asd-please-select-msg">Please select an assessment to proceed.</p>
</div>

<div mat-dialog-content>
  <div class="flex flex-wrap px-4">

    <div class="flex-[0_0_20%]">
      <div class=" left-box">
        <div [hidden]="!streetViewLoaded">
          <div #streetViewDiv class="street-view-box" id="street-view-div-container">
          </div>
        </div>
        <div [hidden]="streetViewLoaded">
          <p>
            <span id="rr-asd-no-streetview" class="no-results">
              Sorry. No street view found for the specified property.
            </span>
          </p>
        </div>
        <p id="rr-asd-addr" class="font-bold">{{selectionData?.selectedProperty?.address | na}}</p>
        <ul>
          <li id="rr-asd-pin"><span id="rr-asd-pin-label">PIN:</span> {{selectionData?.selectedProperty?.pin | na}}</li>
          <li id="rr-asd-arns"><span id="rr-asd-arns-label">ARN:</span> Multiple ARN's</li>
        </ul>
      </div>
    </div>

    <div class="flex-1 pl-2">
      <ul class="arn-list">
        @for (arn of selectionData?.arnDescriptions; track arn) {
          <li>
            <a id="rr-link-asd-arn-{{arn.arn}}" (click)="arnSelected(arn.arn)">
              <p id="rr-asd-arn-{{arn.arn}}" class="font-bold m-0">{{arn.arn}}</p>
              <p id="rr-asd-arn-desc-{{arn.arn}}" class="m-0">{{arn.description}}</p>
            </a>
          </li>
        }
      </ul>
    </div>

  </div>
</div>

<mat-divider></mat-divider>

<div align="end" mat-dialog-actions>
  <button id="rr-btn-asd-close" mat-button mat-dialog-close class="dialog-close-btn" type="button">Close</button>
</div>