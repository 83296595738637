import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DemographicsAreaEnum} from "../../../../../../core/enum/demographics-area.enum";
import {DemographicsHouseHold} from "../../../../../../core/model/demographics/demographics-house-hold";
import {FusionChartDataSource} from "../../../../../../core/model/fusion-chart/fusion-chart-data-source";
import {DemographicsChartsUtility} from "../../../../../../shared/utility/fusion-charts/demographics-charts.utility";
import {DataService} from "../../../../../../shared/service/data.service";
import {BaseChartComponent} from "../../../../../../core/component/base-chart/base-chart.component";
import {FusionChartsModule} from "angular-fusioncharts";

@Component({
  selector: 'gema3g-home-age',
  templateUrl: './home-age.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FusionChartsModule
  ],
  styleUrls: ['./home-age.component.scss']
})
export class HomeAgeComponent extends BaseChartComponent {

  @Input() houseHold: DemographicsHouseHold;
  @Input() cityTotalPopulation: number | undefined;

  override setHeightWidth() {
    //this.defaultWidth = "546";
    this.defaultHeight = "177"
    this.minWidth = 230;
    this.maxWidth = 546;
    this.minHeight = 177;
    this.maxHeight = 177;
  }

  override loadChartData() {
    this.nbhDataSource = DemographicsChartsUtility.homeAgeStatusDataSource(DataService.DEFAULT_CHART_FONT, 'NBH', this.houseHold?.PRCDDA, window.innerWidth);
    this.comDataSource = DemographicsChartsUtility.homeAgeStatusDataSource(DataService.DEFAULT_CHART_FONT, 'COM', this.houseHold?.FSA, window.innerWidth);
    this.cityDataSource = this.cityTotalPopulation ?
      DemographicsChartsUtility.homeAgeStatusDataSource(DataService.DEFAULT_CHART_FONT, 'CITY', this.houseHold?.PRCDCSD, window.innerWidth) :
      new FusionChartDataSource();
  }

}
