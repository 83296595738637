import { Component, effect, inject, input, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LoggerService } from '../../../../shared/service/log/logger.service';
import { BannerMessage } from '../../../../core/model/message-center/banner-message';
import { UserService } from '../../../../shared/service/user.service';
import * as _ from 'lodash';
import { BaseUnsubscribe } from '../../base-unsubscribe/base-unsubscribe';
import { MessageCenterService } from '../../../../shared/service/message-center.service';

@Component({
  selector: 'gema3g-ticker-tape',
  templateUrl: './ticker-tape.component.html',
  styleUrl: './ticker-tape.component.scss'
})
export class TickerTapeComponent extends BaseUnsubscribe implements OnInit {

  constructor() {

    super();

    try {
      effect(() => {
        if (this.bannerMessages()?.hasMessages()) {
          this.loggerService.logDebug(`${this.bannerMessages()?.getMessages().length} banner messages found for beid ${this.userService.getBEID()}`);

          this.hasBannerMessages = true;
          this.messageList = new Array<string>();

          this.bannerMessages()?.getMessages().map(message => {
            let messageLines: string[] = _.split(message.getBody(), '^');
            messageLines.forEach(line => {
              if (!_.isEmpty(line)) {
                line = line.trim();
                line = line.replace(/&nbsp;|<[^>]*>/g, "");
                this.messageList.push(line);
              }
            });
          });
        } else {
          this.loggerService.logDebug(`no banner messages found for beid ${this.userService.getBEID()}`);
        }
      });

    } catch (e) {
      this.loggerService.logError(`error retrieving banner messages for beid ${this.userService.getBEID()}`, e);
    }

  }

  private loggerService = inject(LoggerService);
  private messageCenterService = inject(MessageCenterService);
  private userService = inject(UserService);

  bannerMessages = input<BannerMessage | null>();
  tickerTipColor: string = '#F78F1E';
  interval: number = 8000;
  messageList = new Array<string>();
  hasBannerMessages: boolean = this.messageCenterService.isBannerMessagesLoading();

  ngOnInit(): void {
    this.hasBannerMessages = false;
  }

}
