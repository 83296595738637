import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { UserService } from "../../../shared/service/user.service";
import { UserAccessControl } from "../../../core/model/user/user-access-control";
import { User } from "../../../core/model/user/user";
import { Pii } from "../../../core/model/property/pii";
import { DataService } from "../../../shared/service/data.service";

@Injectable({
    providedIn: 'root'
})
export class MainMapInfoBubbleContentService {

    constructor(private userService: UserService) {
        this.userAccessControls = this.userService.getUserAccessControl();
        this.user = this.userService.user;
    }

    private searchResultMarkerBubbleOffsetSize: google.maps.Size = new google.maps.Size(0, -30);
    userAccessControls: UserAccessControl;
    user: User;

    //some ported variables from gema-ui mapservices.js
    bubbleArn: string;
    streetviewPin: string;
    pindata: string;
    unitpin: string;

    //javascript code below ported from createGoogleMapMarkerWindow function in gema-ui mapservices.js and adapted to work in this typescript environment.
    //some null checks and/or specific angularjs functions replaced with lodash functions or removed entirely where applicable.
    //some variable scopes changed from var to let.
    //TODO: need to clean up3 this ported code, it's too messy
    getMarkerBubble = (marker: google.maps.Marker, pii: Pii, mapLatLng?: google.maps.LatLng, arnFromLatLng?: string, staticStreetViewThumbnailImageUrl?: string): google.maps.InfoWindow => {
        let bubble: google.maps.InfoWindow;
        let bubbleContent: string[] = [];

        let address = DataService.MAP_MARKER_BUBBLE_MISSING_ADDRESS;
        let shortLegalDescription: string = DataService.MAP_MARKER_BUBBLE_MISSING_LEGAL_DESCRIPTION;
        let noPropertyDescription: string = DataService.MAP_MARKER_BUBBLE_MISSING_ADDRESS_DESCRIPTION;
        let pin = DataService.NOT_APPLICABLE;
        let pinlabel = "PIN"
        var arn = DataService.NOT_APPLICABLE;
        var arnlabel = "ARN";
        let arnByLatLng = arnFromLatLng;
        var staticStreetViewImageUrl = DataService.NO_STREETVIEW_IMAGE;
        let streetViewNotAvailable: string = DataService.STREETVIEW_PROPERTY_NOT_AVAILABLE;
        let isCondoBuilding: boolean = marker.get('isCondoBuilding');
        let isCondoUnit: boolean = marker.get('isCondoUnit');

        if (pii) {
            var imageSrc;
            if (isCondoBuilding) {
                imageSrc = "assets/img/svg/map/icon_propertytype_condo_building_orange.svg"
            } else {
                imageSrc = "assets/img/svg/map/icon_propertytype_property_orange.svg"
            }

            //address
            let ellipsis: {} = {'length': 75, 'omission': ' &#8230;'};
            address = _.truncate(marker.get('address'), ellipsis);
            if (_.isEmpty(address)) {
                address = DataService.MAP_MARKER_BUBBLE_MISSING_ADDRESS;
            }
            
            //legal description
            if (isCondoBuilding) {
                shortLegalDescription = DataService.MAP_MARKER_BUBBLE_CONDO_BUILDING_DESCRIPTION;
            } else if (_.isEmpty(pii.shortLegalDescription)) {
                shortLegalDescription = DataService.MAP_MARKER_BUBBLE_MISSING_LEGAL_DESCRIPTION;
            } else {
                shortLegalDescription = _.truncate(pii.shortLegalDescription, { 'length': 70, 'omission': ' &#8230;' });
            }

            //pin or block
            if (isCondoBuilding) {
                pin = marker.get('condoBuildingBlockNumber');
                pinlabel = "BLOCK";
            } else {
                if(marker.get('pin')!= null) {
                    pin = marker.get('pin');
                }
                pinlabel = "PIN";
            }

            //arn
            var arndisplay = "display:block";

            if (pii.pin != null) {
                if (pii.pin != null && !pii.condo && pii.pin.length > 5) {
                    // Non Condo
                    if (pii.arns != null && pii.arns.length > 0) {
                        if (pii.arns.length == 1) {
                            arn = pii.arns[0];
                        } else if (pii.arns.length > 1) {
                            arnlabel = "ARN";
                            arn = "Multiple";
                        }

                    } else {
                        if (arnByLatLng) {
                            // div insert for ARN only (no street view)
                            var streetViewDiv2 = '<img class="bubble-links-left-img" src="assets/img/svg/map/icon_map_bubble_pegman-01-grey.svg" title="Street view is not available for this property"/><span class="bubble-links-left-dtls-disabled">Street View<span class="tool-tip">Street view is not available for this property</span></span>';

                            let linkimage = "assets/img/svg/map/icon_map_bubble_propertyreport.svg";
                            let linktext = "Property Report";
                            let linkdata = "openPropertyDetailsfromBubbleARNOnly()";    //TODO: port this function over

                            // For User Access Control and Check if is Property Report Link
                            let propertyReportAccessStyle = 'display:inline-block';
                        }
                    }
                } else {
                    if (isCondoBuilding) {
                        arndisplay = "display:none";
                        arn = "";
                        arnlabel = "";
                    } else if (isCondoUnit) {
                        arnlabel = "ARN";
                        arn = pii.arns[0];
                    }
                }
            } else {
                if (pii.arns != null && pii.arns.length > 0) {
                    if (pii.arns.length == 1) {
                        arn = pii.arns[0];
                    } else if (pii.arns.length > 1) {
                        arnlabel = "ARN";
                        arn = "Multiple";
                    }
                }
            }

            if (staticStreetViewThumbnailImageUrl) {
                staticStreetViewImageUrl = staticStreetViewThumbnailImageUrl;
            }
        }

        bubbleContent.push("<section class='pii-bubble-container'>");

        bubbleContent.push("<section class='header'>");

        bubbleContent.push("<section class='address-icon'>");
        bubbleContent.push("<img src='" + imageSrc + "'>");
        bubbleContent.push("</section>");

        bubbleContent.push("<section class='address'>");
        bubbleContent.push(address);
        bubbleContent.push("</section>");   //closes address
        bubbleContent.push("</section>");   //closes header

        bubbleContent.push("<section class='body'>");
        bubbleContent.push("<section class='property-thumbnail'>");
        //to avoid unnecessary google api calls, we will only retrieve the thumbnail image when the user mouses over the marker
        //bubbleContent.push("<img class='property-thumbnail-img' src='" + staticStreetViewImageUrl + "'>");
        bubbleContent.push("<img src='" + DataService.NO_STREETVIEW_IMAGE + "'>");  //always initially show the default no streetview image
        bubbleContent.push("</section>");   //closes property-thumbnail

        bubbleContent.push("<section class='property-details'>");
        bubbleContent.push("<section class='description'>");
        if ((pin == DataService.NOT_APPLICABLE && arn == DataService.NOT_APPLICABLE)) {
            bubbleContent.push(noPropertyDescription);
        } else {
            bubbleContent.push(shortLegalDescription);
        }
        bubbleContent.push("</section>");   //closes description

        bubbleContent.push("<section class='pin-arn-container'>");
        bubbleContent.push("<section class='pin-container'>");
        bubbleContent.push("<div class='pin-label'>" + pinlabel + "</div>");
        bubbleContent.push("<div id='pin-value' class='pin-value'>" + pin + "</div>");
        bubbleContent.push("</section>");   //closes pin-container
        bubbleContent.push("<section class='arn-container'>");
        bubbleContent.push("<div class='arn-label'>" + arnlabel + "</div>");
        bubbleContent.push("<div class='arn-value'>" + arn + "</div>");
        bubbleContent.push("</section>");   //closes arn-container
        bubbleContent.push("</section>");   //closes pin-arn-container

        bubbleContent.push("<section class='links-container'>");

        if ((pin != DataService.NOT_APPLICABLE || arn != DataService.NOT_APPLICABLE)) {

            if (this.userAccessControls.googleStreetViewAccess) {
                bubbleContent.push("<section class='link1-container'>");
                bubbleContent.push("<img class='icon' src='assets/img/svg/map/icon_map_bubble_pegman-orange.jpg'>");
                bubbleContent.push("<div class='url'>Street View</div>");
                bubbleContent.push("</section>");   //closes link1-container
            } else {
                bubbleContent.push("<section class='link1-container'>");
                bubbleContent.push("<span><!-- no streetview access --></span>");
                bubbleContent.push("</section>");   //closes link1-container
            }

            bubbleContent.push("<section class='link2-container'>");
            if (isCondoBuilding) {
                bubbleContent.push("<img class='icon' src='assets/img/svg/map/icon_propertytype_condounits_blue.jpg'>");
                bubbleContent.push("<div id='property-report-link' class='url'>View Units</div>");
            } else {
                if (this.userAccessControls.propertyReportAccess) {
                    bubbleContent.push("<img class='icon' src='assets/img/svg/map/icon_map_bubble_propertyreport.jpg'>");
                    bubbleContent.push("<div id='property-report-link' class='url'>Property Report</div>");
                }
            }
            bubbleContent.push("</section>");   //closes link2-container
        }

        bubbleContent.push("</section>");   //closes links-container

        if ((arnByLatLng || (pin == DataService.NOT_APPLICABLE && arn != DataService.NOT_APPLICABLE && arnByLatLng != undefined)) && !isCondoBuilding) {

            if (pin == DataService.NOT_APPLICABLE && arn == DataService.NOT_APPLICABLE) {
            } else {
                bubbleContent.push("<section class='pin-arn-container'>");
                bubbleContent.push("<section class='pin-container'>");
                bubbleContent.push("<div class='pin-label'>" + pinlabel + "</div>");
                bubbleContent.push("<div class='pin-value'>N/A</div>");
                bubbleContent.push("</section>");
                bubbleContent.push("<section class='arn-container'>");
                bubbleContent.push("<div class='arn-label'>" + arnlabel + "</div>");
                bubbleContent.push("<div class='arn-value'>" + arnByLatLng + "</div>");
                bubbleContent.push("</section>");
                bubbleContent.push("</section>");   //closes pin-arn-container
            }

            bubbleContent.push("<section class='links-container'>");
            bubbleContent.push("<section class='link1-container'>");
            bubbleContent.push("<img class='icon' src='assets/img/svg/map/icon_map_bubble_pegman-grey.jpg' title='" + streetViewNotAvailable + "'>");
            bubbleContent.push("<div class='url-greyed' title=" + "'" + streetViewNotAvailable + "'" + ">Street View</div>");
            bubbleContent.push("</section>");

            if (this.userAccessControls.propertyReportAccess) {
                bubbleContent.push("<section class='link2-container'>");
                bubbleContent.push("<img class='icon' src='assets/img/svg/map/icon_map_bubble_propertyreport.jpg'>");
                bubbleContent.push("<div id='property-report-arn-link' class='url'>Property Report</div>");
                bubbleContent.push("</section>");
            }

            bubbleContent.push("</section>");   //closes links-container

        }

        bubbleContent.push("</section>");   //closes property-details
        bubbleContent.push("</section>");   //closes body
        bubbleContent.push("</section>");   //closes pii-bubble-container

        var contentDomElement = document.createElement('div');
        contentDomElement.innerHTML = bubbleContent.join("");

        bubble = new google.maps.InfoWindow({
            content: contentDomElement, //bubbleContent.join(""),
            pixelOffset: this.searchResultMarkerBubbleOffsetSize
        });

        return bubble;
    }

    //replaces $filter('titleCase') filter from angularjs
    convertToTitleCase(sentence: string): string {
        return _.snakeCase(sentence).split(`_`).map(x => _.capitalize(x)).join(` `);
    }

}
