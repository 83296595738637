import { BaseModel } from "../base-model";
import { RataReportPeriod } from "./reporting-period";

export class RataReportPeriods extends BaseModel {
    constructor(rrPeriods?: RataReportPeriods) {
        super(rrPeriods);

        if (rrPeriods) {
            if (Array.isArray(rrPeriods.reportingPeriods)) {
                this.reportingPeriods = rrPeriods.reportingPeriods.map(value => {
                    return new RataReportPeriod(value);
                });
            }
        }
    }

    reportingPeriods: RataReportPeriod[];

    public isReportPeriodsExist() {
        return (this.reportingPeriods && this.reportingPeriods.length > 0) ? true : false;
    }
}