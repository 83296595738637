<h1 id="rr-h1-aid-title" mat-dialog-title>
  Report Time Frame
</h1>

<mat-divider></mat-divider>

<div class="dialog-content" mat-dialog-content>
  @if (isYearly) {
    <div id="rr-aid-yearly-note">
      Please note, this report can only be generated on a 12-month rolling basis.
    </div>
    <div class="pt-2">
      <div class="">
        <span id="rr-aid-yearly-date-range">Date Range: {{result.fromDate | date : 'MMMM dd yyyy' }} to {{result.toDate | date : 'MMMM dd yyyy'}}</span>
      </div>
    </div>
  } @else {
    <div class="time-container">

      <div class="top">
        <div class="left">
          <mat-label id="rr-label-aid-select-quarter">Select Quarter: *</mat-label>
        </div>

        <div class="right">
          <mat-form-field id="rr-ff-aid-quarter" appearance="outline">
            <mat-label id="rr-label-aid-quarter">Choose a quarter</mat-label>
            <mat-select class="" id="residential-relocation-report-quarter-select">
              @for (qa of quartersArray; track qa) {
                <mat-option id="rr-option-aid-quarter-{{qa.value}}" [value]="qa.value" (onSelectionChange)="selected($event, qa.value)">
                  {{qa.display}}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      @if (result.fromDate && result.toDate) {
        <div class="bottom">
          <div class="left">
            <mat-label id="rr-label-aid-quarterly-date-range-label">Date Range:</mat-label>
          </div>


          <div class="right">
            <mat-label id="rr-label-aid-quarterly-date-range-value">{{result.fromDate | date : 'MMMM dd yyyy' }} to {{result.toDate | date : 'MMMM dd yyyy'}}</mat-label>
          </div>
        </div>
      }
    </div>
  }

</div>

<mat-divider></mat-divider>

<div align="end" mat-dialog-actions>

  @if (result.fromDate && result.toDate) {
    <button id="rr-btn-aid-continue" [mat-dialog-close]='result' cdkFocusInitial color="primary" mat-button>Continue</button>
  }

  <button id="rr-btn-aid-cancel" mat-button mat-dialog-close class="dialog-close-btn" type="button">Cancel</button>
</div>
