import { Pipe, PipeTransform } from '@angular/core';
import dayjs from "dayjs";
import {DateUtilityService} from "../utility/date.utility";

@Pipe({
  name: 'shortDate'
})
export class ShortDatePipe implements PipeTransform {

  constructor(private dateUtilityService: DateUtilityService) {
  }

  transform(value: any, ignoreTimeStamp?: boolean): any {
    return this.dateUtilityService.customFormatDate(value, 'MMM DD', ignoreTimeStamp);
  }
}
