export class UserPaymentMethod {

  constructor(userPaymentMethod? :UserPaymentMethod) {
    if(userPaymentMethod){
      for(let property in userPaymentMethod){
        if(userPaymentMethod.hasOwnProperty(property)){
          // @ts-ignore
          this[property] = userPaymentMethod[property];
        }
      }
    }
  }

	cardCompany: string;
	cardNumber: string;
	expiryDate: string;
	externalPaymentId: string;
	nameOnCard: string;
	vaultID: string;

  get formattedExpireDate(): string {
    if (this.expiryDate && this.expiryDate.length >=4){
      return this.expiryDate.slice(0,2) + '/' + this.expiryDate.slice(2);
    }
    return this.expiryDate;
  }
}
