import {EStoreProduct} from "./e-store-product";
import {EstoreProductCategoryEnum} from "../../../enum/estore-product-category-enum";
import {TITLE_CHECK_PROD_ID} from "../../../../shared/constant/constants";

export class EStoreGroupedProduct{

  // this is the list of all products
  products: EStoreProduct[] = [];

  comparableSearchTopUpProducts: EStoreProduct[] = [];
  reportsTopUpProducts: EStoreProduct[] = [];

  // this is the list of products that are currently being displayed
  selectedProducts: (EStoreProduct[] | EStoreProduct)[] = [];

  selectedOrder: 'ASC' | 'DSC';

  loadProducts(products?: EStoreProduct[], preserveOrder = false) {
    if (products) {
      this.products = [...products];
      this.loadSelectedProducts(products);
    }
    if (preserveOrder){
      this.preserveOrder();
    }
  }

  setSelectedProducts(products?: EStoreProduct[], preserveOrder = false){
    if(products) {
      this.loadSelectedProducts(products);
    }
    if (preserveOrder){
      this.preserveOrder();
    }
  }

  // this method is used to load the selected products  from original products list
  reloadSelectedProducts(preserveOrder = false){
    this.loadSelectedProducts(this.products);
    if (preserveOrder){
      this.preserveOrder();
    }
  }

  private loadSelectedProducts(products: EStoreProduct[]){
    if(products) {
      this.comparableSearchTopUpProducts = [];
      this.reportsTopUpProducts = [];
      this.selectedProducts = [];

      products.forEach((product) => {
        if (product.isProductComparableSearchTopUp()) {
          this.comparableSearchTopUpProducts.push(product);
        } else if (product.isProductStandardTopUp()) {
          this.reportsTopUpProducts.push(product);
        } else {
          this.selectedProducts.push(product);
        }
      });
     if(this.reportsTopUpProducts.length > 0){
       this.selectedProducts.unshift(this.reportsTopUpProducts);
     }
     if(this.comparableSearchTopUpProducts.length > 0){
       this.selectedProducts.splice(2, 0, this.comparableSearchTopUpProducts);
     }
    }
  }

  sortOrderChanged($event: any) {
    const newOrder = $event;
    if (['ASC', 'DSC'].includes(newOrder)){
      this.selectedOrder = newOrder;
    }
    this.selectedProducts.sort((a, b) => {

      if (a instanceof EStoreProduct && b instanceof EStoreProduct)
      {
        // we have to show subscription renewal products at first place in ascending and descending order.
        if (a.category == EstoreProductCategoryEnum.GWH_RENEW_SUBSCRIPTION || b.category == EstoreProductCategoryEnum.GWH_RENEW_SUBSCRIPTION) {
          return (a.category == b.category ? 0 : a.category == EstoreProductCategoryEnum.GWH_RENEW_SUBSCRIPTION ? -1 : 1);
        }

          // The product 'Property Survey Image' has no price, but as per the requirement this product should meet below criteria
        // in ascending order it should show in last position, and in descending it should show in first.
        else if (a.category == EstoreProductCategoryEnum.PROPERTY_SURVEY_IMAGE || b.category == EstoreProductCategoryEnum.PROPERTY_SURVEY_IMAGE) {
          if (a.category == EstoreProductCategoryEnum.PROPERTY_SURVEY_IMAGE) {
            return newOrder == "ASC" ? 1 : -1;
          } else {
            return newOrder == "ASC" ? -1 : 1;
          }
        }
        // all products with price are sorted here
        return (newOrder == "ASC") ?
          a.price - b.price :
          b.price - a.price;
      } else {
        return 0;
      }
    });
  }

  preserveOrder() {
    if (['ASC', 'DSC'].includes(this.selectedOrder)) {
      this.sortOrderChanged(this.selectedOrder);
    }
  }

  filterProducts(category: EstoreProductCategoryEnum[]){
    this.setSelectedProducts(this.products.filter(value => category.includes(value.category)));
    this.preserveOrder();
  }

  get noProducts(): boolean {
    return this.products.length == 0;
  }

  get noSelectedProducts(): boolean {
    return this.selectedProducts.length == 0;
  }

  updateTitleCheckIndicators(titleCheckIndicators: string[]){
    this.products.forEach((product) => {
      if (product.prodId === TITLE_CHECK_PROD_ID && product.description?.bullets) {
        product.description.bullets = titleCheckIndicators;
      }
    });
  }

}
