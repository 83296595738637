import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LogLevel} from './log-level-model';
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  private defaultLogLevel = LogLevel.INFO;
  private _logLevel: LogLevel = this.defaultLogLevel;

  constructor(private http: HttpClient) {
  }

  logError = (message: any, ...optionalParams: any[]) => {
    this.log(message, LogLevel.ERROR, optionalParams);
  }

  logWarning = (message: any, ...optionalParams: any[]) => {
    this.log(message, LogLevel.WARNING, optionalParams);
  }

  logInfo = (message: any, ...optionalParams: any[]) => {
    this.log(message, LogLevel.INFO, optionalParams);
  }

  logDebugWithTime = (message: any, ...optionalParams: any[]) => {
    this.log(`${new Date().toLocaleTimeString()}::${message}`, LogLevel.DEBUG, optionalParams);
  }

  logDebug = (message: any, ...optionalParams: any[]) => {
    this.log(message, LogLevel.DEBUG, optionalParams);
  }

  logTable = (message: any, ...optionalParams: any[]) => {
    this.log(message, LogLevel.TABLE, optionalParams);
  }

  log = (message: any, level = LogLevel.DEBUG, ...optionalParams: any[]) => {
    if (this.shouldLog(level)) {
      switch (level) {
        case LogLevel.ERROR:
          console.error(message, (optionalParams?.length > 0) ? optionalParams : ' ');
          break;
        case LogLevel.WARNING:
          console.warn(message, (optionalParams?.length > 0) ? optionalParams : ' ');
          break;
        case LogLevel.INFO:
          console.info(message, (optionalParams?.length > 0) ? optionalParams : ' ');
          break;
        case LogLevel.DEBUG:
          console.debug(message, (optionalParams?.length > 0) ? optionalParams : ' ');
          break;
        case LogLevel.TABLE:
          console.table(message);
          break;
        default:
          console.log(message, (optionalParams?.length > 0) ? optionalParams : ' ');
      }
    }
  }

  private shouldLog = (level: LogLevel) => {
    if (this._logLevel === LogLevel.NONE) {
      return false;
    } else if (this._logLevel === LogLevel.ERROR) {
      return level === LogLevel.ERROR;
    } else if (this._logLevel === LogLevel.WARNING) {
      return level === LogLevel.ERROR || level === LogLevel.WARNING;
    } else if (this._logLevel === LogLevel.INFO) {
      return level === LogLevel.ERROR || level === LogLevel.WARNING || level === LogLevel.INFO;
    } else if (this._logLevel === LogLevel.DEBUG) {
      return level === LogLevel.ERROR || level === LogLevel.WARNING || level === LogLevel.INFO || level == LogLevel.DEBUG;
    } else {
      return true;
    }
  }

  loadLogProperties = async () => {
    let logLevel = environment.logLevel;
    switch (logLevel) {
      case LogLevel.ERROR:
        this._logLevel = LogLevel.ERROR;
        break;
      case LogLevel.WARNING:
        this._logLevel = LogLevel.WARNING;
        break;
      case LogLevel.INFO:
        this._logLevel = LogLevel.INFO;
        break;
      case LogLevel.DEBUG:
        this._logLevel = LogLevel.DEBUG;
        break;
      default:
        this._logLevel = this.defaultLogLevel;
    }
    console.log('Log level set to '+ this._logLevel);
  }

  // ToDo: we could use this method to set the log level to debug in the future (maybe a hotkey combination)
  setLogLevelToDebug = () => {
    console.log('Setting log level to DEBUG');
    this._logLevel = LogLevel.DEBUG;
  }
}
