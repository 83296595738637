import {BaseModel} from "../base-model";
import {SpatialPoint} from "../spatial/spatial-point";

export class MeasuredLineSegment extends BaseModel{

  constructor(mls?: MeasuredLineSegment) {
    super(mls);
    if(mls){
      if(mls.startPoint){
        this.startPoint = new SpatialPoint(mls.startPoint);
      }
      if(mls.endPoint){
        this.endPoint = new SpatialPoint(mls.endPoint);
      }
      if(mls.labelPosition){
        this.labelPosition = new SpatialPoint(mls.labelPosition);
      }
    }
  }

  startPoint: SpatialPoint;
  endPoint: SpatialPoint;
  labelPosition: SpatialPoint;
  labelAngle: number;
  measurement: string;
  uom: string;

}

