import {Injectable} from "@angular/core";

export class AddressUtility{

  static checkPostal = (postal: string) => {
    var regex = new RegExp(/^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i);
    if (regex.test(postal))
      return true;
    else
      return false;
  }

  static getPinCodeFromStreetAddress(streetAddress: string): string {
    if (streetAddress && streetAddress.length > 6) {
      let pincodenumber = streetAddress.slice(streetAddress.length - 6, streetAddress.length);
      if (this.checkPostal(pincodenumber)) {
        return pincodenumber;
      }
    }
    return '';
  }
}
