import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import dayjs from "dayjs";
import { ResidentialRelocationAdditionalInfoData } from "./residential-relocation-additional-info-data";
import { ResidentialRelocationAdditionalInfoResult } from "./residential-relocation-additional-info-result";
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { QuarterSelect, QuarterSelectValue } from "./quarter-select";
import { LoggerService } from '../../../../shared/service/log/logger.service';
import {MatOptionSelectionChange} from "@angular/material/core";

@Component({
  selector: 'gema3g-residential-relocation-additional-info-dialog',
  templateUrl: './residential-relocation-additional-info-dialog.component.html',
  styleUrls: ['./residential-relocation-additional-info-dialog.component.scss']
})
export class ResidentialRelocationAdditionalInfoDialogComponent {

  result: ResidentialRelocationAdditionalInfoResult = new ResidentialRelocationAdditionalInfoResult();
  quartersArray: QuarterSelect[] = [];

  constructor(public dialogRef: MatDialogRef<ResidentialRelocationAdditionalInfoDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ResidentialRelocationAdditionalInfoData,
              private loggerService: LoggerService) {
    dayjs.extend(quarterOfYear);
    this.init();
  }

  init() {
    let currentDay = parseInt(dayjs().format('D'));
    let currentMonth = parseInt(dayjs().format('M'));
    if (this.isYearly) {
      if (currentDay < 16) {
        this.result.fromDate = dayjs().subtract(1, 'year').subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
        this.result.toDate = dayjs().subtract(2, 'months').endOf('month').format('YYYY-MM-DD');
      } else {
        this.result.fromDate = dayjs().subtract(1, 'year').startOf('month').format('YYYY-MM-DD');
        this.result.toDate = dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
      }
    } else {
      const maxQuarters = 4;
      let refDay = dayjs();

      // if current date is on the first 15 days of the first month of this quarter move reference day to previous month
      // to accommodate this rule: "reports not available first 15 days after the end of quarter"
      if (currentMonth == parseInt(refDay.startOf('quarter').format('M')) && currentDay < 16) {
        refDay = refDay.subtract(16, 'days');
      }

      for(let i = maxQuarters; i > 0; i--){
        let dateInQuarter = refDay.subtract(i, 'Q');
        let fromDate = dateInQuarter.startOf('quarter').format('YYYY-MM-DD');
        let toDate = dateInQuarter.endOf( 'Q').format('YYYY-MM-DD');
        let quarter = dateInQuarter.format('YYYY-Q')+refDay.subtract(i, 'Q').quarter();
        this.quartersArray.unshift(
          new QuarterSelect( quarter,
            new QuarterSelectValue(fromDate, toDate,quarter))
        );
      }
      this.loggerService.logInfo(this.quartersArray);
    }
  }

  get isYearly(): boolean {
    return this.data.prodId == '1121';
  }

  selected(event: MatOptionSelectionChange, selectedValue: QuarterSelectValue) {
    if(event.source.selected && !!selectedValue) {
      this.result.toDate = selectedValue.toDate;
      this.result.fromDate = selectedValue.fromDate;
      this.result.quarter = selectedValue.quarter;
    }
  }
}

