import {Owner} from "./owner";
import {MailingAddress} from "./mailing-address";
import {BaseModel} from "../base-model";
import {DataService} from "../../../shared/service/data.service";

export class Foi extends BaseModel{
  constructor(foi? :Foi) {
    super(foi);

    if(foi){
      if(Array.isArray(foi.owners)){
        this.owners = foi.owners.map(value => { return new Owner(value) });
      }
      if(foi.mailingAddress){
        this.mailingAddress = new MailingAddress(foi.mailingAddress);
      }
    }

  }

  owners : Owner[];
  mailingAddress : MailingAddress ;

  get ownersList(): string {
    if(Array.isArray(this.owners)){
      return this.owners.map(value => { return value.ownerName }).filter(Boolean).join(', ');
    }
    return DataService.NOT_APPLICABLE;
  }

}
