import {AfterViewInit, Component, EventEmitter, Input, Output, inject} from '@angular/core';
import {PdfReportMyPropertyReportConfiguration} from "../../../../../model/pdf-report/input-param/pdf-report-my-property-report-configuration";
import {PdfReportBasicReportConfiguration} from "../../../../../model/pdf-report/input-param/pdf-report-basic-report-configuration";
import {PdfReportClientReportConfiguration} from "../../../../../model/pdf-report/input-param/pdf-report-client-report-configuration";
import {MatInputModule} from "@angular/material/input";
import {MaterialModule} from "../../../../../material.module";
import {SvgIconComponent} from "../../../../svg-icon/svg-icon.component";
import {PropertyDetail} from "../../../../../model/property/property-detail";
import {NgIf} from "@angular/common";
import {UserActivity} from "../../../../../model/user/user-activity";
import {lastValueFrom, Observable} from "rxjs";
import {UserService} from "../../../../../../shared/service/user.service";
import { CommonModule } from '@angular/common';

@Component({
  selector: 'gema3g-generate-pdf-report-property-info-section',
  standalone: true,
  imports: [
    MatInputModule,
    MaterialModule,
    SvgIconComponent,
    NgIf,
    CommonModule
  ],
  templateUrl: './generate-pdf-report-property-info-section.component.html',
  styleUrl: './generate-pdf-report-property-info-section.component.scss'
})
export class GeneratePdfReportPropertyInfoSectionComponent implements AfterViewInit{
  private userService = inject(UserService);
  recentlyViewedProperties$: Observable<UserActivity[]>;
  maxProperties: number = 4;

  @Input() inputParam: PdfReportMyPropertyReportConfiguration | PdfReportBasicReportConfiguration | PdfReportClientReportConfiguration;
  @Output() openComparableSales = new EventEmitter<boolean>();
  @Output() reloadProperty = new EventEmitter<string>();

  goToComparableSales() {
    this.openComparableSales.emit(true);
  }

  get hasPropertyInfo(): boolean {
    return this.inputParam instanceof PdfReportMyPropertyReportConfiguration || this.inputParam instanceof PdfReportClientReportConfiguration;
  }

  async ngAfterViewInit() {
    this.recentlyViewedProperties$ = this.userService.getUserActivity(this.maxProperties);
  }

  setSubjectProperty(pin: string) {
    this.reloadProperty.emit(pin);
  }
}
