import { BaseModel } from "../base-model";
import { RataFilterCriteriaInstrumentType } from "./filter-criteria-instrument-type";
import { RataFilterCriteriaLro } from "./filter-criteria-lro";

export class RataFilterCriteriaResponse extends BaseModel {

    constructor(filterResponse?: RataFilterCriteriaResponse) {
        super(filterResponse);

        if (filterResponse) {
            if (Array.isArray(filterResponse.lros)) {
                this.lros = filterResponse.lros.map(value => {
                    return new RataFilterCriteriaLro(value);
                });
            }

            if (Array.isArray(filterResponse.instrumentTypes)) {
                this.instrumentTypes = filterResponse.instrumentTypes.map(value => {
                    return new RataFilterCriteriaInstrumentType(value);
                });
            }

            if (Array.isArray(filterResponse.chargeInstrumentTypes)) {
                this.chargeInstrumentTypes = filterResponse.chargeInstrumentTypes.map(value => {
                    return new RataFilterCriteriaInstrumentType(value);
                });
            }

            if (Array.isArray(filterResponse.transferInstrumentTypes)) {
                this.transferInstrumentTypes = filterResponse.transferInstrumentTypes.map(value => {
                    return new RataFilterCriteriaInstrumentType(value);
                });
            }
        }
    }

    //these properties are populated from the backend
    public processId: string;
    public modelType: string;   //todo: convert this to RataReportTypeEnum
    public lros: RataFilterCriteriaLro[];
    public instrumentTypes: RataFilterCriteriaInstrumentType[];
    public chargeInstrumentTypes: RataFilterCriteriaInstrumentType[];
    public transferInstrumentTypes: RataFilterCriteriaInstrumentType[];
    //these properties are populated from the backend

    public pin: string;
    public partyFrom: string;
    public partyTo: string;
}