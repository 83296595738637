<div class="gema3g-ss-container">
  <div class="gema3g-ss-container__inner">

    <div class="gema3g-ss-container__inner-grid">

      <div class="l-column">
        <h4 id="ss-h4-struct-{{assessment.rollNumber}}">Structure</h4>        
      </div>

      <div class="m-column">
        <div id="ss-struct-pd-label-{{assessment.rollNumber}}" class="flex">
          <h4 id="ss-h4-struct-pd-{{assessment.rollNumber}}">Property Description </h4>
          <span id="ss-struct-pd-{{assessment.rollNumber}}" class="ml-auto min-w-[100px] text-right">{{ propertyDescription }}</span>
        </div>
      </div>

      <div class="r-column">
        <div id="ss-struct-pc-label-{{assessment.rollNumber}}" class="flex">
          <h4 id="ss-h4-struct-pc-{{assessment.rollNumber}}">Property Code </h4>
          <span id="ss-struct-pc-{{assessment.rollNumber}}" class="ml-auto">{{ propertyCode }}</span></div>
      </div>

    </div>
    
  </div>
</div>