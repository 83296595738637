import {BaseModel} from "../base-model";

export class TrackingRecord  extends BaseModel{

  constructor(tr?: TrackingRecord) {
    super(tr);
  }

  activity: string;
  address: string;
  businessEntityId: number;
  count: number;
  createdDate: string;
  pin: string;
  username: string;
  source: string;

  //UI only
  activityNameMap: Map<string, string> = new Map<string, string>([['circle', 'radius'], ['latlong', 'radius']]);

  //backend returns activity as a string in this format 'comparable.sales.by.[circle, polygon, municipality.latlong]'
  get activityLastSegment(): string {
    if (this.activity?.length > 0 && this.activity.includes('.') && this.activity.split('.').length > 0){
      const tokens = this.activity.split('.');
      return tokens[tokens.length - 1];
    }
    return this.activity;
  }

  get activityDisplayName(): string {
    return this.activityNameMap.get(this.activityLastSegment) || this.activityLastSegment;
  }

}
