import {AfterViewInit, Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {DemographicsAreaEnum} from "../../enum/demographics-area.enum";
import {FusionChartDataSource} from "../../model/fusion-chart/fusion-chart-data-source";
import {DataService} from "../../../shared/service/data.service";
import {parseInt} from "lodash";

@Component({
  template: ''
})
export class BaseChartComponent implements OnChanges, OnInit, AfterViewInit {
  //@ViewChild('parentDiv') parentDiv : ElementRef;
  @Input() selectedArea: DemographicsAreaEnum[] = [];
  charts : any[] = [];
  pinOrArn: string;

  demographicsAreaEnum = DemographicsAreaEnum;
  nbhDataSource: FusionChartDataSource = new FusionChartDataSource();
  comDataSource: FusionChartDataSource = new FusionChartDataSource();
  cityDataSource: FusionChartDataSource = new FusionChartDataSource();

  ds = DataService;
  defaultWidth = "100%";
  defaultHeight = ""
  minWidth = 230;
  maxWidth = 546;
  minHeight = 20;
  maxHeight = 200;

  ngOnChanges(changes: SimpleChanges): void {
    this.loadChartData();
  }

  ngOnInit(): void {
    this.setHeightWidth();
  }

  setHeightWidth() {}
  loadChartData(){}

  initialized($event: any) {
    this.charts.push($event.chart);
    this.loadChartData();
  }

  @HostListener('window:resize')
  onReSize(){
    if(this.charts) {
      this.charts.forEach(chart => {
        if(chart['id']) {
          // we force a re-sizing to refresh the width
          chart.resizeTo(this.defaultWidth, parseInt(this.defaultHeight));
        }
      });
      this.loadChartData();
    }
  }

  ngAfterViewInit(): void {
    setTimeout( () =>   this.loadChartData(), 100);
  }
}
