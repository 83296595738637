import { HttpClient } from "@angular/common/http";
import {inject, Injectable} from "@angular/core";
import { BehaviorSubject, catchError, map, Observable, throwError } from "rxjs";
import { DataService } from "../data.service";
import { LoggerService } from "../log/logger.service";
import { baseUrl } from "../system";
import * as _ from 'lodash';
import {PointOfView} from "../../../core/model/spatial/point-of-view";

@Injectable({
  providedIn: 'root'
})
export class StreetViewService {

  private https = inject(HttpClient);
  private loggerService = inject(LoggerService);

  private _mainMapStreetViewInitiated = new BehaviorSubject<boolean>(false);
  mainMapStreetViewInitiated$ = this._mainMapStreetViewInitiated.asObservable();

  private _geoAddressMapOverlayCoordinates = new BehaviorSubject<google.maps.LatLng>(DataService.TORONTO_COORDINATES);
  geoAddressMapOverlayCoordinates$ = this._geoAddressMapOverlayCoordinates.asObservable();

  public cancelMainMapStreetView = () => {
    this.initiateMainMapStreetView(false);
  }

  public initiateMainMapStreetView = (flag: boolean) => {
    this._mainMapStreetViewInitiated.next(flag);
  }

  public isMainMapStreetViewInitiated = (): boolean => {
    return this._mainMapStreetViewInitiated.getValue();
  }

  async isStreetViewServiceAvailable(lat: number, long: number): Promise<boolean> {
    let isAvailable: boolean = false;
    let streetViewService = new google.maps.StreetViewService();
    let projectionPosition = new google.maps.LatLng(lat, long);

    await new Promise(async (resolve, reject) => {
      await streetViewService.getPanorama( {location : projectionPosition} ,  (result: any, status: any) => {
        this.loggerService.logDebug('streetview service result', result);
        this.loggerService.logDebug('streetview service status', status);

        if (status == google.maps.StreetViewStatus.OK) {
          resolve(result);
        } else {
          reject(status);
        }
      });
    }).then((result) => {
      this.loggerService.logDebug('streetview service result', result);
      isAvailable = true;

    }).catch((err) => {
      this.loggerService.logError(`streetview service returned ${err} on coordinates`, lat, long);
    });

    return isAvailable;
  }

  getAddressByStreetViewPOV = (pov: PointOfView): Observable<any> => {
    const url = baseUrl + '/property/addressOverlay?lat=' + pov.latitude + '&lon=' + pov.longitude + '&heading=' + pov.heading;

    return this.https.get(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).pipe(
      map(response => {
        if (!_.isEmpty(response)) {
          return response;
        } else {
          return {};
        }
      }),
      catchError((err) => {
        this.loggerService.logError(`error getting property address by streetview point of view`, pov.latitude, pov.longitude, pov.heading, err);
        return throwError(err);
      })
    );
  }

  public updateGeoAdressMapOverlayCoordinates = (coordinates: google.maps.LatLng) => {
    this._geoAddressMapOverlayCoordinates.next(coordinates);
  }

}
