import {Pipe, PipeTransform} from "@angular/core";
import {DataService} from "../service/data.service";
import {DateUtilityService} from "../utility/date.utility";

@Pipe({
  name: 'longDateOrNa'
})
export class LongDateOrNaPipe implements PipeTransform {

  constructor(private dateUtilityService: DateUtilityService) {
  }

  transform(value: any): any {
    if (value) {
      return this.dateUtilityService.customFormatDate(value, 'MMM DD, YYYY', true);
    }
    return DataService.NOT_APPLICABLE;
  }
}
