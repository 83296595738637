<div class="gema3g-ss-container">
  <div class="gema3g-ss-container__inner">
    <div class="gema3g-ss-container__inner-grid-3">

      <div class="l-column">
        <gema3g-store-front-product-category-item [productCategoryItem]="productCategoryGroup?.item1">
        </gema3g-store-front-product-category-item>
      </div>

      <div class="m-column">
        <gema3g-store-front-product-category-item [productCategoryItem]="productCategoryGroup?.item2">
        </gema3g-store-front-product-category-item>
      </div>

      <div class="r-column">
        <gema3g-store-front-product-category-item [productCategoryItem]="productCategoryGroup?.item3">
        </gema3g-store-front-product-category-item>
      </div>

    </div>
  </div>
</div>