import {Component, Input} from '@angular/core';
import {ProductCategoryGroup} from "../../../../../core/model/store-front/product-category/product-category-group";

@Component({
  selector: 'gema3g-store-front-product-group',
  templateUrl: './store-front-product-category-group.component.html',
  styleUrls: ['./store-front-product-category-group.component.scss']
})
export class StoreFrontProductGroupComponent {
  @Input() productCategoryGroup: ProductCategoryGroup;

}
