import {inject, Injectable} from "@angular/core";
import {BehaviorSubject, catchError, map, Observable, throwError} from "rxjs";
import {LoggerService} from "./log/logger.service";
import {CookieService} from 'ngx-cookie-service';
import {baseUrl} from "./system";
import {HttpClient, HttpStatusCode} from "@angular/common/http";
import * as _ from "lodash";
import {FirstBaseSolutionHashResponse} from "../../core/model/map/fbs-hash-response";
import {DataService} from "./data.service";
import {LocalStorageKey} from "../constant/constants";
import {UserService} from "./user.service";

/**
 * First Base Solution
 */
@Injectable({
  providedIn: 'root'
})
export class FirstBaseSolutionService {

  https = inject(HttpClient);
  loggerService = inject(LoggerService)
  cookieService = inject(CookieService);
  dataService = inject(DataService);
  userService = inject(UserService);

  public static UNIQUE_ID: string = '02c14456180aeaa45376feb117b38d58';
  public static TILE_URL: string = 'https://teranet.firstbasesolutions.com/tiles/tms/canada/EPSG3857';
  public static DEFAULT_MINIMUM_ZOOM_LEVEL: number = 16;
  public static DEFAULT_MINIMUM_ADMIN_ZOOM_LEVEL: number = 5;

  minimumFBSAerialViewZoomLevel: number = DataService.DEFAULT_FBS_AERIAL_VIEW_ZOOM_LEVEL;

  /**
   * Updates FBS authentication cookie with the current date.
   */
  updateAuthenticationCookie = () => {
    if (this.cookieService.get('session_id')) {
      this.cookieService.delete('session_id'); //remove old cookie if it exists
      this.cookieService.delete('session_expire');
    }
    this.recreateAuthenticationCookie();
  }

  recreateAuthenticationCookie = async () => {
    const url = baseUrl + '/tiles/authenticate';
    this.loggerService.logDebug(`calling ${url} to recreate the fbs auth cookie`);

    try {
      var response = await fetch(url);
      if (response && response.status == HttpStatusCode.Ok) {
        await response.json().then((fbsHashResponse) => {
          var cookieOptions = {
            "path": "/tiles/tms/",
            "domain": "geowarehouse.ca", // cname for teranet.firstbasesolutions.com
            "expires": new Date(fbsHashResponse['expiryTime'] * 1000),
            "secure": false
          };
          this.cookieService.set('session_id', fbsHashResponse['hash'], cookieOptions);
          this.cookieService.set('session_expire', fbsHashResponse['expiryTime'], cookieOptions);

          //previousFBSExpiryTime = response['expiryTime'];

          this.loggerService.logDebug('refreshed fbs auth cookie');
          return <any>response;
        });

      } else {
        return throwError('unable to re-create the fbs auth cookie');
      }

      return null;

    } catch (e) {
      this.loggerService.logError('unable to re-create the fbs auth cookie', e);
      return throwError(e);
    }
  }

  getConfiguredMinimumFBSZoomLevel = async () => {
    // no restrictions for admins
    if(this.userService.user.roles.lppadmins){
      localStorage.setItem(LocalStorageKey.FBS_MINIMUM_ZOOM_LEVEL_KEY, String(FirstBaseSolutionService.DEFAULT_MINIMUM_ADMIN_ZOOM_LEVEL));
      return;
    }
    const url = baseUrl + '/tiles/zoom';
    this.loggerService.logDebug(`calling ${url} to get fbs minimum zoom level`);
    try {
      var response = await fetch(url);
      if (response && response.status == HttpStatusCode.Ok) {
        await response.text().then((level) => {
          localStorage.setItem(LocalStorageKey.FBS_MINIMUM_ZOOM_LEVEL_KEY, level);
        });

      } else {
        localStorage.setItem(LocalStorageKey.FBS_MINIMUM_ZOOM_LEVEL_KEY, FirstBaseSolutionService.DEFAULT_MINIMUM_ZOOM_LEVEL + '');
      }
      this.loggerService.logDebug(`fbs minimum zoom level is ${localStorage.getItem(LocalStorageKey.FBS_MINIMUM_ZOOM_LEVEL_KEY)}`);
      return null;

    } catch (e) {
      this.loggerService.logError('unable to retrieve the fbs minimum zoom level', e);
      return throwError(e);
    }
  }

  getMinimumFBSZoomLevel = async () => {
    await this.setMinimumFBSZoomLevel();
    let minLevel = localStorage.getItem(LocalStorageKey.FBS_MINIMUM_ZOOM_LEVEL_KEY);
    return parseInt(minLevel!);
  }

  setMinimumFBSZoomLevel = async () => {
    let minimumFBSZoomLevel = localStorage.getItem(LocalStorageKey.FBS_MINIMUM_ZOOM_LEVEL_KEY);
    if (_.isEmpty(minimumFBSZoomLevel)) {
      await this.getConfiguredMinimumFBSZoomLevel();
    }
  }

  isFBSMapSelected = (): boolean => {
    let mapSelected = localStorage.getItem(LocalStorageKey.FBS_ENHANCED_AERIAL_MAP_SELECTED_KEY);
    if (_.isEmpty(mapSelected)) {
      return false;
    } else {
      let selected = mapSelected === 'true';
      return selected;
    }
  }

  setFBSMapSelected = (selected: boolean) => {
    if (this.dataService.getMapLayerVisibility()) {
      this.dataService.getMapLayerVisibility().firstBaseSolution = selected;
    }
    localStorage.setItem(LocalStorageKey.FBS_ENHANCED_AERIAL_MAP_SELECTED_KEY, selected.toString());
  }

  getMinimumFBSAerialViewZoomLevel(): Observable<any> {
    const url = baseUrl + '/tiles/zoom';

    return this.https.get(url).pipe(
      map(resp => {
        this.minimumFBSAerialViewZoomLevel = resp as number;
        return <any>resp;
      }),
      catchError((err) => {
        this.minimumFBSAerialViewZoomLevel = DataService.DEFAULT_FBS_AERIAL_VIEW_ZOOM_LEVEL;
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }
}
