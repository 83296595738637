<h1 id="spd-h1-title" mat-dialog-title>
  You must select a subject property before buying a report
</h1>
<div class="dialog-content" mat-dialog-content>
  @if (data) {
    <div>
      <p id="spd-msg-top" class="text-black">You can select any of your recently searched properties by clicking on the address here:</p>
    </div>
    <!-- TODO: replace with css grid -->
    <div class="max-w-screen-md">
      @if (data.length > 0) {
        @if (userAccessControls.propertyReportAccess) {
          <ul class="flex flex-wrap recent-properties">
            @for (pResult of data | slice:0:6; track pResult; let i = $index) {
              <li class="w-1/3 px-1">
                <a id="spd-link-property-{{i}}" [mat-dialog-close]='pResult'>
                  @if (pResult.pin == null && pResult.text.length === 15) {
                    <p id="spd-property-arn-{{i}}">Address Not Available<br/>ARN : {{pResult.text}}</p>
                  }
                  @if (pResult.pin != null &&  pResult.text!='') {
                    <p id="spd-property-addr-{{i}}">{{pResult.text}}</p>
                  }
                  @if (pResult.pin != null &&  pResult.text=='') {
                    <p id="spd-property-pin-{{i}}">Address Not Available<br/>PIN : {{pResult.pin}}</p>
                  }
                </a>
              </li>
            }
          </ul>
        }
      } @else {
        <div id="spd-no-property" class="no-properties-available">
          No Property selected
        </div>
      }
    </div>
    <div class="">
      <p id="spd-msg-bottom" class="">Want to access reports and records for another property?
        <a id="spd-link-search-gwh" [mat-dialog-close]="'CLOSE_STORE'" class="anchor">Search Geowarehouse!</a>
      </p>
    </div>
  }
</div>

<mat-divider></mat-divider>

<div align="end" mat-dialog-actions>
  <button id="spd-btn-close" mat-button mat-dialog-close class="dialog-close-btn" type="button">Close</button>
</div>