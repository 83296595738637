import { HttpClient } from '@angular/common/http';
import { baseUrl } from "../system";
import {inject, Injectable, OnInit} from '@angular/core';
import { map, catchError, throwError, Observable, lastValueFrom } from 'rxjs';
import * as _ from 'lodash';
import { PropertyDetail } from '../../../core/model/property/property-detail';
import { ArnDescription } from "../../../core/model/property/arn-description";
import { BaseModel } from 'src/app/core/model/base-model';
import { Pii } from '../../../core/model/property/pii';
import { LoggerService } from '../log/logger.service';
import { Centroid } from "../../../core/model/spatial/centroid";
import { PIIRequest } from 'src/app/core/model/property/pii.request';

@Injectable({
  providedIn: 'root'
})
export class PropertyReportSearchService {

  private https = inject(HttpClient);
  private loggerService = inject(LoggerService);

  getPropertyDetailByPin = (pin: string): Observable<PropertyDetail> => {
    const url = baseUrl + '/property/details?pin=' + pin;

    return this.https.get<PropertyDetail>(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).pipe(
      map(response => {
        if (!_.isEmpty(response)) {
          return new PropertyDetail(<PropertyDetail>response);
        } else {
          return new PropertyDetail();
        }
      }),
      catchError((err) => {
        this.loggerService.logError(`error getting property report by PIN ${pin}`, err);
        return throwError(err);
      })
    );
  }

  getPropertyDetailByArn = (arn: string): Observable<PropertyDetail> => {
    const url = `${baseUrl}/property/details/arn?arn=${arn}`;

    return this.https.get<PropertyDetail>(url).pipe(
      map(response => {
        if (!_.isEmpty(response)) {
          return new PropertyDetail(<PropertyDetail>response);
        } else {
          return new PropertyDetail();
        }
      }),
      catchError((err) => {
        this.loggerService.logError(`error getting property report by ARN ${arn}`, err);
        return throwError(err);
      })
    );
  }

  getPropertyHistoryByPin = (pin: string): Observable<any> => {
    const url = baseUrl + '/property/parentchild?pin=' + pin;

    return this.https.get<any>(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).pipe(
      map(response => {
        if (!_.isEmpty(response)) {
          return <any>response;
        } else {
          //return new PropertyDetail();
          return {};
        }
      }),
      catchError((err) => {
        this.loggerService.logError(`error getting property history by PIN ${pin}`, err);
        return throwError(err);
      })
    );
  }

  getPropertyAssessmentDescription = (pin: string): Observable<ArnDescription[]> => {
    const url = baseUrl + '/assessment/description/' + pin;

    return this.https.get<any>(url).pipe(
      map(response => {
        if (!_.isEmpty(response)) {
          return response.map((item: BaseModel) => new ArnDescription(item));
        } else {
          return {};
        }
      }),
      catchError((err) => {
        this.loggerService.logError(`error getting assessment descriptions for PIN ${pin}`, err);
        return throwError(err);
      })
    );
  }

  getCentroidForMap = async (subjectProperty: PropertyDetail) => {
    let lat: number | undefined;
    let lng: number | undefined;

    if (this.isCondoUnitLockerOrParking(subjectProperty)) {
      //show the map for the suite pin
      let suitePin = subjectProperty.getCondoSuitePin();
      if (suitePin) {
        let suitePropertyDetail: PropertyDetail = await lastValueFrom(this.getPropertyDetailByPin(suitePin!), {defaultValue: new PropertyDetail()});
        if (suitePropertyDetail) {
          lat = suitePropertyDetail?.pii?.pinXy?.centroid?.latitude;
          lng = suitePropertyDetail?.pii?.pinXy?.centroid?.longitude;
        }
      }
    } else {
      if(subjectProperty?.pii?.pinXy?.centroid) {
        lat = subjectProperty?.pii?.pinXy?.centroid?.latitude;
        lng = subjectProperty?.pii?.pinXy?.centroid?.longitude;
      } else if (subjectProperty?.pii?.spatial[0]){
        lat = subjectProperty?.pii?.spatial[0].centroid.latitude;
        lng = subjectProperty?.pii?.spatial[0].centroid.longitude;
      }else if (subjectProperty?.assessments[0]?.spatial[0]) {
        lat = subjectProperty?.assessments[0].spatial[0].centroid.latitude;
        lng = subjectProperty?.assessments[0].spatial[0].centroid.longitude;
      }
    }

    return new Centroid(lat, lng);
  }

  private isCondoUnitLockerOrParking = (subjectProperty: PropertyDetail): boolean => {
    return subjectProperty?.hasCondoDetail() && subjectProperty?.isCondoUnitLockerOrParking();
  }

}
