import {Component, EventEmitter, Output} from "@angular/core";
import {PinOrArn} from "../../../core/model/property/pin-or-arn";

@Component({
  template: ''
})
export class BasePropertyEventEmitter {
  @Output() onPropertyClick = new EventEmitter<PinOrArn>();

  goToProperty(result: PinOrArn) {
    if (result) {
      this.onPropertyClick.emit(result);
    }
  }
}
