import { Pipe, PipeTransform } from '@angular/core';
import {MeasurementUnitService} from "../service/measurement-unit.service";
import {DataService} from "../service/data.service";

@Pipe({
  name: 'metersToMilesWithUom'
})
export class MetersToMilesWithUomPipe implements PipeTransform {

  constructor(private measurementUnitService: MeasurementUnitService) {
  }

  transform(input: any, decimals: number): any {

    if(input == null){
      return DataService.NOT_APPLICABLE;
    }

    if (!isNaN(input)) {
      let n: number = parseFloat(input) * this.measurementUnitService.rate_MetersToKMMiles;
      let nStr: string = n.toFixed(decimals);

      nStr += '';
      var x = nStr.split('.');
      var x1 = x[0];
      var x2 = x.length > 1 ? '.' + x[1] : '';
      var rgx = /(\d+)(\d{3})/;

      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
      }
      var out = x1 + x2;

      return out + this.measurementUnitService.symbol_MetersToKMMiles;
    } else {
      return input;
    }
  }

}



