import {Injectable} from "@angular/core";
import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class CustomValidatorService {

  /**
   * Validates whether a string contains valid phone number including area code.
   * 
   * @returns 
   */
  public cellPhoneValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      //this.loggerService.logInfo(control.value);
      if (!control.value) {
        return null;
      }
      const regex = new RegExp("/^[^\s@]+@[^\s@]+\.[^\s@]{1,}$/");
      return regex.test(control.value) ? null : {cellPhoneInvalid: true};
    }
  }

  /**
   * Validates whether a number is formatted with commas only. Ie, no $ sign and no decimal.
   * 
   * @returns 
   */
  public searchComparablesNumberFormatValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      //this.loggerService.logInfo(control.value);
      if (!control.value) {
        return null;
      }

      const regex = new RegExp("^([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)?$");
      let test: boolean = regex.test(control.value);
      return test ? null : {numberFormatInvalid: true};
    }
  }

  /**
   * Validates whether a string contains exactly 9 digits.
   * 
   * @returns 
   */
  public pinValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      const regex = new RegExp("^[0-9]{9}$");
      return regex.test(control.value?.trim()) ? null : {pinInvalid: true};
    }
  }

  /**
   * Validates whether a string contains space characters only. I.e, Whitespace check not including tab, newline, etc.
   * 
   * @returns 
   */
  public allSpacesValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }

      if (typeof (control.value) === 'object') {
        return control.value.text.length === 0 ? {allSpaces: control.value.text} : null;
      } else {
        return control.value.length === 0 ? {allSpaces: control.value} : null;
      }
    }
  }
}
