<div class="market-analytics">
  @if (inputParam.marketAnalytics.canDisplayHistoricalTrends) {
    <div class="market-analytics-row">

      <div class="market-analytics-row-col">
        <div id="pdf-mr-ma-ht-label" class="market-analytics-row-col-item">
          Historical Trends
        </div>
        <div class="market-analytics-row-col-item">
          <mat-slide-toggle id="pdf-mr-ma-ht-toggle" [(ngModel)]="inputParam.marketAnalytics.historicalTrends" color="primary"></mat-slide-toggle>
        </div>
      </div>

      <div class="market-analytics-row-col">
      </div>

      <div class="market-analytics-row-col">
      </div>

    </div>
  }

  @if (inputParam.marketAnalytics.historicalTrends) {
    <div class="market-analytics-row">
      <div class="market-analytics-row-col col-span-2">
        <div class="text-end">
          <mat-checkbox id="pdf-checkbox-client-asvgr" [(ngModel)]="inputParam.marketAnalytics.averageSaleValueAndGrowthRates">
            Average Sale Value and Growth Rates
          </mat-checkbox>
        </div>
      </div>
      <div class="market-analytics-row-col">
        <div class="text-end">
          <mat-checkbox id="pdf-checkbox-mr-ma-ht-mt" [(ngModel)]="inputParam.marketAnalytics.historicalTrendsMarketTurnover">
            Market Turnover
          </mat-checkbox>
        </div>
      </div>
    </div>
  }
  @if (inputParam.marketAnalytics.canDisplayHistoricalTrends) {
    <mat-divider></mat-divider>
  }

  @if (inputParam.marketAnalytics.canDisplayMarketStats) {
    <div class="market-analytics-row">
      <div class="market-analytics-row-col">
        <div id="pdf-mr-ma-ms-label" class="market-analytics-row-col-item">
          Market Statistics
        </div>
        <div class="market-analytics-row-col-item">
          <mat-slide-toggle id="pdf-mr-ma-ms-toggle" [(ngModel)]="inputParam.marketAnalytics.marketStatistics" color="primary"></mat-slide-toggle>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
  }

  @if (inputParam.marketAnalytics.canDisplayAreaHeatmaps) {
    <div class="market-analytics-row">
      <div class="market-analytics-row-col">
        <div id="pdf-mr-ma-ahm-label" class="market-analytics-row-col-item">
          Area Heat Maps
        </div>
        <div class="market-analytics-row-col-item">
          <mat-slide-toggle id="pdf-mr-ma-ahm-toggle" [(ngModel)]="inputParam.marketAnalytics.areaHeatMaps" color="primary"></mat-slide-toggle>
        </div>
      </div>
    </div>

    @if (inputParam.marketAnalytics.areaHeatMaps) {
      <div class="market-analytics-row">
        <div class="market-analytics-row-col">
          <div class="text-end">
            <mat-checkbox id="pdf-checkbox-mr-ma-asv" [(ngModel)]="inputParam.marketAnalytics.averageSale">
              Average Sale Value
            </mat-checkbox>
          </div>
        </div>
        <div class="market-analytics-row-col">
          <div class="text-end">
            <mat-checkbox id="pdf-checkbox-mr-ma-ahm-gr" [(ngModel)]="inputParam.marketAnalytics.growthRates">
              Growth Rate
            </mat-checkbox>
          </div>
        </div>
        <div class="market-analytics-row-col">
          <div class="text-end">
            <mat-checkbox id="pdf-checkbox-mr-ma-ahm-mt" [(ngModel)]="inputParam.marketAnalytics.areaHeatMapsMarketTurnover">
              Market Turnover
            </mat-checkbox>
          </div>
        </div>
      </div>
    }
  }
</div>
