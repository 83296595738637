import {ComparablePreference} from "./comparable-preference";
import {GenericPreference} from "./generic-preference";
import {PropertyReportPreference} from "./property-report-preference";


export class UserPreference {
  constructor(userPreference?: UserPreference) {
    if (userPreference){
      for (let property in userPreference) {
        if (userPreference.hasOwnProperty(property)) {
          // @ts-ignore
          this[property] = userPreference[property];
        }
        this.comparablesPreference = new ComparablePreference(userPreference.comparablesPreference);
        this.genericPreference = new GenericPreference(userPreference.genericPreference);
        this.propertyReportPreference = new PropertyReportPreference(userPreference.propertyReportPreference);
      }
    }
  }

	comparablesPreference: ComparablePreference;
	genericPreference: GenericPreference;
	propertyReportPreference: PropertyReportPreference;


  patchFromUserInput(userPreference?: UserPreference){
    if(userPreference){
      if (userPreference.genericPreference){
        this.genericPreference.patchFromUserInput(userPreference.genericPreference);
      }
      if (userPreference.comparablesPreference){
        this.comparablesPreference.patchFromUserInput(userPreference.comparablesPreference);
      }
      if (userPreference.propertyReportPreference) {
        this.propertyReportPreference.patchFromUserInput(userPreference.propertyReportPreference);
      }
    }
  }

  // values returned from system default values have a different format for some fields
  // making adjustments so it will apply correctly
  makeAdjustmentsOnSystemDefaultValues(){
    this.comparablesPreference?.makeAdjustmentsOnSystemDefaultValues();
    this.genericPreference?.makeAdjustmentsOnSystemDefaultValues();
    this.propertyReportPreference?.makeAdjustmentsOnSystemDefaultValues();
  }
}
