export enum MapTileEnum {
    OWNER_TILE = 'OWNER',
    ASSESSMENT_TILE = 'ASSESS',
    ADDRESS_TILE = 'ADDR',
    THEMATIC_TILE = 'THEMATIC_PT',
    MUNICIPALITY_LRO_TILE = 'MUN_LRO',
    LOT_CONCESSION_TILE = 'CONN',
    LRO_SURVEY_PLAN_TILE = 'LRO_SURVEY_PLAN',
    LRO_PLAN_TILE = 'LRO_PLAN',
    FIRST_BASE_SOLUTION_TILE = 'FBS'
}