import { inject, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { LoggerService } from "./log/logger.service";

declare var webkitSpeechRecognition: any;

@Injectable({
  providedIn: 'root'
})
export class VoiceRecognitionService {

    constructor() {
    }

    private loggerService = inject(LoggerService);
    
    private wksRecognition =  new webkitSpeechRecognition();
    private _textOutputSubject = new BehaviorSubject<string | null>('');
    private textOutputObservable$ = this._textOutputSubject.asObservable();
    private isActive: boolean = false;

    transcriptArray = [];

    public getTextOutputObservable = () => {
        return this.textOutputObservable$;
    }
    
    public initialize = () => {
        this.wksRecognition.lang = 'en-US';

        this.wksRecognition.continuous = true;
        this.wksRecognition.interimResults = false; //true;

        this.wksRecognition.addEventListener('result', (e: { results: Iterable<unknown> | ArrayLike<unknown>; }) => {
            this.loggerService.logDebug(`voice recognition result`, e.results);

            const transcript = Array.from(e.results)
                .map((result: any) => result[0])
                .map((result) => result.transcript)
                .join('');

            //@ts-ignore
            this.transcriptArray.push(transcript);
            this._textOutputSubject.next(this.transcriptArray?.toString());

            this.stop();
        });

        this.wksRecognition.addEventListener('end', (condition: any) => {
            this.loggerService.logDebug(`voice recognition ended`);

            this.wksRecognition.stop();
            this.isActive = false;
            this.transcriptArray = [];
            this._textOutputSubject.next(null);

        });
    }

    public start = () => {
        try {
            this.wksRecognition.start();
            this.isActive = true;
            this.loggerService.logDebug('voice recognition started');

        } catch (e) {
            this.isActive = false;
            this.loggerService.logError('error starting voice recognition', e);
            throw e;
        }
    }

    public stop = () => {
        try {
            this.wksRecognition.stop();
            this.isActive = false;
            this.wksRecognition.isStoppedSpeechRecog = true;
            this.loggerService.logDebug(`voice recognition stopped? ${this.wksRecognition.isStoppedSpeechRecog}`);
        } catch (e) {
            this.isActive = true;
            this.loggerService.logError('error stopping voice recognition', e);
            throw e;
        }

    }

    public isListening = () => {
        return this.isActive;
    }
}