import { Component, Inject } from '@angular/core';
import { ConfirmDialogData } from "./confirm-dialog-data";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DialogReturnTypeEnum } from "../../../enum/dialog-return-type-enum";
import { ConfirmDialogUsageEnum } from './confirm-dialog-usage.enum';

@Component({
  selector: 'gema3g-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
  ) {
  }

  returnType = DialogReturnTypeEnum;

  get dialogUsage() {
    return this.data.dialogUsage;
  }



  public get ConfirmDialogUsageEnum() {
    return ConfirmDialogUsageEnum;
  }

  onFirstButtonClick() {
    this.dialogRef.close(DialogReturnTypeEnum.FIRST_BUTTON);
  }

  onSecondButtonClick() {
    this.dialogRef.close(DialogReturnTypeEnum.SECOND_BUTTON);
  }
}
