import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LoggerService } from '../../../shared/service/log/logger.service';
import { VoiceRecognitionService } from '../../../shared/service/voice-recognition.service';
import { faMicrophone, faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons';
import { BaseUnsubscribe } from '../base-unsubscribe/base-unsubscribe';
import { takeUntil } from 'rxjs';
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { defaultErrorMatSnackBarConfig, defaultMatSnackBarConfig } from '../../../shared/constant/constants';
import { DataService } from '../../../shared/service/data.service';
import {ErrorUtil} from "../../../shared/service/error.util";

@Component({
  selector: 'gema3g-voice-search',
  templateUrl: './voice-search.component.html',
  styleUrl: './voice-search.component.scss'
})
export class VoiceSearchComponent extends BaseUnsubscribe implements OnInit {
  constructor(private vrService: VoiceRecognitionService,
    private loggerService: LoggerService,
    private _snackBar: MatSnackBar) {

      super();
      this.vrService.initialize();
  }

  @Output() recognizedText = new EventEmitter<string | null>();
  faMicrophone = faMicrophone;
  faMicrophoneSlash = faMicrophoneSlash;

  startListening = () => {
    try {
      this.vrService.start();
    } catch (e) {
      this._snackBar.open(ErrorUtil.VOICE_SEARCH_RECOGNITION_START_ERROR, 'Close', defaultErrorMatSnackBarConfig);
    }
  }

  stopListening = () => {
    try {
      this.vrService.stop();
    } catch (e) {
      this._snackBar.open(ErrorUtil.VOICE_SEARCH_RECOGNITION_STOP_ERROR, 'Close', defaultErrorMatSnackBarConfig);
    }
  }

  toggleMic = () => {
    if (this.isMicrophoneActive()) {
      this.stopListening();
    } else {
      this.startListening();
    }
  }

  isMicrophoneActive = () => {
    return this.vrService.isListening();
  }

  ngOnInit(): void {
    this.vrService.getTextOutputObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((textOutput) => {
        if (textOutput) {
          this.loggerService.logDebug(`voice search recognized text: ${textOutput}`);
          this.recognizedText.emit(textOutput);
        } else {
          this.loggerService.logDebug(`no voice search text recognized`);
        }
      });
  }

}
