import {EstoreProductCategoryEnum} from "../../core/enum/estore-product-category-enum";


export class RouteMappingUtility {

  static eStoreCategories = [
    {
      "route": "m",
      "productCategory": EstoreProductCategoryEnum.MPAC
    },
    {
      "route": "mb",
      "productCategory": EstoreProductCategoryEnum.MPAC_BUNDLE
    },
    {
      "route": "ti",
      "productCategory": EstoreProductCategoryEnum.TERANET_INSIGHTS
    },
    {
      "route": "s",
      "productCategory": EstoreProductCategoryEnum.STAT
    },
    {
      "route": "h",
      "productCategory": EstoreProductCategoryEnum.HOODQ
    },
    {
      "route": "rs",
      "productCategory": EstoreProductCategoryEnum.GWH_RENEW_SUBSCRIPTION
    },
    {
      "route": "pyb",
      "productCategory": EstoreProductCategoryEnum.PYB
    },
    {
      "route": "rr",
      "productCategory": EstoreProductCategoryEnum.RESIDENTIAL_RELOCATION
    },
    {
      "route": "c",
      "productCategory": EstoreProductCategoryEnum.CONDO
    },
    {
      "route": "ot",
      "productCategory": EstoreProductCategoryEnum.PROPERTY_SURVEY_IMAGE
    }

  ];

  static eStoreRouteToCategory(route: string): EstoreProductCategoryEnum[] | null {
    let routes: string[] = [];
    if (route.indexOf('-') > -1){
      routes = route.split('-');
    } else {
      routes.push(route);
    }
    let eStoreCatProd : EstoreProductCategoryEnum[] = [];
    routes.forEach(r => {
      let found = this.eStoreCategories.find(item => item.route == r);
      if (found) {
        eStoreCatProd.push(found.productCategory);
      }
    });
    return eStoreCatProd;
  }

  static eStoreCategoryToRoute(productCategory: EstoreProductCategoryEnum): string {
    let found = this.eStoreCategories.find(value => value.productCategory == productCategory);
    if (found) {
      return found.route;
    }
    return '';
  }
}
