import {Injectable} from "@angular/core";
import {ComponentCanDeactivate} from "./component-can-deactivate";
import {Observable, Observer, of} from "rxjs";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import {ConfirmDialogData} from "../component/modal/confirm-dialog/confirm-dialog-data";
import {ConfirmDialogComponent} from "../component/modal/confirm-dialog/confirm-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {GoogleAnalyticsService} from "../../shared/service/google-analytics.service";

@Injectable( {providedIn : 'root'})
export class ModifiedFormGuard {

  constructor(private dialog: MatDialog,
              private gaService: GoogleAnalyticsService) {
  }

  canDeactivate(component: ComponentCanDeactivate, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (component.canDeactivate()){
      return of(true);
    }
    let errMsg = 'There was an error on your request.';
    if(currentRoute.url.some( urlPart => urlPart.path.indexOf('profile') > -1)){
      errMsg = 'Are you sure you want to discard changes made on your Account Profile?' ;
    }
    if(currentRoute.url.some( urlPart => urlPart.path.indexOf('preference') > -1)){
      errMsg = 'Are you sure you want to discard changes made on your Account Preferences?' ;
    }
    this.gaService.openModal('UnsavedChanges');
    const dialogData = new ConfirmDialogData('Unsaved Changes', [errMsg], 'Continue', 'Discard');
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {data: dialogData});
    return dialogRef.afterClosed();
  }

}
