import {Component, inject, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UserAccessControl} from "../../../model/user/user-access-control";
import {UserService} from "../../../../shared/service/user.service";
import {ComparablesReportService} from "../../../../shared/service/comparables-report.service";
import {ComparableSalesReportOption} from "../../../model/comparables/report/comparable-sales-report-option";
import {MatRadioChange} from "@angular/material/radio";
import {lastValueFrom} from "rxjs";
import {PropertyDetail} from "../../../model/property/property-detail";
import {ComparableSalesReportResponse} from "../../../model/comparables/report/comparable-sales-report-select-report-from-modal";

@Component({
  selector: 'gema3g-comparables-report-select-report',
  templateUrl: './comparables-report-select-report.html',
  styleUrls: ['./comparables-report-select-report.scss']
})
export class ComparablesReportSelectReport implements OnInit {
  userAccessControls: UserAccessControl;

  constructor(public dialogRef: MatDialogRef<ComparablesReportSelectReport>,
              @Inject(MAT_DIALOG_DATA) public data: PropertyDetail,
              private userService: UserService) {
    this.userAccessControls = this.userService.getUserAccessControl();
  }

  comparableSaleReportService = inject(ComparablesReportService);
  comparablesReportService = inject(ComparablesReportService);
  availableReports: ComparableSalesReportOption[] = [];
  selectedReportId: number;

  async ngOnInit(): Promise<void> {

    const reports = await lastValueFrom(this.comparablesReportService.getRecentReports(this.data.pii.pin, this.data.pii.arn));

    if (reports?.recentReports?.length) {
      for (let i = 0; i < reports.recentReports.length; i++) {
        this.availableReports.push(new ComparableSalesReportOption(reports.recentReports[i].reportId,
          reports.recentReports[i].reportRadioButtonLabel));
      }
      this.availableReports.splice(1, 0, new ComparableSalesReportOption(-1, 'A new report will be created for the subject property and selected comparables will be added to it.'));
    } else {
      this.availableReports.push(new ComparableSalesReportOption(-1, 'A new report will be created for the subject property and selected comparables will be added to it.'));
    }
    this.selectedReportId = -1;
  }

  radioChange($event: MatRadioChange) {

  }

  goToReport(openReport: boolean) {
    this.dialogRef.close( new ComparableSalesReportResponse ({openReport: openReport, reportId: this.selectedReportId}));
  }
}
