export  class Structure {

  constructor(structure: Structure) {
    if (structure) {
      for (let property in structure) {
        if (structure.hasOwnProperty(property)) {
          // @ts-ignore
          this[property] = structure[property];
        }
      }
    }
  }

  structureNumber: string;
  structureCode: string;
  actualYearBuilt: string;
  structureDescription: string;
  totalFullStoreysCount: string;
  partStoreysCode: string;
  partStoreysDescription: string;
  totalBedroomsCount: string;
  totalFullBathsCount: string;
  totalHalfBathsCount: string;
  totalFirePlacesCount: string;
  splitLevelTypeCode: string;
  splitLevelTypeDescription: string;
}
