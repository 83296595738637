import { Injectable, inject } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { LoggerService } from "../../../shared/service/log/logger.service";
import { UserService } from "../../../shared/service/user.service";
import { UserAccessControl } from "../../model/user/user-access-control";
import { RouteMappingUtility } from "../../../shared/utility/route-mapping-utility";
import { EstoreProductCategoryEnum } from "../../enum/estore-product-category-enum";


/**
 * This Auth guard restricts the route to users who have valid subscriptions.
 */
@Injectable({ providedIn: 'root' })
export class UserSubscriptionNotExpiredAuthGuard {

  private loggerService: LoggerService = inject(LoggerService);
  private router: Router = inject(Router);
  private userService: UserService = inject(UserService);

  userAccessControls: UserAccessControl;
  
  private urlsPathsAllowedForSubscriptionExpiredUsers: string[] = [
    '/account/profile',
    '/catalogue/' + RouteMappingUtility.eStoreCategoryToRoute(EstoreProductCategoryEnum.GWH_RENEW_SUBSCRIPTION)
  ];

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree> {
    let user = this.userService.user;
    
    this.userAccessControls = this.userService.getUserAccessControl();
    let userSubcriptionExpired: boolean = this.userAccessControls.GWHRSubscriptionExpired;

    this.loggerService.logDebug(`user ${this.userService.user?.businessEntityId} subscription expired? ${userSubcriptionExpired}`);

    if (userSubcriptionExpired) {
      if (!this.urlsPathsAllowedForSubscriptionExpiredUsers.includes(state.url)) {
        this.router.navigate(['/home']);
        return false;
      }
    }

    return true;
  }
}
