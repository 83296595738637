import {ComparableSalesReportComparableProperty} from "./comparable-sales-report-comparable-property";
import {BaseModel} from "../../base-model";
import {ComparableSalesReportSubjectArn} from "./comparable-sales-report-subject-arn";
import dayjs from "dayjs";

export class ComparableSalesReportDetail extends BaseModel {
  reportId: number;
  reportName: string;
  modifiedDate: number;
  clientName: string;
  jobNumber: string;
  comments: string;
  subjectArnVO: ComparableSalesReportSubjectArn;
  compsArnVO: ComparableSalesReportComparableProperty[];

  constructor(comparableSalesReportDetail?: ComparableSalesReportDetail) {
    super(comparableSalesReportDetail);
    if (comparableSalesReportDetail) {
      this.subjectArnVO = new ComparableSalesReportSubjectArn(comparableSalesReportDetail.subjectArnVO);
      this.compsArnVO = comparableSalesReportDetail.compsArnVO.map((comp: any) => new ComparableSalesReportComparableProperty(comp));
    }
  }

  get reportDate(){
    return dayjs(this.modifiedDate).format('MM/DD/YYYY');
  }
}
