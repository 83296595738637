import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { PropertyDetail } from '../../../../core/model/property/property-detail';
import { PropertyReportComponentBase } from '../property-report-component-base';
import { PropertyReportSectionEnum } from '../../../../core/enum/property-report-section-enum';
import { Demographics } from "../../../../core/model/demographics/demographics";
import { lastValueFrom, takeUntil } from "rxjs";
import { DemographicsService } from "../../../../shared/service/demographics.service";
import { DemographicsAreaEnum } from "../../../../core/enum/demographics-area.enum";
import { DataService } from '../../../../shared/service/data.service';
import { GoogleAnalyticsService } from '../../../../shared/service/google-analytics.service';

@Component({
  selector: 'gema3g-property-report-demographics',
  templateUrl: './demographics.component.html',
  styleUrls: ['./demographics.component.scss']
})
export class DemographicsComponent extends PropertyReportComponentBase implements OnInit, AfterViewInit {

  constructor() {
    super();
  }

  private dataService: DataService = inject(DataService);
  private demographicsService = inject(DemographicsService);
  private gaService = inject(GoogleAnalyticsService);

  selectedArea: DemographicsAreaEnum[] = [DemographicsAreaEnum.NBH];
  demographicsAreaEnum = DemographicsAreaEnum;
  subjectProperty: PropertyDetail;
  propertyReportSection = PropertyReportSectionEnum.DEMOGRAPHICS;
  demographicsData: Demographics = new Demographics();
  noDemographicsData: string = DataService.DEMOGRAPHICS_DATA_NOT_AVAILABLE;

  legend = [
    {
      title: "Neighbourhood:",
      body: DataService.DEMOGRAPHICS_LEGEND_NEIGHBOURHOOD,
      code: "PRCDDA 35202574"
    },
    {
      title: "Community:",
      body: DataService.DEMOGRAPHICS_LEGEND_COMMUNITY,
      code: "FSA M2R"
    },
    {
      title: "City:",
      body: DataService.DEMOGRAPHICS_LEGEND_CITY,
      code: "PRCDCSD 3519036"
    }
  ]

  refreshUI = async () => {
    this.loggerService.logDebug('demographics refresh UI');
    try {
      const demographicsDataForProperty= await lastValueFrom(this.demographicsService.getDemographicsDataForPropertyDetail(this.subjectProperty));
        if(demographicsDataForProperty){
          this.demographicsData = demographicsDataForProperty;
        }
    } catch (e) {
      this.loggerService.logError(`error loading demographics data for pin ${this.subjectProperty.pii.pin}`);
    }
  }

  initializeSubjectPropertyListener = () => {
    this.propertyReportService.subjectProperty$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((newPropertyDetail) => {
      this.subjectProperty = newPropertyDetail;

        setTimeout( () => {
          this.refreshUI();
        },1000);
    });
  }

  ngOnInit(): void {
    this.initializeSubjectPropertyListener();
  }

  ngAfterViewInit(): void {
    //this.initializeSubjectPropertyListener();
  }
}
