import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BaseMap } from "../../../../../core/component/base-map/base-map";
import { PlanSurvey } from "../../../../../core/model/plan/plan-survey";
import { PlanSurveyMapItData } from "../../../../../core/model/plan/plan-survey-map-it-data";
import * as _ from "lodash";
import { SurveyPlanTile } from "../../../main-map/tiles/survey-plan-tile";
import { PlanTile } from "../../../main-map/tiles/plan-tile";
import { DataService } from 'src/app/shared/service/data.service';

@Component({
  selector: 'gema3g-map-plans-survey',
  standalone: true,
  imports: [],
  templateUrl: './map-plans-survey.component.html',
  styleUrl: './map-plans-survey.component.scss'
})
export class MapPlansSurveyComponent extends BaseMap {
  minZoom: number;

  constructor() {
    super();
    this.componentPrefix = 'plans-survey';
    this.parentMapContainerElementId = 'plans-surveys-map';
    this.mapCopyUINotify = true;
    this.minZoom = DataService.PLAN_SURVEY_MAP_MIN_ZOOM_LEVEL;
  }

  @Input('selectPlanLabel') selectPlanLabel : string;
  @Input('selectedPlanNumber') selectedPlanNumber : string;
  @ViewChild('psMapContainer', {static: false}) gmapElementRef: ElementRef;

  override initializeMap = async() => {
    try {

      if (this.gmapElementRef !== undefined) {

        this.loggerService.logDebug("initializing plans and survey map");

        this.googleMap = new google.maps.Map(this.gmapElementRef.nativeElement, await this.getMapOptions(this.subjectProperty, google.maps.MapTypeId.ROADMAP));

        let mapControls = this.addMapControls(true);

        // @ts-ignore
        this.googleMap.controls[google.maps.ControlPosition.TOP_RIGHT].push(mapControls);
        this.startListenerOnFullScreen(this.gmapElementRef);

        super.initializeMap();
        
        this.propertyReportMapService.renderPolygon(this.googleMap, this.getPolygon(this.subjectProperty));
      } else {
        this.loggerService.logError(`Plan survey :: map could not be initialized, gmapElementRef = ${this.gmapElementRef}, centroid=${this.subjectProperty?.pii?.pinXy?.centroid}`)
      }
    } catch (e) {
      this.loggerService.logError(`error initializing parcel map on plan surveys for PIN=${this.subjectProperty?.pii?.pin}`);
    }
  }

  mapPlanSurvey = (planSurvey: PlanSurvey) => {

    this.googleMap.overlayMapTypes.clear();
    this.selectedPlanNumber = planSurvey.documentNumber;

    let mapItDataArray = planSurvey?.mapItData as unknown as PlanSurveyMapItData[];
    if (!_.isEmpty(mapItDataArray)) {

      let mapItData = mapItDataArray[0];

      let bounds = new google.maps.LatLngBounds();
      let centroid = mapItData.centroid;
      let polygonPoints = mapItData.polygon;

      polygonPoints.forEach(point => {
        var myLatLng = new google.maps.LatLng(point.latitude, point.longitude);
        bounds.extend(myLatLng);
      })

      this.googleMap.fitBounds(bounds);
      if (centroid && centroid.latitude != null && centroid.longitude != null) {
        this.googleMap.setCenter(new google.maps.LatLng(centroid.latitude, centroid.longitude));
      }
      if (this.googleMap?.getZoom()! < this.minZoom) {
        this.googleMap.setZoom(this.minZoom);
      }
    } else {
      this.selectedPlanNumber = this.selectedPlanNumber + ' (No mapping data found)';
    }

    if (planSurvey.planCategory == 'P') {
      this.googleMap.overlayMapTypes.setAt(0, new SurveyPlanTile(true, this.mainMapService.tileProtocol, this.mainMapService.tileServerUrl, this.mainMapService.tileAuthenticationKey, planSurvey));
    } else {
      this.googleMap.overlayMapTypes.setAt(0, new PlanTile(true, this.mainMapService.tileProtocol, this.mainMapService.tileServerUrl, this.mainMapService.tileAuthenticationKey, planSurvey));
    }

  }
}
