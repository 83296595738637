import {AfterViewInit, Component, inject, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogReturnTypeEnum} from "../../../enum/dialog-return-type-enum";
import {ProgressBarDialogData} from "./progress-bar-data";
import {LoggerService} from "../../../../shared/service/log/logger.service";

@Component({
  selector: 'gema3g-warning-dialog',
  templateUrl: './progress-bar-dialog.component.html',
  styleUrls: ['./progress-bar-dialog.component.scss']
})
export class ProgressBarDialogComponent implements AfterViewInit {
  constructor(
    public dialogRef: MatDialogRef<ProgressBarDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProgressBarDialogData,
  ) {
  }

  loggerService = inject(LoggerService);

  ngAfterViewInit(): void {
    this.callAsyncFunction();
  }

  async callAsyncFunction() {
    if (this.data?.asyncFunction) {
      this.loggerService.logDebug('Progress Bar Dialog: ' + this.data.title);
      await this.data.asyncFunction(this.data?.param).then(
        () => {
          this.dialogRef.close(DialogReturnTypeEnum.FIRST_BUTTON);
          this.loggerService.logDebug(`Progress Bar Dialog: ${this.data.title} completed`);
        }
      ).catch(error => {
        this.loggerService.logError(`Progress Bar Dialog: ${this.data.title} failed`, error);
      });
    }
  }
}
