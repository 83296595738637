export class PropertyHistory {

    constructor(propertyHistory?: PropertyHistory) {
        if (propertyHistory) {
            for (let property in propertyHistory) {
                if (propertyHistory.hasOwnProperty(property)) {
                    //@ts-ignore
                    this[property] = propertyHistory[property];
                }
            }
        }
    }

    pin: string;
    creationYear: number;
    eventType: string;
    status: string;
}