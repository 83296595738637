import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AddressCorrectionDialogData } from "./address-correction-dialog-data";
import { AddressUtility } from "../../../../shared/utility/address.utility";
import { AddressCorrectionRequestComment, AddressCorrectionRequestCustomFields, AddressCorrectionRequest, AddressCorrectionRequestRequester, AddressCorrection } from "./AddressCorrectionRequest";
import { UserService } from "../../../../shared/service/user.service";
import { User } from "../../../model/user/user";
import { lastValueFrom } from "rxjs";
import { ZendeskService } from "../../../../shared/service/zendesk.service";
import { LoggerService } from '../../../../shared/service/log/logger.service';

export type AddressCorrectionStatus = 'CollectingData' | 'Sending' | 'Success' | 'Error';

@Component({
  selector: 'gema3g-address-correction-dialog',
  templateUrl: './address-correction-dialog.component.html',
  styleUrls: ['./address-correction-dialog.component.scss']
})
export class AddressCorrectionDialogComponent {
  status: AddressCorrectionStatus = 'CollectingData';
  correctAddress: string;
  additionalComments: string;
  linkToGoogleMaps: string;
  GMapRegexStr: string = '(?:https?:\/\/)?(?:www\.)?google\.[a-z]+\/maps\/.*';
  GMapRegex: RegExp = new RegExp(this.GMapRegexStr);

  constructor(
    public dialogRef: MatDialogRef<AddressCorrectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddressCorrectionDialogData,
    private userService: UserService,
    private zendeskService: ZendeskService,
    private loggerService: LoggerService
  ) {

  }

  async buildRequest(): Promise<AddressCorrection> {
    const user: User = await lastValueFrom(this.userService.getUser());
    const request:AddressCorrectionRequest = new AddressCorrectionRequest() ;
    request.subject = "Address Correction Request For " + this.data.pinOrARN + ". Existing Address " + this.data.propertyAddress + "(in, LRO " + this.data.propertyLro + ")";
    request.via_id = 48;
    request.ticket_form_id = 360000040212;
    this.addCustomFields(request, user);
    this.addComment(request);
    this.addRequester(request, user);
    const addressCorrectionRequest : AddressCorrection = new AddressCorrection();
    addressCorrectionRequest.request = request;
    return addressCorrectionRequest;
  }

  addCustomFields(request: AddressCorrectionRequest, user: User) {
    request.custom_fields.push(
      new AddressCorrectionRequestCustomFields(360001208012, user.businessEntityId.toString()),
      new AddressCorrectionRequestCustomFields(360001266611, this.data.pin),
      new AddressCorrectionRequestCustomFields(360003282812, this.data.propertyAddress),
      new AddressCorrectionRequestCustomFields(360003322591, this.correctAddress),
      new AddressCorrectionRequestCustomFields(360003322611, this.additionalComments),
      new AddressCorrectionRequestCustomFields(8690894558093, encodeURI(this.linkToGoogleMaps))
    );
  }

  addComment(request: AddressCorrectionRequest) {
    request.comment = new AddressCorrectionRequestComment();
    request.comment.body = "Suggested Address: \n" + this.correctAddress + "\n";
    request.comment.uploads = [];
  }

  addRequester(request: AddressCorrectionRequest, user: User) {
    request.requester = new AddressCorrectionRequestRequester();
    if (user) {
      request.requester.email = user.email;
      request.requester.name = user.fullName;
      request.requester.locale_id = 1;
    }
  }

  get pinCode(): string {
    return AddressUtility.getPinCodeFromStreetAddress(this.data.propertyAddress);
  }

  async submitRequest() {
     this.status = 'Sending';
     const request : AddressCorrection = await this.buildRequest();
     const response = await lastValueFrom(this.zendeskService.postAddressCorrectionRequest(request));
     this.loggerService.logInfo(response);
     if(response){
       this.status = 'Success';
     }
  }

  activateZendesk() {
    setTimeout(() => {
      this.dialogRef.close();
    }, 100);
    this.zendeskService.showMessagingWidget();
    this.zendeskService.openMessagingWidget();
  }

  get dataNotValid(): boolean {
    return !this.correctAddress || !this.linkToGoogleMaps || !this.GMapRegex.test(this.linkToGoogleMaps);
  }
}
