<div class="gema3g-ss-container">
  <div class="gema3g-ss-container__inner">
    <div class="gema3g-ss-container__inner-grid">

      <div class="l-column">
        <h4 id="ss-h4-vs-{{assessment.rollNumber}}">Commercial Information</h4>
      </div>

      <div class="m-column">
        @for (item of assessment.siteDetail?.commercialInfo; track item) {
          @if (item.field1.label) {
            <div id="ss-vs-sd-label-{{assessment.rollNumber}}" class="flex">
              <h4 id="ss-h4-vs-sd-{{assessment.rollNumber}}">{{item.field1.label}}</h4>
              <span id="ss-vs-sd-{{assessment.rollNumber}}" class="ml-auto text-right"> {{item.field1.value}}</span>
            </div>
          }
        }
      </div>

      <div class="r-column">
        @for (item of assessment.siteDetail?.commercialInfo; track item) {
          @if (item.field2.label) {
            <div id="ss-vs-lv-label-{{assessment.rollNumber}}" class="flex">
              <h4 id="ss-h4-vs-lv-{{assessment.rollNumber}}">{{item.field2.label}}</h4>
              <span id="ss-vs-lv-{{assessment.rollNumber}}" class="ml-auto text-right"> {{item.field2.value}}</span>
            </div>
          }
        }
      </div>

    </div>
  </div>
</div>
<div class="dense-2">
  <gema3g-site-structure-structure-info [assessment]="assessment" [structureAssessment]="assessment.commercialStructureAssessments"></gema3g-site-structure-structure-info>
</div>