export type PropertyType = 'freehold' | 'condo';

export class FreeholdOrCondoSearchFocusState {
    propertyType: PropertyType;
    centroid: google.maps.LatLng;
    condoAddress: string;
    freeholdAddress: string;

    isCanCreateCircularSearchBuffer = () => {
        return (this.propertyType == 'freehold');
    }
}