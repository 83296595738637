import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {HttpInterceptorService} from "./http-interceptor.service";
import {HttpCacheInterceptor} from "./http-cache-interceptor";

export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpCacheInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpInterceptorService,
    multi: true
  },
];
