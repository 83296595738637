import {Pipe, PipeTransform} from "@angular/core";
import {DataService} from "../service/data.service";

@Pipe({
  name: 'na'
})
export class NaPipe implements PipeTransform {

  transform(value: any): any {
    if (value) {
      return value;
    }
    return DataService.NOT_APPLICABLE;
  }
}
