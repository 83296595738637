import { AfterViewInit, Component, effect, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MarkerWithLabel } from "@googlemaps/markerwithlabel";
import { MapTileEnum } from "../../../../../core/enum/map-tile-enum";
import { skip, takeUntil } from "rxjs";
import { MeasurementUnitService } from "../../../../../shared/service/measurement-unit.service";
import { MetersToFeetWithUomPipe } from "../../../../../shared/pipe/meters-to-feet-with-uom.pipe";
import { PropertyDetail } from "../../../../../core/model/property/property-detail";
import { MapTypeEnum } from "../../../../../core/enum/map-type-enum";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxChange, MatCheckboxModule } from "@angular/material/checkbox";
import { MatDividerModule } from "@angular/material/divider";
import { MatMenuModule } from "@angular/material/menu";
import { TooltipModule } from "primeng/tooltip";
import { NgClass } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { IsVisibleDirective } from "../../../../../shared/directive/is-visible.directive";
import { BaseMap } from "../../../../../core/component/base-map/base-map";

declare var InfoBox: any;

@Component({
  selector: 'gema3g-map-measurement',
  standalone: true,
  imports: [
    MatButtonModule,
    MatCheckboxModule,
    MatDividerModule,
    MatMenuModule,
    TooltipModule,
    NgClass,
    FormsModule,
    IsVisibleDirective
  ],
  templateUrl: './map-measurement.component.html',
  styleUrl: './map-measurement.component.scss'

})
export class MapMeasurementComponent extends BaseMap implements OnInit, AfterViewInit {

  constructor(
    private measurementUnitService: MeasurementUnitService,
    private metersToFeetWithUomPipe: MetersToFeetWithUomPipe,
    private elementRef: ElementRef
  ) {
    super();
    this.componentPrefix = 'msrmnt';
    this.parentMapContainerElementId = 'lot-measurement-map-container-id';
    this.mapCopyUINotify = true;
  }

  @ViewChild('lotMeasurementMapContainer', {static: false}) lotMeasurementElementRef: ElementRef;
  private measuringToolsLabelList: (typeof InfoBox)[] = [];
  measuringEnabled: boolean = false;
  markers: MarkerWithLabel[] = [];
  polyLine: google.maps.Polyline;
  totalDistance: number = 0.0;
  mapType: MapTypeEnum = MapTypeEnum.STANDARD;
  mapTypeAerialViewTilted: boolean = true;
  mapTypeAerialViewLabeled: boolean = true;

  ngAfterViewInit(): void {
    this.initializeTemporaryChangeInUomListener();
    this.initializeMap();
    this.startListenerOnFullScreen(this.lotMeasurementElementRef);
  }

  initializeTemporaryChangeInUomListener() {
    this.propertyReportService.temporaryChangeOnUom
      .pipe(skip(1), takeUntil(this.ngUnsubscribe))
      .subscribe((arn) => {
        this.initializeMap();
      });
  }


  override initializeMap = async () => {
    try {
      if (this.lotMeasurementElementRef !== undefined) {

        this.loggerService.logDebug("initializing lot measurement map");

        this.googleMap = new google.maps.Map(this.lotMeasurementElementRef.nativeElement, await this.getMapOptions(this.subjectProperty));
        
        // @ts-ignore
        this.googleMap.controls[google.maps.ControlPosition.TOP_LEFT].push(this.getMeasurementControls());
        // @ts-ignore
        this.googleMap.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(this.getDisplayControl());

        let mapControls = this.addMapControls(false);
        // @ts-ignore
        this.googleMap.controls[google.maps.ControlPosition.TOP_RIGHT].push(mapControls);

        this.googleMap.controls[google.maps.ControlPosition.TOP_RIGHT].push(this.getMenuButton());

        this.googleMap.controls[google.maps.ControlPosition.RIGHT_TOP].push(this.getMenuContent());

        super.initializeMap();
        
        setTimeout(() => {
          this.propertyReportMapService.renderPolygon(this.googleMap, this.getPolygon(this.subjectProperty));
          this.propertyReportMapService.renderLotSizeMeasurements(this.googleMap, this.subjectProperty?.pii?.measuredLineSegment, this.metersToFeetWithUomPipe);
          this.mainMapService.addSubjectPropertyRegistryMapLayer(this.googleMap, MapTileEnum.OWNER_TILE);
        }, 100);

      }
    } catch (e) {
      this.loggerService.logError(`error initializing lot measurement map of pin ${this.subjectProperty?.pii?.pin}`);
    }
  }


  toggleMeasure() {
    this.measuringEnabled = !this.measuringEnabled;
    if (this.measuringEnabled) {
      this.intializeMeasuringTool();
    } else {
      this.deleteMeasuringToolsLabels();
      this.deleteMarkers();
      this.deletePolyLines();
      this.googleMap.setOptions({
        draggableCursor: ''
      });
      google.maps.event.clearListeners(this.googleMap, "click");
      this.changeButtonsVisibility('none');

    }
  }

  restartMeasure() {
    this.deleteMeasuringToolsLabels();
    this.deleteMarkers();
    this.deletePolyLines();
    this.initPolyLine();
  }

  set distanceControlText(display: string) {
    if (document.getElementById('reg-measure-dc-distance')) {
      // @ts-ignore
      document.getElementById('reg-measure-dc-distance').innerText = display;
    }

  }

  initPolyLine() {
    this.distanceControlText = "Click on the map to start measuring";

    const lineSymbol = {

      path: "M0,0  L0,5  M0,0 L3,0 ",
      strokeOpacity: 1,
      strokeColor: '#FFFFFF',
      scale: 1.4
    }

    this.polyLine = new google.maps.Polyline({
      icons: [{
        icon: lineSymbol,
        offset: '10',
        repeat: '8px'
      }],
      path: [],
      strokeOpacity: 0,
      map: this.googleMap
    });
  }

  changeButtonsVisibility(visibility: string) {
    if (document.getElementById('distance-control-ctrl-btn')) {
      // @ts-ignore
      document.getElementById('distance-control-ctrl-btn').style.display = visibility;
    }
    if (document.getElementById('reg-measure-erase-btn')) {
      // @ts-ignore
      document.getElementById('reg-measure-erase-btn').style.display = visibility;
    }

  }

  intializeMeasuringTool() {

    this.changeButtonsVisibility('flex');

    this.initPolyLine();

    this.googleMap.setOptions({
      draggableCursor: 'crosshair'
    });

    let MeasuringToolsLabelList = [];

    let isClosed = false;

    google.maps.event.addListener(this.googleMap, "click", (event: { latLng: google.maps.LatLng; }) => {
      var markerIndex = this.polyLine.getPath().getLength();
      this.polyLine.setMap(this.googleMap);

      var marker = new MarkerWithLabel({
        labelContent: "",
        anchorPoint: undefined,
        animation: undefined,
        clickable: undefined,
        collisionBehavior: undefined,
        crossOnDrag: undefined,
        cursor: undefined,
        //label: undefined,
        //labelContent: undefined,
        labelZIndexOffset: 0,
        opacity: undefined,
        optimized: undefined,
        shape: undefined,
        title: undefined,
        visible: undefined,
        zIndex: undefined,
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          fillOpacity: 1.0,
          fillColor: '#F78F20',
          strokeOpacity: 1.0,
          strokeColor: '#FFFFFF',
          strokeWeight: 2.0,
          scale: 5
          // pixels
        },
        position: event.latLng,
        draggable: true,
        map: this.googleMap,
        //labelAnchor: new google.maps.Point(0, 0),
        //labelClass: "labels",
      });

      let infowindow = this.addEndpointInfoBox();
      let map = this.googleMap;
      var fromclick = true;
      marker.addListener('mouseover', () => {
        if (!fromclick) {
          infowindow.open(map, marker);
        }
      });
      marker.addListener('mouseout', () => {
        fromclick = false;
        infowindow.close();
      });
      marker.addListener('click', () => {
        infowindow.close();
      });
      marker.addListener('rightclick', () => {
        infowindow.close();
      });
      infowindow.close();

      this.polyLine.getPath().push(event.latLng);

      this.markers.push(marker);

      google.maps.event.addListener(marker, 'drag', (dragEvent: { latLng: google.maps.LatLng; }) => {
        infowindow.close();
        this.polyLine.getPath().setAt(markerIndex, dragEvent.latLng);
        this.updateMeasuringToolsDistance();
      });

      this.updateMeasuringToolsDistance();
    });
  }

  addEndpointInfoBox() {
    var pixelOffset = new google.maps.Size(10, -10)
    var infoBoxOptions = {
      content: "<div style='height: 20px;padding-top:4px; width: 125px;color: #1c1c1c;background-color: #ffffff;border-radius: 2px;box-shadow: 2px 2px 4px ;font-size: 11px;font-weight: 500;text-align: center; vertical-align: middle;'>Drag to Change</p>",
      disableAutoPan: false,
      maxWidth: 0,
      pixelOffset: pixelOffset,
      zIndex: null,
      boxStyle: {
        background: null,
        opacity: 1,
        width: "0px"
      },
      //closeBoxMargin: "0px 0px px px",
      enableCloseButton: false
    };
    return new InfoBox(infoBoxOptions);
  }

  deleteMarkers() {
    this.markers?.forEach((val) => {
      val.setMap(null);
      val.unbindAll();
    });
    this.markers = [];
  }

  deleteMeasuringToolsLabels() {
    this.measuringToolsLabelList.forEach(value => {
      // @ts-ignore
      if (value instanceof InfoBox) {
        (value as typeof InfoBox).close();
      }
    });
  }

  deletePolyLines() {
    this.polyLine.setMap(null);
  }

  convertCalculatedDistaceToUom(distance: number): string {
    if (this.measurementUnitService.isUomInMeters) {

      if (distance > 500) {
        distance = distance / 1000;
        return distance.toFixed(2) + " km ";
      } else {
        return distance.toFixed(2) + " m ";
      }
    } else {
      var formileconversion = distance;
      distance = distance * 3.2808;
      if (distance > 999) {
        return (formileconversion * 0.000621371).toFixed(2) + " mi ";

      } else {
        return distance.toFixed(2) + " ft ";
      }
    }
  }

  updateMeasuringToolsDistance() {
    if (this.measuringEnabled) {
      let lastPosition: google.maps.LatLng | undefined = undefined;
      let newTotalDistance = 0.0;
      this.deleteMeasuringToolsLabels();
      this.markers?.forEach((val) => {
        const markerPosition = val.getPosition();
        if (markerPosition) {
          if (lastPosition && markerPosition) {

            let compdistance: number = google.maps.geometry.spherical.computeDistanceBetween(lastPosition, markerPosition);
            var midP = google.maps.geometry.spherical.interpolate(lastPosition, markerPosition, 0.5);
            var heading = google.maps.geometry.spherical.computeHeading(lastPosition, markerPosition);
            var {pixelOffset, angleOffset} = this.mainMapService.calculateOffsets(heading);

            var roundHeading = Math.round(heading);
            var angle = roundHeading - angleOffset;

            var content = "<p style='color:#FFF; text-indent: -50px; transform: rotate(" + angle + "deg); font-weight: bold;  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;''>" + this.convertCalculatedDistaceToUom(compdistance) + "</p>";
            var myOptions = {
              content: content,
              disableAutoPan: true,
              maxWidth: 0,
              pixelOffset: pixelOffset,
              zIndex: null,
              boxStyle: {
                background: null,
                opacity: 1,
                width: "0px"
              },
              position: midP,
              cancelBubble: false
            };

            var infoBox = new InfoBox(myOptions);
            infoBox.open(this.googleMap);
            this.measuringToolsLabelList.push(infoBox);
            newTotalDistance += google.maps.geometry.spherical.computeDistanceBetween(lastPosition, markerPosition);
          }
          lastPosition = markerPosition;
        }
      });
      this.totalDistance = newTotalDistance;
      this.distanceControlText = `Total Distance: ${this.convertCalculatedDistaceToUom(this.totalDistance)}`;
    }
  }

  protected readonly MTE = MapTypeEnum;


  onMapTypeSelected = (mapName: string) => {
    switch (mapName) {
      case MapTypeEnum.STANDARD:
        this.mapType = MapTypeEnum.STANDARD;
        setTimeout(() => {
          this.mapService.setMapType(this.googleMap, MapTypeEnum.STANDARD);
          this.mapService.applyFBSLayerVisibility(this.googleMap, false);
        }, 200);
        break;

      case MapTypeEnum.AERIAL:
        this.mapType = MapTypeEnum.AERIAL;
        setTimeout(() => {
          this.mapService.setMapType(this.googleMap, MapTypeEnum.AERIAL);
          this.mapService.applyFBSLayerVisibility(this.googleMap, false);
          this.onAerialViewLabelChange();
          this.onAerialViewTiltChange();
        }, 200);
        break;

      case MapTypeEnum.ENHANCED_AERIAL:
        this.mapType = MapTypeEnum.ENHANCED_AERIAL;
        this.mainMapService.setMapTilt(0);
        setTimeout(() => {
          this.mapService.setMapType(this.googleMap, MapTypeEnum.ENHANCED_AERIAL);
        }, 200);
        this.mapService.applyFBSLayerVisibility(this.googleMap, true);
        this.mainMapService.refreshMapLayer(MapTileEnum.FIRST_BASE_SOLUTION_TILE, true);
        break;

      case MapTypeEnum.SATELLITE:
        this.mapService.setMapType(this.googleMap, MapTypeEnum.SATELLITE);
        this.mapService.applyFBSLayerVisibility(this.googleMap, false);
        this.onAerialViewLabelChange();
        this.onAerialViewTiltChange();
        break;
    }
  }

  closedMenu(mapTypeMenu: string) {

  }

  onAerialViewTiltChange() {
    if(document.getElementById('map-msrmnt-checkbox-tilt-change')) {
      // @ts-ignore
      this.mapTypeAerialViewTilted = document.getElementById('map-msrmnt-checkbox-tilt-change').checked == true;
      setTimeout(() => {
        this.googleMap.setTilt((this.mapTypeAerialViewTilted) ? 45 : 0);
      }, 200);
    }
  }

  onAerialViewLabelChange() {
    if(document.getElementById('map-msrmnt-checkbox-label-change')) {
      // @ts-ignore
      this.mapTypeAerialViewLabeled = document.getElementById('map-msrmnt-checkbox-label-change').checked == true;
      setTimeout(() => {
        this.mapService.setMapType(this.googleMap, (this.mapTypeAerialViewLabeled) ? MapTypeEnum.AERIAL : MapTypeEnum.SATELLITE);
      }, 200);
    }
  }

  private getMenuButton(): HTMLDivElement {
    const mainDiv = document.createElement('div');
    mainDiv.id = `map-measure-view-menu-button-id`;
    mainDiv.className = 'map-measure-view-menu-button';
    mainDiv.innerHTML = '<img id="map-msrmnt-img-viewcontrol" src="assets/img/svg/map/icon_map_viewcontrol_ariel.svg"' +
      '           class="menu-img"' +
      '           pTooltip="Map Views"' +
      '           tooltipPosition="bottom">';
    mainDiv.addEventListener('click', () => {
      this.changeMenuVisibility();
    });
    return mainDiv;
  }

  changeMenuVisibility() {
    if (document.getElementById('map-measure-view-menu-options-id')) {
      // @ts-ignore
      document.getElementById('map-measure-view-menu-options-id').style.visibility = (document.getElementById('map-measure-view-menu-options-id').style.visibility == 'visible') ? 'hidden' : 'visible';
    }
  }

  private getMenuContent(): HTMLDivElement {
    const mainDiv = document.createElement('div');
    mainDiv.id = `map-measure-view-menu-options-id`;
    mainDiv.className = 'map-measure-view-menu-options-small';

    this.addRoadMapMenuItem(mainDiv);
    this.addAerialMenuItem(mainDiv);
    this.addEnhancedAerialMenuItem(mainDiv);

    return mainDiv;
  }

  markMenuActive(id: string){
    ['map-msrmnt-div-map','map-msrmnt-div-aerialview','map-msrmnt-div-enhanced-aerial'].forEach(value => {
      if(document.getElementById(value)) {
        // @ts-ignore
        document.getElementById(value).style.fontWeight =  (value == id) ? 'bold' :'normal';
      }
    });
  }

  addRoadMapMenuItem(mainDiv: HTMLDivElement) {
    const roadMapDiv = document.createElement('div');
    roadMapDiv.id = `road-map-id`;
    roadMapDiv.className = 'map-view-option';
    roadMapDiv.innerHTML =
      '              <div class="icon">' +
      '                <img id="map-msrmnt-img-mapview" src="assets/img/svg/map/icon_map_en-mapview.svg">' +
      '              </div>' +
      '              <div class="option-container">' +
      '                <div id="map-msrmnt-div-map" class="label">' +
      '                  Map' +
      '                </div>' +
      '                <div id="map-msrmnt-div-standard-road-map" class="label-small-hidden">' +
      '                  Standard Road Map' +
      '                </div>' +
      '            </div>';

    roadMapDiv.addEventListener('click', () => {
      this.onMapTypeSelected(MapTypeEnum.STANDARD);
      this.markMenuActive('map-msrmnt-div-map');
    });
    mainDiv.appendChild(roadMapDiv);
  }

  addAerialMenuItem(mainDiv: HTMLDivElement) {
    const aerialViewDiv = document.createElement('div');
    aerialViewDiv.id = `aerial-view-id`;
    aerialViewDiv.className = 'map-view-option';

    const aerialViewIconDiv = document.createElement('div');
    aerialViewIconDiv.className = 'icon';
    aerialViewIconDiv.innerHTML =
      '                <img id="map-msrmnt-img-aerialview" src="assets/img/svg/map/icon_map_en-aerialview.svg">';
    aerialViewDiv.appendChild(aerialViewIconDiv);

    const optionContainerDiv = document.createElement('div');
    optionContainerDiv.className = 'option-container';
    optionContainerDiv.innerHTML =
      '<div id="map-msrmnt-div-aerialview" class="label" style="font-weight: bold">' +
      '                  Aerial View' +
      '                </div>' +
      '                <div id="map-msrmnt-birds-eye-view-label" class="label-small-hidden">' +
      '                  Bird\'s Eye View' +
      '                </div>';

    const optionItemsDiv  = document.createElement('div');
    optionItemsDiv.className = 'tilt';
    optionItemsDiv.id = 'map-msrmnt-birds-eye-view-ctrls';
    optionItemsDiv.innerHTML =
      '      <div class="flex-1">' +
      '        <input type="checkbox" id="map-msrmnt-checkbox-tilt-change" color="primary">' +
      '        <span id="map-msrmnt-label-45" class="label !inline">45&#176;</span>' +
      '      </div>' +
      '      <div class="flex-1">' +
      '        <input type="checkbox" id="map-msrmnt-checkbox-label-change" color="primary">' +
      '        <span id="map-msrmnt-label-label" class="label !inline">Label</span>' +
      '      </div>';
    optionContainerDiv.appendChild(optionItemsDiv);

    aerialViewDiv.appendChild(optionContainerDiv);

    aerialViewDiv.addEventListener('click', () => {
      this.onMapTypeSelected(this.mapTypeAerialViewLabeled ? MapTypeEnum.AERIAL : MapTypeEnum.SATELLITE);
      this.markMenuActive('map-msrmnt-div-aerialview');
    });
    mainDiv.appendChild(aerialViewDiv);
  }

   addEnhancedAerialMenuItem(mainDiv: HTMLDivElement) {
    if (this.userAccessControls.FBSAccess) {
      const enhancedAerialDiv = document.createElement('div');
      enhancedAerialDiv.id = `enhanced-aerial-id`;
      enhancedAerialDiv.className = 'map-view-option';
      enhancedAerialDiv.innerHTML =

        '                <div class="icon">' +
        '                  <img id="map-msrmnt-img-satelliteview" src="assets/img/svg/map/icon_map_en-satelliteview.svg">' +
        '                </div>' +
        '                <div class="option-container">' +
        '                  <div id="map-msrmnt-div-enhanced-aerial" class="label">' +
        '                    Enhanced Aerial' +
        '                  </div>' +
        '                  <div id="map-msrmnt-div-high-resolution" class="label-small-hidden">' +
        '                    High Resolution Aerial View' +
        '                  </div>' +
        '                </div>';

      enhancedAerialDiv.addEventListener('click', () => {
        this.onMapTypeSelected(MapTypeEnum.ENHANCED_AERIAL);
        this.markMenuActive('map-msrmnt-div-enhanced-aerial');
      });
      mainDiv.appendChild(enhancedAerialDiv);
    }
  }

  getMeasurementControls(): HTMLDivElement {
    const mainDiv = document.createElement('div');
    mainDiv.id = `top-control-buttons`;
    mainDiv.className = 'map-measure-controls';

    const measureBtn = document.createElement('div');
    measureBtn.id = `reg-measure-ctrl-btn`;
    measureBtn.className = 'measure-control';
    measureBtn.innerHTML = '      <div id="reg-measure-ctrl-img" class="measure-img"></div>' +
      '      <div class="measure-button">' +
      '        <span id="reg-measure-ctrl-text" class="measure-text">Measure</span>' +
      '      </div>';
    measureBtn.addEventListener('click', () => {
      this.toggleMeasure();
    });
    mainDiv.appendChild(measureBtn);

    const eraseBtn = document.createElement('div');
    eraseBtn.id = 'reg-measure-erase-btn';
    eraseBtn.className = 'erase-measure-control';
    eraseBtn.innerHTML = ' <div id="reg-measure-erase-img" class="erase-measure-img"></div>' +
      '      <div class="erase-measure-button">' +
      '        <span id="reg-measure-erase-text" class="erasemeasure-text">Clear</span>' +
      '      </div>';
    eraseBtn.addEventListener('click', () => {
      this.restartMeasure();
    });
    mainDiv.appendChild(eraseBtn);

    return mainDiv;
  }

  getDisplayControl(): HTMLDivElement {
    const mainDiv = document.createElement('div');
    mainDiv.id = `bottom-controls-id`;
    mainDiv.className = 'bottom-controls';

    mainDiv.innerHTML = '<div class="map-measure-distance-control" id="distance-control-ctrl-btn">' +
      '      <div class="distance-control-text">' +
      '        <span id="reg-measure-dc-distance"></span>' +
      '      </div>' +
      '    </div>';

    return mainDiv;
  }

  override fullScreenModeChanged(){
    if (document.getElementById('map-measure-view-menu-options-id')) {
      // @ts-ignore
      document.getElementById('map-measure-view-menu-options-id').className =
        this.mapInFullScreen ? 'map-measure-view-menu-options-large' : 'map-measure-view-menu-options-small';
    }
  }

}
