import {inject, Injectable} from "@angular/core";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {TimerService} from "../timer/timer.service";
import {AuthenticationService} from "../authentication.service";
import {LoggerService} from "../log/logger.service";
import {defaultErrorMatSnackBarConfig, LocalStorageKey} from "../../constant/constants";
import {MatSnackBar} from "@angular/material/snack-bar";
import dayjs from "dayjs";
import {ScreenManager} from "../screen-manager.service";
import {ErrorUtil} from "../error.util";
import {baseUrl} from "../system";
import {WarningService} from "../warning.service";
import {UrlService} from "../url.service";
import {GoogleAnalyticsService} from "../google-analytics.service";
import {DataService} from "../data.service";
import {WarningDialogData} from "../../../core/component/modal/warning-dialog/warning-dialog-data";
import {EstoreProductCategoryEnum} from "../../../core/enum/estore-product-category-enum";

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  private authService = inject(AuthenticationService);
  private timerService = inject(TimerService);
  private loggerService = inject(LoggerService);
  private _snackBar = inject(MatSnackBar);
  screenManager = inject(ScreenManager);
    private warningService = inject(WarningService);
    private urlService = inject(UrlService);
    private gaService = inject( GoogleAnalyticsService);

  private serverDidNotRespondCounter = 0;
  private serverDidNotRespondThreshold = 20;
  private errorMessageDelaySeconds = 10;
  private lastTimeWhenServerWasNotAvailable = dayjs();
  private serverNotAvailableHttpCodes = [408, 503, 504];

  private urlsToBeIgnoredFor401Check: string[] = [
    baseUrl + '/lotcon/municipalities',
    baseUrl + '/comparableSales/md',
    baseUrl + '/assessment/xref'
  ];

  // add here any other endpoints that might fail temporarily and will not need to trigger a snackbar msg
  private urlsToBeIgnoredForServerAvailableCheck: string[] = [
    baseUrl + '/estore/cart/counter',
    baseUrl + '/login'
  ];

  private urlsExceptedFromOktaToken: string[] = [
    'teranetcommercialsolutions.zendesk.com'
  ];

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //this.loggerService.logInfo(`${new Date()}::intercepted a ${req.method} with url = ${req.url}`);
    this.timerService.addHttpRequest(req.url);

    if (this.authService.oktaLogin && localStorage.getItem(LocalStorageKey.auth0Token) &&
      !this.urlsExceptedFromOktaToken?.some(url => { return req.url.includes(url) })) {
      req = req.clone(
        {
          setHeaders: {Authorization: `Bearer ${localStorage.getItem(LocalStorageKey.auth0Token)}`}
        }
      );
    }
    // if (this.needsOriginHeaderRemoved(req.url)) {
    //   const modifiedReq = req.clone(
    //     {
    //       headers : req.headers.delete('Origin').delete('Referer').set('Access-Control-Allow-Origin', '*')
    //     }
    //   );
    //   return next.handle(modifiedReq);
    // }
    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (this.serverNotAvailableHttpCodes.indexOf(error.status) > 0) {
            this.loggerService.logWarning(dayjs().diff(this.lastTimeWhenServerWasNotAvailable, 'second') > this.errorMessageDelaySeconds);
            this.serverDidNotRespondCounter++;
            if (this.serverDidNotRespondCounter > this.serverDidNotRespondThreshold) {
              this.authService.logoutFromApp();
              this.serverDidNotRespondCounter = 0;
            } else if (dayjs().diff(this.lastTimeWhenServerWasNotAvailable, 'second') > this.errorMessageDelaySeconds) {
              this.lastTimeWhenServerWasNotAvailable = dayjs();
              if (this.urlsToBeIgnoredForServerAvailableCheck.some(url => {
                return req.url.startsWith(url);
              })) {
                // do not display snackbar for login and endpoints that are part of any pooling process
              } else {
                this._snackBar.open(ErrorUtil.BACKEND_ERROR_HTTP_CALL, 'Close', defaultErrorMatSnackBarConfig);
                this.screenManager.closeAllScreens();
              }
            }
          } else if (error.status === 401) {
            const ignoredUrls = this.urlsToBeIgnoredFor401Check.some(url => {
              return req.url.startsWith(url);
            })

            if (ignoredUrls) {
              //these endpoints require product id 141 but they should not terminate the 3g session when 141 is off
            } else {
              return this.handle401(req, next);
            }
          } else if(error.status === 403 && error.error.key === "B2B_SUBSCRIPTION_LIMIT_REACHED" ) {
              this.loggerService.logDebug(`Error: ${error.error.message}`);
              this.showUserSearchWarning(0);
          } else if (error.status === 200) {
            this.serverDidNotRespondCounter = 0;
          }
        }
        return throwError(() => error);
      })
    );
  }

  needsOriginHeaderRemoved(url: string) {
    return url.endsWith('.pdf');
  }

    async showUserSearchWarning(counter: number): Promise<void> {
        const content = [DataService.SEARCH_RESULT_NO_BALANCE_WARNING];
        const dialogData = new WarningDialogData('Buy Top-Up?', content, '', 'Remind Me Later', 'Buy Top-up', true);
        this.gaService.openModal("PropertyReportLowBalance");
        this.warningService.showWarning(dialogData, true, 600, () => {
            this.urlService.openEstoreCatalogueWithCategory(EstoreProductCategoryEnum.ALL);
        });
    }

  private handle401(request: HttpRequest<any>, next: HttpHandler) {

    if (this.authService.isLoggedIn) {
      this.loggerService.logInfo(`Received a 401 on '${request.url}' request, logging out the user`);
      this.timerService.processSessionTimeoutMessage(true);
    }

    return next.handle(request);
  }
}

