import { HttpClient } from "@angular/common/http";
import {inject, Injectable} from "@angular/core";
import { baseUrl } from "./system";
import { map, catchError, throwError, Observable, lastValueFrom, BehaviorSubject } from 'rxjs';
import * as _ from "lodash";
import { LoggerService } from "./log/logger.service";
import { RataReportTypes } from "../../core/model/rata/report-types";
import { RataReportModels } from "../../core/model/rata/report-models";
import { RataReportPeriods } from "../../core/model/rata/reporting-periods";
import { RataSearchResponse } from "../../core/model/rata/search-response";
import { RataSearchRequest } from "../../core/model/rata/search-request";
import { RataPii } from "../../core/model/rata/pii";
import { RataFilterCriteriaResponse } from "../../core/model/rata/filter-criteria-response";
import { RataReportRecord } from "src/app/core/model/rata/report-record";

@Injectable({
    providedIn: 'root'
})
export class RegistrationTrackerService {

    private https = inject(HttpClient);
    private loggerService = inject(LoggerService);

    private _pii = new BehaviorSubject<RataPii>(new RataPii());
    pii$ = this._pii.asObservable();

    getObservablePiiValue(): RataPii {
        return this._pii.getValue();
    }

    updateObservablePiiValue = (pii: RataPii) => {
        this._pii.next(pii);
    }

    getObservablePiiPinValue = (): string | undefined => {
        return this.getObservablePiiValue()?.pin;
    }

    getObservablePiiIdValue = (): number | undefined => {
        return this.getObservablePiiValue()?.recordId;
    }

    getReportTypes = (businessEntityId: string): Observable<any> => {
        const url = baseUrl + '/rata/reporttypes?beid=' + businessEntityId;
        this.loggerService.logDebug(`calling ${url} to get rata report types for beid ${businessEntityId}`);

        return this.https.get<RataReportTypes>(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).pipe(
            map((response: RataReportTypes) => {
                if (!_.isEmpty(response)) {
                    return new RataReportTypes(<RataReportTypes> response);
                } else {
                    return new RataReportTypes();
                }
            }),
            catchError((err) => {
                this.loggerService.logError(`unable to retrieve rata report types for beid ${businessEntityId}`, err);
                return throwError(err);
            })
        );
    }

    getReportModels = (businessEntityId: string, reportType: string): Observable<any> => {
        const url = baseUrl + '/rata/modelnames?beid=' + businessEntityId + '&reporttype=' + reportType;
        this.loggerService.logDebug(`calling ${url} to get rata model names for beid ${businessEntityId}, report type ${reportType}`);

        return this.https.get<RataReportModels>(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).pipe(
            map((response: RataReportModels) => {
                if (!_.isEmpty(response)) {
                    return new RataReportModels(<RataReportModels> response);
                } else {
                    return new RataReportModels();
                }
            }),
            catchError((err) => {
                this.loggerService.logError(`unable to retrieve rata report models for beid ${businessEntityId}, report type ${reportType}`, err);
                return throwError(err);
            })
        );
    }

    getReportPeriods = (modelId: string): Observable<any> => {
        const url = baseUrl + '/rata/reportingperiods?modelId=' + modelId;
        this.loggerService.logDebug(`calling ${url} to get rata reporting periods for model id ${modelId}`);

        return this.https.get<RataReportPeriods>(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).pipe(
            map((response: RataReportPeriods) => {
                if (!_.isEmpty(response)) {
                    return new RataReportPeriods(<RataReportPeriods> response);
                } else {
                    return new RataReportPeriods();
                }
            }),
            catchError((err) => {
                this.loggerService.logError(`unable to retrieve rata reporting periods for model id ${modelId}`, err);
                return throwError(err);
            })
        );
    }

    getReport = (request: RataSearchRequest): Observable<any> => {
        const url = baseUrl + '/rata/report/search';
        const payload = JSON.stringify(request.getJSON());

        this.loggerService.logDebug(`calling ${url} to get report`);

        return this.https.post<RataSearchResponse>(url, payload, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).pipe(
            map((response: RataSearchResponse) => {
                if (!_.isEmpty(response)) {
                    return new RataSearchResponse(<RataSearchResponse> response);
                } else {
                    return new RataSearchResponse();
                }
            }),
            catchError((err) => {
                this.loggerService.logError(`unable to retrieve rata report`, err);
                return throwError(err);
            })
        );
    }

    getPii = (processId: string, pin: string): Observable<any> => {
        const url = baseUrl + '/rata/pii?pin=' + pin + '&processId=' + processId;
        this.loggerService.logDebug(`calling ${url} to get pii for pin ${pin}, process id ${processId}`);

        return this.https.get<RataPii>(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).pipe(
            map((response: RataPii) => {
                if (!_.isEmpty(response)) {
                    return response;
                } else {
                    return new RataPii();
                }
            }),
            catchError((err) => {
                this.loggerService.logError(`unable to retrieve rata pii for pin ${pin}, process id ${processId}`, err);
                return throwError(err);
            })
        );
    }

    getFilterCriteria = (processId: string): Observable<any> => {
        const url = baseUrl + `/rata/report/${processId}/dropdown`;
        this.loggerService.logDebug(`calling ${url} to get rata filter criteria for process id ${processId}`);

        return this.https.get<RataFilterCriteriaResponse>(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).pipe(
            map((response: RataFilterCriteriaResponse) => {
                if (!_.isEmpty(response)) {
                    return new RataFilterCriteriaResponse(<RataFilterCriteriaResponse> response);
                } else {
                    return new RataFilterCriteriaResponse();
                }
            }),
            catchError((err) => {
                this.loggerService.logError(`unable to retrieve rata filter criteria for process id ${processId}`, err);
                return throwError(err);
            })
        );
    }

    downloadAllReportActivities = (processId: string, sortColumn: string, sortDescending: boolean): Observable<Blob> => {
        const url = baseUrl + `/rata/report/downloadAll?processId=${processId}&sortBy=${sortColumn}&isDescending=${sortDescending}`;
        this.loggerService.logDebug(`calling ${url} to get download all rata report activities`);

        return this.https.get(url, {
            headers: {
                'Content-Type': 'application/json, text/plain'
            },
            responseType: 'blob'
        }).pipe(
            map((response: any) => {
                return response;
            }),
            catchError((err) => {
                this.loggerService.logError(`unable to download all rata report activities`, err);
                return throwError(err);
            })
        );
    }

    downloadSelectedReportActivities = (processId: string,  selectedRecordIds: number[]) : Observable<Blob> => {
        let url = baseUrl + `/rata/report/download?processId=${processId}&rataRecords=`;
        this.loggerService.logDebug(`calling ${url} to get selected download rata report activities`);

        if (!_.isEmpty(selectedRecordIds)) {
            url += selectedRecordIds.join(',');
        }

        return this.https.get(url, {
            headers: {
                'Content-Type': 'application/json, text/plain'
            },
            responseType: 'blob'
        }).pipe(
            map((response: any) => {
                return response;
            }),
            catchError((err) => {
                this.loggerService.logError(`unable to download selected rata report activities`, err);
                return throwError(err);
            })
        );
    }
}
