import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FulfillmentItem } from "../../../../model/download/fulfillment-item";
import { DownloadService } from "../../../../../shared/service/download.service";
import { PybPurchaseStatus } from "../../../../model/purchase/pyb-purchase/pyb-purchase-status";
import { lastValueFrom } from "rxjs";
import { LoggerService } from '../../../../../shared/service/log/logger.service';

@Component({
  selector: 'gema3g-pyb-report-status',
  templateUrl: './pyb-report-status.component.html',
  styleUrls: ['./pyb-report-status.component.scss']
})
export class PybReportStatusComponent {
  constructor(
    public dialogRef: MatDialogRef<PybReportStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FulfillmentItem,
    private downloadService: DownloadService,
    private loggerService: LoggerService
  ) {

  }

  isUpdatingStatus: boolean = false;
  isUpdatedStatusFrmPYB: boolean = false;
  isErrorInPybStatus: boolean = false;

  async getPYBUpdatedStatus(item: FulfillmentItem) {
    if(item && item.estorePybReport) {
      this.isErrorInPybStatus = false;
      this.isUpdatingStatus = true;
      try {
        const newStatus: PybPurchaseStatus = await lastValueFrom(this.downloadService.getPYBUpdatedStatus(item.estorePybReport.sourceOrderNumber));
        if (newStatus && newStatus.status_name != null) {
          if (this.data.estorePybReport) {
            this.data.estorePybReport.statusName = newStatus.status_name;
          }
          this.data.pybRptETA = newStatus.dt_due;
          this.isUpdatedStatusFrmPYB = true;
        } else {
          this.isErrorInPybStatus = true;
          this.isUpdatedStatusFrmPYB = false;
        }
      } catch (e) {
        this.loggerService.logError(e);
        this.isErrorInPybStatus = true;
      } finally {
        this.isUpdatingStatus = false;
      }
    }
  }
}
