import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { PropertyReportSectionEnum } from '../../../../core/enum/property-report-section-enum';
import { PropertyReportComponentBase } from '../property-report-component-base';
import { UserAccessControl } from '../../../../core/model/user/user-access-control';
import { EStoreProduct } from "../../../../core/model/product/e-store/e-store-product";
import { SelectedProperty } from "../../../../core/model/property/selected-property";
import { UserService } from "../../../../shared/service/user.service";
import { defaultResponsiveOptions } from "../../../../shared/constant/constants";
import { CarouselService } from "../../../../shared/service/carousel.service";
import { LayoutComponent } from "../layout/layout.component";
import { MatDividerModule } from "@angular/material/divider";
import { CarouselModule } from "primeng/carousel";
import { CoreModule } from "../../../../core/core.module";
import { PropertyReportService } from '../../../../shared/service/property-report.service';

@Component({
  selector: 'gema3g-property-report-residential-relocation',
  standalone: true,
  templateUrl: './residential-relocation.component.html',
  imports: [
    LayoutComponent,
    MatDividerModule,
    CarouselModule,
    CoreModule
  ],
  styleUrls: ['./residential-relocation.component.scss']
})
export class ResidentialRelocationComponent extends PropertyReportComponentBase {

  @Input() userAccessControls: UserAccessControl;
  @Input() carouselProducts: EStoreProduct[];
  @Input() selectedProperty: SelectedProperty;

  responsiveOptions = defaultResponsiveOptions;

  constructor(private userService : UserService,
              private carouselService: CarouselService) {
    super();
  }

  propertyReportSection = PropertyReportSectionEnum.RESIDENTIAL_RELOCATION;

  get showNavigators(): boolean{
    return this.carouselService.showNavigators(this.carouselProducts?.length);
  }
}
