import {ChangeDetectionStrategy, Component, Input, SimpleChanges} from '@angular/core';
import {DemographicsAreaEnum} from "../../../../../../core/enum/demographics-area.enum";
import {DemographicsPopulation} from "../../../../../../core/model/demographics/demographics-population";
import {MaritalStatusItemComponent} from "./marital-status-item/marital-status-item.component";

@Component({
  selector: 'gema3g-marital-status',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './marital-status.component.html',
  imports: [
    MaritalStatusItemComponent
  ],
  styleUrls: ['./marital-status.component.scss']
})
export class MaritalStatusComponent {
  @Input() selectedArea: DemographicsAreaEnum[] = [];
  @Input() demographicsPopulation = new DemographicsPopulation();
  demographicsAreaEnum = DemographicsAreaEnum;


}
