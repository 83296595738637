import {Component, EventEmitter, Output} from "@angular/core";
import {BaseUnsubscribe} from "../../../core/component/base-unsubscribe/base-unsubscribe";
import {OwnerToPropertyMap} from "../../../core/model/search-result/owner-to-property-map";
import {OwnerToPropertyMapItem} from "../../../core/model/search-result/owner-to-property-map-item";
import {PinOrArn} from "../../../core/model/property/pin-or-arn";
import {Pii} from "../../../core/model/property/pii";

@Component({
  template: ''
})
export class BaseSearchResults extends BaseUnsubscribe{
  @Output() onPropertyClick = new EventEmitter<PinOrArn>();

  goToProperty(result: OwnerToPropertyMap | OwnerToPropertyMapItem | Pii) {
    if (result) {
      let pinOrArn = new PinOrArn(result.pin, result.arn);
      this.onPropertyClick.emit(pinOrArn);
    }
  }
}
