import { AdBannerWeightEnum } from "../../enum/ad-banner-weight-enum"
import { PropertyReportSectionEnum } from "../../enum/property-report-section-enum";
import {AdBannerAction} from "./ad-banner-action";

export class AdBanner {

    public static IMAGE_AD: string = 'imageAd';
    public static TEXT_AD: string = 'textAd';

    constructor(adType: string, propertyReportSection: PropertyReportSectionEnum, adWeight: AdBannerWeightEnum, imageSource: string, action: AdBannerAction) {
        this.adType = adType;
        this.propertyReportSection = propertyReportSection;
        this.adWeight = adWeight;
        this.imageSource = imageSource;
        this.action = action;
    }

    adType: string;
    propertyReportSection: PropertyReportSectionEnum;
    adWeight: AdBannerWeightEnum;
    imageSource: string;
    action: AdBannerAction;
}
