import {HttpClient} from '@angular/common/http';
import {baseUrl} from "../system";
import {inject, Injectable, signal, WritableSignal} from '@angular/core';
import {catchError, lastValueFrom, map, Observable, throwError} from 'rxjs';
import * as _ from 'lodash';
import {ComparableSalesResponse} from '../../../core/model/comparables/comparable-sales-response';
import {LoggerService} from '../log/logger.service';
import {ComparableSalesRequest} from "../../../core/model/comparables/comparable-sales-request";
import {UserService} from "../user.service";
import {GoogleAnalyticsService} from "../google-analytics.service";
import {MatDialog} from "@angular/material/dialog";
import {DataService} from "../data.service";
import {ConfirmDialogComponent} from "../../../core/component/modal/confirm-dialog/confirm-dialog.component";
import {ConfirmDialogData} from "../../../core/component/modal/confirm-dialog/confirm-dialog-data";
import {UrlService} from "../url.service";
import {DialogReturnTypeEnum} from "../../../core/enum/dialog-return-type-enum";
import {LocalStorageKey} from "../../constant/constants";

@Injectable({
  providedIn: 'root'
})
export class ComparablesSearchService {

  private https = inject(HttpClient);
  private loggerService = inject(LoggerService);
  private userService = inject(UserService);
  private gaService = inject(GoogleAnalyticsService);
  private dialog = inject(MatDialog);
  private urlService = inject(UrlService);

  comparableSearchCounter: WritableSignal<number> = signal(0);
  lastComparableSearchRequest: ComparableSalesRequest = new ComparableSalesRequest();

  counterValuesThatTriggerPopUp: number[] = [25, 15, 5, 0];

  checkAvailableLicense(): boolean {
    if (this.userService?.user?.hasLicensePackageId()) {
      if(this.comparableSearchCounter() <= 0) {
        this.showLicensePopup(0);
        return false;
      }
    }
    return true;
  }

  async updateCounter(count: number) {
    if (this.userService.user.hasLicensePackageId()) {
      // unfortunately some of the comparables (comparableSales/condoByLatLong) replies do not have the amountLeft property set properly, a new call is needed
      if(count <= 0){
        const counter = await lastValueFrom(this.userService.getUserLicenseCounters());
        if(counter?.comparablesSearchCounter){
          count = counter.comparablesSearchCounter.licenseRemaining;
        }
      }
      if (count >= 0) {
        this.comparableSearchCounter.set(count);
        localStorage.setItem(LocalStorageKey.comparablesSalesCounter, String(count));

        if (this.counterValuesThatTriggerPopUp.some(value => value === count)) {
          this.showLicensePopup(count);
        }
      }
    }
  }

  async initCounters(loadFromLocalStorage: boolean = false) {
    if (this.userService.user.hasLicensePackageId()) {
      if(loadFromLocalStorage){
        const counter =  Number(localStorage.getItem(LocalStorageKey.comparablesSalesCounter));
        if(_.isNumber(counter)) {
          this.comparableSearchCounter.set(counter);
          return;
        }
      }
      const counters = await lastValueFrom(this.userService.getUserLicenseCounters());
      if (counters?.comparablesSearchCounter) {
        this.comparableSearchCounter.set(counters.comparablesSearchCounter.licenseRemaining);
        localStorage.setItem(LocalStorageKey.comparablesSalesCounter, String(counters.comparablesSearchCounter.licenseRemaining));
      }
    }
  }

  getComparableSalesByRadius = (request: ComparableSalesRequest): Observable<ComparableSalesResponse> => {
    const url = baseUrl + '/comparableSales/circle?sort=area&direction=asc';
    const payload = JSON.stringify(request);
    try {
      this.lastComparableSearchRequest = <ComparableSalesRequest>request;
    } catch (ex) {
      this.loggerService.logError('could not register comparable sale request');
    }

    return this.https.post<ComparableSalesResponse>(url, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).pipe(
      map(response => {
        const result = new ComparableSalesResponse(response);
        this.updateCounter(result.amountLeft);
        return result;
      }),
      catchError((err) => {
        this.loggerService.logError('error getting comparables sales by radius', err);
        return throwError(err);
      })
    );
  }

  getComparableSalesByPolygon = (request: ComparableSalesRequest): Observable<ComparableSalesResponse> => {
    const url = baseUrl + '/comparableSales/polygon?sort=area&direction=asc';
    const payload = JSON.stringify(request);
    try {
      this.lastComparableSearchRequest = <ComparableSalesRequest>request;
    } catch (ex) {
      this.loggerService.logError('could not register comparable sale request');
    }

    return this.https.post<ComparableSalesResponse>(url, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).pipe(
      map(response => {
        const result = new ComparableSalesResponse(response);
        this.updateCounter(result.amountLeft);
        return result;
      }),
      catchError((err) => {
        this.loggerService.logError('error getting comparables sales by polygon', err);
        return throwError(err);
      })
    );
  }

  getComparableSalesByMunicipality = (request: ComparableSalesRequest): Observable<ComparableSalesResponse> => {
    const url = baseUrl + '/comparableSales/municipality?sort=area&direction=asc';
    const payload = JSON.stringify(request);
    try {
      this.lastComparableSearchRequest = <ComparableSalesRequest>request;
    } catch (ex) {
      this.loggerService.logError('could not register comparable sale request');
    }

    return this.https.post<ComparableSalesResponse>(url, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).pipe(
      map(response => {
        const result = new ComparableSalesResponse(response);
        this.updateCounter(result.amountLeft);
        return result;
      }),
      catchError((err) => {
        this.loggerService.logError('error getting comparables sales by municipality', err);
        return throwError(err);
      })
    );
  }

  getComparableSalesWithinSameCondo = (request: ComparableSalesRequest): Observable<ComparableSalesResponse> => {
    const url = baseUrl + '/comparableSales/condoByLatLong?sort=area&direction=asc';
    const payload = JSON.stringify(request);
    try {
      this.lastComparableSearchRequest = <ComparableSalesRequest>request;
    } catch (ex) {
      this.loggerService.logError('could not register comparable sale request');
    }

    return this.https.post<ComparableSalesResponse>(url, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).pipe(
      map(response => {
        const result = new ComparableSalesResponse(response);
        this.updateCounter(result.amountLeft);
        return result;
      }),
      catchError((err) => {
        this.loggerService.logError('error getting comparables sales within same condo', err);
        return throwError(err);
      })
    );
  }

  getPropertyCodesByLro = (lro: string): Observable<any> => {
    const url = `${baseUrl}/comparableSales/md/${lro}`;

    return this.https.get(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).pipe(
      map(response => {
        if (!_.isEmpty(response)) {
          return response;
        } else {
          return {};
        }
      }),
      catchError((err) => {
        this.loggerService.logError('error getting property codes by lro', err);
        return throwError(err);
      })
    );
  }

  async showLicensePopup(count: number) {
    // show license popup
    this.gaService.openModal("ComparableSearchLowBalance");
    const msg = count > 0 ?
      [DataService.COMPARABLE_SEARCH_LOW_BALANCE_MESSAGE] :
      [DataService.COMPARABLE_SEARCH_NO_BALANCE_MESSAGE];
    const dialogRef = this.dialog.open(ConfirmDialogComponent,
      {
        data: new ConfirmDialogData(DataService.COMPARABLE_SEARCH_LOW_BALANCE_HEADER,
          msg,
          DataService.COMPARABLE_SEARCH_LOW_BALANCE_BUY_BUTTON,
          DataService.COMPARABLE_SEARCH_LOW_BALANCE_REMINDER_BUTTON, true)
      })
      .afterClosed()
      .subscribe((resp) => {
          if (resp == DialogReturnTypeEnum.FIRST_BUTTON) {
            this.urlService.goToCataloguePage();
          } else {
            this.loggerService.logDebug('User clicked on cancel button in license popup.');
          }
        }
      );
  }
}
