import { Component, EventEmitter, inject, OnInit, Output, signal, ViewChild, WritableSignal } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SearchComparablesResultService } from '../../../shared/service/search/search-comparables-result.service';
import { ComparableSale } from '../../../core/model/comparables/comparable-sales-response';
import { lastValueFrom, takeUntil } from "rxjs";
import { BaseUnsubscribe } from '../../../core/component/base-unsubscribe/base-unsubscribe';
import { MainMapService } from '../main-map/main-map.service';
import { ComparableSalesResultPayload } from '../../../core/model/comparables/comparable-sales-result-payload';
import { SearchComparablesFormService } from '../../../shared/service/search/search-comparables-form.service';
import { ScreenManager } from '../../../shared/service/screen-manager.service';
import { ScreenNameEnum } from '../../../core/enum/screen-name.enum';
import { MatSort, SortDirection } from '@angular/material/sort';
import { PropertyReportService } from '../../../shared/service/property-report.service';
import { DataService } from '../../../shared/service/data.service';
import { comparablesLotSizeValues, defaultMatSnackBarConfig } from "../../../shared/constant/constants";
import { MatSnackBar } from '@angular/material/snack-bar';
import { ScreenOrientation } from '../../../core/enum/screen-orientation-enum';
import { MeasurementUnitService } from "../../../shared/service/measurement-unit.service";
import { MatRadioChange } from '@angular/material/radio';
import { SearchComparablesResultPriceChartComponent } from "../search-comparables-result-price-chart/search-comparables-result-price-chart.component";
import { SearchComparablesCriteriaCrumb } from '../../../core/model/comparables/search-comparables-criteria-crumb';
import { SearchComparablesResultSnapshot } from '../../../core/model/search-result/comparables-result-snapshot';
import { LoggerService } from '../../../shared/service/log/logger.service';
import { faCircleInfo, faCircleXmark, faDownLeftAndUpRightToCenter, faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import { SearchComparablesEnum } from "../../../core/enum/search-comparables.enum";
import { ScreenDisplay } from '../../../core/enum/screen-display-enum';
import { SearchComparablesFormWrapper } from '../../../core/model/comparables/search-comparables-form-wrapper';
import { PIIService } from '../../../shared/service/pii.service';
import { UserService } from '../../../shared/service/user.service';
import { PropertyReportSearchService } from "../../../shared/service/search/property-report-search.service";
import { SnackBarService } from "../../../shared/service/snack-bar.service";
import { MatDialog } from "@angular/material/dialog";
import { ComparablesReportSelectReport } from "../../../core/component/modal/comparables-report-select-report/comparables-report-select-report";
import { WarningService } from "../../../shared/service/warning.service";
import { WarningDialogData } from "../../../core/component/modal/warning-dialog/warning-dialog-data";
import _ from "lodash";
import { ComparablesReportAdd } from "../../../core/component/modal/comparables-report-add/comparables-report-add";
import { ComparableSalesReportCreationParam } from "../../../core/model/comparables/report/comparable-sales-report-creation-param";
import { ComparableSalesReportCreationRequest } from "../../../core/model/comparables/report/comparable-sales-report-creation-request";
import { PinOrArn } from "../../../core/model/property/pin-or-arn";
import { ErrorUtil } from "../../../shared/service/error.util";
import { PropertyDetail } from "../../../core/model/property/property-detail";
import { ComparablesReportService } from "../../../shared/service/comparables-report.service";
import { ComparableSalesReportResponse } from "../../../core/model/comparables/report/comparable-sales-report-select-report-from-modal";
import { Router } from "@angular/router";
import { UserAccessControl } from "../../../core/model/user/user-access-control";
import { SortColumn } from '../../../core/model/search-result/sort-column';

type PayloadType = "regular" | "snapshot";
declare function scrollToElementById0(elementId: string): void;
declare var $: any;

@Component({
  selector: 'gema3g-search-comparables-result',
  templateUrl: './search-comparables-result.component.html',
  styleUrls: ['./search-comparables-result.component.scss']
})
export class SearchComparablesResultComponent extends BaseUnsubscribe implements OnInit {

  constructor() {
    super();
  }

  protected propertyReportSearchService: PropertyReportSearchService = inject(PropertyReportSearchService);
  private loggerService = inject(LoggerService);
  private searchComparablesResultService = inject(SearchComparablesResultService);
  private searchComparablesFormService = inject(SearchComparablesFormService);
  private mainMapService = inject(MainMapService);
  private screenManager = inject(ScreenManager);
  private propertyReportService = inject(PropertyReportService);
  private _snackBar = inject(MatSnackBar);
  private dataService = inject(DataService);
  private piiService = inject(PIIService);
  private userService = inject(UserService);
  private measurementUnitService = inject(MeasurementUnitService);

  @Output() scrollToTop = new EventEmitter();
  @ViewChild(SearchComparablesResultPriceChartComponent) chart: SearchComparablesResultPriceChartComponent;
  searchResultsPayload: ComparableSalesResultPayload;
  searchResultsCount: number = 0;
  searchedBy: SearchComparablesEnum = SearchComparablesEnum.SEARCH_BY_RADIUS;
  searchResultsCountMaxAllowed: number = DataService.SEARCH_COMPARABLES_MAXIMUM_SALES_ALLOWED
  displayedColumns: string[];
  dataSource = new MatTableDataSource<ComparableSale>();
  sort: MatSort;
  orientation = ScreenOrientation;
  screenOrientation: ScreenOrientation = ScreenOrientation.HORIZONTAL;
  flipResultsVertically: string = DataService.SEARCH_COMPARABLES_RESULT_ORIENTATION_VERTICAL_TOOLTIP_TEXT;
  flipResultsHorizontally: string = DataService.SEARCH_COMPARABLES_RESULT_ORIENTATION_HORIZONTAL_TOOLTIP_TEXT;
  maximizeDisplay: string = DataService.SEARCH_COMPARABLES_RESULT_ORIENTATION_MAXIMIZE_TOOLTIP_TEXT;
  minimizeDisplay: string = DataService.SEARCH_COMPARABLES_RESULT_ORIENTATION_MINIMIZE_TOOLTIP_TEXT;
  resultsCountTooltipText: string = DataService.SEARCH_COMPARABLES_MAXIMUM_SALES_DISPLAYED;
  openPropertyReportTooltipText: string = DataService.SEARCH_COMPARABLES_RESULT_OPEN_PROPERTY_REPORT_TOOLTIP_TEXT;
  display = ScreenDisplay;
  screenDisplay: ScreenDisplay = ScreenDisplay.NORMAL;
  normalizeDisplay: string = this.minimizeDisplay;  //for now, minimized size is the normal size
  chartTabIndex: number = 0;
  listTabIndex: number = 1;
  activeTabIndex = this.listTabIndex;
  priceChartInput: any = {};
  ANA: string = DataService.ADDRESS_NOT_AVAILABLE;
  NA: string = DataService.NOT_APPLICABLE;
  propertyCodeTooltipDescription: string;
  selectedDisplay: string = 'List';
  displayOptions: string[] = ['Chart', 'List'];
  isMpsRequest: boolean;
  searchCriteriaCrumbs: SearchComparablesCriteriaCrumb[] = [];
  snapshot: SearchComparablesResultSnapshot;
  payloadType: PayloadType;
  faCircleXmark = faCircleXmark;
  faCircleInfo = faCircleInfo;
  faMinimize = faDownLeftAndUpRightToCenter;
  faMaximize = faUpRightAndDownLeftFromCenter;
  lotSizeHeader: WritableSignal<string> = signal('');
  lotSizePriceHeader: WritableSignal<string> = signal('');
  userAccessControls: UserAccessControl;

  snackBarService = inject(SnackBarService);
  dialog = inject(MatDialog);
  warningService = inject(WarningService);
  comparableSaleReportService = inject(ComparablesReportService);
  router = inject(Router);

  //This setter will fire once matSort in the view changes.
  //I.e. when it is defined the first time. It will not fire when you change the sorting by clicking on the arrows.
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.sort = sort;
  }

  setDataSort = (columnName: string, sortDirection: SortDirection, payloadType: PayloadType) => {
    this.dataSource.sort = this.sort;

    if (this.sort) {
      this.sort.sort({
        id: columnName,
        start: sortDirection,
        disableClear: true
      });
      this.sort.disableClear = true;
      this.loggerService.logDebug(`sorting column ${this.sort.active} ${this.sort.direction} from ${payloadType} payload`);
    }

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'registrationDate':
          return new Date(item.registrationDate).getTime();
        default: // @ts-ignore
          return item[property];
      }
    }
  }

  initializeSalesTableColumns = () => {
    this.displayedColumns = [];
    this.displayedColumns.push('selected');
    this.displayedColumns.push('saleAddressStr');
    this.displayedColumns.push('registrationDate');
    this.displayedColumns.push('considerationAmount');
    this.displayedColumns.push('area');
    this.displayedColumns.push('pricePerArea');
    this.displayedColumns.push('distance');
    this.displayedColumns.push('pin');
    if (this.isMpsRequest) {
      this.displayedColumns.push('yearBuilt');
      this.displayedColumns.push('propertyCode');
      this.displayedColumns.push('rollNumber');
    }
  }

  get useImperial(): boolean {
    return (!this.measurementUnitService.isUomInMeters);
  }

  get useAcres(): boolean {
    return (this.useImperial) && this.searchResultsPayload?.request?.usesAcres;
  }

  populateSalesTable = () => {
    if (this.useImperial) {
      if (this.useAcres) {
        this.lotSizeHeader.set('Lot Size (acres)');
        this.lotSizePriceHeader.set('$/ac');
      } else {
        this.lotSizeHeader.set('Lot Size (ft²)');
        this.lotSizePriceHeader.set('$/ft²');
      }
    } else {
      this.lotSizeHeader.set('Lot Size (m²)');
      this.lotSizePriceHeader.set('$/m²');
    }

    this.searchResultsPayload?.response?.computePricePerArea(this.measurementUnitService.rateSquareMetersToSquareFeet,
      this.measurementUnitService.rate_squareMetersToAcres,
      this.useImperial, this.useAcres);
    this.dataSource = new MatTableDataSource(this.searchResultsPayload?.response?.sales);
  }

  modifySearchCriteria = () => {
    this.screenManager.showScreen(ScreenNameEnum.SEARCH_COMPARABLES_FORM);
  }

  onRowMouseOver = (sale: ComparableSale, uniqueRowId: string) => {
    if (this.isMpsRequest) {
      if (this.dataService.propertyCodes != null) {
        let propertyCode: any = this.dataService.propertyCodes.find((propertyCode: any) => {
          return propertyCode.code == sale.propertyCode;
        })
        this.propertyCodeTooltipDescription = propertyCode.description;
      } else {
        this.propertyCodeTooltipDescription = sale.propertyCode!;
      }
    }

    setTimeout(() => {
      this.mainMapService.highlightSearchComparableMarker(this.getSaleMarkerPin(sale), uniqueRowId);
    }, 100);
  }

  onRowMouseOut = (sale: ComparableSale) => {
    setTimeout(() => {
      this.mainMapService.unhighlightSearchComparableMarker(this.getSaleMarkerPin(sale));
    }, 100);
  }

  getSaleMarkerPin = (sale: ComparableSale) => {
    let pin: string = '';
    if (sale.condo == 'Y') {
      pin = this.piiService.getCondoBlockPin(sale.pin);
    } else {
      pin = sale.pin;
    }

    return pin;
  }

  goBack() {
    this.scrollToTop.emit();
  }

  closeResultsScreen = () => {
    this.mainMapService.clearAllRenderedMapObjects();
    this.screenManager.hideScreen(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS);
  }

  onDisplayOptionChanged = (event: MatRadioChange) => {
    switch (event.value) {
      case 'Chart':
        this.chart?.updatePriceChart();
        break;
      case 'List':
    }
  }

  adjustMapBounds = (formWrapper: SearchComparablesFormWrapper | undefined) => {
    try {
      if (formWrapper) {
        switch (formWrapper.selectedSearchBy) {
          case SearchComparablesEnum.SEARCH_BY_RADIUS:
            this.loggerService.logDebug(`adjusting search circle map bounds for screen orientation ${this.screenOrientation}`);
            if (formWrapper.sameCondoSearch) {
              setTimeout(() => {
                this.mainMapService.setMapCenter(formWrapper.sameCondoLocation);
                this.mainMapService.setMapZoomLevel(DataService.SEARCH_COMPARABLES_SINGLE_SEARCH_RESULT_DEFAULT_ZOOM_LEVEL);
              }, 100);
            } else {
              this.mainMapService.getMap().fitBounds(formWrapper.searchShapeBounds.searchCircleBounds);
            }
            break;

          case SearchComparablesEnum.SEARCH_BY_POLYGON:
            this.loggerService.logDebug(`adjusting search polygon map bounds for screen orientation ${this.screenOrientation}`);
            this.mainMapService.getMap().fitBounds(formWrapper.searchShapeBounds.searchPolygonBounds);
            break;

          case SearchComparablesEnum.SEARCH_BY_MUNICIPALITY:
            this.loggerService.logDebug(`adjusting search municipality map bounds for screen orientation ${this.screenOrientation}`);
            this.mainMapService.getMap().fitBounds(formWrapper.searchShapeBounds.searchMunicipalityBounds);
            break;
        }
      }

    } catch (e) {
      this.loggerService.logError(`error adjusting search results map bounds in current payload type ${this.payloadType}`, e);
    }
  }

  openPropertyReport = async (pin: string) => {
    if (pin) {
      //save a snapshot of the current results so the user can get back to it from the property report
      this.snapshot.pin = pin;
      this.snapshot.sortColumn = new SortColumn(this.sort.active, this.sort.direction); //save the current sort state
      this.snapshot.saveSelectedProperties();
      this.snapshot.ready = false;

      this.loggerService.logDebug(`sorted column ${this.snapshot.sortColumn.columnName} ${this.snapshot.sortColumn.sortOrder}`);

      if (!this.snapshot.originalSubjectProperty) {
        this.snapshot.originalSubjectProperty = this.propertyReportService.getSubjectProperty();
      }
      this.searchComparablesResultService.updateSearchResultsSnapshot(this.snapshot);
      this.loggerService.logDebug(`saved search comparables snapshot initiated by pin ${this.snapshot.pin} with original pin ${this.snapshot.originalSubjectProperty?.pii?.pin}`);

      this.propertyReportService.showPropertyReportByPinDeferData(pin);
    }
  }

  get wasLastSearchByMunicipality(): boolean {
    return this.searchedBy === SearchComparablesEnum.SEARCH_BY_MUNICIPALITY;
  }

  ngOnInit(): void {

    this.userAccessControls = this.userService.getUserAccessControl();

    //display results from the most recent search (regular payload sent from the search comparables form)
    this.searchComparablesResultService.getSearchResultsPayloadObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((payload) => {
        if (payload && !this.searchComparablesResultService.isSearchResultsReadySnapshotExists()) {
          if (!payload.isEmpty()) {
            this.isMpsRequest = payload.request.mps;
            this.searchCriteriaCrumbs = payload.formWrapper.searchCriteriaCrumbs;
            this.initializeSalesTableColumns();
            this.searchResultsPayload = payload;
            this.searchedBy = payload?.formWrapper?.selectedSearchBy;
            this.searchResultsCount = this.searchResultsPayload?.response?.sales?.length;
            //ToDo remove this if we change ComparableSaleResponse to inherit from BaseModel
            this.searchResultsPayload?.response?.sales?.forEach(value => value.selected = true);

            this.searchResultsPayload.request.usesAcres = comparablesLotSizeValues
              .some(lotSize => lotSize.value == this.searchResultsPayload.request.minArea && lotSize.imperialDisplayValue.includes('acre'));

            if (this.searchResultsCount >= DataService.SEARCH_COMPARABLES_MAXIMUM_SALES_ALLOWED) {
              this._snackBar.open(DataService.SEARCH_COMPARABLES_TOP_MAXIMUM_SALES_DISPLAYED, 'Close', defaultMatSnackBarConfig);
            }

            //initialize for the next snapshot which is initiated when a pin is selected
            this.snapshot = new SearchComparablesResultSnapshot();
            this.snapshot.results = payload;

            this.payloadType = 'regular';

            setTimeout(() => {
              this.populateSalesTable();
              this.setDataSort('saleAddressStr', 'asc', this.payloadType);
            }, 100);

            setTimeout(async () => {
              await this.mainMapService.renderComparableSalesMarkers(this.searchResultsPayload?.response?.sales, new google.maps.LatLng(payload.request.searchCenter.latitude, payload.request.searchCenter.longitude));
              this.searchComparablesResultService.adjustSearchResultsMapBounds(payload.formWrapper);
            }, 100);

          } else {
            this.loggerService.logDebug('no search comparables result to display');
            this.searchResultsCount = 0;
          }
        }
      });

    //display results from the last results snapshot (triggered by selecting a pin from the results)
    this.searchComparablesResultService.getSearchResultsSnapshotObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((snapshot) => {
        if (snapshot && snapshot.ready) {
          this.loggerService.logDebug(`restoring the search comparables result from snapshot initiated by pin ${snapshot.pin}`);

          let results: ComparableSalesResultPayload | null = snapshot.results;
          if (results) {
            this.isMpsRequest = results.request.mps;
            this.searchCriteriaCrumbs = results.formWrapper.searchCriteriaCrumbs;
            this.initializeSalesTableColumns();
            this.searchResultsPayload = results;
            this.searchResultsCount = this.searchResultsPayload?.response?.sales?.length;

            if (this.searchResultsCount >= DataService.SEARCH_COMPARABLES_MAXIMUM_SALES_ALLOWED) {
              this._snackBar.open(DataService.SEARCH_COMPARABLES_TOP_MAXIMUM_SALES_DISPLAYED, 'Close', defaultMatSnackBarConfig);
            }

            //initialize for the next snapshot which is initiated when a pin is selected
            this.snapshot = new SearchComparablesResultSnapshot();
            this.snapshot.results = results;
            this.snapshot.originalSubjectProperty = snapshot.originalSubjectProperty;

            this.payloadType = 'snapshot';

            setTimeout(() => {
              this.populateSalesTable();
              this.setDataSort(snapshot.sortColumn.columnName, snapshot.sortColumn.sortOrder, 'snapshot');
              snapshot.reselectProperties();
            }, 100);

            setTimeout(async () => {
              await this.mainMapService.renderComparableSalesMarkers(this.searchResultsPayload?.response?.sales, new google.maps.LatLng(snapshot.results.request.searchCenter.latitude, snapshot.results.request.searchCenter.longitude));
              this.searchComparablesResultService.adjustSearchResultsMapBounds(snapshot.results.formWrapper);
            }, 100);

            this.searchComparablesFormService.markSnapshotFormRecoveryAsStarted(snapshot);

          } else {
            this.loggerService.logDebug('no search comparables result to display from snapshot');
            //this._snackBar.open(DataService.SEARCH_COMPARABLES_RESULT_EMPTY, 'Close', defaultErrorMatSnackBarConfig);
          }

          //clear any previous snapshots
          this.loggerService.logDebug('clearing previous search comparables result snapshot');
          this.searchComparablesResultService.clearSearchResultsSnapshot();

        } else {
          this.loggerService.logDebug('no search comparables result to display from snapshot');
          // this._snackBar.open(DataService.SEARCH_COMPARABLES_RESULT_EMPTY, 'Close', defaultErrorMatSnackBarConfig);
        }
      });

    this.searchComparablesResultService.screenOrientation$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(orientation => {
        if (this.screenManager.isScreenVisible(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS)) {
          switch (orientation) {
            case null:
              this.screenOrientation = ScreenOrientation.HORIZONTAL;  //set default orientation
              break;

            case ScreenOrientation.HORIZONTAL:
              this.screenOrientation = ScreenOrientation.HORIZONTAL;
              break;

            case ScreenOrientation.VERTICAL:
              this.screenOrientation = ScreenOrientation.VERTICAL;
              break;
          }

          if (this.searchResultsPayload?.formWrapper.searchShapeBounds) {
            setTimeout(() => {
              this.searchComparablesResultService.adjustSearchResultsMapBounds(this.searchResultsPayload.formWrapper);
            }, 100);
          }
        }
      });

    this.searchComparablesResultService.screenDisplay$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(display => {
        if (this.screenManager.isScreenVisible(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS)) {
          switch (display) {
            case null:
              this.screenDisplay = ScreenDisplay.NORMAL;  //set default size
              break;

            case ScreenDisplay.NORMAL:
              this.screenDisplay = ScreenDisplay.NORMAL;
              break;

            case ScreenDisplay.MAXIMIZED:
              this.screenDisplay = ScreenDisplay.MAXIMIZED;
              break;
          }
        }
      });

    this.searchComparablesResultService.getSearchResultsMapBoundsObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(formWrapper => {
        if (formWrapper) {
          this.adjustMapBounds(formWrapper);
        } else {
          //find out if there is a regular payload or a snapshot payload
          if (this.payloadType == 'regular' || this.payloadType == 'snapshot') {
            this.adjustMapBounds(this.searchComparablesResultService.getSearchResultsPayloadValue()?.formWrapper);
          }
        }
      });

      this.mainMapService.markerMouseOverEvent$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(marker => {
          if (marker && !marker.get('isMouseOverRequestFromSearchResults') && this.screenManager.isScreenVisible(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS)) {
            marker.set('isMouseOverRequestFromSearchResults', false);  //reset
            if (marker.get('uniqueSearchResultRowId')) {
              scrollToElementById0(marker.get('uniqueSearchResultRowId'));
            }
          }
        });
  }

  toggleScreenSize = () => {
    switch (this.screenDisplay) {
      case ScreenDisplay.MINIMIZED:
        //not implemented at this time
        break;

      case ScreenDisplay.MAXIMIZED:
        this.searchComparablesResultService.changeScreenDisplay(ScreenDisplay.NORMAL);
        break;

      case ScreenDisplay.NORMAL:
        this.searchComparablesResultService.changeScreenDisplay(ScreenDisplay.MAXIMIZED);
        break;
    }
  }

  toggleScreenOrientation = () => {
    switch (this.screenOrientation) {
      case ScreenOrientation.HORIZONTAL:
        this.searchComparablesResultService.changeScreenOrientation(ScreenOrientation.VERTICAL);
        break;

      case ScreenOrientation.VERTICAL:
        this.searchComparablesResultService.changeScreenOrientation(ScreenOrientation.HORIZONTAL);
        break;
    }
  }

  get containerClassName() {
    let className: string = 'flip-horizontal-normal'; //default

    if (this.screenOrientation == ScreenOrientation.HORIZONTAL && this.screenDisplay == ScreenDisplay.NORMAL) {
      className = 'flip-horizontal-normal';
    }

    if (this.screenOrientation == ScreenOrientation.HORIZONTAL && this.screenDisplay == ScreenDisplay.MAXIMIZED) {
      className = 'maximized';
    }

    if (this.screenOrientation == ScreenOrientation.VERTICAL && this.screenDisplay == ScreenDisplay.NORMAL) {
      className = 'flip-vertical-normal';
    }

    if (this.screenOrientation == ScreenOrientation.VERTICAL && this.screenDisplay == ScreenDisplay.MAXIMIZED) {
      className = 'maximized';
    }

    return className;
  }

  printPDFReports = async () => {
    // after a return from opening a property from compSearch result the snapshot is not set to ready and the pin is not set, but the property details will have the value of the original pin the search was performed for
    const propertyDetail = !this.snapshot.ready && !this.snapshot.pin && this.snapshot.originalSubjectProperty ? this.snapshot.originalSubjectProperty : undefined;
    await this.propertyReportService.printPDFReports(true, this.searchResultsPayload, propertyDetail);
  }

  areAllSelected() {
    return this.dataSource.data.every(value => value.selected);
  }

  areSomeSelected() {
    return this.dataSource.data.some(value => value.selected);
  }

  areNoneSelected() {
    return this.dataSource.data.every(value => !value.selected);
  }

  toggleAll() {
    let newSelection = !this.areAllSelected();
    this.dataSource.data.forEach(value => value.selected = newSelection);

    if (this.areAllSelected()) {
      setTimeout(() => {
        this.mainMapService.showSearchComparableMarkers();
      }, 100);
    }

    if (this.areNoneSelected()) {
      setTimeout(() => {
        this.mainMapService.hideSearchComparableMarkers();
      }, 100);
    }
  }

  toggle(row: ComparableSale) {
    row.selected = !row.selected;

    setTimeout(() => {
      this.mainMapService.toggleSearchComparableMarker(this.getSaleMarkerPin(row), row.selected);
    }, 100);
  }

  get propertyReportAccess() {
    return this.userService.getUserAccessControl().propertyReportAccess;
  }

  async addToReport() {
    if (this.searchResultsPayload.response.sales.filter(sale => sale.selected).length > 20) {
      this.snackBarService.displaySnackBarError('Maximum allowable properties in a report is 20.');

    } else {
      const subjectProperty = this.propertyReportService.getSubjectProperty();
      if (!subjectProperty || subjectProperty.isEmpty) {
        const content = [DataService.MISSING_SUBJECT_PROPERTY];
        const dialogData = new WarningDialogData(DataService.MISSING_SUBJECT_PROPERTY_HEADER, content, '', 'Ok');
        this.warningService.showWarning(dialogData, false, 560);
      } else {
        const dialogRef = await this.dialog.open(ComparablesReportSelectReport, {data: subjectProperty})
          .afterClosed()
          .subscribe(async (reportSelection: ComparableSalesReportResponse) => {
            if (_.isNumber(reportSelection?.reportId)) {
              if (reportSelection?.reportId == -1) {
                // new report requested
                this.createNewReport(subjectProperty, reportSelection.openReport);
              } else {
                this.addToExistingReport(reportSelection.openReport, reportSelection.reportId);
              }
            }
          });
      }
    }
  }

  async createNewReport(subjectProperty: PropertyDetail, openReport: boolean) {
    // create new report
    const dialogRef = await this.dialog.open(ComparablesReportAdd,)
      .afterClosed()
      .subscribe(async (param: ComparableSalesReportCreationParam) => {
          if (param && param.reportName) {
            const reportParam: ComparableSalesReportCreationRequest = new ComparableSalesReportCreationRequest();
            reportParam.reportParam = new ComparableSalesReportCreationParam(param);
            reportParam.subjectPin = new PinOrArn(subjectProperty?.pii?.pin, subjectProperty?.pii?.arn);
            reportParam.comparablePins = this.searchResultsPayload.response.sales.filter(sale => sale.selected).map(sale => new PinOrArn(sale.pin, sale.arn));
            const reportCreated = await lastValueFrom(this.comparableSaleReportService.createReport(reportParam));
            if (reportCreated?.businessError == 0) {
              if (reportCreated.reportId && openReport) {
                this.router.navigate(["/comparables-report"], {
                  queryParams: {
                    reportId: reportCreated.reportId
                  }
                });
              } else {
                this.router.navigate(["/comparables-report"]);
              }
            } else {
              this.snackBarService.displaySnackBarError(ErrorUtil.ERROR_ON_CREATING_REPORT);
            }
          }
        }
      );
  }

  async addToExistingReport(openReport: boolean, reportId: number) {
    const newPins = this.searchResultsPayload.response.sales.filter(sale => sale.selected).map(sale => new PinOrArn(sale.pin, sale.arn))
    const reportUpdated = await lastValueFrom(this.comparableSaleReportService.addPinsToReport(reportId, newPins));
    if (reportUpdated?.businessError == 0) {
      if (openReport) {
        this.router.navigate(["/comparables-report"], {
          queryParams: {
            reportId: reportId
          }
        });
      } else {
        this.router.navigate(["/comparables-report"]);
      }
    } else {
      this.snackBarService.displaySnackBarError(ErrorUtil.ERROR_ON_AADDING_PINS_TO_REPORT);
    }
  }
}
