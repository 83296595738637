<div #parcelMapContainer class="parcel-map-container" id="parcel-map-container-id"></div>
<div class="map-top-right-controls" id="parcel-map-top-right-controls">
  <div id="top-right-control-zoom-in" class="zoom-in-black" (click)="zoomIn()">
    <img src="/assets/img/svg/icons/icon_map_zoom_plus_white.svg"/>
  </div>
  <div id="top-right-control-zoom-out" class="zoom-out-black" (click)="zoomOut()">
    <img src="/assets/img/svg/icons/icon_map_zoom_minus_white.svg"/>
  </div>
  <div id="parcel-top-right-control-full-map" class="enter-full-map-black" (click)="enterFullMap()"></div>
  <div id="parcel-top-right-control-exit-map" class="exit-full-map-black" (click)="exitFullMap()"></div>
</div>
