import {Component, Input} from '@angular/core';
import {Assessment} from "../../../../../core/model/assessment/assessment";
import {UserAccessControl} from "../../../../../core/model/user/user-access-control";
import {PropertyReportComponentBase} from "../../property-report-component-base";
import {DataService} from "../../../../../shared/service/data.service";
import {MeasurementUnitService} from "../../../../../shared/service/measurement-unit.service";
import {MetersToFeetWithUomPipe} from "../../../../../shared/pipe/meters-to-feet-with-uom.pipe";
import {MetersToMilesWithUomPipe} from "../../../../../shared/pipe/meters-to-miles-with-uom.pipe";
import {PropertyZoning} from "../../../../../core/model/property/property-detail";

@Component({
  selector: 'gema3g-site-structure-site',
  templateUrl: './site-structure-site.component.html',
  styleUrls: ['./site-structure-site.component.scss'],
  providers: [MetersToFeetWithUomPipe, MetersToMilesWithUomPipe]
})
export class SiteStructureSiteComponent extends PropertyReportComponentBase{
  @Input() assessmentIndex: number = 1;
  @Input() assessment: Assessment = new Assessment();
  @Input() userAccessControls: UserAccessControl;
  assessmentMetersFeetThreshold: number = DataService.ASSESSMENT_METERS_THRESHOLD;
  NA: string = DataService.NOT_APPLICABLE;

  constructor(private measurementUnitService: MeasurementUnitService,
              private metersToFeetWithUom: MetersToFeetWithUomPipe,
              private metersToMilesWithUom: MetersToMilesWithUomPipe) {
    super();
    this.assessmentMetersFeetThreshold = measurementUnitService.meterfeetThreshold;
  }


  get frontage(): string{
    const frontageNumber = (this.userAccessControls.MPACSubscriptionEnabled) ?
       parseFloat(this.assessment?.mpacAssessment?.property?.frontage):
      this.assessment?.baseInfo?.frontage;

    if (frontageNumber !== null && frontageNumber != 0) {

      if (!isNaN(frontageNumber)) {
        if (frontageNumber < this.assessmentMetersFeetThreshold) {
          return this.metersToFeetWithUom.transform(frontageNumber, 2);
        }
        return this.metersToMilesWithUom.transform(frontageNumber, 2);
      }
    }
    return this.NA;
  }

  get depth(): string {
    if (this.assessment?.baseInfo?.depth && this.assessment.baseInfo.depth < this.assessmentMetersFeetThreshold && this.assessment.baseInfo.depth != 0){
      return this.metersToFeetWithUom.transform(this.assessment.baseInfo.depth, 2);
    }
    if (this.assessment?.baseInfo?.depth == null || this.assessment?.baseInfo?.depth == 0){
      return this.NA;
    }
    return '';
  }

  getZoningList(propertyZoning: PropertyZoning[]): string {
    return propertyZoning.map(zoningEntry => zoningEntry.zoning).join(", ");
  }
}
