import { Component } from '@angular/core';

@Component({
  selector: 'gema3g-store-front',
  templateUrl: './store-front.component.html',
  styleUrls: ['./store-front.component.scss']
})
export class StoreFrontComponent {

}
