<div class="gema3g-ao-container">
  <div class="header pl-3 pr-3">
    <ul>
      <li class="active">
        <a id="dl-link-purchases">eStore Purchases</a>
      </li>
    </ul>
    <div class="absolute sm:relative sm:ml-auto">
      <input (change)="choosedDate($event)"
        [(ngModel)]="selectedRange"
        [alwaysShowCalendars]="true"
        [autoApply]=true
        [closeOnAutoApply]=true
        [keepCalendarOpeningWithRange]=true
        [locale]="{displayFormat: 'MMM,DD YYYY', separator: ' To '}"
        [opens]="'left'"
        [ranges]="customRanges"
        [showRangeLabelOnInput]=false
        class="date-range-picker-box"
        ngxDaterangepickerMd
        readonly
        type="text"
        id="dl-choose-date"
        >
      </div>
    </div>
    <section>
      <div class="notes pl-3 pr-3">
        @if (isLoading) {
          <div id="dl-loading-msg">
            Loading...
          </div>
        }
        @if (length == 0 && !isLoading) {
          <div id="dl-no-purchases-msg">
            You have not purchased any downloadable product (report, instrument, plan etc.) between {{startDateFormatted}} - {{endDateFormatted}}, try changing the dates.
          </div>
        }
        @if (length>0 && !isLoading) {
          <div id="dl-loaded-msg" class="line-of-copy">
            Please note: if you are unable to see all of the reports for a period of time, the data set may be too large. If no data is displaying, please reduce your date range to ensure all information can be retrieved.
          </div>
        }
      </div>
    </section>
    <section class="table-section mt-9 col-0">
      @if (isLoading) {
        <mat-progress-bar id="dl-progress-bar" mode="indeterminate"></mat-progress-bar>
      }
      <table id="dl-tbl" mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort (matSortChange)="getDownloads('sort', $event)">

        <ng-container matColumnDef="date">
          <th *matHeaderCellDef mat-header-cell mat-sort-header="D" >
            <div id="dl-tbl-hdr-date"> Date</div>
          </th>
          <td id="dl-tbl-data-date-{{element?.pk?.fulfillmentId}}" *matCellDef="let element" mat-cell class="w-[10%]"> {{element.createdDate | date : 'mediumDate'}} </td>
        </ng-container>

        <ng-container matColumnDef="product">
          <th id="dl-tbl-hdr-product" *matHeaderCellDef mat-header-cell mat-sort-header="P"> Product</th>
          <td *matCellDef="let element" mat-cell class="w-[31%]">
            <span>
              @if (element.contentType == 'txt') {
                <a id="dl-tbl-link-product-txt-{{element?.pk?.fulfillmentId}}" class="anchor" href="{{element.contentFileName}}" target="_blank" title="{{element.productName}}">{{element.productName | uppercase}} </a>
              }
              @if (element.contentType != 'txt' && element.productCategoryName != 'PYBRpt') {
                <a id="dl-tbl-link-product-download-{{element?.pk?.fulfillmentId}}" (click)="downloadReport(element)" class="anchor">{{element.productName | uppercase}}</a>
              }
              @if (element.productCategoryName == 'PYBRpt') {
                <a id="dl-tbl-link-product-open-{{element?.pk?.fulfillmentId}}" (click)="openPYBReportStatus(element)" class="anchor" target="_blank">{{element.productName | uppercase}}</a>
              }
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="details">
          <th id="dl-tbl-hdr-details" *matHeaderCellDef mat-header-cell > Details</th>
          <td id="dl-tbl-data-details-{{element?.pk?.fulfillmentId}}" *matCellDef="let element" mat-cell class="w-[31%]"> {{element.cartDesc}} </td>
        </ng-container>

        <ng-container matColumnDef="transaction">
          <th id="dl-tbl-hdr-transaction" *matHeaderCellDef mat-header-cell mat-sort-header="T"> Transaction #</th>
          <td *matCellDef="let element" mat-cell class="w-[28%]">
            <span class="mr-[5px]">{{element?.transactionId}}</span>
            <span>
              @if (element.contentType == 'txt') {
                <a id="dl-tbl-link-transaction-view-{{element?.pk?.fulfillmentId}}" class="anchor left-transaction-link" href="{{element.contentFileName}}" target="_blank">View</a>
              }
              @if (element.contentType != 'txt' && element.productCategoryName != 'PYBRpt') {
                <a id="dl-tbl-link-transaction-download-{{element?.pk?.fulfillmentId}}" (click)="downloadReport(element)" class="anchor left-transaction-link">Download</a>
              }
              @if (element.productCategoryName == 'PYBRpt') {
                <a id="dl-tbl-link-transaction-viewstatus-{{element?.pk?.fulfillmentId}}" (click)="openPYBReportStatus(element)" class="anchor left-transaction-link" target="_blank">View Status</a>
              }
              <span class="separator">|</span>
              <a id="dl-tbl-link-transaction-invoice-{{element?.pk?.fulfillmentId}}" (click)="redirectToGWHInvoicePage(element?.transactionId)" class="anchor">Invoice</a>
            </span>
          </td>
        </ng-container>



        <tr id="dl-tbl-row-hdr" *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr id="dl-tbl-row-{{row?.pk?.fulfillmentId}}" *matRowDef="let row;
      let odd = odd;
      columns: displayedColumns;" mat-row></tr>
      </table>
      <mat-divider></mat-divider>
      <mat-paginator
        id="dl-paginator"
        [length]="length"
        [pageIndex]="pageIndex"
        [pageSizeOptions]="pageSizes"
        (page)="pageEvent = getDownloads('pagination', $event)"
        showFirstLastButtons
      ></mat-paginator>
    </section>
  </div>
