import {inject, Injectable} from "@angular/core";
import {MapTypeEnum} from "../../core/enum/map-type-enum";
import {UserService} from "./user.service";
import * as _ from "lodash";
import {FirstBaseSolutionTile} from "../../home/home/main-map/tiles/first-base-solution-tile";
import {MapTileEnum} from "../../core/enum/map-tile-enum";
import {FirstBaseSolutionService} from "./first-base-solution.service";

@Injectable({
  providedIn: 'root'
})
export class MapService {

  userService = inject(UserService);
  fbsService = inject(FirstBaseSolutionService);

  get tileProtocol(): string | undefined {
    //return "https";
    return this.userService.user?.tileServerHttpProtocol;
  }

  get tileServerUrl(): string | undefined {
    //return "t0.geowarehouse.ca"; // use this if you need to use PROD server
    return this.userService.user?.tileServerURL;
  }

  get tileAuthenticationKey(): string | undefined {
    return this.userService.user?.tileAuthKey;
  }

  setMapType = (gMap: google.maps.Map, type: MapTypeEnum) => {
    switch (type) {
      case MapTypeEnum.STANDARD:
        gMap.setMapTypeId(google.maps.MapTypeId.ROADMAP);
        break;
      case MapTypeEnum.AERIAL:
        gMap?.setMapTypeId(google.maps.MapTypeId.HYBRID);
        break;
      case MapTypeEnum.ENHANCED_AERIAL:
        gMap?.setMapTypeId(google.maps.MapTypeId.HYBRID);
        break;
      case MapTypeEnum.SATELLITE:
        gMap?.setMapTypeId(google.maps.MapTypeId.SATELLITE);
        break;
    }
  }

  applyFBSLayerVisibility(gMap: google.maps.Map, layerVisibility: boolean) {
    let currentMapZoomLevel: number = gMap?.getZoom()!;
    if (layerVisibility && _.inRange(currentMapZoomLevel, FirstBaseSolutionTile.MIN_ZOOM, FirstBaseSolutionTile.MAX_ZOOM + 1)) {
      gMap.overlayMapTypes.setAt(gMap.overlayMapTypes.getLength(), new FirstBaseSolutionTile(true, this.tileProtocol, this.tileServerUrl, this.tileAuthenticationKey, this.fbsService));
    } else {
      const overlayIndex = this.getLayerIndex(gMap, MapTileEnum.FIRST_BASE_SOLUTION_TILE);
      if (overlayIndex > -1){
        gMap.overlayMapTypes.removeAt(overlayIndex);
      }
    }
  }

  getLayerIndex(gMap: google.maps.Map, layerName: string | null){
    for (let i: number = 0; i < gMap.overlayMapTypes.getLength(); i++) {
      //let overlay: google.maps.MapType | null = this.gMap.overlayMapTypes.getAt(i);
      let overlay: any = gMap.overlayMapTypes.getAt(i);
      if (overlay?.name == layerName) {
        return i;
      }
    }
    return -1;
  }
}
