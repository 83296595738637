export enum AutoSuggestCurrentResultTypeEnum {
    
    //re-using this autosuggest enum to track recent selections
    RECENTLY_SEARCHED = 'S',
    RECENTLY_VIEWED = 'V',

    //enum for autosuggest search results
    OWNER = 'O',
    FREEHOLD = 'F',
    CONDO = "C",
    BLOCK = 'B',
    MUNICIPALITY = 'M',
    NOT_APPLICABLE = "N/A"
}