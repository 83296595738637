import {Component, Input} from '@angular/core';
import {PdfReportMyPropertyReportConfiguration} from "../../../../../model/pdf-report/input-param/pdf-report-my-property-report-configuration";
import {MatInputModule} from "@angular/material/input";
import {MaterialModule} from "../../../../../material.module";
import {SvgIconComponent} from "../../../../svg-icon/svg-icon.component";

@Component({
  selector: 'gema3g-generate-pdf-report-market-analytics-section',
  standalone: true,
  imports: [
    MatInputModule,
    MaterialModule,
    SvgIconComponent
  ],
  templateUrl: './generate-pdf-report-market-analytics-section.component.html',
  styleUrl: './generate-pdf-report-market-analytics-section.component.scss'
})
export class GeneratePdfReportMarketAnalyticsSectionComponent {
  @Input() inputParam: PdfReportMyPropertyReportConfiguration;
}
