<div class="demographics-header">
    <h4 id="dg-h4-cul">
      Cultural
    </h4>
</div>
<div class="flex flex-col md:flex-row py-9">
  <div class="flex md:pl-2 md:w-1/2 border-r-black border-r">
    <div class="flex flex-col w-full">
      <span id="dg-cul-dl-label" class="demographics-sub-header pb-9 lg:pb-9">Dominant Language</span>
      <div class="flex">
        <gema3g-dominant-language
          [cultural]="cultural"
          [metadata]="metadata"
          [selectedArea]="selectedArea"
          class="w-full"
        >
        </gema3g-dominant-language>
      </div>
    </div>
  </div>
  <div class="flex md:w-1/2 md:pl-2">
    <div class="flex flex-col w-full">
      <span id="dg-cul-is-label" class="demographics-sub-header pb-9 lg:pb-9">Immigration Status</span>
      <div class="flex">
        <gema3g-immigration-status
          [cultural]="cultural"
          [selectedArea]="selectedArea"
          class="w-full"
        >
        </gema3g-immigration-status>
      </div>
    </div>
  </div>
</div>
