import {Component, Input, SimpleChanges} from '@angular/core';
import {Assessment} from "../../../../../core/model/assessment/assessment";
import {UserAccessControl} from "../../../../../core/model/user/user-access-control";
import {MatTableDataSource} from "@angular/material/table";
import {BasicAssessment} from "../../../../../core/model/assessment/basic-assessment";
import {TaxEstimate} from "../../../../../core/model/assessment/mpac-assessment";

@Component({
  selector: 'gema3g-site-structure-residential-property-tax',
  templateUrl: './site-structure-residential-property-tax.component.html',
  styleUrls: ['./site-structure-residential-property-tax.component.scss']
})
export class SiteStructureResidentialPropertyTaxComponent {

  @Input() assessment: Assessment = new Assessment();
  @Input() userAccessControls: UserAccessControl;
  columns: string[] = ['taxYear', 'taxEstimate'];
  taxEstimateDataSource: MatTableDataSource<TaxEstimate>;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.userAccessControls.MPACSubscriptionPremiumOrEnhanced)  {
      if (Array.isArray(this.assessment?.mpacAssessment?.taxEstimates)) {
        this.taxEstimateDataSource = new MatTableDataSource<TaxEstimate>(this.assessment.mpacAssessment.taxEstimates);
      }
    }
  }

}
