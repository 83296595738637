export const environment = {
  text: 'UAT',
  isGoogleAnalyticsEnabled: true,
  isOktaClassicEnabled: false,
  logLevel: 'DEBUG',
  auth0: {
    DOMAIN: "auth-uat.geowarehouse.ca",
    CLIENT_ID: "HHUx2S8I813w6PeVDo0jQnroul5n3QMB",
    LOGIN_REDIRECT_URI: "https://collaboration-uat.geowarehouse.ca/ui/login",
    RESPONSE_TYPE: "token id_token",
    AUDIENCE: "https://collaboration-uat.geowarehouse.ca/",
    SCOPE: "openid profile email",
    LOGOUT_URL: "https://www2.geowarehouse.ca",
    ENV: "uat-ocp",
    ACCESS_TOKEN_EARLY_RENEW_BY: 120, // RENEW EARLY BY 2 MINUTES
  },
  url: {
    ORIGIN: "https://collaboration-uat.geowarehouse.ca", // set to same value as the proxy target for the same environment
    OVERVIEW_URL: "https://geowarehousstg.wpengine.com/academy/#",
    TRAINING_CALENDAR_URL: "https://geowarehousstg.wpengine.com/academy/training-calendar/",
    HOW_TO_LIBRARY_URL: "https://geowarehousstg.wpengine.com/academy/how-to-library",
    HELP_AND_SUPPORT_URL: "https://geowarehousstg.wpengine.com/resources/help/",
    CONTACT_US_URL: "https://geowarehousstg.wpengine.com/contact/",
    BECOME_INSIGHTER_URL: "https://www.teranetinsighters.ca/h/s/5n3fLDDOYRF9GGE8DI3sLj",
    TERMS_OF_USE_URL: "https://geowarehousstg.wpengine.com/terms-of-use/",
    PRIVACY_STATEMENT_URL: "https://geowarehousstg.wpengine.com/privacy-policy/",
    MARKETING_SITE: "https://www2.geowarehouse.ca/",

    SECURITY_STATEMENT: "https://www.teranet.ca/legal-notice/",
    ZENDESK: "https://teranetcommercialsolutions.zendesk.com/hc/en-ca/requests/new",

    E_STORE: "https://dev.geowarehouse.ca/gwhweb",
    MPAC_UNDERSTANDING_YOUR_ASSESSMENT: 'https://www.mpac.ca/en/UnderstandingYourAssessment/AssessmentCycle'
  }
}
