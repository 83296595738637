import { inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { DataService } from "./data.service";
import { LoggerService } from "./log/logger.service";

@Injectable({
    providedIn: 'root'
})
export class LocationService {
  
    constructor() {
    }

    private loggerService = inject(LoggerService);
    private dataService = inject(DataService);
    
    private _position = new BehaviorSubject<any>(null);
    position$: any = this._position.asObservable();

    getPosition() {
        this.loggerService.logInfo(`acquiring user's location`);
        window.navigator.geolocation.getCurrentPosition(position => {
            this.loggerService.logInfo(`acquired user's location ${position.coords}`);
            this._position.next(position);
        },
        error => {
            this.loggerService.logError("error acquiring user's location", error);
            this._position.next(DataService.USER_LOCATION_PROMPT_DENIED);
        });
    }

    clearPosition = () => {
        this._position.next(null);
    }
}