<div class="h-auto max-h-[130px] lg:mb-6 sticky top-0 left-0 bg-white">
  <div class="text-os-bold">
    Comparable Properties
  </div>
  <div class="h-[90px] hidden lg:block">
    <!--  image placeholder-->
  </div>
  <div>
    Record Current as of:
  </div>
</div>
<!--LAND REGISTRY INFORMATION-->
<section class="text-os-bold">
  <div class="row">
    LAND REGISTRY INFORMATION
  </div>
  <div class="row">
    PIN
  </div>
  <div class="row">
    Municipality
  </div>
  <div class="row">
    Legal Description
  </div>
  <div class="row">
    Area (m²)
  </div>
</section>

<!--ASSESSMENT PROPERTY INFORMATION-->
<section class="text-os-bold">
  <div class="row">
    ASSESSMENT PROPERTY INFORMATION
  </div>
  <div class="row">
    Assessment Roll Number
  </div>
  <div class="row">
    Property Address
  </div>
  <div class="row">
    Unit Number
  </div>
  <div class="row">
    Property Type
  </div>
  <div class="row">
    Property Code
  </div>
  <div class="row">
    Property Description
  </div>
  <div class="row">
    Legal Description
  </div>
  @if (userAccessControls.iciAssessmentInformation) {
    <div class="row">
      Corporate Name
    </div>
    <div class="row">
      Corporate Mailing Address
    </div>
  }
</section>
<!--VALUATION AND SALE INFORMATION-->
<section class="text-os-bold">
  <div class="row">
    VALUATION AND SALE INFORMATION
  </div>
  <div class="row">
    {{ propertyDetail?.labelsBase }}
  </div>
  <div class="row">
    {{ propertyDetail?.labelsPhasedIn }}
  </div>
  <div class="row">
    Last Valid Sale Date
  </div>
  <div class="row">
    Last Valid Sale Amount
  </div>
  <div class="row">
    Total Land Value
  </div>
  <div class="row">
    Total Land Value Base Year
  </div>
</section>
<!--ASSESSMENT SITE INFORMATION-->
<section class="text-os-bold">
  <div class="row">
    ASSESSMENT SITE INFORMATION
  </div>
  <div class="row">
    Frontage
  </div>
  <div class="row">
    Depth
  </div>
  <div class="row">
    Unit of Measure
  </div>
  <div class="row">
    Lot Size
  </div>
  <div class="row">
    Lot Size Unit of Measure
  </div>
  <div class="row">
    Zoning
  </div>
</section>
<!--RESIDENTIAL STRUCTURE INFORMATION-->
<section class="text-os-bold">
  <div class="row">
    RESIDENTIAL STRUCTURE INFORMATION
  </div>
  <div class="row">
    Year Built
  </div>
  @if (userAccessControls.resFloorAreaBillable) {
    <div class="row">
      Total Floor Area
    </div>
  }
</section>
<!--INDUSTRIAL STRUCTURE INFORMATION-->
@if (userAccessControls.iciAssessmentInformation) {
  <section class="text-os-bold">
    <div class="row">
      INDUSTRIAL STRUCTURE INFORMATION
    </div>
    <div class="row">
      Year Built
    </div>
    <div class="row">
      Basement Area
    </div>
    <div class="row">
      Basement Office Area
    </div>
    <div class="row">
      Mezzanine Area
    </div>
    <div class="row">
      Mezzanine Office Area
    </div>
    <div class="row">
      Interior Office Area
    </div>
    <div class="row">
      Additional Area
    </div>
    <div class="row">
      Total Area
    </div>
    <div class="row">
      Clear Height Min/Max(ft)
    </div>
    <div class="row">
      Largest Structure Interior Office Area
    </div>
    <div class="row">
      Oldest/Largest Structure Year Built
    </div>
  </section>
  <!--COMMERCIAL INFORMATION-->
  <section class="text-os-bold">
    <div class="row">
      COMMERCIAL INFORMATION
    </div>
    <div class="row">
      Office Building Number of Storeys
    </div>
    <div class="row">
      Office Building Single Tenant Floor Area
    </div>
    <div class="row">
      Office Building Multiple Tenant Floor Area
    </div>
    <div class="row">
      Retail Property Year Built
    </div>
    <div class="row">
      Shopping Centre Number of Units
    </div>
    <div class="row">
      Shopping Centre Ground Floor Area(sqft)
    </div>
    <div class="row">
      Shopping Centre Gross Lease Area(sqft)
    </div>
  </section>
}
