import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UserAccessControl} from "../../../model/user/user-access-control";
import {UserService} from "../../../../shared/service/user.service";
import {ComparableSalesReportCreationParam} from "../../../model/comparables/report/comparable-sales-report-creation-param";

@Component({
  selector: 'gema3g-comparables-report-add',
  templateUrl: './comparables-report-add.html',
  styleUrls: ['./comparables-report-add.scss']
})
export class ComparablesReportAdd {
  userAccessControls: UserAccessControl;
  param: ComparableSalesReportCreationParam = new ComparableSalesReportCreationParam();
  showError: boolean = false;

  constructor(public dialogRef: MatDialogRef<ComparablesReportAdd>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private userService: UserService) {
    this.userAccessControls = this.userService.getUserAccessControl();
  }


  getRemainingCharacters(): number {
    return 300 - (this.param?.comments?.length ?? 0);
  }

  get formNotValid(): boolean {
    return !this.param.reportName;
  }

  sendParams() {
    if (this.formNotValid) {
      this.showError = true;
    } else {
      this.dialogRef.close(this.param);
    }
  }
}
