import {inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {LoggerService} from "./log/logger.service";
import {baseUrl} from "./system";
import {catchError, lastValueFrom, map, Observable, of} from "rxjs";
import {PdfReportRequestParam} from "../../core/model/pdf-report/pdf-report-request-param";
import {PdfReportResponse} from "../../core/model/pdf-report/pdf-report-response";
import {PdfReportTypeEnum} from "../../core/enum/pdf-report-type-enum";
import {defaultErrorMatSnackBarConfig, LocalStorageKey} from "../constant/constants";
import {FileUtility} from "../utility/file.utility";
import {UserService} from "../../shared/service/user.service";
import dayjs from "dayjs";
import {PdfReportDownloadResult} from "../../core/model/pdf-report/pdf-report-download-result";
import {MatDialog} from "@angular/material/dialog";
import {ProgressBarDialogComponent} from "../../core/component/modal/progress-bar-dialog/progress-bar-dialog.component";
import {ProgressBarDialogData} from "../../core/component/modal/progress-bar-dialog/progress-bar-data";
import {ErrorUtil} from "./error.util";
import {SnackBarService} from "./snack-bar.service";

@Injectable({
  providedIn: 'root'
})
export class GeneratePdfReportService {

  private https = inject(HttpClient);
  private loggerService = inject(LoggerService);
  private fileUtility = inject(FileUtility);
  private userService: UserService = inject(UserService);
  private dialog = inject(MatDialog);
  private snackBarService = inject(SnackBarService);

  generatePropertyReportPDFToken = (param: PdfReportRequestParam): Observable<PdfReportResponse> => {

    this.loggerService.logDebug("Getting property report PDF token ", param);
    const url = baseUrl + '/property/details/report/pdf/data';

    return this.https.post(url, param)
      .pipe(
        map(resp => {
          return new PdfReportResponse(resp);
        }),
        catchError((err) => {
          this.loggerService.logError(`Unable to retrieve property pdf report token`, err);
          return of(new PdfReportResponse());
        })
      );
  }

  generateMyPropertyReportForComparableSalesPDFToken = (param: PdfReportRequestParam): Observable<PdfReportResponse> => {

    this.loggerService.logDebug("Getting my property report PDF token for a comparable request", param);

    const requestType = param.polygonRequest ? 'polygon' : (param.circleRequest ? 'circle' : 'latlong');
    const url = `${baseUrl}/comparableSales/${requestType}/report/data`;

    return this.https.post(url, param)
      .pipe(
        map(resp => {
          return new PdfReportResponse(resp);
        }),
        catchError((err) => {
          this.loggerService.logError(`Unable to retrieve my property pdf report token for comparable sales`, err);
          return of(new PdfReportResponse());
        })
      );
  }

  generateClientReportPDFToken = (param: PdfReportRequestParam): Observable<PdfReportResponse> => {

    this.loggerService.logDebug("Getting Client Report PDF token ", param);
    const url = baseUrl + '/clientReport/data?sorting=date&direction=asc';

    return this.https.post(url, param)
      .pipe(
        map(resp => {
          return new PdfReportResponse(resp);
        }),
        catchError((err) => {
          this.loggerService.logError(`Unable to retrieve client pdf report token`, err);
          return of(new PdfReportResponse());
        })
      );
  }

  downloadPdfReport = (param: PdfReportRequestParam, reportKey: string): Observable<PdfReportDownloadResult> => {
    let url;
    if (param.requestType === PdfReportTypeEnum.MY_PROPERTY_REPORT && (param.polygonRequest || param.circleRequest || param.latLongRequest)) {
      url = baseUrl + '/comparableSales/report.pdf?reportKey=' + reportKey;
      return this.openBlob(url, param.requestType);
    } else if (param.requestType == PdfReportTypeEnum.MY_PROPERTY_REPORT || param.requestType == PdfReportTypeEnum.BASIC) {
      url = baseUrl + '/property/details/report.pdf?reportKey=' + reportKey + '&reportName=' + param.reportName;
      return this.openBlob(url, param.requestType);
    } else if (param.requestType) {
      url = baseUrl + '/clientReport/report.pdf?reportKey=' + reportKey;
      return this.openBlob(url, param.requestType);
    } else {
      this.loggerService.logError("Request Type is missing when downloading PDF.");
      return of(new PdfReportDownloadResult());
    }
  }

  openBlob(url: string, requestType: PdfReportTypeEnum): Observable<PdfReportDownloadResult> {
    return this.https.get<Blob>(url, {
      responseType: 'blob' as 'json'
    }).pipe(
      map((response) => {
        const fileName = `gwh-pdf-report-${this.userService.user.businessEntityId}-${requestType}-${dayjs().format('YYYY-MM-DD_HH-mm-ss.SSS')}.pdf`;
        this.fileUtility.displayBrowserDownloadPrompt(fileName, response);
        return new PdfReportDownloadResult(fileName);
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        throw err;
      })
    );
  }


  async openPdfReport(param: PdfReportRequestParam) {
    let modalConfig = {
      data: new ProgressBarDialogData('PDF generation is in progress...', this.openLastPdfRequest.bind(this), param),
      minWidth: 400,
      maxWidth: 900,
    };
    const dialogRef = await this.dialog.open(ProgressBarDialogComponent, modalConfig)
      .afterClosed()
      .subscribe(async (resp: boolean) => {
        this.loggerService.logDebug("Done.")
      });
  }

  async openLastPdfRequest(param: any) {
    if (param) {
      try {
        const requestedToken = await this.requestToken(param);
        if (requestedToken?.reportKey) {
          const pdfReportDownloadResult = await lastValueFrom(this.downloadPdfReport(param, requestedToken.reportKey));
          if(pdfReportDownloadResult?.success) {
            const downloadedFileName = pdfReportDownloadResult.fileName;
            if(pdfReportDownloadResult.fileDownloaded && downloadedFileName){
              this.snackBarService.displaySnackBarMessage(`PDF report ${downloadedFileName} has been downloaded.`);
            }
          } else {
            this.snackBarService.displaySnackBarError(ErrorUtil.PDF_REPORT_VIEWER_NO_REPORT_RETURNED);
          }
        } else {
          this.snackBarService.displaySnackBarError(ErrorUtil.PDF_REPORT_VIEWER_NO_REPORT_KEY);
        }
      } catch (ex) {
        this.snackBarService.displaySnackBarError(ErrorUtil.PDF_REPORT_VIEWER_NO_REPORT_RETURNED);
      }
    } else {
      this.loggerService.logError("Last PDF report request param is not defined");
    }
  }

  async requestToken(param: PdfReportRequestParam): Promise<PdfReportResponse>{

    if(param.requestType === PdfReportTypeEnum.MY_PROPERTY_REPORT && (param.polygonRequest || param.circleRequest || param.latLongRequest)){
      return await lastValueFrom(this.generateMyPropertyReportForComparableSalesPDFToken(param));
    }

    return param.requestType == PdfReportTypeEnum.CLIENT_REPORT ?
      await lastValueFrom(this.generateClientReportPDFToken(param)) :
      await lastValueFrom(this.generatePropertyReportPDFToken(param));
  }
}


