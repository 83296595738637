import { Component, Input, OnInit, ViewChild, inject } from '@angular/core';
import { LeftSidebarComponent } from '../left-sidebar/left-sidebar.component';
import { RightSidebarComponent } from '../right-sidebar/right-sidebar.component';
import { DataService } from '../../../../shared/service/data.service';
import { PropertyReportSectionEnum } from '../../../../core/enum/property-report-section-enum';

export interface Legend {
  title: string,
  body: string,
  code: string
}

@Component({
  selector: 'gema3g-property-report-layout',
  standalone: true,
  templateUrl: './layout.component.html',
  imports: [
    LeftSidebarComponent,
    RightSidebarComponent
  ],
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  @Input() propertyReportSection: any
  @Input() legend: Legend[] = [];
  @ViewChild('lSideBar') lSideBar: LeftSidebarComponent;
  @ViewChild('rSideBar') rSideBar: RightSidebarComponent;
  showLSideBar = 0;
  showRSideBar = 0;

  get demographicsSection() {
    return this.propertyReportSection == PropertyReportSectionEnum.DEMOGRAPHICS;
  }

  public get DataService() {
    return DataService;
  }

  ngOnInit(): void {
    this.showLSideBar = this.lSideBar?.newAdBanners?.length;
    this.showRSideBar = this.rSideBar?.newAdBanners?.length;
  }
}
