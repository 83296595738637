export enum SSODeeplinkRequestParameter {
    DEEP_LINK_TARGET = 'deeplinktarget',
    DEEP_LINK_SEARCH_TYPE = 'deeplinksearchtype',
    SEARCH_VALUE = 'deeplink_searchval',
    ADDRESS = 'address',
    REGISTERED_PLAN = 'rpln',
    LRO = 'lro',
    LOT = 'lot',
    CONCESSION = 'con',
    INSTRUMENT_NUMBER = 'instrumentNumber',
    PRODUCT_CODE = 'deeplink_productcode',
    PYB_TOKEN = 'pybToken',
    ESTORE_ONLY = 'eStoreOnly',
    CALLBACK = 'callback',
    REQUESTED_FEATURE = 'rf',
    REFERER = 'ref'
}