export class SearchResultOrder {
  firstPredicate: string;
  secondPredicate: string;
  isReverse: boolean;

  get sorting(): string[] {
    return [this.firstPredicate, this.secondPredicate].filter(Boolean);
  }

  constructor(firstPredicate: string, secondPredicate: string, isReverse: boolean) {
    this.firstPredicate = firstPredicate;
    this.secondPredicate = secondPredicate;
    this.isReverse = isReverse;
  }

  public static AddressDropdownValues = [
    {
      label: 'Address: A - Z',
      value: new SearchResultOrder('streetName', '', false)
    },
    {
      label: 'Address: Z - A',
      value: new SearchResultOrder('streetName', '', true)
    },
    {
      label: 'Address: 0 - 9',
      value: new SearchResultOrder('streetNumber', 'unitNumber', false)
    },
    {
      label: 'Address: 9 - 0',
      value: new SearchResultOrder('streetNumber', 'unitNumber', true)
    }
  ];

  public static NameDropdownValues = [
    {
      label: 'Name: A - Z',
      value: new SearchResultOrder('name', '', false)
    },
    {
      label: 'Name: Z - A',
      value: new SearchResultOrder('name', '', true)
    }
  ]
  public static PinDropdownValues = [
    {
      label: 'PIN: 0 - 9',
      value: new SearchResultOrder('pin', '', false)
    },
    {
      label: 'PIN: 9 - 0',
      value: new SearchResultOrder('pin', '', true)
    }
  ]
  public static LevelNumberDropdownValues = [
    {
      label: 'Level: 0 - 9',
      value: new SearchResultOrder('levelNumber', '', false)
    },
    {
      label: 'Level: 9 - 0',
      value: new SearchResultOrder('levelNumber', '', true)
    }
  ]
  public static DropdownValues = [
    ...SearchResultOrder.AddressDropdownValues,
    ...SearchResultOrder.NameDropdownValues,
    ...SearchResultOrder.PinDropdownValues
  ];

  public static AddressPinDropdownValues = [
    ...SearchResultOrder.AddressDropdownValues,
    ...SearchResultOrder.PinDropdownValues
  ];

  public static LevelPinDropdownValues = [
    ...SearchResultOrder.LevelNumberDropdownValues,
    ...SearchResultOrder.PinDropdownValues
  ];

}
