<div class="demographics-header">
  <h4 id="dg-h4-se">
    Socio-economic
  </h4>
</div>

@if (socioEconomic) {
  <ul class="demographics-data-header">
    <li class="title"><h5 id="dg-h5-se-ahi">Avg. Household Income</h5></li>
    <li id="dg-se-nbh-label">NBH @if (socioEconomic.PRCDDA?.averageHouseholdIncome) {
      <span id="dg-se-nbh">
        ${{socioEconomic.PRCDDA?.averageHouseholdIncome | fusionChartsNumber}}
      </span>
    }
    @if (!socioEconomic.PRCDDA?.averageHouseholdIncome) {
      <span id="dg-se-nbh-na" class="demographics-values-na">N/A</span>
    }
  </li>
  <li id="dg-se-com-label">COM @if (socioEconomic.FSA?.averageHouseholdIncome) {
    <span id="dg-se-com">
      ${{socioEconomic.FSA?.averageHouseholdIncome | fusionChartsNumber}}
    </span>
  }
  @if (!socioEconomic.FSA?.averageHouseholdIncome) {
    <span id="dg-se-com-na" class="demographics-values-na">N/A</span>
  }
</li>
<li id="dg-se-city-label">CITY @if (socioEconomic.PRCDCSD?.averageHouseholdIncome) {
  <span id="dg-se-city">
  ${{socioEconomic.PRCDCSD?.averageHouseholdIncome  | fusionChartsNumber}}</span>
}
@if (!socioEconomic.PRCDCSD?.averageHouseholdIncome) {
  <span id="dg-se-city-na" class="demographics-values-na">N/A</span>
}
</li>
</ul>
<div class="flex flex-col md:flex-row py-9">
  <div class="flex md:w-1/2">
    <div class="flex flex-col">
      <span id="dg-se-emp-label" class="text-lg demographics-sub-header">Employment</span>
      <div>
        @if (selectedArea.includes(demographicsAreaEnum.NBH)) {
          <gema3g-employment [socioEconomic]="socioEconomic.PRCDDA" [key]="'NBH'" [metadata]="demographicsData.metaData" >
          </gema3g-employment>
        }
        @if (selectedArea.includes(demographicsAreaEnum.COM)) {
          <gema3g-employment [socioEconomic]="socioEconomic.FSA" [key]="'COM'" [metadata]="demographicsData.metaData">
          </gema3g-employment>
        }
        @if (selectedArea.includes(demographicsAreaEnum.CITY)) {
          <gema3g-employment [socioEconomic]="socioEconomic.PRCDCSD" [key]="'City'" [metadata]="demographicsData.metaData">
          </gema3g-employment>
        }
      </div>
    </div>
  </div>
  <div class="flex md:w-1/2 pl-2 pt-9 lg:pt-0">
    <div class="flex flex-col">
      <span id="dg-se-el-label" class="text-lg demographics-sub-header">Highest Level of Education</span>
      <div>
        @if (selectedArea.includes(demographicsAreaEnum.NBH)) {
          <gema3g-education-level [socioEconomic]="socioEconomic.PRCDDA" [key]="'NBH'" >
          </gema3g-education-level>
        }
        @if (selectedArea.includes(demographicsAreaEnum.COM)) {
          <gema3g-education-level [socioEconomic]="socioEconomic.FSA" [key]="'COM'">
          </gema3g-education-level>
        }
        @if (selectedArea.includes(demographicsAreaEnum.CITY)) {
          <gema3g-education-level [socioEconomic]="socioEconomic.PRCDCSD" [key]="'City'">
          </gema3g-education-level>
        }
      </div>
    </div>
  </div>
</div>
}
