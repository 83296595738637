import { Component } from '@angular/core';

@Component({
  selector: 'gema3g-registration-tracker',
  templateUrl: './registration-tracker.component.html',
  styleUrls: ['./registration-tracker.component.scss']
})
export class RegistrationTrackerComponent {

}
