import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class SSODeeplinkObservablesService {
    constructor() {
    }

    private _ssoSubjectPropertyPin = new BehaviorSubject<string | null>(null);
    private ssoSubjectPropertyPin$ = this._ssoSubjectPropertyPin.asObservable();

    getSSOSubjectPropertyPinObservable = (): Observable<string | null> => {
        return this.ssoSubjectPropertyPin$;
    }

    updateSSOSubjectPropertyPin = (pin: string) => {
        this._ssoSubjectPropertyPin.next(pin);
    }

    resetSSOSubjectPropertyPin = () => {
        this._ssoSubjectPropertyPin.next(null);
    }
    
    getSSOSubjectPropertyPin = () => {
        return this._ssoSubjectPropertyPin.getValue();
    }
}