<div class="h-auto max-h-[130px] lg:mb-6 text-center sticky top-0 left-0 bg-white">
  <div class="text-os-bold">
    {{ propertyDetail?.pii?.fullStreetName | addressOrNa }}
  </div>
  <div class="h-[90px] hidden lg:block">
    <img height="90px" src="{{imageUrl}}"/>
  </div>
  <div>
    @if (isSubject) {
      Subject
    } @else {
      <a id="comp-sal-report-remove-pin-{{propertyDetail?.pii?.pin}}" (click)="removePropertyFromReport()" class="anchor" target="_blank">
        Remove
      </a>
    }  | {{ reportDate }}
  </div>
</div>
<!--LAND REGISTRY INFORMATION-->
<section>
  <div class="row">
    <!--    This is for header-->
  </div>
  <div class="row">
    <a id="comp-sal-report-pin-{{propertyDetail?.pii?.pin}}" (click)="goToPin(propertyDetail.pii.pin)" class="anchor" target="_blank">
      {{ propertyDetail?.pii?.pin }}
    </a>
  </div>
  <div class="row" id="comp-sal-report-muni-{{propertyDetail?.pii?.pin}}">
    {{ propertyDetail?.pii?.municipality }}
  </div>
  <div class="row" id="comp-sal-report-short-desc-{{propertyDetail?.pii?.pin}}">
    {{ propertyDetail?.pii?.shortDescription | na }}
  </div>
  <div class="row" id="comp-sal-report-area-{{propertyDetail?.pii?.pin}}">
    {{ propertyDetail?.pii?.area | na }}
  </div>
</section>

<!--ASSESSMENT PROPERTY INFORMATION-->
<section>
  <div class="row">
    <!--    This is for header-->
  </div>
  <div class="row" id="comp-sal-report-parn-no-{{propertyDetail?.pii?.pin}}">
    {{ propertyDetail?.mpacPropertyAssessmentVO?.assessmentRollNumber | na }}
  </div>
  <div class="row" id="comp-sal-report-adr-{{propertyDetail?.pii?.pin}}">
    {{ propertyDetail?.mpacPropertyAssessmentVO?.address | na }}
  </div>
  <div class="row" id="comp-sal-report-unit-{{propertyDetail?.pii?.pin}}">
    {{ propertyDetail?.mpacPropertyAssessmentVO?.unitNo | na }}
  </div>
  <div class="row" id="comp-sal-report-prop-type-{{propertyDetail?.pii?.pin}}">
    {{ propertyDetail.propertyType | na }}
  </div>
  <div class="row" id="comp-sal-report-prop-code-{{propertyDetail?.pii?.pin}}">
    {{ propertyDetail?.mpacPropertyAssessmentVO?.propertyTypeCode | na }}
  </div>
  <div class="row" id="comp-sal-report-prop-type-desc-{{propertyDetail?.pii?.pin}}">
    {{ propertyDetail?.mpacPropertyAssessmentVO?.propertyTypeDescription | na }}
  </div>
  <div class="row" id="comp-sal-report-lgl-descr-{{propertyDetail?.pii?.pin}}">
    {{ propertyDetail?.mpacPropertyAssessmentVO?.legalDescription | na }}
  </div>
  @if (userAccessControls.iciAssessmentInformation) {
    <div class="row" id="comp-sal-report-corp-name-{{propertyDetail?.pii?.pin}}">
      {{ propertyDetail?.mpacPropertyAssessmentVO?.corporationName | na }}
    </div>
    <div class="row" id="comp-sal-report-mail-adr-{{propertyDetail?.pii?.pin}}">
      {{ propertyDetail?.mpacPropertyAssessmentVO?.mailingAddress | na }}
    </div>
  }
</section>
<!--VALUATION AND SALE INFORMATION-->
<section>
  <div class="row">
    <!--    This is for header-->
  </div>
  <div class="row" id="comp-sal-report-dest-val-{{propertyDetail?.pii?.pin}}">
    {{ propertyDetail?.mpacPropertyAssessmentVO?.destinationValue | na | prefixWithDollarSign }}
  </div>
  <div class="row" id="comp-sal-report-crnt-val-{{propertyDetail?.pii?.pin}}">
    {{ propertyDetail?.mpacPropertyAssessmentVO?.currentValue | na | prefixWithDollarSign }}
  </div>
  <div class="row" id="comp-sal-report-sale-date-{{propertyDetail?.pii?.pin}}">
    {{ propertyDetail?.mpacPropertyAssessmentVO?.lastSale?.saleDate | na }}
  </div>
  <div class="row" id="comp-sal-report-sale-amount-{{propertyDetail?.pii?.pin}}">
    {{ propertyDetail?.mpacPropertyAssessmentVO?.lastSale?.saleAmount | na }}
  </div>
  <div class="row" id="comp-sal-report-land-val-{{propertyDetail?.pii?.pin}}">
    {{ propertyDetail?.mpacPropertyAssessmentVO?.firstLandAssessment?.totalLandValue | na }}
  </div>
  <div class="row" id="comp-sal-report-mrkt-val-base-year-{{propertyDetail?.pii?.pin}}">
    {{ propertyDetail?.mpacPropertyAssessmentVO?.firstLandAssessment?.marketValueBaseYear | na }}
  </div>
</section>
<!--ASSESSMENT SITE INFORMATION-->
<section>
  <div class="row">
    <!--    This is for header-->
  </div>
  <div class="row" id="comp-sal-report-frontage-{{propertyDetail?.pii?.pin}}">
    {{ propertyDetail?.mpacPropertyAssessmentVO?.frontage | na }}
  </div>
  <div class="row" id="comp-sal-report-depth-{{propertyDetail?.pii?.pin}}">
    {{ propertyDetail?.mpacPropertyAssessmentVO?.depth | na }}
  </div>
  <div class="row" id="comp-sal-report-uom-{{propertyDetail?.pii?.pin}}">
    {{ propertyDetail?.mpacPropertyAssessmentVO?.actualUnitOfMeasure | na }}
  </div>
  <div class="row" id="comp-sal-report-site-area-{{propertyDetail?.pii?.pin}}">
    {{ propertyDetail?.mpacPropertyAssessmentVO?.siteArea | na }}
  </div>
  <div class="row" id="comp-sal-report-a-site-area-uom-{{propertyDetail?.pii?.pin}}">
    {{ propertyDetail?.mpacPropertyAssessmentVO?.actualSiteAreaUom | na }}
  </div>
  <div class="row" id="comp-sal-report-zoning-code-{{propertyDetail?.pii?.pin}}">
    {{ propertyDetail?.mpacPropertyAssessmentVO?.zoningCode | na }}
  </div>
</section>
<!--RESIDENTIAL STRUCTURE INFORMATION-->
<section>
  <div class="row">
    <!--    This is for header-->
  </div>
  <div class="row" id="comp-sal-report-year-built-{{propertyDetail?.pii?.pin}}">
    {{ propertyDetail?.mpacPropertyAssessmentVO?.yearBuilt | na }}
  </div>
  @if (userAccessControls.resFloorAreaBillable) {
    <div class="row" id="comp-sal-report-area-billable-{{propertyDetail?.pii?.pin}}">
      N/A
    </div>
  }
</section>
<!--INDUSTRIAL STRUCTURE INFORMATION-->
@if (userAccessControls.iciAssessmentInformation) {
  <section>
    <div class="row">
      <!--    This is for header-->
    </div>
    <div class="row" id="comp-sal-report-year-built-{{propertyDetail?.pii?.pin}}">
      {{ propertyDetail?.mpacPropertyAssessmentVO?.yearBuilt | na }}
    </div>
    <div class="row" id="comp-sal-report-basmnt-area-{{propertyDetail?.pii?.pin}}">
      {{ propertyDetail?.mpacPropertyAssessmentVO?.basementArea | na }}
    </div>
    <div class="row" id="comp-sal-report-bsmnt-area-office-{{propertyDetail?.pii?.pin}}">
      {{ propertyDetail?.mpacPropertyAssessmentVO?.basementOfficeArea | na }}
    </div>
    <div class="row" id="comp-sal-report-mzn-area-{{propertyDetail?.pii?.pin}}">
      {{ propertyDetail?.mpacPropertyAssessmentVO?.mezzanineArea | na }}
    </div>
    <div class="row" id="comp-sal-report-mzn-office-area-{{propertyDetail?.pii?.pin}}">
      {{ propertyDetail?.mpacPropertyAssessmentVO?.mezzanineOfficeArea | na }}
    </div>
    <div class="row" id="comp-sal-report-int-office-area-{{propertyDetail?.pii?.pin}}">
      {{ propertyDetail?.mpacPropertyAssessmentVO?.interiorOfficeArea | na }}
    </div>
    <div class="row" id="comp-sal-report-padd-area-{{propertyDetail?.pii?.pin}}">
      {{ propertyDetail?.mpacPropertyAssessmentVO?.additionalArea | na }}
    </div>
    <div class="row" id="comp-sal-report-ttl-area-{{propertyDetail?.pii?.pin}}">
      {{ propertyDetail?.mpacPropertyAssessmentVO?.totalArea | na }}
    </div>
    <div class="row" id="comp-sal-report-strct-min-hght-{{propertyDetail?.pii?.pin}}">
      {{ propertyDetail?.mpacPropertyAssessmentVO?.structureMinHeight | na }} / {{ propertyDetail?.mpacPropertyAssessmentVO?.structureMaxHeight | na }}
    </div>
    <div class="row" id="comp-sal-report-dtrct-int-office-area-{{propertyDetail?.pii?.pin}}">
      {{ propertyDetail?.mpacPropertyAssessmentVO?.structureMaxInteriorOfficeArea | na }}
    </div>
    <div class="row" id="comp-sal-report-oldest-largest-year-built-{{propertyDetail?.pii?.pin}}">
      {{ propertyDetail?.mpacPropertyAssessmentVO?.oldestYearBuilt | na }} /  {{ propertyDetail?.mpacPropertyAssessmentVO?.largestYearBuilt | na }}
    </div>
  </section>
  <!--COMMERCIAL INFORMATION-->
  <section>
    <div class="row">
      <!--    This is for header-->
    </div>
    <div class="row" id="comp-sal-report-pin-incm-dt-nr-stories-{{propertyDetail?.pii?.pin}}">
      {{ propertyDetail?.mpacPropertyAssessmentVO?.incomeData?.numberOfStories | na }}
    </div>
    <div class="row" id="comp-sal-report-pin-incm-dt-nr-stories-office-stfa-ttl-{{propertyDetail?.pii?.pin}}">
      {{ propertyDetail?.mpacPropertyAssessmentVO?.incomeData?.officeSTFATotal | na }}
    </div>
    <div class="row" id="comp-sal-report-pin-incm-dt-nr-stories-office-mtfa-ttl-{{propertyDetail?.pii?.pin}}">
      {{ propertyDetail?.mpacPropertyAssessmentVO?.incomeData?.officeMTFATotal | na }}
    </div>
    <div class="row" id="comp-sal-report-pin-incm-dt-year-blt-{{propertyDetail?.pii?.pin}}">
      {{ propertyDetail?.mpacPropertyAssessmentVO?.incomeData?.yearBuilt | na }}
    </div>
    <div class="row" id="comp-sal-report-pin-shop-cntr-total-un-{{propertyDetail?.pii?.pin}}">
      {{ propertyDetail?.mpacPropertyAssessmentVO?.shoppingCentreData?.totalUnits | na }}
    </div>
    <div class="row" id="comp-sal-report-pin-shop-cntr-grnd-area-{{propertyDetail?.pii?.pin}}">
      {{ propertyDetail?.mpacPropertyAssessmentVO?.shoppingCentreData?.groundFloorArea | na }}
    </div>
    <div class="row" id="comp-sal-report-pin-shop-cntr-total-gross-area-{{propertyDetail?.pii?.pin}}">
      {{ propertyDetail?.mpacPropertyAssessmentVO?.shoppingCentreData?.totGrossLeaseArea | na }}
    </div>
  </section>
}
