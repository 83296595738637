export class OmnibarState {

  private _searchResultsFound: boolean;
  private _searchInitiated: boolean;

  public get searchResultsFound() {
    return this._searchResultsFound;
  }

  public set searchResultsFound(flag: boolean) {
    this._searchResultsFound = flag;
  }

  public get searchInitiated() {
    return this._searchInitiated;
  }

  public set searchInitiated(flag: boolean) {
    this._searchInitiated = flag;
  }
}
