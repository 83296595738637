import { HttpClient, HttpHeaders } from "@angular/common/http";
import {inject, Injectable} from "@angular/core";
import { map, catchError, throwError, Observable } from 'rxjs';
import { baseUrl } from "../system";
import * as _ from 'lodash';
import { LoggerService } from "../log/logger.service";

@Injectable({
    providedIn: 'root'
})
export class PlanSurveySearchService {

    private https = inject(HttpClient);
    private loggerService = inject(LoggerService);

    getPlanAndSurveyDetails = (pin: string): Observable<any> => {
        const url = baseUrl + '/plan/sp?pin=' + pin;

        return this.https.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).pipe(
            map(response => {
                if (!_.isEmpty(response)) {
                    return response;
                } else {
                    return {};
                }
            }),
            catchError((err) => {
                this.loggerService.logError(`unable to retrieve plan and survey details for pin ${pin}`, err);
                return throwError(err);
            })
        );
    }

    getEasementCount = (pin: string): Observable<any> => {
        const url = baseUrl + '/plan/easementCount?pin=' + pin;

        return this.https.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).pipe(
            map(response => {
                if (!_.isEmpty(response)) {
                    return response;
                } else {
                    return {};
                }
            }),
            catchError((err) => {
                this.loggerService.logError(`error retrieving easement count for pin ${pin}`, err);
                return throwError(err);
            })
        );
    }

    getLSRSurveySampleUrl(companySurveyId: string): string {
      return `${baseUrl}/plan/previewLSRPlan?id=${companySurveyId}`;
    }

}
