import {BaseModel} from "../base-model";

export class PdfReportRequestReportConfig extends BaseModel {

  constructor(reportConfig?: PdfReportRequestReportConfig) {
    super(reportConfig);
    if (reportConfig) {
      if (Array.isArray(reportConfig.reportSections)) {
        this.reportSections = [...reportConfig.reportSections];
      }
      if (Array.isArray(reportConfig.reportImages)) {
        this.reportImages = [...reportConfig.reportImages];
      }
    }
  }

  reportName: string;
  reportTitle: string;
  reportSections: string[] = [];
  reportImages: string[] | null = [];
  uom: string = 'metric';

}
