@if (socioEconomic) {
  <div class="flex flex-row pt-[10%]">
    <div class="">
      <div id="chart-education-{{key}}">
        <fusioncharts
          [dataSource]="educationStatus"
          dataFormat="json"
          height="177"
          width="177"
          type="doughnut2d"
        ></fusioncharts>
      </div>
    </div>
    @if (socioEconomic.percentageWithUniversityDegree) {
      <table id="dg-tbl-se-el-{{key}}" class="chart-doughnut-legend" >
        <tr id="dg-tbl-row-se-el-uni-{{key}}">
          <td>
            <div id="dg-tbl-data-se-el-uni-circle-{{key}}" class="demographics-circle demographics-circle-color5"></div>
          </td>
          <td id="dg-tbl-data-se-el-uni-label-{{key}}">University</td>
          <td id="dg-tbl-data-se-el-uni-pctg-{{key}}" class="number number-education">{{socioEconomic.percentageWithUniversityDegree | number : '1.0-0'}}%
          </td>
        </tr>
        <tr id="dg-tbl-row-se-el-clg-{{key}}">
          <td>
            <div id="dg-tbl-data-se-el-clg-circle-{{key}}" class="demographics-circle demographics-circle-color6"></div>
          </td>
          <td id="dg-tbl-data-se-el-clg-label-{{key}}">College</td>
          <td id="dg-tbl-data-se-el-clg-pctg-{{key}}" class="number number-education">{{socioEconomic.percentageWithCollegeDiploma | number: '1.0-0'}}%
          </td>
        </tr>
        <tr id="dg-tbl-row-se-el-hs-{{key}}">
          <td>
            <div id="dg-tbl-data-se-el-hs-circle-{{key}}" class="demographics-circle demographics-circle-color7"></div>
          </td>
          <td id="dg-tbl-data-se-el-hs-label-{{key}}">High School</td>
          <td id="dg-tbl-data-se-el-hs-pctg-{{key}}" class="number number-education">{{socioEconomic.percentageWithHighSchoolDiploma  | number: '1.0-0'}}%
          </td>
        </tr>
        <tr id="dg-tbl-row-se-el-other-{{key}}">
          <td>
            <div id="dg-tbl-data-se-el-other-circle-{{key}}" class="demographics-circle demographics-circle-color8"></div>
          </td>
          <td id="dg-tbl-data-se-el-other-label-{{key}}">Other</td>
          <td id="dg-tbl-data-se-el-other-pctg-{{key}}" class="number number-education">{{calculateOtherValue() | number : '1.0-0'}}%</td>
        </tr>
      </table>
    }
    <div class="clear"></div>
  </div>
}
