<div class="flex flex-row w-full">
  <fusioncharts
    [dataSource]="maritalStatus"
    dataFormat="json"
    height="177"
    width="177"
    type="doughnut2d"
    [dataEmptyMessage]="key+'Data Not Available'"
  ></fusioncharts>
  <div>
    @if (maritalStatusItem) {
      <table id="dg-tbl-pop-ms-{{key}}" class="chart-doughnut-legend">
        <tr id="dg-tbl-row-pop-ms-single-{{key}}">
          <td>
            <div id="dg-tbl-data-pop-ms-single-circle-{{key}}" class="demographics-circle demographics-circle-color1"></div>
          </td>
          <td id="dg-tbl-data-pop-ms-single-label-{{key}}">Single</td>
          <td id="dg-tbl-data-pop-ms-single-pctg-{{key}}" class="number">{{ maritalStatusItem?.percentageOfSingle   | number : '1.0-0' }}%
          </td>
        </tr>
        <tr id="dg-tbl-row-pop-ms-married-{{key}}">
          <td>
            <div id="dg-tbl-data-pop-ms-married-circle-{{key}}" class="demographics-circle demographics-circle-color2"></div>
          </td>
          <td id="dg-tbl-data-pop-ms-married-label-{{key}}">Married</td>
          <td id="dg-tbl-data-pop-ms-married-pctg-{{key}}" class="number">{{ maritalStatusItem?.percentageOfMarried | number : '1.0-0' }}%
          </td>
        </tr>
        <tr id="dg-tbl-row-pop-ms-sepdiv-{{key}}">
          <td>
            <div id="dg-tbl-data-pop-ms-sepdiv-circle-{{key}}" class="demographics-circle demographics-circle-color3"></div>
          </td>
          <td id="dg-tbl-data-pop-ms-sepdiv-label-{{key}}" class="min-w-[110px]">Separated / Divorced</td>
          <td id="dg-tbl-data-pop-ms-sepdiv-pctg-{{key}}" class="number">{{ maritalStatusItem.percentageOfSeparatedOrDivorced | number : '1.0-0' }}%
          </td>
        </tr>
        <tr id="dg-tbl-row-pop-ms-other-{{key}}">
          <td>
            <div id="dg-tbl-data-pop-ms-other-circle-{{key}}" class="demographics-circle demographics-circle-color4"></div>
          </td>
          <td id="dg-tbl-data-pop-ms-other-label-{{key}}">Other</td>
          <td id="dg-tbl-data-pop-ms-other-pctg-{{key}}" class="number">{{
              calculateReminderValue(maritalStatusItem.percentageOfSingle,
                maritalStatusItem.percentageOfMarried,
                maritalStatusItem.percentageOfSeparatedOrDivorced) | number : '1.0-0'
            }}%
          </td>
        </tr>
      </table>
    }
  </div>
</div>
