import {BaseModel} from "../base-model";

export class Address2 extends BaseModel{
  city: string;
  postalCode: string;
  province: string;
  streetDesignation: string;
  streetDirection?: any;
  streetName: string;
  streetNumber: string;
  streetSuffix?: any;
  unitNumber: string;
  valid: boolean;
  streetAddress: string;
  streetNameWithNumber: string;
  fullAddress: string;
}
