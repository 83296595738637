<div class="flex justify-between">
  <div class="ml-[85px]">
    <div class="text-center">
      <img id="dg-pop-gs-female-icon" alt="Female" class="w-[15px] h-[30px] mb-1" src="/assets/img/icon_female.png">
      <p id="dg-pop-gs-female-label" class="text-os-bold">Female</p>
    </div>
  </div>

  <div class="justify-end md:pr-[50px]">
    <div class="text-center">
      <img id="dg-pop-gs-male-icon" alt="Male" class="w-[15px] h-[30px] mb-1" src="/assets/img/icon_male.png">
      <p id="dg-pop-gs-male-label" class="text-os-bold">Male</p>
    </div>
  </div>
</div>

<!-- @defer (on viewport) { -->
  <div id="dg-pop-gs-fusioncharts" class="flex flex-col" #parentDiv>
    @if(selectedArea.includes(demographicsAreaEnum.NBH)){
      <fusioncharts
        (initialized)="initialized($event)"
        [dataEmptyMessage]="'Neighbourhood Data Not Available'"
        [dataSource]="nbhDataSource"
        dataFormat="json"
        height="38"
        type="stackedbar2d"
        width="{{defaultWidth}}"
        [id]="'chart-gender-nbh'"
      ></fusioncharts>
    }
    @if(selectedArea.includes(demographicsAreaEnum.COM)){
      <fusioncharts
        (initialized)="initialized($event)"
        [dataEmptyMessage]="'Community Data Not Available'"
        [dataSource]="comDataSource"
        dataFormat="json"
        height="38"
        type="stackedbar2d"
        width="{{defaultWidth}}"
        [id]="'chart-gender-com'"
      ></fusioncharts>
    }
    @if(selectedArea.includes(demographicsAreaEnum.CITY)){
      <fusioncharts
        (initialized)="initialized($event)"
        [dataEmptyMessage]="'City Data Not Available'"
        [dataSource]="cityDataSource"
        dataFormat="json"
        height="38"
        type="stackedbar2d"
        width="{{defaultWidth}}"
        [id]="'chart-gender-city'"
      ></fusioncharts>
    }
  </div>
<!-- }
@placeholder (){
<div>
  Loading data ...
</div>
} -->
