import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarModule } from 'primeng/progressbar';
import { SidebarModule } from 'primeng/sidebar';
import { ChartModule } from 'primeng/chart';
import { AccordionModule } from 'primeng/accordion';
import { TooltipModule } from 'primeng/tooltip';
import { FieldsetModule } from 'primeng/fieldset';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { PaginatorModule } from 'primeng/paginator';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputTextModule } from 'primeng/inputtext';
import { TabViewModule } from 'primeng/tabview';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ProgressBarModule,
    SidebarModule,
    ChartModule,
    AccordionModule,
    TooltipModule,
    FieldsetModule,
    ToggleButtonModule,
    PaginatorModule,
    RadioButtonModule,
    InputTextModule,
    TabViewModule
  ],
  exports: [
    ProgressBarModule,
    SidebarModule,
    ChartModule,
    AccordionModule,
    TooltipModule,
    FieldsetModule,
    ToggleButtonModule,
    PaginatorModule,
    RadioButtonModule,
    InputTextModule,
    TabViewModule
  ]
})
export class PrimeNGModule { }
