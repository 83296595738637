import { AfterViewInit, Component, inject, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { UserAccessControl } from "../../../core/model/user/user-access-control";
import { AccountMenuItem } from "./account-menu-item";
import { AccountMenuSubItem } from "./account-menu-subitem";
import { NavigationEnd, Router } from "@angular/router";
import { UserService } from "../../../shared/service/user.service";
import { filter, lastValueFrom, takeUntil } from "rxjs";
import { accountMenuDefinitions } from "./account-menu-definitions";
import { User } from "../../../core/model/user/user";
import { LoggerService } from '../../../shared/service/log/logger.service';
import { BaseUnsubscribe } from "../../../core/component/base-unsubscribe/base-unsubscribe";
import { ScreenManager } from '../../../shared/service/screen-manager.service';
import { ScreenNameEnum } from '../../../core/enum/screen-name.enum';
import { GoogleAnalyticsService } from "../../../shared/service/google-analytics.service";
import { AuthenticationService } from "../../../shared/service/authentication.service";

@Component({
  selector: 'gema3g-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent extends BaseUnsubscribe implements OnInit, AfterViewInit {

  constructor() {
    super();
    this.router.events
      .pipe(takeUntil(this.ngUnsubscribe), filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.loggerService.logInfo('navigated to ' + event?.url);
            this.simulateBreadcrumbs(event.url.replace('/account/', ''));
          }
        }
      );
    this.userService.userObservable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(value => {
        this.user = value;
      });

    /**
     * Use breakpointObserver to observe
     * orientation changes. When orientation
     * changes, use isMatched of breakpointObserver
     * to determine the current orientation and call
     * our webOrientationChange function with a string
     * value coorespoinding to the new orientation.
     */
    this.breakpointObserver
      .observe([
        Breakpoints.WebLandscape,
        Breakpoints.WebPortrait
      ])
      .subscribe((state: BreakpointState) => {
        this.breakpointObserver.isMatched(Breakpoints.WebLandscape)
          ? this.webOrientationChange('landscape')
          : this.webOrientationChange('portrait');
      });

    this.widthChange = this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
    ])
      .subscribe((state: BreakpointState) => {
        this.isXSmall = this.breakpointObserver.isMatched(Breakpoints.XSmall);
        this.isSmall = this.breakpointObserver.isMatched(Breakpoints.Small);
        this.isMedium = this.breakpointObserver.isMatched(Breakpoints.Medium);
        this.isLarge = this.breakpointObserver.isMatched(Breakpoints.Large);
        this.isXLarge = this.breakpointObserver.isMatched(Breakpoints.XLarge);

        if (
          this.drawer?.opened === false && this.isLarge ||
          this.drawer?.opened === false && this.isXLarge) {
          this.drawer.opened = true
        }
        if (this.drawer?.opened === true && this.isMedium) {
          this.drawer.opened = false
        }
      })
  }

  private router = inject(Router);
  private userService = inject(UserService);
  private loggerService = inject(LoggerService);
  private screenManager = inject(ScreenManager);
  public breakpointObserver = inject(BreakpointObserver);
  private gaService = inject(GoogleAnalyticsService);
  private authService = inject(AuthenticationService);

  ngAfterViewInit(): void {
    this.userAccessControls = this.userService.getUserAccessControl();

    if (
      this.drawer.opened === false && this.isLarge ||
      this.drawer.opened === false && this.isXLarge) {
      this.drawer.opened = true
    }
    if (this.drawer.opened === true && this.isMedium) {
      this.drawer.opened = false
    }
    this.screenManager.closeScreensWhenThisOpened(ScreenNameEnum.MY_ACCOUNT);
  }

  ngOnInit(): void {
    this.configureMenu();
  }


  async configureMenu() {
    if (this.accountMenu?.length && (this.user?.roles?.ssouser || this.user?.roles?.thirdpartyuser || this.authService.isSSOLogin)) {
      // remove pwd menu option for users that are not supposed to change their pwd
      this.accountMenu
        .filter(menu => menu.id === 'ProfileId')
        .forEach(menu => {
          menu.children = menu.children.filter(child => child.id != 'PasswordId');
        });
    }

    const licenseCounters = await lastValueFrom(this.userService.getUserLicenseCounters());

    // remove search comparable history menu options for non-REB users that do not have license package at company level or no license for comparables search
    if(!this.user.userProfile.parentREB){
    if ((licenseCounters.comparablesSearchCounter && licenseCounters.comparablesSearchCounter.licenseTotal <= 0) || !this.user.hasLicensePackageId()) {
      this.accountMenu
        .filter(menu => menu.id === 'SubscriptionsId')
        .forEach(menu => {
          menu.children = menu.children.filter(child => child.id != 'SearchComparablesReportsHistoryId');
        });
    }
    }
  }

  @ViewChild('drawer') drawer: MatSidenav;
  showFiller = false;
  accountMenu: AccountMenuItem[] = accountMenuDefinitions.map(value => {
    return new AccountMenuItem(value);
  });
  selectedMenu: AccountMenuItem | null;
  selectedSubMenu: AccountMenuSubItem | null;

  profilePhotoUrl: undefined;

  user: User;
  name: String = "";

  userAccessControls: UserAccessControl;
  firstSubMenuLink: string = '';
  firstSubMenu: string = '';
  secondSubMenuLink: string = '';
  secondSubMenu: string = '';
  breadcrumbsEnabled: boolean = false;

  // new
  message: string;
  stateMessage: any;
  matchedMessage: any;
  sizeMessage: any[] = [];
  matcher: MediaQueryList;
  widthChange;
  /**
   * Breakpoints and Orientation provided
   * via the 'layout' cdk.
   * For reference only
   */
  // viewportSizes = [
  //   Breakpoints.XSmall,
  //   Breakpoints.Small,
  //   Breakpoints.Medium,
  //   Breakpoints.Large,
  //   Breakpoints.XLarge,
  //   Breakpoints.Web,
  //   Breakpoints.WebLandscape,
  //   Breakpoints.WebPortrait,
  //   Breakpoints.Handset,
  //   Breakpoints.HandsetLandscape,
  //   Breakpoints.HandsetPortrait,
  //   Breakpoints.Tablet,
  //   Breakpoints.TabletLandscape,
  //   Breakpoints.TabletPortrait
  // ];

  /**
   * Our local boolean variables to
   * declare is we are within certain
   * breakpoints.
   */
  isXSmall: boolean;
  isSmall: boolean;
  isMedium: boolean;
  isLarge: boolean;
  isXLarge: boolean;

  menuItemClicked(menuItem: AccountMenuItem) {
    if (this.userAccessControls.restrictedAccess) {
      return;
    }
    this.accountMenu.forEach(item => {
      if (item.id != menuItem.id) {
        item.collapsed = true;
      } else {
        item.collapsed = !item.collapsed;
      }
    })
    if (this.isXSmall || this.isSmall || this.isMedium) this.toggle();
    this.loggerService.logInfo('account/' + menuItem.redirectTo);
    this.gaService.navigate('My Account', menuItem.redirectTo);
    this.router.navigate(['/account/' + menuItem.redirectTo]);
  }

  subMenuItemClicked(menuItem: AccountMenuSubItem) {
    if (this.userAccessControls.restrictedAccess) {
      return;
    }
    if (this.isXSmall || this.isSmall || this.isMedium) this.toggle();
    this.gaService.navigate('My Account', menuItem.redirectTo);
    this.router.navigate(['account/' + menuItem.redirectTo]);
  }

  openMyAccountOverview() {

  }

  navigateHome() {
    this.router.navigate(['../home']);
  }

  // since this is the only place where we use breadcrumbs it does not need to have a full library for it
  simulateBreadcrumbs(router: string) {
    this.loggerService.logInfo(router);
    let found: boolean = false;
    this.firstSubMenuLink = '';
    this.firstSubMenuLink = '';
    this.secondSubMenu = '';
    this.secondSubMenuLink = '';
    if (router == this.accountMenu[0]?.redirectTo) {
      this.breadcrumbsEnabled = false;
      // To set the title for the Overview screen and to reset after navigating from other selectedMenu to overview.
      this.firstSubMenu = this.accountMenu[0].title;
      this.selectedMenu = null;
      this.selectedSubMenu = null;
      return; // no need for any secondary link for Account
    }
    this.breadcrumbsEnabled = true;
    for (let i = 0; i < this.accountMenu.length; i++) {
      const menu = this.accountMenu[i];
      if (menu.redirectTo == router) {
        found = true;
        this.firstSubMenuLink = menu.redirectTo;
        this.firstSubMenu = menu.title;
        this.selectedMenu = menu;
        break;
      }

      if (!found && menu.children.length > 0) {
        for (let k = 0; k < menu.children.length; k++) {
          const menuSubItem = menu.children[k];

          if (router == menuSubItem.redirectTo) {
            this.firstSubMenuLink = menu.redirectTo;
            this.firstSubMenu = menu.title;
            this.secondSubMenuLink = menuSubItem.redirectTo;
            this.secondSubMenu = menuSubItem.title;
            found = true;
            this.selectedMenu = menu;
            this.selectedSubMenu = menuSubItem;
          }
        }
      }
      if (found) {
        break;
      }
    }
  }

  webOrientationChange(orientation: string) {
    // debugger;
    this.message = orientation;
  }

  toggle() {
    this.drawer.toggle();
  }

}
