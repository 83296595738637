import {StreetViewPoint} from "../../core/model/spatial/street-view-point";

export class StreetViewUtility {
  static Vc(a: number) {
    return a * (Math.PI / 180)
  }

  static Wc(a: number) {
    return a / (Math.PI / 180)
  }

  static Tc(a: number, b: number, c: number) {
    return ((a - b) % (c - b) + (c - b)) % (c - b) + b;
  }

  static computeHeading(streetViewPoint: StreetViewPoint): number {
    if(streetViewPoint && streetViewPoint.centroid && streetViewPoint.projection) {
      return this.calculateHeading(
        streetViewPoint.projection.latitude,
        streetViewPoint.projection.longitude,
        streetViewPoint.centroid.latitude,
        streetViewPoint.centroid.longitude);
    }
    return 0;
  }

  static calculateHeading(lat1: number | undefined, lng1: number | undefined, lat2: number | undefined, lng2: number | undefined): number {
    if(lat1 && lat2 && lng1 && lng2) {
      let c = this.Vc(lat1);
      let d = this.Vc(lat2);
      let e = this.Vc(lng2) - this.Vc(lng1);

      return this.Tc(this.Wc(Math.atan2(Math.sin(e) * Math.cos(d), Math.cos(c) * Math.sin(d) - Math.sin(c) * Math.cos(d) * Math.cos(e))), -180, 180);
    }
    return 0;
  }
}
