import {ConduitData} from "./conduit-data";

export class RelatedCondoDetail {

  constructor(relatedCondoDetail: RelatedCondoDetail) {
    if (relatedCondoDetail) {
      for (let property in relatedCondoDetail) {
        if (relatedCondoDetail.hasOwnProperty(property)) {
          // @ts-ignore
          this[property] = relatedCondoDetail[property];
        }
      }

      if (Array.isArray(relatedCondoDetail.conduitDatas)) {
        this.conduitDatas = relatedCondoDetail.conduitDatas.map(value => {return new ConduitData(value)});
      }
    }
  }

  commonName: string;
  conduitDatas: ConduitData[];
  corporationId: string;
  corporationName: string;
  createDate: Date;
  instrumentNum: string;
  levelNumber: string;
  pii?: any;
  pin: string;
  registrationDate?: Date;
  suitePin: string;
  type: string;
  unitNumber: string;
  updateDate: Date;
}
