import {inject, Injectable} from '@angular/core';
import {baseUrl} from "./system";
import {catchError, lastValueFrom, map, Observable, of, throwError} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {AddressCorrection} from "../../core/component/modal/address-correction-dialog/AddressCorrectionRequest";
import {LoggerService} from './log/logger.service';
import {UrlService} from "./url.service";
import {ZendeskConnectionParam} from "../../core/model/zendesk/zendesk-connection-param";
import {RemoteError} from "../../core/model/error/remote-error";

declare var zE: any;

@Injectable({
  providedIn: 'root'
})
export class ZendeskService {

  private https = inject(HttpClient);
  private loggerService = inject(LoggerService);

  private sendToken(zendeskConnectionParam: ZendeskConnectionParam): Observable<string>{

    return this.https.post(zendeskConnectionParam.url, {
      jwtInput: zendeskConnectionParam.jwtToken,
      returnInput: zendeskConnectionParam.returnTo
    }).pipe(
      map( (response: any) => {
        this.loggerService.logInfo(response);
        if (response) {
          return <string>response["return_to"];
        } else {
          return UrlService.DEFAULT_ZENDESK_URL;
        }
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return UrlService.DEFAULT_ZENDESK_URL;
      })
    );
  }

  getZendeskConnectionParams(targetPage?: string): Observable<ZendeskConnectionParam> {
    let urlSuffix = '/zendeskRedirectURL';
    if (targetPage && targetPage == 'support-page') {
      urlSuffix += '?defaultPage=true&supportPage=true';
    } else {
      urlSuffix += '?defaultPage=false&newFeedBack=false';
    }
    const url = baseUrl + urlSuffix;
    return this.https.get(url).pipe(
      map((resp: any) => {
        if (resp) {
          return <ZendeskConnectionParam>resp;
        } else {
          return new ZendeskConnectionParam(UrlService.DEFAULT_ZENDESK_URL);
        }
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }

  postAddressCorrectionRequest(request: AddressCorrection): Observable<boolean> {
    const url = UrlService.ZENDESK_REQUEST_URL;
    return this.https.post(url, request)
      .pipe(
        map(resp => {
          this.loggerService.logInfo(resp);
          return true;
        }),
        catchError((err) => {
          this.loggerService.logError(err);
          return throwError(err);
        })
      );
  }

  authenticateUserWidget = async () => {
    try {
      this.hideMessagingWidget(); //this deals with the new messaging api's bug with calling the older zE.hide function.

      var serviceContext = window.location.origin;
      var response = await fetch(serviceContext + "/gema-rest/rest/zendeskWidgetJWT");
      var responseText = await response.text();
      var zendeskAuthentication = JSON.parse(responseText);
      if (zendeskAuthentication) {
        if (zendeskAuthentication.authenticateMessagingWidgetUser) {
          var _this = this;
          zE("messenger", "loginUser", function (callback: (arg0: any) => void) {
            _this.loggerService.logDebug(`Authenticating user to Zendesk messaging widget: ${zendeskAuthentication.signedJWT}`);
            callback(zendeskAuthentication.signedJWT);
          });
        } else {
          this.loggerService.logWarning("Bypassing zendesk messaging widget authentication.");
        }
      } else {
        this.loggerService.logWarning("Failed to retrieve zendesk messaging widget authentication details.");
      }

    } catch (e) {
      this.loggerService.logError("Error processing zendesk messaging widget authentication." + e);
    } finally {
      //show the widget regardless of authentication result
      this.showMessagingWidget();
    }
  }

  loginUserToMessagingWidget = () => {
    this.authenticateUserWidget();
  }

  logoutUserFromMessagingWidget = () => {
    try {
      zE('messenger', 'logoutUser');
    } catch (e) {
      this.loggerService.logError('error logging out from zendesk messaging widget', e);
    }
  }

  showMessagingWidget = () => {
    try {
      zE('messenger', 'show');
    } catch (e) {
      this.loggerService.logError('error showing zendesk messaging widget', e);
    }
  }

  hideMessagingWidget = () => {
    try {
      zE('messenger', 'hide');
    } catch (e) {
      this.loggerService.logError('error hiding zendesk messaging widget', e);
    }
  }

  closeMessagingWidget = () => {
    try {
      zE('messenger', 'close');
    } catch (e) {
      this.loggerService.logError('error closing zendesk messaging widget', e);
    }
  }

  openMessagingWidget = () => {
    try {
      zE('messenger', 'open');
    } catch (e) {
      this.loggerService.logError('error opening zendesk messaging widget', e);
    }
  }

}
