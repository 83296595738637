<div class="gema3g-ss-container">
  <div class="gema3g-ss-container__inner">
    <div class="gema3g-ss-container__inner-grid">

      <div class="l-column">
        <h4 id="ss-h4-ap-{{assessment.rollNumber}}">Assessment Property Information</h4>
      </div>

      <div class="m-column">
        <div id="ss-ap-pa-label-{{assessment.rollNumber}}" class="flex">
          <h4 id="ss-h4-ap-pa-{{assessment.rollNumber}}">Property Address </h4>
          <span id="ss-ap-pa-{{assessment.rollNumber}}" class="ml-auto text-right">{{ assessment.siteDetail?.propertyAddress }}</span>
        </div>
        <div id="ss-ap-muni-label-{{assessment.rollNumber}}" class="flex">
          <h4 id="ss-h4-ap-muni-{{assessment.rollNumber}}">Municipality </h4>
          <span id="ss-ap-muni-{{assessment.rollNumber}}" class="ml-auto text-right">{{ assessment.siteDetail?.municipality }}</span>
        </div>
        <div id="ss-ap-ld-label-{{assessment.rollNumber}}" class="flex">
          <h4 id="ss-h4-ap-ld-{{assessment.rollNumber}}">Legal Description </h4>
          <span id="ss-ap-ld-{{assessment.rollNumber}}" class="ml-auto text-right">{{ assessment.siteDetail?.legalDescription }}</span>
        </div>
        @if (userAccessControls.iciAssessmentInformation) {
          <ng-container class="mb-8">
            <div id="ss-ap-cn-label-{{assessment.rollNumber}}" class="flex">
              <h4 id="ss-h4-ap-cn-{{assessment.rollNumber}}">Corporate Name </h4>
              <span id="ss-ap-cn-{{assessment.rollNumber}}" class="ml-auto text-right">{{ assessment.siteDetail?.corporateName }}</span>
            </div>
          </ng-container>
        }
        @if (userAccessControls.iciAssessmentInformation) {
          <div id="ss-ap-cma-label-{{assessment.rollNumber}}" class="flex">
            <h4 id="ss-h4-ap-cma-{{assessment.rollNumber}}">Corporate Mailing Address </h4>
            <span id="ss-ap-cma-{{assessment.rollNumber}}" class="ml-auto text-right">{{ assessment.siteDetail?.corporateMailingAddress }}</span>
          </div>
        }
      </div>

      <div class="r-column">
        <div id="ss-ap-un-label-{{assessment.rollNumber}}" class="flex">
          <h4 id="ss-h4-ap-un-{{assessment.rollNumber}}">Unit Number </h4>
          <span id="ss-ap-un-{{assessment.rollNumber}}" class="ml-auto text-right">{{ assessment.siteDetail?.unitNumber }}</span>
        </div>

        <ng-container class="mb-8">
          @if (userAccessControls.iciAssessmentInformation) {
            <div id="ss-ap-zoning-label-{{assessment.rollNumber}}" class="flex">
              <h4 id="ss-h4-ap-zoning-{{assessment.rollNumber}}">Zoning </h4>
              <span id="ss-ap-zoning-{{assessment.rollNumber}}" class="ml-auto text-right">{{ assessment.siteDetail?.zoning }}</span>
            </div>
          }
          <div id="ss-ap-pt-label-{{assessment.rollNumber}}" class="flex">
            <h4 id="ss-h4-ap-pt-{{assessment.rollNumber}}">Property Type </h4>
            <span id="ss-ap-pt-{{assessment.rollNumber}}" class="ml-auto text-right">{{ assessment.siteDetail?.propertyType }}</span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
