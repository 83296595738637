<div class="gema3g-ss-container">
  <div class="gema3g-ss-container__inner">
    @if (assessment?.mpacAssessment?.property?.residentialStructures?.length) {
      <div class="flex flex-col">
        <h4 class="pr-data-label mr-2">Structures</h4>
        <div class="flex flex-row dense-2 pb-2 overflow-auto">
          <table id="ss-tbl-med-{{assessment.rollNumber}}" [dataSource]="structuresDataSource" class="h-[100%]" class="mat-elevation-z2 standard-table" mat-table matSort>
            <ng-container matColumnDef="structureCode">
              <th id="ss-tbl-hdr-med-sn-{{assessment.rollNumber}}" *matHeaderCellDef mat-header-cell mat-sort-header>#</th>
              <td id="ss-tbl-data-med-sn-{{assessment.rollNumber}}-{{element.structureCode}}" *matCellDef="let element" mat-cell>
                {{element.structureCode | na}}
              </td>
            </ng-container>
            <ng-container matColumnDef="structureDescription">
              <th id="ss-tbl-hdr-ed-sd-{{assessment.rollNumber}}" *matHeaderCellDef mat-header-cell mat-sort-header>Description</th>
              <td id="ss-tbl-data-ed-sd-{{assessment.rollNumber}}-{{element.structureCode}}" *matCellDef="let element" mat-cell>
                {{element.structureDescription | na}}
              </td>
            </ng-container>
            <ng-container matColumnDef="actualYearBuilt">
              <th id="ss-tbl-hdr-ed-ayb-{{assessment.rollNumber}}" *matHeaderCellDef mat-header-cell mat-sort-header>Year<br/>Built</th>
              <td id="ss-tbl-data-ed-ayb-{{assessment.rollNumber}}-{{element.structureCode}}" *matCellDef="let element" mat-cell>
                {{element.actualYearBuilt | na}}
              </td>
            </ng-container>
            <ng-container matColumnDef="totalBedroomsCount">
              <th id="ss-tbl-hdr-ed-tbc-{{assessment.rollNumber}}" *matHeaderCellDef mat-header-cell mat-sort-header>Bedrooms</th>
              <td id="ss-tbl-data-ed-tbc-{{assessment.rollNumber}}-{{element.structureCode}}" *matCellDef="let element" mat-cell>
                {{element.totalBedroomsCount | na}}
              </td>
            </ng-container>
            <ng-container matColumnDef="totalFullBathsCount">
              <th id="ss-tbl-hdr-ed-tfbc-{{assessment.rollNumber}}" *matHeaderCellDef mat-header-cell mat-sort-header>Full<br/>Bathrooms</th>
              <td id="ss-tbl-data-ed-tfbc-{{assessment.rollNumber}}-{{element.structureCode}}" *matCellDef="let element" mat-cell>
                {{element.totalFullBathsCount | na}}
              </td>
            </ng-container>
            <ng-container matColumnDef="totalHalfBathsCount">
              <th id="ss-tbl-hdr-ed-thbc-{{assessment.rollNumber}}" *matHeaderCellDef mat-header-cell mat-sort-header>Half<br/>Bathrooms</th>
              <td id="ss-tbl-data-ed-thbc-{{assessment.rollNumber}}-{{element.structureCode}}" *matCellDef="let element" mat-cell>
                {{element.totalHalfBathsCount | na}}
              </td>
            </ng-container>
            <ng-container matColumnDef="totalFullStoreysCount">
              <th id="ss-tbl-hdr-ed-tfsc-{{assessment.rollNumber}}" *matHeaderCellDef mat-header-cell mat-sort-header>Full<br/>Stories</th>
              <td id="ss-tbl-data-ed-tfsc-{{assessment.rollNumber}}-{{element.structureCode}}" *matCellDef="let element" mat-cell>
                {{element.totalFullStoreysCount | na}}
              </td>
            </ng-container>
            <ng-container matColumnDef="partStoreysCode">
              <th id="ss-tbl-hdr-ed-psd-{{assessment.rollNumber}}" *matHeaderCellDef mat-header-cell mat-sort-header>Partial<br/>Stories</th>
              <td id="ss-tbl-data-ed-psd-{{assessment.rollNumber}}-{{element.structureCode}}" *matCellDef="let element" mat-cell>
                {{element.partStoreysDescription | na}}
              </td>
            </ng-container>
            <ng-container matColumnDef="splitLevelTypeDescription">
              <th id="ss-tbl-hdr-ed-sltd-{{assessment.rollNumber}}" *matHeaderCellDef mat-header-cell mat-sort-header>Split<br/>Level</th>
              <td id="ss-tbl-data-ed-sltd-{{assessment.rollNumber}}-{{element.structureCode}}" *matCellDef="let element" mat-cell>
                {{element.splitLevelTypeDescription | na}}
              </td>
            </ng-container>
            <ng-container matColumnDef="totalFirePlacesCount">
              <th id="ss-tbl-hdr-ed-tfpc-{{assessment.rollNumber}}" *matHeaderCellDef mat-header-cell mat-sort-header>Fireplaces</th>
              <td id="ss-tbl-data-ed-tfpc-{{assessment.rollNumber}}-{{element.structureCode}}" *matCellDef="let element" mat-cell>
                {{element.totalFirePlacesCount | na}}
              </td>
            </ng-container>
            <ng-container matColumnDef="indoorPool">
              <th id="ss-tbl-hdr-ed-tfpc-{{assessment.rollNumber}}" *matHeaderCellDef mat-header-cell mat-sort-header>Indoor Pool</th>
              <td id="ss-tbl-data-ed-tfpc-{{assessment.rollNumber}}-{{element.structureCode}}" *matCellDef="let element" mat-cell>
                {{element.indoorPool | na}}
              </td>
            </ng-container>
            <ng-container matColumnDef="outdoorPool">
              <th id="ss-tbl-hdr-ed-tfpc-{{assessment.rollNumber}}" *matHeaderCellDef mat-header-cell mat-sort-header>Outdoor Pool</th>
              <td id="ss-tbl-data-ed-tfpc-{{assessment.rollNumber}}-{{element.structureCode}}" *matCellDef="let element" mat-cell>
                {{element.outdoorPool | na}}
              </td>
            </ng-container>
            <tr id="ss-tbl-row-med-header-{{assessment.rollNumber}}" *matHeaderRowDef="columns" mat-header-row></tr>
            <tr id="ss-tbl-row-med-{{assessment.rollNumber}}-{{row.structureCode}}" *matRowDef="let row; columns: columns;" mat-row></tr>
          </table>
        </div>
      </div>
    }

    <div class="gema3g-ss-container__inner-grid">
      <div class="l-column">
      </div>
      <div class="m-column">
        @if (userAccessControls.MPACSubscriptionPremiumOrEnhanced) {
          <div id="ss-msed-ld-label-{{assessment.rollNumber}}" class="flex">
            <h4 id="ss-h4-msed-ld-{{assessment.rollNumber}}">Assessment Roll Legal Description </h4>
            <span id="ss-msed-ld-{{assessment.rollNumber}}" class="ml-auto min-w-[100px] lg:max-w-[200px] xl:max-w-[300px] text-right"> {{assessment.mpacAssessment?.property?.legalDescription | na}}</span>
          </div>
          @if(!userAccessControls.MPACSubscriptionPremiumOrEnhanced){
            <div id="ss-msed-zoning-label-{{assessment.rollNumber}}" class="flex">
              <h4 id="ss-h4-msed-zoning-{{assessment.rollNumber}}">Zoning </h4>
              <span id="ss-msed-zoning-{{assessment.rollNumber}}" class="ml-auto"> {{assessment.mpacAssessment?.property?.zoningList}}</span>
            </div>
          }
          <div id="ss-msed-sa-label-{{assessment.rollNumber}}" class="flex">
            <h4 id="ss-h4-msed-sa-{{assessment.rollNumber}}">Site Area </h4>
            <span id="ss-msed-sa-{{assessment.rollNumber}}" class="ml-auto"> {{assessment.mpacAssessment?.property?.actualLotSize | na }}{{assessment.mpacAssessment?.property?.actualLotSizeUom || ''}}</span>
          </div>
          <div id="ss-msed-sv-label-{{assessment.rollNumber}}" class="flex">
            <h4 id="ss-h4-msed-sv-{{assessment.rollNumber}}">Site Variance </h4>
            <span id="ss-msed-sv-{{assessment.rollNumber}}" class="ml-auto"> {{assessment.mpacAssessment?.property?.actualVarianceDescription | na}}</span>
          </div>
          <div id="ss-msed-dt-label-{{assessment.rollNumber}}" class="flex">
            <h4 id="ss-h4-msed-dt-{{assessment.rollNumber}}">Driveway Type </h4>
            <span id="ss-msed-dt-{{assessment.rollNumber}}" class="ml-auto"> {{assessment.mpacAssessment?.property?.drivewayTypeDescription | na}}</span>
          </div>
          <div id="ss-msed-gt-label-{{assessment.rollNumber}}" class="flex">
            <h4 id="ss-h4-msed-gt-{{assessment.rollNumber}}">Garage Type </h4>
            <span id="ss-msed-gt-{{assessment.rollNumber}}" class="ml-auto"> {{assessment.mpacAssessment?.property?.garageType | na}}</span>
          </div>
        }
      </div>
      <div class="r-column">
        @if (userAccessControls.MPACSubscriptionPremiumOrEnhanced) {
          <div id="ss-msed-gs-label-{{assessment.rollNumber}}" class="flex">
            <h4 id="ss-h4-msed-gs-{{assessment.rollNumber}}">Garage Spaces </h4>
            <span id="ss-msed-gs-{{assessment.rollNumber}}" class="ml-auto"> {{assessment.mpacAssessment?.property?.garageStructure}}</span>
          </div>
          <div id="ss-msed-wst-label-{{assessment.rollNumber}}" class="flex">
            <h4 id="ss-h4-msed-wst-{{assessment.rollNumber}}">Water Service Type </h4>
            <span id="ss-msed-wst-{{assessment.rollNumber}}" class="ml-auto"> {{assessment.mpacAssessment?.property?.waterServiceDescription | na}}</span>
          </div>
          <div id="ss-msed-st-label-{{assessment.rollNumber}}" class="flex">
            <h4 id="ss-h4-msed-st-{{assessment.rollNumber}}">Sanitation Type </h4>
            <span id="ss-msed-st-{{assessment.rollNumber}}" class="ml-auto"> {{assessment.mpacAssessment?.property?.sanitaryServiceDescription | na}}</span>
          </div>
          @if (userAccessControls.MPACSubscriptionPremium) {
            <div id="ss-msed-ad-label-{{assessment.rollNumber}}" class="flex">
              <h4 id="ss-h4-msed-ad-{{assessment.rollNumber}}">Abut Details </h4>
              <span id="ss-msed-ad-{{assessment.rollNumber}}" class="ml-auto"> {{assessment.mpacAssessment?.property?.abutDetails | na}}</span>
            </div>
          }
          @if (userAccessControls.MPACSubscriptionPremium) {
            <div id="ss-msed-pd-label-{{assessment.rollNumber}}" class="flex">
              <h4 id="ss-h4-msed-pd-{{assessment.rollNumber}}">Proximity Details </h4>
              <span id="ss-msed-pd-{{assessment.rollNumber}}" class="ml-auto min-w-[100px] lg:max-w-[200px] xl:max-w-[300px] text-right"> {{assessment.mpacAssessment?.property?.proximityDetails | na}}</span>
            </div>
          }
          @if (userAccessControls.MPACSubscriptionPremium) {
            <div id="ss-msed-od-label-{{assessment.rollNumber}}" class="flex">
              <h4 id="ss-h4-msed-od-{{assessment.rollNumber}}">Onsite Details </h4>
              <span id="ss-msed-od-{{assessment.rollNumber}}" class="ml-auto min-w-[100px] lg:max-w-[200px] xl:max-w-[300px] text-right"> {{assessment.mpacAssessment?.property?.onSiteDetails | na}}</span>
            </div>
          }
          @if (userAccessControls.MPACSubscriptionPremium) {
            <div id="ss-msed-wd-label-{{assessment.rollNumber}}" class="flex">
              <h4 id="ss-h4-msed-wd-{{assessment.rollNumber}}">WaterFront Details </h4>
              <span id="ss-msed-wd-{{assessment.rollNumber}}" class="ml-auto"> {{assessment.mpacAssessment?.property?.waterfrontDetails | na}}</span>
            </div>
          }
        }
      </div>
    </div>

  </div>
</div>
