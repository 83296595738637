import { Injectable, OnInit } from "@angular/core";
import * as _ from "lodash";
import { MetersToFeetWithUomPipe } from "../../../shared/pipe/meters-to-feet-with-uom.pipe";
import { MeasuredLineSegment } from "../../../core/model/property/measured-line-segment";
import { Polygon } from "../../../core/model/property/polygon";
import { SpatialPoint } from "../../../core/model/spatial/spatial-point";

declare var InfoBox: any;

@Injectable({
    providedIn: 'root'
})
export class PropertyReportMapService {

    renderPolygon = (map: google.maps.Map, polygon: Polygon[]) => {
        if (!_.isEmpty(polygon)) {
            var lotBounds = new google.maps.LatLngBounds();

            let polygonPaths: google.maps.LatLng[] = [];
            polygon.forEach((polygon: any) => {
                var latlng = new google.maps.LatLng(polygon.latitude, polygon.longitude);
                polygonPaths.push(latlng);
                lotBounds.extend(latlng);
            })

            //TODO: change styling
            const subjectPropertyPolygonToRender = new google.maps.Polygon({
                paths: polygonPaths,
                strokeColor: "#61A5F2",
                strokeWeight: 4,
                fillColor: "#61A5F2",
                fillOpacity: 0.0,
                clickable: false
            });
            subjectPropertyPolygonToRender.setMap(map);
            map.fitBounds(lotBounds);
        }
    }

    renderLotSizeMeasurements = (map: google.maps.Map, lineSegments: MeasuredLineSegment[], conversionPipe: MetersToFeetWithUomPipe) => {

        lineSegments?.forEach((segment) => {
            let label: string = conversionPipe.transform(segment.measurement, 2);
            this.renderLineSegmentPoint(map, segment.endPoint);
            this.renderLineSegmentLabel(map, segment.labelPosition, label.replace(/\s/g,""), segment.labelAngle);
        });
    }

    private renderLineSegmentPoint = (map: google.maps.Map, endPointCoordinate: SpatialPoint) => {

        let pixelOffset = new google.maps.Size(-3, -3)
        let content = "<div style='color:#fff; background:white; box-shadow: 0 0 0 1pt #3a8fef; border-radius: 3px; width: 6px; height: 6px;'></div>";

        let infoBoxOptions = {
            content: content,
            disableAutoPan: false,
            maxWidth: 0,
            pixelOffset: pixelOffset,
            zIndex: null,
            closeBoxURL: '',
            boxStyle: {
                background: null,
                opacity: 1,
                width: "0px"
            },
            position: new google.maps.LatLng(endPointCoordinate.latitude, endPointCoordinate.longitude)
        };

        let infoBox = new InfoBox(infoBoxOptions);
        infoBox.open(map);
    }

    private renderLineSegmentLabel = (map: google.maps.Map, labelCoordinate: SpatialPoint, label: string, rotation: number) => {

        let pixelOffset = new google.maps.Size(0, 0)
        let content = "<p style='color:#fff; text-indent: -10px; display:block; text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; " + "transform: rotate(" + (-rotation) + "deg);'>" + label + "</p>";

        let infoBoxOptions = {
            content: content,
            disableAutoPan: false,
            maxWidth: 0,
            pixelOffset: pixelOffset,
            zIndex: null,
            closeBoxURL: '',
            boxStyle: {
                background: null,
                opacity: 1,
                width: "0px"
            },
            position: new google.maps.LatLng(labelCoordinate.latitude, labelCoordinate.longitude)
        };

        let infoBox = new InfoBox(infoBoxOptions);
        infoBox.open(map);
    }
}
