import {Injectable} from "@angular/core";
import {UserPreference} from "../../core/model/user/preference/user-preference";

@Injectable({
  providedIn: 'root'
})
export class MeasurementUnitService{

  metersToFeet: number = 3.28084;
  metersToMiles: number = 0.000621371;
  metersToKilometers: number = 0.001;
  squareMetersToSquareFeet : number = 10.7639;
  squareMetersToAcres : number = 0.000247105;
  rate_MetersToFeet: number;
  symbol_MetersToFeet: string;
  rate_MetersToKMMiles: any;
  symbol_MetersToKMMiles: string;
  meterfeetThreshold: number;
  rateSquareMetersToSquareFeet: number;
  symbolSquareMetersToSquareFeet: string;
  rate_squareMetersToAcres: number;
  symbol_squareMetersToAcres: string;
  isUomInMeters: boolean = false;
  isUomUserPreferenceInMeteres: boolean = false;

  get feetThreshold():number {
    return 1000 / this.metersToFeet;
  }
  meterThreshold: number = 500;

  sqfeetTosqMeters: number = 0.092903;

  setUnitOfMeasure(userPreference: UserPreference) {
    this.setUnitOfMeasureUom(userPreference?.genericPreference?.uomDistance);
    // to allow reverting on each property change to user's preference UOM
    this.isUomUserPreferenceInMeteres = userPreference?.genericPreference?.uomDistance?.charAt(0) == 'M';
  }

  setUnitOfMeasureUom(uom: string){
    if (uom && uom.charAt(0) == 'M') {

      this.isUomInMeters = true;

      // / meters to feet
      this.rate_MetersToFeet = 1;
      this.symbol_MetersToFeet = " m";
      // / meters to miles
      this.rate_MetersToKMMiles = this.metersToKilometers;
      this.symbol_MetersToKMMiles = " km";
      this.meterfeetThreshold = this.meterThreshold;

      this.rateSquareMetersToSquareFeet = 1;
      this.symbolSquareMetersToSquareFeet = " m\u00B2"
      // / square meters to acres
      this.rate_squareMetersToAcres = 1;
      this.symbol_squareMetersToAcres = " m\u00B2"

    } else {

      this.isUomInMeters = false;
      // / meters to feet
      this.rate_MetersToFeet = this.metersToFeet;
      this.symbol_MetersToFeet = " ft";

      // / meters to miles
      this.rate_MetersToKMMiles = this.metersToMiles;
      this.symbol_MetersToKMMiles = " mi";
      this.meterfeetThreshold = this.feetThreshold;

      // / square meters to square feet
      this.rateSquareMetersToSquareFeet = this.squareMetersToSquareFeet;
      this.symbolSquareMetersToSquareFeet = " ft\u00B2";

      // / square meters to acres
      this.rate_squareMetersToAcres = this.squareMetersToAcres;
      this.symbol_squareMetersToAcres = " ac";
    }
  }

}
