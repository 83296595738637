import {BaseModel} from "../base-model";
import {Pii} from "../property/pii";

export class OwnerToPropertyMapItem extends BaseModel {
  constructor(ownerToPropertyMapItem?: OwnerToPropertyMapItem) {
    super(ownerToPropertyMapItem);

    if (ownerToPropertyMapItem) {
      if (ownerToPropertyMapItem.pii) {
        this.pii = new Pii(ownerToPropertyMapItem.pii);
      }
    }
  }

  count: number;
  pii: Pii;

  get fullAddress(): string {

    if (this.pii?.address && this.pii?.address.fullAddress.length > 0) {
      return this.pii?.address.fullAddress;
    }
    return "Address Not Available";
  }

  get pin(): string {
    return ( this.pii?.pin) ? this.pii.pin : '';
  }

  get arn(): string {
    return (this.pii?.arns?.length > 0)  ?  this.pii.arns[0] : '';
  }

  get hasPinOrArn(): boolean {
    return !!(this.pin || this.arn);
  }

  get legalDescription(): string {
    return ( this.pii) ? this.pii.shortLegalDescription : '';
  }

  get unitType(): string {
    return ( this.pii) ?this.pii.unitType :  '';
  }

  get streetName(): string {
    if (this.pii?.address?.streetName) {
      return this.pii.address.streetName;
    }
    return '';
  }

  get streetNumber(): string {
    if ( this.pii?.address?.streetNumber) {
      return this.pii.address.streetNumber;
    }
    return '';
  }

  // UI only
  detailsExpanded: boolean = false;
  arnExpanded: boolean = false;
  showExpansionImages: boolean = false;
}
