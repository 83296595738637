import { inject, Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogData } from "../../core/component/modal/confirm-dialog/confirm-dialog-data";
import { ConfirmDialogComponent } from "../../core/component/modal/confirm-dialog/confirm-dialog.component";
import { GoogleAnalyticsService } from "./google-analytics.service";
import { catchError, lastValueFrom, map, Observable, throwError } from "rxjs";
import { BuildVersion } from "../../core/model/miscellaneous/build-version";
import { LoggerService } from "./log/logger.service";
import { HttpClient } from "@angular/common/http";
import { ConfirmDialogUsageEnum } from "../../core/component/modal/confirm-dialog/confirm-dialog-usage.enum";

@Injectable({
  providedIn: 'root'
})
export class VersioningService{

  buildVersion: BuildVersion;
  _displayNewVersionDialog: boolean = false;
  private https = inject(HttpClient);
  private loggerService = inject(LoggerService);
  private dialog = inject(MatDialog);
  private gaService  = inject(GoogleAnalyticsService);

  getLocalBuildVersion(): Observable<BuildVersion> {
    let versionFile = '../../../../assets/json/version.json';
    return this.https.get(versionFile).pipe(
      map(resp => {
        this.loggerService.logInfo(`application version`, JSON.stringify(resp));
        return <BuildVersion>resp;
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }

  public async loadBuildVersion() {
    this.buildVersion = await lastValueFrom(this.getLocalBuildVersion());
  }

  public currentVersion(): string {
    return this.buildVersion?.version;
  }

  public async checkIfNewVersionIsAvailable(): Promise<boolean> {
    try {
      const currentVersion = this.currentVersion(); // this should be the version at the time the app started
      await this.loadBuildVersion(); // this should load the latest version from the static file on server (nginx should not cache version.json)
      return !!this.currentVersion() && !!currentVersion && this.currentVersion() != currentVersion;
    }catch (ex){
      this.loggerService.logError('Could not determine version info', ex);
    }
    return false;
  }

  restartApp(){
    caches.keys().then(function (names) {
      for (let name of names) {
        caches.delete(name);
      }
    });
    window.location.reload();
  }

  async processLocalVersionOutOfDate() {
    if (!this._displayNewVersionDialog) {
      this._displayNewVersionDialog = true;
      this.gaService.openModal('NewVersionDialog');
      const dialogData = new ConfirmDialogData(DataService.NEW_VERSION_TITLE, DataService.NEW_VERSION_MESSAGE, '', 'Ok', false);
      dialogData.dialogUsage = ConfirmDialogUsageEnum.NEW_VERSION;
      dialogData.newVersion = this.currentVersion();

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {data: dialogData})
        .afterClosed()
        .subscribe((response) => {
          try {
            this.restartApp();
          } finally {
            this._displayNewVersionDialog = false;
          }
        });
    } else {
      this.restartApp();
    }
  }
}
