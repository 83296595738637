export class Preference {
  constructor(key: string, value: string) {
    this.key = key;
    this.setValue(value);
  }

  key: string;
  booleanValue: boolean;
  value: string;

  setValue(val: string) {
    this.value = val;
    if (val) {
      this.booleanValue = val.toLowerCase() == 'true';
    }
  }

  toString(): string {
    return `key=${this.key} value =${this.value} booleanValue =${this.booleanValue}`;
  }
}
