<div class="gema3g-ao-container">
  <div class="gema3g-ao-container__inner">
    <div class="col-0 p-4">


      <form id="profile-form" [formGroup]="profileForm" class="account-main-white">
        <div class='geo-flex-row'>
          <div class='geo-flex-column'>
            <button id="profile-btn-save-top" (click)="submitProfile()" [disabled]="saveButtonDisabled" color="primary" [class.spinner]="saving" mat-raised-button>Save profile

            </button>
          </div>
        </div>

        <div class='geo-flex-row justify-content-center'>

          <div class='geo-flex-column get-dense-3'>

            <div id="profile-about-me" class="profileheader 2xl:text-base hidden lg:block">About Me</div>

            <div class="photosection pt-5 2xl:pt-6">
              <div class="container-fluid ">
                <div class="row">

                  <div class="float-left">

                    <div ng-if="profilePhotoUrl != null">
                      <img id='account-about-profile-picture'
                        class='xl:w-[80px] h-[109px] lg:w-[80px] h-[109px]'
                        [src]="profilePhotoUrl">
                      </div>
                    </div>
                    <div class="uploadtxt">
                      <a id="account-upload-pic" class="anchor" (click)="fileUpload.click()">Upload your photo</a>
                      <div id="profile-pic-max-size" class="maxsizetxt text-xs md:text-sm">Max Size: 500KB</div>
                      @if (hasProfilePhoto) {
                        <a (click)="removePhoto()" id="account-profile-photo" class="anchor">Remove your photo</a>
                      }
                      @if (!selectedPhotoExtensionsValid) {
                        <h4 id="account-fileext-error" class="fileuploaderror">Allowed extensions are: .jpg and .jpeg</h4>
                      }
                      @if (!selectedPhotoSizeValid) {
                        <h4 id="account-filesize-error" class="fileuploaderror">Maximum image size is 500KB</h4>
                      }
                    </div>
                    <input style="display: none"
                      class='about-profile-upload' type="file"
                      name="account-uploadedProfilePhoto" id='account-uploadProfilePhoto'
                      (change)="onFileSelected($event)"
                      accept="image/jpeg"
                      #fileUpload>
                    </div>
                  </div>

                </div>

                <mat-form-field id="profile-ff-firstName" appearance="outline" class="input-field">
                  <mat-label id="profile-label-firstName">First name</mat-label>
                  <input formControlName="firstName" id="account-firstName" matInput placeholder="">
                  @if (formControls['firstName']?.touched && formControls['firstName']?.errors?.['maxlength']) {
                    <mat-error id="profile-error-firstName-over-max">
                      Your first name or initial cannot exceed 23 characters.
                    </mat-error>
                  }
                  @if (formControls['firstName']?.touched && formControls['firstName']?.errors?.['required']) {
                    <mat-error id="profile-error-firstName-req">
                      First Name is required
                    </mat-error>
                  }
                </mat-form-field>

                <mat-form-field id="profile-ff-lastName" appearance="outline" class="input-field">
                  <mat-label id="profile-label-lastName">Last name</mat-label>
                  <input formControlName="lastName" id="account-lastName" matInput placeholder="">
                  @if (formControls['lastName']?.touched && formControls['lastName']?.errors?.['maxlength']) {
                    <mat-error id="profile-error-lastName-over-max">
                      Your first name or initial cannot exceed 23 characters.
                    </mat-error>
                  }
                  @if (formControls['lastName']?.touched && formControls['lastName']?.errors?.['required']) {
                    <mat-error id="profile-error-lastName-req">
                      Last Name is required
                    </mat-error>
                  }
                </mat-form-field>

                <mat-form-field id="profile-ff-email" appearance="outline" class="input-field">
                  <mat-label id="profile-label-email">Email</mat-label>
                  <input formControlName="email" id="account-email" matInput placeholder="name@example.com">
                  @if (formControls['email']?.touched && formControls['email']?.errors?.['email']) {
                    <mat-error id="profile-error-email-invalid">
                      Your email address must contain "&#64;" and ".".
                    </mat-error>
                  }
                  @if (formControls['email']?.touched && formControls['email']?.errors?.['required']) {
                    <mat-error id="profile-error-email-req">
                      Email is required
                    </mat-error>
                  }
                </mat-form-field>

                <mat-form-field id="profile-ff-cellphone" appearance="outline" class="input-field">
                  <mat-label id="profile-label-cellphone">Cell</mat-label>
                  <input formControlName="cellphone" id="account-cellphone" matInput placeholder="(555) 555-5555">
                  @if (formControls['cellphone']?.touched && formControls['cellphone']?.errors?.['pattern']) {
                    <mat-error id="profile-error-cellphone-invalid">
                      Invalid cellphone number format.
                    </mat-error>
                  }
                  @if (formControls['cellphone']?.touched && formControls['cellphone']?.errors?.['maxlength']) {
                    <mat-error id="profile-error-cellphone-over-max">
                      Your cellphone number cannot exceed 20 characters.
                    </mat-error>
                  }
                </mat-form-field>

                <mat-form-field id="profile-ff-position" appearance="outline" class="input-field">
                  <mat-label id="profile-label-position">Occupation</mat-label>
                  <input formControlName="position" id="account-position" matInput placeholder="">
                  @if (formControls['position']?.touched && formControls['position']?.errors?.['maxlength']) {
                    <mat-error id="profile-error-position-over-max">
                      Your occupation cannot exceed 50 characters.
                    </mat-error>
                  }
                </mat-form-field>

                <mat-form-field id="profile-ff-twitterUrl" appearance="outline" class="input-field">
                  <mat-label id="profile-label-twitterUrl">Twitter</mat-label>
                  <input formControlName="twitterUrl" id="account-twitterUrl" matInput placeholder="http://twitter.com/"/>
                  @if (formControls['twitterUrl']?.touched && formControls['twitterUrl']?.errors?.['maxlength']) {
                    <mat-error id="profile-error-twitterUrl-over-max">
                      Your twitter url exceeds maximum allowed characters.
                    </mat-error>
                  }
                </mat-form-field>

                <mat-form-field id="profile-ff-linkedInUrl" appearance="outline" class="input-field">
                  <mat-label id="profile-label-linkedInUrl">LinkedIn</mat-label>
                  <input formControlName="linkedInUrl" id="account-linkedInUrl" matInput placeholder="http://linkedin.com/"/>
                  @if (formControls['linkedInUrl']?.touched && formControls['linkedInUrl']?.errors?.['maxlength']) {
                    <mat-error id="profile-error-linkedInUrl-over-max">
                      Your linkedIn url exceeds maximum allowed characters.
                    </mat-error>
                  }
                </mat-form-field>

              </div>
              <div class='geo-flex-column get-dense-3'>
                <mat-form-field id="profile-ff-companyName" appearance="outline" class="input-field">
                  <mat-label id="profile-label-companyName">Company Name</mat-label>
                  <input formControlName="company" id="account-companyName" matInput placeholder=""/>
                  @if (formControls['company']?.touched && formControls['company']?.errors?.['maxlength']) {
                    <mat-error id="profile-error-companyName-over-max">
                      Your company name cannot exceed 75 characters.
                    </mat-error>
                  }
                </mat-form-field>

                <mat-form-field id="profile-ff-address" appearance="outline" class="input-field">
                  <mat-label id="profile-label-address">Address</mat-label>
                  <input formControlName="address" id="account-address" matInput placeholder=""/>
                </mat-form-field>

                <mat-form-field id="profile-ff-city" appearance="outline" class="input-field">
                  <mat-label id="profile-label-city">City</mat-label>
                  <input formControlName="city" id="account-city" matInput placeholder=""/>
                  @if (formControls['companyName']?.touched && formControls['city']?.errors?.['maxlength']) {
                    <mat-error id="profile-error-city-over-max">
                      City name cannot exceed 75 characters.
                    </mat-error>
                  }
                </mat-form-field>

                <mat-form-field id="profile-ff-province" appearance="outline">
                  <mat-label id="profile-label-province">Province</mat-label>
                  <mat-select formControlName="province" id="account-province">
                    @for (province of provinces; track province) {
                      <mat-option id="profile-option-province-{{province}}" [value]="province">
                        {{province}}
                      </mat-option>
                    }
                  </mat-select>
                </mat-form-field>

                <mat-form-field id="profile-ff-postalCode" appearance="outline" class="input-field">
                  <mat-label id="profile-label-postalCode">Postal Code</mat-label>
                  <input formControlName="postalCode" id="account-postalCode" matInput placeholder="A1A 1A1">
                  @if (formControls['postalCode']?.touched && formControls['postalCode']?.errors?.['pattern']) {
                    <mat-error id="profile-error-postalCode-invalid">
                      A valid postal code is required.
                    </mat-error>
                  }
                </mat-form-field>

                <mat-form-field id="profile-ff-homePageUrl" appearance="outline" class="input-field">
                  <mat-label id="profile-label-homePageUrl">Website</mat-label>
                  <input formControlName="homePageURL" id="account-homePageURL" matInput placeholder="www.example.com"/>
                  @if (formControls['homePageURL']?.touched && formControls['homePageURL']?.errors?.['maxlength']) {
                    <mat-error id="profile-error-homePageUrl-over-max">
                      Website cannot exceed 250 characters.
                    </mat-error>
                  }
                </mat-form-field>

                <mat-form-field id="profile-ff-phone" appearance="outline" class="input-field">
                  <mat-label id="profile-label-phone">Office</mat-label>
                  <input formControlName="phone" id="account-phone" matInput placeholder="(555) 555-5555 x555">
                  @if (formControls['phone']?.touched && formControls['phone']?.errors?.['pattern']) {
                    <mat-error id="profile-error-phone-invalid">
                      Invalid phone number format.
                    </mat-error>
                  }
                  @if (formControls['phone']?.touched && formControls['phone']?.errors?.['maxlength']) {
                    <mat-error id="profile-error-phone-over-max">
                      Your phone number cannot exceed 20 characters.
                    </mat-error>
                  }
                </mat-form-field>

                <mat-form-field id="profile-ff-faxfield" appearance="outline" class="input-field">
                  <mat-label id="profile-label-faxfield">Fax</mat-label>
                  <input formControlName="fax" id="account-faxfield" matInput placeholder="(555) 555-5555"/>
                  @if (formControls['fax']?.touched && formControls['fax']?.errors?.['maxlength']) {
                    <mat-error id="profile-error-faxfield-over-max">
                      Your fax number cannot exceed 15 characters.
                    </mat-error>
                  }
                </mat-form-field>

                @if (isUserProfileFormForNewUser()) {
                  <mat-form-field id="profile-ff-search-method" appearance="outline">
                    <mat-label id="profile-label-search-method">Default Search Method</mat-label>
                    <mat-select formControlName="searchMethod" id="search-method" [(ngModel)]="defaultSearchMethod">
                      @for (sm of searchMethods; track sm) {
                        <mat-option id="profile-option-search-method-{{sm.value}}" [value]="sm.value">
                          {{sm.viewValue}}
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field id="profile-ff-search-location" appearance="outline">
                    <mat-label id="profile-label-search-location">Default LRO</mat-label>
                    <mat-select formControlName="lro" id="search-location" [(ngModel)]="defaultLro">
                      @for (lro of lros; track lro) {
                        <mat-option id="profile-option-search-location-{{lro.id}}" [value]="lro.id">
                          {{lro.name}} ({{lro.id}})
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                }

              </div>

            </div>

            @if (!formControls['email']?.touched) {
              <div class='geo-flex-row'>
                <div class='geo-flex-column'>
                  <mat-checkbox class='checkbox' color="primary" formControlName="receiveMail" id="account-receiveMail"/>
                  <div id="profile-consent" class='caslsection consentFormPosition flex-1 text-base sm:text-xs md:text-sm xl:text-lg'>
                    Yes, I agree to receive Teranet Inc.'s marketing communications such as: newsletters,
                    product related news and contents.
                    You can withdraw your consent at any time. Please refer to our Privacy Policy or contact us for more details.
                  </div>
                </div>
              </div>
            }
            <div class='geo-flex-row justify-content-center'>
              <div class='geo-flex-column'>
                <button id="profile-btn-save-bottom" (click)="submitProfile()" [disabled]="saveButtonDisabled" color="primary" [class.spinner]="saving" mat-raised-button>Save profile
                </button>
              </div>
              <div class='geo-flex-column'>

              </div>
            </div>
          </form>


        </div>
      </div>
    </div>