import {PinOrArn} from "../property/pin-or-arn";

export class HomeParam {
  pin: string;
  arn: string;
  showMapOnly: boolean;

  constructor(pin: string, arn: string, showMapOnly: boolean) {
    this.pin = pin;
    this.arn = arn;
    this.showMapOnly = showMapOnly;
  }

  toString(): string {
    return `pin=${this.pin}, arn=${this.arn}, showMapOnly=${this.showMapOnly}`;
  }

  static fromPinOrArn(poa: PinOrArn): HomeParam {
    return new HomeParam(poa.pin, poa.arn, false);
  }
}
