import {Polygon} from "../property/polygon";
import {BaseModel} from "../base-model";

export class PdfReportCircleRequest extends BaseModel {
  constructor(pdfReportCircleRequest?: PdfReportCircleRequest) {
    super(pdfReportCircleRequest);
    this.center = new Polygon(pdfReportCircleRequest?.center);
  }

  center: Polygon;
  radiusInMeters: number;
  minDate?: any;
  lastDays: number;
  maxDate?: any;
  minAmount: number;
  maxAmount: number;
  condo: boolean;
  maxArea: number;
  minArea: number;
  freehold: boolean;
}
