import {BaseModel} from "../base-model";

export class SalesHistory extends BaseModel {

  constructor(salesHistory?: SalesHistory) {
    super(salesHistory);
    if(salesHistory){
      this.polInstrument = new PolInstrument(salesHistory.polInstrument);
      this.polPropertyInstrumentPK = new PolPropertyInstrumentPK(salesHistory.polPropertyInstrumentPK);
    }
  }

  modificationDate: Date;
  polInstrument: PolInstrument;
  polPropertyInstrumentPK: PolPropertyInstrumentPK;
  ruledOutInd: string;
}


export class PolInstrument extends BaseModel{

  constructor(pi? : PolInstrument) {
    super(pi);
    if(pi){
      if(Array.isArray(pi.instrumentParties)){
        this.instrumentParties = pi.instrumentParties.map(value => {return new InstrumentParty(value)});
      }
      if(pi.instrumentSubType){
        this.instrumentSubType = new InstrumentSubType(pi.instrumentSubType);
      }
      if(pi.polInstrumentPK){
        this.polInstrumentPK = new PolInstrumentPK(pi.polInstrumentPK);
      }
      if(pi.registrationDate){
        this.registrationDate = new Date(pi.registrationDate);
      }
    }
  }

  concatenatedPartyTos: string;
  considerationAmt: number;
  instrumentParties: InstrumentParty[];
  instrumentSubType: InstrumentSubType;
  instrumentSubTypeCode: string;
  polInstrumentPK: PolInstrumentPK;
  registrationDate: Date;
}

export class PolPropertyInstrumentPK extends BaseModel{
  instrumentNum: string;
  pin: string;
}

export class PolInstrumentPK extends BaseModel{
  instrumentNum: string;
  lro: string;
}

export class InstrumentPartyId extends BaseModel{
  instrumentNum: string;
  lroNum: string;
  seqNo: string;
}

export class InstrumentParty extends BaseModel{
  constructor(ip?: InstrumentParty) {
    super(ip);
    if(ip){
      this.instrumentPartyId = new InstrumentPartyId(ip);
    }
  }


  indicator: string;
  instrumentPartyId: InstrumentPartyId;
  name: string;
}

export class InstrumentSubType extends BaseModel{
  instrumentSubTypeCode: string;
  instrumentSubTypeDesc: string;
  instrumentTypeCode: string;
  registrationSystemCode: string;
}
