import { inject, Injectable } from "@angular/core";
import { UserService } from "./user.service";
import { LroPolygonsService } from "./lro-polygons.service";
import { LoggerService } from "./log/logger.service";
import { LogLevel } from "./log/log-level-model";
import { AuthenticationService } from "./authentication.service";
import { TimerService } from "./timer/timer.service";
import { ComparablesSearchService } from "./search/comparables-search.service";
import { ZendeskService } from "./zendesk.service";


@Injectable({providedIn: 'root'})
export class ReloadStateService {
  constructor() {
  }

  authService = inject(AuthenticationService);
  timerService = inject(TimerService);
  comparablesSearchService = inject(ComparablesSearchService);
  zendeskService = inject(ZendeskService);

  private userService = inject(UserService);
  private lroPolygonsService = inject(LroPolygonsService);
  private loggerService = inject(LoggerService);

  async reload(){
    this.loggerService.logInfo("Initialization service is starting...");
    this.userService.tryToLoadUserFromLocalStorage();
    this.authService.tryToLoadAuth0FromLocalStorage();
    if(this.authService.oktaLogin){
      setTimeout( () => {this.timerService.startCheckingForAuth0TokenExpiration();}, 2000);
    }
    await this.lroPolygonsService.loadLroPolygons();
    await this.loggerService.loadLogProperties();

    this.zendeskService.loginUserToMessagingWidget();
    this.comparablesSearchService.initCounters(true);

    this.loggerService.log("ReloadState service has completed.", LogLevel.INFO);
  }

}
