import {DataService} from "../../../shared/service/data.service";

export class PinListStaticStreetView {
  data: Map<string, SignedImageData>;

  constructor(pinList?: string[], defaultImageUrl?: string) {
    this.data = new Map<string, SignedImageData>();

    if (Array.isArray(pinList) && pinList?.length > 0 && defaultImageUrl) {
      pinList?.forEach(pin => {
        this.data.set(pin, {imageUrl: defaultImageUrl});
      })

    }
  }

  getImageUrlForPin(pin: string | undefined): string {
    if(pin) {
      const signedImageData = this.data.get(pin);
      return signedImageData?.imageUrl ?? DataService.NO_STREETVIEW_IMAGE;
    }
    return DataService.NO_STREETVIEW_IMAGE;
  }
}

export class SignedImageData {
  imageUrl: string;
}
