import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DemographicsAreaEnum} from "../../../../../../core/enum/demographics-area.enum";
import {FusionChartDataSource} from "../../../../../../core/model/fusion-chart/fusion-chart-data-source";
import {DemographicsChartsUtility} from "../../../../../../shared/utility/fusion-charts/demographics-charts.utility";
import {DemographicsCultural} from "../../../../../../core/model/demographics/demographics-cultural";
import {DataService} from "../../../../../../shared/service/data.service";
import {BaseChartComponent} from "../../../../../../core/component/base-chart/base-chart.component";

@Component({
  selector: 'gema3g-immigration-status',
  templateUrl: './immigration-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./immigration-status.component.scss']
})
export class ImmigrationStatusComponent extends BaseChartComponent {
  @Input() cultural = new DemographicsCultural();

  override setHeightWidth() {
    //this.defaultWidth = "177";
    this.defaultHeight = "177"
    this.minWidth = 177;
    this.maxWidth = 177;
    this.minHeight = 177;
    this.maxHeight = 177;
  }

  override loadChartData() {
    setTimeout(() => {
      this.nbhDataSource = DemographicsChartsUtility.immigrationStatusDataSource(DataService.DEFAULT_CHART_FONT, 'NBH', this.cultural?.PRCDDA, window.innerWidth);
      this.comDataSource = DemographicsChartsUtility.immigrationStatusDataSource(DataService.DEFAULT_CHART_FONT, 'COM', this.cultural?.FSA, window.innerWidth);
      this.cityDataSource = DemographicsChartsUtility.immigrationStatusDataSource(DataService.DEFAULT_CHART_FONT, 'CITY', this.cultural?.PRCDCSD, window.innerWidth);
    }, 10);
  }


  getPercentageImmigrant(demographicsAreaEnum: DemographicsAreaEnum): number {
    switch (demographicsAreaEnum) {
      case DemographicsAreaEnum.NBH:
        return this.cultural?.PRCDDA?.percentageImmigrant;
      case DemographicsAreaEnum.CITY:
        return this.cultural?.PRCDCSD?.percentageImmigrant;
      case DemographicsAreaEnum.COM:
        return this.cultural?.FSA?.percentageImmigrant;
    }
    return 0;
  }

  getPercentageNonImmigrant(demographicsAreaEnum: DemographicsAreaEnum): number {
    switch (demographicsAreaEnum) {
      case DemographicsAreaEnum.NBH:
        return this.cultural?.PRCDDA?.percentageNonImmigrant;
      case DemographicsAreaEnum.CITY:
        return this.cultural?.PRCDCSD?.percentageNonImmigrant;
      case DemographicsAreaEnum.COM:
        return this.cultural?.FSA?.percentageNonImmigrant;
    }
    return 0;
  }
}
