import {BaseModel} from "../../../base-model";
import {PropertyDetail} from "../../../property/property-detail";
import {ReportPreference} from "../../../user/preference/report-preference";

export class PdfReportPropertyDetailConfiguration extends BaseModel {

  constructor(propertyDetail: PropertyDetail, reportPreferences?: ReportPreference) {
    super();
    if (propertyDetail) {
      this.enabled = !!reportPreferences?.pdfPropertyDetails;

      this.propertyHasAerialMapView = propertyDetail.hasArielMap;
      if (this.propertyHasAerialMapView && reportPreferences) {
        this.aerialMapView = !!reportPreferences?.pdfAerialMap;
      }
      this.propertyHasStreetView = propertyDetail.isStreetViewAvailable;
      if (this.propertyHasStreetView && reportPreferences) {
        this.streetView = reportPreferences.pdfStreetView;
      }
    }
  }

  enabled: boolean;

  propertyHasStreetView: boolean;
  streetView: boolean;

  propertyHasAerialMapView: boolean;
  aerialMapView: boolean;
}
