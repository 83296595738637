@if (demographicsPopulation) {
  <table id="dg-tbl-pop-fs" class="family-status-table">
    <tr id="dg-tbl-row-pop-fs-hdr">
      <th id="dg-tbl-hdr-pop-fs-type"></th>
      <th><img id="dg-pop-fs-married-icon" alt="Male" src="/assets/img/icon_married-children.png"/>
        <p id="dg-tbl-hdr-pop-fs-married" class="text-os-bold">>15 Married with Children</p></th>
      <th class="single-fam"><img
        id="dg-pop-fs-single-icon-1" alt="Single Family" src="/assets/img/icon_single-children-1.png"/> <img
        id="dg-pop-fs-single-icon-2" alt="Single Family" class="last"
        src="/assets/img/icon_single-children-2.png"/>
        <div class="clear"></div>
        <p id="dg-tbl-hdr-pop-fs-single" class="text-os-bold">>15 Single with Children</p></th>
    </tr>
    @if (selectedArea.includes(demographicsAreaEnum.NBH)) {
      <tr id="dg-tbl-row-pop-fs-nbh" class="display-optionT-da">
        <td id="dg-tbl-data-pop-fs-type-nbh" class="first-td">NBH</td>
        <td id="dg-tbl-data-pop-fs-married-nbh" class="other-td">
          {{ demographicsPopulation.PRCDDA?.percentageOfMarriedWithChildrenFor15Yrs | na | fusionChartsNumber }}%
        </td>
        <td id="dg-tbl-data-pop-fs-single-nbh" class="other-td">
          {{ demographicsPopulation.PRCDDA?.percentageOfSingleWithChildrenFor15Yrs | na | fusionChartsNumber }}%
        </td>
      </tr>
    }

    @if (selectedArea.includes(demographicsAreaEnum.COM)) {
      <tr id="dg-tbl-row-pop-fs-com" class="display-optionT-fsa">
        <td id="dg-tbl-data-pop-fs-type-com" class="first-td">COM</td>
        <td id="dg-tbl-data-pop-fs-married-com" class="other-td">
          {{ demographicsPopulation.FSA?.percentageOfMarriedWithChildrenFor15Yrs  | na | fusionChartsNumber }}%
        </td>
        <td id="dg-tbl-data-pop-fs-single-com" class="other-td">
          {{ demographicsPopulation.FSA?.percentageOfSingleWithChildrenFor15Yrs | na | fusionChartsNumber }}%
        </td>
      </tr>
    }

    @if (selectedArea.includes(demographicsAreaEnum.CITY)) {
      <tr id="dg-tbl-row-pop-fs-city" class="display-optionT-cma">
        <td id="dg-tbl-data-pop-fs-type-city" class="first-td">CITY</td>
        <td id="dg-tbl-data-pop-fs-married-city" class="other-td">
          {{ demographicsPopulation.PRCDCSD?.percentageOfMarriedWithChildrenFor15Yrs | na | fusionChartsNumber }}%
        </td>
        <td id="dg-tbl-data-pop-fs-single-city" class="other-td">
          {{ demographicsPopulation.PRCDCSD?.percentageOfSingleWithChildrenFor15Yrs | na | fusionChartsNumber }}%
        </td>
      </tr>
    }

  </table>
}
