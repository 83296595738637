import {BaseModel} from "../base-model";

export class PdfReportDownloadResult extends BaseModel{

  constructor(fileName?: string) {
    super();
    if(fileName){
      this.fileName = fileName;
      this.fileDownloaded = true;
    }
  }

  success: boolean = true;
  fileDownloaded: boolean = true;
  fileName: string;
}
