import { Component } from '@angular/core';

@Component({
  selector: 'gema3g-omnibar-condo-search-results',
  templateUrl: './omnibar-condo-search-results.component.html',
  styleUrls: ['./omnibar-condo-search-results.component.scss']
})
export class OmnibarCondoSearchResultsComponent {

}
