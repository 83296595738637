@if (condoLevels && condoLevels.length) {
  <div class="condo-name-section">
    <div class="condo-image">
      <img alt="single condo header" id="csr-img-propertytype-condo-white" src="assets/img/svg/icons/property/icon_propertytype_condo_white.svg">
    </div>
    <h3 class="condo-name" id="csr-h3-condo-address"><span>{{ condoAddress | na }}</span></h3>
  </div>
  <div class="sr-results-found" id="csr-condo-units">
    <strong>
      Condo Units:
      <span id="csr-total-units">{{ totalUnits ? totalUnits : 'Not Available' }}</span>
    </strong>
  </div>
  <div class="flex flex-column">
    <div>
      <gema3g-sort-search-results
        (onSortingSelected)="applySorting($event)"
        (onClickEvent)="onSortingClick($event)"
        [defaultSortingOption]="defaultSortingOption"
        [sortingOptions]="sortingOptions"
        class="sr-sorting-container level-range flex-0 w-1/4"
        >
      </gema3g-sort-search-results>
    </div>
    <div class="flex-1 !border-b-2 !border-solid">
      <p-dropdown (onChange)="jumpToLevel($event)"
        (onClick)="onSortingClick($event)"
        [options]="condoLevels"
        [style]="{'width':'100%'}"
        [styleClass]="'condo-levels'"
        autoWidth="false"
        class="flex-1"
        optionLabel="lbl"
        optionValue="levelNumber"
        placeholder="SELECT BY LEVEL"
        >
        <ng-template let-condoLevel pTemplate="item">
          <div>
            {{ condoLevel.levelNumber }}
          </div>
        </ng-template>
      </p-dropdown>
    </div>
  </div>
  <div class="sr-results-child">
    @for (condoLevel of condoLevels; track condoLevel) {
      <div>
        <div class="condo-level" id="condo-level-header-{{condoLevel.levelNumber}}">
          <div id="csr-condo-levels-{{condoLevel.levelNumber}}-units-{{condoLevel.count}}">
            <strong>
              Level: {{ condoLevel.levelNumber }} / Condo Units: {{ condoLevel.count }}
            </strong>
          </div>
          <div class="flex-1"></div>
          <div class="h-[24px]">
            @if (condoLevel.expanded) {
              <div (click)="collapseCondoLevel(condoLevel)" class="text-left" id="csr-collapse-condo-level-{{condoLevel.levelNumber}}">
                <gema3g-svg-icon [fill]="'#9e9e9e'"
                  [height]="24"
                  [icon]="'chevronup'"
                  [width]="34"
                  id="csr-icon-chevronup-top-level-{{condoLevel.levelNumber}}"
                  >
                </gema3g-svg-icon>
              </div>
            }
            @if (!condoLevel.expanded) {
              <div (click)="expandCondoLevel(condoLevel)" class="text-left" id="csr-expand-condo-level-{{condoLevel.levelNumber}}">
                <gema3g-svg-icon [fill]="'#9e9e9e'"
                  [height]="24"
                  [icon]="'chevrondown'"
                  [width]="34"
                  id="csr-icon-chevrondown-top-level-{{condoLevel.levelNumber}}"
                  >
                </gema3g-svg-icon>
              </div>
            }
          </div>
        </div>
        @if (condoLevel.expanded && condoLevel.loading) {
          <div class="loading" id="csr-loading-{{condoLevel.levelNumber}}">
            <img alt="Loading..." id="csr-img-wait-spinner-{{condoLevel.levelNumber}}" src="/assets/img/icon-refresh.gif"/>
          </div>
        }
        @if (condoLevel.expanded && !condoLevel.loading) {
          @for (unit of condoLevel.units; track unit; let i = $index) {
            <div>
              @if (unit.fullAddress) {
                <div (mouseout)="unit.showExpansionImages = false"
                  (mouseover)="unit.showExpansionImages = true"
                  class="sr-property-container !border-b-2 !border-solid"
                  id="csr-property-container-{{condoLevel.levelNumber}}-{{i}}"
                  >
                  <div class="sr-item-image-container">
                    @if (unit?.type != null) {
                      @switch (unit?.type) {
                        @case ('SUITE') {
                          <gema3g-svg-icon id="csr-icon-location-{{condoLevel.levelNumber}}-{{i}}" [fill]="'#fcbc77'" [height]="24" [icon]="'location'" [width]="24"></gema3g-svg-icon>
                        }
                        @case ('LOCKER_OR_PARKING') {
                          <gema3g-svg-icon id="csr-icon-locker-or-parking-{{condoLevel.levelNumber}}-{{i}}" [fill]="'#3a8fef'" [height]="24" [icon]="'parking'" [width]="24"></gema3g-svg-icon>
                        }
                        @case ('PARKING') {
                          <gema3g-svg-icon id="csr-icon-parking-{{condoLevel.levelNumber}}-{{i}}" [fill]="'#3a8fef'" [height]="24" [icon]="'parking'" [width]="24"></gema3g-svg-icon>
                        }
                        @case ('LOCKER') {
                          <gema3g-svg-icon id="csr-icon-locker-{{condoLevel.levelNumber}}-{{i}}" [fill]="'#3a8fef'" [height]="24" [icon]="'locker'" [width]="24"></gema3g-svg-icon>
                        }
                      }
                    } @else {
                      <div class="icon-location">
                        @if (unit.pin == null) {
                          <gema3g-svg-icon [fill]="'#fcbc77'" [height]="24" [icon]="'location'" [width]="24" id="csr-icon-location-pin-null-{{condoLevel.levelNumber}}-{{i}}"></gema3g-svg-icon>
                        } @else {
                          @if (unit.pin.length < 6) {
                            <gema3g-svg-icon id="csr-icon-building-{{condoLevel.levelNumber}}-{{i}}" [fill]="'#3a8fef'" [height]="24" [icon]="'building'" [width]="24"></gema3g-svg-icon>
                          } @else {
                            <gema3g-svg-icon id="csr-icon-location-len-greaterthan-5-{{condoLevel.levelNumber}}-{{i}}" [fill]="'#fcbc77'" [height]="24" [icon]="'location'" [width]="24"></gema3g-svg-icon>
                          }
                        }
                      </div>
                    }
                  </div>
                  <div class="sr-property-description-container">
                    <div class="sr-property-description-first-item">
                      <div class="text-os-bold" id="csr-unit-full-address-{{condoLevel.levelNumber}}-{{i}}">{{ unit.fullAddress }}</div>
                      <div class="flex-1"></div>
                      <div [ngClass]="unit.hasPinOrArn ? 'block' : 'hidden'" class="h-[24px]" id="csr-unit-pin-or-arn">
                        @if (unit.detailsExpanded) {
                          <div (click)="unit.detailsExpanded = !unit.detailsExpanded" class="text-left" id="csr-details-collapse-{{condoLevel.levelNumber}}-{{i}}">
                            <gema3g-svg-icon [fill]="'#9e9e9e'"
                              [height]="24"
                              [hidden]="!unit.showExpansionImages && !isMobileFullScreen"
                              [icon]="'chevronup'"
                              [width]="34"
                              id="csr-icon-chevronup-{{condoLevel.levelNumber}}-{{i}}"
                              >
                            </gema3g-svg-icon>
                          </div>
                        }
                        @if (!unit.detailsExpanded) {
                          <div (click)="unit.detailsExpanded = !unit.detailsExpanded" class="text-left" id="csr-details-expand-{{condoLevel.levelNumber}}-{{i}}">
                            <gema3g-svg-icon [fill]="'#9e9e9e'"
                              [height]="24"
                              [hidden]="!unit.showExpansionImages && !isMobileFullScreen"
                              [icon]="'chevrondown'"
                              [width]="34"
                              id="csr-icon-chevrondown-{{condoLevel.levelNumber}}-{{i}}"
                              >
                            </gema3g-svg-icon>
                          </div>
                        }
                      </div>
                    </div>
                    <div id="csr-legal-description-{{condoLevel.levelNumber}}-{{i}}">{{ unit.legalDescription | cutText:43: false }}</div>
                    @if (unit.detailsExpanded) {
                      <div>
                        @if (unit.pii?.pin) {
                          <div class="flex flex-row" id="csr-unit-pin-{{condoLevel.levelNumber}}-{{i}}">
                            <div class="sr-details-label text-os-bold" id="csr-details-label-pin-{{condoLevel.levelNumber}}-{{i}}">PIN:</div>
                            <div id="csr-pii-pin-{{condoLevel.levelNumber}}-{{i}}">{{ unit.pii?.pin }}</div>
                          </div>
                        }
                        @if (unit.pii?.arns && unit.pii.arns.length > 0) {
                          @if (unit.pii?.arns && unit.pii.arns.length == 1) {
                            <div class="flex flex-row">
                              <div class="sr-details-label text-os-bold" id="csr-details-label-arn-{{condoLevel.levelNumber}}-{{i}}">ARN:</div>
                              <div id="csr-pii-arns-{{condoLevel.levelNumber}}-{{i}}">{{ unit.pii.arns[0] }}</div>
                            </div>
                          }
                          @if (unit.pii?.arns && unit.pii.arns.length > 1) {
                            <div>
                              <a (click)="unit.arnExpanded = !unit.arnExpanded" class="anchor" id="csr-link-arn-expanded-{{condoLevel.levelNumber}}-{{i}}" target="_blank">
                                Multiple ARNS
                              </a>
                            </div>
                            @if (unit.arnExpanded) {
                              @for (arn of unit.pii?.arns; track arn) {
                                <div class="flex flex-row">
                                  <div class="sr-details-label text-os-bold" id="csr-details-label-arn-{{arn}}-{{condoLevel.levelNumber}}-{{i}}">ARN:</div>
                                  <div id="csr-unit-pii-arn-{{arn}}-{{condoLevel.levelNumber}}-{{i}}">{{ arn }}</div>
                                </div>
                              }
                            }
                          }
                        }
                        @if (unit.unitType) {
                          <div class="flex flex-row">
                            <div class="sr-details-label text-os-bold" id="csr-details-label-type-{{condoLevel.levelNumber}}-{{i}}">Type:</div>
                            @if (unit.unitType == 'LOCKER_OR_PARKING') {
                              <div id="csr-unit-type-other-{{condoLevel.levelNumber}}-{{i}}">Other</div>
                            }
                            @if (unit.unitType != 'LOCKER_OR_PARKING') {
                              <div id="csr-unit-type-locker-or-parking-{{condoLevel.levelNumber}}-{{i}}">{{ unit.unitType | capitalize }}</div>
                            }
                          </div>
                        }
                      </div>
                    }
                    @if (unit.hasPinOrArn) {
                      <a (click)="goToProperty(unit?.pii!)" class="anchor" id="csr-link-property-report-{{condoLevel.levelNumber}}-{{i}}" target="_blank">Property Report</a>
                    }
                  </div>
                </div>
              }
            </div>
          }
        }
      </div>
    }
  </div>
  @if(showBlankSection){
    <div class="min-h-[130px]"></div>
  }
}
