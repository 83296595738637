import {Address} from "../property/address";
import {MpacAssessmentProperty} from "../property/mpac-assessment-property";
import {BaseModel} from "../base-model";
import {Foi} from "./foi";

export class MpacAssessment {

  constructor(mpacAssessment? : MpacAssessment) {

    if (mpacAssessment) {
      for (let property in mpacAssessment) {
        if (mpacAssessment.hasOwnProperty(property)) {
          // @ts-ignore
          this[property] = mpacAssessment[property];
        }
      }
      if(mpacAssessment.address){
        this.address = new Address(mpacAssessment.address);
      }
      if(mpacAssessment.property){
        this.property = new MpacAssessmentProperty(mpacAssessment.property);
      }
      if(Array.isArray(mpacAssessment.taxEstimates)){
        this.taxEstimates = mpacAssessment.taxEstimates.map(value => {return new TaxEstimate(value);});
      }

      if(mpacAssessment.foi){
        this.foi = new Foi(mpacAssessment.foi);
      }
    }
  }

  address: Address;
  property: MpacAssessmentProperty;
  taxEstimates: TaxEstimate[];
  reportDate: string;
  roll: string;
  foi: Foi;
}

export class TaxEstimate extends BaseModel{
  constructor(taxEstimate? : TaxEstimate) {
    super(taxEstimate);
  }
  taxEstimate: string;
  taxYear: string;
}

