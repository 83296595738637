import {inject, Injectable} from "@angular/core";
import {catchError, map, Observable, of, throwError} from "rxjs";
import { HttpClient } from "@angular/common/http";
import { baseUrl } from "./system";
import { Demographics } from "../../core/model/demographics/demographics";
import { LoggerService } from "./log/logger.service";
import {PropertyDetail} from "../../core/model/property/property-detail";

@Injectable({providedIn: 'root'})

export class DemographicsService {

  private https = inject(HttpClient);
  private loggerService = inject(LoggerService);

  getDemographicsDataForPropertyDetail(propertyDetail: PropertyDetail): Observable<Demographics | null> {
     if(propertyDetail?.pii?.pin){
       return this.getDemographicsDataFromPin(propertyDetail?.pii?.pin);
     } else if (propertyDetail.pii?.arn){
       return this.getDemographicsDataFromArn(propertyDetail?.pii?.arn);
     }
     return of(null);
  }

  getDemographicsDataFromPin(pin: string): Observable<Demographics> {
    const url = baseUrl + '/property/demographics?pin=' + pin;
    return this.getDemographicsDataFromUrl(url, pin);
  }

  getDemographicsDataFromArn(arn: string): Observable<Demographics> {
    const url = baseUrl + '/property/demographics/arn?arn=' + arn;
    return this.getDemographicsDataFromUrl(url,arn);
  }

  getDemographicsDataFromUrl(url: string, pinOrArn: string): Observable<Demographics> {
    return this.https.get(url).pipe(
      map(resp => {
        const demo: Demographics = new Demographics(resp);
        demo.setPinOrArn(pinOrArn);
        //this.loggerService.logTable(demo);
        return demo;
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }
}
