import {Component, inject, OnInit} from '@angular/core';
import {LoggerService} from "../../shared/service/log/logger.service";
import {AuthenticationService} from "../../shared/service/authentication.service";


/*
* this will be called from the password reset email link
* see Branding -> Email Templates -> Redirect to
* */
@Component({
  selector: 'gema3g-aith0-password-reset-callback',
  standalone: true,
  imports: [],
  templateUrl: './auth-password-reset-callback.component.html',
  styleUrl: './auth-password-reset-callback.component.scss'
})
export class AuthPasswordResetCallbackComponent {

  authService = inject(AuthenticationService);
  loggerService = inject(LoggerService);

  error: string = '';

  async ngOnInit(): Promise<void> {

    this.loggerService.logDebug('Running password reset callback');

    await this.authService.loginWithOkta();
  }
}
