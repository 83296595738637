import { HttpClient, HttpHeaders } from "@angular/common/http";
import { inject, Injectable, OnDestroy, signal, WritableSignal } from "@angular/core";
import { BehaviorSubject, catchError, lastValueFrom, map, Observable, takeUntil, throwError } from "rxjs";
import { PropertyDetail } from "../../core/model/property/property-detail";
import { EStoreProduct } from "../../core/model/product/e-store/e-store-product";
import { baseUrl } from "./system";
import { ReportAvailability } from "../../core/model/report/report-availability";
import { StreetViewPoint } from "../../core/model/spatial/street-view-point";
import { PropertyReportSearchService } from "./search/property-report-search.service";
import { LoggerService } from "./log/logger.service";
import { ScreenManager } from "./screen-manager.service";
import { ScreenNameEnum } from "../../core/enum/screen-name.enum";
import { PropertyDetailSectionEnum } from "../../core/enum/property-detail-section-enum";
import { GeneratePdfReportDialogData } from "../../core/component/modal/generate-pdf-report/generate-pdf-report-dialog-data";
import { GeneratePdfReportComponent } from "../../core/component/modal/generate-pdf-report/generate-pdf-report.component";
import { PdfReportRequestParam } from "../../core/model/pdf-report/pdf-report-request-param";
import { GeneratePdfReportService } from "./generate-pdf-report.service";
import { MatDialog } from "@angular/material/dialog";
import { GoogleAnalyticsService } from "./google-analytics.service";
import { ComparableSalesResultPayload } from "../../core/model/comparables/comparable-sales-result-payload";
import { PropertyViewedInsight } from "../../core/model/property/property-viewed-insight";
import {DataService} from "./data.service";


@Injectable({
  providedIn: 'root'
})
export class PropertyReportService {

  private https = inject(HttpClient);
  private propertyReportSearchService = inject(PropertyReportSearchService);
  private loggerService = inject(LoggerService);
  private screenManager = inject(ScreenManager);
  private dialog = inject(MatDialog);
  private generatePdfReportService = inject(GeneratePdfReportService);
  private gaService = inject(GoogleAnalyticsService);

  resetSubscribers() {
    this._subjectPropertyIdentifier.next('');
    this._subjectProperty.next(new PropertyDetail());
    this._propertyStreetViewRequest.next(new StreetViewPoint());
  }

  private _subjectPropertyIdentifier = new BehaviorSubject<string>('');
  subjectPropertyIdentifier$ = this._subjectPropertyIdentifier.asObservable();

  private _propertyDetailIdentifier = new BehaviorSubject<string>('');
  propertyDetailIdentifier$ = this._propertyDetailIdentifier.asObservable();

  private _subjectProperty = new BehaviorSubject<PropertyDetail>(new PropertyDetail());
  subjectProperty$ = this._subjectProperty.asObservable();

  private _propertyPropertyRendered = new BehaviorSubject<boolean>(false);
  propertyPropertyRendered$ = this._propertyPropertyRendered.asObservable();

  private _propertyReportedPanelScroller = new BehaviorSubject<PropertyDetailSectionEnum | null>(null);
  propertyReportedPanelScroller$ = this._propertyReportedPanelScroller.asObservable();

  private _propertyStreetViewRequest = new BehaviorSubject<StreetViewPoint>(new StreetViewPoint());
  propertyStreetViewRequest$ = this._propertyStreetViewRequest.asObservable();

  private _siteStructureMapItRequest = new BehaviorSubject<boolean>(false);
  siteStructureMapItRequest$ = this._siteStructureMapItRequest.asObservable();

  private _temporaryChangeOnUom = new BehaviorSubject<null>(null);
  temporaryChangeOnUom = this._temporaryChangeOnUom.asObservable();

  private _propertyReportStreetView = new BehaviorSubject<boolean | null>(false);
  propertyReportStreetView$ = this._propertyReportStreetView.asObservable();

  private _propertyReportTopScroller = new BehaviorSubject<boolean>(false);
  propertyReportTopScroller$ = this._propertyReportTopScroller.asObservable();

  private carouselProductsLoadingSignal: WritableSignal<boolean> = signal(false);

  getSubjectProperty(): PropertyDetail {
    return this._subjectProperty.getValue();
  }

  getSubjectPropertyPin(): string {
    return this.getSubjectProperty().pii?.pin;
  }

  getSubjectPropertyCentroid(): any {
    return this.getSubjectProperty().pii?.pinXy?.centroid;
  }

  getSubjectPropertyLatLng(): google.maps.LatLng {
    let propertyCentroid = this.getSubjectProperty().pii?.pinXy?.centroid;
    let latLng!: google.maps.LatLng;

    if (propertyCentroid != null) {
      latLng = new google.maps.LatLng(propertyCentroid.latitude, propertyCentroid.longitude);
    }

    return latLng;
  }

  updateSubjectProperty = (subjectProperty: PropertyDetail) => {
    this._subjectProperty.next(subjectProperty);
    this._propertyPropertyRendered.next(false);
  }

  setPropertyReportRendered = (rendered: boolean) => {
    this._propertyPropertyRendered.next(rendered);
  }

  scrolltoSectionPanel = (panel: PropertyDetailSectionEnum | null) => {
    this._propertyReportedPanelScroller.next(panel);
  }

  /**
   * Delegates the retrieval of the <code>PropertyDetail</code> data by PIN to the <code>HomeComponent</code> component.
   * @param pin
   */
  showPropertyReportByPinDeferData = (pin: string) => {
    this._subjectPropertyIdentifier.next(pin);
  }

  /**
   * Delegates the retrieval of the <code>PropertyDetail</code> data by ARN to the <code>HomeComponent</code> component.
   * @param arn
   */
  showPropertyReportByArnDeferData = (arn: string) => {
    this._subjectPropertyIdentifier.next(arn);
  }

  setNextPropertyDetailIdentifier = (identifier: string) => {
    this._propertyDetailIdentifier.next(identifier);
  }

  /**
   * This is a utility method to simultaneously retrieve the property report data and update the subject property observable for all subscribers.
   * @param pin
   */
  retrieveAndUpdatePropertyReport = async (pin: string) => {
    try {
      let propertyDetail: PropertyDetail = await lastValueFrom(this.propertyReportSearchService.getPropertyDetailByPin(pin), { defaultValue: new PropertyDetail() });
      this.updateSubjectProperty(propertyDetail);

    } catch (e) {
      this.loggerService.logError(`error retrieving property detail for pin ${pin}`, e);
      this.screenManager.hideScreen(ScreenNameEnum.PROPERTY_REPORT);
    }
  }

  teranetInsightsReportType(prod: EStoreProduct): number {
    return (prod.prodId == "170") ? 1 : 2;
  }

  checkTeranetInsightsReportAvailability(pin: string | undefined, prod: EStoreProduct): Observable<ReportAvailability> {
    const reportType = this.teranetInsightsReportType(prod);
    const persist = true;
    const url = `${baseUrl}/avmreport/availability?pin=${pin}&reporttype=${reportType}&persist=${persist}`;
    return this.https.get(url).pipe(
      map(resp => {
        this.loggerService.logInfo(resp);
        return <ReportAvailability>resp;

      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }

  getStreetViewPoint(pin: string | undefined): Observable<StreetViewPoint> {
    const url = `${baseUrl}/property/streetViewPoints?pin=${pin}`;
    return this.https.get(url).pipe(
      map(resp => {
        return <StreetViewPoint>resp;
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }

  requestPropertyStreetView(streetViewPoint: StreetViewPoint) {
    this._propertyStreetViewRequest.next(streetViewPoint);
  }

  requestSiteStructureMapIt(flag: boolean) {
    this._siteStructureMapItRequest.next(flag);
  }

  newTemporaryChangeOnUom() {
    this._temporaryChangeOnUom.next(null);
  }

  setStreetViewOpenedFromPropertyReport = (flag: boolean) => {
    this._propertyReportStreetView.next(flag);
  }

  isStreetViewOpenedFromPropertyReport = () => {
    return this._propertyReportStreetView.getValue();
  }

  printPDFReports = async (openFromComparablesReport: boolean = false, comparableSales?: ComparableSalesResultPayload, propertyDetail? : PropertyDetail) => {

    this.gaService?.buttonClicked(DataService.GA_PRINT_PDF_BUTTON, DataService.GA_PRINT_PDF_BUTTON_LABEL);
    let dialogData ;
    if(comparableSales && propertyDetail){
      dialogData = new GeneratePdfReportDialogData(propertyDetail, openFromComparablesReport, comparableSales)
    } else {
      dialogData = new GeneratePdfReportDialogData(this.getSubjectProperty(), openFromComparablesReport, comparableSales)
    }
    let modalConfig = {
      data: dialogData,
      minWidth: 400,
      maxWidth: 900,
      maxHeight: '110%'
    };
    this.gaService.openModal('GeneratePdfReport');
    const dialogRef = await this.dialog.open(GeneratePdfReportComponent, modalConfig)
      .afterClosed()
      .subscribe(async (resp: boolean) => {
        // @ts-ignore
        if (resp && (resp instanceof PdfReportRequestParam)) {
          this.generatePdfReportService.openPdfReport(resp);
        } else if (resp && resp.hasOwnProperty("openComparableSales")) {
          this.screenManager.showScreen(ScreenNameEnum.SEARCH_COMPARABLES_FORM)
        } else {
          this.loggerService.logDebug("user cancelled pdf request.")
        }
      });
  }

  getPropertyViewedInsights = (pin: string): Observable<PropertyViewedInsight> => {
    const url = `${baseUrl}/property/insights/property/viewed?pin=${pin}`;
    return this.https.get(url).pipe(
      map(resp => {
        return <PropertyViewedInsight>resp;
      }),
      catchError((err) => {
        this.loggerService.logError(err);
        return throwError(err);
      })
    );
  }

  startCarouselProductsLoad = () => {
    this.carouselProductsLoadingSignal.set(true);
  }

  endCarouselProductsLoad = () => {
    this.carouselProductsLoadingSignal.set(false);
  }

  isCarouselProductsLoading = (): boolean => {
    return this.carouselProductsLoadingSignal();
  }

  scrollToTopOfPage = () => {
    this._propertyReportTopScroller.next(true);
  }

  cancelScrollToTopOfPage = () => {
    this._propertyReportTopScroller.next(false);
  }
}
