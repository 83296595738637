import { inject, Injectable } from "@angular/core";
import { catchError, map, Observable, throwError } from "rxjs";
import { baseUrl } from "./system";
import { HttpClient } from "@angular/common/http";
import * as _ from 'lodash';
import { LoggerService } from "./log/logger.service";
import { Assessment } from "../../core/model/assessment/assessment";
import { XrefId } from "../../core/model/property/property-detail";
import { PinRollNumberXref } from "../../core/model/mpac/pin-rollnumber-xref";

@Injectable({
  providedIn: 'root'
})
export class MpacAssessmentService {

    private https = inject(HttpClient);
    private loggerService = inject(LoggerService);

    public getFullAssessmentsByPin = (pin: string): Observable<Assessment[]> => {
        const url = `${baseUrl}/assessment/fullByPin/${pin}`;

        return this.https.get(url, {
          headers: {
            'Content-Type': 'application/json'
          }
        }).pipe(
          map(response => {
            if (!_.isEmpty(response)) {
              return <Assessment[]>response;
            } else {
              return [];
            }
          }),
          catchError((err) => {
            this.loggerService.logError(`error retrieving full assessments by pin ${pin}`, err);
            return throwError(err);
          })
        );
    }

    public getAssessmentXRefByArn = (arn: string): Observable<PinRollNumberXref[]> => {
      const url = `${baseUrl}/assessment/xref/${arn}`;

      return this.https.get(url, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).pipe(
        map(response => {
          if (!_.isEmpty(response)) {
            return <PinRollNumberXref[]>response;
          } else {
            return [];
          }
        }),
        catchError((err) => {
          this.loggerService.logError(`error retrieving assessment xref by arn ${arn}`, err);
          return throwError(err);
        })
      );
  }


}
